import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, ConfigProvider, Modal, Popconfirm, Select, Typography } from 'antd'
import groupBy from 'lodash/groupBy'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { addWellAfe, getAfeDict, getWellAfe, updateWellAfe } from 'service/afe'
import { EditableProTable } from '@ant-design/pro-components'
import enUS from "antd/locale/en_US";
import omit from 'lodash/omit'
import SaveAFEModal from './SaveAFEModal'
import { useToggle } from '@uidotdev/usehooks'
import { formatDate } from 'utils/dateUtil'

import editableTableClasses from '../editable-table.module.scss'
import afeTableClasses from './afe-table.module.scss'
import { exportToSpreadsheet } from 'utils/excelUtil'
import CreateAFEModal from './CreateAFEModal'
import AddAFEItemModal from './AddAFEItemModal'
import { snakeToCamel } from 'utils/string'

const TOTAL_ROW_ID = 10000000;

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const WellAfeTable = ({ wellId, assetId }) => {
  const [activeAFE, setActiveAFE] = useState(null)
  const [saveModalOpened, toggleSaveModal] = useToggle(false)
  const [createModalOpened, toggleCreateModal] = useToggle(false)
  const [afeApproved, setAFEApproved] = useState(null)
  const [addItemModalOpened, toggleAddItemModal] = useToggle(false)

  const { data: afeList, isFetching: isFetchingAFE, refetch: refetchAFE } = useQuery({
    queryKey: ['getWellAfe', wellId],
    queryFn: () => getWellAfe(wellId, null, (data) => {
      if (!activeAFE) {
        setActiveAFE(data[0]?.id ?? null)
      }
    }),
    select: res => res.data
  })

  const { data: afeDict, isFetching: isFetchingDict } = useQuery({
    queryKey: ['getAfeDict'],
    queryFn: () => getAfeDict(),
    select: res => res.data
  })

  const { mutate: updateAFE, mutateAsync: updateAFEAsync, isPending: isUpdating } = useMutation({
    mutationFn: (data) => updateWellAfe(wellId, data, () => {
      refetchAFE({
        cancelRefetch: true
      })
    })
  })

  const { mutateAsync: createAFEAsync, isPending: isCreatingAFE } = useMutation({
    mutationFn: (afeData) => addWellAfe(wellId, afeData),
    onSuccess: (res) => {
      refetchAFE({
        cancelRefetch: true
      })
      setActiveAFE(res.data.id)
    },
    onError: (err) => {
      if (err.response?.data?.detail?.startsWith("IntegrityError: duplicate key value violates unique constraint \"auth_for_expt_afe_uwi_afe_name_unique\"")) {
        Modal.error({
          title: "Sending data error",
          content:
            "The AFE name already exists",
        });
      } else {
        Modal.error({
          title: "Sending data error",
          content:
            "Please contact with system manager. error info: " + err.message,
        });
      }
    }
  })

  const afeOptions = useMemo(() => afeList?.map?.(afe => ({
    value: afe.id,
    label: afe.afe_name
  }) ?? []), [afeList])

  const activeAFEData = useMemo(() => afeList?.find(afe => afe.id === activeAFE), [activeAFE, afeList])

  useEffect(() => {
    setAFEApproved(activeAFEData?.approval_status ?? null)
  }, [activeAFEData])

  const afeCostItemRecords = useMemo(() => {
    if (!afeDict || !afeList || !activeAFEData) return []
    const groupedCostItems = groupBy(activeAFEData.cost_items ?? [], 'cost_item_id')

    const records = []

    Object.entries(afeDict).forEach(([group, items], groupIndex) => {
      const groupRecords = []
      let drillingCostSum = 0,
        completionCostSum = 0,
        totalCostSum = 0

      items.forEach(item => {
        const itemRecords = groupedCostItems[item.id]
        if (!itemRecords?.length) return;
        groupRecords.push(...itemRecords.map((record) => {
          drillingCostSum += record.drilling_cost ?? 0
          completionCostSum += record.completion_cost ?? 0
          totalCostSum += record.total_cost ?? 0
          return {
            ...Object.entries(record).reduce((recordData, [key, value]) => {
              if (typeof value !== 'number') return recordData
              recordData[key] = Number.parseFloat(value.toFixed(2)) || null
              return recordData
            }, { ...record }),
            item: snakeToCamel(item.name),
            __type: 'item'
          }
        }))
      })

      records.push(...[{
        id: `__${ group }_${ groupIndex }`,
        item: group,
        drilling_cost: Number.parseFloat(drillingCostSum.toFixed(2)) || null,
        completion_cost: Number.parseFloat(completionCostSum.toFixed(2)) || null,
        total_cost: Number.parseFloat(totalCostSum.toFixed(2)) || null,
        __type: 'group'
      }, ...groupRecords])
    })

    const grandTotal = records.reduce((sum, record) => {
      if (record.__type !== 'item') return sum
      Object.keys(sum).forEach(metric => {
        if (typeof record[metric] !== 'number') return;
        sum[metric] += record[metric] ?? 0
      })
      return sum
    }, {
      drilling_cost_per_unit: 0,
      drilling_days_or_units: 0,
      drilling_cost: 0,
      completion_cost_per_unit: 0,
      completion_days_or_units: 0,
      completion_cost: 0,
      total_cost: 0
    })

    records.push({
      id: `__total`,
      item: 'Total',
      ...Object.entries(grandTotal).reduce((total, [key, value]) => {
        total[key] = Number.parseFloat(value.toFixed(2)) || null
        return total
      }, { ...grandTotal }),
      __type: 'grandTotal'
    })

    return records
  }, [activeAFEData, afeDict, afeList])

  const dictValueEnums = useMemo(() => {
    if (!afeDict) return {}
    let _dictValueEnums = {};
    Object.values(afeDict).forEach((items) => {
      items?.forEach((item) => {
        _dictValueEnums[item.id] = {
          text: snakeToCamel(item.name),
        };
      });
    });
    _dictValueEnums[TOTAL_ROW_ID] = { text: "Total" };

    return _dictValueEnums
  }, [afeDict])

  const columns = useMemo(
    /**
     * 
     * @returns {import('@ant-design/pro-components').ProColumns[]}
     */
    () => [
      {
        title: "Item",
        dataIndex: "item",
        key: "item",
        editable: (_, record) => record.__type === 'item',
        valueType: "select",
        valueEnum: dictValueEnums,
        render: (dom, record) => record.__type !== 'item' ? <b>{record.item}</b> : dom,
        onCell: (record) => ({
          colSpan: record.__type !== 'item' ? 3 : 1,
          className: record.__type === 'item' ? afeTableClasses.costItemName : undefined
        }),
        fieldProps: {
          dropdownMatchSelectWidth: false,
          showSearch: true,
          filterOption
        },
        width: 150,
      },
      {
        title: "Drilling Cost Per Unit",
        dataIndex: "drilling_cost_per_unit",
        key: "drilling_cost_per_unit",
        editable: (_, record) => record.__type === 'item',
        onCell: (record) => ({
          colSpan: record.__type !== 'item' ? 0 : 1
        })
      },
      {
        title: "Drilling Days/Units",
        dataIndex: "drilling_days_or_units",
        key: "drilling_days_or_units",
        editable: (_, record) => record.__type === 'item',
        onCell: (record) => ({
          colSpan: record.__type !== 'item' ? 0 : 1
        }),
      },
      {
        title: "Drilling Cost",
        dataIndex: "drilling_cost",
        key: "drilling_cost",
        editable: (_, record) => record.__type === 'item',
        render: (dom, record) => record.__type === 'group' ? <b>{record.drilling_cost}</b> : dom,
        onCell: (record) => ({
          colSpan: record.__type !== 'item' ? 3 : 1,
        }),
        fieldProps: (_form, config) => {
          const drillingCostPerUnit = _form.getFieldValue([config.entity.id, 'drilling_cost_per_unit'])
          const drillingUnits = _form.getFieldValue([config.entity.id, 'drilling_days_or_units'])
          const drillingCost = Number.parseFloat(`${ drillingCostPerUnit || 0 }`)
            * Number.parseFloat(`${ drillingUnits || 0 }`)
          _form.setFieldValue([config.entity.id, 'drilling_cost'], drillingCost.toFixed(2))
          return {
            readOnly: true,
          }
        }
      },
      {
        title: "Completion Cost Per Unit",
        dataIndex: "completion_cost_per_unit",
        key: "completion_cost_per_unit",
        editable: (_, record) => record.__type === 'item',
        onCell: (record) => ({
          colSpan: record.__type !== 'item' ? 0 : 1
        }),
      },
      {
        title: "Completion Days/Units",
        dataIndex: "completion_days_or_units",
        key: "completion_days_or_units",
        editable: (_, record) => record.__type === 'item',
        onCell: (record) => ({
          colSpan: record.__type !== 'item' ? 0 : 1
        }),
      },
      {
        title: "Completion Cost",
        dataIndex: "completion_cost",
        key: "completion_cost",
        editable: (_, record) => record.__type === 'item',
        render: (dom, record) => record.__type === 'group' ? <b>{record.completion_cost}</b> : dom,
        fieldProps: (_form, config) => {
          const completionCostPerUnit = _form.getFieldValue([config.entity.id, 'completion_cost_per_unit'])
          const completionUnits = _form.getFieldValue([config.entity.id, 'completion_days_or_units'])
          const completionCost = Number.parseFloat(`${ completionCostPerUnit || 0 }`)
            * Number.parseFloat(`${ completionUnits || 0 }`)
          _form.setFieldValue([config.entity.id, 'completion_cost'], completionCost.toFixed(2))
          return {
            readOnly: true,
          }
        }
      },
      {
        title: "Total Cost",
        dataIndex: "total_cost",
        key: "total_cost",
        editable: (_, record) => record.__type === 'item',
        render: (dom, record) => record.__type === 'group' ? <b>{record.total_cost}</b> : dom,
        fieldProps: (_form, config) => {
          const drillingCost = _form.getFieldValue([config.entity.id, 'drilling_cost'])
          const completionCost = _form.getFieldValue([config.entity.id, 'completion_cost'])
          const totalCost = Number.parseFloat(`${ drillingCost || 0 }`)
            + Number.parseFloat(`${ completionCost || 0 }`)
          _form.setFieldValue([config.entity.id, 'total_cost'], totalCost.toFixed(2))
          return {
            readOnly: true,
          }
        }
      },
      {
        title: "Operation",
        key: 'ops',
        valueType: "option",
        render: (text, record, _, action) => {
          let ops = [];

          if (record.__type !== 'item') return ops

          ops.push(
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key="editable"
              onClick={() => {
                action?.startEditable?.(record.id);
              }}
              role="button"
            >
              Edit
            </a>
          );
          ops.push(
            <Popconfirm
              title="Sure to delete?"
              onConfirm={(e) => {
                const updatedAFE = {
                  ...activeAFEData,
                  cost_items: activeAFEData.cost_items.map(item => {
                    if (item.id !== record.id) return item
                    return {
                      ...item,
                      drilling_cost_per_unit: null,
                      drilling_days_or_units: null,
                      drilling_cost: null,
                      completion_cost_per_unit: null,
                      completion_days_or_units: null,
                      completion_cost: null,
                      total_cost: null
                    }
                  })
                }
                updateAFE(updatedAFE)
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a role="button">Delete</a>
            </Popconfirm>
          );
          return ops;
        },
      },
    ], [dictValueEnums, activeAFEData, updateAFE])

  const onSaveAFE = useCallback(async (data) => {
    const payload = {
      ...data,
      uwi: wellId,
      cost_items: [...(activeAFEData?.cost_items ?? [])].map(item => {
        const cloned = { ...item }
        delete cloned.afe_id
        return cloned
      })
    }

    await createAFEAsync(payload)

    toggleSaveModal(false)
  }, [activeAFEData?.cost_items, createAFEAsync, toggleSaveModal, wellId])

  const onExportExcel = useCallback(() => {
    if (!activeAFEData || !afeCostItemRecords?.length) {
      Modal.info({
        title: "Info!",
        content: (
          <>
            <p>No data to export.</p>
          </>
        ),
      });
      return;
    }

    const dataColumns = columns.filter(col => col.key !== 'ops')
    const data = [dataColumns.map(col => col.title)]

    afeCostItemRecords.forEach((record, index) => {
      data.push(dataColumns.map(col => record[col.dataIndex] ?? ''))
    })
    exportToSpreadsheet(data, `afe-${ activeAFEData.afe_name }-${ wellId }`)
  }, [activeAFEData, afeCostItemRecords, columns, wellId])

  const onCreateNewAFE = useCallback(async (data, source) => {
    const payload = {
      ...data
    }
    if (source) {
      payload.cost_items = (source.cost_items ?? []).map(item => {
        const cloned = { ...item }
        delete cloned.afe_id
        return cloned
      })
    }

    await createAFEAsync(payload)

    toggleCreateModal(false)
  }, [createAFEAsync, toggleCreateModal])

  const onChangeApprovalStatus = useCallback((status) => {
    setAFEApproved(status)
    if (!activeAFEData || !status || activeAFEData.approval_status === status) return;
    updateAFE({
      ...activeAFEData,
      approval_status: status,
      approval_date: status === 'Yes' ? formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSSS") : null
    })
  }, [activeAFEData, updateAFE])

  const onAddAFEItem = useCallback(async (item) => {
    const newItem = {
      ...item,
      afe_id: activeAFE
    }
    await updateAFEAsync({
      ...activeAFEData,
      cost_items: [
        ...(activeAFEData.cost_items ?? []),
        newItem
      ]
    })

    toggleAddItemModal(false)
  }, [activeAFE, activeAFEData, toggleAddItemModal, updateAFEAsync])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      <SaveAFEModal
        open={saveModalOpened}
        isSubmitting={isCreatingAFE}
        onSave={onSaveAFE}
        onCancel={() => toggleSaveModal(false)}
      />
      <CreateAFEModal
        open={createModalOpened}
        assetId={assetId}
        isSubmitting={isCreatingAFE}
        onSave={onCreateNewAFE}
        onCancel={() => toggleCreateModal(false)}
      />
      <AddAFEItemModal
        open={addItemModalOpened}
        afeDict={afeDict}
        isSubmitting={isUpdating}
        currentCostItems={activeAFEData?.cost_items?.map(item => item.cost_item_id)}
        onSubmit={onAddAFEItem}
        onCancel={() => toggleAddItemModal(false)}
      />
      <div className="vstack gap-2">
        <div className="hstack justify-item-between align-items-center">
          <Select
            loading={isFetchingAFE}
            value={activeAFE}
            onChange={setActiveAFE}
            options={afeOptions}
            dropdownMatchSelectWidth={false}
            placeholder="Select AFE"
          />
          <div className="hstack flex-grow-1 justify-content-center gap-4">
            {activeAFEData ? (
              <>
                <div className="hstack gap-2">
                  <Typography.Text className="text-light">Approved:</Typography.Text>
                  <Select
                    loading={isFetchingAFE || isUpdating}
                    className={afeTableClasses.select}
                    data-disabled={afeApproved === 'Yes'}
                    disabled={afeApproved === 'Yes'}
                    value={afeApproved}
                    onChange={onChangeApprovalStatus}
                    options={[
                      {
                        value: 'Yes',
                        label: 'Yes'
                      },
                      {
                        value: 'No',
                        label: 'No'
                      }
                    ]} />
                </div>
                {activeAFEData.last_modified ? (
                  <Typography.Text className="text-light">Last Status Changed: {formatDate(new Date(activeAFEData.last_modified), 'MM/dd/yyyy HH:mm:ss')}</Typography.Text>
                ) : null}
              </>
            ) : null}
          </div>
          <div className="hstack justify-content-end align-items-center gap-2">
            <Button loading={isFetchingAFE || isFetchingDict} onClick={() => toggleSaveModal(true)}>Save As...</Button>
            <Button loading={isFetchingAFE || isFetchingDict} onClick={onExportExcel}>Export Excel</Button>
            <Button loading={isCreatingAFE} onClick={() => toggleCreateModal(true)}>New AFE</Button>
            {afeApproved === 'No' ? (
              <Button
                loading={isFetchingAFE || isFetchingDict}
                onClick={() => toggleAddItemModal(true)}
              >New Item</Button>
            ) : null}
          </div>
        </div>
        <div className="flex-grow-1">
          <EditableProTable
            rowKey="id"
            loading={isFetchingAFE || isFetchingDict || isUpdating}
            columns={columns}
            value={afeCostItemRecords}
            columnEmptyText=''
            editable={{
              onSave: (key, record, oldRecord) => {
                record.cost_item_id = dictValueEnums[record.item] ? Number.parseInt(record.item) : record.cost_item_id
                const normalizedRecord = omit(record, '__type', 'item', 'index', 'isNewRecord')
                Object.entries(normalizedRecord).forEach(([key, value]) => {
                  if (!value) {
                    normalizedRecord[key] = null
                  }
                })
                if (record.isNewRecord) {
                  delete normalizedRecord.id

                  const updatedAFE = {
                    ...activeAFEData,
                    cost_items: [...activeAFEData.cost_items, normalizedRecord]
                  }

                  updateAFE(updatedAFE)
                  return
                }
                if (!activeAFEData) return

                const updatedAFE = {
                  ...activeAFEData,
                  cost_items: activeAFEData.cost_items.map(item => {
                    if (item.id !== record.id) return item
                    return normalizedRecord
                  })
                }

                updateAFE(updatedAFE)
              }
            }}
            recordCreatorProps={{
              style: {
                display: 'none'
              }
            }}
            className={editableTableClasses.editableTable}
            cardProps={{
              className: editableTableClasses.card
            }}
            tableClassName={editableTableClasses.table}
            scroll={{
              y: 540
            }}
          />
        </div>
      </div>
    </ConfigProvider>
  )
}

export default WellAfeTable