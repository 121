import { EditableProTable } from '@ant-design/pro-components'
import React, { useEffect, useMemo, useState } from 'react'

import classes from './ValueTable.module.scss'
import FormSection from 'components/form-section'
import { Button, Form } from 'antd'

const units = {
  PERMEABILITY: {
    FIELD: '(mD)',
    METRIC: '(mD)'
  },
  EFFECTIVE_THICKNESS: {
    FIELD: '(ft)',
    METRIC: '(m)'
  }
}

const ValueTable = ({ tab, fields = [] }) => {
  const form = Form.useFormInstance()

  const simulationUnit = Form.useWatch(["GENERAL", "unit"])

  const [data, setData] = useState([])

  const fetchData = () => {
    setData(fields.map(field => form.getFieldValue(["RESERVOIR", tab, field.name])))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(
    /**
     * 
     * @returns {import('@ant-design/pro-components').ProColumnType[]}
     */
    () => [
      {
        key: 'REGION',
        dataIndex: 'REGION',
        title: 'Region',
        editable: false
      },
      {
        key: 'POROSITY',
        dataIndex: 'POROSITY',
        title: 'Porosity',
        editable: true,
      },
      {
        key: 'PERMEABILITY',
        dataIndex: 'PERMEABILITY',
        title: `Permeability ${ units.PERMEABILITY[simulationUnit] ?? '' }`,
        editable: true,
      },
      {
        key: 'EFFECTIVE_THICKNESS',
        dataIndex: 'EFFECTIVE_THICKNESS',
        title: `Effective thickness ${ units.EFFECTIVE_THICKNESS[simulationUnit] ?? '' }`,
        editable: true,
      },
      {
        title: "Operation",
        valueType: "option",
        key: "operation",
        width: 160,
        render: (text, record, index, action) => [
          <Button
            key="editable"
            size="small"
            onClick={() => {
              action?.startEditable?.(record.REGION);
            }}
          >
            Edit
          </Button>
        ],
      },
    ]
    , [simulationUnit])

  return (
    <FormSection title="Value">
      <EditableProTable
        options={false}
        pagination={false}
        search={false}
        columns={columns}
        value={data}
        rowKey="REGION"
        sticky={{
          offsetHeader: 32
        }}
        className={classes.tableContainer}
        cardProps={{
          className: classes.card
        }}
        tableClassName={classes.table}
        editable={{
          type: 'multiple',
          actionRender: (row, config, defaultDom) => [
            defaultDom.save,
            defaultDom.cancel,
          ],
          onSave: (rowKey, data, row) => {
            const { index, ...updatedRow } = data
            form.setFieldValue(["RESERVOIR", tab, index], updatedRow)
            fetchData()
          }
        }}
        scroll={{
          y: 380
        }}
        recordCreatorProps={{
          style: {
            display: 'none'
          }
        }}
      />
    </FormSection>
  )
}

export default ValueTable