import { useQuery } from '@tanstack/react-query'
import { Input, Modal, Table } from 'antd'
import orderBy from 'lodash/orderBy'
import React, { useMemo } from 'react'
import { getDictionaryLog } from 'service/dictionary'
import { formatDate } from 'utils/dateUtil'

const LogTable = ({ dictype }) => {
  const { data: logs, isFetching } = useQuery({
    queryKey: ['getDictionaryLog', dictype],
    queryFn: () => getDictionaryLog(dictype),
    gcTime: 0,
    select: res => orderBy(res.data ?? [], (item) => new Date(item.created_date), 'desc')
  })

  const columns = useMemo(() => [
    {
      title: 'Time',
      dataIndex: 'created_date',
      render: (date) => formatDate(date, 'yyyy-MM-dd HH:mm:ss')
    },
    {
      title: 'Action',
      dataIndex: 'action'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (description) => <Input.TextArea readOnly autoSize bordered={false} value={description} />
    }
  ], [])

  return (
    <Table
      loading={isFetching}
      rowKey="id"
      columns={columns}
      dataSource={logs}
      pagination={false}
      scroll={{
        y: 560
      }}
    />
  )
}

const DictionaryLogModal = ({ dictype, ...modalProps }) => {
  return (
    <Modal
      title="Logs"
      okButtonProps={{
        style: {
          display: 'none'
        }
      }}
      cancelText="Close"
      width={720}
      {...modalProps}
    >
      {modalProps.open ? <LogTable dictype={dictype} /> : null}
    </Modal>
  )
}

export default DictionaryLogModal