import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setAlarmDataStructure,
  selectAlarmDataStructure,
} from "features/input/dataManagerSlice";

import NullFile from "./NullFile";
import { getAlarmInputConfig } from "service/dataInput";

function AlarmDataInput(props) {
  const { inputData } = props;
  const [refreshed, setRefreshed] = useState(false);
  const [columnDictionary, setColumnDictionary] = useState([]);

  const alarmDataStructure = useSelector(selectAlarmDataStructure);

  let dispatch = useDispatch();

  function initDataStructure() {
    const initialSelection = inputData["headers"].map((x, index) => {
      let selectedCol = null;
      let header = x.trim().toLowerCase().replace(/\s/g, "_");
      let i = columnDictionary.findIndex((e) => (header.startsWith(e.header) || e.header.startsWith(header) || header.includes(e.header) || e.header.includes(header)));
      if (i !== -1) {
        selectedCol = columnDictionary[i].header;
      } else {
        selectedCol = columnDictionary[0].header;
      }
      return selectedCol;
    });
    dispatch(setAlarmDataStructure(initialSelection));
  }

  useEffect(() => {
    if (!refreshed) {
      refreshData();
      setRefreshed(true);
    }
  }, []);

  useEffect(() => {
    if (
      inputData !== null &&
      columnDictionary !== null
    ) {
      initDataStructure();
    }
  }, [inputData, columnDictionary]);

  function refreshData() {
    getAlarmInputConfig(
      (result) => {
        const columns = ["N/A", ...result.columns];
        setColumnDictionary(
          columns.map((x) => {
            return {
              header: x
            }
          }));
      },
      (error) => {
        console.error(error);
      }
    );
  }

  function handleChangeColumn(e, i) {
    const unpacked = alarmDataStructure.map((el, index) => {
      if (index === i) {
        return e.target.value;
      } else {
        return el;
      }
    });
    dispatch(setAlarmDataStructure(unpacked));
  }

  return (
    <div className="table-container d-flex flex-column justify-content-start align-items-start">
      {inputData == null || alarmDataStructure == null ? (
        <NullFile />
      ) : (
        <>
          <div className="table-header">
            {inputData["headers"].map((x, i) => {
              return (
                <div className="header-cell" key={i}>
                  {x}
                </div>
              );
            })}
          </div>
          <div className="table-header">
            {inputData["headers"].map((x, i) => {
              return (
                <div className="header-cell" key={i}>
                  <select
                    id={`attribute_id${i}`}
                    onChange={(e) => {
                      handleChangeColumn(e, i);
                    }}
                    value={alarmDataStructure[i]}
                  >
                    {columnDictionary.map((y, j) => {
                      return (
                        <option key={i + "_" + j} value={y.header}>
                          {y.header}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
            })}
          </div>
          <div className="table-body">
            {inputData["data"].map((el, index) => {
              return (
                <div className="data-row" key={index}>
                  {el.map((val, subIndex) => {
                    return (
                      <div className="data-cell" key={index + "_" + subIndex}>
                        {val}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default AlarmDataInput;