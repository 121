import { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";

function DcaManagementHistogram(props) {
  const { xSeries, dataSeries } = props;
  const [chartCategory, setChartCategory] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (
      xSeries == null || dataSeries == null
    ) {
      return;
    }

    setChartCategory(xSeries);
    setChartData(dataSeries);
  }, [xSeries, dataSeries]);

  function getOptions() {
    let options = {
      title: {

        text: " ",
        left: 'center',
        textStyle: {
          color: "#ccc",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: chartCategory,
        axisTick: {
          interval: 0,
        },
        axisLabel: {
          interval: 0,
          formatter: function (value, index) {
            if (value.length > 6 && index > 0) {
              return value.slice(0, 5) + '..'
            } else return value
          }
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: chartData,
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
        },
      ],
    };

    return options;
  }
  return (
    <>
      <ReactECharts option={getOptions()} style={{ height: "100%", width: "100%" }} />
    </>
  );
}

export { DcaManagementHistogram };
