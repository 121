import { get, post, remove } from "./base";

export const ProjectTypes = [
  {
    value: "dca",
    name: "Decline Analysis",
    url: "/decline_analysis/analyzer/",
  },
  {
    value: "type_curve",
    name: "Type Curve Analysis",
    url: "/type_curve/analyzer/",
  },
  { value: "well_planner", name: "Well Planner", url: "/well_planner/" },
  {
    value: "dca_management",
    name: "Decline Management",
    url: "/decline_analysis/management/",
  },
];

export function parseProjectId(value) {
  if (value != null) {
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string") {
      return parseInt(value);
    }
  }
  return value;
}

export function buildProjects(projects) {
  const projectsDataMap = new Map();
  if (!projects) {
    return projectsDataMap;
  }

  for (const project of projects) {
    let projectsData = null;
    if (!projectsDataMap.has(project.project_type)) {
      const projectOption = ProjectTypes.find(
        (item) => item.value === project.project_type
      );
      projectsData = {
        title: projectOption.name,
        url: projectOption.url,
        sectionData: [],
      };
      projectsDataMap.set(project.project_type, projectsData);
    }
    projectsData = projectsDataMap.get(project.project_type);
    projectsData.sectionData.push(project);
  }
  return projectsDataMap;
}

export function getProjects(projectType, onSuccess, onError) {
  let params = {};
  if (projectType) {
    params.project_type = projectType;
  }
  get(
    "/api/projects/query",
    params,
    {},
    (res) => {
      const projects = projectType
        ? res.data.filter((project) => project.project_type === projectType)
        : res.data;
      onSuccess(projects);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function createProject(project, onSuccess, onError) {
  post(
    "/api/projects/",
    project,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function deleteProject(projectId, onSuccess, onError) {
  remove(
    `/api/projects/${projectId}`,
    null,
    null,
    {},
    (res) => {
      onSuccess();
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function getProjectDetail(projectId, onSuccess, onError) {
  get(
    `/api/projects/${projectId}`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function saveDcaProjectDetail(projectData, onSuccess, onError) {
  post(
    "/api/dca/main/",
    projectData,
    null, //{ type: "dca" },
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

// export function saveTypeCurveProjectDetail(projectData, onSuccess, onError) {
//   post(
//     "/api/projects/",
//     projectData,
//     { type: "type_curve" },
//     {},
//     (res) => {
//       onSuccess(res.data);
//     },
//     (error) => { console.error(error); onError(error) },
//     true
//   );
// }

export function saveDcaManagementProjectDetail(
  projectData,
  onSuccess,
  onError
) {
  post(
    "/api/dca/management/",
    projectData,
    null, // { type: "dca_management" },
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}
