import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  project: null,

  product: "oil_prd_rate",

  dataType: "wells", // wells, asset
  selectedWells: [],
  selectedWellIds: [],

  allParams: null,
  availableParamsData: null, // well uwi => input x
  availableWellParams: null, // available params for all wells
  selectedWellParams: null, // available params for all wells
  interpoParams: null,

  usedWellInterpoParams: null,
  activeMapUsedParam: null,

  gridParams: null, // grid parameters saved
  gridType: null, // grid type user current selected

  customWells: null, // user created
  customWellScreenParams: null,
  updatedCustomWells: null,

  gridData: null, // grid x y values, update when map zoomed, moved.
  wellGridProperties: null, // interpolated data of grid, index by i,j, not x y coordinates.
  reInterpolate: false, // recompute interpolate params.

  showWellSettingsWin: false,

  activeParam: null,

  wellLatLng: `{"x0":0, "y0":0}`, // lat, lng of well
};

const plannerProjectSlice = createSlice({
  name: "well_planner",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setDataType: (state, action) => {
      state.dataType = action.payload;
    },
    setSelectedWells: (state, action) => {
      state.selectedWells = action.payload;
    },
    setSelectedWellIds: (state, action) => {
      state.selectedWellIds = action.payload;
    },
    setAllParams: (state, action) => {
      state.allParams = action.payload;
    },
    setAvailableParamsData: (state, action) => {
      state.availableParamsData = action.payload;
    },
    setAvailableWellParams: (state, action) => {
      state.availableWellParams = action.payload;
    },
    setSelectedWellParams: (state, action) => {
      state.selectedWellParams = action.payload;
    },
    setInterpoParams: (state, action) => {
      state.interpoParams = action.payload;
    },
    setGridParams: (state, action) => {
      state.gridParams = action.payload;
    },
    setGridType: (state, action) => {
      state.gridType = action.payload;
    },
    setUsedWellInterpoParams: (state, action) => {
      state.usedWellInterpoParams = action.payload;
    },
    setActiveMapUsedParam: (state, action) => {
      state.activeMapUsedParam = action.payload;
    },
    setCustomWells: (state, action) => {
      state.customWells = action.payload;
    },
    setCustomWellScreenParams: (state, action) => {
      state.customWellScreenParams = action.payload;
    },
    setUpdatedCustomWells: (state, action) => {
      state.updatedCustomWells = action.payload;
    },
    setGridData: (state, action) => {
      state.gridData = action.payload;
    },
    setWellGridProperties: (state, action) => {
      state.wellGridProperties = action.payload;
    },
    setReInterpolate: (state, action) => {
      state.reInterpolate = action.payload;
    },
    setShowWellSettingsWin: (state, action) => {
      state.showWellSettingsWin = action.payload;
    },

    setActiveParam: (state, action) => {
      state.activeParam = action.payload;
    },
    setWellLatLng: (state, action) => {
      state.wellLatLng = action.payload;
    },
  },
});

export const {
  reset,
  setProject,
  setProduct,
  setDataType,
  setSelectedWells,
  setSelectedWellIds,
  setAllParams,
  setAvailableParamsData,
  setAvailableWellParams,
  setSelectedWellParams,
  setInterpoParams,
  setGridParams,
  setGridType,
  setUsedWellInterpoParams,
  setActiveMapUsedParam,
  setCustomWells,
  setCustomWellScreenParams,
  setUpdatedCustomWells,
  setGridData,
  setWellGridProperties,
  setReInterpolate,
  setShowWellSettingsWin,
  setActiveParam,
  setWellLatLng,
} = plannerProjectSlice.actions;

export const selectProject = (state) => state.well_planner.project;
export const selectProduct = (state) => state.well_planner.product;
export const selectDataType = (state) => state.well_planner.dataType;
export const selectSelectedWells = (state) => state.well_planner.selectedWells;
export const selectSelectedWellIds = (state) => state.well_planner.selectedWellIds;
export const selectAllParams = (state) => state.well_planner.allParams;
export const selectAvailableParamsData = (state) => state.well_planner.availableParamsData;
export const selectAvailableWellParams = (state) => state.well_planner.availableWellParams;
export const selectSelectedWellParams = (state) => state.well_planner.selectedWellParams;
export const selectInterpoParams = (state) => state.well_planner.interpoParams;
export const selectGridParams = (state) => state.well_planner.gridParams;
export const selectGridType = (state) => state.well_planner.gridType;
export const selectUsedWellInterpoParams = (state) => state.well_planner.usedWellInterpoParams;
export const selectActiveMapUsedParam = (state) => state.well_planner.activeMapUsedParam;
export const selectCustomWells = (state) => state.well_planner.customWells;
export const selectCustomWellScreenParams = (state) => state.well_planner.customWellScreenParams;
export const selectUpdatedCustomWells = (state) => state.well_planner.updatedCustomWells;
export const selectGridData = (state) => state.well_planner.gridData;
export const selectWellGridProperties = (state) => state.well_planner.wellGridProperties;
export const selectReInterpolate = (state) => state.well_planner.reInterpolate;
export const selectShowWellSettingsWin = (state) => state.well_planner.showWellSettingsWin;
export const selectActiveParam = (state) => state.well_planner.activeParam;
export const selectWellLatLng = (state) => state.well_planner.wellLatLng;

export default plannerProjectSlice.reducer;
