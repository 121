import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Tooltip } from 'antd';

import {
    selectActiveProject,
} from "features/project/projectsSlice";

import { getProjectDetail } from "service/projects";

const PpSelector = (props) => {
    let { dataList, onOptionSelected, needSort, selected, } = props;
    const activeProject = useSelector(selectActiveProject);

    const [wellsList, setWellsList] = useState(null);
    useEffect(() => {
        const handleOptionSelected = (targetId, targetStatus) => {
            onOptionSelected && onOptionSelected(targetId, targetStatus);
        }

        let demoList;
        if (!dataList || !Array.isArray(dataList))
            return;
        else demoList = [...dataList];
        needSort && (demoList = demoList.sort((a, b) => {
            return (a.label || a.name || a) < (b.label || b.name || b) ? -1 : 1;
        }))
        setWellsList(demoList.map((item, index) => {
            let showText = item.label || item;
            showText.length > 10 && (showText = (showText.slice(0, 9) + '...'));
            return (
                <div
                    id={index}
                    key={index}
                    style={{
                        height: '20px',
                        justifyContent: 'left',
                        alignContent: 'left',
                        alignItems: 'left',
                        color: 'rgba(255,255,255,0.5)'
                    }}
                    value={item.label}
                >
                    <Checkbox
                        style={{
                            width: '100%',
                            color: 'rgba(255,255,255,0.5)',
                            alignSelf: 'flex-end',
                        }}
                        name={item.name}
                        checked={selected ? selected.find(sel => typeof sel === "string" ? sel === item.name : sel.name === item.name) : false}
                        onClick={(e) => {
                            handleOptionSelected(item, e.target.checked);
                        }}
                    >
                        <Tooltip
                            arrow
                            title={(item && item.logType) ? item.logType + " : " + item.description : null}
                            placement="right"
                        >
                            <span>{showText}</span>
                        </Tooltip>
                    </Checkbox>
                </div>
            );
        }));
    }, [dataList, onOptionSelected, needSort, selected]);

    useEffect(() => {
        const handleOptionSelected = (targetId, targetStatus) => {
            onOptionSelected && onOptionSelected(targetId, targetStatus);
        }

        if (!activeProject) return;

        getProjectDetail(
            activeProject.id,
            ({ success, wells, custom_data }) => {
                if (success !== true) {
                    alert("get project detail error!");
                    return;
                }
                wells && setWellsList(wells.map((well, index) => {
                    let className = "select-option d-flex justify-content-center align-items-start";

                    return (
                        <div
                            id={well.well_uwi}
                            key={index}
                            className={className}
                            value={well.well_uwi}
                            onClick={(e) => {
                                handleOptionSelected(e.target.value);
                            }}
                        >
                            <span>{well.well_name}</span>
                        </div>
                    );
                }));
            },
            (e) => {
                alert("get project detail error!");
                console.error("get project detail error!", e);
            })
    }, [activeProject, onOptionSelected]);

    return (
        <Fragment>
            <div className="selector d-flex flex-column w-100 h-100">
                {
                    props.title && <div
                        className="selector-select d-flex flex-column align-items-stretch"
                        style={{
                            fontSize: '15px',
                            color: 'rgba(255,255,255,0.8)',
                            background: 'rgba(0, 0, 0, 0.2)'
                        }}
                    >
                        {props.title}
                    </div>

                }
                <div style={{
                    height: '100%',
                    color: '#ccc',
                    alignItems: 'left',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
                >
                    {(wellsList && wellsList.length > 0) ? wellsList : 'Please select wells above...'}
                </div>
                {props.children}
            </div>
        </Fragment>
    )
};
export default PpSelector;