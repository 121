import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ProForm, ProFormDatePicker, ProFormSelect, ProFormText, ProFormSlider } from '@ant-design/pro-form';
import { Button, ConfigProvider, Divider, Form, Typography, message, theme } from 'antd';
import en_US from 'antd/locale/en_US';
import { DeclineModelOptions } from 'app/codes';
import Draggable from "react-draggable";
import locale from 'antd/es/date-picker/locale/en_US';

const options = DeclineModelOptions.slice(1, 3).map(({ name, value }) => ({ label: name, value: value }));

export const useUserPreference = () => {
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const showUserPreference = useCallback(() => {
        setVisible(true);
    }, []);

    const hideUserPreference = useCallback(() => {
        setVisible(false);
    }, []);

    const UserPreference = (props) => {
        const { recommendData, userTuneData, onClose, hasDate, defaultParameters, maxMonths } = props;
        const [hasBValue, setHasBValue] = useState(false);
        const [ipRatioRange] = useState([-10, 10, { "-10": "-10", "10": "10" }, 0.01]);
        const [bRange] = useState([0, 10, { "0": "0", "10": "10" }, 0.01]);
        const [declineRateRange, setDeclineRateRange] = useState([0, 10000, { "0": "0", "10000": "10000" }, 100]);
        const themes = {
            algorithm: theme.darkAlgorithm,
        };

        useEffect(() => {
            form.setFieldsValue({
                ...recommendData,
                ...userTuneData,
                ipRatioSlider: userTuneData.ipRatio,
                declineRateSlider: userTuneData.declineRate,
                bSlider: userTuneData.b,
            })
            setHasBValue(userTuneData.declineModel === "HYP")

            if (!defaultParameters[0]?.model_name) return;

            let range = { "0": "0" }, max = (parseInt(Math.max(
                defaultParameters?.find(item => item.model_name === "HYP")?.model_params.qi,
                defaultParameters?.find(item => item.model_name === "EXP")?.model_params.qi
            ) / 100) + 1) * 200;
            range[max] = max;

            setDeclineRateRange([0, max, range, parseInt(max / 200)])
        }, [recommendData, userTuneData, defaultParameters])
        const [position, setPosition] = useState({ x: -100, y: -250 }); // 设置初始位置
        const ref = useRef(null); // 创建一个 ref

        const onStart = useCallback(() => {
        }, []);

        const onDrag = useCallback((e, data) => {
            setPosition({ x: data.x, y: data.y }); // 更新位置
        }, []);

        const onStop = useCallback(() => {
        }, []);

        const [modelSelected, setModelSelected] = useState(false);

        return (
            <Draggable
                position={position} // 设置位置
                onStart={onStart} // 设置拖动开始的回调函数
                onDrag={onDrag} // 设置拖动过程中的回调函数
                onStop={onStop} // 设置拖动结束的回调函数
            >
                <div ref={ref}
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: '1px solid #ccc',
                        width: '600px',
                        backgroundColor: 'rgba(23,30,50, 0.97)',
                        borderRadius: '10px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        overflow: 'auto',
                        display: visible ? 'block' : 'none',
                        zIndex: 1000,
                        padding: '2em',
                        cursor: 'grab'
                    }}>
                    <ConfigProvider theme={themes} locale={en_US}>
                        <Typography.Title level={4}>User Preference</Typography.Title>
                        <ProForm
                            form={form}
                            onReset={(values) => { form.resetFields() }}
                            onFinish={(values) => {
                                if ("b" in values && (!values.b || parseFloat(values.b) === 0.0)) {
                                    message.error("Please input b value with non-zero number")
                                    return;
                                }
                                onClose ? onClose(values) : console.log(values);
                                return Promise.resolve();
                            }}
                            grid={true}
                            labelAlign="left"
                            layout="horizontal"
                            initialValues={{
                                ...recommendData,
                                ...userTuneData,
                            }}
                            onValuesChange={(changedValues, values) => {
                                if (!values.declineModel) {
                                    setModelSelected(false);
                                    form.setFieldsValue({
                                        ipRatio: null,
                                        b: null,
                                        declineRate: null,
                                    });
                                }
                                if (changedValues.declineModel === "HYP") {
                                    setHasBValue(true);
                                    setModelSelected(true);
                                    if (!defaultParameters) return;
                                    const { qi, di, b } = defaultParameters.find(item => item.model_name === "HYP")?.model_params || {}
                                    !values.b && form.setFieldsValue({
                                        "b": b?.toFixed(4),
                                        "bSlider": b?.toFixed(4),
                                    });
                                    !values.declineRate && form.setFieldsValue({
                                        "declineRate": qi?.toFixed(3),
                                        "declineRateSlider": qi?.toFixed(3),
                                    });
                                    !values.ipRatio && form.setFieldsValue({
                                        "ipRatio": di?.toFixed(3),
                                        "ipRatioSlider": di?.toFixed(3),
                                    });
                                    !values.date && form.setFieldsValue({
                                        "date": values.dateValue,
                                    });
                                }
                                if (changedValues.declineModel === "EXP") {
                                    setHasBValue(false)
                                    setModelSelected(true);
                                    if (!defaultParameters) return;
                                    const { qi, di } = defaultParameters.find(item => item.model_name === "EXP")?.model_params || {}
                                    !values.declineRate && form.setFieldsValue({
                                        "declineRate": qi?.toFixed(3),
                                        "declineRateSlider": qi?.toFixed(3),
                                    });
                                    !values.ipRatio && form.setFieldsValue({
                                        "ipRatio": di?.toFixed(3),
                                        "ipRatioSlider": di?.toFixed(3),
                                    });
                                    !values.date && form.setFieldsValue({
                                        "date": values.dateValue,
                                    });
                                }

                                if (changedValues.ipRatio) {
                                    form.setFieldsValue({
                                        ipRatioSlider: changedValues.ipRatio,
                                    });
                                }
                                if (changedValues.ipRatioSlider) {
                                    form.setFieldsValue({
                                        ipRatio: changedValues.ipRatioSlider,
                                    });
                                }
                                if (changedValues.declineRate) {
                                    form.setFieldsValue({
                                        declineRateSlider: changedValues.declineRate,
                                    });
                                }
                                if (changedValues.declineRateSlider) {
                                    form.setFieldsValue({
                                        declineRate: changedValues.declineRateSlider,
                                    });
                                }
                                if (changedValues.b) {
                                    form.setFieldsValue({
                                        bSlider: changedValues.b,
                                    });
                                }
                                if (changedValues.bSlider) {
                                    form.setFieldsValue({
                                        b: changedValues.bSlider,
                                    });
                                }
                            }}
                            style={{ colorText: 'white' }}
                            submitter={{
                                searchConfig: {
                                    submitText: "Apply",
                                },
                                render: (props, dom) => [
                                    <Button key="close" onClick={() => hideUserPreference()}>
                                        Close
                                    </Button>,
                                    dom[1],
                                ],
                            }}
                        >
                            <ProForm.Group style={{ height: '30px' }}>
                                <ProFormText
                                    name="aa"
                                    label=" "
                                    labelCol={{ span: 14 }}
                                    readonly
                                    colProps={{ span: 10 }}
                                    value="Recommend"
                                    colon={false}
                                    labelAlign="none"
                                />
                                <ProFormText
                                    name="bb"
                                    label=" "
                                    labelCol={{ span: 2 }}
                                    colon={false}
                                    readonly
                                    colProps={{ span: 10 }}
                                    value="User Tuned"
                                    labelAlign="none"
                                />
                            </ProForm.Group>
                            <ProForm.Group style={{ height: '50px' }}>
                                <ProFormText
                                    name="declineModelValue"
                                    label="Decline Model"
                                    labelCol={{ span: 14 }}
                                    placeholder="Please enter the decline model value"
                                    readonly
                                    colProps={{ span: 10 }}
                                    style={{ width: '350px', color: 'white' }}
                                    value=""
                                    labelAlign="left"
                                />
                                <ProFormSelect
                                    name="declineModel"
                                    label=" "
                                    colon={false}
                                    options={options}
                                    allowClear={true}
                                    colProps={{ span: 7 }}
                                    placeholder="Please select a decline model"
                                    rules={[{ required: false, message: 'Please select a decline model' }]}
                                    style={{ width: 150, color: 'white', backgroundColor: 'black' }}
                                />
                            </ProForm.Group>
                            <ProForm.Group style={{ height: '50px' }}>
                                <ProFormText
                                    name="ipRatioValue"
                                    label="IP Ratio"
                                    labelCol={{ span: 14 }}
                                    readonly
                                    style={{ width: 150 }}
                                    labelAlign="left"
                                    colProps={{ span: 10 }}
                                />
                                <ProFormText
                                    name="ipRatio"
                                    label=" "
                                    colon={false}
                                    rules={[{ required: modelSelected, message: 'Please enter the IP ratio' }]}
                                    type="number"
                                    colProps={{ span: 7 }}
                                    labelAlign="left"
                                />
                                <ProFormSlider
                                    name='ipRatioSlider'
                                    min={ipRatioRange[0]}
                                    max={ipRatioRange[1]}
                                    colProps={{ span: 6, offset: 1 }}
                                    initialValue={values => values.ipRatio}
                                    marks={ipRatioRange[2]}
                                    labelAlign="none"
                                    step={ipRatioRange[3] || 0.01}
                                />
                            </ProForm.Group>
                            <ProForm.Group style={{ height: '50px' }}>
                                <ProFormText
                                    name="declineRateValue"
                                    label="Initial Decline Rate"
                                    labelCol={{ span: 14 }}
                                    readonly
                                    labelAlign="left"
                                    colProps={{ span: 10 }}
                                />
                                <ProFormText
                                    name="declineRate"
                                    label=" "
                                    colon={false}
                                    rules={[{ required: modelSelected, message: 'Please enter the decline rate' }]}
                                    colProps={{ span: 7 }}
                                    type="number"
                                    labelAlign="left"
                                />
                                <ProFormSlider
                                    name='declineRateSlider'
                                    colProps={{ span: 6, offset: 1 }}
                                    min={declineRateRange[0]}
                                    max={declineRateRange[1]}
                                    initialValue={values => values.declineRate}
                                    marks={declineRateRange[2]}
                                    step={declineRateRange[3]}
                                    labelAlign="none"
                                />
                            </ProForm.Group>
                            <ProForm.Group style={{ height: '48px' }}>
                                <ProFormText
                                    name="bValue"
                                    label="b"
                                    labelCol={{ span: 14 }}
                                    readonly
                                    colProps={{ span: 10 }}
                                    labelAlign="left"
                                    style={{ height: '48px' }}
                                />
                                {
                                    hasBValue && <>
                                        <ProFormText
                                            name="b"
                                            label=" "
                                            colon={false}
                                            rules={[{ required: false, message: 'Please enter the b value' }]}
                                            colProps={{ span: 7 }}
                                            type="number"
                                            labelAlign="left"
                                            style={{ height: '48px' }}
                                        />
                                        <ProFormSlider
                                            name='bSlider'
                                            min={bRange[0]}
                                            max={bRange[1]}
                                            initialValue={values => values.b}
                                            marks={bRange[2]}
                                            step={bRange[3]}
                                            colProps={{ span: 6, offset: 1 }}
                                            labelAlign="none"
                                            value={values => values.b.toFixed(3)}
                                            style={{ height: '48px' }}
                                        />
                                    </>
                                }
                            </ProForm.Group>
                            {
                                hasDate && <>
                                    <Divider style={{ margin: '12px' }} />
                                    <ProForm.Group style={{ height: '50px' }}>
                                        <ProFormDatePicker
                                            name="dateValue"
                                            label="Date Started Decline"
                                            readonly
                                            format="MM/DD/YYYY"
                                            locale={locale}
                                            colProps={{ span: 10 }}
                                            labelAlign="left"
                                        />
                                        <ProFormDatePicker
                                            name="date"
                                            label=" "
                                            colon={false}
                                            rules={[{ required: false, message: 'Please enter the start date' }]}
                                            format="MM/DD/YYYY"
                                            locale={locale}
                                            colProps={{ span: 8 }}
                                        />
                                    </ProForm.Group>
                                    <ProForm.Group style={{ height: '50px' }}>
                                        <ProFormText
                                            name='months'
                                            label='Validation'
                                            rules={[
                                                { required: false, message: 'Please enter the validation value' },
                                                {
                                                    type: 'number',
                                                    max: maxMonths || 100,
                                                    validator: (rule, value) => {
                                                        if (value > (maxMonths || 100)) {
                                                            return Promise.reject('must <= ' + (maxMonths || 100));
                                                        } else if (value < 0) {
                                                            return Promise.reject('must > 0');
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                }
                                            ]}
                                            colProps={{ span: 8 }}
                                            value={values => (values.months || 2)}
                                        />
                                        <ProFormText
                                            name='monthsUnitText'
                                            rules={[{ required: false, message: 'Please enter the validation value' }]}
                                            colProps={{ span: 8 }}
                                            value={"Months"}
                                            readonly
                                        />

                                    </ProForm.Group>
                                </>
                            }
                        </ProForm>
                    </ConfigProvider>
                </div>
            </Draggable>
        );
    };

    return [UserPreference, showUserPreference, hideUserPreference];
};
