import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectActiveWells,
  selectActiveAssetId,
} from "features/asset/assetSelectorSlice";
import {
  setManagementGlobal,
  selectManagementGlobal,
  selectDeclinePhase,
} from "features/decline_analysis/dcaManagementSlice";

import { getTotalProductionsData } from "service/productionData";
import { getMapFeatures } from "utils/map";
import LMap from "components/map/Map";
import SectionSetting from "components/common/SectionSetting";

const DECLINE_PHASE_OPTIONS = [
  { name: "Cumulative Oil", value: "oil" },
  { name: "Cumulative Gas", value: "gas" },
  { name: "Cumulative Liquid", value: "liquid" },
];

const PRODUCT_OPTIONS = {
  oil: "oil_prd_rate",
  gas: "gas_prd_rate",
  liquid: "oil_prd_rate+water_prd_rate",
};

const zoom = 15;

const WellsMap = (props) => {
  const { selectedWellIds, onWellSelected, onWellsSelected } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const dispatch = useDispatch();
  const assetWells = useSelector(selectActiveWells);
  const activeAssetId = useSelector(selectActiveAssetId);
  const declinePhase = useSelector(selectDeclinePhase);
  const managementGlobal = useSelector(selectManagementGlobal);
  const [productionsData, setProductionsData] = useState(null);

  const [features, setFeatures] = useState([]);
  const [center, setCenter] = useState([0.0, 46.036]);
  const [bounds, setBounds] = useState(null);
  const chartType = declinePhase || "liquid";

  const refreshData = useCallback(
    (assetId, product) => {
      let products = [product];
      let plus = false;
      if (product.indexOf("+") !== -1) {
        plus = true;
        products = product.split("+");
      }
      showLoadingIcon && showLoadingIcon();
      getTotalProductionsData(
        null,
        assetId,
        products,
        null,
        null,
        (result) => {
          if (plus) {
            let data = {};
            for (let wellId of Object.keys(result)) {
              let prdData = result[wellId];
              data[wellId] = {};
              let prdValue = 0;
              for (let prdKey of Object.keys(prdData)) {
                prdValue += prdData[prdKey];
              }
              data[wellId][product] = prdValue;
            }
            setProductionsData(data);
          } else {
            setProductionsData(result);
          }
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [hideLoadingIcon, showLoadingIcon]
  );

  useEffect(() => {
    if (!chartType || !activeAssetId) return;
    const product = PRODUCT_OPTIONS[chartType];
    refreshData(activeAssetId, product);
    // eslint-disable-next-line
  }, [activeAssetId, chartType]);

  useEffect(() => {
    if (productionsData && assetWells && chartType) {
      const product = PRODUCT_OPTIONS[chartType];
      const [tempFeatures, mapCenter, mapBounds] = getMapFeatures(
        productionsData,
        assetWells,
        product
      );

      if (!tempFeatures || isNaN(tempFeatures[0]?.Value)) return;
      setFeatures(tempFeatures);
      setCenter(mapCenter);
      setBounds(mapBounds);
    }
  }, [productionsData, assetWells, chartType]);

  function handleObjectiveSelected(objective) {
    let well = assetWells.find((well) => well.uwi === objective.apiId);
    onWellSelected(well);
  }

  function handleAreaSelected(objectives) {
    if (objectives && objectives.length > 0) {
      let tempWells = objectives.map((objective) => {
        return assetWells.find((well) => well.uwi === objective.apiId);
      });
      onWellsSelected(tempWells);
    }
  }

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Map</span>
        <div className="header-menu-container d-flex flex-row">
          <select
            name="chartType"
            value={managementGlobal.declinePhase}
            onChange={(e) => {
              let value = e.target.value;
              dispatch(
                setManagementGlobal({
                  ...managementGlobal,
                  declinePhase: value,
                })
              );
            }}
          >
            {DECLINE_PHASE_OPTIONS.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content">
        <div className="chart-container">
          <LMap
            objectives={features ? features : []}
            center={center}
            zoom={zoom}
            bounds={bounds}
            selectedObjectiveIds={
              selectedWellIds && selectedWellIds.length > 0
                ? new Set(selectedWellIds)
                : new Set()
            }
            objectiveSelectedHandler={handleObjectiveSelected}
            areaSelectedHandler={handleAreaSelected}
            selectRed={true}
          />
        </div>
      </div>
    </div>
  );
};

export default WellsMap;
