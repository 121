import { get, post, remove } from "./base";

export function getInjections(
  queryData,
  onSuccess,
  onError
) {
  get(
    "/api/injections/editor/",
    queryData,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function saveInjections(
  inputData,
  onSuccess,
  onError
) {
  post(
    "/api/injections/editor/",
    inputData,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError,
    true
  );
}

export function removeInjections(
  inputData,
  onSuccess,
  onError
) {
  remove(
    "/api/injections/editor/",
    inputData,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}