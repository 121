import { get, post, put } from "./base";
import { apiDelete } from "./petrophysics";
// import { apiDelete } from "./petrophysics";

export function getWellSummary(assetId, onSuccess, onError) {
  return get(
    `/api/assets/${ assetId }/wells`,
    null,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => { console.error(error); onError?.(error) }
  );
}

export function saveWellSummary(well, onSuccess, onError) {
  return post(
    "/api/wells/",
    well,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

export function updateWellSummary(well, onSuccess, onError) {
  const uwi = well.uwi;
  return put(
    `/api/wells/${ uwi }`,
    well,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

export function getWellStatus(assetId, onSuccess, onError) {
  return get(
    `/api/assets/${ assetId }/wells/status`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getWellCategory(assetId, onSuccess, onError) {
  return get(
    `/api/assets/${ assetId }/wells/category`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function searchWells(q) {
  return get(
    '/api/search/wells',
    { q }
  )
}

export function getWellPerformanceMetrics(uwi) {
  return get(
    `/api/economics/well/${ uwi }/performance/metrics`,
  )
}

export function deleteWell(wellId, onSuccess, onError) {
  apiDelete(
    `/api/wells/${wellId}`,
    null,
    onSuccess || (() => { }),
    onError || (() => { }));
}

// export function deleteWells(wellnames, onSuccess, onError) {
//   if (!wellnames || wellnames.length === 0) {
//     console.warn("deleteWells: found null parameters!");
//     return;
//   }
//   apiDelete(
//     "/api/wells/",
//     {
//       "uwis": wellnames
//     },
//     onSuccess || (() => { }),
//     onError || (() => { }));
// }