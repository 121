import { get, post } from "./base";

export function getWellAvailableParams(data, onSuccess, onError) {
  post(
    "/api/wells/available_params/",
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getDcaManagementAvailableParams(data, onSuccess, onError) {
  get(
    "/api/dca/available_params/",
    data,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getMLInputParams(data, onSuccess, onError) {
  get(
    "/api/ml/input_params/",
    { category: data.category },
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function prepareWellData(onSuccess, onError) {
  post(
    "/api/wells/prepare_data/",
    null,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}


export function computeWellParamsCorrelation(paramsData, onSuccess, onError) {
  post(
    "/api/wells/available_params/correlation/",
    paramsData,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}
