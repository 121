import { useSelector } from "react-redux";
import {
  selectWellById,
  selectAssetById,
} from "features/asset/assetSelectorSlice";

function WellSummaryTable(props) {
  const { assetId, wellId } = props;
  let well = useSelector((state) => selectWellById(state, wellId));
  let asset = useSelector((state) => selectAssetById(state, assetId));

  return (
    asset &&
    well && (
      <div className="d-flex flex-column justify-content-between">
        <div className="summary-row">
          <span className="summary-item">Well Name</span>
          <span className="summary-item">{well.well_name}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">API / UWI</span>
          <span className="summary-item">{well.uwi}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Asset Name</span>
          <span className="summary-item">{asset.asset_name}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Well Type</span>
          <span className="summary-item">{well.well_type}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Lease Name</span>
          <span className="summary-item">{well.lease_name}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Drill Type</span>
          <span className="summary-item">{well.drill_type}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">County</span>
          <span className="summary-item">{well.county}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">State</span>
          <span className="summary-item">{well.state}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Basin</span>
          <span className="summary-item">{well.basin}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Reservoir</span>
          <span className="summary-item">{well.reservoir}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Measured Depth</span>
          <span className="summary-item">{well.measured_depth}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Production Type</span>
          <span className="summary-item">{well.production_type}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Artificial Lift Type</span>
          <span className="summary-item">{well.artificial_lift_type}</span>
        </div>
        <div className="summary-row">
          <span className="summary-item">Spud Date</span>
          <span className="summary-item">{well.spud_date}</span>
        </div>
      </div>
    )
  );
}

export default WellSummaryTable;
