import { convertToImperialDate } from "app/codes";
import React, { useEffect, useState } from "react";
import { getAlarms, getAssetAlarms, EVENT_STATUS_MAP } from "service/events";

function AlarmTable(props) {
  const { wellId, assetId } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const [data, setData] = useState(null);

  useEffect(() => {
    if (wellId == null && assetId == null) {
      return;
    }
    showLoadingIcon && showLoadingIcon();
    if (wellId != null) {
      getAlarms(
        wellId,
        (result) => {
          result.sort((x1, x2) => {
            return x1.id - x2.id;
          });
          setData(result);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    } else if (assetId != null) {
      getAssetAlarms(
        assetId,
        (result) => {
          result.sort((x1, x2) => {
            return x1.id - x2.id;
          });
          setData(result);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    }
  }, [wellId, assetId, hideLoadingIcon, showLoadingIcon]);

  return (
    <>
      <table className="table table-borderless table-centered table-nowrap">
        <thead className="">
          <tr>
            <th style={{ width: "1.25rem" }}>
              <div className="d-flex flex-row align-items-center form-check align-middle">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                />
                <label className="form-check-label" htmlFor="customCheck1">
                  &nbsp;
                </label>
              </div>
            </th>
            <th>#</th>
            <th>Asset</th>
            <th>Well Name</th>
            <th>Event Type</th>
            <th>Date</th>
            <th>Status</th>
            <th>Severity</th>
            <th>Reporter</th>
            <th>Contact</th>
          </tr>
        </thead>
        {data && (
          <tbody>
            {data.map((item, index) => {
              let severity = EVENT_STATUS_MAP.find(
                (status) => status.text === item.severity
              );
              return (
                <tr key={index}>
                  <td style={{ width: "1.25rem" }}>
                    <div className="d-flex flex-row align-items-center form-check align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"customCheck" + item.id}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"customCheck" + item.id}
                      >
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td>#{item.id}</td>
                  <td>{item.asset_name}</td>
                  <td className="text-muted fw-semibold text-start">
                    {item.well_name}
                  </td>
                  <td className="text-muted fw-semibold text-start">
                    {item.event_type}
                  </td>
                  <td className="text-muted fw-semibold text-start">
                    {convertToImperialDate(item.start_date)}
                  </td>
                  <td className="text-muted fw-semibold text-start">
                    {item.status}
                  </td>
                  <td className="text-muted fw-semibold text-start p-1">
                    <div
                      className="d-flex flex-row justify-content-center align-items-center p-1"
                      style={{
                        color: severity.textColor,
                        background: severity.bg,
                        borderRadius: "4px",
                      }}
                    >
                      <span>{severity.text}</span>
                    </div>
                  </td>
                  <td>{item.reporter}</td>
                  <td>
                    <button className="btn-custom">{item.contact}</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </>
  );
}

export default AlarmTable;
