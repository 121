import { DatePicker, Form, Input, Modal, Select } from 'antd'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getWellSummary } from 'service/wellSummary'
import classNames from 'classnames'
import sum from 'lodash/sum'
import { formatDate } from 'utils/dateUtil'
import { snakeToCamel } from 'utils/string'

const ALLOCATE_OPTION_VALUES = {
  EQUAL: 'Equal division',
  PROPORTIONAL: 'Proportional'
}

const ALLOCATE_OPTIONS = Object.entries(ALLOCATE_OPTION_VALUES).map(([value, label]) => ({
  value,
  label
}))

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const AddLOEItemModal = ({
  assetId,
  wellId,
  isSubmitting,
  onSave,
  expenseDict = [],
  ...modalProps
}) => {
  const [form] = Form.useForm()

  const additionalWells = Form.useWatch('additional_wells', form)
  const allocation = Form.useWatch('allocate', form)
  const fixedExpense = Form.useWatch('fixed_expense', form)
  const variableExpense = Form.useWatch('variable_expense', form)

  const { data: wells, isFetching: isFetchingWells } = useQuery({
    queryKey: ['getWellSummary', assetId],
    queryFn: () => getWellSummary(assetId),
    enabled: Boolean(assetId),
    select: (res) => res.data
  })

  useEffect(() => {
    if (!modalProps.open) {
      form.resetFields()
    }
  }, [form, modalProps.open])

  useEffect(() => {
    if (fixedExpense) {
      const fixedExpenseValue = Number.parseFloat(fixedExpense)
      if (fixedExpenseValue > 100) {
        if (`${ form.getFieldValue('variable_expense') }` !== '0') {
          form.setFieldValue('variable_expense', 0)
        }
      } else {
        if (`${ form.getFieldValue('variable_expense') }` !== `${ 100 - fixedExpenseValue }`) {
          form.setFieldValue('variable_expense', 100 - fixedExpenseValue)
        }
      }
    }
  }, [fixedExpense, form])

  useEffect(() => {
    if (variableExpense) {
      const variableExpenseValue = Number.parseFloat(variableExpense)
      if (variableExpenseValue > 100) {
        if (`${ form.getFieldValue('fixed_expense') }` !== '0') {
          form.setFieldValue('fixed_expense', 0)
        }
      } else {
        if (`${ form.getFieldValue('fixed_expense') }` !== `${ 100 - variableExpenseValue }`) {
          form.setFieldValue('fixed_expense', 100 - variableExpenseValue)
        }
      }
    }
  }, [variableExpense, form])

  const wellOptions = useMemo(() => wells?.map(well => ({
    value: well.uwi,
    label: well.well_name
  }) ?? []), [wells])

  const expenseOptions = useMemo(() => {
    return expenseDict.map(item => ({
      value: item.id,
      label: snakeToCamel(item.name)
    }))
  }, [expenseDict])

  const onSubmit = useCallback((data) => {
    const date = formatDate(data.date.toDate(), 'yyyy-MM-dd')
    const { additional_wells, allocate, proportion, expense_amount, ...expenseData } = data
    const allWells = [wellId, ...(additional_wells ?? [])]
    const totalAmmount = Number.parseFloat(expense_amount || '0')
    let ratios = []
    if (!allocate || allocate === 'EQUAL') {
      ratios = Array(allWells.length).fill(1 / allWells.length)
    } else {
      ratios = proportion
        .split(',')
        .map(ratio => ratio.trim() ? Number.parseFloat(ratio.trim()) : '')
        .filter(Boolean)
    }
    const dataToSubmit = allWells.reduce((state, uwi, index) => {
      const ammount = totalAmmount * (ratios[index] || 0)

      const fixedExpenseRatio = Number.parseFloat(data.fixed_expense || '0') / 100
      const fixedExpense = ammount * fixedExpenseRatio

      const variableExpenseRatio = Number.parseFloat(data.variable_expense || '0') / 100
      const variableExpense = ammount * variableExpenseRatio

      state[uwi] = [
        {
          loe_expense_id: data.expense_id,
          expense_items: [{
            ...expenseData,
            date,
            expense_amount: Number.parseFloat(ammount.toFixed(2)),
            fixed_expense: Number.parseFloat(fixedExpense.toFixed(2)),
            variable_expense: Number.parseFloat(variableExpense.toFixed(2))
          }]
        }
      ]

      return state
    }, {})

    onSave(dataToSubmit)
  }, [onSave, wellId])

  const proportionValidator = async (_, value) => {
    if (!value) return
    const ratios = value
      .split(',')
      .map(ratio => ratio.trim() ? Number.parseFloat(ratio.trim()) : '')
      .filter(Boolean)
    const missingRatios = ((additionalWells?.length || 0) + 1) - ratios.length
    if (missingRatios > 0) {
      throw new Error(`Please input ${ missingRatios } more ratios`)
    }
    const total = sum(ratios)
    if (total !== 1) throw new Error('Total ratios must equal 1')
  }

  return (
    <Modal
      title="New LOE Item"
      okText="Add"
      width={640}
      onOk={form.submit}
      cancelButtonProps={{
        disabled: isSubmitting
      }}
      okButtonProps={{
        loading: isSubmitting
      }}
      {...modalProps}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <div className="row">
          <Form.Item className="col-6" name="expense_id" label="Expense Name" rules={[{ required: true }]}>
            <Select options={expenseOptions} />
          </Form.Item>
          <Form.Item className="col-6" name="date" label="Date" rules={[{ required: true }]}>
            <DatePicker format="MM-DD-YYYY" className="w-100" />
          </Form.Item>
        </div>
        <Form.Item name="expense_amount" label="Amount ($)" rules={[{ required: true }]}>
          <Input type="number" step={0.01} typeof="number" />
        </Form.Item>
        <div className="row">
          <Form.Item className="col-6" name="fixed_expense" label="Fixed Expense (%)" rules={[{ required: true }]}>
            <Input type="number" step={0.01} min={0} max={100} />
          </Form.Item>
          <Form.Item className="col-6" name="variable_expense" label="Variable Expense (%)" rules={[{ required: true }]}>
            <Input type="number" step={0.01} min={0} max={100} />
          </Form.Item>
        </div>
        <Form.Item name="additional_wells" label="Add wells">
          <Select mode="multiple" loading={isFetchingWells} options={wellOptions} showSearch filterOption={filterOption} />
        </Form.Item>
        <div className="row">
          <Form.Item
            className={classNames('col-6', {
              'd-none': !additionalWells?.length
            })}
            name="allocate"
            label="Allocate"
          >
            <Select options={ALLOCATE_OPTIONS} />
          </Form.Item>
          <Form.Item
            className={classNames('col-6', {
              'd-none': allocation !== 'PROPORTIONAL'
            })}
            name="proportion"
            label="Proportion"
            rules={[
              {
                required: allocation === 'PROPORTIONAL'
              },
              {
                validator: proportionValidator,
              }
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item name="notes" label="Note">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddLOEItemModal