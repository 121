import { Form } from 'antd'
import React from 'react'
import EnumerationTable from './EnumerationTable'

const InitialSetting = () => {
  return (
    <Form.List name={['INITIAL']}>
      {(fields, operation) => (
        <EnumerationTable fields={fields} operation={operation} />
      )}
    </Form.List>
  )
}

export default InitialSetting