import { get, post, put, remove } from "./base";

export function getWellOwnershipDict(onSuccess, onError) {
  return get(
    "/api/economics/ownership/dict/",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

/**
 *
 * @param {*} uwi
 * @param {*} params
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */
export function getWellOwnership(uwi, params, onSuccess, onError) {
  return get(
    `/api/economics/well/${ uwi }/ownership/`,
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

/**
 * 
 * @param {*} uwi 
 * @param {*} data 
 * {
    "owner":"Sam Johson",
    "vaworking_interestlue":0.5,
    "notes":"xxx"
    }
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function addWellOwnership(uwi, data, onSuccess, onError) {
  return post(
    `/api/economics/well/${ uwi }/ownership/`,
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

/**
 * 
 * @param {*} uwi 
 * @param {*} data 
 * {
    "id":1,
    "owner":"Sam Johson",
    "vaworking_interestlue":0.32,
    "notes":"xxx"
    }
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function updateWellOwnership(uwi, data, onSuccess, onError) {
  return put(
    `/api/economics/well/${ uwi }/ownership/`,
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function deleteWellOwnership(uwi, ids, onSuccess, onError) {
  return remove(
    `/api/economics/well/${ uwi }/ownership/`,
    { ids: ids },
    null,
    {},
    (res) => {
      onSuccess();
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}
