import { useEffect, useMemo } from "react";
import React from "react";
import { useSelector } from "react-redux";

import {
  selectSelectedWellParams,
  selectAvailableParamsData,
  selectAvailableWellParams,
} from "features/well_planner/plannerSlice";
import { computeWellParamsCorrelation } from "service/wellData";

import { drawCorrChart } from "../../well_planner/chart/chart";

function DcaManagementCorrelation(props) {
  const { xSeries, dataSeries } = props;
  const selectedParams = useSelector(selectSelectedWellParams);
  const wellParams = useSelector(selectAvailableWellParams);
  const wellParamsData = useSelector(selectAvailableParamsData);

  const svgRef = React.useRef(null);

  // const dimensions = {
  //   width: 600,
  //   height: 300,
  //   margin: { top: 30, right: 30, bottom: 30, left: 60 }
  // };

  // const { width, height, margin } = dimensions;
  // const svgWidth = width + margin.left + margin.right;
  // const svgHeight = height + margin.top + margin.bottom;
  const DIMENSION_SETTING = useMemo(() => [
    [42, 200, 100], //0
    [42, 200, 100], //1
    [42, 170, 82],  // 2
    [41, 240, 120],  // 3
    [41, 270, 160],  // 4 
    [40, 300, 200],  // 5
    [40, 300, 200],  // 6
    [40, 300, 200],  // 7
    [40, 350, 230],  // 8
    [40, 350, 230],  // 9
  ], []);

  useEffect(() => {
    if (
      wellParams == null ||
      wellParamsData == null ||
      selectedParams == null
    ) {
      return;
    }

    let paramsMatrix = [];
    for (let param of selectedParams) {
      let wellIds = Object.keys(wellParams);
      let dataRow = [];
      for (let wellId of wellIds) {
        let params = wellParams[wellId];
        let paramsData = wellParamsData[wellId];
        let index = params.findIndex((p) => p === param);
        dataRow.push(paramsData[index]);
      }
      paramsMatrix.push({ [param]: dataRow });
    }


    computeWellParamsCorrelation(
      paramsMatrix,
      (result) => {
        // setDimensions({
        //   cellSize: 24,
        //   width: 24 * result.params.length + 40,
        //   height: 24 * result.params.length,
        // });
      },
      (error) => { }
    );
  }, [wellParams, wellParamsData, selectedParams]);

  useEffect(() => {
    if (
      dataSeries == null ||
      Array.isArray(dataSeries) === false ||
      dataSeries.length === 0 ||
      xSeries == null
    ) {
      return;
    }

    drawCorrChart(svgRef.current, xSeries, dataSeries, {
      cellSize: DIMENSION_SETTING[xSeries[0] ? xSeries.length : 0][0],
      width: DIMENSION_SETTING[xSeries[0] ? xSeries.length : 0][1],
      height: DIMENSION_SETTING[xSeries[0] ? xSeries.length : 0][2],
    });
  }, [xSeries, dataSeries, DIMENSION_SETTING]);

  return (
    <div
      id="corr_chart"
      className="w-100 h-100"
    >
      <svg
        ref={svgRef}
        viewBox={`-15 0 ${DIMENSION_SETTING[xSeries[0] ? xSeries.length : 0][1]} ${DIMENSION_SETTING[xSeries[0] ? xSeries.length : 0][2]}`}
        className="w-100 h-100"
      />
    </div>
  );
}

export { DcaManagementCorrelation };
