import React, { Fragment, useEffect, useMemo } from "react";
import PpSelector from "./PpSelector";
import { useDispatch, useSelector } from "react-redux";
import {
    selectActiveCrossSection,
    setTopsList,
    selectTopsSelected,
    selectSelectedWells,
    selectTopNameList,
    setTopNameList,
    setTopsSelected
} from "features/petrophysics/petrophysicsSlice";
import { Switch } from "antd";
import { getWellTops } from "service/petrophysics";
const Context = React.createContext({
    name: 'Default',
});
const PpTopSelector = (props) => {
    const { viewSwitch, onViewSwitch } = props;
    const { loadingIconActions } = props;
    const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
    const dispatch = useDispatch();

    const selectedWells = useSelector(selectSelectedWells);
    const activeCrossSection = useSelector(selectActiveCrossSection);
    const topNameList = useSelector(selectTopNameList);
    const selectedTop = useSelector(selectTopsSelected);

    useEffect(() => {
        if (!selectedWells || !Array.isArray(selectedWells) || selectedWells.length === 0) {
            dispatch(setTopsList([]));
            dispatch(setTopNameList([]));
            return;
        }
        showLoadingIcon && showLoadingIcon();
        getWellTops(
            selectedWells,
            null,
            (res) => {
                // use new tops replace all old tops
                let orderedRes = [];
                selectedWells.map(uwi => orderedRes.push(res.find(item => item.uwi === uwi)));
                dispatch(setTopsList([...orderedRes.filter(item => item !== undefined)]));

                let nameSet = new Set();
                res.map(item => {
                    nameSet = new Set([...nameSet, ...Object.keys(item.tops)]);
                    return null;
                })
                dispatch(setTopNameList([...nameSet]));
                hideLoadingIcon && hideLoadingIcon();
            },
            (e) => {
                console.error("load cross sections failed.", e);
                hideLoadingIcon && hideLoadingIcon();
            }
        )
    }, [selectedWells, hideLoadingIcon, showLoadingIcon, dispatch]);

    const contextValue = useMemo(
        () => ({
            name: activeCrossSection ? activeCrossSection[0] : '',
        }),
        [activeCrossSection],
    );

    const handleTopSelected = (selectionId, status) => {
        // deselect a top, remove selected and/or other selection
        if (!status) {
            dispatch(setTopsSelected(selectedTop.filter(item => item !== selectionId)));
            return;
        }

        if (selectedTop.includes(selectionId)) return;
        dispatch(setTopsSelected([...selectedTop, selectionId]));
    }

    return (
        <Context.Provider value={contextValue}>
            <Fragment>
                <PpSelector
                    dataList={topNameList ? topNameList.map(
                        item => ({
                            label: item,
                            name: item
                        }))
                        : []
                    }
                    selected={selectedTop}
                    title={'Tops'}
                    needSort={true}
                    onOptionSelected={handleTopSelected}
                >
                    {viewSwitch && <Switch
                        checkedChildren='Map View'
                        unCheckedChildren='Log View'
                        onChange={onViewSwitch}
                    />}
                </PpSelector>
            </Fragment>
        </Context.Provider>
    )
};
export default PpTopSelector;