import { useState } from "react";

import 'styles/forcastsetting.css'

function DcaManagementForecastSetting(props) {

    /* in fact, value this is forecastSetting
    value: 
    {
        forecastMonths: 20, // also forecastSetting member
        paramsNames: ["pressure", "wer"],
        paramsRange: ["100-200", "120-300"],
        paramsSuggested: [100, 122],
        paramsDefault: [200, 300],
        paramsValues: [100, 100],   // last user input, also forecastSetting member
    }
    */
    const { value, onApply, onClose } = props;
    const { paramsValues, paramsNames, paramsRange, paramsSuggested, paramsDefault } = value;
    const paramsRangeArr = {}, paramsSuggestedArr = {}, paramsDefaultArr = {}, paramsValuesArr = {};
    paramsNames.map((name, index) => {
        paramsRangeArr[name] = paramsRange[index];
        paramsSuggestedArr[name] = paramsSuggested[index];
        paramsDefaultArr[name] = paramsDefault[index];
        paramsValuesArr[name] = paramsValues[index];
        return null;
    });
    const [paramsData, setParamsData] = useState([
        {
            settingId: '01',
            parameter: 'History Range',
            editable: false,
            ...paramsRangeArr,
        },
        {
            settingId: '02',
            parameter: 'Suggested',
            editable: false,
            ...paramsSuggestedArr,
        },
        {
            settingId: '03',
            parameter: 'Default',
            editable: false,
            ...paramsDefaultArr,
        },
        {
            settingId: '04',
            parameter: 'User Input',
            editable: true,
            ...paramsValuesArr,
        },
    ])
    const [forecastMonths, setForecastMonths] = useState(value.forecastMonths)

    const onChangeInput = (e, paramId) => {
        const { name, value } = e.target

        const editData = paramsData.map((item) =>
            item.settingId === paramId && name ? { ...item, [name]: value } : item
        )

        setParamsData(editData);
    }

    useState(() => {
        if (!value) return;
        setForecastMonths(value.forecastMonths);
    }, value);

    return (
        <div className="popup-wrapper d-flex flex-column" style={{ minWidth: '400px' }}>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <span>Forecast setting</span>
                <div
                    onClick={(e) => {
                        props.onClose();
                    }}
                >
                    <span>X</span>
                </div>
            </div>

            <div className="d-flex align-items-center" style={{ marginTop: '20px' }}>
                <label className="me-1">Forecast:</label>
                <input
                    value={forecastMonths}
                    name="months"
                    type="number"
                    style={{ width: '60px', height: '15px' }}
                    onChange={event => setForecastMonths(event.target.value < 0 ? 0 : event.target.value)}
                />
                <span className="ms-2">(Months)</span>
            </div>
            {Object.keys(paramsData[0]).length > 3 && <div className="fcs_container">
                <table>
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            {
                                paramsNames.map((name, index) => <th style={{ width: '30px', textAlign: 'start' }} key={index}>{name}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {paramsData.map(({ settingId, parameter, editable, ...params }, index) => {
                            return (
                                <tr key={settingId}>
                                    <td>
                                        <span
                                            style={{ width: '100px', margin: '2px' }}
                                            name="Parameter"
                                            value={parameter}
                                            readOnly
                                            type="text"
                                        >{parameter}</span>
                                    </td>
                                    {
                                        Object.keys(params).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    editable ? (<input
                                                        className="dcm_input"
                                                        name={key}
                                                        value={params[key]}
                                                        type="text"
                                                        style={{ height: '16px', textAlign: 'start' }}
                                                        onChange={(e) => onChangeInput(e, settingId)}
                                                    />) : (<span
                                                        style={{ textAlign: 'center', margin: '2px', marginLeft: '12px' }}
                                                    >{params[key]}</span>)
                                                }
                                            </td>

                                        ))
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>}
            <div className="forecast-lower-part d-flex flex-column mt-2">
                <div className="d-flex justify-content-between align-items-center mt-2">
                    <button
                        className="btn-custom"
                        onClick={(e) => {
                            onApply({
                                forecastMonths: forecastMonths,
                                paramsValues: Object.keys(paramsData[3]).map(key => paramsData[3][key]).slice(3),
                                parameters: { ...paramsData[3] },
                            });
                            onClose();
                        }}
                        disabled={!parseInt(forecastMonths)}
                    >
                        Apply
                    </button>
                    <button
                        className="btn-custom"
                        style={{ minWidth: "8em" }}
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DcaManagementForecastSetting;
