import React, { useCallback, useMemo, useState } from 'react'
import { Form, Select, Typography } from 'antd'
import { formatDate } from 'utils/dateUtil'
import groupBy from 'lodash/groupBy'
import { flatten, orderBy, uniq } from 'lodash'
import TimeSeriesChart from 'components/common/TimeSeriesChart'
import { snakeToCamel } from 'utils/string'
import FormSection from 'components/form-section'

const WellReport = ({ uwi, report }) => {
  const form = Form.useFormInstance()

  const flattenedData = useMemo(() => {
    const sensitivityRates = form.getFieldValue(['general', 'sensitivity_rates']) ?? []

    return report ? [
      ...(report.cash_flow?.map(([date, value]) => ({
        date,
        value,
        type: 'cash_flow'
      })) ?? []),
      ...(report.dcf?.map(([date, value]) => ({
        date,
        value,
        type: 'dcf'
      })) ?? []),
      ...(report.loe_expenses?.map(([date, value]) => ({
        date,
        value,
        type: 'loe_expenses'
      })) ?? []),
      ...(report.senstivity_dcfs ?? []).reduce((items, currentItems, index) => {
        const sensitivityRate = sensitivityRates[index]
        items.push(...currentItems.map(([date, value]) => ({
          date,
          value,
          type: `senstivity_dcfs_${ sensitivityRate }`
        })))
        return items
      }, [])
    ] : []
  }, [form, report])

  const allCurves = useMemo(() => uniq(flattenedData.map(item => item.type)), [flattenedData])

  const [visibleCurves, setVisibleCurves] = useState(allCurves)

  const data = useMemo(() => {
    if (!report) return []

    const groupedByDate = groupBy(flattenedData, 'date')

    const dateSeries = orderBy(Object.keys(groupedByDate).map(date => new Date(date)), date => date, 'asc')

    const itemsByDate = orderBy(flatten(Object.values(groupedByDate)), (record) => new Date(record.date), 'asc')
    const itemsByKey = groupBy(itemsByDate, 'type')

    const chartData = Object.entries(itemsByKey).reduce((state, [key, items]) => {
      if (!visibleCurves.includes(key)) return state
      const curveLegend = snakeToCamel(key)
      state[curveLegend] = state[curveLegend] || {
        data: [],
        lineType: "line",
        lineName: ' ',
        showAxisLine: key === 'cash_flow',
      }
      state[curveLegend].data.push(...items.map(item => item.value))
      return state
    }, {})

    const _dataSeries = {
      xSeries: dateSeries,
      config: {
        multiYAxis: false,
        leftYAxisLabel: ''
      },
      dataSeries: chartData,
    };

    return _dataSeries
  }, [flattenedData, report, visibleCurves])

  const onSelectCurve = useCallback((curveType) => {
    setVisibleCurves(prev => [...prev, curveType])
  }, [])

  const onDeselectCurve = useCallback((curveType) => {
    setVisibleCurves(prev => prev.filter(type => type !== curveType))
  }, [])

  return (
    <div className="vstack gap-2">
      {report.economic_limit_date ? (
        <FormSection>
          <div className="hstack gap-4">
            <Typography.Text className="text-light">Economic Limit: <strong>{formatDate(report.economic_limit_date)}</strong></Typography.Text>
            {report.eur_boe ? <Typography.Text className="text-light"><strong>{report.eur_boe}</strong> boe</Typography.Text> : null}
            {report.eur_mcfe ? <Typography.Text className="text-light"><strong>{report.eur_mcfe}</strong> bmscfe</Typography.Text> : null}
          </div>
        </FormSection>
      ) : null}
      <FormSection title="Plot">
        <Select
          mode="multiple"
          className="mb-2"
          dropdownMatchSelectWidth={false}
          value={visibleCurves}
          options={allCurves.map((curve) => ({
            value: curve,
            label: snakeToCamel(curve)
          }))}
          placeholder="Select curves"
          style={{
            minWidth: 150
          }}
          onSelect={onSelectCurve}
          onDeselect={onDeselectCurve}
        />
        <div style={{
          height: 340
        }}>
          <TimeSeriesChart data={data} />
        </div>
      </FormSection>
    </div>
  )
}

export default WellReport