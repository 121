import React, { useMemo } from 'react'
import { Tabs } from 'antd';

import classes from './loe-table.module.scss'
import PerformanceMetrics from './PerformanceMetrics';
import LOERecords from './LOERecords';

const WellLoeTable = (props) => {
  const tabs = useMemo(
    /**
     * 
     * @returns {import('antd').TabsProps['items']}
     */
    () => {
      return [
        {
          key: 'performance_metrics',
          label: 'Performance Metrics',
          children: <PerformanceMetrics {...props} />
        },
        {
          key: 'loe_records',
          label: 'LOE Records',
          children: <LOERecords {...props} />
        }
      ]
    }, [props])

  return (
    <div className="vstack">
      <Tabs items={tabs} className={classes.tabs} destroyInactiveTabPane />
    </div>
  )
}

export default WellLoeTable;