import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProjectsItems,
  setProjectItems,
} from "features/project/projectsSlice";
import { getProjects } from "service/projects";
import ProjectLayoutSection from "pages/project/ProjectSectionLayout";
import useLoading from "components/common/useLoading";
import { Spin } from "antd";

const PrdAnalyzerProjectSummary = (props) => {
  const { projectType } = props;
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(<Spin size="large" />, 50);

  const projects = useSelector(selectProjectsItems);
  let dispatch = useDispatch();
  useEffect(() => {
    showLoadingIcon();
    getProjects(
      projectType,
      (projects) => {
        dispatch(setProjectItems(projects));
        hideLoadingIcon();
      },
      (err) => { hideLoadingIcon(); }
    );
  }, [dispatch, projectType, hideLoadingIcon, showLoadingIcon]);

  return (
    <div className="page-content">
      <LoadingIcon />
      {projects !== null && (
        <div className="content">
          <ProjectLayoutSection projectType={projectType} projects={projects} />
        </div>
      )}
    </div>
  );
};

export default PrdAnalyzerProjectSummary;
