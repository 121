import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectTopDataStructure,
  setTopDataStructure,
  selectReload,
  setColumnObj,
  setShowAddColumnPopup,
  setReload,
  selectShowAddColumnPopup,
} from "features/input/dataManagerSlice";
import { getWellTopInputConfig, addWellTopColumn } from "service/dataInput";

import NullFile from "./NullFile";
import AddColumn from "./AddColumn";

import AddIcon from "@mui/icons-material/Add";

function TopsInput(props) {
  const { inputData } = props;
  const topDataStructure = useSelector(selectTopDataStructure);
  const reload = useSelector(selectReload);
  const showAddColumnPopup = useSelector(selectShowAddColumnPopup);

  const [columnDictionary, setColumnDictionary] = useState(null);

  const [selectedColumnObj, setSelectedColumnObj] = useState(null);
  const [inputColumns, setInputColumns] = useState(null);
  const [topsData, setTopsData] = useState(null);

  let dispatch = useDispatch();

  useEffect(() => {
    if (inputData == null) {
      return;
    }
    setInputColumns(inputData.headers);
    setTopsData(inputData.data);

    refreshData();
  }, [inputData]);

  useEffect(() => {
    if (!reload) {
      return;
    }
    dispatch(setReload(false));
    refreshData();
  }, [reload]);

  useEffect(() => {
    if (inputColumns == null || columnDictionary == null) {
      return;
    }
    initDataStructure();
  }, [inputColumns, columnDictionary]);

  function refreshData() {
    getWellTopInputConfig(
      (result) => {
        let columns = result.map((e) => {
          return { id: e.id, header: e.top_attr };
        });
        setColumnDictionary([{ id: -1, header: "N/A" }, ...columns]);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  function initDataStructure() {
    const initialSelection = inputColumns.map((x, index) => {
      let selectedCol = null;
      let header = x.trim().toLowerCase().replace(/\s/g, "_");
      let i = columnDictionary.findIndex(
        (e) =>
          header.startsWith(e.header) ||
          e.header.startsWith(header) ||
          header.includes(e.header) ||
          e.header.includes(header)
      );
      if (i !== -1) {
        selectedCol = columnDictionary[i].header;
      } else {
        selectedCol = columnDictionary[0].header;
      }
      return selectedCol;
    });
    dispatch(setTopDataStructure(initialSelection));
  }

  function handleChangeColumn(e, i) {
    const unpacked = topDataStructure.map((el, index) => {
      if (index === i) {
        return e.target.value;
      } else {
        return el;
      }
    });
    dispatch(setTopDataStructure(unpacked));
  }

  function addCustomColumn(inputColumnObj) {
    let columnObj = {
      columnName: inputColumnObj.name,
      unit_id: inputColumnObj.unit_id,
    };
    setSelectedColumnObj(columnObj);
    dispatch(setColumnObj(columnObj));
    dispatch(setShowAddColumnPopup(true));
  }

  function saveColumn(columnObj) {
    addWellTopColumn(
      {
        top_attr: columnObj.columnName,
        unit_id: columnObj.unit_id,
      },
      (result) => {
        dispatch(setShowAddColumnPopup(false));
        dispatch(setReload(true));
      },
      (error) => {
        dispatch(setShowAddColumnPopup(false));
      }
    );
  }

  return (
    <div className="table-container d-flex flex-column justify-content-start align-items-start">
      {inputColumns == null ||
      topDataStructure == null ||
      columnDictionary == null ? (
        <NullFile />
      ) : (
        <>
          {showAddColumnPopup && (
            <div className="popup-window">
              <AddColumn
                inputColumnObj={selectedColumnObj}
                saveColumn={saveColumn}
              />
            </div>
          )}
          <div className="table-header">
            {inputColumns.map((x, i) => {
              return (
                <div className="header-cell" key={i}>
                  {x}
                  <i
                    className="header-icon"
                    onClick={(e) => {
                      addCustomColumn({
                        name: x,
                        unit_id: null,
                      });
                    }}
                  >
                    <AddIcon />
                  </i>
                </div>
              );
            })}
          </div>
          <div className="table-header">
            {inputColumns.map((x, i) => {
              return (
                <div className="header-cell" key={i}>
                  <select
                    id={`attribute_id${i}`}
                    onChange={(e) => {
                      handleChangeColumn(e, i);
                    }}
                    value={topDataStructure[i]}
                  >
                    {columnDictionary.map((y, j) => {
                      return (
                        <option key={i + "_" + j} value={y.header}>
                          {y.header}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
            })}
          </div>
          <div className="table-body">
            {topsData.map((el, index) => {
              return (
                <div className="data-row" key={index}>
                  {el.map((val, subIndex) => {
                    return (
                      <div className="data-cell" key={index + "_" + subIndex}>
                        {val}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default TopsInput;
