import { utcToZonedTime, format } from 'date-fns-tz'

export function getDateBefore(startDate, days) {
  // const now = new Date();
  return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - days);
}

/**
 * 
 * default: yyyy-mm-dd
 */
export function formatDate(date, formatStr = 'yyyy-MM-dd') {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const zonedDate = utcToZonedTime(date, timeZone)
  return format(zonedDate, formatStr, {
    timeZone
  })
}
