import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  selectClusterWells,
  selectGroupWells,
  selectClusteringType,
} from "features/decline_analysis/typeCurveProjectSlice";
import {
  selectActiveWells,
  selectAssets,
  selectActiveAssetId,
  setActiveAssetByName,
  selectActiveAsset,
} from "features/asset/assetSelectorSlice";
import { ClusteringTypes } from "app/codes";

function WellsSelector(props) {

  const assets = useSelector(selectAssets);
  const activeAssetId = useSelector(selectActiveAssetId);
  const activeAsset = useSelector(selectActiveAsset);
  const activeWells = useSelector(selectActiveWells);

  const clusteringType = useSelector(selectClusteringType);
  const clusterWellIds = useSelector(selectClusterWells);
  const groupWellIds = useSelector(selectGroupWells);

  const dispatch = useDispatch();

  const [wellsData, setWellsData] = useState(null);

  let assetName = null;
  if (activeAsset) {
    assetName = activeAsset.asset_name;
  }

  useEffect(() => {
    if (clusteringType == null) {
      return;
    }

    let wellIds = null;
    if (clusteringType === ClusteringTypes.AIClustering) {
      wellIds = clusterWellIds;
    } else {
      wellIds = groupWellIds;
    }

    if (
      wellIds == null ||
      wellIds.length === 0 ||
      activeWells == null ||
      activeWells.length === 0
    ) {
      setWellsData(null);
      return;
    }
    let wellIdsSet = new Set(wellIds);
    let wells = activeWells
      .filter((well) => wellIdsSet.has(well.uwi))
      .filter((well) => well != null && well.asset_id === activeAssetId);

    setWellsData(wells);
  }, [activeAssetId, clusterWellIds, groupWellIds, activeWells, clusteringType]);

  function handleAssetChanged(e) {
    const assetName = e.target.value;
    dispatch(setActiveAssetByName({ assetName }));
  }

  const assetsSelect = () => {
    if (assets == null || assets.length === 0) {
      return null;
    }

    return assets.map((asset, index) => {
      return (
        <option
          key={index}
          className="d-flex justify-content-center align-items-content"
          value={asset.asset_name}
        >
          {asset.asset_name}
        </option>
      );
    });
  };

  const wellsSelect = () => {
    if (wellsData == null || wellsData.length === 0) {
      return null;
    }

    return wellsData.map((well, index) => {
      let className =
        "select-option d-flex justify-content-center align-items-start";
      return (
        <div id={well.uwi} key={well.uwi} className={className}>
          {well.well_name}
        </div>
      );
    });
  };

  return (
    <div className="selector d-flex flex-column w-100 h-100">
      <div className="selector-select d-flex flex-column align-items-stretch">
        <select
          onChange={handleAssetChanged}
          name="asset_name"
          className="d-flex"
          value={assetName || ""}
        >
          {assetsSelect()}
        </select>
      </div>
      <div className="select-options-list">{wellsSelect()}</div>
    </div>
  );
}

export default WellsSelector;
