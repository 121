import { useDispatch, useSelector } from "react-redux";
import {
  selectRecommEurDataAllPhase,
  selectUserEurDataAllPhase,
  setShowEurParamsPanel,
} from "features/decline_analysis/dcaProjectSlice";
import { DECLINE_PHASE_OPTIONS } from "app/codes";

const PreferredDecimal = 2;

function ForecastParamsPanel(props) {
  const eurRecommDataAllPhase = useSelector(selectRecommEurDataAllPhase);
  const eurUserDataAllPhase = useSelector(selectUserEurDataAllPhase);
  const dispatch = useDispatch();

  function initDisplayValue(value) {
    return value ? value.toFixed(PreferredDecimal) : 0;
  }
  return (
    <div className="popup-wrapper d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>Eur Data</span>
        <div
          onClick={(e) => {
            dispatch(setShowEurParamsPanel(false));
          }}
        >
          <span>X</span>
        </div>
      </div>
      <div className="eur-table">
        <table>
          <tr>
            <td rowSpan="2" className="cell-header">Phase</td>
            <th colSpan="2" scope="colgroup" className="cell-header">
              EUR
            </th>
            <th colSpan="2" scope="colgroup" className="cell-header">
              Period Production
            </th>
          </tr>
          <tr>
            <th scope="col" className="cell-header">Auto-regression</th>
            <th scope="col" className="cell-header">User-Arps</th>
            <th scope="col" className="cell-header">Auto-regression</th>
            <th scope="col" className="cell-header">User-Arps</th>
          </tr>
          {/* {!eurDataList && <tr>
            <th scope="row" className="cell-header">{DECLINE_PHASE_OPTIONS.find(item => item.value === declinePhase).name}</th>
            <td>{initDisplayValue(eurRecomm)}</td>
            <td>{initDisplayValue(eurUser)}</td>
            <td>{initDisplayValue(periodPrdRecomm)}</td>
            <td>{initDisplayValue(periodPrdUser)}</td>
            <td>{DECLINE_PHASE_OPTIONS.find(item => item.value === declinePhase).unit.slice(0, -3)}</td>
          </tr>} */}
          {
            DECLINE_PHASE_OPTIONS.map((option, index) => (<tr key={index}>
              <th scope="row" className="cell-header">{option.name + '(' + option.unit.slice(0, -7) + ')'}</th>
              <td>{initDisplayValue(eurRecommDataAllPhase[option.value].eurRecomm)}</td>
              <td>{initDisplayValue(eurUserDataAllPhase[option.value].eurUser)}</td>
              <td>{initDisplayValue(eurRecommDataAllPhase[option.value].periodPrdRecomm)}</td>
              <td>{initDisplayValue(eurUserDataAllPhase[option.value].periodPrdUser)}</td>
            </tr>))
          }
        </table>
      </div>
    </div>
  );
}

export default ForecastParamsPanel;
