import React, { useState } from "react";

import GridConfig from "./GridConfig";
import PlaceWellSetting from "./PlaceWellSetting";
import { InterpolateParamsSettings } from "./InterpolateParamsSettings";

function WellSettings(props) {
  const { loadingIconActions } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const TabOptions = [
    { name: "Step-1", component: GridConfig },
    { name: "Step-2", component: InterpolateParamsSettings },
    { name: "Step-3", component: PlaceWellSetting },
  ];

  function nextStep() {
    if (activeTabIndex < 2) {
      setActiveTabIndex(activeTabIndex + 1);
    }
  }

  function generateTabs() {
    return TabOptions.map((option, index) => {
      let className = "tab-item py-2 ps-3 pe-3 me-2";
      if (index === activeTabIndex) {
        className += " active";
      }
      return (
        <div
          key={index}
          className={className}
          onClick={(e) => {
            setActiveTabIndex(index);
          }}
        >
          {option.name}
        </div>
      );
    });
  }

  return (
    <div
      className="d-flex flex-column"
      style={{ width: "960px" }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex">{generateTabs()}</div>
        <div
          onClick={(e) => {
            props.close();
          }}
        >
          X
        </div>
      </div>
      <div className="tab-content">
        {activeTabIndex === 0 && <GridConfig nextStep={nextStep} loadingIconActions={loadingIconActions} />}
        {activeTabIndex === 1 && (
          <InterpolateParamsSettings nextStep={nextStep} close={props.close} />
        )}
        {activeTabIndex === 2 && <PlaceWellSetting loadingIconActions={loadingIconActions} />}
      </div>
    </div>
  );
}

export { WellSettings };
