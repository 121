import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import Logout from "pages/auth/Logout";
import { UserDashboard } from "components/layouts/userroutes";
import AssetView from "pages/AssetViewer";
import WellView from "pages/WellViewer";
import PrdAnalyzerProjectSummary from "pages/project/PrdAnalyzerProjectSummary";
import DeclineAnalysisCreateProject from "pages/dca/DeclineAnalysisCreateProject";
import Dca from "pages/dca/Dca";
import DcaManagement from "pages/dca/DcaManagement";
import TypeCurve from "pages/type_curve/TypeCurve";
import DcaTypeCurveCreateProject from "pages/type_curve/DcaTypeCurveCreateProject";
import PetroInputData from "pages/data_manager/data_Input/PetroInputData";
import DictionaryEditor from "pages/data_manager/dictionary";
import WellAssetEditor from "pages/data_manager/asset/WellAssetEditor";
import WellSummaryEditor from "pages/data_manager/well/WellSummary";
import ProductionEditor from "pages/data_manager/production/ProductionEditor";
import ProductionEditor3 from "pages/data_manager/production/ProductionEditor3";
import EventsEditor from "pages/data_manager/operation/EventsEditor";
import LogViewer from "pages/data_manager/petrophysics/LogViewer/LogViewer"

import WellPlaner from "pages/well_planner/WellPlanner";
import WellPlannerCreateProject from "pages/well_planner/WellPlannerCreateProject";
import Homepage from "pages/homepage/homepage";
import Correlation from "pages/data_manager/petrophysics/Correlation/Correlation";
import CrossSection from "pages/data_manager/petrophysics/CrossSection/CrossSection";
import PropertyLog from "pages/data_manager/petrophysics/PropertyLog/PropertyLog";
import WellEditor2 from "pages/data_manager/well/WellEditor2";
import WellPlannerReporting from "pages/well_planner/reporting/WellPlannerReporting";
// import EventsEditor2 from "pages/data_manager/operation/EventsEditor2";
import CompletionEditor from "pages/data_manager/completion/CompletionEditor";
import DeclineAnalysisReporting from "pages/dca/reporting/DeclineAnalysisReporting";
import EconomicBasics from "pages/data_manager/economics/basic";
import WellEconomicsEditor from "pages/data_manager/economics/editor/WellEconomicsEditor";
import ProjectSummaryPage from "pages/completion_optimizer/ProjectSummary";
import ProjectSettingsPage from "pages/completion_optimizer/ProjectSettings";
import OptimizerRunPage from "pages/completion_optimizer/Run";

const OptimizerRunResultsPage = React.lazy(() => import("pages/completion_optimizer/Results"));

const App = () => {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/login/" element={<Login />} />
          <Route path="/logout/" element={<Logout />} />
          <Route path="/register/" element={<Register />} />
          <Route path="/home/" element={<Homepage />} />
          <Route path="/" element={<UserDashboard />} >
            <Route path="/asset-viewer/" element={<AssetView />} />
            <Route path="/well-viewer/" element={<WellView />} />
            <Route path="/project_summary/" element={<PrdAnalyzerProjectSummary />} />
            <Route path="/project_summary/well_planner" element={<PrdAnalyzerProjectSummary projectType="well_planner" />} />
            <Route path="/project_summary/dca" element={<PrdAnalyzerProjectSummary projectType="dca" />} />
            <Route path="/project_summary/dcm" element={<PrdAnalyzerProjectSummary projectType="dca_management" />} />
            <Route path="/project_summary/type_curve" element={<PrdAnalyzerProjectSummary projectType="type_curve" />} />
            <Route path="/decline_analysis/create_project/" element={<DeclineAnalysisCreateProject />} />
            <Route path="/decline_analysis/analyzer/" element={<Dca />} />
            <Route path="/decline_analysis/management/" element={<DcaManagement />} />
            <Route path="/decline_analysis/reporting" element={<DeclineAnalysisReporting />} />
            <Route path="/economics/basic" element={<EconomicBasics />} />
            <Route path="/economics/editor" element={<WellEconomicsEditor />} />
            <Route path="/type_curve/analyzer/" element={<TypeCurve />} />
            <Route path="/type_curve/create_project/" element={<DcaTypeCurveCreateProject />} />
            <Route path="/well_planner/" element={<WellPlaner />} />
            <Route path="/well_planner/create_project/" element={<WellPlannerCreateProject />} />
            <Route path="/well_planner/reporting/" element={<WellPlannerReporting />} />
            <Route path="/petrophysics_input/" element={<PetroInputData />} />
            <Route path="/dictionary_editor/" element={<DictionaryEditor />} />
            <Route path="/well_asset/" element={<WellAssetEditor />} />
            <Route path="/well_summary/" element={<WellSummaryEditor />} />
            <Route path="/well_editor/" element={<WellEditor2 />} />
            <Route path="/well_editor/:uwi" element={<WellEditor2 />} />
            <Route path="/production_editor/" element={<ProductionEditor />} />
            <Route path="/production_editor/:uwi" element={<ProductionEditor3 />} />
            <Route path="/operation_editor/" element={<EventsEditor />} />
            {/* <Route path="/operation_editor/:type/:id" element={<EventsEditor2 />} /> */}
            <Route path="/petrophysics_logviewer/" element={<LogViewer />} />
            <Route path="/petrophysics_correlation/" element={<Correlation />} />
            <Route path="/petrophysics_crosssection/" element={<CrossSection />} />
            <Route path="/petrophysics_propertylog/" element={<PropertyLog />} />
            <Route path="/completion_editor/" element={<CompletionEditor />} />
            <Route path="/completion_editor/:uwi" element={<CompletionEditor />} />
            <Route path="/completion_optimizer/project_summary" element={<ProjectSummaryPage />} />
            <Route path="/completion_optimizer/project_settings" element={<ProjectSettingsPage />} />
            <Route path="/completion_optimizer/run" element={<OptimizerRunPage />} />
            <Route path="/completion_optimizer/results" element={<OptimizerRunResultsPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const NotFound = () => {
  return (
    <div>No Pages</div>
  );
};
export default App;
