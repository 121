import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import { COLORS } from "app/codes";
import { Empty } from "antd";

function TimeSeriesChart(props, ref) {
  const { xSeries, dataSeries, config } = props.data;
  const eChartsComponent = useRef(null)

  useImperativeHandle(ref, () => ({
    getEchartsInstance: () => eChartsComponent.current?.getEchartsInstance?.()
  }))

  const {
    multiYAxis,
    startColorIndex,
    xAxisLabel,
    leftYAxisLabel,
    title,
    allCurveUsePoint,
    yAxisOffset = 50,
    yAxisNameGap = 35
  } = config || { multiYAxis: false, startColorIndex: 0 };

  const chartInitialOptions = {
    aria: {
      enabled: true,
      decal: {
        show: true,
      },
    },
    title: {
      text: title,
      left: "center",
      top: "3%",
      textStyle: {
        color: "rgba(255, 255, 255, 0.6)",
        fontSize: "1.5em",
      },
    },
    legend: {
      pageTextStyle: {
        width: 10,
        overflow: "truncate",
      },
      textStyle: {
        color: "#ccc",
      },
      top: "7.5%",
      left: "13%",
      width: "70%",
      type: "scroll",
      itemWidth: 20,
      pageIconSize: [15, 15],
      pageButtonItemGap: 0,
      pageButtonPosition: "start",
    },
    toolbox: {
      left: "right",
      itemSize: 18,
      top: 0,
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: { type: "png" },
      },
    },
    axisPointer: {
      show: true,
      snap: false,
      label: {
        precision: 3,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "5%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [""],
      name: xAxisLabel,
      nameLocation: "middle",
      nameGap: 25,
      nameTextStyle: {
        color: "#aaa",
      },
    },
    yAxis: [
      {
        type: "value",
        show: true,
        splitLine: {
          show: false,
          lineStyle: {
            opacity: "0.2",
            color: ["#fff"],
          },
        },
        name: leftYAxisLabel,
        nameLocation: "center",
        nameGap: yAxisNameGap,
        nameTextStyle: {
          color: "#aaa",
        },
      },
    ],
    series: [
      {
        name: " ",
        type: "line",
        data: [0],
      },
    ],
  };
  const [options, setOptions] = useState(chartInitialOptions);

  function buildChartOptions(xSeries, dataSeries) {
    let legendArr = [],
      seriesValues = [],
      xAxisData = [],
      yAxisArr = [];

    seriesValues = [];

    let leftYAxis = null

    // get yAxis, unit\name\index\offset\color, push into
    const getYAxis = (name, index, unit, {
      showAxisLine = true,
      offset = 0,
      lineName,
      labelFormatter
    } = {}) => {
      const isMainAxis = multiYAxis ? (!leftYAxis && (name === leftYAxisLabel || lineName === leftYAxisLabel)) : index === 0

      if (isMainAxis) leftYAxis = name

      return {
        type: "value",
        offset: isMainAxis ? 0 : offset,
        position: isMainAxis ? "left" : "right",
        axisLine: {
          onZero: false,
          show: showAxisLine,
          lineStyle: {
            color: multiYAxis ? COLORS[index] : "#888",
          },
        },
        splitLine: {
          show: index ? false : true,
          lineStyle: {
            opacity: "0.2",
            color: ["#fff"],
          },
        },
        axisTick: {
          show: showAxisLine,
          color: COLORS[index],
        },
        axisLabel: {
          show: showAxisLine,
          interval: "auto",
          formatter: labelFormatter
        },
        name: showAxisLine ? lineName ? lineName : `${ name }${ unit ? ` (${ unit })` : '' }` : '',
        nameLocation: 'center',
        nameGap: yAxisNameGap,
        nameRotate: isMainAxis ? 90 : -90,
      };
    };

    // get values, clean(calc, get monthly)
    const getValues = (model, data, index) => {
      return {
        name: model,
        type: "line",
        data: data.data,
        symbol: "circle",
        symbolSize: index === 0 || allCurveUsePoint ? 5 : 0,
        yAxisIndex: multiYAxis ? index : 0,
        lineStyle: {
          show: false,
          // opacity: (data.lineType === 'scatter' || allCurveUsePoint) ? 0 : 1,
          opacity: 1,
          smooth: true,
        },
        itemStyle: {
          color: COLORS[index + startColorIndex],
          borderWidth: 0,
          decal: {
            symbol: "rect",
          },
        },
      };
    };

    let currentRightOffset = 0
    if (dataSeries) {
      const seriesNames = Object.keys(dataSeries);
      seriesNames.forEach((seriesName, index) => {
        const dataObject = dataSeries[seriesName];
        legendArr.push(seriesName);

        if (index === 0 || multiYAxis) {
          if (dataObject.unit !== leftYAxisLabel && dataObject.showAxisLine) {
            currentRightOffset += yAxisOffset
          }

          const yAxis = getYAxis(seriesName, index, dataObject.unit, {
            showAxisLine: dataObject.showAxisLine,
            offset: currentRightOffset,
            lineName: dataObject.lineName,
            labelFormatter: dataObject.labelFormatter
          })

          yAxisArr.push(yAxis);
        }

        seriesValues.push(getValues(seriesName, dataObject, index));
      });
    }

    xAxisData.length === 0 && (xAxisData = xSeries);

    let tempOption = {
      ...options,
      title: {
        text: title,
        left: "center",
        textStyle: {
          color: "rgba(255, 255, 255, 0.6)",
          fontSize: "1.5em",
        },
      },
    };
    tempOption.grid.right = currentRightOffset + 100
    tempOption.xAxis.data = xAxisData;
    tempOption.xAxis.axisLabel = {
      ...(tempOption.xAxis.axisLabel ? tempOption.xAxis.axisLabel : {}),
      formatter: function (value, index) {
        if (!isNaN(value)) return value;
        var date = new Date(value);
        if (date === "Invalid Date") return value;
        var texts = [
          ("0" + (date.getMonth() + 1)).slice(-2),
          ("0" + date.getDate()).slice(-2),
          date.getFullYear(),
        ];
        return texts.join("/");
      },
    };
    tempOption.yAxis = yAxisArr;
    tempOption.series = seriesValues;
    setOptions(tempOption);
  }

  useEffect(() => {
    if (!xSeries || !dataSeries) return;

    buildChartOptions(xSeries, dataSeries);

    //eslint-disable-next-line
  }, [xSeries, dataSeries]);

  let seriesNames = [];
  if (dataSeries) {
    seriesNames = Object.keys(dataSeries);
  }

  return seriesNames.length > 0 ? (
    <ReactECharts
      ref={eChartsComponent}
      option={options}
      notMerge={true}
      style={{ width: "100%", height: "100%", minHeight: 240 }}
    />
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      style={{ color: "rgba(255,255,255, 0.6)", paddingTop: 80 }}
    >
      {"Loading..."}
    </Empty>
  );
}

export default forwardRef(TimeSeriesChart);
