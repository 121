import { isNil } from "lodash"
import max from "lodash/max"

export function isIncreasing(arr = []) {
  return !arr.some((el, index) => {
    if (isNil(arr[index - 1])) return false
    const prevValue = arr[index - 1] ?? 0
    return el <= prevValue
  })
}

export function isDecreasing(arr = []) {
  const maxValue = max(arr)
  return !arr.some((el, index) => {
    if (isNil(arr[index - 1])) return false
    const prevValue = arr[index - 1] ?? maxValue
    return el >= prevValue
  })
}