export const PARAM_NAMES = {
  oil_prd_rate: { name: "Oil Rate", ogw: true, unit: "bbl/m" },
  oil: { name: "Oil", ogw: true, unit: "bbl/m" },
  gas: { name: "Gas", ogw: true, unit: "Mscf/m" },
  liquid: { name: "Liquid", ogw: true, unit: "bbl/m" },
  gas_prd_rate: { name: "Gas Rate", ogw: true, unit: "Mscf/m" },
  water_prd_rate: { name: "Water Rate", ogw: true, unit: "bbl/m" },
  bottomhole_flow_pressure: {
    name: "Bottomhole Pressuree",
    ogw: false,
    unit: "kpa",
  },
  wellhead_tubing_pressure: {
    name: "Tubing Pressuree",
    ogw: false,
    unit: "kpa",
  },
  wellhead_casing_pressure: {
    name: "Casing Pressure",
    ogw: false,
    unit: "kpa",
  },
  average_wellhead_pressure: { name: "Average HTP", ogw: false, unit: "" },
  dayson: { name: "Dayson", ogw: false, unit: "" },
  fluid_level: { name: "Fluid Level", ogw: false, unit: "ft" },
};

export const DECLINE_PHASE_OPTIONS = [
  { name: "OIL", value: "oil" },
  { name: "GAS", value: "gas" },
  { name: "LIQUID", value: "liquid" },
];
