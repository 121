import React, { Fragment, useEffect, useState } from "react";
import { Typography, Radio, Slider, ConfigProvider, Button } from 'antd';
import { TwitterPicker } from 'react-color';
import { setBackgroundColorSetting, setFontColorSetting, selectBackgroundColorSetting, selectSettings, selectFontSettings, setFontSettings, selectFontColorSetting } from "features/petrophysics/petrophysicsSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;

const options = [
    {
        label: 'Well',
        value: 'well',
    },
    {
        label: 'Curve',
        value: 'curve',
    },
    {
        label: 'Scale',
        value: 'scale',
    },
];

const PpSettingSelector = (props) => {
    const { title, onClose } = props;

    const settings = useSelector(selectSettings, shallowEqual);
    const fontSetting = useSelector(selectFontSettings, shallowEqual);

    const [selectedFontItem, setSelectedFontItem] = useState(options[0].value);
    const [fontValue, setFontValue] = useState(0);
    const onChange1 = ({ target: { value } }) => {
        setSelectedFontItem(value);
        setFontValue(fontSetting[value]);
    };

    const handleSlider = (value) => {
        if (!selectedFontItem) return;
        let curValue = {};
        curValue[selectedFontItem] = value;
        dispatch(setFontSettings({ ...fontSetting, ...curValue }));
        setFontValue(value);
    };

    const backgroundColorSetting = useSelector(selectBackgroundColorSetting);
    const fontColorSetting = useSelector(selectFontColorSetting);
    const [showPicker, setShowPicker] = useState(false);
    const [backColor, setBackColor] = useState(backgroundColorSetting);
    const [fontColor, setFontColor] = useState(fontColorSetting);
    const [colorItem, setColorItem] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedFontItem || !fontSetting) return;
        setFontValue(fontSetting[selectedFontItem]);
    }, [selectedFontItem, fontSetting]);

    useEffect(() => {
        if (settings.color.background === '#000') return;
        localStorage.setItem("localSettings", JSON.stringify(settings));
        setBackColor(settings.color.background);
        setFontColor(settings.color.font);
        if (selectedFontItem) {
            setFontValue(settings.font[selectedFontItem]);
        }
    }, [settings, selectedFontItem]);

    return (
        <Fragment>
            {showPicker && <div className="popup-window" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <CloseCircleOutlined
                    style={{ position: 'relative', top: '12px', zIndex: 100 }}
                    onClick={() => setShowPicker(false)}
                />
                <TwitterPicker
                    color={colorItem === 'font' ? fontColor : backColor}
                    colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', 'black']}
                    triangle='hide'
                    onChangeComplete={(color) => {
                        colorItem === 'font' ? setFontColor(color.hex) : setBackColor(color.hex);
                        setShowPicker(false);
                        if (colorItem === 'font') {
                            dispatch(setFontColorSetting(color.hex));
                        }
                        if (colorItem === 'background') {
                            dispatch(setBackgroundColorSetting(color.hex));
                        }
                    }}
                    onAccept={() => {
                        if (colorItem === 'font') {
                            dispatch(setFontColorSetting(backColor));
                        }
                        if (colorItem === 'background') {
                            dispatch(setBackgroundColorSetting(backColor));
                        }
                        setShowPicker(false);
                    }}
                    onCancel={() => setShowPicker(false)}
                    onClick={() => setShowPicker(false)}
                    onClose={() => setShowPicker(false)}
                />
            </div>}
            <div className="selector d-flex flex-column w-100 h-100" style={{ alignItems: 'left' }}>
                <ConfigProvider
                    theme={{
                        components: {
                            Text: {
                                colorPrimary: '#eee',
                            }
                        },
                    }}
                >
                    <div
                        className="selector-select d-flex flex-column align-items-stretch"
                        style={{
                            textAlign: 'left',
                            fontSize: '15px',
                            color: 'rgba(255,255,255,0.8)',
                            background: 'rgba(0, 0, 0, 0.2)'
                        }}
                    >
                        {title || 'Settings'}
                    </div>

                    <Text
                        level={5}
                        style={{
                            textAlign: 'center',
                            color: 'rgba(255,255,255,0.5)',
                            fontSize: '1.5em'
                        }}
                    >
                        Font Size
                    </Text>
                    <div style={{ color: 'rgba(255,255,255,0.5)' }}>
                        <Radio.Group
                            onChange={onChange1}
                            value={selectedFontItem}
                            style={{
                                color: '#eee',
                                marginTop: '10px',
                                marginBottom: '5px'
                            }}
                        >
                            {
                                options.map((op, index) => (<Radio
                                    key={index}
                                    value={op.value}
                                    style={{ color: 'rgba(255, 255, 255, 0.5' }}
                                >
                                    {op.label}
                                </Radio>
                                ))
                            }
                        </Radio.Group>
                    </div>
                    <div style={{ width: '100%' }}>
                        <Slider
                            min={1}
                            max={5}
                            value={fontValue}
                            defaultValue={3}
                            onChange={handleSlider}
                            style={{ backgroundColor: 'rgba(0, 0, 255, 0.7)' }}
                        />
                    </div>
                    <Text
                        level={5}
                        style={{
                            textAlign: 'center',
                            color: 'rgba(255,255,255,0.5)',
                            fontSize: '1.5em'
                        }}
                    >
                        Color
                    </Text>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{
                            fontSize: 12,
                            color: 'rgba(255, 255, 255,0.5)',
                        }}>
                            Font :
                            <button style={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: fontColor
                            }}
                                onClick={() => {
                                    setColorItem('font');
                                    setShowPicker(!showPicker);
                                }}
                            />
                        </span>
                        <span
                            style={{
                                fontSize: 12,
                                color: 'rgba(255, 255, 255,0.5)',
                                marginLeft: '1em',
                            }}>
                            Background :
                            <button
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    backgroundColor: backColor
                                }}
                                onClick={() => {
                                    setColorItem('background');
                                    setShowPicker(!showPicker);
                                }}
                            /></span>
                    </div>
                    {onClose && <Button
                        size="small"
                        type="primary"
                        style={{ marginTop: '1em' }}
                        onClick={() => onClose(false)}>
                        Close
                    </Button>}
                </ConfigProvider>
            </div>
        </Fragment>
    )
};
export default PpSettingSelector;