import { Col, Input, Row } from "antd";
import React, { Fragment } from "react";
const { TextArea } = Input;

const CalcFormula = (props) => {
    const { formula, onFormulaChange, onCursorChange, newLogName } = props;

    return (
        <Fragment>
            <Row style={{ width: "100%", marginTop: '2em', marginRight: '1em' }} align="middle">
                <Col span={6}> * Log: {newLogName} =</Col>
                <Col span={18}>
                    <TextArea
                        rows={2}
                        value={formula}
                        onChange={onFormulaChange}
                        style={{ caretColor: 'black' }}
                        onSelect={(e) => onCursorChange && onCursorChange(e.target.selectionStart)}
                        placeholder="Please write formula..." />
                </Col>
            </Row>
        </Fragment >
    )
};
export default CalcFormula;