import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	selectLasDataStructure,
	setLasDataStructure,
	selectReload,
	setShowAddColumnPopup,
	setReload,
	selectShowAddColumnPopup,
} from "features/input/dataManagerSlice";
import { getWellLogInputConfig, addWellLogColumn } from "service/dataInput";

import NullFile from "./NullFile";
import AddLogName from "./AddLogName";

import AddIcon from "@mui/icons-material/Add";

function WellLogsInput(props) {
	const { inputData } = props;

	const lasDataStructure = useSelector(selectLasDataStructure);
	const reload = useSelector(selectReload);
	const showAddColumnPopup = useSelector(selectShowAddColumnPopup);

	const [logTypeDictionary, setLogTypeDictionary] = useState(null);
	const [logNameDictionary, setLogNameDictionary] = useState(null);

	const [selectedColumnObj, setSelectedColumnObj] = useState(null);

	let dispatch = useDispatch();

	function getInputColumns(inputData) {
		if (inputData == null || inputData.length < 3) {
			return null;
		}
		return inputData[2].elementValues;
	}

	function getLogsData(inputData) {
		if (inputData == null || inputData.length < 4) {
			return null;
		}
		return inputData[inputData.length - 1].elementValues;
	}

	function refreshData() {
		getWellLogInputConfig(
			(result) => {
				setLogTypeDictionary([
					{
						log_type: "N/A",
						unit_uk: null,
						unit_metric: null,
					},
					...result.log_types,
				]);
				setLogNameDictionary([...result.log_names]);
			},
			(error) => {
				console.error(error);
			}
		);
	}

	const initDataStructure = useCallback((inputData) => {
		let inputColumns = getInputColumns(inputData);

		const initialSelection = inputColumns.map((x, index) => {
			let columnName = x.name.toLowerCase();
			let logNameItem = logNameDictionary.find((item) => {
				return item.log_name.toLowerCase() === columnName.toLowerCase();
			});

			let itemIndex = null;
			if (logNameItem != null) {
				itemIndex = logTypeDictionary.findIndex((item) => {
					return item.log_type === logNameItem.log_type;
				});
			} else {
				itemIndex = 0;
			}
			let logTypeItem = logTypeDictionary[itemIndex];

			let columnItem = {
				log_name_id: null,
				log_type: logTypeItem.log_type,
				log_name: null,
			};
			if (logNameItem != null) {
				columnItem.log_name = logNameItem.log_name;
				columnItem.log_name_id = logNameItem.log_name_id;
			} else {
				columnItem.log_name = x.name;
			}

			return columnItem;
		});

		dispatch(setLasDataStructure(initialSelection));
	}, [dispatch, logNameDictionary, logTypeDictionary])

	useEffect(() => {
		refreshData();
	}, []);

	useEffect(() => {
		if (!reload) {
			return;
		}
		dispatch(setReload(false));
		refreshData();
	}, [dispatch, reload]);

	useEffect(() => {
		if (
			inputData == null ||
			logTypeDictionary == null ||
			logNameDictionary == null
		) {
			return;
		}
		initDataStructure(inputData);
	}, [inputData, logTypeDictionary, logNameDictionary, initDataStructure]);

	function handleChangeColumn(e, i) {
		const unpacked = lasDataStructure.map((el, index) => {
			if (index === i) {
				return { ...el, log_type: e.target.value };
			}
			return el;
		});
		dispatch(setLasDataStructure(unpacked));
	}

	function addCustomColumn(inputColumnObj) {
		let columnObj = {
			logName: inputColumnObj.name,
			logTypes: logTypeDictionary.map((el) => {
				return el.log_type;
			}),
		};
		setSelectedColumnObj(columnObj);
		dispatch(setShowAddColumnPopup(true));
	}

	function saveLogName(columnObj) {
		addWellLogColumn(
			{
				log_name: columnObj.logName,
				log_type: columnObj.logType,
				description: columnObj.description,
			},
			(result) => {
				setSelectedColumnObj({
					...selectedColumnObj,
					log_name_id: result.log_name_id,
				});

				const unpacked = lasDataStructure.map((el, index) => {
					if (el.log_name === columnObj.logName) {
						return {
							...el,
							log_name_id: result.log_name_id,
						};
					}
					return el;
				});
				dispatch(setLasDataStructure(unpacked));

				dispatch(setShowAddColumnPopup(false));
				dispatch(setReload(true));
			},
			(error) => {
				dispatch(setShowAddColumnPopup(false));
			}
		);
	}
	let inputColumns = getInputColumns(inputData);
	let logsData = getLogsData(inputData);

  if (
		lasDataStructure == null ||
		lasDataStructure.length === 0 ||
		lasDataStructure.length !== inputColumns.length
	) {
		return;
	}

	return (
		<div className="table-container d-flex flex-column justify-content-start align-items-start">
			{inputColumns == null || lasDataStructure == null ? (
				<NullFile />
			) : (
				<>
					{showAddColumnPopup && (
						<div className="popup-window">
							<AddLogName
								inputColumnObj={selectedColumnObj}
								saveLogName={saveLogName}
							/>
						</div>
					)}
					<div className="table-header">
						{inputColumns.map((x, i) => {
							return (
								<div className="header-cell" key={i}>
									{x.name}
									<i
										className="header-icon"
										onClick={(e) => {
											addCustomColumn(x);
										}}
									>
										<AddIcon />
									</i>
								</div>
							);
						})}
					</div>
					<div className="table-header">
						{inputColumns.map((x, i) => {
							return (
								<div className="header-cell" key={i}>
									<select
										id={`attribute_id${i}`}
										onChange={(e) => {
											handleChangeColumn(e, i);
										}}
										value={lasDataStructure[i].log_type}
									>
										{logTypeDictionary.map((y, j) => {
											return (
												<option
													key={i + "_" + j}
													value={y.log_type}
												>
													{y.log_type}
												</option>
											);
										})}
									</select>
								</div>
							);
						})}
					</div>
					<div className="table-body">
						{logsData.map((el, index) => {
							return (
								<div className="data-row" key={index}>
									{el.map((val, subIndex) => {
										return (
											<div
												className="data-cell"
												key={index + "_" + subIndex}
											>
												{val}
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
}

export default WellLogsInput;
