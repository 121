/*
    render multi curves for multi logs.
    support render with or without Y axis.
*/
import React, { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import { selectFontColorSetting, selectFontSettings, selectLogListForNewLog, selectTopNameList, selectTopsSelected } from "features/petrophysics/petrophysicsSlice";
import { useSelector } from "react-redux";
import { COLORS, FONT_SIZE, getColorIndexFromTopNamesList } from "app/codes";

const PpWellMultiCurve = (props) => {
    const { uwi, param, titleTooltip, tops, zoomRef, height, onTopCoordsChange, onChartReady, index, showZoomer, startEndValue, syncDepth } = props;
    const { dataSeries, yAxisboundary, config } = props;
    const [yAxisMin, yAxisMax] = yAxisboundary;
    const [xAxisMin, xAxisMax] = [0, 1000];
    const { multiYAxis, startColorIndex, xAxisLabel, showYAxis, columnWidth } = config || { multiYAxis: false, startColorIndex: 0 };

    const topNamesList = useSelector(selectTopNameList);
    const selectedTop = useSelector(selectTopsSelected);
    const fontColor = useSelector(selectFontColorSetting);
    const fontSize = useSelector(selectFontSettings)['curve'];
    const wellFontSize = useSelector(selectFontSettings)['well'];
    const labelFontSize = useSelector(selectFontSettings)['scale'];
    const logListForNewLog = useSelector(selectLogListForNewLog);
    const insRef = useRef();

    const getLineColor = (key) => (getColorIndexFromTopNamesList(topNamesList, key) !== -1)
        ? COLORS[getColorIndexFromTopNamesList(topNamesList, key) + 2]
        : 'rgba(0, 0, 0, 0)';

    const [options, setOptions] = useState({
        aria: {
            enabled: true,
            decal: {
                show: true,
            },
        },
        tooltip: {
            show: false,
            trigger: 'axis',
            axisPointer: {
                show: false,
            },
        },
        legend: {
            data: [' '],
            show: false,
            textStyle: {
                color: "#ccc",
            },
        },
        toolbox: {
            left: 'center',
            itemSize: 18,
            top: '95%',
            feature: {
            }
        },
        dataZoom: [
            {
                type: 'slider',
                show: showZoomer,
                yAxisIndex: [0],
                zoomOnMouseWheel: true,
                moveOnMouseMove: true,
                moveOnMouseWheel: true,
                width: 16,
                start: startEndValue && startEndValue[0],
                end: startEndValue && startEndValue[1],
            },
        ],
        title: {
            show: false,
            text: param,
            subtext: titleTooltip,
            subtextStyle: {
                color: fontColor,
            },
            left: 'left',
            padding: [10, 0, 0, 30],
            itemGap: 0,
            top: parseInt(21 * (parseInt(height) / 880)),
            textStyle: {
                color: fontColor,
                fontSize: FONT_SIZE[fontSize],
            }
        },
        grid: {
            left: "0%",
            right: "0%",
            bottom: "5%",
            top: '10%',
            containLabel: true,
        },
        xAxis: {
            type: param === "resistivity" ? "log" : "value",
            data: dataSeries,
            min: xAxisMin !== undefined ? xAxisMin : 'dataMin',
            max: xAxisMax !== undefined ? xAxisMax : 'dataMax',
            position: 'top',
            name: xAxisLabel,
            nameLocation: 'middle',
            nameGap: 15,
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: "0.6",
                    color: "#888",
                },
            },
            axisPointer: {
                show: true,
                label: {
                    show: false,
                    precision: 0,
                    // formatter: '{y}'
                },
                snap: true,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                color: fontColor,
                inside: true,
                rotate: 90,
                position: 'top',
                fontSize: FONT_SIZE[labelFontSize],
                lineHeight: 100,
                height: 50,
                hideOverlap: false,
                // formatter: function (value, index) {
                //     return ("00000" + value.toString()).slice(-4);
                // }
            },
            nameTextStyle: {
                color: fontColor,
            },
        },
        yAxis: [{
            type: "category",
            show: true,
            position: "left",
            inverse: true,
            splitLine: {
                show: false,
                lineStyle: {
                    opacity: "0.2",
                    color: ["#fff"],
                },
            },
            name: index === 0 ? uwi : '',
            nameLocation: 'start',
            nameGap: parseInt(33 * (parseInt(height) / 880)),
            nameTextStyle: {
                color: fontColor,
                fontSize: FONT_SIZE[wellFontSize],
                padding: [0, 0, 0, -13],
                align: "left",
            },
            axisLine: {
                onZero: false,
                show: true,
                lineStyle: {
                    color: "#aaa",
                },
            },
            axisLabel: {
                color: fontColor,
                fontSize: FONT_SIZE[labelFontSize],
            },
        }],
        series: [{
            name: ' ',
            type: 'line',
            data: [0],
        }],
    });

    /*
                {
                "combined": {       // could be found in dataSeries
                    "Neutron:ni": [...],
                    "Density:di":[...],
                },
                "ACOUSTIC": {       // could be found in dataSeries
                    "ACOUSTIC:aco": [...],
                    "ACOUSTIC:aa": [...],
                },*/
    useEffect(() => {
        getChartOptions();
    }, [dataSeries, startEndValue, selectedTop, fontColor, fontSize, wellFontSize, labelFontSize]);

    let legendArr = [], seriesValues = [], xAxisData = [], yAxisArr = [];
    function getChartOptions() {
        if (!dataSeries) return;
        seriesValues = [];

        const getXAxis = (dataSeries) => {
            if (!dataSeries) return [];
            const xArr = [];
            Object.keys(dataSeries).map((logId, index) => {
                const [logType, logName] = logId.split(":");
                const [, , unitMin, unitMax] = dataSeries[logId][0];
                return xArr.push({
                    type: logType === "Resistivity" ? "log" : "value",
                    id: index,
                    position: 'top',
                    name: `${logName}${(logListForNewLog && logListForNewLog[logId]) ? (' (' + logListForNewLog[logId].log_unit) + ')' : ''}`,
                    nameLocation: 'middle',
                    min: (unitMin === undefined || unitMin === null) ? 'dataMin' : unitMin,
                    max: unitMax || 'dataMax',
                    offset: 15 * index,
                    nameGap: 0,
                    splitLine: {
                        show: index === 0,
                        lineStyle: {
                            opacity: "0.6",
                            color: "#888",
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: COLORS[index + startColorIndex],
                        }
                    },
                    axisPointer: {
                        show: true,
                        label: {
                            show: true,
                            precision: 0,
                        },
                        snap: true,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: index === 0,
                        color: fontColor,
                        inside: true,
                        rotate: 90,
                        position: 'top',
                        fontSize: FONT_SIZE[labelFontSize],
                        lineHeight: 100,
                        height: 50,
                        hideOverlap: false,
                    },
                    nameTextStyle: {
                        // color: fontColor,
                        color: index === 0 ? fontColor : COLORS[index + startColorIndex],
                        textBorderColor: 'grey',
                        textBorderWidth: 1,
                        fontSize: FONT_SIZE[fontSize],
                    },
                })
            });
            return xArr;
        }

        const getSeriesData = (dataSeries) => {
            if (!dataSeries) return;
            const result = Object.keys(dataSeries).map((logId, index) => {
                const [, logName] = logId.split(":");
                return {
                    name: logName,
                    type: 'line',
                    data: dataSeries[logId].map(item => [item[1], item[0]]),
                    symbol: 'none',
                    symbolSize: index === 0 ? 4 : 0,
                    yAxisIndex: multiYAxis ? index : 0,
                    xAxisIndex: index,
                    lineStyle: {
                        color: COLORS[index + startColorIndex],
                        // opacity: data.lineType === 'scatter' ? 0 : 1,
                        smooth: true,
                    },
                    itemStyle: {
                        color: COLORS[index + startColorIndex],
                        borderWidth: 0,
                        decal: {
                            symbol: 'rect',
                        }
                    },
                    markLine: {
                        silent: true,
                        symbol: 'none',
                        lineStyle: {
                            color: '#0f0',
                            type: 'solid',
                        },
                        data: tops && selectedTop && selectedTop.map((key, index) => {
                            return {
                                yAxis: tops[key] || '',
                                lineStyle: {
                                    color: getLineColor(key),
                                },
                                label: {
                                    position: 'start',
                                    formatter: `${key}`,
                                    color: getLineColor(key),
                                    overflow: 'truncate',
                                    width: 40,
                                },
                            }
                        }),
                    }
                }
            });
            return result;
        }

        // get yAxis, unit\name\index\offset\color, push into
        const getYAxis = (name, item, unit) => {
            return {
                type: "value",
                offset: 0,
                position: "left",
                inverse: true,
                show: true,
                min: yAxisMin ?? 'dataMin',
                max: yAxisMax ?? 'dataMax',
                axisPointer: {
                    show: true,
                    label: {
                        show: index === 0,
                        precision: 0,
                        // formatter: '{y}'
                    },
                    snap: true,
                },
                axisLine: {
                    onZero: false,
                    show: true,
                    lineStyle: {
                        color: multiYAxis ? COLORS[item] : "#aaa",
                    },
                },
                splitLine: {
                    show: item ? false : true,
                    lineStyle: {
                        opacity: "0.2",
                        color: "#888",
                    },
                },
                axisTick: {
                    show: showYAxis,
                    color: COLORS[item],
                },
                axisLabel: {
                    show: showYAxis,
                    interval: 'auto',
                    color: fontColor,
                    fontSize: FONT_SIZE[labelFontSize],
                },
                name: index === 0 ? uwi : '',
                nameLocation: 'start',
                nameGap: parseInt(33 * (parseInt(height) / 440)),
                nameTextStyle: {
                    color: fontColor,
                    padding: [0, 0, 0, -13],
                    fontSize: FONT_SIZE[wellFontSize],
                    align: "left",
                },
            }
        };

        const prepareOptions = () => {
            let tempOption = { ...options };
            tempOption.legend.data = legendArr;
            tempOption.xAxis = getXAxis(dataSeries);
            tempOption.title.text = param;
            tempOption.title.textStyle.color = fontColor;
            tempOption.title.subtextStyle.color = fontColor;
            tempOption.title.textStyle.fontSize = FONT_SIZE[fontSize];
            tempOption.yAxis = yAxisArr;
            tempOption.series = getSeriesData(dataSeries);
            tempOption.dataZoom[0].start = startEndValue && startEndValue[0];
            tempOption.dataZoom[0].end = startEndValue && startEndValue[1];
            tempOption.dataZoom[0].show = showZoomer;

            return tempOption;
        }

        ((result) => {
            let seriesName = param;
            legendArr.push(uwi);

            // (xAxisData.length === 0) && (xAxisData = xSeries);

            yAxisArr = [getYAxis(seriesName, 0, '')];

            // seriesValues.push(getValues(seriesName, dataSeries.map(item => [item[1], item[0]]), 0));

            setOptions(prepareOptions(legendArr, xAxisData, yAxisArr, seriesValues));
        })();
    }

    const getTopCoords = (uwi, ins, startEnd) => {
        if (!tops) return [uwi, null, index, startEnd];
        let res = {};

        Object.keys(tops).map(topName => {
            if (tops[topName] === null || tops[topName] === undefined) {
                res[topName] = null;
                return null;
            }
            res[topName] = parseFloat(ins.convertToPixel({ yAxisIndex: 0 }, tops[topName]));
            return null;
        });

        return [uwi, res, index, startEnd];
    }

    const handleZoom = async (e) => {
        zoomRef.current = [e.start, e.end, e.from];
        insRef && insRef.current && onTopCoordsChange(getTopCoords(uwi, insRef.current.getEchartsInstance(), [e.start, e.end, e.from]));
    }
    return (
        <div style={{ width: columnWidth, height: '50vh' }}>
            {
                (<ReactECharts
                    option={options}
                    notMerge={true}
                    ref={insRef}
                    opts={{
                        width: columnWidth,
                        height: (height || 750) + 'px',
                    }}
                    width={columnWidth}
                    onChartReady={ins => {
                        ins.group = syncDepth ? 'shale' : uwi;
                        // tops && onTopCoordsChange && onTopCoordsChange(getTopCoords(uwi, ins));
                        onChartReady(getTopCoords(uwi, ins));
                    }}
                    onEvents={{
                        "datazoom": handleZoom,
                    }}
                />)
            }
        </div>
    )
};
export default PpWellMultiCurve;