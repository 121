import React, { useMemo, useState } from 'react'
import { RECORD_FIELDS_MAP } from './consts'
import TimeSeriesChart from 'components/common/TimeSeriesChart'
import { Select } from 'antd'

const curves = Object.keys(RECORD_FIELDS_MAP).filter(key => key !== 'date')

const PerformanceMetricsChart = ({ records }) => {
  const [visibleCurves, setVisibleCurves] = useState(curves)

  const chartData = useMemo(() => {
    if (!records.length || !visibleCurves.length) return {}
    const dateSeries = []

    const unitAxes = {}

    const series = records.reduce((state, record) => {
      const date = record.date?.slice(0, 10)
      if (date) dateSeries.push(date)
      curves.forEach((key) => {
        if (!visibleCurves.includes(key)) return
        const param = RECORD_FIELDS_MAP[key]
        if (!state[param.name]) {
          state[param.name] = {
            data: [],
            lineType: "line",
            showAxisLine: !unitAxes[param.unit],
            lineName: param.unit,
            labelFormatter: (value) => {
              if (param.unit !== '$') return value
              return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
              }).format(value);
            }
          }
        }
        state[param.name].data.push(record[key] ?? null)
        if (param.unit) {
          unitAxes[param.unit] = true
        }
      })
      return state
    }, {})

    const _dataSeries = {
      xSeries: dateSeries,
      config: {
        multiYAxis: true,
        leftYAxisLabel: "$/boe",
      },
      dataSeries: series,
    };

    return _dataSeries
  }, [records, visibleCurves])

  const onSelectCurve = (curveType) => {
    setVisibleCurves(prev => [...prev, curveType])
  }

  const onDeselectCurve = (curveType) => {
    setVisibleCurves(prev => prev.filter(type => type !== curveType))
  }

  return (
    <div className="vstack gap-2">
      <Select
        mode="multiple"
        dropdownMatchSelectWidth={false}
        value={visibleCurves}
        options={curves.map((key) => ({
          value: key,
          label: RECORD_FIELDS_MAP[key]?.name
        }))}
        placeholder="Select curves"
        style={{
          minWidth: 150
        }}
        onSelect={onSelectCurve}
        onDeselect={onDeselectCurve}
      />
      <TimeSeriesChart data={chartData} />
    </div>
  )
}

export default PerformanceMetricsChart