import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { message } from "antd";

import { selectActiveProject } from "features/project/projectsSlice";

import { selectActiveWells } from "features/asset/assetSelectorSlice";

import {
  selectAllParams,
  selectUsedWellInterpoParams,
  selectCustomWells,
  selectSelectedWellIds,
  selectUpdatedCustomWells,
} from "features/well_planner/plannerSlice";

import {
  setWellGroups,
  setWellClusters,
  setPerformanceParams,
  selectPerformanceParams,
  selectWellGroups,
  // selectProjectGroupDataItems,
} from "features/well_planner/plannerDcaSlice";

import { geoDistance } from "utils/geo";
import { findHyperWellNearest } from "service/wellPlanner";

import {
  deleteProjectGroups,
  deleteProjectClusters,
} from "service/wellPlannerDca";

function PerformanceSetting(props) {
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const currentProject = useSelector(selectActiveProject);

  const allParams = useSelector(selectAllParams);
  const usedWellInterpoParams = useSelector(selectUsedWellInterpoParams);
  const customWells = useSelector(selectCustomWells);
  const updatedCustomWells = useSelector(selectUpdatedCustomWells);

  const activeWells = useSelector(selectActiveWells);
  const selectedWellIds = useSelector(selectSelectedWellIds);

  const selectedPerfParams = useSelector(selectPerformanceParams);
  const savedWellGroups = useSelector(selectWellGroups);
  // const projectGroupDataItems = useSelector(selectProjectGroupDataItems);

  const [isShown, setIsShown] = useState(false);

  const dispatch = useDispatch();

  const [paramsList, setParamsList] = useState(null);

  const [wellsReferenceMap, setWellsReferenceMap] = useState(null);
  const [activeCustomWellIndex, setActiveCustomWellIndex] = useState(null);
  const [activeReferenceWellIndex, setActiveReferenceWellIndex] =
    useState(null);

  const selectedWells = useMemo(() => {
    let selectedWells = [];
    if (
      selectedWellIds != null &&
      selectedWellIds.length > 0 &&
      activeWells != null &&
      activeWells.length > 0
    ) {
      let wellIdsSet = new Set(selectedWellIds);
      selectedWells = activeWells.filter((well) => wellIdsSet.has(well.uwi));
    }
    return selectedWells;
  }, [activeWells, selectedWellIds]);

  useEffect(() => {
    if (allParams == null || usedWellInterpoParams == null) {
      return;
    }
    let availableParamsSet = new Set(
      usedWellInterpoParams.map((item) => {
        return item.param;
      })
    );
    setParamsList(
      allParams.map((item, index) => {
        let selected = false;
        if (selectedPerfParams != null && selectedPerfParams.length > 0) {
          selected = selectedPerfParams.includes(item);
        }
        return {
          name: item,
          selected: selected,
          available: availableParamsSet.has(item),
        };
      })
    );
  }, [allParams, usedWellInterpoParams, selectedPerfParams]);

  useEffect(() => {
    if (customWells == null || selectedWells == null) {
      return;
    }

    let wellsMap = {};
    for (let customWell of customWells) {
      let savedWellIds = null;
      if (savedWellGroups != null) {
        savedWellIds = savedWellGroups[customWell.well_uwi];
      }
      let referenceWells = selectedWells.map((well) => {
        let selected = false;
        if (savedWellIds && savedWellIds.length > 0) {
          selected = savedWellIds.find((wellId) => wellId === well.uwi) != null;
        }
        return {
          uwi: well.uwi,
          well_name: well.well_name,
          distance: geoDistance(
            customWell.landing_x,
            customWell.landing_y,
            well.latitude,
            well.longitude,
            "F"
          ),
          selected: selected,
        };
      });

      referenceWells.sort((x1, x2) => {
        return x1.distance - x2.distance;
      });
      wellsMap[customWell.well_uwi] = referenceWells;
    }
    setWellsReferenceMap(wellsMap);
  }, [selectedWells, customWells, savedWellGroups]);

  function handleSave() {
    if (!currentProject) {
      message.error("Please create a project first !");
      return;
    }

    let selectedParams = paramsList.filter((item) => item.selected);
    if (
      !selectedParams?.length ||
      customWells.length === 0 ||
      selectedWells.length === 0
    ) {
      message.error("Please select at least 1 parameter, 1 well !");
      return;
    }

    let wellGroups = {};
    for (let customWell of customWells) {
      let referenceWells = wellsReferenceMap[customWell.well_uwi];
      let selectedItems = referenceWells.filter((item) => item.selected);
      if (!selectedItems?.length) {
        message.error("Please select at least one parameter and one well !");
        return;
      }
      let wellIds = selectedItems.map((item) => item.uwi);
      wellGroups[customWell.well_uwi] = wellIds;
    }
    dispatch(setWellGroups(wellGroups));

    showLoadingIcon && showLoadingIcon();
    deleteProjectGroups(
      currentProject.id,
      null,
      (result) => {
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );

    let params = selectedParams.map((item) => {
      return item.name;
    });

    dispatch(setPerformanceParams(params));

    showLoadingIcon && showLoadingIcon();
    deleteProjectClusters(
      currentProject.id,
      null,
      (result) => {
        // hideLoadingIcon && hideLoadingIcon();
        // showLoadingIcon && showLoadingIcon();
        let wellIds = selectedWells.map((well) => well.uwi);
        findHyperWellNearest(
          currentProject.id,
          wellIds,
          null,
          updatedCustomWells,
          params,
          (result) => {
            let wellNeighbors = result.well_neighbors;
            let hyperWellIds = Object.keys(wellNeighbors);
            let clusters = {};
            for (let hyperWellId of hyperWellIds) {
              let clusterWellIds = wellNeighbors[hyperWellId];
              clusters[hyperWellId] = clusterWellIds;
            }

            dispatch(setWellClusters(clusters));

            hideLoadingIcon && hideLoadingIcon();
          },
          (error) => {
            console.error(error);
            hideLoadingIcon && hideLoadingIcon();
          }
        );
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
    return true;
  }

  function handleParamSelectedChanged(index) {
    let tempList = [...paramsList];
    let param = { ...paramsList[index] };
    param.selected = !param.selected;
    tempList[index] = param;

    setParamsList(tempList);
  }

  function handleReferenceWellSelectedChanged(index) {
    let customWell = customWells[activeCustomWellIndex];
    let tempWellMap = { ...wellsReferenceMap };
    let referenceWells = tempWellMap[customWell.well_uwi];
    let tempWell = { ...referenceWells[index] };
    tempWell.selected = !tempWell.selected;
    referenceWells[index] = tempWell;
    setWellsReferenceMap(tempWellMap);
  }

  function generateReferencesSelector() {
    if (customWells == null || wellsReferenceMap == null) {
      return;
    }

    return customWells.map((customWell, index) => {
      let selected = activeCustomWellIndex === index;
      let itemClass = "list-item text-center mb-2 text-nowrap";
      if (selected) {
        itemClass += " selected";
      }
      return (
        <div
          key={"custom_well_" + index}
          className={itemClass}
          onClick={(e) => {
            setActiveCustomWellIndex(index);
            setActiveReferenceWellIndex(null);
          }}
        >
          {customWell.well_name}
        </div>
      );
    });
  }

  function generateReferenceWellsSelector() {
    if (wellsReferenceMap == null || activeCustomWellIndex == null) {
      return;
    }
    let customWell = customWells[activeCustomWellIndex];
    let referenceWells = wellsReferenceMap[customWell.well_uwi];

    return referenceWells.map((item, index) => {
      // let well = item.well;
      let selected = activeReferenceWellIndex === index;
      let itemClass =
        "list-item text-center mb-2 d-flex flex-row justify-content-between align-items-center";
      if (selected) {
        itemClass += " selected";
      }
      return (
        <div
          className={itemClass}
          key={"ref_well_" + activeCustomWellIndex + "_" + index}
          onClick={(e) => {
            setActiveReferenceWellIndex(index);
          }}
        >
          <div className="py-2 text-nowrap">{item.well_name}</div>
          <input
            className="py-2 ms-2"
            type="checkbox"
            onChange={(e) => {
              handleReferenceWellSelectedChanged(index);
            }}
            checked={item.selected}
          ></input>
        </div>
      );
    });
  }

  function generateParamsList() {
    if (paramsList == null || paramsList.length === 0) {
      return null;
    }

    return paramsList.map((item, index) => {
      return (
        <div
          key={"param_" + index}
          className="list-item d-flex justify-content-between align-items-center mb-2 pe-4"
        >
          <div className="me-4">{item.name}</div>
          <input
            className="py-2"
            type="checkbox"
            onChange={(e) => {
              handleParamSelectedChanged(index);
            }}
            checked={item.selected}
            disabled={!item.available}
          ></input>
        </div>
      );
    });
  }
  return (
    <div className="d-flex flex-column w-100 h-100">
      <div className="py-2 d-flex justify-content-between align-items-center">
        <div className="">Performance Prediction</div>
        <div
          className=""
          onClick={(e) => {
            props.close();
          }}
        >
          X
        </div>
      </div>
      <div className="row d-flex h-75">
        <div className="col-4 d-flex flex-column h-100">
          <div className="py-2 d-flex justify-content-center">
            Machine-learning-based
          </div>
          <div className="d-flex flex-column overflow-auto hide-scrollbar flex-grow-1">
            {generateParamsList()}
          </div>
        </div>
        <div className="col-4 d-flex flex-column h-100">
          <div className="py-2 d-flex justify-content-center">Hyper Wells</div>
          <div className="col d-flex flex-column align-items-stretch overflow-auto hide-scrollbar h-100">
            {generateReferencesSelector()}
          </div>
        </div>
        <div className="col-4 d-flex flex-column h-100">
          <div className="py-2 d-flex justify-content-center">
            User-grouping-based
          </div>
          <div className="row d-flex flex-grow-1 overflow-hidden">
            <div className="col d-flex flex-column align-items-stretch overflow-auto hide-scrollbar h-100">
              {generateReferenceWellsSelector()}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center py-4">
        <button
          className="btn-custom"
          onClick={(e) => {
            handleSave() && props.close();
          }}
        >
          Calculate
        </button>
      </div>
      <div className="d-flex justify-content-end align-items-right py-4">
        {isShown && (
          <div
            style={{
              position: "absolute",
              top: "75%",
              bottom: "5%",
              left: "30%",
              right: "30%",
              opacity: "0.8",
              backgroundColor: "#222",
            }}
          >
            This page is for the setting of predictive modes. There are two
            models to forecast the performance of designed wells:
            <br />
            Model_1: Machine-learning-based, which needs the user to select
            relevant parameters for clustering
            <br />
            Model_2: User-grouping-based. Using Arp&apos;s decline theory, which
            needs the user to select the most relevant wells to generate type
            curves
          </div>
        )}
        <QuestionCircleTwoTone
          onMouseOver={() => setIsShown(true)}
          height={"50em"}
          onMouseLeave={() => setIsShown(false)}
        />
      </div>
    </div>
  );
}

export default PerformanceSetting;
