import NewProjectLayout from "pages/project/NewProjectLayout";
import { ProjectTypes } from "service/projects";

const DeclineAnalysisCreateProject = () => {
  return (
    <NewProjectLayout
      input={{
        project_type: ProjectTypes[0].value,
        project_name: "",
        comment: "",
        redirect: "/project_summary/",
      }}
    />
  );
};
export default DeclineAnalysisCreateProject;