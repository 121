import React, { useState } from "react";
import { AssetSummary } from "../tables/AssetSummary";
import { AssetMap } from "../charts/AssetMap";
import { AssetHistoricalPanel } from "../charts/AssetHistoricalPanel";
import Alarm from "../tables/Alarm";
import EventLog from "../tables/EventLog";
import { WellStatusChart } from "../charts/WellStatusChart";
import Planning from "../tables/AssetPlanning";
import useLoading from "components/common/useLoading";
import { Spin } from "antd";

const AssetView = () => {
  const [activeAssetId, setActiveAssetId] = useState(null);
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(<Spin size="large" />, 50);

  return (
    <div className="page-content hide-scrollbar">
      <LoadingIcon />
      <AssetSummary
        assetId={activeAssetId}
        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
      />
      <div className="row row-wrapper g-0 mt-2 mb-2">
        <div className="col pe-1">
          <AssetMap
            showWellSelects={false}
            assetSelected={setActiveAssetId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
        <div className="col ps-1">
          <AssetHistoricalPanel
            assetId={activeAssetId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
      </div>
      <div className="row row-wrapper g-0 mb-2">
        <div className="col pe-1">
          <Alarm
            assetId={activeAssetId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
        <div className="col ps-1">
          <EventLog
            assetId={activeAssetId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
      </div>
      <div className="row row-wrapper g-0 mb-2">
        <div className="col w-50 pe-1">
          <WellStatusChart
            assetId={activeAssetId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
        <div className="col w-50 ps-1">
          <Planning
            assetId={activeAssetId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
      </div>
    </div>
  );
};
export default AssetView;
