import SectionSetting from "components/common/SectionSetting";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getWellCompletionString } from "service/wellCompletion";

function WellArchitect(props) {
  const { wellId } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const [wellCompletion, setWellCompletion] = useState();

  useEffect(() => {
    if (wellId == null || wellId === "") {
      return;
    }

    showLoadingIcon && showLoadingIcon();
    getWellCompletionString(
      [wellId],
      (result) => {
        let completions = result.completions;
        if (completions != null && completions.length > 0) {
          setWellCompletion(completions[0]);
        }
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }, [wellId, hideLoadingIcon, showLoadingIcon]);

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Wellbore Schematic</span>
        <div className="header-menu-container d-flex flex-row">
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content">
        <div className="chart-container">
          <div className="well-arhitect h-100 p-12 m-2">
            <svg
              className="w-100 h-100"
              viewBox="0 0 666 530"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="252.691"
                y="11.3984"
                width="18.9963"
                height="129.175"
                fill="#264C6D"
              />
              <rect
                x="250.445"
                y="140.57"
                width="21.2426"
                height="32.2956"
                fill="#1E3967"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M252.691 11.3984H237.494V159.591L220.478 172.869L240.53 172.867L240.53 172.867H252.691V11.3984Z"
                fill="#1E3967"
              />
              <path
                d="M286.885 143.42L271.688 115.875V349.5L279 352.5L286.885 355V143.42Z"
                fill="#264C6D"
              />
              <rect
                width="18.9963"
                height="129.175"
                transform="matrix(-1 0 0 1 381.078 11.3984)"
                fill="#264C6D"
              />
              <rect
                width="21.2426"
                height="32.2956"
                transform="matrix(-1 0 0 1 383.324 140.57)"
                fill="#1E3967"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M381.078 11.3984H396.275V159.591L413.291 172.869L393.24 172.867L393.24 172.867H381.078V11.3984Z"
                fill="#0072E1"
                fillOpacity="0.2"
              />
              <path
                d="M346.885 143.42L362.082 115.875L362.5 350.5L355 352.5L346.885 354.283V143.42Z"
                fill="#264C6D"
              />
              <path
                d="M274.237 370.336L281.275 371.672L288.365 372.46V463.563H273.961L274.237 370.336Z"
                fill="#1E3967"
              />
              <path
                d="M288.365 372.395L296.432 373.402V445.705H288.365V372.395Z"
                fill="#264C6D"
              />
              <rect
                x="283.262"
                y="445.703"
                width="13.6826"
                height="17.8587"
                fill="#1E3967"
              />
              <path
                d="M263.005 463.567L275.055 455.259L276.135 463.566L263.005 463.567Z"
                fill="#1E3967"
              />
              <path
                d="M305.383 399.778L295.885 385.625V505.637L305.383 509.101V399.778Z"
                fill="#264C6D"
              />
              <path
                d="M340.96 372.683V463.312H354.863L354.596 370.668L347.845 371.917L347.836 371.919L347.827 371.92L340.96 372.683Z"
                fill="#1E3967"
                stroke="#1E3967"
                strokeWidth="0.5"
              />
              <path
                d="M340.71 372.395L332.642 373.402V445.705H340.71V372.395Z"
                fill="#264C6D"
              />
              <rect
                width="13.6826"
                height="17.8587"
                transform="matrix(-1 0 0 1 345.813 445.703)"
                fill="#1E3967"
              />
              <path
                d="M366.07 463.563L354.019 455.255L352.94 463.562L366.07 463.563Z"
                fill="#1E3967"
              />
              <path
                d="M323.692 399.778L333.19 385.625V505.637L323.692 509.101V399.778Z"
                fill="#264C6D"
              />
              <rect
                x="112"
                y="9"
                width="410"
                height="5"
                rx="2.5"
                fill="#264C6D"
              />
              <path
                d="M309.5 357.684L309.5 10.9991"
                stroke="white"
                strokeOpacity="0.1"
              />
              <path
                d="M324.5 357.617L324.5 11.002"
                stroke="white"
                strokeOpacity="0.1"
              />
              <line
                x1="316.5"
                y1="56.4883"
                x2="316.5"
                y2="0.499435"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <line
                x1="316.5"
                y1="79.2852"
                x2="316.5"
                y2="66.9878"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <line
                x1="316.5"
                y1="136.273"
                x2="316.5"
                y2="87.8831"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <line
                x1="316.5"
                y1="170.465"
                x2="316.5"
                y2="161.967"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <line
                x1="316.5"
                y1="244.57"
                x2="316.5"
                y2="194.245"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <line
                x1="316.5"
                y1="263.551"
                x2="316.5"
                y2="251.253"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <line
                x1="316.5"
                y1="318.637"
                x2="316.5"
                y2="272.146"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <path
                d="M310.5 505.109L310.5 373.916"
                stroke="white"
                strokeOpacity="0.1"
              />
              <path
                d="M318.5 505.086L318.5 373.918"
                stroke="white"
                strokeOpacity="0.1"
              />
              <path
                d="M314.25 419.402V373.675"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <line
                x1="314.25"
                y1="442.199"
                x2="314.25"
                y2="429.902"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />
              <line
                x1="314.25"
                y1="499.188"
                x2="314.25"
                y2="450.797"
                stroke="white"
                strokeOpacity="0.1"
                strokeLinecap="round"
              />

              {/* Left */}
              <path
                d="M262.189 60.664L256.459 59.9535L258.709 65.2707L262.189 60.664ZM163.311 102.495L163.312 102.995L163.413 102.995L163.505 102.956L163.311 102.495ZM257.85 61.9568L163.116 102.035L163.505 102.956L258.24 62.8778L257.85 61.9568ZM163.309 101.995L86.6175 102.21L86.6203 103.21L163.312 102.995L163.309 101.995Z"
                fill="white"
                fillOpacity="0.2"
              />
              {/* <path
                d="M277.575 172.938L271.843 172.244L274.108 177.555L277.575 172.938ZM163.311 221.679L163.312 222.179L163.414 222.179L163.507 222.139L163.311 221.679ZM273.239 174.243L163.115 221.219L163.507 222.139L273.632 175.163L273.239 174.243ZM163.309 221.179L86.6177 221.394L86.6205 222.394L163.312 222.179L163.309 221.179Z"
                fill="white"
                fillOpacity="0.2"
              /> */}
              <path
                d="M276.949 296.68L271.217 295.986L273.483 301.297L276.949 296.68ZM162.685 345.421L162.686 345.921L162.788 345.921L162.881 345.881L162.685 345.421ZM272.614 297.986L162.489 344.961L162.881 345.881L273.006 298.905L272.614 297.986ZM162.684 344.921L85.9919 345.136L85.9947 346.136L162.686 345.921L162.684 344.921Z"
                fill="white"
                fillOpacity="0.2"
              />
              <path
                d="M293.765 416.495L288.053 415.653L290.18 421.021L293.765 416.495ZM162.685 468.425L162.686 468.925L162.781 468.924L162.869 468.889L162.685 468.425ZM289.397 417.688L162.501 467.96L162.869 468.889L289.766 418.617L289.397 417.688ZM162.684 467.925L85.9919 468.14L85.9947 469.14L162.686 468.925L162.684 467.925Z"
                fill="white"
                fillOpacity="0.2"
              />
              {/* Right 1->4*/}
              <path
                d="M369.812 41.6005L375.481 42.6959L371.698 47.0574L369.812 41.6005ZM449.788 110.969L449.776 111.469L449.596 111.464L449.461 111.346L449.788 110.969ZM373.539 44.1713L450.116 110.591L449.461 111.346L372.884 44.9268L373.539 44.1713ZM449.801 110.469L530.687 112.469L530.662 113.469L449.776 111.469L449.801 110.469Z"
                fill="white"
                fillOpacity="0.2"
              />
              {/* <path
                d="M356.395 147.142L362.05 148.304L358.216 152.621L356.395 147.142ZM442.476 223.594L442.465 224.094L442.282 224.089L442.144 223.968L442.476 223.594ZM360.091 149.757L442.808 223.22L442.144 223.968L359.427 150.504L360.091 149.757ZM442.488 223.094L530.374 225.094L530.351 226.094L442.465 224.094L442.488 223.094Z"
                fill="white"
                fillOpacity="0.2"
              /> */}
              <path
                d="M349.551 267.717L355.225 268.787L351.461 273.165L349.551 267.717ZM442.477 347.594L442.465 348.094L442.286 348.089L442.151 347.973L442.477 347.594ZM353.29 270.271L442.803 347.215L442.151 347.973L352.638 271.029L353.29 270.271ZM442.488 347.094L530.374 349.094L530.351 350.094L442.465 348.094L442.488 347.094Z"
                fill="white"
                fillOpacity="0.2"
              />
              <path
                d="M332.854 416.654L338.614 416.272L336.065 421.452L332.854 416.654ZM442.477 470.594L442.465 471.094L442.355 471.091L442.256 471.042L442.477 470.594ZM337.112 418.192L442.697 470.145L442.256 471.042L336.67 419.09L337.112 418.192ZM442.488 470.094L530.374 472.094L530.351 473.094L442.465 471.094L442.488 470.094Z"
                fill="white"
                fillOpacity="0.2"
              />

              <text x="0" y="90" fill="white" fillOpacity="0.8">
                <tspan>Surface Casing:</tspan>
                <tspan x="0" dy="18">
                  ID{" "}
                  {wellCompletion != null ? wellCompletion.suf_casing_id : ""}
                </tspan>
                <tspan x="0" dy="18">
                  OD{" "}
                  {wellCompletion != null ? wellCompletion.suf_casing_od : ""}
                </tspan>
              </text>
              <text x="0" y="212" fill="white" fillOpacity="0.8"></text>
              <text x="0" y="280" fill="white" fillOpacity="0.8">
                <tspan>Intermediate Casing:</tspan>
                <tspan x="0" dy="18">
                  ID{" "}
                  {wellCompletion != null ? wellCompletion.inter_casing_id : ""}
                </tspan>
                <tspan x="0" dy="18">
                  OD{" "}
                  {wellCompletion != null
                    ? wellCompletion.inter_casing_od
                    : ""}
                </tspan>
                <tspan x="0" dy="18">
                  Bottom depth{" "}
                  {wellCompletion != null
                    ? wellCompletion.inter_casing_end
                    : ""}
                </tspan>
              </text>
              <text x="0" y="430" fill="white" fillOpacity="0.8">
                <tspan>Production Casing:</tspan>
                <tspan x="0" dy="18">
                  ID{" "}
                  {wellCompletion != null ? wellCompletion.prd_casing_id : ""}
                </tspan>
                <tspan x="0" dy="18">
                  OD{" "}
                  {wellCompletion != null ? wellCompletion.prd_casing_od : ""}
                </tspan>
              </text>

              <text x="500" y="100" fill="white" fillOpacity="0.8">
                <tspan>Surface Casing:</tspan>
                <tspan x="500" dy="18">
                  Bottom depth{" "}
                  {wellCompletion != null ? wellCompletion.suf_casing_end : ""}
                </tspan>
              </text>
              <text x="500" y="212" fill="white" fillOpacity="0.8"></text>
              <text x="500" y="322" fill="white" fillOpacity="0.8">
                <tspan>Intermediate Casing:</tspan>
                <tspan x="500" dy="18">
                  Bottom depth{" "}
                  {wellCompletion != null
                    ? wellCompletion.inter_casing_end
                    : ""}
                </tspan>
              </text>
              <text x="500" y="442" fill="white" fillOpacity="0.8">
                <tspan>Production Casing:</tspan>
                <tspan x="500" dy="18">
                  Bottom depth{" "}
                  {wellCompletion != null ? wellCompletion.prd_casing_end : ""}
                </tspan>
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WellArchitect;
