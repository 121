import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  assets: {
    active: null, // 'Bares River'
    list: null,
    /*
    {
      'Bares River':{
        '45345000': { // well_uwi
          'wellName': 'BRC-100',
          'logs': {
            'TL': [[100: 1010],[200:1220]],
            'GR': [[100: 1010],[200:1220]],
          }
        },
      }
    }
    */
  },
  wells: {
    selected: [],  // in asset/well list, selected wells
    selectedForNewLog: [], // in Property Log, selected wells list for selecting log
    list: {},
  },
  logs: {
    selected: [],
    selectedForNewLog: [],
    listForNewLog: [],
    list: {},
  },
  crossSetions: {
    selected: [],
    list: [],
  },
  tops: {
    selected: [],
    nameList: [],
    list: [],
    /* 
    {}
    */
  },
  selectedWells: {  // on Cross Section view(component: Selected) , the user selected wells chosen from asset/well list
    selected: [],
    list: [],
  },
  zoom: {}, // {'293874':{startValue, endValue}, '28376':{startValue, endValue}}
  settings: {
    font: {
      well: 3,
      curve: 3,
      scale: 3,
    },
    color: {
      font: '#000',
      background: '#ccc',
    },
  },
  selectedTopNames: [],

  // topNames: names of tops listed on Petrophysics/Well Correlation & Cross Section/Log view
  topNames: {
    selected: [],
    topNames: [],
    // {'1327': [123, 34, 546], '2349:[null, 123, null]}, numbers in array indicates all top values(not only selected) coordinates on Y axis 
    pixelCoordinatesOnWell: {},
  }
};

const petrophysicsSlice = createSlice({
  name: "petrophysics",
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setFontSettings: (state, action) => {
      const { well, curve, scale } = action.payload;
      let newParam = {};
      well && (newParam = { ...newParam, well });
      curve && (newParam = { ...newParam, curve });
      scale && (newParam = { ...newParam, scale });
      state.settings.font = { ...state.settings.font, ...newParam };
    },
    setSelectedTopNames: (state, action) => {
      state.selectedTopNames = action.payload;
    },
    setColorSettings: (state, action) => {
      state.settings.color = { ...state.settings.color, ...action.payload };
    },
    setFontColorSetting: (state, action) => {
      state.settings.color.font = action.payload;
    },
    setBackgroundColorSetting: (state, action) => {
      state.settings.color.background = action.payload;
    },
    setActiveAsset: (state, action) => {
      state.assets.active = action.payload;
    },
    setAssetList: (state, action) => {
      if (!action.payload) throw new Error('Parameter should not be empty!')
      if (!state.assets.list) state.assets.list = {};

      let {assetId, wells} = action.payload;
      state.assets.list[assetId] = wells;
    },
    setActiveCrossSection: (state, action) => {
      state.crossSetions.selected = Array.isArray(action.payload) ? action.payload : [action.payload];
    },
    setCrossSectionList: (state, action) => {
      if (!action.payload) throw new Error('Parameter should not be empty!')
      state.crossSetions.list = action.payload;
    },
    setCrossSectionSelectedWells: (state, action) => {  // set selected wells for log viewer
      if (!Array.isArray(action.payload)) throw new Error('Parameter should be an array!')
      state.selectedWells.list = action.payload;
    },
    addCrossSectionSelectedWell: (state, action) => {
      if (!action.payload) return;
      !state.selectedWells.list.includes(action.payload) && (state.selectedWells.list = [...state.selectedWells.list, action.payload]);
    },
    removeCrossSectionSelectedWell: (state, action) => {
      if (!action.payload) return;
      state.selectedWells.list = state.selectedWells.list.filter(item => item !== action.payload);
    },

    // Assets & Wells
    setSelectedWellList: (state, action) => {
      if (!Array.isArray(action.payload)) throw new Error('Parameter should be an array!');
    },
    setActiveAssetId: (state, action) => {
      if (!action.payload) throw new Error('Parameter should not be null!')
      state.assets.active = action.payload;
    },
    setSelectedWells: (state, action) => {  // set selected wells for log viewer
      if (!Array.isArray(action.payload)) throw new Error('Parameter should be an array!')
      state.wells.selected = action.payload;
    },
    setSelectedWellsForNewLog: (state, action) => {  // set selected wells for log viewer
      if (!Array.isArray(action.payload)) throw new Error('Parameter should be an array!')
      state.wells.selectedForNewLog = action.payload;
    },
    addSelectedWell: (state, action) => {
      if (!action.payload) return;
      !state.wells.selected.includes(action.payload) && (state.wells.selected = [...state.wells.selected, action.payload]);
    },
    removeSelectedWell: (state, action) => {
      if (!action.payload) return;
      state.wells.selected = state.wells.selected.filter(item => item !== action.payload);
    },
    addSelectedWellForNewLog: (state, action) => {
      if (!action.payload) return;
      !state.wells.selectedForNewLog.includes(action.payload) && (state.wells.selectedForNewLog = [...state.wells.selectedForNewLog, action.payload]);
    },
    removeSelectedWellForNewLog: (state, action) => {
      if (!action.payload) return;
      state.wells.selectedForNewLog = state.wells.selectedForNewLog.filter(item => item !== action.payload);
    },

    // Logs
    addSelectedLogName: (state, action) => {
      if (!action.payload || !action.payload.logNameId) return;
      // !state.logs.selected.includes(action.payload) && (state.logs.selected = [...state.logs.selected, action.payload]);
      state.logs.selected.find(item => item.logNameId === action.payload.logNameId) === undefined && (state.logs.selected = [...state.logs.selected, action.payload]);
    },
    removeSelectedLogName: (state, action) => {
      if (!action.payload || !action.payload.logNameId) return;
      state.logs.selected = state.logs.selected.filter(item => item.logNameId !== action.payload.logNameId);
    },
    addSelectedLogNameForNewLog: (state, action) => {
      if (!action.payload) return;
      !state.logs.selectedForNewLog.includes(action.payload) && (state.logs.selectedForNewLog = [...state.logs.selectedForNewLog, action.payload]);
    },
    removeSelectedLogNameForNewLog: (state, action) => {
      if (!action.payload) return;
      state.logs.selectedForNewLog = state.logs.selectedForNewLog.filter(item => item !== action.payload);
    },
    setLogListForNewLog: (state, action) => {
      if (!action.payload) return;
      state.logs.listForNewLog = { ...state.logs.listForNewLog, ...action.payload };
    },

    /*
    action.payload: an array includes:well uwi, param names and param values array
    [
    {
        "uwi": "42329397390000",
        "well_name": "BRC-686",
        "gamma ray": [
            {
                "depth": 1390.0,
                "log_value": -999.95
            },
            {
                "depth": 1390.5,
                "log_value": -999.95
            },
          ......
    */
    setWellLog: (state, action) => {
      const activeAsset = state.assets.active;
      const assetList = state.assets.list;
      if (!activeAsset || !assetList) throw new Error('No asset actived or no asset list!');

      const wellList = assetList[activeAsset];  // wells list in redux
      if (!wellList) throw new Error('No well list in actived asset!');

      const wellArray = action.payload; // wells list contain logs data
      if (!wellArray) throw new Error('Parameter should not be null!');

      wellArray.map(item => {
        const { uwi } = item;
        const well = wellList[uwi];
        if (!well) {
          console.warn("Found well in logs not in the asset/well list. uwi:", uwi);
          return null;
        }
        let wellLogList = well['logs']; // save logs data to well in redux
        if (!wellLogList) wellLogList = {};
        wellLogList = { ...wellLogList, ...item };
        delete wellLogList.uwi;
        delete wellLogList.well_name;
        return null;
      })
    },

    // Tops
    setTopNameList: (state, action) => { state.tops.nameList = action.payload },
    setWellTops: (state, action) => {
      state.wells.list = action.payload
    },

    setTopsList: (state, action) => { state.tops.list = action.payload },
    setTopsSelected: (state, action) => { state.tops.selected = action.payload },
  },
});

export const {
  reset,
  setLoading,
  // Settings
  setFontSettings,
  setColorSettings,
  setFontColorSetting,
  setBackgroundColorSetting,
  setSettings,

  // Assets & Wells
  setActiveAsset,
  setAssetList,
  setSelectedWells,
  setActiveAssetId,
  addSelectedWell,
  removeSelectedWell,
  addSelectedWellForNewLog,
  removeSelectedWellForNewLog,

  // Logs
  setWellLog,
  addSelectedLogName,
  removeSelectedLogName,
  setLogListForNewLog,

  // Cross Section
  setCrossSectionSelectedWells,
  addCrossSectionSelectedWell,
  removeCrossSectionSelectedWell,
  setActiveCrossSection,
  setCrossSectionList,

  // Tops
  setWellTops,
  setTopsList,
  setTopsSelected,
  setTopNameList,
  setSelectedTopNames,
} = petrophysicsSlice.actions;

// Settings
export const selectSettings = (state) => state.petrophysics.settings;
export const selectFontSettings = (state) => state.petrophysics.settings.font;
export const selectColorSettings = (state) => state.petrophysics.settings.color;
export const selectFontColorSetting = (state) => state.petrophysics.settings.color.font;
export const selectBackgroundColorSetting = (state) => state.petrophysics.settings.color.background;

// Assets & Wells
export const selectActiveAssetId = (state) => state.petrophysics.assets.active;
export const selectAssetList = (state) => state.petrophysics.assets.list;
export const selectSelectedWells = (state) => state.petrophysics.wells.selected;
export const selectActiveAssetObject = (state) => {
  const activeAssetId = selectActiveAssetId(state);
  const assetList = selectAssetList(state);
  if (!assetList || !activeAssetId) return null;
  return assetList[activeAssetId];
};
export const selectSelectedWellsForNewLog = (state) => state.petrophysics.wells.selectedForNewLog;

// Cross Section
export const selectActiveCrossSection = (state) => state.petrophysics.crossSetions.selected;
export const selectCrossSectionList = (state) => state.petrophysics.crossSetions.list;
export const selectCrossSectionSelectedWells = (state) => state.petrophysics.selectedWells.list;

// Tops
export const selectTopsSelected = (state) => state.petrophysics.tops.selected;
export const selectTopNameList = (state) => state.petrophysics.tops.nameList;
export const selectTopsList = (state) => state.petrophysics.tops.list;
export const selectSelectedTopNames = (state) => state.petrophysics.selectedTopNames;
export const selectTopsByWellUwi = (state, uwi) => {
  const activeAssetId = selectActiveAssetId(state);
  const assetList = selectAssetList(state);
  if (!uwi || !activeAssetId || !assetList || !assetList[activeAssetId] || assetList[activeAssetId][uwi]) {
    console.warn("not found tops data by uwi: assetList, activeAssetId, uwi", assetList, activeAssetId, uwi);
    return null;
  }
  return assetList[activeAssetId][uwi]['tops'];
}

// Logs
export const selectLogByWellUwi = (state, well_uwi) => {
  if (!state.petrophysics.assets
    || !state.petrophysics.assets.active
    || !state.petrophysics.assets.list[state.petrophysics.assets.active][well_uwi])
    return null;
  return state.petrophysics.assets.list[state.petrophysics.assets.active][well_uwi]['logs']
};
export const selectSelectedLogNames = (state) => state.petrophysics.logs.selected;
export const selectLogListForNewLog = (state) => state.petrophysics.logs.listForNewLog;
export const selectLoading = (state) => state.petrophysics.loading;

export default petrophysicsSlice.reducer;
