import React from 'react'
import SWTable from './SWTable'
import { Form } from 'antd'
import SGOTable from './SGOTable'
import SWOPlot from './SWOPlot'
import SGOPlot from './SGOPlot'

const SCALSettingTab = ({ tab }) => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <Form.List name={["SCAL", tab, "SWO"]}>
            {(fields, operation) => (
              <SWTable tab={tab} fields={fields} operation={operation} />
            )}
          </Form.List>
        </div>
        <div className="col-6">
          <div className="vstack gap-2">
            <SWOPlot name={["SCAL", tab, "SWO"]} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Form.List name={["SCAL", tab, "SGO"]}>
            {(fields, operation) => (
              <SGOTable tab={tab} fields={fields} operation={operation} />
            )}
          </Form.List>
        </div>
        <div className="col-6">
          <div className="vstack gap-2">
            <SGOPlot name={["SCAL", tab, "SGO"]} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SCALSettingTab