import React, { useEffect, useMemo } from 'react'
import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd'
import { isDeepEmpty } from 'utils/isDeepEmpty'
import { snakeToCamel } from 'utils/string'
import FormSection from 'components/form-section'

const durationOptions = [
  {
    value: 6,
    label: 'Last 6-month Avg'
  },
  {
    value: 12,
    label: 'Last 12-month Avg'
  },
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'user',
    label: 'User'
  }
]

const IndividualSetting = ({ well }) => {
  const getFormItemName = (fieldName) => ['wells', well.uwi, ...(Array.isArray(fieldName) ? fieldName : [fieldName])]

  const form = Form.useFormInstance()
  const avgDuration = Form.useWatch(getFormItemName(['loe_expenses', 'avg_duration']))
  const wellSetting = Form.useWatch(getFormItemName([]))

  const syncWellSetting = (uwis = [], setting) => {
    uwis.forEach(uwi => {
      form.setFieldValue(['wells', uwi, 'loe_expenses'], setting.loe_expenses)
      form.setFieldValue(['wells', uwi, 'ownership'], setting.ownership)
    })
  }

  useEffect(() => {
    if (wellSetting && !isDeepEmpty(wellSetting) && wellSetting.sync) {
      const wellsData = form.getFieldValue('wells')
      const syncedWells = Object.keys(wellsData).filter(uwi => uwi !== well.uwi && wellsData[uwi]?.sync)
      syncWellSetting(syncedWells, wellSetting)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, wellSetting])

  const loeExpenseOptions = useMemo(() => {
    return well.loe_expenses?.map(item => ({
      value: item.id,
      label: snakeToCamel(item.name)
    })) ?? []
  }, [well])

  const afeTables = useMemo(() => well.afe_names?.map(afe => ({
    value: afe.id,
    label: afe.afe_name
  })) ?? [], [well.afe_names])

  return (
    <div className="vstack gap-2">
      <FormSection title="LOE" extra={
        <Form.Item className="mb-0" name={getFormItemName(['sync'])} initialValue={true} valuePropName="checked">
          <Checkbox>Sync with every well</Checkbox>
        </Form.Item>
      }>
        <div className="row">
          <Form.Item className="col-6" name={getFormItemName(['loe_expenses', 'items'])} label="Select LOE Items">
            <Select mode="multiple" options={loeExpenseOptions} maxTagCount="responsive" dropdownRender={(menu) => (
              <>
                <Button
                  type="text"
                  className="mt-2 w-100"
                  style={{ textAlign: 'left' }}
                  onClick={() => form.setFieldValue(getFormItemName(['loe_expenses', 'items']), loeExpenseOptions.map(option => option.value))}
                >
                  All
                </Button>
                {menu}
              </>
            )} />
          </Form.Item>
          <Form.Item className="col-6" name={getFormItemName(['loe_expenses', 'avg_duration'])} label="Fixed vs. Variables">
            <Select options={durationOptions} />
          </Form.Item>
        </div>
        {avgDuration === 'user' ? (
          <div className="row">
            <Form.Item className="col-6" name={getFormItemName(['loe_expenses', 'fixed_expense'])} label="Fixed">
              <InputNumber className="w-100" placeholder="$$$/m" />
            </Form.Item>
            <Form.Item className="col-6" name={getFormItemName(['loe_expenses', 'variable_expense'])} label="Variables">
              <InputNumber className="w-100" placeholder="$/boe/m" />
            </Form.Item>
          </div>
        ) : null}
      </FormSection>
      <FormSection title="CAPAX">
        <div className="row">
          <Form.Item className="col-4" name={getFormItemName(['capax', 'afe_tables'])} label="Select AFE Tables">
            <Select mode="multiple" options={afeTables} dropdownRender={(menu) => (
              <>
                <Button
                  type="text"
                  className="mt-2 w-100"
                  style={{ textAlign: 'left' }}
                  onClick={() => form.setFieldValue(getFormItemName(['capax', 'afe_tables']), afeTables.map(option => option.value))}
                >
                  All
                </Button>
                {menu}
              </>
            )} />
          </Form.Item>
        </div>
      </FormSection>
      <FormSection title="Ownership">
        <div className="row">
          <Form.Item className="col-4" name={getFormItemName(['ownership', 'working_interest'])} label="Working Interest (%)">
            <Input type="number" step={0.01} />
          </Form.Item>
          <Form.Item className="col-4" name={getFormItemName(['ownership', 'revenue_interest'])} label="Revenue Interest (%)">
            <Input type="number" step={0.01} />
          </Form.Item>
          <Form.Item className="col-4" name={getFormItemName(['ownership', 'lease_nri'])} label="Lease NRI (%)">
            <Input type="number" step={0.01} />
          </Form.Item>
        </div>
      </FormSection>
    </div>
  )
}

export default IndividualSetting