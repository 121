import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  datItems: null,
  lasItems: null,
  prdItems: null,
  injItems: null,
  topItems: null,
  devItems: null,
  surveyItems: null,
  alarmItems: null,
  planningItems: null,
  eventsItems: null,
  showAddColumnPopup: false,
  columnObj: {},
  reload: false,
};

const dataManagerSlice = createSlice({
  name: "data_manager",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setDatDataStructure: (state, action) => {
      state.datItems = action.payload;
    },
    setLasDataStructure: (state, action) => {
      state.lasItems = action.payload;
    },
    setTopDataStructure: (state, action) => {
      state.topItems = action.payload;
    },
    setDevDataStructure: (state, action) => {
      state.devItems = action.payload;
    },
    setSurveyDataStructure: (state, action) => {
      state.surveyItems = action.payload;
    },
    setPrdDataStructure: (state, action) => {
      state.prdItems = action.payload;
    },
    setInjDataStructure: (state, action) => {
      state.injItems = action.payload;
    },
    setEventsDataStructure: (state, action) => {
      state.eventsItems = action.payload;
    },
    setAlarmDataStructure: (state, action) => {
      state.alarmItems = action.payload;
    },
    setPlanningDataStructure: (state, action) => {
      state.planningItems = action.payload;
    },
    setShowAddColumnPopup: (state, action) => {
      state.showAddColumnPopup = action.payload;
    },
    setColumnObj: (state, action) => {
      state.columnObj = action.payload;
    },
    setReload: (state, action) => {
      state.reload = action.payload;
    },
  },
});

export const {
  reset,
  setDatDataStructure,
  setLasDataStructure,
  setPrdDataStructure,
  setInjDataStructure,
  setTopDataStructure,
  setDevDataStructure,
  setSurveyDataStructure,
  setEventsDataStructure,
  setAlarmDataStructure,
  setPlanningDataStructure,
  setShowAddColumnPopup,
  setColumnObj,
  setReload,
} = dataManagerSlice.actions;
export const selectDatDataStructure = (state) => state.data_manager.datItems;
export const selectLasDataStructure = (state) => state.data_manager.lasItems;
export const selectPrdDataStructure = (state) => state.data_manager.prdItems;
export const selectInjDataStructure = (state) => state.data_manager.injItems;
export const selectTopDataStructure = (state) => state.data_manager.topItems;
export const selectDevDataStructure = (state) => state.data_manager.devItems;
export const selectSurveyDataStructure = (state) => state.data_manager.surveyItems;
export const selectEventsDataStructure = (state) => state.data_manager.eventsItems;
export const selectAlarmDataStructure = (state) => state.data_manager.alarmItems;
export const selectPlanningDataStructure = (state) => state.data_manager.planningItems;
export const selectShowAddColumnPopup = (state) => state.data_manager.showAddColumnPopup;
export const selectColumnObj = (state) => state.data_manager.columnObj;
export const selectReload = (state) => state.data_manager.reload;

export default dataManagerSlice.reducer;
