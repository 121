import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getWellSalePrices } from 'service/salePrices'
import WellSalePricesChart from './WellSalePricesChart'
import WellSalePricesEditor from './WellSalePricesEditor'

const WellSalePrices = (props) => {
  const { wellId } = props

  const { data: records = [], isFetching, refetch } = useQuery({
    queryKey: ['getWellSalePrices', wellId],
    queryFn: () => getWellSalePrices(wellId),
    enabled: Boolean(wellId),
    select: (res) => Array.isArray(res.data) ? res.data : []
  })

  return (
    <div className="vstack h-100 gap-2">
      <div style={{
        height: '60%'
      }}>
        <WellSalePricesChart isLoading={isFetching} records={records} />
      </div>
      <div style={{
        height: '40%'
      }}>
        <WellSalePricesEditor wellId={wellId} onRefetch={refetch} isLoading={isFetching} records={records} />
      </div>
    </div>
  )
}

export default WellSalePrices