import { get, post, put, remove } from "./base";

export function getUnits(params, onSuccess, onError) {
  return get(
    "/api/dictionary/query",
    params,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    }
  );
}

export function saveUnit(dictype, unit, onSuccess, onError) {
  return post(
    `/api/dictionary/`,
    {
      ...unit,
      dictype
    },
    null,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    true
  );
}

export function updateUnit(dictype, unitId, unit, onSuccess, onError) {
  return put(
    `/api/dictionary/${ unitId }`,
    {
      ...unit,
      dictype
    },
    null,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    true
  );
}

export function deleteUnit(dictype, unitId, data, onSuccess, onError) {
  return remove(
    `/api/dictionary/${ unitId }`,
    data,
    {
      dictype
    },
    {},
    (res) => {
      onSuccess?.();
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    true
  );
}

export function getDictionaryLog(dictype) {
  return get(
    `/api/dictionary/log`,
    {
      dictype
    },
    {}
  )
}