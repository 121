import { setSettings } from "features/petrophysics/petrophysicsSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const PpLayout = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        let localSettings = localStorage.getItem("localSettings");
        if (!localSettings) return;
        dispatch(setSettings(JSON.parse(localSettings)));
    }, [dispatch]);

    return (
        <div style={{ backgroundColor: 'white' }}>
            {props.children}
        </div>
    )
};
export default PpLayout;