import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRoutes } from "features/nav/routesSlice";

function Bread(props) {
  const location = useLocation();
  const rootRoutes = useSelector(selectRoutes);

  function matchRoutes(routes, matchPath) {
    for (const route of routes) {
      if (route.link === location.pathname) {
        matchPath.push(route);
        return true;
      } else {
        if (Object.prototype.hasOwnProperty.call(route, "routes") && route.routes.length > 0) {
          matchPath.push(route);
          if (matchRoutes(route.routes, matchPath)) {
            return true;
          } else {
            matchPath.pop();
          }
        }
      }
    }
    return false;
  }

  function generateBread(paths) {
    let images = [
      "/static/images/breadcrumbs/breadcrumbs-bg-01.png",
      "/static/images/breadcrumbs/breadcrumbs-bg-02.png",
      "/static/images/breadcrumbs/breadcrumbs-bg-03.png",
    ];
    return paths.map((route, index) => {
      const className = `bread-item item-${index} text-nowrap align-middle`;
      return (
        <div key={"bread_" + index} className={className}>
          <img src={images[index]} alt=""></img>
          <Link to={route.link}>{route.title}</Link>
        </div>
      );
    });
  }

  const routePaths = [];
  matchRoutes(rootRoutes, routePaths);

  return (
    <div className="breadcrumbs d-flex flex-row justify-content-start align-items-center">
      {generateBread(routePaths)}
    </div>
  );
}

export { Bread };
