import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultGridSettings from "./DefaultGridSettings";
import UserDefinedGridSettings from "./UserDefinedGridSettings";

import {
  selectGridParams,
  selectGridType,
  setGridType,
} from "features/well_planner/plannerSlice";

import { DataOptions } from "app/codes";
import { Radio } from "antd";

function GridConfig(props) {
  const { nextStep, loadingIconActions } = props;

  const gridParams = useSelector(selectGridParams);
  const gridType = useSelector(selectGridType);

  const dispatch = useDispatch();

  useEffect(() => {
    if (gridParams == null) {
      dispatch(setGridType(DataOptions[0].value));
      return;
    }

    dispatch(setGridType(gridParams.grid_type));
  }, [gridParams, dispatch]);

  function gridTypeChanged(e) {
    dispatch(setGridType(e.target.value));
  }

  function saveFinished() {
    nextStep();
  }

  return (
    <div className="d-flex flex-column params-panel">
      <div className="py-2 px-2 panel-header">Grid & Base</div>
      <div className="d-flex flex-column panel-container mt-2 p-2">
        <div className="d-flex justify-content-between align-items-center px-2 py-2">
          <div>Grid Setting</div>
          <div className="d-flex align-items-center">
            <Radio.Group
              onChange={gridTypeChanged}
              value={gridType}
              buttonStyle="solid"
            >
              <Radio
                value={DataOptions[0].value}
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
              >
                Default
              </Radio>
              <Radio
                value={DataOptions[1].value}
                style={{ color: 'rgba(255, 255, 255, 0.6)' }}
              >
                User Defined
              </Radio>
            </Radio.Group>
          </div>
        </div>
        {gridType === DataOptions[0].value && (
          <DefaultGridSettings
            onFinish={saveFinished}
            loadingIconActions={loadingIconActions}
          />
        )}
        {gridType === DataOptions[1].value && (
          <UserDefinedGridSettings
            onFinish={saveFinished}
            loadingIconActions={loadingIconActions}
          />
        )}
      </div>
    </div>
  );
}

export default GridConfig;
