import React, { useEffect, useMemo, useState } from "react";
import { ConfigProvider } from 'antd';
import { EditableProTable, ProFormRadio } from '@ant-design/pro-components';
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedWells, selectTopsList, selectTopsSelected, setSelectedWells, setTopsSelected } from "features/petrophysics/petrophysicsSlice";
import { deleteWellTopAttr, saveWellTops } from "service/petrophysics";
import enUS from 'antd/locale/en_US';

const defaultData = [];

const PpFormationTable = (props) => {
    const { loadingIconActions } = props;
    const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

    const dispatch = useDispatch();
    const selectedTops = useSelector(selectTopsSelected);
    const topsList = useSelector(selectTopsList);
    const selectedWells = useSelector(selectSelectedWells);

    const headColumn = useMemo(() => [{
        title: 'Tops',
        dataIndex: 'title',
        tooltip: 'Name of top',
        formItemProps: (form, { rowIndex }) => {
            return {
                rules: rowIndex > 1 ? [{ required: true, message: 'Required!' }] : [],
            };
        },
        // 第一行不允许编辑
        // editable: (text, record, index) => {
        //     return index !== 0;
        // },
        width: '15%',
    }], []);

    const [editableKeys, setEditableRowKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const { editable } = props;
    const [position, setPosition] = useState(editable ? 'bottom' : 'hidden');
    const [columns, setColumns] = useState(headColumn);

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            dispatch(setTopsSelected(selectedRows.map(item => item.title)));
        },
    };

    const getRowKeysFromDataSourceByTop = (dataSource, topsSelected) => {
        let selectedItems = dataSource.filter(item => topsSelected.includes(item.title));
        return selectedItems.map(item => item.id);
    }

    useEffect(() => {
        //  get all tops and attrs

        // set column
        let tempColumn = [...headColumn];
        topsList.map((item, index) => {
            return tempColumn.push({
                title: item.well_name,
                dataIndex: item.uwi,
            });
        });
        tempColumn.push(
            {
                title: 'Operation',
                valueType: 'option',
                width: 150,
                render: (text, record, _, action) => {
                    let ops = [];
                    editable && ops.push(<a
                        key="editable"
                        onClick={() => {
                            action?.startEditable?.(record.id);
                        }}
                    >
                        Edit
                    </a>);
                    return ops;
                },
            }
        )
        setColumns(tempColumn);

        let tempDataSource = [];
        // setDataSource([]);
        // loop tops list which include well tops array got from backend
        topsList.map(well => {
            // for each well
            // get tops
            const { uwi } = well;

            // only render param "MD"

            const getTopDataLineFromDataSource = (dataSource, topName) => {
                if (!dataSource || !dataSource.length || !topName) return null;
                let found = dataSource.filter(item => item.title === topName);
                if (!found || found.length === 0) return null;
                return found[0];
            }
            Object.keys(well['tops']).map(top => {
                let topDataLine = getTopDataLineFromDataSource(tempDataSource, top);
                !topDataLine && (topDataLine = {});
                !topDataLine.title && (topDataLine.title = top);
                !topDataLine.id && (topDataLine.id = (Math.random() * 1000000).toFixed(0));
                // set the value of this param for current well uwi
                let temp = {};
                temp[uwi] = well['tops'][top]['MD'] || '  ';
                topDataLine = { ...topDataLine, ...temp };
                tempDataSource = [...tempDataSource.filter(item => item.title !== top), topDataLine];
                return null;
            });
            return null;
        });
        setDataSource(tempDataSource);

    }, [topsList, editable, headColumn]);

    return (
        <div style={{ marginTop: '10px' }}>
            <ConfigProvider locale={enUS} theme={{
                token: {
                    colorBgElevated: "blue",
                }
            }}>
                <EditableProTable
                    alwaysShowAlert={false}
                    tableAlertRender={false}
                    tableAlertOptionRender={false}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: getRowKeysFromDataSourceByTop(dataSource, selectedTops),
                        tableAlertOptionRender: false,
                        ...rowSelection,
                    }}
                    rowKey="id"
                    headerTitle="Tops"
                    scroll={{
                        x: 960,
                    }}
                    recordCreatorProps={
                        position !== 'hidden'
                            ? {
                                position: position,
                                text: 'Add new top...',
                                record: () => ({ id: (Math.random() * 1000000).toFixed(0) }),
                                creatorButtonText: 'Add a new top',
                                style: (editableKeys.length !== 0) && {
                                    display: 'none'
                                },
                            }
                            : false
                    }
                    loading={false}
                    toolBarRender={() => editable && [
                        <ProFormRadio.Group
                            key="render"
                            fieldProps={{
                                value: position,
                                onChange: (e) => setPosition(e.target.value),
                            }}
                            options={[
                                {
                                    label: 'Add to top',
                                    value: 'top',
                                },
                                {
                                    label: 'Add to bottom',
                                    value: 'bottom',
                                },
                                {
                                    label: 'Hide Adding New',
                                    value: 'hidden',
                                },
                            ]}
                        />,
                    ]}
                    columns={columns}
                    request={async () => ({
                        data: defaultData,
                        // total: 1,
                        // success: true,
                    })}
                    value={dataSource}
                    editable={{
                        type: 'multiple',
                        editableKeys,
                        saveText: "Save",
                        deleteText: "Delete",
                        cancelText: "Cancel",
                        actionRender: (row, config, defaultDom) => {
                            return [defaultDom.save, defaultDom.cancel]
                        },
                        onSave: async (rowKey, data, row) => {
                            let saveAll = false;
                            if (data.title !== row.title) saveAll = true;
                            const NOT_DATA_KEY = ['title', 'id', 'index'];
                            let tops = {}, topsToDelete = {};
                            const topName = data.title;
                            Object.keys(data).map((uwi, index) => {
                                if (NOT_DATA_KEY.includes(uwi)) return null;
                                if (!topName) {
                                    return null;
                                }
                                if (saveAll || (data[uwi] !== row[uwi])) {
                                    if (!tops[uwi]) tops[uwi] = {};
                                    if (!topsToDelete[uwi]) topsToDelete[uwi] = {};
                                    if (!tops[uwi][topName]) tops[uwi][topName] = {};
                                    if (!topsToDelete[uwi][topName]) topsToDelete[uwi][topName] = [];

                                    if (data[uwi] === null || data[uwi] === undefined || data[uwi] === '') {
                                        topsToDelete[uwi][topName].push('MD');
                                    } else {
                                        tops[uwi][topName]['MD'] = data[uwi];
                                    }
                                }
                                return null;
                            });
                            showLoadingIcon && showLoadingIcon();
                            saveWellTops(
                                tops,
                                (res) => {
                                    console.log("successful saved tops.");
                                    dispatch(setSelectedWells([...selectedWells]));
                                    hideLoadingIcon && hideLoadingIcon();
                                },
                                (e) => {
                                    console.warn("failed saving tops.")
                                    hideLoadingIcon && hideLoadingIcon();
                                }
                            );
                            let top0 = Object.values(topsToDelete)[0];
                            let top0NameArray = Object.values(top0)[0];
                            if (top0NameArray.length > 0) {
                                showLoadingIcon && showLoadingIcon();
                                deleteWellTopAttr(
                                    topsToDelete,
                                    (res) => {
                                        console.log("successful delete tops.");
                                        hideLoadingIcon && hideLoadingIcon();
                                    },
                                    (e) => {
                                        console.warn("failed delete tops.");
                                        hideLoadingIcon && hideLoadingIcon();
                                    });
                            }
                        },
                        onChange: setEditableRowKeys,
                    }}
                />
            </ConfigProvider>
        </div>
    )
};
export default PpFormationTable;