export function parseFloatValue(value) {
  if (value == null) {
    return 0;
  }
  if (typeof value === "string") {
    return parseFloat(value);
  } else if (typeof value === "number") {
    return value;
  }
  return value;
}

export function parseIntValue(value) {
  if (value == null) {
    return 0;
  }
  if (typeof value === "string") {
    return parseInt(value);
  } else if (typeof value === "number") {
    return value;
  }
  return value;
}

export function setDisplayValue(value, decimal) {
  if (!value) {
    return 0;
  }
  return value.toFixed(decimal);
}

export function generateUuid() {
  let s = [];
  let hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  let uuid = s.join("");
  return uuid;
}

function find_nearest(arr, value) {
  let size = arr.length;
  let i = 0;
  while (i < size && value > arr[i]) {
    i += 1;
  }

  if (
    i > 0 &&
    (i === size || Math.abs(arr[i - 1] - value) < Math.abs(arr[i] - value))
  ) {
    return i - 1;
  }

  return i;
}

export function update_well_data_after_interpolate(
  x_coordinates,
  y_coordinates,
  x,
  y,
  z_grid
) {
  let x_idx = find_nearest(x_coordinates, x);
  let y_idx = find_nearest(y_coordinates, y);
  return z_grid[y_idx][x_idx];
}
