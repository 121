import { Form, Input, Select } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { filterOption } from 'utils/select'
import { useDCAReport } from '../DCAReportProvider'
import { useQuery } from '@tanstack/react-query'
import { getDCAReportTemplates } from 'service/dcaAnalysis'
import { sensitivityOptions } from '../consts'

const SourceProject = () => {
  const { projects, setSourceProjectId } = useDCAReport()

  const form = Form.useFormInstance()
  const projectId = Form.useWatch('project_id')
  const templateId = Form.useWatch('template_id')

  const { data: templates, isFetching: isLoadingTemplates } = useQuery({
    queryKey: ['getDCAReportTemplates'],
    queryFn: () => getDCAReportTemplates()
  })

  useEffect(() => {
    form.resetFields(['general', 'wells'])
    form.setFieldValue(['general', 'sensitivity_rates'], sensitivityOptions.map(option => option.value))
    setSourceProjectId(projectId || null)
  }, [form, projectId, setSourceProjectId])

  useEffect(() => {
    if (templateId && templates?.data) {
      applyTemplate(templateId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, templates?.data, projectId])

  const applyTemplate = (id) => {
    console.log('apply template', id)
    const template = templates?.data.find(temp => temp.id === id)
    console.log(template)
    if (!template) return;
    form.resetFields(['general'])
    form.setFieldsValue({
      general: {
        apply_ngl: template.apply_ngl,
        apply_diff: template.apply_price_differentials,
        cashflow: template.cashflow,
        discount_rate: template.discount_rate ? parseFloat(template.discount_rate) : undefined,
        discounted_cashflow: template.discounted_cashflow,
        economic_eur: template.economic_eur,
        ngl_ratio: template.ngl_ratio,
        price_projections: {
          oil: template.price_proj_oil,
          gas: template.price_proj_gas,
          ngl: template.price_proj_ngl
        },
        sensitivity_rates: template.sensitivity_rates?.split(',').filter(Boolean).map(rate => parseFloat(rate))
      }
    })
    if (template.project_id === projectId) {
      form.resetFields(['wells'])
      template.wells?.forEach(wellData => {
        form.setFieldValue(['wells', wellData.uwi], {
          loe_expenses: {
            items: wellData.loe_expense_ids?.split(',').filter(Boolean).map(id => parseInt(id)),
            avg_duration: wellData.loe_expense_method === 'user' ? 'user' : wellData.expense_range || 'all',
            fixed_expense: wellData.fixed_expense,
            variable_expense: wellData.variable_expense
          },
          capax: {
            afe_tables: wellData.afe_tables?.split(',').filter(Boolean).map(id => parseInt(id))
          },
        })
      })
    }
  }

  const templateOptions = useMemo(() => templates?.data?.map(item => ({
    value: item.id,
    label: item.name,
  })), [templates?.data])

  return (
    <div className="vstack gap-2">
      <Form.Item name={['project_id']} label="Select Project">
        <Select
          showSearch
          options={projects.map(project => ({
            value: project.id,
            label: project.name
          }))}
          filterOption={filterOption}
        />
      </Form.Item>
      <Form.Item name={['report_title']} label="Report Title">
        <Input />
      </Form.Item>
      <Form.Item name={['template_id']} label="Replicate Settings">
        <Select loading={isLoadingTemplates} options={templateOptions} />
      </Form.Item>
      <Form.Item name={['report_type']} label="Report Type">
        <Input />
      </Form.Item>
      <Form.Item name={['report_note']} label="Note">
        <Input.TextArea />
      </Form.Item>
    </div>
  )
}

export default SourceProject