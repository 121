import SectionSetting from "components/common/SectionSetting";
import PlanningTable from "./PlanningTable";

function Planning(props) {
  const { wellId, assetId, loadingIconActions } = props;
  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Planning and Scheduling</span>
        <div className="header-menu-container d-flex flex-row">
          <div className="form-container me-2">
            <form className="app-search d-none d-lg-block">
              <div className="d-flex flex-row">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <div className="search-icon">
                  <i className="bi bi-search"></i>
                </div>
              </div>
            </form>
          </div>
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content">
        <div className="table-container overflow-auto hide-scrollbar">
          <PlanningTable
            wellId={wellId}
            assetId={assetId}
            loadingIconActions={loadingIconActions}
          />
        </div>
      </div>
    </div>
  );
}

export default Planning;
