import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";
import groupBy from "lodash/groupBy";
import { createContext, useContext, useMemo, useState } from "react";
import { dcaReportInit } from "service/dcaAnalysis";

const DCAReportContext = createContext({})

const DCAReportProvider = ({ children }) => {
  const [sourceProjectId, setSourceProjectId] = useState(null)

  const { data: projects = [], isFetching } = useQuery({
    queryKey: ['dca', 'dcaReportInit'],
    queryFn: () => dcaReportInit(),
    select: res => res.data,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const projectsMap = useMemo(() => groupBy(projects, 'id'), [projects])

  const sourceProject = useMemo(() => projectsMap[sourceProjectId]?.[0] ?? null, [projectsMap, sourceProjectId])

  return (
    <DCAReportContext.Provider value={{ projects, setSourceProjectId, sourceProject }}>
      {isFetching ? <Spin size="large" /> : children}
    </DCAReportContext.Provider>
  )
}

export function useDCAReport() {
  return useContext(DCAReportContext)
}

export default DCAReportProvider