import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  declinePhase: "oil",
  product: "oil_prd_rate",

  activeWell: null,

  /* dcaWells structure:
  [
    // well 1
    {
      "project_id": 1,
      "well_uwi": "42329421930000",
      "records": [
          // phase 1: oil
          {
              "decline_phase": "oil",
              "start_date": "2019-10-01",
              "validation_length": 2,
              "dca_model_type": "HYP",
              "user_ip_ratio": -0.01,
              "user_initial_decline_rate": 4300,
              "user_coefficient_b": 0.9,
              "recomm_ip_ratio": -0.009461241261511436,
              "recomm_initial_decline_rate": 42627.72381518101,
              "recomm_coefficient_b": 0.7614023587909217,
              "trained_ml_model": "",
              "forecast_length": 20,
              "eur_ml": 0,
              "eur_recomm_dca": 0,
              "eur_user_dca": 0,
              "period_prd_ml": 0,
              "period_prd_recomm_dca": 0,
              "period_prd_user_dca": 0
          },
          // phase 2: gas
          ...
      ]
    },
    // well 2
    ...
  ]
  */
  dcaWells: [], // the fitted wells of current project

  applyUserParams: false,

  showEurParamsPanel: false,

  dcaCommonParams: {
    oil: {
      startDate: null,
      // declineModel: null,
      validationMonths: 2, // months
      forecastMonths: 240, //months
    },
    gas: {
      startDate: null,
      // declineModel: null,
      validationMonths: 2, // months
      forecastMonths: 240, //months
    },
    liquid: {
      startDate: null,
      // declineModel: null,
      validationMonths: 2, // months
      forecastMonths: 240, //months
    },
  },
  dcaRecommParams: {
    oil: {
      // startDate: null,
      declineModel: null,
      declineRate: 0,
      ipRatio: 0,
      b: 0,
    },
    gas: {
      // startDate: null,
      declineModel: null,
      declineRate: 0,
      ipRatio: 0,
      b: 0,
    },
    liquid: {
      // startDate: null,
      declineModel: null,
      declineRate: 0,
      ipRatio: 0,
      b: 0,
    },
  },
  dcaUserParams: {
    oil: {
      // startDate: null,
      declineModel: null,
      declineRate: 0,
      ipRatio: 0,
      b: 0,
    },
    gas: {
      // startDate: null,
      declineModel: null,
      declineRate: 0,
      ipRatio: 0,
      b: 0,
    },
    liquid: {
      // startDate: null,
      declineModel: null,
      declineRate: 0,
      ipRatio: 0,
      b: 0,
    },
  },
  dcaParamsRange: {
    oil: { declineRate: [0, 0], ipRatio: [0, 0], b: [0, 0] },
    gas: { declineRate: [0, 0], ipRatio: [0, 0], b: [0, 0] },
    liquid: { declineRate: [0, 0], ipRatio: [0, 0], b: [0, 0] },
  },
  eurRecommDataAllPhase: {
    oil: {
      eurRecomm: 0,
      periodPrdRecomm: 0,
    },
    gas: {
      eurRecomm: 0,
      periodPrdRecomm: 0,
    },
    liquid: {
      eurRecomm: 0,
      periodPrdRecomm: 0,
    },
  },
  eurUserDataAllPhase: {
    oil: {
      eurUser: 0,
      periodPrdUser: 0,
    },
    gas: {
      eurUser: 0,
      periodPrdUser: 0,
    },
    liquid: {
      eurUser: 0,
      periodPrdUser: 0,
    },
  },
  allModelsParams: {
    oil: [
      {
        model_name: "",
        params: {
          qi: 0,
          di: 0,
          b: 0,
        },
      },
    ],
    gas: [
      {
        model_name: "",
        params: {
          qi: 0,
          di: 0,
          b: 0,
        },
      },
    ],
    liquid: [
      {
        model_name: "",
        params: {
          qi: 0,
          di: 0,
          b: 0,
        },
      },
    ],
  },
};

const dcaProjectSlice = createSlice({
  name: "dca_project",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    resetDcaParams: (state, action) => {
      state.dcaCommonParams = initialState.dcaCommonParams;
      state.dcaRecommParams = initialState.dcaRecommParams;
      state.dcaUserParams = initialState.dcaUserParams;
    },
    resetEurParams: (state, action) => {
      state.eurRecommDataAllPhase = initialState.eurRecommDataAllPhase;
      state.eurUserDataAllPhase = initialState.eurUserDataAllPhase;
    },
    setDeclinePhase: (state, action) => {
      state.declinePhase = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setActiveWell: (state, action) => {
      const newActiveWell = action.payload;
      if (!newActiveWell) {
        state.activeWell = null;
        return;
      }
      state.activeWell = { ...state.activeWell, ...newActiveWell };
    },
    setDcaWells: (state, action) => {
      state.dcaWells = action.payload;
    },
    addDcaWell: (state, action) => {
      const well_uwi = action.payload.well_uwi;
      let wells = state.dcaWells.filter((well) => well.well_uwi !== well_uwi);
      let savedWell = state.dcaWells.find((well) => well.well_uwi === well_uwi);
      let updatedWell = { ...action.payload };
      let newRecords = [];

      // merge old and new dca well data
      // add old dca phase to new dca phases if it doesn't exist in new records.
      // otherwise, use new dca phase instead of old dca phase.
      savedWell &&
        savedWell.records &&
        savedWell.records.length > 0 &&
        savedWell.records.forEach((oldRecord) => {
          if (updatedWell.records && updatedWell.records.length > 0) {
            if (
              !updatedWell.records.find(
                (record) => record.decline_phase === oldRecord.decline_phase
              )
            ) {
              newRecords.push(oldRecord);
            }
          }
        });
      updatedWell.records = [...updatedWell.records, ...newRecords];
      state.dcaWells = [...wells, updatedWell];
    },
    setActiveDcaWell: (state, action) => {
      const dcaWellId = action.payload;
      let dcaWell = null;
      if (dcaWellId && state.dcaWells != null && state.dcaWells.length > 0) {
        dcaWell = state.dcaWells.find((item) => item.well_uwi === dcaWellId);
      }
      if (
        dcaWell == null ||
        dcaWell.records == null ||
        dcaWell.records.length === 0
      ) {
        state.dcaCommonParams = initialState.dcaCommonParams;
        state.dcaRecommParams = initialState.dcaRecommParams;
        state.dcaUserParams = initialState.dcaUserParams;
        state.eurRecommDataAllPhase = initialState.eurRecommDataAllPhase;
        state.eurUserDataAllPhase = initialState.eurUserDataAllPhase;
        return;
      }

      for (let record of dcaWell.records) {
        const phase = record.decline_phase;

        let dcaCommonParams = state.dcaCommonParams[phase];
        state.dcaCommonParams[phase] = {
          startDate: record.start_date || dcaCommonParams.startDate,
          validationMonths:
            record.validation_length || dcaCommonParams.validationMonths,
          forecastMonths:
            record.forecast_length || dcaCommonParams.forecastMonths,
        };

        let dcaRecommParams = state.dcaRecommParams;
        state.dcaRecommParams[phase] = {
          // startDate: record.start_date || dcaRecommParams.startDate,
          declineModel: record.dca_model_type || dcaRecommParams.declineModel,
          declineRate:
            record.recomm_initial_decline_rate || dcaRecommParams.declineRate,
          ipRatio: record.recomm_ip_ratio || dcaRecommParams.ipRatio,
          b: record.recomm_coefficient_b || dcaRecommParams.b,
        };

        let dcaUserParams = state.dcaUserParams;
        state.dcaUserParams[phase] = {
          // startDate: record.start_date || dcaUserParams.startDate,
          declineModel: record.user_decline_model || dcaUserParams.declineModel,
          declineRate:
            record.user_initial_decline_rate || dcaUserParams.declineRate,
          ipRatio: record.user_ip_ratio || dcaUserParams.ipRatio,
          b: record.user_coefficient_b || dcaUserParams.b,
        };

        state.eurRecommDataAllPhase[phase] = {
          eurRecomm: record.eur_recomm_dca,
          periodPrdRecomm: record.period_prd_recomm_dca,
        };
        state.eurUserDataAllPhase[phase] = {
          eurUser: record.eur_user_dca,
          periodPrdUser: record.period_prd_user_dca,
        };
      }
    },
    setApplyUserParams: (state, action) => {
      state.applyUserParams = action.payload;
    },
    setShowEurParamsPanel: (state, action) => {
      state.showEurParamsPanel = action.payload;
    },
    setDcaCommonParams: (state, action) => {
      state.dcaCommonParams[state.declinePhase] = action.payload;
    },
    setDcaRecommParams: (state, action) => {
      state.dcaRecommParams[state.declinePhase] = action.payload;
    },
    setDcaUserParams: (state, action) => {
      state.dcaUserParams[state.declinePhase] = action.payload;
    },
    setDcaCommonParamsByPhase: (state, action) => {
      const { phase, dcaParams } = action.payload;
      state.dcaCommonParams[phase] = dcaParams;
    },
    setDcaRecommParamsByPhase: (state, action) => {
      const { phase, dcaParams } = action.payload;
      state.dcaRecommParams[phase] = dcaParams;
    },
    setDcaUserParamsByPhase: (state, action) => {
      const { phase, dcaParams } = action.payload;
      state.dcaUserParams[phase] = dcaParams;
    },
    setDcaParamsRange: (state, action) => {
      state.dcaParamsRange[state.declinePhase] = action.payload;
    },
    setRecommEurDataAllPhase: (state, action) => {
      state.eurRecommDataAllPhase = {
        ...state.eurRecommDataAllPhase,
        ...action.payload,
      };
    },
    setRecommEurDataByPhase: (state, action) => {
      let { phase, eurData } = action.payload;
      state.eurRecommDataAllPhase[phase] = {
        ...state.eurRecommDataAllPhase[phase],
        ...eurData,
      };
    },
    setUserEurDataAllPhase: (state, action) => {
      state.eurUserDataAllPhase = {
        ...state.eurUserDataAllPhase,
        ...action.payload,
      };
    },
    setUserEurDataByPhase: (state, action) => {
      let { phase, eurData } = action.payload;
      state.eurUserDataAllPhase[phase] = {
        ...state.eurUserDataAllPhase[phase],
        ...eurData,
      };
    },
    setDcaAllModelsParams: (state, action) => {
      state.allModelsParams[state.declinePhase] = action.payload;
    },
  },
});

export const {
  reset,
  resetDcaParams,
  resetEurParams,
  setProject,
  setActiveWell,
  setDeclinePhase,
  setProduct,
  setDcaWells,
  addDcaWell,
  setActiveDcaWell,
  setApplyUserParams,
  setShowEurParamsPanel,
  setDcaCommonParams,
  setDcaRecommParams,
  setDcaUserParams,
  setDcaParamsRange,
  setDcaCommonParamsByPhase,
  setDcaRecommParamsByPhase,
  setDcaUserParamsByPhase,
  setRecommEurDataAllPhase,
  setRecommEurDataByPhase,
  setUserEurDataAllPhase,
  setUserEurDataByPhase,
  setDcaAllModelsParams,
} = dcaProjectSlice.actions;

export const selectProject = (state) => state.dca_project.project;
export const selectDeclinePhase = (state) => state.dca_project.declinePhase;
export const selectProduct = (state) => state.dca_project.product;

export const selectActiveWell = (state) => state.dca_project.activeWell;
export const selectDcaWells = (state) => state.dca_project.dcaWells;

export const selectApplyUserParams = (state) =>
  state.dca_project.applyUserParams;
export const selectShowEurParamsPanel = (state) =>
  state.dca_project.showEurParamsPanel;

export const selectDcaCommonParams = (state) =>
  state.dca_project.dcaCommonParams[state.dca_project.declinePhase];
export const selectDcaRecommParams = (state) =>
  state.dca_project.dcaRecommParams[state.dca_project.declinePhase];
export const selectDcaUserParams = (state) =>
  state.dca_project.dcaUserParams[state.dca_project.declinePhase];

export const selectDcaParamsRange = (state) =>
  state.dca_project.dcaParamsRange[state.dca_project.declinePhase];

export const selectDcaAllModelsParams = (state) =>
  state.dca_project.allModelsParams[state.dca_project.declinePhase];

export const selectAllDcaCommonParams = (state) =>
  state.dca_project.dcaCommonParams;
export const selectAllDcaRecommParams = (state) =>
  state.dca_project.dcaRecommParams;
export const selectAllDcaUserParams = (state) =>
  state.dca_project.dcaUserParams;

export const selectDcaCommonParamsByPhase = (state, phase) =>
  state.dca_project.dcaCommonParams[phase];
export const selectDcaRecommParamsByPhase = (state, phase) =>
  state.dca_project.dcaRecommParams[phase];
export const selectDcaUserParamsByPhase = (state, phase) =>
  state.dca_project.dcaUserParams[phase];

export const selectRecommEurDataAllPhase = (state) =>
  state.dca_project.eurRecommDataAllPhase;
export const selectUserEurDataAllPhase = (state) =>
  state.dca_project.eurUserDataAllPhase;

export default dcaProjectSlice.reducer;
