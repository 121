import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PerformanceSetting from "./settings/PerformanceSetting";
// import HistoricalH from "components/charts/HistoricalH";
import WellPlannerPerformance from "components/charts/WellPlannerPerformance";

import { selectCustomWells } from "features/well_planner/plannerSlice";
import {
  selectWellClusters,
  selectWellGroups,
  selectDeclinePhase,
  setDeclinePhase,
  selectPerformanceParams,
} from "features/well_planner/plannerDcaSlice";

import { getPerformance } from "service/typeCurve";
import { DECLINE_PHASE_OPTIONS } from "app/codes";
import DcaManagementEurPanel from "pages/dca/DcaManagementEurPanel";

const CurveModeOptions = [
  { value: "hybrid", name: "Machine-learning-based" },
  { value: "user", name: "User-grouping-based" },
];

function PerformanceChart(props) {
  const { submitWpProject, loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const customWells = useSelector(selectCustomWells);
  const wellClusters = useSelector(selectWellClusters);
  const wellGroups = useSelector(selectWellGroups);
  const performanceParams = useSelector(selectPerformanceParams);
  const dispatch = useDispatch();

  const [showPerformanceSetting, setShowPerformanceSetting] = useState(false);
  const [curveModeIndex, setCurveModeIndex] = useState(0);
  const [selectedWellIndex, setSelectedWellIndex] = useState(null);
  const declinePhase = useSelector(selectDeclinePhase);

  const [architectureSources, setArchitectureSources] = useState(null);
  const [customPalette, setCustomPalette] = useState(null);
  const [x_data, setXData] = useState(null);
  const [y_series, setYSeries] = useState(null);
  const [showEurPanel, setShowEurPanel] = useState(false);
  const [eurData, setEurData] = useState(null);
  const [eurDataOil, setEurDataOil] = useState(null);
  const [eurDataGas, setEurDataGas] = useState(null);
  const [eurDataLiquid, setEurDataLiquid] = useState(null);

  useEffect(() => {
    if (
      customWells == null ||
      customWells.length === 0 ||
      selectedWellIndex != null
    ) {
      return;
    }
    !selectedWellIndex && setSelectedWellIndex(0);
  }, [customWells, selectedWellIndex]);

  const updateEurData = useCallback(
    (result) => {
      const { dca, history } = result;
      const { fitted: predicted } = dca;

      // calculate eur data
      let predictSum = predicted
        .map((item) => item[0])
        .reduce((total, v) => (total += v), 0);
      let historySum = history
        .map((item) => item[0])
        .reduce((total, v) => (total += v), 0);
      let tempEurData = {
        predictAutoRegression: predictSum,
        eurAutoRegression: predictSum + historySum,
      };

      tempEurData[declinePhase + "_predictAutoRegression"] = predictSum;
      tempEurData[declinePhase + "_eurAutoRegression"] =
        predictSum + historySum;
      setEurData((eurData) => ({ ...eurData, ...tempEurData }));
    },
    [declinePhase]
  );

  useEffect(() => {
    if (
      wellClusters == null ||
      wellClusters.length === 0 ||
      wellGroups == null ||
      wellGroups.length === 0 ||
      selectedWellIndex == null
    ) {
      return;
    }

    const getAndUpdateAllEurData = (wellIds, declinePhases) => {
      declinePhases.map(({ value: declinePhase }) =>
        getPerformance(
          {
            uwis: wellIds,
            // product: "oil_prd_rate",
            decline_phase: declinePhase || "oil",
            normalize_by: "wellbore_length",
          },
          (result) => {
            // updateEurData(result);
            const { dca, history } = result;
            const { fitted: predicted } = dca;

            // calculate eur data
            let predictSum = predicted
              .map((item) => item[1])
              .reduce((total, v) => (total += v), 0);
            let historySum = history
              .map((item) => item[1])
              .reduce((total, v) => (total += v), 0);
            let tempEurData = {};

            tempEurData[declinePhase + "_predictAutoRegression"] = predictSum;
            tempEurData[declinePhase + "_eurAutoRegression"] =
              predictSum + historySum;
            declinePhase === "oil" && setEurDataOil(tempEurData);
            declinePhase === "gas" && setEurDataGas(tempEurData);
            declinePhase === "liquid" && setEurDataLiquid(tempEurData);
          },
          (error) => {}
        )
      );
    };

    showLoadingIcon && showLoadingIcon();
    if (curveModeIndex === 0) {
      // AI, ML: use API return nearest well list, use wellClusters
      let customWell = customWells[selectedWellIndex];
      let wellIds = wellClusters[customWell.well_uwi];
      if (wellIds && wellIds.length > 0) {
        getPerformance(
          {
            uwis: wellIds,
            // product: "oil_prd_rate",
            decline_phase: declinePhase || "oil",
            normalize_by: "wellbore_length",
          },
          (result) => {
            updateAIPerformance(result, customWell.wellbore_length);
            updateEurData(result);
            getAndUpdateAllEurData(
              wellIds,
              DECLINE_PHASE_OPTIONS.filter(
                (item) => item.value !== declinePhase
              )
            );
            hideLoadingIcon && hideLoadingIcon();
          },
          (error) => {
            hideLoadingIcon && hideLoadingIcon();
          }
        );
      }
    } else if (curveModeIndex === 1) {
      // "user": use user selected well list in PerformanceSetting, use wellGroups
      let customWell = customWells[selectedWellIndex];
      let wellIds = wellGroups[customWell.well_uwi];
      if (wellIds && wellIds.length > 0) {
        getPerformance(
          {
            uwis: wellIds,
            // product: "oil_prd_rate",
            decline_phase: declinePhase || "oil",
            normalize_by: "wellbore_length",
          },
          (result) => {
            updateHybridPerformance(
              result,
              "auto-Arp's",
              customWell.wellbore_length
            );
            updateEurData(result);
            getAndUpdateAllEurData(
              wellIds,
              DECLINE_PHASE_OPTIONS.filter(
                (item) => item.value !== declinePhase
              )
            );
            hideLoadingIcon && hideLoadingIcon();
          },
          (error) => {
            hideLoadingIcon && hideLoadingIcon();
          }
        );
      }
    } else {
      // no use now
      let customWell = customWells[selectedWellIndex];
      let wellIds = wellGroups[customWell.well_uwi];
      if (wellIds && wellIds.length > 0) {
        getPerformance(
          {
            uwis: wellIds,
            // product: "oil_prd_rate",
            decline_phase: declinePhase || "oil",
            normalize_by: "wellbore_length",
          },
          (result) => {
            updateHybridPerformance(
              result,
              "user-Arp's",
              customWell.wellbore_length
            );
            hideLoadingIcon && hideLoadingIcon();
          },
          (error) => {
            hideLoadingIcon && hideLoadingIcon();
          }
        );
      }
    }
  }, [
    curveModeIndex,
    selectedWellIndex,
    wellClusters,
    wellGroups,
    declinePhase,
    customWells,
    hideLoadingIcon,
    showLoadingIcon,
    updateEurData,
  ]);

  function updateAIPerformance(result, length) {
    setArchitectureSources([
      // { value: "historical_data", name: "Historical" },
      { value: "ml_predict", name: "auto-ML" },
      { value: "ml_lower", name: "auto-ML Lower" },
      { value: "ml_upper", name: "auto-ML Upper" },
    ]);

    setCustomPalette([
      "blue",
      "hsl(39, 100%, 50%)",
      "hsl(59, 96%, 49%)",
      "hsl(59, 49%, 50%)",
    ]);

    setXData(result.history.map((item) => item[0]));

    setYSeries(result.dca);
    // setYSeries(multiplyYDateWithWellboreLength(result.dca, length));
  }

  function updateHybridPerformance(result, tag, length) {
    if (result.dca) {
      setArchitectureSources([
        // { value: "historical_data", name: "Historical" },
        { value: "hyp_predict", name: tag },
        { value: "hyp_lower", name: tag + " Lower" },
        { value: "hyp_upper", name: tag + " Upper" },
      ]);

      setCustomPalette([
        "hsl(39, 100%, 50%)",
        "hsl(59, 96%, 49%)",
        "hsl(59, 49%, 50%)",
      ]);

      setXData(result.history.map((item) => item[0]));
      setYSeries(result.dca);
      // setYSeries(multiplyYDateWithWellboreLength(result.dca, length));
    } else if (result.dca.exponential) {
      setArchitectureSources([
        { value: "exp_predict", name: tag },
        { value: "exp_lower", name: tag + " Lower" },
        { value: "exp_upper", name: tag + " Upper" },
      ]);

      setCustomPalette([
        "hsl(120, 84%, 28%)",
        "hsl(120, 95%, 21%)",
        "hsl(120, 85%, 46%)",
      ]);

      setXData(result.history.map((item) => item[0]));
      setYSeries(result.dca);
    }
  }

  function handleWellIndexChange(e) {
    setSelectedWellIndex(e.target.value);
  }

  function handleCurveModeIndexChange(e) {
    let index = parseInt(e.target.value);
    setCurveModeIndex(index);
  }

  function handleSaveForecastData(e) {
    submitWpProject(performanceParams, wellClusters, wellGroups);
  }

  return (
    <>
      {showPerformanceSetting && (
        <div className="popup-window">
          <div className="popup-wrapper d-flex justify-content-center align-items-center h-100 w-50">
            <PerformanceSetting
              close={() => {
                setShowPerformanceSetting(false);
              }}
            />
          </div>
        </div>
      )}
      {showEurPanel && (
        <div className="popup-window">
          <DcaManagementEurPanel
            eurData={{
              ...eurData,
              ...eurDataOil,
              ...eurDataGas,
              ...eurDataLiquid,
            }}
            phase={
              DECLINE_PHASE_OPTIONS.find((item) => item.value === declinePhase)
                ?.name
            }
            onClose={() => setShowEurPanel(false)}
          />
        </div>
      )}
      <div className="cards-container h-100">
        <div className="cards-header d-flex flex-row justify-content-between align-items-center">
          <div className="header-title">Performance</div>
          <div className="header-menu-container d-flex flex-row">
            <div>Scenario</div>
            <select
              id="scenario"
              value={selectedWellIndex || ""}
              onChange={handleWellIndexChange}
            >
              {customWells &&
                customWells.length > 0 &&
                customWells.map((well, index) => {
                  return (
                    <option
                      key={"well_" + index}
                      id={"well_" + index}
                      value={index}
                    >
                      {well.well_name}
                    </option>
                  );
                })}
            </select>
            <div>Phase</div>
            <div>
              <label htmlFor="Phase"></label>
              <select
                id="Phase"
                name="phase"
                value={declinePhase}
                onChange={(e) => dispatch(setDeclinePhase(e.target.value))}
              >
                {DECLINE_PHASE_OPTIONS.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label htmlFor="curveMode"></label>
              <select
                id="curveMode"
                name="curveMode"
                value={curveModeIndex || ""}
                onChange={handleCurveModeIndexChange}
              >
                {CurveModeOptions.map((item, index) => {
                  return (
                    <option key={index} value={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              className="cards-setting-btn ms-2"
              onClick={(e) => {
                setShowPerformanceSetting(true);
              }}
            >
              <div className="cards-setting-icon" />
            </div>
          </div>
        </div>
        <div className="cards-content">
          {wellClusters === null
            ? "Please set Performance settings first."
            : null}
          <WellPlannerPerformance
            architectureSources={architectureSources}
            customPalette={customPalette}
            x_data={x_data}
            y_series={y_series}
          />
        </div>
        <div className="forecast-lower-part d-flex flex-column mt-2">
          <div className="d-flex justify-content-around align-items-center mt-2">
            <button
              className="btn-custom"
              onClick={(e) => {
                setShowEurPanel(true);
              }}
            >
              EUR
            </button>
            <button
              className="btn-custom"
              style={{ minWidth: "8em" }}
              onClick={handleSaveForecastData}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PerformanceChart;
