import { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  DatePeriodOptions,
  // PHASE_MODEL_MAP,
  DECLINE_PHASE_OPTIONS,
  NormalizeOptions,
  DeclineModelOptions,
} from "app/codes";

import {
  setDeclinePhase,
  selectDeclinePhase,
  // setActiveClusterId,
  selectProjectClusterDataItems,
  // setProjectClusterDataItemsWithIndexProjectCluster,
  setProjectClusterDataRecommParamsWithIndex,
  setProjectClusterDataUserParamsWithIndex,
  selectGroupNormalizeBy,
  setGroupNormalizeBy,
  selectClusterNormalizeBy,
  selectApplyUserParams,
  setApplyUserParams,
  setClusterNormalizeBy,
  selectDcaAllModelsParams,
  setDcaAllModelsParams,
} from "features/decline_analysis/typeCurveProjectSlice";

import { getDcaRecommends, getDcaUserTuneData } from "service/typeCurve";

import TypeCurveTypeCurve from "components/charts/TypeCurveTypeCurve";
import { Empty } from "antd";

import SectionSetting from "components/common/SectionSetting";
import { useUserPreference } from "components/charts/UserPreference";

function AiTypeCurveChart(props) {
  const { submitTCProject } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const declinePhase = useSelector(selectDeclinePhase);
  // const product = declinePhase ? PHASE_MODEL_MAP[declinePhase] : PHASE_MODEL_MAP[DECLINE_PHASE_OPTIONS[0].value];
  const projectClusterDataItems = useSelector(
    selectProjectClusterDataItems,
    shallowEqual
  );
  const normalizedByParam = useSelector(selectClusterNormalizeBy);
  const applyUserParams = useSelector(selectApplyUserParams);
  const allModelsParameters = useSelector(selectDcaAllModelsParams);
  const dispatch = useDispatch();
  const [selectedClusterIndex, setSelectedClusterIndex] = useState(null);
  const normalizeBy = useSelector(selectGroupNormalizeBy);
  // const currentProjectClusterDataItem = useSelector((state)=>selectProjectClusterDataItemByIndex(state, selectedClusterIndex));

  const [UserPreference, showUserPreference] =
    useUserPreference();
  // historical production data
  const [historicalData, setHistoricalData] = useState(null);

  // computed dca data with default dca parameters
  const [recommData, setRecommData] = useState(null);
  const [userDcaData, setUserDcaData] = useState(null);
  const [activePeriod, setActivePeriod] = useState(1);

  const handlePeriodChanged = (days) => {
    setActivePeriod(days);
  };

  const updateRecommData = useCallback(
    (cluserIndex, result) => {
      /* result sample:
      {
        "success": true,
        "history": [
          [0, 2.47317362166568],
          ......
        ],
        "dca": {
          "best_model": "AI",
          "qi": 2.3792521158217454e-11,
          "di": 26.657390337946783,
          "fitted": [
            [10, 8.986653356409253],
            .....
          ]
        }
      }
      */
      let history = result.history;
      setHistoricalData(history);

      let recommends = result.dca;
      let bestModel = recommends.best_model;
      setRecommData(result.dca.fitted);

      dispatch(
        setProjectClusterDataRecommParamsWithIndex({
          index: cluserIndex,
          recommParams: {
            decline_phase: declinePhase,
            normalized_by: normalizedByParam,
            dca_model_type: bestModel,
            recomm_ip_ratio: recommends.di,
            recomm_initial_decline_rate: recommends.qi,
            recomm_coefficient_b: recommends.b,
          },
        })
      );
    },
    [dispatch, declinePhase, normalizedByParam]
  );

  const getDcaUserTuneDataCallback = useCallback(
    (cluserIndex, projectClusterDataItem) => {
      let projectCluster = projectClusterDataItem.project_cluster;
      const record = projectCluster?.records?.find(
        (item) => item.decline_phase === declinePhase
      );
      let declineModel = record.user_decline_model;
      let wellIds = projectClusterDataItem.cluster_wells;
      if (!declineModel || !wellIds || wellIds.length === 0) {
        console.error("declineModel, wellIds:", declineModel, wellIds);
        return;
      }

      let decline_rate =
        record.user_initial_decline_rate || record.recomm_initial_decline_rate;
      let ip_ratio = record.user_ip_ratio || record.recomm_ip_ratio;
      let b = record.user_coefficient_b || record.recomm_coefficient_b;

      if (typeof decline_rate != "number") {
        decline_rate = parseFloat(decline_rate);
        ip_ratio = parseFloat(ip_ratio);
        b = parseFloat(b);
      }

      if (declineModel === "AI" || (declineModel === "HYP" && b === 0)) {
        console.error("declineModel, b:", declineModel, b);
        return;
      }
      showLoadingIcon && showLoadingIcon();
      getDcaUserTuneData(
        {
          uwis: wellIds,
          decline_phase: declinePhase || "oil",
          decline_model: declineModel,
          normalize_by: normalizedByParam,
          decline_rate: decline_rate,
          ip_ratio: ip_ratio,
          b: b,
        },
        (result) => {
          if (!result || !result.dca || !result.dca.fitted) {
            hideLoadingIcon && hideLoadingIcon();
            return;
          }
          // setHistoricalData(result.history);
          setUserDcaData(result.dca.fitted);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [declinePhase, normalizedByParam, showLoadingIcon, hideLoadingIcon]
  );

  const getDcaRecommendDataCallback = useCallback(
    (cluserIndex, projectClusterDataItem) => {
      let wellIds = projectClusterDataItem.cluster_wells;
      if (wellIds.length === 0) {
        setHistoricalData(null);
        return;
      }

      showLoadingIcon && showLoadingIcon();
      getDcaRecommends(
        {
          uwis: wellIds,
          decline_phase: declinePhase,
          normalize_by: normalizedByParam,
        },
        (result) => {
          updateRecommData(cluserIndex, result);
          let allModels = [...result.other_models];
          let { best_model, b, di, qi } = result.dca;
          allModels.push({
            model_name: best_model,
            model_params: {
              qi: qi,
              di: di,
              b: b,
            },
          });
          dispatch(setDcaAllModelsParams(allModels));
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          console.error("getDcaRecommends error! ", error);
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [
      dispatch,
      declinePhase,
      normalizedByParam,
      hideLoadingIcon,
      showLoadingIcon,
      updateRecommData,
    ]
  );

  useEffect(() => {
    if (
      projectClusterDataItems == null ||
      projectClusterDataItems.length === 0
    ) {
      return;
    }
    if (
      selectedClusterIndex == null ||
      selectedClusterIndex > projectClusterDataItems.length - 1
    ) {
      setSelectedClusterIndex(0);
    }
  }, [dispatch, projectClusterDataItems, selectedClusterIndex]);

  useEffect(
    () => {
      if (
        selectedClusterIndex == null ||
        !projectClusterDataItems ||
        projectClusterDataItems.length === 0
      ) {
        return;
      }
      setRecommData(null);
      setUserDcaData(null);
      setHistoricalData(null);
      // if (normalizeBy === NormalizeOptions[0].value) return;
      let currentProjectClusterDataItem =
        projectClusterDataItems[selectedClusterIndex];
      getDcaRecommendDataCallback(
        selectedClusterIndex,
        currentProjectClusterDataItem
      );
      getDcaUserTuneDataCallback(
        selectedClusterIndex,
        currentProjectClusterDataItem
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      normalizeBy,
      selectedClusterIndex,
      declinePhase,
      // currentProjectClusterDataItem,
      // projectClusterDataItems,
      // getDcaRecommendDataCallback,
      // getDcaUserTuneDataCallback
    ]
  );

  useEffect(() => {
    if (
      !applyUserParams ||
      !projectClusterDataItems ||
      projectClusterDataItems.length === 0 ||
      selectedClusterIndex == null
    ) {
      return;
    }
    setUserDcaData(null);
    let currentProjectClusterDataItem =
      projectClusterDataItems[selectedClusterIndex];
    getDcaUserTuneDataCallback(
      selectedClusterIndex,
      currentProjectClusterDataItem
    );

    dispatch(setApplyUserParams(false));
  }, [
    applyUserParams,
    dispatch,
    projectClusterDataItems,
    selectedClusterIndex,
    getDcaUserTuneDataCallback,
  ]);

  function handleCluserSelectChanged(e) {
    let value = e.target.value;
    setSelectedClusterIndex(value);
    // dispatch(setActiveClusterId(value));
  }

  function handleDecliePhaseChanged(e) {
    let value = e.target.value;
    dispatch(setDeclinePhase(value));
  }

  function handleSubmitProject() {
    let projectItem = projectClusterDataItems[selectedClusterIndex];
    if (projectItem) {
      submitTCProject({ projectItem: projectItem });
    }
  }

  const clustersSelector = useMemo(() => {
    if (
      projectClusterDataItems == null ||
      projectClusterDataItems.length === 0
    ) {
      return;
    }

    return projectClusterDataItems.map((projectItem, index) => {
      return (
        <option key={index} value={index}>
          Cluster{index}
        </option>
      );
    });
  }, [projectClusterDataItems]);

  function handleNormalizeParamChanged(e) {
    const value = e.target.value;
    dispatch(setGroupNormalizeBy(value));
    dispatch(setClusterNormalizeBy(value));
  }

  const getRecommParams = useMemo(() => {
    const projectCluster =
      projectClusterDataItems[selectedClusterIndex]?.project_cluster || {};
    const record =
      projectCluster?.records?.find(
        (item) => item.decline_phase === declinePhase
      ) || {};
    const {
      dca_model_type,
      recomm_ip_ratio,
      recomm_initial_decline_rate,
      recomm_coefficient_b,
    } = record || {};
    return {
      declineModelValue: DeclineModelOptions.find(
        (item) => item.value === dca_model_type
      )?.name,
      ipRatioValue: recomm_ip_ratio?.toFixed(3),
      declineRateValue: recomm_initial_decline_rate?.toFixed(3),
      bValue: recomm_coefficient_b?.toFixed(3),
    };
  }, [declinePhase, selectedClusterIndex, projectClusterDataItems]);

  const getUserParams = useMemo(() => {
    const projectCluster =
      projectClusterDataItems[selectedClusterIndex]?.project_cluster || {};
    const record = projectCluster?.records?.find(
      (item) => item.decline_phase === declinePhase
    );
    const {
      user_decline_model,
      user_ip_ratio,
      user_initial_decline_rate,
      user_coefficient_b,
    } = record || {};
    return {
      declineModel: user_decline_model,
      ipRatio: user_ip_ratio,
      declineRate: user_initial_decline_rate,
      b: user_coefficient_b,
    };
  }, [declinePhase, selectedClusterIndex, projectClusterDataItems]);

  const getYUnit = useCallback((phase) => {
    return DECLINE_PHASE_OPTIONS.find((item) => item.value === phase)?.unit;
  }, []);

  return (
    <>
      <UserPreference
        recommendData={getRecommParams}
        userTuneData={getUserParams}
        defaultParameters={allModelsParameters}
        onClose={(values) => {
          dispatch(
            setProjectClusterDataUserParamsWithIndex({
              index: selectedClusterIndex,
              userParams: {
                decline_phase: declinePhase,
                normalized_by: normalizedByParam,
                user_decline_model: values.declineModel,
                user_ip_ratio: values.ipRatio,
                user_initial_decline_rate: values.declineRate,
                user_coefficient_b: values.b,
              },
            })
          );
          dispatch(setApplyUserParams(true));
        }}
      />
      <div className="cards-container h-100">
        <div className="cards-header d-flex flex-row justify-content-between align-items-center">
          <div className="header-title">Type Curve</div>
          <div className="header-menu-container d-flex flex-row">
            <div className="d-flex flex-row align-items-center">
              <div>Normalized By</div>
              <select
                className="ms-2"
                value={
                  normalizeBy || {
                    name: "Wellbore Length",
                    value: "wellbore_length",
                  }
                }
                onChange={handleNormalizeParamChanged}
              >
                {NormalizeOptions.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <select
              className="ms-2"
              value={selectedClusterIndex || 0}
              onChange={handleCluserSelectChanged}
            >
              {clustersSelector}
            </select>
            <select
              className="ms-2"
              value={declinePhase || "oil"}
              onChange={handleDecliePhaseChanged}
            >
              {DECLINE_PHASE_OPTIONS.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <div>
              {DatePeriodOptions.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="periodOpt"
                      id={"periodOpt" + index.toString()}
                      onChange={() => {
                        handlePeriodChanged(item.days);
                      }}
                      checked={activePeriod === item.days}
                    />
                    <label
                      htmlFor={"periodOpt" + index.toString()}
                      className="btn-label header"
                    >
                      {item.name}
                    </label>
                  </Fragment>
                );
              })}
            </div>
            <div className="cards-setting-btn">
              <SectionSetting disable />
            </div>
          </div>
        </div>
        <div className="cards-content d-flex flex-column justify-content-between align-items-stretch">
          <div className="validation-chart-wrapper mb-2">
            {historicalData ? (
              <TypeCurveTypeCurve
                historical_data={historicalData}
                recomm_data={recommData}
                user_dca={userDcaData}
                yUnit={getYUnit(declinePhase)}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ color: "rgba(255,255,255, 0.6)" }}
              >
                {
                  "Please set clusters first! must select: Wellbore length to show data"
                }
              </Empty>
            )}
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <button
              className="btn-custom"
              onClick={(e) => {
                showUserPreference();
              }}
              disabled={!projectClusterDataItems?.length}
            >
              Edit Parameters
            </button>
            <button
              className="btn-custom"
              onClick={(e) => {
                handleSubmitProject();
              }}
              disabled={!projectClusterDataItems?.length}
            >
              Validated to Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AiTypeCurveChart;
