import { convertToImperialDate } from "app/codes";
import React, { useEffect, useState } from "react";
import { getPlanning, getAssetPlannings } from "service/events";

function PlanningTable(props) {
  const { wellId, assetId } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const [plannings, setPlannings] = useState(null);

  useEffect(() => {
    if (wellId == null && assetId == null) {
      return;
    }
    showLoadingIcon && showLoadingIcon();
    if (wellId != null) {
      getPlanning(
        wellId,
        (result) => {
          setPlannings(result);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          console.error(error);
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    } else if (assetId != null) {
      getAssetPlannings(
        assetId,
        (result) => {
          setPlannings(result);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          console.error(error);
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    }
  }, [wellId, assetId, hideLoadingIcon, showLoadingIcon]);

  return (
    <>
      <table className="table table-borderless table-centered table-nowrap">
        <thead className="">
          <tr>
            <th style={{ width: "1.25rem" }}>
              <div className="d-flex flex-row align-items-center form-check align-middle">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                />
                <label className="form-check-label" htmlFor="customCheck1">
                  &nbsp;
                </label>
              </div>
            </th>
            <th>Well</th>
            <th>Event</th>
            <th>Event Type</th>
            <th>Start Date</th>
            <th>Duration(Days)</th>
            <th>Status</th>
            <th>LeadPerson</th>
            <th>Contact</th>
          </tr>
        </thead>
        {plannings && (
          <tbody>
            {plannings.map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: "1.25rem" }}>
                    <div className="d-flex flex-row align-items-center form-check align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"customCheck" + item.event}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"customCheck" + item.event}
                      >
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td>
                    <a href="javascript: void(0);" className="">
                      {item.well_name}
                    </a>{" "}
                  </td>
                  <td>{item.event}</td>
                  <td>{item.event_type}</td>
                  <td>{convertToImperialDate(item.start_date)}</td>
                  <td>
                    <div className="circle-pill">{item.duration}</div>
                  </td>
                  <td>{item.status}</td>
                  <td>{item.lead}</td>
                  <td>
                    <button className="btn-custom">{item.contact}</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </>
  );
}

export default PlanningTable;
