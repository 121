import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Tooltip, SVGOverlay, Pane } from "react-leaflet";
// import { useMap } from "react-leaflet/hooks";
import "components/leaflet-area-select";
import L from "leaflet";

import SelectArea from "./SelectArea";
import redMarker from "./images/red-marker.png";
import greenMarker from "./images/green-marker.png";

const LMap = (props) => {
  const {
    objectives,
    center,
    zoom,
    selectedObjectiveIds, // a Set
    bounds,
    objectiveSelectedHandler,
    areaSelectedHandler,
    showArrow,
    wellFilter,
  } = props;

  const [map, setMap] = useState(null);
  const [filteredWells, setFilteredWells] = useState(null);
  const [arrows, setArrows] = useState([]); // [[x1,y1,x2,y2],[x2,y2,x3,y3],...]

  useEffect(() => {
    if (!selectedObjectiveIds || !bounds) return;
    let arrowsArray = [];
    // get well obj
    let geoCoords = []; // [[lat1,lng1], [lat2, lng2], ...]

    [...selectedObjectiveIds].forEach(uwi => {
      let resArr = objectives.filter(item => item.apiId === uwi);
      if (!resArr || !resArr.length) return;
      let { Latitude, Longitude } = resArr[0];
      geoCoords.push([Latitude, Longitude]);
    });

    let [[bx1, by1], [bx2, by2]] = bounds;
    let width = Math.abs(bx2 - bx1), height = Math.abs(by2 - by1);
    for (let i = 0; i < geoCoords.length - 1; i++) {
      let x1, y1, x2, y2; // result values
      // codes under is very confusing because the percentage coordinates in the map are very different against geo-coordinates
      let [gx1, gy1] = geoCoords[i];
      let [gx2, gy2] = geoCoords[i + 1];
      y1 = (Math.abs(gx1 - bx1) / width) * 100 + '%';
      x1 = (Math.abs(gy1 - by1) / height) * 100 + '%';
      y2 = (Math.abs(gx2 - bx1) / width) * 100 + '%';
      x2 = (Math.abs(gy2 - by1) / height) * 100 + '%';
      arrowsArray.push([x1, y1, x2, y2]);
    }
    setArrows(arrowsArray);
  }, [objectives, selectedObjectiveIds, bounds]);

  useEffect(() => {
    if (map) {
      if (bounds) {
        if (
          bounds.length === 2 &&
          bounds[0][0] &&
          bounds[0][1] &&
          bounds[1][0] &&
          bounds[1][1]
        )
          map.fitBounds(bounds);
      } else {
        map.setView(center, zoom);
      }
    }
  }, [center, zoom, bounds, map]);

  function getMarker(objective, idsSet) {
    return idsSet && idsSet.has(objective.apiId) ? redMarker : greenMarker;
  }

  useEffect(() => {
    // filter for wells, just for testing
    setFilteredWells(wellFilter ? objectives.filter(well => wellFilter.includes(well.apiId)) : objectives)
  }, [objectives, wellFilter]);

  return (
    <>
      <MapContainer
        className="map-container"
        center={center}
        zoom={zoom}
        ref={setMap}
      >
        <TileLayer
          // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          attribution=""
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <SelectArea
          Objectives={wellFilter ? objectives.filter(well => wellFilter.includes(well.apiId)) : objectives}
          selectedObjectivesHandler={areaSelectedHandler}
        />
        {
          filteredWells &&
          filteredWells.length > 0 &&
          filteredWells.map((objective, index) => (
            <Marker
              key={index}
              zIndexOffset={0}
              icon={
                new L.icon({
                  iconUrl: getMarker(objective, selectedObjectiveIds),
                  iconAnchor: null,
                  popupAnchor: null,
                  shadowUrl: null,
                  shadowSize: null,
                  shadowAnchor: null,
                  opacity: 0.5,
                  iconSize: new L.Point(objective.Value, objective.Value),
                })
              }
              position={[objective.Latitude, objective.Longitude]}
              eventHandlers={{
                click: () => {
                  objectiveSelectedHandler(objective);
                },
              }}
            >
              <Tooltip
                sticky
                className="tooltip"
                direction="top"
                offset={[0, 0]}
                opacity={1}
                permanent
              >
                <p className="fw-bold">{objective.Name}</p>
              </Tooltip>
            </Marker>
          ))
        }
        {
          showArrow && arrows && (arrows.length > 0) && <Pane name="arrows" style={{ zIndex: 1000 }}>
            <SVGOverlay
              bounds={bounds}
              zIndex={10000}
            >
              <defs>
                <marker
                  id="arrowhead"
                  markerWidth="10"
                  markerHeight="7"
                  refX="0"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon
                    points="0 0, 10 3.5, 0 7"
                    fill="red"
                  />
                </marker>
              </defs>
              {
                arrows.map(([x1, y1, x2, y2], index) => {
                  return <line
                    key={index}
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    fill="red"
                    strokeWidth='3'
                    stroke="red"
                    markerEnd="url(#arrowhead)"
                  />
                })
              }
            </SVGOverlay>
          </Pane>
        }
      </MapContainer>
    </>
  );
};

export default LMap;
