import { get, post, remove } from "./base";

const baseURL = 'http://62.169.24.57:2020/'

export function getCOProjects() {
  return get('/projects/settings/', {}, {
    baseURL
  }).then(res => res.data)
}

export function getCOProjectSettings(projectId, backupId) {
  return get(`/projects/edit/${ projectId }/${ backupId }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function upsertProjectSettings(data) {
  return post(`/projects/settings/create_or_update/`, data, {}, {
    baseURL
  })
}

export function runCOProject(data) {
  return post(`/run/create/`, data, {}, {
    baseURL
  })
}

export function getProjectNumOfStages(projectId) {
  return get(`/run/project_stages/${ projectId }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStagePlotResult(projectId, rateNo, stageNo) {
  return get(`/run/rate_${ rateNo }/${ projectId }/${ stageNo }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getProjectListWithResults() {
  return get('/run/results/', {}, {
    baseURL
  }).then(res => res.data)
}

export function deleteCOProject(projectId) {
  return remove(`/projects/settings/${ projectId }/delete/`, {}, {}, {
    baseURL
  }).then(res => res.data)
}

export function getWellPlotResult(projectId) {
  return get(`/run/well/rates/${ projectId }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dBase(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/base/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dCommodityFile(projectId, stageNumber, commodity, model, date) {
  return get(`/run/files/${ projectId }/${ stageNumber }/${ commodity.toLowerCase() }/${ model }/${ date }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dPressure(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/pressure/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dGas(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/sgas/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStage3dWater(projectId, stageNumber) {
  return get(`/run/files/${ projectId }/${ stageNumber }/swat/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getWellRates(projectId, model) {
  return get(`/run/well/rates/${ projectId }/${ model }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getWellCumulatives(projectId, model) {
  return get(`/run/well/cumulative/${ projectId }/${ model }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getStageRates(projectId, stage, model) {
  return get(`/run/rate_0/${ projectId }/${ stage }/${ model }/`, {}, {
    baseURL
  }).then(res => ({
    ...res.data,
    stage
  }))
}

export function getStageCumulatives(projectId, stage, model) {
  return get(`/run/rate_1/${ projectId }/${ stage }/${ model }/`, {}, {
    baseURL
  }).then(res => ({
    ...res.data,
    stage
  }))
}

export function get3dStageTree(projectId, model, stageNo) {
  return get(`/run/3d/${ projectId }/${ model }/tree/${ stageNo }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getRunProgress(projectId, model) {
  return get(`/run/progress/${ projectId }/${ model }/`, {}, {
    baseURL
  }).then(res => res.data)
}

export function getCustomizedModels() {
  return get('/projects/customized/models/', {}, {
    baseURL
  }).then(res => res.data)
}

export function getKIcReferences() {
  return get('/projects/customized/kic_references/', {}, {
    baseURL
  }).then(res => res.data)
}