import { EditableProTable } from '@ant-design/pro-components';
import FormSection from 'components/form-section'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Form, Popconfirm, Row, Select, Typography } from 'antd';
import { generateUuid } from 'utils/dataUtil';

import classes from './Table.module.scss'
import { isNumeric } from 'utils/string';
import { isDecreasing, isIncreasing } from 'utils/number';
import { COLORS } from 'app/codes';
import Chart from 'chart.js/auto';

const units = {
  PRESSURE: {
    FIELD: '(psia)',
    METRIC: '(bar)'
  },
  FVF: {
    FIELD: '(ft3/Mscf)',
    METRIC: '(rm3/sm3)'
  },
  VISCOSITY: {
    FIELD: '(cP)',
    METRIC: '(cP)'
  }
}

const GasTable = ({ fields = [], operation }) => {
  /**
   * @type {React.MutableRefObject<Chart>} chart
   */
  const chart = useRef()
  const chartCanvas = useRef()
  const form = Form.useFormInstance()

  const simulationUnit = Form.useWatch(["GENERAL", "unit"])

  const [data, setData] = useState([])
  const [errors, setErrors] = useState([])

  const fetchData = () => {
    setData(fields.map((field, index) => ({
      ...form.getFieldValue(["PVT", "gas", field.name]),
      key: index
    })) ?? [])
  }

  useEffect(() => {
    validateData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  const columns = useMemo(
    /**
     * 
     * @returns {import('@ant-design/pro-components').ProColumnType[]}
     */
    () => [
      {
        key: 'key',
        dataIndex: 'key',
        title: '#',
        editable: false,
        render: (dom, record, index) => index + 1
      },
      {
        key: 'PRESSURE',
        dataIndex: 'PRESSURE',
        title: `Pressure ${ units.PRESSURE[simulationUnit] ?? '' }`,
        editable: true
      },
      {
        key: 'FVF',
        dataIndex: 'FVF',
        title: `FVF ${ units.FVF[simulationUnit] ?? '' }`,
        editable: true,
      },
      {
        key: 'VISCOSITY',
        dataIndex: 'VISCOSITY',
        title: `Viscosity ${ units.VISCOSITY[simulationUnit] ?? '' }`,
        editable: true,
      },
      {
        title: "Operation",
        valueType: "option",
        key: "operation",
        width: 160,
        render: (text, record, index, action) => [
          <Button
            key="editable"
            type="text"
            className="text-primary"
            onClick={() => {
              action?.startEditable?.(record.key);
            }}
          >
            Edit
          </Button>,
          <Popconfirm
            key="delete"
            title="Sure to delete?"
            onConfirm={(e) => {
              operation?.remove?.(index)
            }}
          >
            <Button type="text" danger>Delete</Button>
          </Popconfirm>
        ],
      },
    ]
    , [operation, simulationUnit])

  const chartOptions = useMemo(
    /**
     * 
     * @returns {import('chart.js').ChartData}
     */
    () => {
      const labels = data.map(row => row[columns[1].dataIndex])

      return {
        labels,
        datasets: [
          {
            label: columns[2].title,
            data: data.map(row => row[columns[2].dataIndex]),
            fill: false,
            borderColor: COLORS[0],
            yAxisID: columns[2].dataIndex,
            tension: 0.4
          },
          {
            label: columns[3].title,
            data: data.map(row => row[columns[3].dataIndex]),
            fill: false,
            borderColor: COLORS[1],
            yAxisID: columns[3].dataIndex,
            tension: 0.4
          }
        ]
      }
    },
    [columns, data])

  /**
   * 
   * @param {import('chart.js').ChartData} config 
   * @returns 
   */
  const updateChart = (config) => {
    if (!chartCanvas.current) return
    if (!chart.current) return

    chart.current.data.labels = config.labels
    chart.current.data.datasets = config.datasets

    chart.current.update()
  }

  useEffect(() => {
    if (chart.current) chart.current.destroy()
    chart.current = new Chart(chartCanvas.current, {
      type: 'line',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          [columns[2].dataIndex]: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: columns[2].title
            }
          },
          [columns[3].dataIndex]: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
              text: columns[3].title
            }
          },
          x: {
            display: true,
            title: {
              display: true,
              text: columns[1].title
            }
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateChart(chartOptions)
  }, [chartOptions])

  const validateData = useCallback((_data = []) => {
    setErrors([])

    const _errors = []

    if (!_data.length) return;

    const pressureValues = []
    const fvfValues = []
    const viscosityValues = []

    _data.forEach((row, index) => {
      if (!isNumeric(row.PRESSURE)) {
        _errors.push(`Value of Pressure in row #${ index + 1 } must be a number`)
      } else {
        const pressure = parseFloat(row.PRESSURE)
        if (pressure <= 0) {
          _errors.push(`Value of Pressure in row #${ index + 1 } must be > 0`)
        } else pressureValues.push(pressure)
      }
      if (!isNumeric(row.FVF)) {
        _errors.push(`Value of FVF in row #${ index + 1 } must be a number`)
      } else {
        const fvf = parseFloat(row.FVF)
        if (fvf <= 0) {
          _errors.push(`Value of FVF in row #${ index + 1 } must be > 0`)
        } else fvfValues.push(fvf)
      }
      if (!isNumeric(row.VISCOSITY)) {
        _errors.push(`Value of Viscosity in row #${ index + 1 } must be a number`)
      } else {
        const viscosity = parseFloat(row.VISCOSITY)
        if (viscosity <= 0) {
          _errors.push(`Value of Viscosity in row #${ index + 1 } must be > 0`)
        } else viscosityValues.push(viscosity)
      }
    })

    if (_errors.length) {
      setErrors(_errors)
      return
    }

    const isPressureIncreasing = isIncreasing(pressureValues)
    if (!isPressureIncreasing) {
      _errors.push(`The values of Pressure must be increasing`)
    }

    const isFVFDecreasing = isDecreasing(fvfValues)
    if (!isFVFDecreasing) {
      _errors.push(`The values of FVF must be decreasing`)
    }

    const isViscosityIncreasing = isIncreasing(viscosityValues)
    if (!isViscosityIncreasing) {
      _errors.push(`The values of Viscosity must be increasing`)
    }

    setErrors(_errors)
  }, [])

  return (
    <FormSection
      title="Gas"
      extra={
        <div className="d-flex justify-content-end align-items-center gap-2">
          <Typography.Text className="text-light">Reference</Typography.Text>
          <Select style={{ minWidth: 120 }} dropdownMatchSelectWidth={false} />
        </div>
      }
    >
      <Row>
        <Col span={12}>
          <EditableProTable
            options={false}
            pagination={false}
            search={false}
            columns={columns}
            value={data}
            rowKey="key"
            sticky={{
              offsetHeader: 32
            }}
            className={classes.tableContainer}
            cardProps={{
              className: classes.card
            }}
            tableClassName={classes.table}
            tableExtraRender={() => (
              <ul className="px-24 text-danger">
                {errors.map(errMsg => (
                  <li key={errMsg}>
                    <Typography.Text type="danger">{errMsg}</Typography.Text>
                  </li>
                ))}
              </ul>
            )}
            editable={{
              type: 'multiple',
              actionRender: (row, config, defaultDom) => [
                defaultDom.save,
                defaultDom.cancel,
              ],
              onSave: (rowKey, data, row) => {
                const { index, isNew, ...recordData } = data
                if (isNew) {
                  operation?.add?.(recordData)
                } else {
                  form.setFieldValue(["PVT", "gas", index], recordData)
                  fetchData()
                }
              }
            }}
            recordCreatorProps={{
              record: {
                key: generateUuid(),
                isNew: true
              }
            }}
          />
        </Col>
        <Col span={12} className="pb-2">
          <canvas ref={chartCanvas} />
        </Col>
      </Row>
    </FormSection>
  )
}

export default GasTable