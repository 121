export function toDateSeries(dataSeries) {
  let dateDataSeries = [];
  for (let data of dataSeries) {
    dateDataSeries.push({ date: new Date(data[0]), value: data[1] });
  }
  dateDataSeries.sort((x1, x2) => {
    return x1.date - x2.date;
  });
  return dateDataSeries;
}

export function computeAcummulativeData(dataSeries, startDate, endDate) {
  let dateSeries = dataSeries;
  if (startDate != null && endDate != null) {
    dateSeries = dataSeries.filter(
      (item) => item.date >= startDate && item.date <= endDate
    );
  }
  let totalValue = 0;
  for (let item of dateSeries) {
    totalValue = totalValue + item.value;
  }
  return totalValue;
}

export function computeAcummulativeCurve(dataSeries, startDate, endDate) {
  if (dataSeries == null) {
    return null;
  }
  let dateSeries = dataSeries;
  if (startDate != null && endDate != null) {
    dateSeries = dataSeries.filter(
      (item) => item.date >= startDate && item.date <= endDate
    );
  }

  let curveData = [];
  let value = 0;
  for (let dataItem of dateSeries) {
    value += dataItem.value;
    curveData.push([dataItem.date, value]);
  }
  return curveData;
}

export function computeNormalCurve(dataSeries, startDate, endDate) {
  if (dataSeries == null) {
    return null;
  }
  let dateSeries = dataSeries;
  if (startDate != null && endDate != null) {
    dateSeries = dataSeries.filter(
      (item) => item.date >= startDate && item.date <= endDate
    );
  }
  return dateSeries;
}
