import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, message } from "antd";
import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    let token = getAuthToken();
    if (token) {
      let headers = {};
      if (config.headers) {
        headers = { ...config.headers };
      }
      headers = { ...headers, Authorization: " Token " + token };
      config.headers = headers;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const status = response.status;
    if (status === 401) {
      message.error("You need to be logged in.");
      localStorage.removeItem("user");
      setTimeout(() => {
        // location.href = "/";
        // redirect("/login");
      }, 300);
      return Promise.reject(response);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function getAuthToken() {
  const localItem = localStorage.getItem("user");
  let token = null;
  if (localItem != null) {
    const user = JSON.parse(localItem);
    token = user.token;
  }
  return token;
}

export function get(url, params, config, onSuccess, onError) {
  return axios
    .get(url, { params: params, ...config })
    .then((res) => {
      onSuccess?.(res);
      return res
    })
    .catch((error) => {
      onError?.(error);
      Modal.warning({
        title: "Get information error",
        content:
          "Please contact with system manager. error info: " + error.message,
      });
      throw error
    });
}

export function post(
  url,
  data,
  params,
  config,
  onSuccess,
  onError,
  showSuccess,
  hideError
) {
  return axios
    .post(url, data, { params: params, ...config })
    .then((res) => {
      onSuccess?.(res);
      showSuccess && message.success("Sending data successful!");
      return res
    })
    .catch((error) => {
      onError?.(error);
      !hideError &&
        Modal.error({
          title: "Sending data error",
          content:
            "Please contact with system manager. error info: " + error.message,
        });
      throw error
    });
}

export function put(
  url,
  data,
  params,
  config,
  onSuccess,
  onError,
  showSuccess,
  hideError
) {
  return axios
    .put(url, data, { params: params, ...config })
    .then((res) => {
      onSuccess?.(res);
      showSuccess && message.success("Sending data successful!");
      return res
    })
    .catch((error) => {
      onError?.(error);
      !hideError &&
        Modal.error({
          title: "Sending data error",
          content:
            "Please contact with system manager. error info: " + error.message,
        });
      throw error
    });
}

export function patch(
  url,
  data,
  params,
  config,
  onSuccess,
  onError,
  showSuccess,
  hideError
) {
  return axios
    .patch(url, data, { params: params, ...config })
    .then((res) => {
      onSuccess?.(res);
      showSuccess && message.success("Sending data successful!");
      return res
    })
    .catch((error) => {
      onError?.(error);
      !hideError &&
        Modal.error({
          title: "Sending data error",
          content:
            "Please contact with system manager. error info: " + error.message,
        });

      throw error
    });
}

export function remove(
  url,
  data,
  params,
  config,
  onSuccess,
  onError,
  noConfirm
) {
  return new Promise((rs, rj) => {
    const execFunc = () => {
      axios({
        method: "delete",
        url: url,
        params: params,
        data: data,
        ...config,
      })
        .then((res) => {
          onSuccess?.(res);
          rs(res)
          !noConfirm && message.success("Removing data successful!");
        })
        .catch((error) => {
          onError?.(error);
          rj(error)
          Modal.error({
            title: "Removing data error",
            content:
              "Please contact with system manager. error info: " + error.message,
          });
        });
    };

    noConfirm
      ? execFunc()
      : Modal.confirm({
        title: "Do you Want to delete these items?",
        icon: <ExclamationCircleFilled />,
        content: "This action can't be undone. Please be careful!",
        zIndex: 1002,
        onOk() {
          execFunc();
        },
        onCancel() {
          console.log("Canceled");
          onError?.({ message: "User cancelled" });
          rj({ message: "User cancelled" })
        },
      });
  })
}
