
export const CategoryOptions = [
  { name: "General", value: "general" },
  { name: "Completion", value: "completion" },
  { name: "Deviation", value: "deviation" },
  { name: "Injection", value: "injection" },
  { name: "Log", value: "log" },
  { name: "Top", value: "top" },
  { name: "Prescriptive", value: "prescriptive" },
  { name: "Production", value: "production" },
  { name: "Survey", value: "survey" },
  { name: "Well", value: "well" },
];

export function getLasWellInfo(inputData) {
  let well_info = {};
  if (inputData.length > 1) {
    let wellInfos = inputData[1].elementValues;
    for (let info of wellInfos) {
      if (info.name === "UWI") {
        well_info.uwi = info.data.replaceAll("-", "");
      } else if (info.name === "NULL") {
        well_info.NULL = info.data;
      }
    }
  }
  return well_info;
}

export function getLasData(inputData) {
  return inputData[inputData.length - 1].elementValues;
}

