/*
    render single or multiple parameters curve(provided by PpWellCurve) of one well.
    support loose or tight mode for Log Viewer and Correlation.
*/
import React, { Fragment, useEffect, useRef, useState } from "react";
import PpWell from "./PpWell";
import { Stage, Layer, Line } from 'react-konva';
import { useDispatch, useSelector } from "react-redux";
import { selectActiveAssetId, selectAssetList, selectSelectedWells, selectSelectedLogNames, selectTopsList, selectTopsSelected, selectTopNameList, selectFontColorSetting, setLogListForNewLog, setLoading } from "features/petrophysics/petrophysicsSlice";
import { getWellLogsWithAttr } from "service/petrophysics";
import { COLORS, exportAsImage, getColorIndexFromTopNamesList } from "app/codes";
import { CameraOutlined, SettingOutlined } from "@ant-design/icons";
import PpSettingSelector from "../selector/PpSettingSelector";

const PpWellsWithCrossSection = (props) => {
    const localRef = useRef();
    const { gapWidth, columnWidth, space, height, showTop, syncDepth } = props;
    const { loadingIconActions } = props;
    const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
    const [from, setFrom] = useState({});
    const [to, setTo] = useState({});
    const activeAssetId = useSelector(selectActiveAssetId);
    const assetList = useSelector(selectAssetList);
    const wellList = useSelector(selectSelectedWells);
    const selectedLogNames = useSelector(selectSelectedLogNames);
    const topsList = useSelector(selectTopsList);
    const selectedTop = useSelector(selectTopsSelected);
    const topNamesList = useSelector(selectTopNameList);
    const fontColor = useSelector(selectFontColorSetting);

    const [wellData, setWellData] = useState({});
    const [topCoords, setTopCoords] = useState({});
    const [startEndValue] = useState([0, 100]);
    const [showSettingDialog, setShowSettingDialog] = useState(false);
    // const [gapList, setGapList] = useState(false);  // Gap coordinates, gaps between logs. for drawing top lines between wells. fox Ex: [[null, 100], [300, 300], [500, null]]

    const topCoordsRef = useRef({});    //topCoordsRef.current: {"tl": 13.345, ...}
    const startEndRef = useRef({});

    const dispatch = useDispatch();

    useEffect(() => {
        // Load log data, and rendering. if selectedLogNames is empty, still call api to preload log data, but don't show the data now
        if (!wellList || wellList.length === 0 || !selectedLogNames || selectedLogNames.length === 0) return;

        // dispatch(setLoading(true));
        showLoadingIcon && showLoadingIcon();
        getWellLogsWithAttr(
            wellList,
            selectedLogNames.filter((item)=>item.logNameId !== undefined).map(item => {
                return {
                    "log_name_id": item.logNameId,
                    "log_type": item.logType,
                    "log_name": item.logName,
                };
            }),
            (res) => {
                /* res：
                    [
                        {
                            'uwi': 'uwi1',
                            'well_name': 'xxx',
                            'logs': [
                                {
                                    'log_name_id': 59,
                                    'log_type': 'Gamma_Ray',
                                    'log_name': 'GRN',
                                    'log_unit': 'gAPI',
                                    'unit_min': '0',
                                    'unit_max': '200',
                                    'log': [
                                        [100.5, 1000],
                                        [101, 500],
                                        ...
                                    ]
                                },
                                ...
                            ],
                            ...
                        },
                        ...
                    ]
                */
                let tempWellsLogs = {};
                wellList.map(w => {
                    let item = res.find(r => r.uwi === w);
                    if (!item) return null;
                    let { well_name, logs } = item;
                    tempWellsLogs[well_name] = {};
                    let logNameList = {};
                    logs.map(log => {
                        const { log_name_id, log_type, log_name, log_unit, unit_min, unit_max, log: logData } = log;
                        const logId = log_type + ":" + log_name;
                        tempWellsLogs[well_name][logId] = [];
                        logNameList[logId] = { log_name_id, log_type, log_name, log_unit, unit_min, unit_max };
                        logData.map(oneLogData => {
                            tempWellsLogs[well_name][logId].push([
                                // oneLogData.depth,
                                // oneLogData.value,
                                oneLogData[0],
                                oneLogData[1],
                                unit_min,
                                unit_max,
                            ]);
                            return null;
                        });
                        return null;
                    });
                    setWellData(tempWellsLogs);
                    dispatch(setLogListForNewLog(logNameList));
                    dispatch(setLoading(false));
                    return null;
                });
                hideLoadingIcon && hideLoadingIcon();
            },
            (e) => {
                dispatch(setLoading(false));
                console.error("load curve data failed.");
                hideLoadingIcon && hideLoadingIcon();
            }
        )
    }, [wellList, selectedLogNames, showLoadingIcon, hideLoadingIcon, dispatch]);

    const getYAxisBoundary = () => {
        let min = null, max = null;
        Object.keys(wellData).map(wellName => {
            const well = wellData[wellName];
            if (Object.keys(well).length > 0) {
                const paramData = well[Object.keys(well)[0]];
                const paramDataLength = paramData.length;

                min = (min === null || min > paramData[0][0]) ? paramData[0][0] : min;
                max = (max === null || max < paramData[paramDataLength - 1][0]) ? paramData[paramDataLength - 1][0] : max;
            }
            return null;
        });
        return [min, max];
    }

    const getWellTopsFromTopsListByUwiAndSelectedTop = (wellName) => {
        let res = {};
        if (!topsList || topsList.length === 0 || !wellName) return null;
        topsList.map(well => {
            if (well == null || well.well_name !== wellName)
                return null;
            Object.keys(well.tops).map(topName => res[topName] = well.tops[topName]['MD'] || res[topName]);
            return null;
        })
        return res;
    };

    // record well top coordinates in an array, the indexes in array are the same with wellData
    const handleCoordsChange = (coord) => {
        if (!coord) return;

        const [name, top, index] = coord;
        if (!name || !top || Object.keys(top).length === 0) return;

        if (!topCoords[name]
            || ((index === 0)
                && (JSON.stringify(topCoords[name]) !== JSON.stringify(top)))
        ) {
            let temp = {};
            temp[name] = top;
            topCoordsRef.current[name] = top;
        }
    }
    const handleZoomChange = (uwi, startEnd) => {
        if (!startEnd || startEnd.length < 2) return;
        if (startEnd[0] !== startEndValue[0] || startEnd[1] !== startEndValue[1]) {
            startEndRef.current[uwi] = startEnd;
        }
    }

    const handleChartReady = (coord) => {
        if (!coord) return;

        const [name, top, index] = coord;
        if (!name || !top || Object.keys(top).length === 0) return;

        if (!topCoords[name]
            || ((index === 0)
                && (JSON.stringify(topCoords[name]) !== JSON.stringify(top)))
        ) {
            let temp = {};
            temp[name] = top;
            topCoordsRef.current[name] = top;
            setTopCoords({ ...topCoords, ...temp })
        }
    }

    const getCurveAreaWidth = (wellData) => {
        let itemWidth = parseInt(gapWidth) + parseInt(columnWidth), spaceWidth = parseInt(space || 85);
        let result = 0;
        Object.keys(wellData).map(wellName => result += itemWidth * Object.keys(wellData[wellName]).length + spaceWidth);
        return result;
    }

    const handleClose = (show) => {
        setShowSettingDialog(show || false);
    }

    return (
        <div
            style={{
                ...props.style,
                height: '100%',
                overflowX: 'auto',
                overflowY: 'hidden',
            }}
            onMouseUp={() => {
                setTopCoords({ ...topCoordsRef.current });
            }}
        >
            <div ref={localRef}
                style={{ width: (getCurveAreaWidth(wellData) - 500) + 'px', }}>
                <div style={{
                    marginTop: '5px',
                    width: getCurveAreaWidth(wellData) + 'px',
                    display: 'flex',
                    color: fontColor,
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start'
                }}>
                    <CameraOutlined
                        style={{
                            marginLeft: '5px',
                            color: 'white'
                        }}
                        onClick={() =>
                            exportAsImage(localRef.current, "vargeo-chart")
                        }
                    />
                    <SettingOutlined
                        style={{
                            marginLeft: '5px',
                            color: 'white'
                        }}
                        onClick={() =>
                            setShowSettingDialog(true)
                        }
                    />
                    {
                        showSettingDialog && <div
                            className="popup-window"
                            style={{ left: '400px', top: '100px', width: '20em', height: '21em' }}
                        >
                            <PpSettingSelector onClose={handleClose} />
                        </div>
                    }
                    {/* {
                    showLoading && <LoadingOutlined style={{ marginLeft: '5px', color: 'white' }} />
                } */}
                </div>
                {wellList.length !== 0 && <div style={{
                    marginLeft: '50px',
                    width: getCurveAreaWidth(wellData) + 'px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start'
                }}>
                    {Object.keys(wellData).map((wellName, index) => {
                        let myNode = {};
                        myNode[index] = 'well' + index;
                        if (from[index] !== myNode[index])
                            setFrom({ ...from, ...myNode });
                        if (index !== 0 && to[index - 1] !== myNode[index]) {
                            let lastTo = {};
                            lastTo[index - 1] = myNode[index];
                            setTo({ ...to, ...lastTo });
                        }
                        return (
                            <Fragment key={index}>
                                <div className={"well" + index}
                                    width={parseInt(columnWidth) * Object.keys(wellData[wellName]).length + 'px'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginLeft: '-40px'
                                    }}
                                >
                                    <PpWell
                                        uwi={wellName}
                                        wellData={wellData[wellName]}
                                        gapWidth={gapWidth}
                                        columnWidth={columnWidth}
                                        tops={showTop ? getWellTopsFromTopsListByUwiAndSelectedTop(wellName) : null}
                                        height={height}
                                        onTopCoordsChange={handleCoordsChange}
                                        yAxisboundary={getYAxisBoundary(wellName)}
                                        onZoomChange={handleZoomChange}
                                        syncDepth={syncDepth}
                                        startEndValue={startEndRef.current[wellName]}
                                        onChartReady={handleChartReady}
                                    />
                                </div>
                                <div
                                    width={(space || 85) + 'px'}
                                    style={{ marginTop: '0px', marginLeft: '-10px' }} >
                                    <Stage
                                        width={space || 85}
                                        height={parseInt(height)}>
                                        <Layer>
                                            {
                                                topCoords && topCoords[wellName] && Object.keys(topCoords[wellName]).map(topName => {  // one well may has multiple tops, so need a loop
                                                    if (index + 1 >= Object.keys(topCoords).length) return null;
                                                    const getCurrentTopPoint = (wellName, topName) => {
                                                        return parseInt(topCoords[wellName][topName]);
                                                    }

                                                    // find the next top has the same topName, even it is not next to this
                                                    const getNextTopPoint = (wellName, topName) => {
                                                        let wellNames = Object.keys(wellData);
                                                        let result = null, foundCurrent = false;
                                                        let s = 0;    // s: horizon distance = gapWidth * n + columnWidth * n + space   

                                                        for (let i = 0; i < wellNames.length; i++) {
                                                            if (wellNames[i] === wellName) {
                                                                foundCurrent = true;
                                                                continue;
                                                            }

                                                            if (!foundCurrent) continue;

                                                            if (!topCoords[wellNames[i]]) {
                                                                console.warn("Found well name not in top coordinates list!", wellNames[i])
                                                                continue;
                                                            }

                                                            let x1 = topCoords[wellName][topName],
                                                                x2 = topCoords[wellNames[i]][topName];

                                                            s += parseInt(space || 85);
                                                            if (x2 === null || x2 === undefined) {
                                                                // jump over this well without param data
                                                                let n = Object.keys(wellData[wellNames[i]]).length;    // n: number of params in a well
                                                                s += parseInt(gapWidth) * n + parseInt(columnWidth) * n;
                                                                continue;
                                                            }
                                                            let h = x2 - x1;    // h: vertical height   
                                                            result = parseInt(x1 + (parseInt(space || 85)) * h / s);
                                                            break;
                                                        }
                                                        return result;
                                                    }
                                                    if (selectedLogNames.length === 0 || getNextTopPoint(wellName, topName) === null || !selectedTop.includes(topName)) return null;
                                                    return (
                                                        <Line
                                                            x={0}
                                                            y={0}
                                                            key={topName}
                                                            points={[
                                                                0,
                                                                getCurrentTopPoint(wellName, topName),
                                                                70,
                                                                getNextTopPoint(wellName, topName) === null
                                                                    ? getCurrentTopPoint(wellName, topName)
                                                                    : getNextTopPoint(wellName, topName)
                                                            ]}
                                                            tension={0.5}
                                                            offsetX={-10}
                                                            dashEnabled={false}
                                                            // dash={[50, 20, 50, 20]}
                                                            closed
                                                            stroke={COLORS[getColorIndexFromTopNamesList(topNamesList, topName) + 2]}
                                                            strokeWidth={1}
                                                        />
                                                    )
                                                })
                                            }
                                        </Layer>
                                    </Stage>
                                </div>
                            </Fragment>
                        )
                    })
                    }
                </div>}
            </div>
        </div>
    )
};
export default PpWellsWithCrossSection;