import React from "react";
import { Link } from "react-router-dom";

function Logout() {
  return (
    <div className="authentication-bg">
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <a href="/" className="mb-5 d-block auth-logo">
                  <img
                    src="/static/images/logo-dark.png"
                    alt=""
                    height="22"
                    className="logo logo-dark"
                  />
                  <img
                    src="/static/images/logo-light.png"
                    alt=""
                    height="22"
                    className="logo logo-light"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className=" card-body">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Come back Soon!!!</h5>
                  </div>
                  <div className=" text-center p-2 mt-4">
                    <div className="alert alert-success text-center">
                      You are successfully logged out.
                    </div>

                    <div className="mt-4 ">
                      <Link
                        to="/login/"
                        className="btn btn-primary w-100 waves-effect waves-light "
                      >
                        Sign In Again
                      </Link>
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <p className="text-muted mb-0">
                      Don&apos;t have an account ?{" "}
                      <Link
                        to="/register/"
                        className="text-primary fw-semibold"
                      >
                        {" "}
                        Signup
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5 text-center">
                <p>
                  © <script>document.write(new Date().getFullYear())</script>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
