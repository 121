import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddProjectForm from "components/project/AddProjectForm";
import ProjectItem from "components/project/ProjectItem";

import {
  setShowNewProjectPopup,
  setProjectItems,
} from "features/project/projectsSlice";

import {
  ProjectTypes,
  buildProjects,
  getProjects,
  deleteProject,
} from "service/projects";

function ProjectLayoutSection(props) {
  const { projectType, projects } = props;

  const [dataMap, setDataMap] = useState(null);
  let dispatch = useDispatch();

  useEffect(() => {
    if (!projects) {
      return;
    }
    const projectsDataMap = buildProjects(projects);
    setDataMap(projectsDataMap);
  }, [projects]);

  function handleDeleteProject(projectId) {
    deleteProject(
      projectId,
      (res) => {
        getProjects(
          projectType,
          (res) => {
            dispatch(setProjectItems(res));
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  }

  function generateSection(section, index) {
    return (
      <div
        key={"section_" + index}
        className="cards-container"
        style={index > 0 ? { marginTop: "1.875rem" } : { marginTop: 0 }}
      >
        <div className="cards-header d-flex flex-row justify-content-between align-items-center">
          <div className="header-title">
            <span>{section.title}</span>
          </div>
          <div className="header-menu-container d-flex flex-row">
            <button
              className="btn btn-primary"
              onClick={() => {
                dispatch(setShowNewProjectPopup(true));
              }}
            >
              + Add
            </button>
          </div>
        </div>
        <div className="projects-wrapper">
          <div className="projects-content">
            {section.sectionData
              .sort((p1, p2) => (p1.last_modified < p2.last_modified ? 1 : -1))
              .map((project, index) => {
                return (
                  <ProjectItem
                    key={project.name}
                    detail_url={section.url}
                    project={project}
                    index={index}
                    onDelete={handleDeleteProject}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <AddProjectForm
        input={{
          project_type: "",
          project_name: "",
          comment: "",
          redirect: "",
        }}
      />
      {dataMap && (
        <div className="d-flex flex-column">
          {ProjectTypes.map((prjTypeItem, index) => {
            const section = dataMap.get(prjTypeItem.value);
            if (section) {
              return generateSection(section, index);
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}

export default ProjectLayoutSection;
