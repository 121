import { get, post, remove } from "./base";

export const apiGet = (apiEndpoint, params, onSuccess, onError) => {
  get(
    apiEndpoint,
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export const apiPost = (apiEndpoint, params, onSuccess, onError) => {
  post(
    apiEndpoint,
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export const apiDelete = (apiEndpoint, params, onSuccess, onError) => {
  remove(
    apiEndpoint,
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export function getWellsInfo(uwis, onSuccess, onError) {
  let params = {};
  if (uwis) {
    if (Array.isArray(uwis)) uwis = uwis.join(',');
    params.uwis = uwis;
  } else {
    throw new Error("uwis cannot be null!");
  }

  apiGet("/api/wells/", params, onSuccess, onError);
}

export function getWellInfo(uwi, onSuccess, onError) {
  if (!uwi) {
    throw new Error("uwi cannot be null!");
  }

  apiGet(`/api/wells/${uwi}`, null, onSuccess, onError);
}


/**
 * 
 * @param {*} assetNames 
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns
 {
    "Bear River": [
        {
            "well_name": "BRC-171",
            "uwi": "42329395720000"
        },
        {
            "well_name": "BRC-726",
            "uwi": "42329417280000"
        },
    ]
}
 */
export function getAssetSimpleList(assetId, onSuccess, onError) {
  apiGet(`/api/assets/${assetId}/wells/simplelist`, null, onSuccess, onError);
}

export function getAssetSimpleListWithLog(assetId, onSuccess, onError) {
  let params = { has_log: 1 };
  apiGet(`/api/assets/${assetId}/wells/simplelist`, params, onSuccess, onError);
}

/**
 * 
 * @param {*} assetNames 
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns
 {
    "Bear River": [
        {
            "well_name": "BRC-171",
            "uwi": "42329395720000"
        },
        {
            "well_name": "BRC-726",
            "uwi": "42329417280000"
        },
    ]
}
 */
export function getAssetWellList(assetId, onSuccess, onError) {
  apiGet(`/api/assets/${assetId}/wells`, null, onSuccess, onError);
}

export function getWellLogNames(uwis, onSuccess, onError) {
  let params = {};
  if (uwis) {
    if (Array.isArray(uwis)) uwis = uwis.join(',');
    params.uwis = uwis;
  } else {
    throw new Error("uwis cannot be null!");
  }

  apiGet("/api/logs/log_names/", params, onSuccess, onError);
}

export function getWellLogs(uwis, logNames, onSuccess, onError) {
  let params = {};
  if (uwis) {
    if (Array.isArray(uwis)) uwis = uwis.join(',');
    params.uwis = uwis;
    if (logNames) {
      if (Array.isArray(logNames)) logNames = logNames.join(',');
      params.log_names = logNames;
    }
  } else {
    throw new Error("uwis cannot be null!");
  }

  apiGet("/api/logs/query/", params, onSuccess, onError);
}

/**
 * 
 * @param {*} uwis 
 * @param {*} topAttrs 
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns
 [
    {
        "uwi": "42329397390000",
        "well_name": "BRC-686",
        "tops": {
            "Lower Spraberry": {
                "coordinate_x": 31.7401,
                "coordinate_y": -102.1591,
                "TVD": 7305.0,
                "permeability": 0.0072,
                "porosity": 0.03,
                "thickness": 65.0,
                "TOC": 0.53,
                "poisson_ratio": 0.32,
                "young_modulus": 76.0,
                "MD": 7305.0,
                "test_c": 300.0,
                "test_a": 100.0,
                "test_d": 300.0
            },
            "test top": {
                "a": 100.0
            }
        }
    },
    {
        "uwi": "42329388430000",
        "well_name": "BRC-823",
        "tops": {
            "Lower Spraberry": {
                "coordinate_x": 31.9221,
                "coordinate_y": -102.281,
                "TVD": 7009.0,
                "permeability": 0.0054,
                "porosity": 0.03,
                "thickness": 94.0,
                "TOC": 0.49,
                "poisson_ratio": 0.13,
                "young_modulus": 10.0,
                "MD": 7009.0
            }
        }
    }
]
 */
export function getWellTops(uwis, topAttrs, onSuccess, onError) {
  let params = {};
  if (!uwis) {
    console.warn("uwis cannot be null!");
    return;
  }
  if (Array.isArray(uwis)) uwis = uwis.join(',');
    params.uwis = uwis;
    if (topAttrs) {
      if (Array.isArray(topAttrs)) topAttrs = topAttrs.join(',');
      params.top_attrs = topAttrs;
    }
  apiGet("/api/tops/", params, onSuccess, onError);
}

/**
 * 
 * @param {*} wellTops 
   {
    "42329397390000": {
        "Lower Spraberry": {
            "test_a": 100,
            "test_d": 300
        },
        "test top":{
            "a":100
        }
    }
  }
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns
  {
    "success": true,
    "updated": 3,
    "created": 0
  }
 */
export function saveWellTops(wellTops, onSuccess, onError) {
  post(
    "/api/tops/",
    wellTops,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

/**
 * 
 * @param {*} params 
  {
    "42329397390000": {
        "Lower Spraberry": ["test_a", "test_b"]
    }
  }
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export function deleteWellTopAttr(params, onSuccess, onError) {
  remove(
    "/api/tops/",
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}
/**
 * 
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns
 [
    {
        "name": "xs-1",
        "wells": [
            {
                "uwi": "42329406680000",
                "well_name": "BRC-747",
                "order_id": 0
            },
            {
                "uwi": "42329406700000",
                "well_name": "BRC-63",
                "order_id": 1
            }
        ]
    },
    {
        "name": "xs-2",
        "wells": [
            {
                "uwi": "42329406680000",
                "well_name": "BRC-747",
                "order_id": 0
            },
            {
                "uwi": "42329406700000",
                "well_name": "BRC-63",
                "order_id": 1
            }
        ]
    }
]
 */
export function getCrossSections(onSuccess, onError) {
  let params = {};
  apiGet("/api/logs/crosssection/", params, onSuccess, onError);
}

/**
 * 
 * @param {*} crossSection 
{
    "name": "xs-2",
    "uwis": ["42329406680000", "42329406700000"]
}
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns
{
    "success": true
}
 */
export function saveCrossSection(crossSection, onSuccess, onError) {
  post(
    "/api/logs/crosssection/",
    crossSection,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

/**
 * 
 * @param {*} crossSectionName 
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export function deleteCrossSection(crossSectionName, onSuccess, onError) {
  remove(
    "/api/logs/crosssection/",
    null,
    [crossSectionName],
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

// ==========Below are new log name and type APIs===============
export function getWellInputConfig(onSuccess, onError) {
  let params = {};
  apiGet("/api/datamgr/logs/input_config", params, onSuccess, onError);
}

/**
 * 
 * @param {*} uwis uwis=42329397370000
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns [
    {
        "log_type": "Resistivity",
        "log_name": "ILD",
        "description": "Deep Induction Resistivity"
    },
    {
        "log_type": "ACOUSTIC",
        "log_name": "DT",
        "description": "Compressional Slowness"
    },
...
]
 */
export function getWellLogAttrs(uwis, onSuccess, onError) {
  let params = {};
  if (uwis) {
    if (Array.isArray(uwis)) uwis = uwis.join(',');
    params.uwis = uwis;
  } else {
    throw new Error("uwis cannot be null!");
  }

  apiGet("/api/logs/log_names/", params, onSuccess, onError);
}

/**
 * 
 * @param {*} logAttrs {
    "log_type": "Density",
    "log_name": "DPHI",
    "description": "Density porosity1"
}
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export function addLogName(logAttrs, onSuccess, onError) {
  post(
    "/api/logs/dict/",
    logAttrs,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}
/**
 * 
 * @param {*} inputData inputData={
    'well_info': {
        "NULL": "-999", 
        "uwi": "15007224480000"
    },
    "logs": [
        ["4660", "45.991", "4.667", "0.097", "0.039", "16.097"],
        ["4660.5", "49.746", "4.385", "0.099", "0.045", "12.888"],
        ["4661", "77.713", "4.035", "0.108", "0.063", "10.81"],
        ["4661.5", "118.22", "3.72", "0.13", "0.091", "9.639"],
        ["4662", "156.115", "3.46", "0.184", "0.119", "8.508"],
        ...
    ],
    "dataStructure": [
       {
        "selectedColumn": {
          "log_type": "Depth", 
          "log_name": "MD" 
        },
        "selectedUnit": {
          "unit_system": "UK"
        }
      },
      {
        "selectedColumn": {
          "log_type": "Gamma_Ray", 
          "log_name": "GRN" 
        },
        "selectedUnit": {
          "unit_system": "UK"
        }
      },
      ...
    ]
} 
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export function uploadWellLogs(inputData, onSuccess, onError) {
  post(
    "/api/logs/input/",
    inputData,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

/**
 * 
 * @param {*} uwis [uwi1,uwi2,...,uwiN],
 * @param {*} logs [
        {
            'log_type':'Gamma_Ray',
            'log_name':'GRN'
        },
        ...
    ]
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns [
    {
        'uwi': 'uwi1',
        'well_name': 'xxx',
        'logs': [
            {
                'log_type': 'Gamma_Ray',
                'log_name': 'GRN',
                'log_unit': 'gAPI',
                'min_value': '0',
                'max_value': '200',
                'log': [
                    [100.5, 1000],
                    [101, 500],
                    ...
                ]
            },
            ...
        ],
        ...
    },
    ...

]
j
 */
export function getWellLogsWithAttr(uwis, logs, onSuccess, onError) {
  const param = {};

  if (!uwis || uwis.length === 0) {
    throw new Error("uwis cannot be null!");
  }
  param.uwis = uwis;
  if (logs && logs.length > 0)
    param.logs = logs;

  post(
    "/api/logs/query/",
    param,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

/**
 * 
 * @param {*} formulaForLogs {
    "log_name": "XXR",
    "log_type": "Gamma_Ray",
    "unit_system": "UK",
    "uwis":["42329406700000"],
    "logs": [
        {
            "symbol": "x1",
            "log_type": "Gamma_Ray",
            "log_name": "GRR"
        },
        {
            "symbol": "x2",
            "log_type": "Photoelectric_Factor",
            "log_name": "PEFZ"
        }
    ],
    "formula": "x1+x2"
}
 * @param {*} onSuccess 
 * @param {*} onError 
 * 
 * @returns {
    'log_name': 'log_xx1',
    'log_type': 'GB',
    'logs': [
        {
            'uwi': 'uwi_1',
            'log':[
                {
                    'depth': depth1,
                    'value': value1
                },
                ...
            ],
        },
        ...
    ]
}
*/
export function computeNewLogs(formulaForLogs, onSuccess, onError) {
  const { log_name, log_type, uwis, formula } = formulaForLogs;

  if (!formulaForLogs || !log_name || !log_type || !uwis || !formula) {
    throw new Error("formulaForLogs or params required cannot be null! ", formulaForLogs);
  }

  post(
    "/api/logs/compute/",
    formulaForLogs,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

/**
 * 
 * @param {*} params {
                        "id": 29
                      }
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export function deleteNewLog(params, onSuccess, onError) {
  remove(
    "/api/logs/formula/",
    params,
    null,
    {},
    (res) => {
      remove(
        "/api/logs/custom/",
        params,
        null,
        {},
        (res) => {
          onSuccess(res.data);
        },
        (error) => { console.error(error); onError(error) },
        true
      );
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getNewLogs(onSuccess, onError) {
  let params = {};
  apiGet("/api/logs/formula/", params, onSuccess, onError);
}
