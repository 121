import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectManagementGlobal } from "features/decline_analysis/dcaManagementSlice";
import { DECLINE_PHASE_OPTIONS } from "app/codes";

const PREFERRED_DECIMAL = 2;

function DcaManagementEurPanel(props) {
  const { eurData, unit, dcaTable: dcmTable } = props;
  const managementGlobal = useSelector(selectManagementGlobal);

  useEffect(() => {
    if (managementGlobal == null || managementGlobal) {
      return;
    }
  }, [managementGlobal, eurData]);

  return (
    <div className="popup-wrapper d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span>Eur Data</span>
        <div
          onClick={(e) => {
            props.onClose();
          }}
        >
          <span>X</span>
        </div>
      </div>
      <div className="eur-table">
        <table>
          {
            dcmTable ? <>
              <thead>
                <tr>
                  <td rowSpan="1" className="cell-header">Phase</td>
                  <th scope="col" className="cell-header">EUR</th>
                </tr>
              </thead>
              <tbody>
                {
                  DECLINE_PHASE_OPTIONS.map(({ value: phase, name: phaseName }, index) => (
                    <tr key={index}>
                      <td>{phaseName + '(' + (unit || ({ 'oil': 'bbl', 'gas': 'Mscf', 'liquid': 'bbl' })[phase]) + ')'}</td>
                      <td>{(eurData[phase + '_eurUserArps'] || eurData[phase + '_eurAutoRegression'])?.toFixed(PREFERRED_DECIMAL)}</td>
                      {/* <td>{eurData['predictAutoRegression']?.toFixed(0)}</td> */}
                    </tr>
                  ))
                }
              </tbody>
            </> : <>
              <thead>
                <tr>
                  <td rowSpan="1" className="cell-header">Phase</td>
                  <th scope="col" className="cell-header">Machine learning based</th>
                  <th scope="col" className="cell-header">User grouping based</th>
                </tr>
              </thead>
              <tbody>
                {
                  DECLINE_PHASE_OPTIONS.map(({ value: phase, name: phaseName }, index) => (
                    <tr key={index}>
                      <td>{phaseName + '(' + (unit || ({ 'oil': 'bbl/ft', 'gas': 'Mscf/ft', 'liquid': 'bbl/ft' })[phase]) + ')'}</td>
                      <td>{eurData[phase + '_eurAutoRegression']?.toFixed(PREFERRED_DECIMAL)}</td>
                      <td>{eurData[phase + '_predictAutoRegression']?.toFixed(PREFERRED_DECIMAL)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </>
          }
        </table>
      </div>
    </div>
  );
}

export default DcaManagementEurPanel;
