import { ConfigProvider, Tabs } from 'antd'
import enUS from "antd/locale/en_US";
import classNames from 'classnames';
import { useMemo } from 'react';

import classes from './SCALSetting.module.scss'
import SCALSettingTab from './SCALSettingTab';

const SCALSetting = () => {
  const tabs = useMemo(
    /**
     * 
     * @returns {import('antd').TabsProps['items']}
     */
    () => [
      {
        key: 'matrix',
        label: 'Matrix',
        children: <SCALSettingTab tab="matrix" />
      },
      {
        key: 'fracture',
        label: 'Fracture',
        children: <SCALSettingTab tab="fracture" />
      }
    ]
    , [])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      <Tabs type="card" items={tabs} className={classNames(classes.tabs, classes.parentTabs)} destroyInactiveTabPane={false} />
    </ConfigProvider>
  )
}

export default SCALSetting