import { get, post, remove } from "./base";

export function getWellDeviation(wellId, onSuccess, onError) {
  get(
    "/api/deviations/",
    { uwi: wellId },
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function saveWellDeviation(wellsDeviations, onSuccess, onError) {
  post(
    "/api/deviations/",
    wellsDeviations,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function removeWellDeviation(
  params,
  onSuccess,
  onError
) {
  remove(
    "/api/deviations/",
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}
