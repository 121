import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  project: null,

  // for global project dca
  declinePhase: "oil",
  product: "oil_prd_rate",

  wellIds: [], // project used wells' ids

  selectedWells: [],
  selectedWellIds: [],

  projectClusterDataItems: [], // dca params
  projectGroupDataItems: [], // dca params

  clusterWells: null, // id=>[wellids]
  groupWells: null, // id=>[wellids]

  groupNormalizeBy: "wellbore_length",
  clusterNormalizeBy: "wellbore_length",

  // used for parameter panel and dca chart.
  groupDeclineModel: "HYP",
  clusterDeclineModel: "HYP",
  activeGroupId: null,
  activeClusterId: null,

  clusteringType: 0, // 0-Cluster, 1-Group
  mlInputs: null,
  dataAvailability: null,

  applyGroupParams: false,
  applyUserParams: false,

  allModelsParams: {
    oil: [
      {
        model_name: "",
        params: {
          qi: 0,
          di: 0,
          b: 0,
        },
      },
    ],
    gas: [
      {
        model_name: "",
        params: {
          qi: 0,
          di: 0,
          b: 0,
        },
      },
    ],
    liquid: [
      {
        model_name: "",
        params: {
          qi: 0,
          di: 0,
          b: 0,
        },
      },
    ],
  },
};

const typeCurveProjectSlice = createSlice({
  name: "type_curve_project",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setDeclinePhase: (state, action) => {
      state.declinePhase = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setWellIds: (state, action) => {
      state.wellIds = action.payload;
    },
    setSelectedWells: (state, action) => {
      state.selectedWells = action.payload;
    },
    setSelectedWellIds: (state, action) => {
      state.selectedWellIds = action.payload;
    },
    setProjectClusterDataItems: (state, action) => {
      state.projectClusterDataItems = action.payload;
    },
    setProjectClusterDataItemsClusterId: (state, action) => {
      if (!Array.isArray(state.projectClusterDataItems)) {
        console.warn("Project cluster has no data!");
        return;
      }
      let { index, id } = action.payload;
      if (state.projectClusterDataItems[index]["project_cluster"]) {
        state.projectClusterDataItems[index]["project_cluster"]["id"] = id;
      }
    },
    setProjectClusterDataRecommParamsWithIndex: (state, action) => {
      if (!Array.isArray(state.projectClusterDataItems)) {
        console.warn("Project cluster has no data!");
        return;
      }
      let { index, recommParams } = action.payload;
      let {
        normalized_by,
        decline_phase,
        dca_model_type,
        recomm_ip_ratio,
        recomm_initial_decline_rate,
        recomm_coefficient_b,
      } = recommParams;

      const projectCluster =
        state.projectClusterDataItems[index]["project_cluster"];
      const recordIndex = projectCluster.records.findIndex(
        (item) => item.decline_phase === decline_phase
      );
      let thisRecord = projectCluster.records[recordIndex];
      state.projectClusterDataItems[index]["project_cluster"].records[
        recordIndex
      ] = {
        ...thisRecord,
        normalized_by,
        dca_model_type,
        recomm_ip_ratio,
        recomm_initial_decline_rate,
        recomm_coefficient_b,
      };
    },
    setProjectClusterDataUserParamsWithIndex: (state, action) => {
      if (!Array.isArray(state.projectClusterDataItems)) {
        console.warn("Project cluster has no data!");
        return;
      }
      let { index, userParams } = action.payload;
      let {
        normalized_by,
        decline_phase,
        user_coefficient_b,
        user_initial_decline_rate,
        user_ip_ratio,
        user_decline_model,
      } = userParams;

      const projectCluster =
        state.projectClusterDataItems[index]["project_cluster"];
      const recordIndex = projectCluster.records.findIndex(
        (item) => item.decline_phase === decline_phase
      );

      let isNoData =
        !user_coefficient_b && !user_initial_decline_rate && !user_ip_ratio;

      let thisItem = projectCluster.records[recordIndex];
      projectCluster.records[recordIndex] = {
        ...projectCluster.records[recordIndex],
        ...(isNoData
          ? {}
          : {
              normalized_by: thisItem.normalized_by,
              user_decline_model: user_decline_model,
              user_ip_ratio:
                thisItem.user_ip_ratio ||
                parseFloat(thisItem.recomm_ip_ratio).toFixed(2),
              user_initial_decline_rate:
                thisItem.user_initial_decline_rate ||
                parseFloat(thisItem.recomm_initial_decline_rate).toFixed(2),
              user_coefficient_b:
                thisItem.user_coefficient_b ||
                parseFloat(thisItem.recomm_coefficient_b).toFixed(2),
            }),
        normalized_by,
        user_decline_model,
        user_ip_ratio,
        user_initial_decline_rate,
        user_coefficient_b,
      };
    },
    setProjectGroupDataItems: (state, action) => {
      state.projectGroupDataItems = action.payload;
    },
    setProjectGroupDataItemsGroupId: (state, action) => {
      if (!Array.isArray(state.projectGroupDataItems)) {
        console.warn("Project Group has no data!");
        return;
      }
      let { index, id } = action.payload;
      if (state.projectGroupDataItems[index]["project_group"])
        state.projectGroupDataItems[index]["project_group"]["id"] = id;
    },
    setProjectGroupDataRecommParamsWithIndex: (state, action) => {
      if (!Array.isArray(state.projectGroupDataItems)) {
        console.warn("Project group has no data!");
        return;
      }
      let { index, recommParams } = action.payload;
      let {
        normalized_by,
        decline_phase,
        dca_model_type,
        recomm_ip_ratio,
        recomm_initial_decline_rate,
        recomm_coefficient_b,
      } = recommParams;

      const projectGroup = state.projectGroupDataItems[index]["project_group"];
      const recordIndex = projectGroup.records.findIndex(
        (item) => item.decline_phase === decline_phase
      );
      let thisRecord = projectGroup.records[recordIndex];
      state.projectGroupDataItems[index]["project_group"].records[recordIndex] =
        {
          ...thisRecord,
          normalized_by,
          dca_model_type,
          recomm_ip_ratio,
          recomm_initial_decline_rate,
          recomm_coefficient_b,
        };
    },
    setProjectGroupDataUserParamsWithIndex: (state, action) => {
      if (!Array.isArray(state.projectGroupDataItems)) {
        console.warn("Project group has no data!");
        return;
      }
      let { index, userParams } = action.payload;
      let {
        normalized_by,
        decline_phase,
        user_coefficient_b,
        user_initial_decline_rate,
        user_ip_ratio,
        user_decline_model,
      } = userParams;

      const projectGroup = state.projectGroupDataItems[index]["project_group"];
      const recordIndex = projectGroup.records.findIndex(
        (item) => item.decline_phase === decline_phase
      );
      let thisRecord = projectGroup.records[recordIndex];

      let isNoData =
        !user_coefficient_b && !user_initial_decline_rate && !user_ip_ratio;

      if (thisRecord)
        state.projectGroupDataItems[index]["project_group"].records[
          recordIndex
        ] = {
          ...thisRecord,
          ...(isNoData
            ? {}
            : {
                user_ip_ratio:
                  thisRecord.user_ip_ratio ||
                  thisRecord.recomm_ip_ratio?.toFixed(2),
                user_initial_decline_rate:
                  thisRecord.user_initial_decline_rate ||
                  thisRecord.recomm_initial_decline_rate?.toFixed(2),
                user_coefficient_b:
                  thisRecord.user_coefficient_b ||
                  thisRecord.recomm_coefficient_b?.toFixed(2),
              }),
          normalized_by,
          decline_phase,
          user_coefficient_b,
          user_initial_decline_rate,
          user_ip_ratio,
          user_decline_model,
        };
    },
    setProjectGroupDataItemsWithIndexProjectGroupWells: (state, action) => {
      if (!Array.isArray(state.projectGroupDataItems)) {
        console.warn("Project group has no data!");
        return;
      }
      let { index, group_wells } = action.payload;
      let thisItem = state.projectGroupDataItems[index]["group_wells"];
      if (thisItem) {
        state.projectGroupDataItems[index]["group_wells"] = [...group_wells];
      }
    },
    deleteProjectGroupDataItemsWithIndex: (state, action) => {
      if (!Array.isArray(state.projectGroupDataItems)) {
        console.warn("Project group has no data!");
        return;
      }
      let { index } = action.payload;
      state.projectGroupDataItems.splice(index, 1);
    },
    setGroupNormalizeBy: (state, action) => {
      state.groupNormalizeBy = action.payload;
    },
    setClusterNormalizeBy: (state, action) => {
      state.clusterNormalizeBy = action.payload;
    },
    setGroupDeclineModel: (state, action) => {
      state.groupDeclineModel = action.payload;
    },
    setClusterDeclineModel: (state, action) => {
      state.clusterDeclineModel = action.payload;
    },
    setActiveGroupId: (state, action) => {
      state.activeGroupId = action.payload;
    },
    setActiveClusterId: (state, action) => {
      state.activeClusterId = action.payload;
    },
    setClusteringType: (state, action) => {
      state.clusteringType = action.payload;
    },
    setMlInputs: (state, action) => {
      state.mlInputs = action.payload;
    },
    setDataAvailability: (state, action) => {
      state.dataAvailability = action.payload;
    },
    setClusterWells: (state, action) => {
      state.clusterWells = action.payload;
    },
    setGroupWells: (state, action) => {
      state.groupWells = action.payload;
    },
    setApplyUserParams: (state, action) => {
      state.applyUserParams = action.payload;
    },
    setDcaAllModelsParams: (state, action) => {
      state.allModelsParams[state.declinePhase] = action.payload;
    },
  },
});

export const {
  reset,
  setProject,
  setDeclinePhase,
  setProduct,
  setWellIds,
  setSelectedWells,
  setSelectedWellIds,
  setProjectClusterDataItems,
  setProjectClusterDataItemsClusterId,
  setProjectClusterDataRecommParamsWithIndex,
  setProjectClusterDataUserParamsWithIndex,
  setProjectGroupDataItems,
  setProjectGroupDataItemsGroupId,
  setProjectGroupDataRecommParamsWithIndex,
  setProjectGroupDataUserParamsWithIndex,
  setProjectGroupDataItemsWithIndexProjectGroupWells,
  deleteProjectGroupDataItemsWithIndex,
  setClusterNormalizeBy,
  setGroupNormalizeBy,
  setGroupDeclineModel,
  setClusterDeclineModel,
  setActiveGroupId,
  setActiveClusterId,
  setClusteringType,
  setMlInputs,
  setDataAvailability,
  setClusterWells,
  setGroupWells,
  setApplyUserParams,
  setDcaAllModelsParams,
} = typeCurveProjectSlice.actions;

export const selectProject = (state) => state.type_curve_project.project;
export const selectDeclinePhase = (state) =>
  state.type_curve_project.declinePhase;
export const selectProduct = (state) => state.type_curve_project.product;
export const selectWellIds = (state) => state.type_curve_project.wellIds;
export const selectSelectedWells = (state) =>
  state.type_curve_project.selectedWells;
export const selectSelectedWellIds = (state) =>
  state.type_curve_project.selectedWellIds;

export const selectProjectClusterDataItems = (state) =>
  state.type_curve_project.projectClusterDataItems;
export const selectProjectGroupDataItems = (state) =>
  state.type_curve_project.projectGroupDataItems;

export const selectProjectClusterDataItemByIndex = (state, cluserIndex) => {
  if (
    !Array.isArray(state.type_curve_project.projectClusterDataItems) ||
    state.type_curve_project.projectClusterDataItems.length === 0
  ) {
    console.warn("Project cluster has no data!");
    return null;
  }
  if (cluserIndex) {
    return null;
  }
  return state.type_curve_project.projectClusterDataItems[cluserIndex];
};

export const selectProjectGroupDataItemByIndex = (state, groupIndex) => {
  if (
    !Array.isArray(state.type_curve_project.projectGroupDataItems) ||
    state.type_curve_project.projectGroupDataItems.length === 0
  ) {
    console.warn("Project groups has no data!");
    return null;
  }
  if (groupIndex) {
    return null;
  }
  return state.type_curve_project.projectGroupDataItems[groupIndex];
};

export const selectClusterNormalizeBy = (state) =>
  state.type_curve_project.clusterNormalizeBy;
export const selectGroupDeclineModel = (state) =>
  state.type_curve_project.groupDeclineModel;
export const selectClusterDeclineModel = (state) =>
  state.type_curve_project.clusterDeclineModel;
export const selectGroupNormalizeBy = (state) =>
  state.type_curve_project.groupNormalizeBy;
export const selectActiveGroupId = (state) =>
  state.type_curve_project.activeGroupId;
export const selectActiveClusterId = (state) =>
  state.type_curve_project.activeClusterId;
export const selectClusteringType = (state) =>
  state.type_curve_project.clusteringType;
export const selectMlInputs = (state) => state.type_curve_project.mlInputs;
export const selectDataAvailability = (state) =>
  state.type_curve_project.dataAvailability;
export const selectClusterWells = (state) =>
  state.type_curve_project.clusterWells;
export const selectGroupWells = (state) => state.type_curve_project.groupWells;

export const selectApplyUserParams = (state) =>
  state.type_curve_project.applyUserParams;

export const selectDcaAllModelsParams = (state) =>
  state.type_curve_project.allModelsParams[state.type_curve_project.declinePhase];

export default typeCurveProjectSlice.reducer;
