import { Button, Checkbox, Form, InputNumber, Select } from 'antd'
import React, { useMemo } from 'react'
import { useDCAReport } from '../DCAReportProvider'
import { sensitivityOptions } from '../consts'
import FormSection from 'components/form-section'

const GeneralSetting = () => {
  const { sourceProject } = useDCAReport()

  const {
    price_projections
  } = sourceProject || {}

  const formInstance = Form.useFormInstance()
  const applyNgl = Form.useWatch(['general', 'apply_ngl'])

  const { oilOptions, gasOptions, nglOptions } = useMemo(() => {
    const _oilOptions = Object.entries(price_projections?.oil ?? {}).map(([value, label]) => ({
      value,
      label
    }))

    const _gasOptions = Object.entries(price_projections?.gas ?? {}).map(([value, label]) => ({
      value,
      label
    }))

    const _nglOptions = Object.entries(price_projections?.ngl ?? {}).map(([value, label]) => ({
      value,
      label
    }))

    return {
      oilOptions: _oilOptions,
      gasOptions: _gasOptions,
      nglOptions: _nglOptions
    }
  }, [price_projections?.gas, price_projections?.ngl, price_projections?.oil])

  if (!sourceProject) return null

  return (
    <div className="vstack gap-2">
      <FormSection title="Money Market">
        <div className="row">
          <div className="col-6">
            <Form.Item
              labelCol={24}
              wrapperCol={24}
              name={['general', 'discount_rate']}
              label="Primary Discount Rate (%)"
              rules={[{
                min: 0,
                max: 100,
                type: 'number'
              }]}
            >
              <InputNumber className="w-100" step={0.01} />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item
              labelCol={24}
              wrapperCol={24}
              name={['general', 'sensitivity_rates']}
              label="Rate For Sensitivity"
              initialValue={sensitivityOptions.map(option => option.value)}
            >
              <Select mode="multiple" options={sensitivityOptions} dropdownRender={(menu) => (
                <>
                  <Button
                    type="text"
                    className="mt-2 w-100"
                    style={{ textAlign: 'left' }}
                    onClick={() => formInstance.setFieldValue(['general', 'sensitivity_rates'], sensitivityOptions.map(option => option.value))}
                  >
                    All
                  </Button>
                  {menu}
                </>
              )} />
            </Form.Item>
          </div>
        </div>
      </FormSection >
      <FormSection title="Price Projection">
        <div className="row">
          <div className="col-4">
            <Form.Item name={['general', 'price_projections', 'oil']} label="Oil">
              <Select options={oilOptions} />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item name={['general', 'price_projections', 'gas']} label="Gas">
              <Select options={gasOptions} />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item name={['general', 'price_projections', 'ngl']} label="NGL">
              <Select options={nglOptions} />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <Form.Item className="col-6" name={['general', 'apply_diff']} valuePropName="checked">
            <Checkbox>Apply price differentials</Checkbox>
          </Form.Item>
          <div className="col-6">
            <div className="hstack gap-2">
              <Form.Item name={['general', 'apply_ngl']} valuePropName="checked">
                <Checkbox>Apply NGL (glns per mscf)</Checkbox>
              </Form.Item>
              {applyNgl ? (
                <Form.Item name={['general', 'ngl_ratio']} rules={[{
                  type: 'number',
                  min: 0,
                  max: 100,
                }]}>
                  <InputNumber step={1} />
                </Form.Item>
              ) : null}
            </div>
          </div>
        </div>
      </FormSection>
    </div >
  )
}

export default GeneralSetting