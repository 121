import html2canvas from "html2canvas";
import { getRandomColor } from "utils/color";

export const status = {
  HTTP_200_OK: 200,
  HTTP_400_BAD_REQUEST: 400,
  HTTP_401_UNAUTHORIZED: 401,
  HTTP_405_METHOD_NOT_ALLOWED: 405,
};

export const petroleum_colors = {
  gas_color: "red",
  gas_color_darker: "darkred",
  water_color: "blue",
  water_color_darker: "darkblue",
  oil_color: "darkgreen",
  oil_color_darker: "darkgreen",
  avg_color: "violet",
  fluid_color: "violet",
  power_color: "violet",
  unknown_color: "yellow",
  cum_gas_color: "red",
  cum_water_color: "blue",
  cum_oil_color: "darkgreen",
  monthly_gas_color: "red",
  monthly_water_color: "blue",
  monthly_oil_color: "darkgreen",
  avg_wct_color: "yellow",
  avg_gor_color: "green",
  monthly_wct_color: "purple",
  monthly_gor_color: "orange",
};

export const PRODUCT_OPTIONS = {
  oil_prd_rate: "Oil Rate",
  gas_prd_rate: "Gas Rate",
  water_prd_rate: "Water Rate",
  avg_htp: "Avg Htp",
  fluid_level: "Fluid Level",
  power_consumption: "Power Consumption",
};

export const ProductOptions = [
  { value: "oil_prd_rate", name: "Oil Rate", unit: "bbl" },
  { value: "cumulative_oil", name: "Cumulative Oil", unit: "bbl" },
  { value: "gas_prd_rate", name: "Gas Rate", unit: "mscf" },
  { value: "cumulative_gas", name: "Cumulative Gas", unit: "mscf" },
  { value: "water_prd_rate", name: "Water Rate", unit: "bbl" },
  { value: "cumulative_water", name: "Cumulative Water", unit: "bbl" },
  { value: "liquid_rate", name: "Liquid Rate", unit: "bbl" },
  { value: "cumulative_liquid", name: "Cumulative Liquid", unit: "bbl" },
  { value: "oil_gas_ratio", name: "Oil Gas Ratio", unit: "" },
  { value: "water_cut", name: "Water Cut", unit: "" },
  { value: "power_consumption", name: "Power Consumption", unit: "" },
  {
    value: "cumulative_power_consumption",
    name: "Cumulative Power Consumption",
  },
];
export const ViewersProductOptions = [
  { value: "cumulative_oil", name: "Cum Oil", unit: "bbl" },
  { value: "cumulative_gas", name: "Cum Gas", unit: "Mscf" },
  { value: "cumulative_water", name: "Cum Water", unit: "bbl" },
  { value: "oil_gas_ratio", name: "Avg Gas/Oil Ratio", unit: "" },
  { value: "water_cut", name: "Avg Water Cut", unit: "" },
  { value: "power_consumption", name: "Power Consumption", unit: "kWh" },

  { value: "oil_prd_rate", name: "Oil Rate", unit: "bbl/m" },
  { value: "gas_prd_rate", name: "Gas Rate", unit: "Mscf/m" },
  { value: "water_prd_rate", name: "Water Rate", unit: "bbl/m" },
  { value: "liquid_rate", name: "Liquid Rate", unit: "bbl/m" },
  { value: "cumulative_liquid", name: "Cumulative Liquid", unit: "bbl" },
  { value: "cumulative_power_consumption", name: "Cumulative Power Consumption", unit: "kWh" },
];

export const ProductOptionsSimple = [
  { value: "oil_prd_rate", name: "Oil Rate", unit: "bbl" },
  { value: "gas_prd_rate", name: "Gas Rate", unit: "mscf" },
  { value: "water_prd_rate", name: "Water Rate", unit: "bbl" },
];

export const CumulativeProductsMap = {
  cumulative_oil: "oil_prd_rate",
  cumulative_gas: "gas_prd_rate",
  cumulative_water: "water_prd_rate",
  cumulative_power_consumption: "power_consumption",
};

export const DatePeriodOptions = [
  {
    name: "daily",
    days: 1,
  },
  {
    name: "monthly",
    days: 30,
  },
];

export const DataPeriodOptions = [
  { value: "total", name: "Total", desc: "" },
  { value: "last_week", name: "Last Week", desc: "Last Week" },
  { value: "last_month", name: "Last Month", desc: "Last Month" },
  { value: "last_year", name: "Last Year", desc: "Last Year" },
];

export const DeclineModelOptions = [
  { value: "", name: "select..." },
  { value: "HYP", name: "HyperBolic" },
  { value: "EXP", name: "Exponential" },
  { value: "AI", name: "AI" },
];

export const DECLINE_PHASE_OPTIONS = [
  { name: "OIL", value: "oil", unit: 'bbl/day/ft' },
  { name: "GAS", value: "gas", unit: 'Mscf/day/ft' },
  { name: "LIQUID", value: "liquid", unit: 'bbl/day/ft' },
];

export const PHASE_MODEL_MAP = {
  oil: "oil_prd_rate",
  gas: "gas_prd_rate",
  liquid: "oil_prd_rate+water_prd_rate",
};

export const ClusteringTypes = {
  AIClustering: 0,
  UserGroupping: 1,
};

export const NormalizeOptions = [
  { name: "Sand Usage", value: "total_sand_usage" },
  { name: "Wellbore Length", value: "wellbore_length" },
];

export const DataOptions = [
  { value: "default", name: "Default" },
  { value: "user", name: "User Define" },
];

export const ModelOptions = [
  { value: "nearest", name: "Nearest" },
  { value: "linear", name: "Linear" },
  { value: "cubic", name: "Cubic" },
];

const COLORS_MAP = (() => {
  const colorsMap = new Map();
  const propNames = Object.getOwnPropertyNames(PRODUCT_OPTIONS);
  for (const propKey of propNames) {
    const prop = propKey.split("_")[0];
    const propColorMap = new Map();
    propColorMap.set("1", petroleum_colors[prop + "_color"]);
    propColorMap.set("30", petroleum_colors[prop + "_color_darker"]);
    colorsMap.set(propKey, propColorMap);
  }
  return colorsMap;
})();

export function calculatePalleteValue(model, period) {
  const colorMap = COLORS_MAP.get(model);
  if (colorMap) {
    return colorMap.get(period);
  }

  return petroleum_colors.unknown_color;
}

export function getProductUnit(product) {
  let productItem = ProductOptions.find((item) => item.value === product);
  return productItem.unit;
}

export const TOPS_COLOR = {
  MD: 'red',
  TVD: 'green',
}

export const CURVE_COLORS = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', ...Array(50).fill(null).map(() => getRandomColor())];
export const COLORS = CURVE_COLORS;
export const FONT_SIZE = [8, 10, 12, 14, 16, 20];
export const getColorIndexFromTopNamesList = (topNamesList, topName) => {
  if (!topNamesList || !topName) return;

  let index = topNamesList.indexOf(topName);
  // if (index === -1) throw new Error("found top name not in the top names list: topNamesList, topName" + topNamesList + ',' + topName);
  return index;
};

export const exportAsImage = async (element, imageFileName) => {
  const canvas = await html2canvas(element);
  const image = canvas.toDataURL("image/png", 1.0);
  downloadImage(image, imageFileName);
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;
  fakeLink.href = blob;
  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);
  fakeLink.remove();
};

/**
 * 
 * @param {*} value normal date, like: "2020-9-8"
 * 
 * if invalide date or only numbers, it will return the input
 * @returns "09/08/2020"
 *  
 */
export const convertToImperialDate = (value) => {
  if (!isNaN(value)) return value;

  var date = new Date(value);
  if (date === "Invalid Date") return value;

  var texts = [
    ('0' + (date.getMonth() + 1)).slice(-2),
    ('0' + date.getDate()).slice(-2),
    date.getFullYear()
  ];

  return texts.join('/');
};

export const calcOpsUrl = '/static/icons/calculator/', calcOpsType = '.png';
export const calcOps = [
  // Line: 1
  [{
    label: 'c', // unique id
    print: 'CLEAR',  // showing of this key on page,
    autoComplete: [], //may contain several label of keys need to show on page, like: round => round, (, )
    icon: 'clear',  // icon name, should combine with url and type/postfix
    formula: '',  // transfer to backend to generate log data, scientific format
  },
  {
    label: 'round',
    print: 'Round',
    autoComplete: ['(', ')'],
    icon: 'round',
    formula: 'round',
  },
  {
    label: 'sqrt',
    print: 'Sqrt',
    autoComplete: ['(', ')'],
    icon: 'sqrt',
    formula: 'sqrt',
  },
  {
    label: '7',
    print: '7',
    icon: '7',
    formula: '',
  },
  {
    label: '8',
    print: '8',
    icon: '8',
    formula: '',
  },
  {
    label: '9',
    print: '9',
    icon: '9',
    formula: '',
  },
  {
    label: 'plus',
    print: '+',
    icon: 'add',
    formula: '',
  },
  {
    label: 'and',
    print: 'And',
    icon: 'and',
    formula: 'and',
  },
  {
    label: 'if',
    print: 'If',
    autoComplete: ['(', ',,)'],
    icon: 'if',
    formula: 'if',
  },
  {
    label: 'or',
    print: 'Or',
    icon: 'or',
    formula: 'or',
  }],

  // Line: 2
  [{
    label: 'sin',
    print: 'Sin',
    autoComplete: ['(', ')'],
    icon: 'sin',
    formula: 'sin',
  },
  {
    label: 'abs',
    print: 'Abs',
    autoComplete: ['(', ')'],
    icon: 'abs',
    formula: 'abs',
  },
  {
    label: 'int',
    print: 'Int',
    autoComplete: ['(', ')'],
    icon: 'Int',
    formula: 'int',
  },
  {
    label: '4',
    print: '4',
    icon: '4',
    formula: '',
  },
  {
    label: '5',
    print: '5',
    icon: '5',
    formula: '',
  },
  {
    label: '6',
    print: '6',
    icon: '6',
    formula: '',
  },
  {
    label: 'minus',
    print: '-',
    icon: 'minus',
    formula: '',
  },
  {
    label: 'less_eq',
    print: '<=',
    icon: 'lessorequ',
    formula: '',
  },
  {
    label: 'eq',
    print: '=',
    icon: 'eq',
    formula: '',
  },
  {
    label: 'lg_eq',
    print: '>=',
    icon: 'biggerandeq',
    formula: '',
  },
  ],

  // Line: 3
  [{
    label: 'cos',
    print: 'Cos',
    autoComplete: ['(', ')'],
    icon: 'cos',
    formula: 'cos',
  },
  {
    label: 'exp',
    print: 'Exp',
    autoComplete: ['(', ')'],
    icon: 'exp',
    formula: 'exp',
  },
  {
    label: 'ln',
    print: 'Ln',
    autoComplete: ['(', ')'],
    icon: 'ln',
    formula: 'ln',
  },
  {
    label: '1',
    print: '1',
    icon: '1',
    formula: '',
  },
  {
    label: '2',
    print: '2',
    icon: '2',
    formula: '',
  },
  {
    label: '3',
    print: '3',
    icon: '3',
    formula: '',
  },
  {
    label: 'star',
    print: '*',
    icon: 'times',
    formula: '*',
  },
  {
    label: 'less',
    print: '<',
    icon: 'smaller',
    formula: '',
  },
  {
    label: 'not_eq',
    print: '<>',
    icon: 'noteq',
    formula: '',
  },
  {
    label: 'lg',
    print: '>',
    icon: 'larger',
    formula: '',
  }
  ],

  // Line: 4
  [{
    label: 'tan',
    print: 'Tan',
    autoComplete: ['(', ')'],
    icon: 'tan',
    formula: 'tan',
  },
  {
    label: 'pow',
    print: 'Pow',
    autoComplete: ['(', ',', ')'],
    icon: 'pow',
    formula: 'pow',
  },
  {
    label: 'log',
    print: 'Log',
    autoComplete: ['(', ',', ')'],
    icon: 'log',
    formula: 'log',
  },
  {
    label: '0',
    print: '0',
    icon: '0',
    formula: '',
  },
  {
    label: 'u',
    print: 'U',
    icon: 'u',
    formula: 'u',
  },
  {
    label: 'dot',
    print: '.',
    icon: 'dot',
    formula: '',
  },
  {
    label: 'divide',
    print: '/',
    icon: 'divid',
    formula: '',
  },
  {
    label: 'comma',
    print: ',',
    icon: 'comma',
  },
  {
    label: '(',
    print: '(',
    autoComplete: [')'],
    icon: 'bracket left',
    formula: '',
  },
  {
    label: ')',
    print: ')',
    icon: 'bracketright',
    formula: '',
  },
  ],
]
