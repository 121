import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  // for gobal project dca
  declinePhase: "oil",
  // product: "oil_prd_rate",

  wellGroups: null, // custom well id=>[well ids]
  wellClusters: null, // custom well id=>[well ids]
  performanceParams: null, // selected performance parameters

  projectClusterDataItems: [], // dca params
  projectGroupDataItems: [], // dca params

  groupNormalizeBy: "wellbore_length",
  clusterNormalizeBy: "wellbore_length",

  mlInputs: null,
  dataAvailability: null,

  groupTypeCurveData: null,
  clusterTypeCurveData: null,
};

const plannerDcaSlice = createSlice({
  name: "well_planner_dca",
  initialState,
  reducers: {
    resetDca: () => {
      return initialState;
    },
    setWellGroups: (state, action) => {
      state.wellGroups = action.payload;
    },
    setWellClusters: (state, action) => {
      state.wellClusters = action.payload;
    },
    setPerformanceParams: (state, action) => {
      state.performanceParams = action.payload;
    },
    setDeclinePhase: (state, action) => {
      state.declinePhase = action.payload;
    },
    setProjectClusterDataItems: (state, action) => {
      state.projectClusterDataItems = action.payload;
    },
    setProjectGroupDataItems: (state, action) => {
      state.projectGroupDataItems = action.payload;
    },
    setGroupNormalizeBy: (state, action) => {
      state.groupNormalizeBy = action.payload;
    },
    setClusterNormalizeBy: (state, action) => {
      state.clusterNormalizeBy = action.payload;
    },
    setMlInputs: (state, action) => {
      state.mlInputs = action.payload;
    },
    setDataAvailability: (state, action) => {
      state.dataAvailability = action.payload;
    },
  },
});

export const {
  resetDca,
  setDeclinePhase,

  setWellGroups,
  setWellClusters,
  setPerformanceParams,

  setProjectClusterDataItems,
  setProjectGroupDataItems,

  setClusterNormalizeBy,
  setGroupNormalizeBy,

  setMlInputs,
  setDataAvailability,
} = plannerDcaSlice.actions;

export const selectDeclinePhase = (state) =>
  state.well_planner_dca.declinePhase;
export const selectProduct = (state) => state.well_planner_dca.product;

export const selectWellGroups = (state) => state.well_planner_dca.wellGroups;
export const selectWellClusters = (state) =>
  state.well_planner_dca.wellClusters;
export const selectPerformanceParams = (state) =>
  state.well_planner_dca.performanceParams;

export const selectProjectClusterDataItems = (state) =>
  state.well_planner_dca.projectClusterDataItems;
export const selectProjectGroupDataItems = (state) =>
  state.well_planner_dca.projectGroupDataItems;

export const selectClusterNormalizeBy = (state) =>
  state.well_planner_dca.clusterNormalizeBy;
export const selectGroupNormalizeBy = (state) =>
  state.well_planner_dca.groupNormalizeBy;

export const selectMlInputs = (state) => state.well_planner_dca.mlInputs;

export const selectDataAvailability = (state) =>
  state.well_planner_dca.dataAvailability;

export default plannerDcaSlice.reducer;
