import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  selectProjectClusterDataItems,
  setProjectClusterDataItems,
  selectSelectedWellIds,
  setClusterWells,
  // setActiveClusterId,
  // setProjectClusterDataItemsClusterId,
} from "features/decline_analysis/typeCurveProjectSlice";

import {
  selectActiveProject,
  setShowNewProjectPopup,
} from "features/project/projectsSlice";

import { saveProjectClusters } from "service/typeCurve";
import { doCluster } from "service/cluster";

import { getWellAvailableParams } from "service/wellData";

import { selectActiveWells } from "features/asset/assetSelectorSlice";

import AddProjectForm from "components/project/AddProjectForm";
import { message } from "antd";

function AIClusteringPanel(props) {
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const currentProject = useSelector(selectActiveProject);

  const projectClusterDataItems = useSelector(selectProjectClusterDataItems);

  const selectedWellIds = useSelector(selectSelectedWellIds);

  const activeWells = useSelector(selectActiveWells);

  const [selectedClusterIndex, setSelectedClusterIndex] = useState(null);
  const [selectedSaveWellId, setSelectedSavedWellId] = useState(null);

  const dispatch = useDispatch();

  const [clusterNums, setClusterNums] = useState(0);
  const [allParamsData, setAllParamsData] = useState(null);

  const queryMLInputParams = useCallback(
    (wellIds) => {
      showLoadingIcon && showLoadingIcon();
      getWellAvailableParams(
        {
          uwis: wellIds,
          input_category: "type_curve",
        },
        (result) => {
          // setAvailableParams(result.available_params);
          let tempAvailableParams = [];
          for (let wellId of Object.keys(result.available_params)) {
            tempAvailableParams = [
              ...tempAvailableParams,
              ...result.available_params[wellId],
            ];
          }
          let availableParamsSet = new Set(tempAvailableParams);
          let paramsData = [];
          for (let param of result.all_params) {
            paramsData.push({
              name: param,
              available: availableParamsSet.has(param),
              selected: false,
            });
          }
          if (selectedWellIds && selectedWellIds.length > 0) {
            setAllParamsData(paramsData);
          }
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [hideLoadingIcon, selectedWellIds, showLoadingIcon]
  );

  useEffect(() => {
    if (!selectedWellIds || selectedWellIds.length === 0) {
      setAllParamsData(null);
      return;
    }
    queryMLInputParams(selectedWellIds);
  }, [selectedWellIds, queryMLInputParams]);

  function handleClusterNumsChanged(e) {
    const value = e.target.value;
    setClusterNums(value);
  }

  function handleParamSelectedChanged(index) {
    let tempParamsData = [...allParamsData];
    let oldItem = { ...allParamsData[index] };
    oldItem.selected = !oldItem.selected;
    tempParamsData[index] = oldItem;
    setAllParamsData(tempParamsData);
  }

  const innerSaveClusterParams = (projectId) => {
    let selectedParams = allParamsData.filter((item) => item.selected);
    let inputParams = selectedParams.map((item) => {
      return item.name;
    });

    showLoadingIcon && showLoadingIcon();
    // clear old clusters
    doCluster(
      {
        input_category: "type_curve",
        input_params: inputParams,
        cluster_nums: clusterNums,
        well_uwis: selectedWellIds,
      },
      (result) => {
        hideLoadingIcon && hideLoadingIcon();
        if (result.clusters) {
          let projectClusterItems = [];

          for (let clusterKey of Object.keys(result.clusters)) {
            let clusterId = parseInt(clusterKey);
            let wellIds = result.clusters[clusterKey];
            if (wellIds.length === 0) {
              continue;
            }

            let projectCluster = {
              project_id: projectId,
              cluster_num: clusterId,
              records: [],
            };
            for (let phase of ["oil", "gas", "liquid"]) {
              projectCluster.records.push({
                decline_phase: phase,
                user_ip_ratio: 0,
                user_initial_decline_rate: 0,
                user_coefficient_b: 0,
                dca_model_type: null,
                recomm_ip_ratio: 0,
                recomm_initial_decline_rate: 0,
                recomm_coefficient_b: 0,
              });
            }

            let projectClusterItem = {};
            projectClusterItem.project_cluster = projectCluster;
            projectClusterItem.cluster_wells = wellIds;
            while (clusterId >= projectClusterItems.length) {
              projectClusterItems.push({});
            }
            projectClusterItems[clusterId] = projectClusterItem;
          }
          // dispatch(setProjectClusterDataItems(projectClusterItems));
          dispatch(setClusterWells(selectedWellIds));
          // dispatch(setActiveClusterId(0));

          // saveClusters(projectId, inputParams, projectClusterItems);
          saveProjectClusters(
            projectId,
            inputParams,
            projectClusterItems,
            "create",
            (res) => {
              let tempItems = [...res.project_clusters];
              // tempItems.forEach((item, index) => {
              //   dispatch(
              //     setProjectClusterDataItemsClusterId({
              //       index,
              //       id: item.project_cluster.id,
              //     })
              //   );
              // });
              dispatch(setProjectClusterDataItems(tempItems));
              setSelectedClusterIndex(0);
              const projectClusterItem = tempItems[0];
              const wellIds = projectClusterItem.cluster_wells;
              if (wellIds.length > 0) {
                setSelectedSavedWellId(wellIds[0]);
              }
              hideLoadingIcon && hideLoadingIcon();
            },
            (error) => {
              hideLoadingIcon && hideLoadingIcon();
            }
          );
        }
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  };

  function saveClusterParams(e) {
    if (parseInt(clusterNums) < 2) {
      message.warning('Please set "Number Of Clusters" to at least "2" !');
      return;
    }
    if (!selectedWellIds || selectedWellIds.length < parseInt(clusterNums)) {
      message.warning(
        "Please select at least " + parseInt(clusterNums) + " wells!"
      );
      return;
    }
    let selectedParams = allParamsData.filter((item) => item.selected);
    if (!selectedParams || selectedParams.length < 1) {
      message.warning("Please select at least 1 parameter!");
      return;
    }
    if (!currentProject) {
      dispatch(setShowNewProjectPopup(true));
      return;
    }
    if (
      selectedWellIds == null ||
      selectedWellIds.length === 0 ||
      allParamsData == null ||
      allParamsData.length === 0
    ) {
      return;
    }

    innerSaveClusterParams(currentProject.id);
  }

  function generateParamSelector() {
    if (!allParamsData) {
      return null;
    }
    return allParamsData.map((param, index) => {
      let className = "list-item";
      if (!param.available) {
        className += " disabled";
      }
      return (
        <div
          className={`${className} p-2 d-flex flex-row justify-content-between align-items-center`}
          key={index}
        >
          <div>{param.name}</div>
          <input
            type="checkbox"
            onChange={(e) => {
              handleParamSelectedChanged(index);
            }}
            checked={param.selected}
            disabled={!param.available}
          ></input>
        </div>
      );
    });
  }

  const handleClusterSelected = useCallback(
    (index) => {
      setSelectedClusterIndex(index);
      let wellIds = projectClusterDataItems[selectedClusterIndex].cluster_wells;
      if (wellIds && wellIds.length > 0) {
        setSelectedSavedWellId(wellIds[0]);
      }
    },
    [projectClusterDataItems, selectedClusterIndex]
  );

  const handleSaveWellSelected = useCallback(
    (well) => {
      if (well.uwi !== selectedSaveWellId) {
        setSelectedSavedWellId(well.uwi);
      }
    },
    [selectedSaveWellId]
  );

  const clustersSelector = useMemo(() => {
    if (
      projectClusterDataItems == null ||
      projectClusterDataItems.length === 0
    ) {
      return null;
    }

    return projectClusterDataItems.map((projectClusterItem, index) => {
      let selected = selectedClusterIndex === index;
      let itemClass = "list-item text-center mb-2";
      if (selected) {
        itemClass += " selected";
      }
      return (
        <div
          key={index}
          className={itemClass}
          id={index}
          onClick={(e) => {
            handleClusterSelected(index);
          }}
        >
          Cluster{index}
        </div>
      );
    });
  }, [projectClusterDataItems, selectedClusterIndex, handleClusterSelected]);

  const clusterWellsSelector = useMemo(() => {
    if (
      activeWells == null ||
      activeWells.length === 0 ||
      selectedClusterIndex == null ||
      projectClusterDataItems == null ||
      projectClusterDataItems.length === 0
    ) {
      return null;
    }

    const wellIds = projectClusterDataItems[selectedClusterIndex].cluster_wells;
    if (wellIds == null || wellIds.length === 0) {
      return null;
    }
    let idsSet = new Set(wellIds);
    let wells = activeWells.filter((well) => idsSet.has(well.uwi));

    return wells.map((item, index) => {
      let selected = selectedSaveWellId === item.uwi;
      let itemClass = "list-item text-center mb-2";
      if (selected) {
        itemClass += " selected";
      }
      return (
        <div
          className={itemClass}
          key={item.uwi}
          onClick={(e) => {
            handleSaveWellSelected(item);
          }}
        >
          {item.well_name}
        </div>
      );
    });
  }, [
    activeWells,
    projectClusterDataItems,
    selectedClusterIndex,
    selectedSaveWellId,
    handleSaveWellSelected,
  ]);

  return (
    <>
      <AddProjectForm
        input={{
          project_type: "type_curve",
          project_name: `PRJ_${new Date().getTime()}`,
          comment: "comment",
        }}
        callbackFunction={[innerSaveClusterParams, null]}
        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
      />
      <div className="cards-content d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <div className="d-flex align-items-center me-2">
            <div>Number Of Clusters</div>
            <input
              className="ms-2"
              value={clusterNums}
              onChange={handleClusterNumsChanged}
            ></input>
          </div>
        </div>
        <div className="row flex-list-wrapper g-0">
          <div className="col cluster-params-wrapper d-flex flex-column me-2 h-100 pe-1">
            <div className="p-2">Select Parameters For Clustering</div>
            <div className="cluster-params-list overflow-auto hide-scrollbar flex-list-wrapper p-2">
              <div className="h-100">{generateParamSelector()}</div>
            </div>
            <div className="d-flex justify-content-center align-items-center p-2">
              <button className="btn-custom w-45" onClick={saveClusterParams}>
                Clustering
              </button>
            </div>
          </div>
          <div className="col cluster-params-wrapper d-flex flex-column ms-2 h-100 ps-1">
            <div className="p-2">Clustering Results</div>
            <div className="row flex-list-wrapper g-0">
              <div className="col list-container d-flex flex-column h-100 ms-2 me-1 mb-2 px-0 pe-1">
                <div className="list-header text-center p-2">Clusters</div>
                <div className="d-flex flex-column flex-list-wrapper overflow-auto hide-scrollbar m-2">
                  {clustersSelector}
                </div>
              </div>
              <div className="col list-container d-flex flex-column h-100 ms-1 me-2 mb-2 px-0 ps-1">
                <div className="list-header text-center p-2">Wells</div>
                <div className="d-flex flex-column flex-list-wrapper overflow-auto hide-scrollbar m-2">
                  {clusterWellsSelector}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AIClusteringPanel;
