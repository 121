import { ConfigProvider, Form, Tabs } from 'antd'
import React, { useMemo } from 'react'
import enUS from "antd/locale/en_US";
import classNames from 'classnames';

import classes from './PVTSetting.module.scss'
import DensityTable from './DensityTable';
import RockTable from './RockTable';
import WaterTable from './WaterTable';
import GasTable from './GasTable';
import OilTable from './OilTable';

const PVTSetting = () => {
  const tabs = useMemo(
    /**
     * 
     * @returns {import('antd').TabsProps['items']}
     */
    () => [
      {
        key: 'Density',
        label: 'Density',
        children: <DensityTable />
      },
      {
        key: 'Rock',
        label: 'Rock',
        children: <RockTable />
      },
      {
        key: 'Water',
        label: 'Water',
        children: <WaterTable />
      },
      {
        key: 'Gas',
        label: 'Gas',
        children: (
          <Form.List name={["PVT", "gas"]}>
            {(fields, operation) => (
              <GasTable fields={fields} operation={operation} />
            )}
          </Form.List>
        )
      },
      {
        key: 'Oil',
        label: 'Oil',
        children: (
          <Form.List name={["PVT", "oil"]}>
            {(fields, operation) => (
              <OilTable fields={fields} operation={operation} />
            )}
          </Form.List>
        )
      }
    ]
    , [])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      <Tabs type="card" items={tabs} className={classNames(classes.tabs, classes.parentTabs)} destroyInactiveTabPane={false} />
    </ConfigProvider>
  )
}

export default PVTSetting