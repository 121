import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, ConfigProvider, Popconfirm } from 'antd'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo } from 'react'
import { addExpenseForWells, deleteWellLoeExpense, getWellLoeExpenses, updateWellLoeExpense } from 'service/loeExpense'
import { formatDate } from 'utils/dateUtil'
import groupBy from 'lodash/groupBy'
import { EditableProTable } from '@ant-design/pro-components'
import enUS from "antd/locale/en_US";
import omit from 'lodash/omit'

import editableTableClasses from '../../editable-table.module.scss'
import { useToggle } from '@uidotdev/usehooks'
import AddLOEItemModal from './AddLOEItemModal'
import orderBy from 'lodash/orderBy'

import classes from './loe-records.module.scss'
import { snakeToCamel } from 'utils/string'

const LOERecords = ({ wellId, assetId }) => {
  const [addItemModalOpened, toggleAddItemModal] = useToggle(false)

  const { data: {
    loe_expense_dict: expenseDict,
    loe_expenses: expenses = []
  } = {},
    refetch: refetchLOE,
    isFetching
  } = useQuery({
    queryKey: ['getWellLoeExpenses', wellId],
    queryFn: () => getWellLoeExpenses(wellId),
    select: res => res.data,
    enabled: Boolean(wellId)
  })

  const { mutate: updateLOE, isPending: isUpdating } = useMutation({
    mutationFn: (data) => updateWellLoeExpense(wellId, data, () => refetchLOE())
  })

  const { mutateAsync: addLOEItemAsync, isPending: isAdding } = useMutation({
    mutationFn: (data) => addExpenseForWells(wellId, data, () => refetchLOE())
  })

  const { mutate: deleteLOEItem, isPending: isDeletingItem } = useMutation({
    mutationFn: (ids) => deleteWellLoeExpense(wellId, ids, () => refetchLOE(), null, true)
  })

  const expenseDictMap = useMemo(() => {
    return expenseDict?.reduce((map, record) => {
      map[record.id] = record
      return map
    }, {}) ?? {}
  }, [expenseDict])

  const records = useMemo(() => {
    if (isEmpty(expenseDictMap) || isEmpty(expenses)) return []

    const plainRecords = []
    expenses.forEach(expense => {
      const dictItem = expenseDictMap[expense.loe_expense_id]
      expense.expense_items?.forEach(item => {
        plainRecords.push({
          ...item,
          expense_name: snakeToCamel(dictItem?.name ?? ''),
          expense_id: expense.loe_expense_id
        })
      })
    })

    const groupedRecords = groupBy(plainRecords, 'date')

    return orderBy(Object.entries(groupedRecords), ([date]) => new Date(date), 'asc').reduce((_records, [date, items]) => {
      _records.push({
        __type: 'group',
        expense_name: formatDate(date, 'MM-dd-yyyy'),
        id: date
      })

      _records.push(...items.map(item => ({
        ...item,
        __type: 'item'
      })))

      return _records
    }, [])
  }, [expenseDictMap, expenses])

  const columns = useMemo(
    /**
     * @returns {import('@ant-design/pro-components').ProColumns[]}
     */
    () => {
      return [
        {
          dataIndex: 'expense_name',
          title: 'Expense Name',
          render: (dom, record) => {
            if (record.__type === 'group') return <b>{record.expense_name}</b>
            return <p style={{ paddingLeft: 16 }}>{record.expense_name}</p>
          },
          onCell: (record) => ({
            colSpan: record.__type === 'group' ? 6 : 1
          }),
          valueType: 'select',
          valueEnum: expenseDictMap,
          editable: (_, record) => record.isNewRecord,
        },
        {
          dataIndex: 'expense_amount',
          title: 'Amount ($)',
          onCell: (record) => ({
            colSpan: record.__type === 'group' ? 0 : 1
          })
        },
        {
          dataIndex: 'fixed_expense',
          title: 'Fixed Expense ($)',
          onCell: (record) => ({
            colSpan: record.__type === 'group' ? 0 : 1
          })
        },
        {
          dataIndex: 'variable_expense',
          title: 'Variable Expense ($)',
          onCell: (record) => ({
            colSpan: record.__type === 'group' ? 0 : 1
          })
        },
        {
          dataIndex: 'notes',
          title: 'Note',
          onCell: (record) => ({
            colSpan: record.__type === 'group' ? 0 : 1
          })
        },
        {
          title: "Operation",
          key: 'ops',
          valueType: "option",
          onCell: (record) => ({
            colSpan: record.__type === 'group' ? 0 : 1
          }),
          render: (text, record, _, action) => {
            let ops = [];

            if (record.__type !== 'item') return ops

            ops.push(
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Button
                key="edit"
                type="text"
                onClick={() => {
                  action?.startEditable?.(record.id);
                }}
                className="text-light"
              >
                Edit
              </Button>
            );
            ops.push(
              <Popconfirm
                key="delete"
                title="Sure to delete?"
                onConfirm={(e) => {
                  deleteLOEItem([record.id])
                }}
              >
                <Button type="text" danger loading={isDeletingItem}>Delete</Button>
              </Popconfirm>
            );
            return ops;
          },
        },
      ]
    }
    , [deleteLOEItem, expenseDictMap, isDeletingItem])

  const onAddLOEItem = useCallback(async (loeData) => {
    await addLOEItemAsync(loeData)
    toggleAddItemModal(false)
  }, [addLOEItemAsync, toggleAddItemModal])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      <AddLOEItemModal
        open={addItemModalOpened}
        assetId={assetId}
        wellId={wellId}
        expenseDict={expenseDict}
        onCancel={() => toggleAddItemModal(false)}
        onSave={onAddLOEItem}
        isSubmitting={isAdding}
      />
      <div className="vstack gap-2">
        <div className="hstack justify-content-end gap-2">
          <Button onClick={() => toggleAddItemModal(true)}>Add LOE</Button>
        </div>
        <EditableProTable
          loading={isFetching || isUpdating || isAdding || isDeletingItem}
          rowKey="id"
          columns={columns}
          value={records}
          columnEmptyText=""
          className={editableTableClasses.editableTable}
          cardProps={{
            className: editableTableClasses.card
          }}
          tableClassName={editableTableClasses.table}
          scroll={{
            y: 540
          }}
          editable={{
            onSave: (key, record, originalRecord) => {
              let expenseId = record.expense_id
              if (record.expense_name !== originalRecord.expense_name) {
                const expense = expenseDictMap[record.expense_name]
                if (expense) expenseId = expense.id
              }
              const normalizedRecord = omit(record, 'expense_id', 'expense_name', 'index', '__type')

              if (record.isNewRecord) {
                return
              }

              updateLOE([{
                loe_expense_id: expenseId,
                expense_items: [normalizedRecord]
              }])
            },
          }}
          recordCreatorProps={{
            style: {
              display: 'none'
            }
          }}
          onRow={(record) => record.__type === 'group' ? {
            className: classes.groupRow,
          } : undefined}
        />
      </div>
    </ConfigProvider>
  )
}

export default LOERecords