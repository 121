import { get, post, put, remove } from "./base";

export function getWellLoeExpenses(uwi, params, onSuccess, onError) {
  return get(
    `/api/economics/well/${ uwi }/loe/expenses/`,
    params,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    }
  );
}

/**
 * 
 * @param {*} uwi 
 * @param {*} data 
 * [
    {
        "loe_expense_id": 1,
        "expense_items": [
            {
                "date": "2023-10-01",
                "expense_amount": 2000,
                "fixed_expense": 1000,
                "variable_expense": 1000,
                "notes": "test 1"
            },
            {
                "date": "2023-11-01",
                "expense_amount": 1000,
                "fixed_expense": 500,
                "variable_expense": 500,
                "notes": "test 12"
            }
        ]
    }
]
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function addWellLoeExpense(uwi, data, onSuccess, onError) {
  return post(
    `/api/economics/well/${ uwi }/loe/expenses/`,
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

/**
 * 
 * @param {*} uwi 
 * @param {*} data 
 * [
    {
        "loe_expense_id": 1,
        "expense_items": [
            {
                "id": 306403,
                "date": "2023-10-01",
                "expense_amount": "2000.00",
                "fixed_expense": "1200.00",
                "variable_expense": "800.00",
                "notes": "test 1",
            },
            {
                "id": 306404,
                "date": "2023-11-01",
                "expense_amount": "1200.00",
                "fixed_expense": "500.00",
                "variable_expense": "700.00",
                "notes": "test 12",
            }
        ]
    }
]
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function updateWellLoeExpense(uwi, data, onSuccess, onError) {
  return put(
    `/api/economics/well/${ uwi }/loe/expenses/`,
    data,
    null,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    true
  );
}

/**
 *
 * @param {*} uwi
 * @param {*} ids null will delete all items of this well
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */
export function deleteWellLoeExpense(uwi, ids, onSuccess, onError, noConfirm) {
  return remove(
    `/api/economics/well/${ uwi }/loe/expenses/`,
    { ids: ids },
    null,
    {},
    (res) => {
      onSuccess?.();
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    noConfirm
  );
}


export function getLoeExpenseDict(onSuccess, onError) {
  return get(
    '/api/economics/expense/dict',
    {},
    {},
    res => onSuccess?.(res.data),
    error => onError?.(error)
  )
}

export function addLoeExpenseItem(uwi, data, onSuccess, onError) {
  return post(
    `/api/economics/well/${ uwi }/loe/expenses/`,
    data,
    {},
    {},
    (res) => onSuccess?.(res.data),
    err => onError?.(err)
  )
}

export function addExpenseForWells(uwi, data, onSuccess, onError) {
  return post(
    `/api/economics/loe/expenses/`,
    data,
    {},
    {},
    (res) => onSuccess?.(res.data),
    err => onError?.(err)
  )
}