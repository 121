import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectActiveWells } from "features/asset/assetSelectorSlice";
import {
  selectActiveProject,
  setShowNewProjectPopup,
} from "features/project/projectsSlice";

import {
  selectSelectedWellIds,
  selectProjectGroupDataItems,
  setProjectGroupDataItems,
  setGroupWells,
  setProjectGroupDataItemsWithIndexProjectGroupWells,
  deleteProjectGroupDataItemsWithIndex,
} from "features/decline_analysis/typeCurveProjectSlice";

import {
  saveProjectGroups,
  deleteProjectGroups,
  removeGroupWells,
} from "service/typeCurve";
import AddProjectForm from "components/project/AddProjectForm";
import { message } from "antd";

function UserGroupingPanel(props) {
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const currentProject = useSelector(selectActiveProject);

  const projectGroupItems = useSelector(selectProjectGroupDataItems);

  const activeWells = useSelector(selectActiveWells);
  const selectedWellIds = useSelector(selectSelectedWellIds);

  const dispatch = useDispatch();

  const [newGroupName, setNewGroupName] = useState("");
  const [usedWellsMap, setUsedWellsMap] = useState(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  const [selectedIdsSet, setSelectedIdsSet] = useState(new Set());
  const [selectedSavedWellIds, setSelectedSavedWellIds] = useState([]);

  const selectedWells = useMemo(() => {
    let selectedWells = [];
    if (
      selectedWellIds != null &&
      selectedWellIds.length > 0 &&
      activeWells != null &&
      activeWells.length > 0
    ) {
      let wellIdsSet = new Set(selectedWellIds);
      selectedWells = activeWells.filter((well) => wellIdsSet.has(well.uwi));
    }
    return selectedWells;
  }, [activeWells, selectedWellIds]);

  useEffect(() => {
    if (projectGroupItems == null || projectGroupItems.length === 0) {
      if (selectedGroupIndex != null) {
        setSelectedGroupIndex(null);
      }
      return;
    }

    let wellIdsSet = new Set();
    for (let projectItem of projectGroupItems) {
      const groupWells = projectItem.group_wells;
      groupWells &&
        groupWells.forEach((item) => {
          wellIdsSet.add(item);
        });
    }

    let wellsMap = new Map();
    let wells = activeWells.filter((well) => wellIdsSet.has(well.uwi));
    for (let well of wells) {
      wellsMap.set(well.uwi, well);
    }
    setUsedWellsMap(wellsMap);

    if (selectedGroupIndex == null) {
      setSelectedGroupIndex(0);
    } else if (selectedGroupIndex >= projectGroupItems.length) {
      setSelectedGroupIndex(projectGroupItems.length - 1);
    }

    dispatch(setGroupWells([...wellIdsSet]));
  }, [projectGroupItems, activeWells, selectedGroupIndex, dispatch]);

  useEffect(() => {
    if (selectedGroupIndex == null) {
      setSelectedSavedWellIds([]);
      return;
    }

    let groupWells = projectGroupItems[selectedGroupIndex].group_wells;
    if (groupWells && groupWells.length > 0) {
      setSelectedSavedWellIds([groupWells[0]]);
    }
  }, [selectedGroupIndex, projectGroupItems]);

  useEffect(() => {
    if (!selectedWellIds || selectedWellIds.length === 0) {
      return;
    }

    setSelectedIdsSet((s) => {
      return new Set(
        selectedWellIds.filter(
          (wellId) => s != null && s.size > 0 && s.has(wellId)
        )
      );
    });
  }, [selectedWellIds]);

  function handleGroupNameChanged(e) {
    const value = e.target.value;
    setNewGroupName(value);
  }

  function handleWellSelectedChanged(index, well) {
    let wellId = well.uwi;

    if (selectedIdsSet.has(wellId)) {
      let tempIds = new Set([...selectedIdsSet]);
      tempIds.delete(wellId);
      setSelectedIdsSet(tempIds);
    } else {
      setSelectedIdsSet(new Set([...selectedIdsSet, wellId]));
    }
  }

  function handleSelectSavedGroup(index) {
    if (index !== selectedGroupIndex) {
      setSelectedGroupIndex(index);
    }
  }

  function handleSaveWellSelected(well) {
    if (selectedSavedWellIds.length === 0) {
      setSelectedSavedWellIds([well.uwi]);
    } else {
      if (!selectedSavedWellIds.includes(well.uwi)) {
        setSelectedSavedWellIds([...selectedSavedWellIds, well.uwi]);
      } else {
        setSelectedSavedWellIds(
          selectedSavedWellIds.filter((item) => item !== well.uwi)
        );
      }
    }
  }

  const innerSaveGroup = (projectId) => {
    let wellIds = [...selectedIdsSet];
    if (wellIds.length === 0) {
      return;
    }

    let projectGroup = {
      project_id: projectId,
      group_name: newGroupName,
      records: [],
    };

    for (let phase of ["oil", "gas", "liquid"]) {
      projectGroup.records.push({
        decline_phase: phase,
        dca_model_type: null,
        user_ip_ratio: 0,
        user_initial_decline_rate: 0,
        user_coefficient_b: 0,
        recomm_ip_ratio: 0,
        recomm_initial_decline_rate: 0,
        recomm_coefficient_b: 0,
      });
    }

    let projectGroups = [
      {
        project_group: projectGroup,
        group_wells: wellIds,
      },
    ];

    showLoadingIcon && showLoadingIcon();
    saveProjectGroups(
      projectId,
      projectGroups,
      (res) => {
        if (res.project_groups) {
          let projectItem = res.project_groups[0];
          let tempProjectItems = [...projectGroupItems, projectItem];
          setNewGroupName("");
          setSelectedIdsSet(new Set());
          dispatch(setProjectGroupDataItems(tempProjectItems));
          // dispatch(setGroupWells(selectedWellIds));
        }
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  };

  function handleSaveGroup(e) {
    if (
      !selectedWellIds ||
      selectedWellIds.length < 2 ||
      selectedIdsSet.size < 2
    ) {
      message.warning("Please select at least 2 wells!");
      return;
    }
    if (!newGroupName || newGroupName.length < 3) {
      message.warning(
        "Please set a name for the group with at least 3 letters!"
      );
      return;
    }
    if (!currentProject) {
      dispatch(setShowNewProjectPopup(true));
      return;
    }

    if (newGroupName == null || newGroupName.length === 0) {
      console.error("group name shouldn't be empty.");
      return;
    }

    innerSaveGroup(currentProject.id);
  }

  function handleRemoveSavedGroup() {
    if (!projectGroupItems || projectGroupItems.length === 0) {
      message.info("Please create a project and groups first");
      return;
    }

    let projectGroup = projectGroupItems[selectedGroupIndex].project_group;
    deleteGroup(projectGroup.id);
  }

  function deleteGroup(groupId) {
    showLoadingIcon && showLoadingIcon();
    deleteProjectGroups(
      null,
      [groupId],
      (res) => {
        dispatch(
          deleteProjectGroupDataItemsWithIndex({ index: selectedGroupIndex })
        );
        if (projectGroupItems.length > 1) {
          if (selectedGroupIndex > projectGroupItems.length - 1) {
            setSelectedGroupIndex(projectGroupItems.length - 1);
          }
        } else {
          setSelectedGroupIndex(null);
        }
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }

  function handleRemoveSavedWell() {
    if (!projectGroupItems || projectGroupItems.length === 0) {
      message.info("Please create a project first");
      return;
    }
    const projectItem = projectGroupItems[selectedGroupIndex];

    if (projectItem.group_wells.length === 1) {
      deleteGroup(projectItem.project_group.id);
      return;
    }
    if (!selectedSavedWellIds || selectedSavedWellIds.length < 1) {
      message.info("Please select at least 1 well!");
      return;
    }
    removeGroupWells(
      projectItem.project_group.id,
      selectedSavedWellIds,
      (res) => {
        let groupWells = projectItem.group_wells;
        let tempGroupWells = groupWells.filter(
          (item) => !selectedSavedWellIds.includes(item)
        );
        setSelectedSavedWellIds([]);
        dispatch(
          setProjectGroupDataItemsWithIndexProjectGroupWells({
            index: selectedGroupIndex,
            group_wells: tempGroupWells,
          })
        );
      },
      (error) => {}
    );
  }

  function generateCustomWellSelector() {
    if (selectedWells == null || selectedWells.length === 0) {
      return null;
    }
    return selectedWells.map((item, index) => {
      if (!item) {
        return null;
      }
      return (
        <div
          key={index}
          className="list-item p-2 mb-2 d-flex flex-row justify-content-between align-items-center"
        >
          <div>{item.well_name}</div>
          <input
            type="checkbox"
            onChange={(e) => {
              handleWellSelectedChanged(index, item);
            }}
            checked={selectedIdsSet.has(item.uwi)}
          ></input>
        </div>
      );
    });
  }

  function generateSavedGroups() {
    if (!projectGroupItems) {
      return null;
    }

    return projectGroupItems.map((groupDataItem, index) => {
      let projectGroup = groupDataItem.project_group;
      let selected = selectedGroupIndex === index;
      let itemClass = "list-item text-center mb-2";
      if (selected) {
        itemClass += " selected";
      }
      return (
        <div
          key={index}
          className={itemClass}
          onClick={(e) => {
            handleSelectSavedGroup(index);
          }}
        >
          {projectGroup?.group_name}
        </div>
      );
    });
  }

  function generateSavedWellsSelector() {
    if (
      selectedGroupIndex == null ||
      projectGroupItems == null ||
      projectGroupItems.length === 0 ||
      selectedGroupIndex > projectGroupItems.length - 1 ||
      projectGroupItems[selectedGroupIndex] == null
    ) {
      return null;
    }
    let projectItem = projectGroupItems[selectedGroupIndex];
    let wellIds = projectItem.group_wells;
    if (wellIds == null || wellIds.length === 0) {
      return null;
    }
    let wells = wellIds.map((wellId) => usedWellsMap.get(wellId));

    return (
      wells &&
      wells.length > 0 &&
      wells.map((item, index) => {
        let selected = selectedSavedWellIds.includes(item.uwi);
        let itemClass = "list-item text-center mb-2";
        if (selected) {
          itemClass += " selected";
        }
        return (
          <div
            className={itemClass}
            key={item.uwi}
            onClick={(e) => {
              handleSaveWellSelected(item);
            }}
          >
            {item.well_name}
          </div>
        );
      })
    );
  }

  return (
    <>
      <AddProjectForm
        input={{
          project_type: "type_curve",
          project_name: `PRJ_${new Date().getTime()}`,
          comment: "comment",
        }}
        callbackFunction={[innerSaveGroup, null]}
        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
      />
      <div className="cards-content d-flex flex-column">
        <div className="d-flex flex-row justify-content-between align-items-center pb-2">
          <div className="d-flex flex-row align-items-center me-4">
            <div>Name A Group</div>
            <input
              className="ms-2"
              value={newGroupName}
              onChange={handleGroupNameChanged}
            ></input>
          </div>
        </div>
        <div className="row flex-list-wrapper">
          <div className="col cluster-params-wrapper h-100 d-flex flex-column">
            <div className="p-2">Edit Group</div>
            <div className="cluster-params-list flex-list-wrapper overflow-auto hide-scrollbar p-2">
              {generateCustomWellSelector()}
            </div>
            <div className="d-flex justify-content-center align-items-center p-2">
              <button className="btn-custom w-45" onClick={handleSaveGroup}>
                Create
              </button>
            </div>
          </div>
          <div className="col cluster-params-wrapper h-100 d-flex flex-column">
            <div className="text-center p-2">Created Group</div>
            <div className="row flex-list-wrapper">
              <div className="col list-container d-flex flex-column h-100 ms-2 me-1 px-0">
                <div className="list-header text-center p-2">Groups</div>
                <div className="d-flex flex-column flex-list-wrapper overflow-auto hide-scrollbar m-2">
                  {generateSavedGroups()}
                </div>
              </div>
              <div className="col list-container d-flex flex-column h-100 ms-1 me-2 px-0">
                <div className="list-header text-center p-2">Wells</div>
                <div className="d-flex flex-column flex-list-wrapper overflow-auto hide-scrollbar m-2">
                  {generateSavedWellsSelector()}
                </div>
              </div>
            </div>
            <div className="row p-2">
              <div className="col d-flex justify-content-center align-items-center ms-2 me-1 px-0">
                <button
                  className="btn-custom"
                  onClick={(e) => {
                    handleRemoveSavedGroup();
                  }}
                >
                  Remove Selected
                </button>
              </div>
              <div className="col d-flex justify-content-center align-items-center ms-1 me-2 px-0">
                <button
                  className="btn-custom"
                  onClick={(e) => {
                    handleRemoveSavedWell();
                  }}
                >
                  Remove Selected
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserGroupingPanel;
