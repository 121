import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userData: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setLogout: (state, action) => {
      state.userData = undefined;
      localStorage.removeItem("user");
    },
  },
});

export const { setUserData, setLogout } = userSlice.actions;

export const selectUserData = (state) => {
  if (state.user.userData === null) {
    const localItem = JSON.parse(localStorage.getItem("user"));
    if (localItem === null || localItem === "null") {
      return null;
    } else {
      return localItem;
    }
  }
  return state.user.userData;
};

export const selectAuthenticated = (state) => {
  const userLocal = JSON.parse(localStorage.getItem("user"));
  return userLocal !== null && userLocal !== "null";
};

export default userSlice.reducer;
