import { Button, Card, Col, ConfigProvider, Divider, Form, Input, Checkbox, Row, Space, Modal } from "antd";

const REPORT_OPTIONS = [
  {
    title: "Well",
    name: "well",
    options: [
      { value: 'wellhead_location', label: 'Wellhead Location' },
      { value: 'deviation', label: 'Deviation' },
      { value: 'well_type', label: 'Well Type' },
      { value: 'landing_zone', label: 'Landing Zone' },
    ],
  },
  {
    title: "Completion",
    name: "completion",
    options: [
      { value: 'sand_usage', label: 'Sand Usage' },
      { value: 'proponent_type', label: 'Proponent Type' },
      { value: 'cluster_stage', label: 'Cluster/Stage' },
      { value: 'stage', label: 'Stage' },
    ],
  },
  {
    title: "Background Map",
    name: "background_map",
    options: [
      { value: 'permeability', label: 'Permeability' },
      { value: 'contour_line', label: 'Contour Line' },
      { value: 'isopach', label: 'Isopach' },
    ]
  },
  {
    title: "Production",
    name: "production",
    options: [
      { value: 'fracture_pressure', label: 'Fracture Pressure' },
      { value: 'well_length', label: 'Well Length' },
      { value: 'stage', label: 'Stage' },
    ]
  },
]
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

function WellPlannerReporting(props) {
  const onFinish = (values) => {
    if (values) {
      let modal = Modal.info();
      modal.update({
        title: "Generating",
        content: (<>
          <p>Your requirement has been sent to the backend.</p>
          <p>It will cost a few seconds or minutes. Thanks for your patience!</p>
        </>),
        onOk: () => {
          Modal.success({
            title: "Ready!",
            content: (<>
              <p>The Reporting file is ready for downloading...</p>
              <p>After clicked &quot;OK&quot;, downloading will start in the browser.</p>
            </>),
            onOk: () => {
              Modal.error({
                title: "Download Error",
                content: (<>
                  <p>Sorry, there is an error in generating Reporting file!</p>
                  <p>Please check with the system manager.</p>
                </>),
              })
            }
          })
        }
      });
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: 'white',
          colorBgBase: 'rgba(38,44,71, 1)',
        },
        components: {
          Checkbox: {
            dotSize: 12,
            radioSize: 16,
          },
          Card: {
            headerHeightSM: 100,
            headerFontSize: 24,
            headerBg: 'red',
          },
          Button: {
          }
        }
      }}
    >
      <Space direction="vertical" size="middle" style={{ display: 'flex', margin: '2em', borderRadius: 16, backgroundColor: 'rgba(38,44,71, 1)', padding: '2em', overflow: 'auto' }}>
        <Form
          name="validate_other"
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{
            'filename': 'Report_' + new Date().toISOString(),
            'well': 'wellhead_location',
            'completion': 'sand_usage',
            'background_map': 'permeability',
            'production': 'fracture_pressure',
          }}
          style={{ width: '100%' }}
        >
          <Form.Item name="filename" label="Report Name:"
            rules={[{ required: true, },]}
          >
            <Input style={{ borderColor: 'rgba(255, 255, 255, 0.5' }} />
          </Form.Item>

          <Card title="Select Export:" size="large" style={{ backgroundColor: 'rgba(27,31,57,1)', padding: '2em', borderRadius: 16 }}>
            <Row gutter={[16, 16]}>
              {
                REPORT_OPTIONS.map((item, index) => (
                  <Col key={index} span={12}>
                    <Card title={item.title} size="large" style={{ flex: 12 }}>
                      <Form.Item name={item.name}>
                        <Checkbox.Group>
                          <Row>
                            {
                              item.options.map((option, index) => (
                                <Col key={index} span={24}><Checkbox value={option.value}>{option.label}</Checkbox></Col>
                              ))
                            }
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Card>
                  </Col>
                ))
              }
            </Row>
            <Divider></Divider>
            <Form.Item wrapperCol={{ span: 24, offset: 11 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Generate PDF File...
                </Button>
              </Space>
            </Form.Item>
          </Card>
        </Form>
      </Space>
    </ConfigProvider>
  );
}

export default WellPlannerReporting;
