import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

/**
 * export Array of Arrays to excel file.
 * @param {*} data Array of Arrays data
 * data = [
 * ["Name", "Age", "Gender"],
 * ["Sam", "28", "Male"],
 * ]
 * @param {*} fileName
 */
export const exportToSpreadsheet = (data, fileName, ws) => {
	const workSheet = ws ?? XLSX.utils.aoa_to_sheet(data);
	const workBook = {
		Sheets: { data: workSheet, cols: [] },
		SheetNames: ["data"],
	};
	const excelBuffer = XLSX.write(workBook, {
		bookType: "xlsx",
		type: "array",
	});
	const fileData = new Blob([excelBuffer], {
		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
	});
	FileSaver.saveAs(fileData, fileName + ".xlsx");
};
