import isNil from "lodash/isNil"

export function isDeepEmpty(obj) {
  if (isNil(obj)) return true

  if (typeof obj !== 'object') {
    return !(obj?.toString?.() ?? obj)
  }

  let isEmpty = true
  for (let value of Object.values(obj)) {
    if (typeof value !== 'object') {
      isEmpty = !(value?.toString?.() ?? value)
    }

    if (!isEmpty) break

    isEmpty = isDeepEmpty(value)

    if (!isEmpty) break
  }

  return isEmpty
}