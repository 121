import { get, post, put, remove } from "./base";

export function getAssets(onSuccess, onError) {
  return get(
    "/api/assets/query",
    null,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    }
  );
}

export function saveAsset(asset, onSuccess, onError) {
  return post(
    "/api/assets/",
    asset,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function updateAsset(assetId, asset, onSuccess, onError) {
  return put(
    `/api/assets/${ assetId }`,
    asset,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function deleteAsset(assetId, onSuccess, onError) {
  remove(
    `/api/assets/${ assetId }`,
    null,
    null,
    {},
    (res) => {
      onSuccess();
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}
