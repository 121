import { convertToImperialDate } from "app/codes";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAssets } from "service/assets";
import { getWellSummary } from "service/wellSummary";
import { WellSummaryHeaders } from "./data";
import useLoading from "components/common/useLoading";
import { Spin } from "antd";

function AutoHiddenScrollBar(props) {
  const [showScrollBar, setShowScrollBar] = useState('hidden');
  return (
    <div
      style={{ overflowX: showScrollBar }}
      onMouseOver={() => { setShowScrollBar('auto'); }}
      onMouseLeave={() => { setShowScrollBar('hidden') }}
    >
      {props.children}
    </div>
  )
}

function WellSummaryEditor(props) {
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(<Spin size="large" />, 50);
  const [assets, setAssets] = useState(null);
  const [activeAssetId, setActiveAssetId] = useState(null);
  const [activeAssetName, setActiveAssetName] = useState(null);
  const [wells, setWells] = useState(null);
  const [matchWells, setMatchWells] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    showLoadingIcon();
    getAssets(
      (result) => {
        setAssets(result);
        hideLoadingIcon();
      },
      (error) => { hideLoadingIcon(); }
    );
  }, [showLoadingIcon, hideLoadingIcon]);

  useEffect(() => {
    if (assets == null) {
      return;
    }
    setActiveAssetId(assets[0].id);
    setActiveAssetName(assets[0].asset_name);
  }, [assets]);

  useEffect(() => {
    if (activeAssetId == null) {
      return;
    }
    showLoadingIcon();
    getWellSummary(
      activeAssetId,
      (result) => {
        setWells(result);
        hideLoadingIcon();
      },
      (error) => { hideLoadingIcon(); }
    );
  }, [activeAssetId, hideLoadingIcon, showLoadingIcon]);

  function handleAssetChanged(e) {
    const assetName = e.target.value;
    let asset = assets.find((asset) => asset.asset_name === assetName);
    if (asset) {
      setActiveAssetName(asset.asset_name);
      setActiveAssetId(asset.id);
    }
  }

  function handleEditRowData(well) {
    navigate('/well_editor/' + well.uwi, { replace: false });
  }

  function generateWellsRows() {
    if (!wells || wells.length === 0) {
      return null;
    }

    return wells.map((well, index) => {
      return (
        <div
          className="data-row"
          key={index}
          onClick={(e) => {
            handleEditRowData(well);
          }}
        >
          <div className="data-cell" key={index + "_" + 0}>
            <span>{index}</span>
          </div>
          {WellSummaryHeaders.map((header, i) => {
            return (
              <div className="data-cell" key={index + "_" + (i + 1)}>
                <span>{header.value === "spud_date" ? convertToImperialDate(well[header.value]) : well[header.value]}</span>
              </div>
            );
          })}
        </div>
      );
    });
  }

  function searchWells(e) {
    let value = e.target.value;
    if (value === "") {
      setShowPopup(false);
      return;
    }
    let searchResult = wells.filter((well) => well.well_name.includes(value));
    setMatchWells(searchResult);
    setShowPopup(true);
  }

  function generateSearchResult() {
    if (!matchWells || matchWells.length === 0) {
      return;
    }
    let className = "search-popup";
    if (showPopup) {
      className += " show";
    }
    return (
      <div className={className}>
        {matchWells.map((well, index) => {
          return (
            <div
              className="list-item"
              key={index}
              onClick={(e) => {
                setShowPopup(false);
                handleEditRowData(well);
              }}
            >
              <span>{well.well_name}</span>
            </div>
          );
        })}
      </div>
    );
  }

  const assetsSelect = () => {
    if (assets) {
      return assets.map((asset, index) => {
        return (
          <option
            key={index}
            className="d-flex justify-content-center align-items-content"
            value={asset.asset_name}
          >
            {asset.asset_name}
          </option>
        );
      });
    }

    return null;
  };

  return (
    <>
      <LoadingIcon />
      <div className="ms-4">
        <div className="app-search d-none d-lg-block">
          <div className="search-wraper d-flex flex-row">
            <div className="selector-select d-flex flex-column align-items-stretch">
              <select
                onChange={handleAssetChanged}
                name="asset_name"
                className="d-flex"
                value={activeAssetName || ""}
              >
                {assetsSelect()}
              </select>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              onChange={searchWells}
            />
            <div className="search-icon">
              <i
                className="bi bi-search"
                onClick={(e) => {
                  setShowPopup(false);
                }}
              ></i>
            </div>
          </div>
          {generateSearchResult()}
        </div>
      </div>
      <div className="page-content d-flex flex-column">
        <div className="cards-container">
          <div className="input-container">
            <AutoHiddenScrollBar>
              <div
                className="table-container d-flex flex-column justify-content-start align-items-start"
                style={{ height: "80vh" }}
              >
                <div className="table-header">
                  <div className="header-cell" key={0}>
                    <span>#</span>
                  </div>
                  {WellSummaryHeaders.map((x, i) => {
                    return (
                      <div className="header-cell" key={i + 1}>
                        <span>{x.name}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="table-body">{generateWellsRows()}</div>
              </div>
            </AutoHiddenScrollBar>
          </div>
        </div>
      </div>
    </>
  );
}

export default WellSummaryEditor;
export { AutoHiddenScrollBar };
