import React, { useState, useEffect, useRef, useMemo } from "react";
import { addMonths, startOfMonth, isAfter, isEqual } from 'date-fns'

import { getCommodityPrices } from "service/commodity";
import { PARAM_NAMES } from "./data";
import UserPredictDataEditor from "./UserPredictDataEditor";
import { Select } from "antd";
import TimeSeriesChart from "components/common/TimeSeriesChart";

function CommodityPrices(props) {
  const chartRef = useRef(null)

  const [records, setRecords] = useState([]);
  const [visibleCurves, setVisibleCurves] = useState(Object.keys(PARAM_NAMES))

  const fetchData = () => {
    getCommodityPrices(
      null,
      (data) => {
        if (!data || data.length === 0) return;
        setRecords(data);
      },
      (error) => {
        console.log(error);
      }
    )
  }

  const { userSeries, seriesData } = useMemo(() => {
    if (!records.length || !visibleCurves.length) return {
      userSeries: [],
      seriesData: {}
    }
    let dateSeries = records.map((item) => item.date.slice(0, 10));
    const userSeriesNames = Object.keys(PARAM_NAMES).filter((seriesName) =>
      seriesName.includes("user")
    );
    const userRecordKeys = ["id", "date", ...userSeriesNames];
    let userDataSeries = records
      .map((item) => {
        let record = {};
        userRecordKeys.forEach((key) => (record[key] = item[key]));
        return record;
      })
      .filter((record) => {
        let isEmpty = true;
        const recordDate = new Date(record.date)
        const nextMonth = addMonths(startOfMonth(new Date()), 1)
        const isAfterNextMonth = isEqual(recordDate, nextMonth) || isAfter(recordDate, nextMonth)

        if (!isAfterNextMonth) return false

        userSeriesNames.forEach(
          (key) => (isEmpty = isEmpty && record[key] == null)
        );
        return !isEmpty;
      });

    let dataSeries = {};
    records.forEach((item) => {
      for (const curveType of Object.keys(PARAM_NAMES)) {
        if (!visibleCurves.includes(curveType)) continue
        const curveName = PARAM_NAMES[curveType].name;
        if (dataSeries[curveName] === undefined) {
          dataSeries[curveName] = {
            data: [],
            lineType: "line",
            unit: PARAM_NAMES[curveType].unit,
            showAxisLine: ['oil', 'ng', 'ngl_user_predict'].includes(curveType)
          };
        }
        dataSeries[curveName]["data"].push(item[curveType]);
      }
    });

    let sData = {
      xSeries: dateSeries,
      config: {
        multiYAxis: true,
        leftYAxisLabel: "WTI Oil",
      },
      dataSeries: dataSeries,
    };
    return {
      userSeries: userDataSeries,
      seriesData: sData
    }
  }, [records, visibleCurves])

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleUserDataChanged(newRecord, added = false) {
    // let newRecords = [];
    // if (added) {
    //   newRecords = [...records, newRecord];
    // } else {
    //   let changedIndex = records.findIndex(
    //     (record) => record.id === newRecord.id
    //   );
    //   newRecords = [...records];
    //   newRecords[changedIndex] = newRecord;
    // }
    // setRecords(newRecords);
    fetchData()
  }

  const onSelectCurve = (curveType) => {
    setVisibleCurves(prev => [...prev, curveType])
  }

  const onDeselectCurve = (curveType) => {
    setVisibleCurves(prev => prev.filter(type => type !== curveType))
  }

  return (
    <div className="h-100 w-100">
      <div style={{ height: "40vh" }} className="vstack pt-2 px-2">
        <div className="px-2">
          <div className="mx-2 mb-2">
            <Select
              mode="multiple"
              dropdownMatchSelectWidth={false}
              value={visibleCurves}
              options={Object.entries(PARAM_NAMES).map(([key, param]) => ({
                value: key,
                label: param.name
              }))}
              placeholder="Select curves"
              style={{
                minWidth: 150
              }}
              onSelect={onSelectCurve}
              onDeselect={onDeselectCurve}
            />
          </div>
        </div>
        <div className="flex-grow-1">
          <TimeSeriesChart ref={chartRef} data={seriesData} />
        </div>
      </div>
      <div style={{ height: "40vh", width: "100%", overflow: "auto" }}>
        <UserPredictDataEditor
          userSeries={userSeries}
          dataChanged={handleUserDataChanged}
          onRefetch={fetchData}
        ></UserPredictDataEditor>
      </div>
    </div>
  );
}

export default CommodityPrices;
