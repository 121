import { useState, useEffect, useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import {
  setDeclinePhase,
  selectActiveWell,
  selectDeclinePhase,
  selectManagementGlobal,
  setManagementGlobalParamsSuggested,
  setManagementGlobalByPhase,
} from "features/decline_analysis/dcaManagementSlice";
import { getDcaManagementHistory } from "service/dcaAnalysis";

import DcaManagementHistoryChart from "components/charts/DcaManagementHistoryChart";
import DcaManagementModelSetting from "./DcaManagementModelSetting";

import { PARAM_NAMES, DECLINE_PHASE_OPTIONS } from "./data";

const dateFormat = "MM/DD/YYYY";

const historySetting = {
  startDate: null,
  validationMonths: 0,
  mode: "sum",
  params: [],
};

function DcaManagementHistoryWatch(props) {
  const dispatch = useDispatch();
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const activeWell = useSelector(selectActiveWell);
  const declinePhase = useSelector(selectDeclinePhase);
  const globalSettings = useSelector(selectManagementGlobal, shallowEqual);

  const [seriesData, setSeriesData] = useState({});
  const [showDcaManagementModelSetting, setShowDcaManagementModelSetting] =
    useState(false);
  const [startDate, setStartDate] = useState(null);
 
  const reloadSeriesData = useCallback(
    (well, declinePhase) => {
      const getDataByDate = (dataWithDate, date, method) => {
        let result,
          dataPos = 0;
        result = date.map((item, index) => {
          if (dataPos >= dataWithDate.length) return null;
          if (item === dataWithDate[dataPos][0].slice(0, 10)) {
            return dataWithDate[dataPos++][1];
          } else {
            return null;
          }
        });
        return result;
      };

      let apiData = {
        ...historySetting,
        validationMonths: globalSettings[declinePhase].validationMonths,
        params: globalSettings[declinePhase].params,
        wellId: well.uwi, //activeWell?.uwi || activeWell?.well_uwi,
        declinePhase: declinePhase,
        startDate: globalSettings[declinePhase].startDate
          ? dayjs(globalSettings[declinePhase].startDate).format("YYYY-MM-DD")
          : "",
      };
      showLoadingIcon && showLoadingIcon();
      getDcaManagementHistory(
        apiData,
        (result) => {
          let { success, history, ml } = result;
          if (success) {
            // reset user tuning values
            // dispatch(setDcaUserParams(null));

            let { fitted, predicted } = ml;
            let newPredicated = [fitted[fitted.length - 1]];
            predicted.map((item) => newPredicated.push(item));
            let dateSeries = history.map((item) => item[0].slice(0, 10));
            let sData = {
              xSeries: dateSeries,
              config: {
                multiYAxis: false,
                leftYAxisLabel: DECLINE_PHASE_OPTIONS.find(
                  (item) => item.value === declinePhase
                ).name,
                title: well.well_name,
              },
              dataSeries: [
                {
                  Historical: {
                    data: history.map((item) => item[1]),
                    lineType: "scatter",
                    unit: PARAM_NAMES[declinePhase].unit,
                  },
                },
                {
                  "AI-physics": {
                    data: getDataByDate(fitted, dateSeries),
                    lineType: "line",
                    unit: PARAM_NAMES[declinePhase].unit,
                  },
                },
                {
                  Validation: {
                    data: getDataByDate(newPredicated, dateSeries),
                    lineType: "line",
                    unit: PARAM_NAMES[declinePhase].unit,
                  },
                },
              ],
            };
            setSeriesData(sData);

            let sameSuggested =
              result.optimal_x.length ===
              globalSettings[declinePhase].paramsSuggested.length;
            if (sameSuggested) {
              result.optimal_x.map(
                (item, index) =>
                  (sameSuggested =
                    sameSuggested &&
                    result.optimal_x[index] ===
                      globalSettings[declinePhase].paramsSuggested[index])
              );
            }
            if (!sameSuggested) {
              dispatch(setManagementGlobalParamsSuggested(result.optimal_x));
            }
            // get default date
            let maxValue = 0,
              maxDate = undefined;
            history.map((item) => {
              let date = item[0];
              let value = item[1];
              if (value > maxValue) {
                maxValue = value;
                maxDate = date;
              }
              return null;
            });

            if (maxDate && maxDate !== startDate && startDate === null) {
              setStartDate(
                globalSettings[declinePhase].startDate ||
                  dayjs(maxDate.slice(0, 10)).format(dateFormat)
              );
            }
          } else {
            console.error("load data error.");
          }
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          console.error("load data error====>", error);
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [
      globalSettings,
      dispatch,
      hideLoadingIcon,
      showLoadingIcon,
      startDate,
    ]
  );

  useEffect(() => {
    if (!activeWell?.uwi) {
      return;
    }
    reloadSeriesData(activeWell, declinePhase);
  }, [
    declinePhase,
    activeWell,
    reloadSeriesData,
  ]);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(setDeclinePhase(value));
    },
    [dispatch]
  );

  const phasesSelect = useMemo(() => {
    return DECLINE_PHASE_OPTIONS.map((item, index) => {
      const id = "man_history" + index.toString();
      return (
        <span key={index}>
          <input
            className="btn-check"
            id={id}
            type="radio"
            name="man_history"
            value={item.value}
            onChange={handleChange}
            checked={declinePhase === item.value}
          />
          <label htmlFor={id} className="btn-label header">
            {item.name}
          </label>
        </span>
      );
    });
  }, [declinePhase, handleChange]);

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">History Match</span>
        <div className="header-menu-container d-flex flex-row">
          <div>
            <span>Decline Phase :</span>
            {phasesSelect}
          </div>
          <div className="cards-setting-btn ms-2">
            <div
              className="cards-setting-icon"
              onClick={() => setShowDcaManagementModelSetting(true)}
            />
          </div>
        </div>
      </div>

      <div className="cards-content d-flex flex-column justify-content-between align-items-stretch">
        <div className="forecast-upper-part">
          <DcaManagementHistoryChart data={seriesData} />
        </div>
      </div>
      <div className="forecast-lower-part d-flex flex-column mt-2">
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div
            className="d-flex justify-content-between align-items-center mt-2"
            style={{ marginLeft: "5px" }}
          >
            Date Start Modeling
            <DatePicker
              style={{ backgroundColor: "grey", height: "25px", margin: "1px" }}
              value={startDate ? dayjs(startDate, dateFormat) : ""}
              format={dateFormat}
              onChange={(v) => {
                if (v == null) return;
                const date =
                  ("0" + (v.$M + 1)).slice(-2) +
                  "/" +
                  ("0" + v.$D).slice(-2) +
                  "/" +
                  v.$y;
                dispatch(
                  setManagementGlobalByPhase({
                    phase: declinePhase,
                    settings: {
                      startDate: date,
                    },
                  })
                );
                setStartDate(date);
              }}
            />
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginLeft: "5px" }}
          >
            <label className="me-1">Validation:</label>
            <input
              value={globalSettings.validationMonths}
              name="months"
              type="number"
              style={{ width: "50px", height: "16px" }}
              onChange={(event) => {
                let v = event.target.value;
                dispatch(
                  setManagementGlobalByPhase({
                    phase: declinePhase,
                    settings: {
                      validationMonths: v < 0 ? 0 : v,
                    },
                  })
                );
                return false;
              }}
            />
            <span className="ms-2">(Months)</span>
          </div>
        </div>
      </div>
      {showDcaManagementModelSetting && (
        <div className="popup-window">
          <DcaManagementModelSetting
            options={
              globalSettings.availableParams &&
              globalSettings.availableParams
                .filter((item) => PARAM_NAMES[item] && !PARAM_NAMES[item].ogw)
                .map((item, index) => ({
                  key: index,
                  label: PARAM_NAMES[item].name,
                  value: item,
                }))
            }
            values={
              globalSettings[declinePhase].params &&
              globalSettings[declinePhase].params.map((item) => ({
                label: PARAM_NAMES[item].name,
                value: item,
              }))
            }
            onChange={(value) => {
              dispatch(
                setManagementGlobalByPhase({
                  phase: declinePhase,
                  settings: { params: value },
                })
              );
            }}
            onClose={() => setShowDcaManagementModelSetting(false)}
          />
        </div>
      )}
    </div>
  );
}

export default DcaManagementHistoryWatch;
