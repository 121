import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import AssetWellsSelector from "../components/AssetWellsSelector";
import WellsMap from "../components/WellsMap";
import WellsSelector from "./WellsSelector";
import DataPanel from "./DataPanel";
import PlannerMap from "./PlannerMap";
import PerformanceChart from "./PerformanceChart";
import { message } from "antd";

import {
  reset,
  setSelectedWellIds,
  selectSelectedWellIds,
  setGridParams,
  setCustomWells,
  selectCustomWells,
  setDataType,
  setWellGridProperties,
  setUsedWellInterpoParams,
} from "features/well_planner/plannerSlice";

import {
  resetDca,
  setPerformanceParams,
  selectDeclinePhase,
  setProjectGroupDataItems,
  setProjectClusterDataItems,
  setWellGroups,
  setWellClusters,
} from "features/well_planner/plannerDcaSlice";

import {
  addProjectItem,
  setActiveProjectId,
  selectActiveProject,
} from "features/project/projectsSlice";

import { setActiveAssetId } from "features/asset/assetSelectorSlice";
import { getProjectDetail, parseProjectId } from "service/projects";
import { saveProjectGroups, saveProjectClusters } from "service/wellPlannerDca";

import useLoading from "components/common/useLoading";
import { Spin } from "antd";
import { PHASE_MODEL_MAP, DECLINE_PHASE_OPTIONS } from "app/codes";

function WellPlaner(props) {
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(
    <Spin size="large" />,
    50
  );
  let [searchParams] = useSearchParams();
  const projectId = parseProjectId(searchParams.get("project_id"));
  // const projectName = searchParams.get("project_name");

  const currentProject = useSelector(selectActiveProject);

  // const activeWells = useSelector(selectActiveWells);

  const dispatch = useDispatch();

  const selectedWellIds = useSelector(selectSelectedWellIds);
  const customWells = useSelector(selectCustomWells);

  const declinePhase = useSelector(selectDeclinePhase);
  const product = declinePhase
    ? PHASE_MODEL_MAP[declinePhase]
    : PHASE_MODEL_MAP[DECLINE_PHASE_OPTIONS[0].value];

  const [projectLoadStatus, setProjectLoadStatus] = useState(null); // loading, loaded

  const updateProjectData = useCallback(
    (projectData) => {
      let gridInfos = projectData.grid_infos;
      if (gridInfos && gridInfos.length > 0) {
        let gridInfo = gridInfos[0];
        dispatch(setGridParams(gridInfo));

        if (gridInfo.asset_id != null) {
          dispatch(setDataType("asset"));
          dispatch(setActiveAssetId(gridInfo.asset_id));

          // dispatch(setSelectedWells(activeWells));
          // dispatch(
          //   setSelectedWellIds(
          //     activeWells.map((well) => {
          //       return well.uwi;
          //     })
          //   )
          // );
        } else {
          dispatch(setDataType("wells"));
          if (
            projectData.selected_wells &&
            projectData.selected_wells.length > 0
          ) {
            let wellIds = projectData.selected_wells;
            wellIds = wellIds.filter((wellId) => wellId != null);
            dispatch(setSelectedWellIds(wellIds));
            // let idsSet = new Set(wellIds);
            // dispatch(
            //   setSelectedWells(
            //     activeWells.filter((well) => idsSet.has(well.uwi))
            //   )
            // );
          }
        }
      }

      let hyperWells = projectData.hyper_wells;
      if (hyperWells && hyperWells.length > 0) {
        dispatch(setCustomWells(hyperWells));
      }

      let projectGroupItems = projectData.project_groups;
      if (projectGroupItems && projectGroupItems.length > 0) {
        dispatch(setProjectGroupDataItems(projectGroupItems));
      }
      let projectClusterItems = projectData.project_clusters;
      if (projectClusterItems && projectClusterItems.length > 0) {
        dispatch(setProjectClusterDataItems(projectClusterItems));
      }

      if (
        hyperWells &&
        hyperWells.length > 0 &&
        projectGroupItems &&
        projectGroupItems.length > 0
      ) {
        let wellGroups = {};
        for (const hyperWell of hyperWells) {
          let groupDataItem = projectGroupItems.find(
            (item) => item.project_group?.hyper_well_id === hyperWell.id
          );
          if (groupDataItem) {
            wellGroups[hyperWell.well_uwi] = groupDataItem.group_wells;
          }
        }
        dispatch(setWellGroups(wellGroups));
      }

      if (
        hyperWells &&
        hyperWells.length > 0 &&
        projectClusterItems &&
        projectClusterItems.length > 0
      ) {
        let wellClusters = {};
        for (const hyperWell of hyperWells) {
          let clusterDataItem = projectClusterItems.find(
            (item) => item.project_cluster?.hyper_well_id === hyperWell.id
          );
          if (clusterDataItem) {
            wellClusters[hyperWell.well_uwi] = clusterDataItem.cluster_wells;
          }
        }
        dispatch(setWellClusters(wellClusters));
      }

      let interpolate_parameters = projectData.interpolate_parameters;
      if (interpolate_parameters != null && interpolate_parameters.length > 0) {
        let usedInterpoParams = interpolate_parameters.map((item) => {
          return {
            param: item.param,
            method: item.method,
          };
        });
        dispatch(setUsedWellInterpoParams(usedInterpoParams));
      }
      let interpolatedGrid = projectData.interpolated_grid;
      if (interpolatedGrid) {
        dispatch(setWellGridProperties(interpolatedGrid));
      }
      let performanceParams = projectData.performance_inputs;
      if (performanceParams != null && performanceParams.length > 0) {
        dispatch(setPerformanceParams(performanceParams));
      }
    },
    [dispatch]
  );

  const refreshData = useCallback(
    (projId) => {
      showLoadingIcon();
      setProjectLoadStatus("loading");
      getProjectDetail(
        projId,
        (result) => {
          setProjectLoadStatus("loaded");
          if (currentProject == null) {
            dispatch(
              addProjectItem({
                id: result.project_id,
                name: result.project_name,
                project_type: result.project_type,
                custom_data: result.custom_data,
              })
            );
            dispatch(setActiveProjectId(result.project_id));
          }
          updateProjectData(result);
          hideLoadingIcon();
        },
        (error) => {
          console.error(error);
          setProjectLoadStatus(null);
          hideLoadingIcon();
        }
      );
    },
    [
      dispatch,
      updateProjectData,
      hideLoadingIcon,
      showLoadingIcon,
      currentProject,
    ]
  );

  useEffect(() => {
    return () => {
      dispatch(reset());
      dispatch(resetDca());
      dispatch(setActiveProjectId());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      projectLoadStatus === "loading" ||
      projectLoadStatus === "loaded" ||
      (projectId == null && currentProject == null)
    )
      return;
    refreshData(projectId || currentProject.id);
  }, [projectLoadStatus, projectId, currentProject, refreshData]);

  function handleWellSelected(well) {
    let wellId = well.uwi;
    let tempIds = null;
    if (selectedWellIds == null) {
      tempIds = [wellId];
    } else if (selectedWellIds.includes(wellId)) {
      tempIds = selectedWellIds.filter((item) => item !== wellId);
    } else {
      tempIds = [...selectedWellIds, wellId];
    }

    dispatch(setSelectedWellIds(tempIds));
  }

  function handleWellsSelected(wells) {
    if (wells == null || wells.length === 0) {
      return;
    }
    let wellIds = wells.map((well) => {
      return well.uwi;
    });

    let tempIds = null;
    if (!selectedWellIds) {
      tempIds = wellIds;
    } else {
      let tempIdsSet = new Set([...selectedWellIds, ...wellIds]);
      tempIds = [...tempIdsSet];
    }

    dispatch(setSelectedWellIds(tempIds));
  }

  function handleSubmitWpProject(performanceParams, wellClusters, wellGroups) {
    if (currentProject == null) {
      console.error("project is null");
      return;
    }

    if (customWells == null || customWells.length === 0) {
      console.error("custom wells is null");
      message.error("No user defined wells !");
      return;
    }

    let projectClusters = [];
    let hyperWellIds = Object.keys(wellClusters);
    for (let hyperWellUwi of hyperWellIds) {
      let hyperWell = customWells.find((item) => item.well_uwi === hyperWellUwi);
      let projectClusterItem = {};
      let projectCluster = {
        hyper_well_id: hyperWell.id,
        records: [],
      };
      for (let phase of ["oil", "gas", "liquid"]) {
        let record = {
          decline_phase: phase,
          normalized_by: "wellbore_length",
        };
        projectCluster.records.push(record);
      }
      projectClusterItem["project_cluster"] = projectCluster;
      projectClusterItem["cluster_wells"] = wellClusters[hyperWellUwi];
      projectClusters.push(projectClusterItem);
    }

    if (projectClusters.length > 0) {
      showLoadingIcon();
      saveProjectClusters(
        currentProject.id,
        performanceParams,
        projectClusters,
        (result) => {
          hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon();
        }
      );
    }

    let projectGroups = [];
    for (let hyperWellUwi of hyperWellIds) {
      let hyperWell = customWells.find((item) => item.well_uwi === hyperWellUwi);
      let projectGroupItem = {};
      let projectGroup = {
        hyper_well_id: hyperWell.id,
        records: [],
      };
      for (let phase of ["oil", "gas", "liquid"]) {
        let record = {
          decline_phase: phase,
          normalized_by: "wellbore_length",
        };
        projectGroup.records.push(record);
      }
      projectGroupItem["project_group"] = projectGroup;
      projectGroupItem["group_wells"] = wellGroups[hyperWellUwi];
      projectGroups.push(projectGroupItem);
    }

    if (projectGroups.length > 0) {
      showLoadingIcon();
      saveProjectGroups(
        currentProject.id,
        projectGroups,
        (result) => {
          hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon();
        }
      );
    }
  }

  return (
    <>
      <LoadingIcon />
      <div className="page-content d-flex flex-column w-100 hide-scrollbar">
        <div className="d-flex mb-2 row-wrapper w-100">
          <div className="h-100 ps-0 pe-1" style={{ width: "8.875rem" }}>
            <AssetWellsSelector
              singleSelect={false}
              noAutoSelectFirstWell={true}
              selectedWellIds={selectedWellIds}
              onWellSelected={handleWellSelected}
              onWellsSelected={handleWellsSelected}
              loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
            />
          </div>
          <div className="d-flex h-100 flex-grow-1 g-0">
            <div className="w-50 ps-1 pe-1">
              <WellsMap
                product={product}
                selectedWellIds={selectedWellIds}
                onWellSelected={handleWellSelected}
                onWellsSelected={handleWellsSelected}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
            <div className="w-50 ps-1">
              <DataPanel
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
          </div>
        </div>
        <div className="d-flex mb-2 row-wrapper">
          <div className="h-100 ps-0 pe-1" style={{ width: "8.875rem" }}>
            <WellsSelector
              loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
            />
          </div>
          <div className="row h-100 g-0 flex-grow-1">
            <div className="col ps-1 pe-1">
              <PlannerMap
                selectRed={false}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
            <div className="col ps-1">
              <PerformanceChart
                submitWpProject={handleSubmitWpProject}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WellPlaner;
