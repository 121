export const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '32vh',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#1b1f3a',
};
export const contentStyle = {
    minHeight: 120,
    height: '33.33%',
    padding: '1em 0em 0em 1em',
    backgroundColor: '#1b1f3a',
};
export const assetContentStyle = {
    minHeight: 120,
    height: '50%',
    padding: '1em 0em 0em 1em',
    backgroundColor: '#1b1f3a',
};
export const settingsContentStyle = {
    minHeight: 120,
    height: '22%',
    padding: '1em 0em 0em 1em',
    backgroundColor: '#1b1f3a',
};
export const rightContentStyle = {
    minHeight: 120,
    height: '50%',
    backgroundColor: '#1b1f3a',
};
export const siderStyle = {
    textAlign: 'left',
    height: "calc(100vh - 70px)",
    backgroundColor: '#1b1f3a',
    width: '8%',
};
export const rightStyle = {
    color: '#fff',
    textAlign: 'center',
    height: "calc(100vh - 70px)",
    width: '120wh',
    backgroundColor: '#1b1f3a',
    padding: '1em 0em 0em 1em',
    overflow: 'auto',
};
export const footerStyle = {
    textAlign: 'center',
    height: '32vh',
    color: '#fff',
    backgroundColor: '#1b1f3a',
};
