import { Fragment, useEffect, useState } from "react";
import HistoricalAAsset2 from "components/charts/HistoricalA2";
import { ProductOptions, DatePeriodOptions } from "app/codes";
import Select from 'react-select';
import SectionSetting from "components/common/SectionSetting";

const modelOptions = ProductOptions.filter((item) => (item.value !== "power_consumption" && item.value !== "cumulative_power_consumption"));

function HistoricalChart(props) {
  const { wellId, assetId, historicalVisible, setHistoricalVisible } = props;
  // for now 23-08-10 there are no data for power_consumption & cumulative_power_consumption, so let it disappear. when had data, should remove the filter
  const { loadingIconActions } = props;
  const [activePeriod, setActivePeriod] = useState(1);

  const handlePeriodChanged = (days) => {
    setActivePeriod(days);
  };

  const [models, setModels] = useState([modelOptions[0]]);
  const [selectedIndexes, setSelectedIndexes] = useState([0]);

  useEffect(() => {
    if (selectedIndexes == null || selectedIndexes.length === 0) {
      return;
    }
    let selectedOptions = modelOptions.filter((option, index) =>
      selectedIndexes.includes(index)
    );
    setModels(
      selectedOptions.map((option) => {
        return { value: option.value, name: option.name };
      })
    );
  }, [selectedIndexes]);

  const handleMultiSelected = (arr) => {
    let indexes = [];
    arr.map(item => indexes.push(item.key));

    setSelectedIndexes(indexes);
  };

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <div>
          <input
            type="radio"
            className="btn-check"
            name="chartTypeOptions"
            id="chartType1"
            defaultChecked={historicalVisible ? true : false}
            onClick={() => {
              setHistoricalVisible(true);
            }}
          />
          <label htmlFor="chartType1" className="btn-label header">
            Historical
          </label>
          <input
            onClick={() => {
              setHistoricalVisible(false);
            }}
            type="radio"
            className="btn-check"
            name="chartTypeOptions"
            id="chartType2"
            defaultChecked={!historicalVisible ? true : false}
          />
          <label htmlFor="chartType2" className="btn-label header">
            Prescriptive
          </label>
        </div>
        <div className="d-flex flex-row justify-content-end align-items-center">
          <div>
            {DatePeriodOptions.map((item, index) => {
              return (
                <Fragment key={index}>
                  <input
                    key={'i' + index}
                    type="radio"
                    className="btn-check"
                    name="periodOpt"
                    id={"periodOpt" + index.toString()}
                    onChange={() => {
                      handlePeriodChanged(item.days);
                    }}
                    defaultChecked={activePeriod === item.days}
                  />
                  <label
                    key={'l' + index}
                    htmlFor={"periodOpt" + index.toString()}
                    className="btn-label header"
                  >
                    {item.name}
                  </label>
                </Fragment>
              );
            })}
          </div>
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div>
        <Select
          defaultValue={{ value: 'oil_prd_rate', label: 'Oil Rate', key: 0 }}
          onChange={handleMultiSelected}
          isMulti
          name="colors"
          options={modelOptions.map((item, index) => {
            return { key: index, value: item.value, label: item.name }
          })}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? 'red' : 'grey',
            }),
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused ? 'red' : 'black',
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused ? 'grey' : 'black',
            }),
          }}
        />
      </div>
      <div className="cards-content">
        <div className="chart-container d-fex justify-content-center align-items-center">
          <HistoricalAAsset2
            wellId={wellId}
            assetId={assetId}
            models={models}
            period={activePeriod}
            loadingIconActions={loadingIconActions}
          />
        </div>
      </div>
    </div>
  );
}

export { HistoricalChart };
