import { useMutation, useQuery } from '@tanstack/react-query'
import groupBy from 'lodash/groupBy'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { deleteUnit, getUnits, saveUnit, updateUnit } from 'service/dictionary'
import CategoryList from './CategoryList'
import { AutoComplete, Button, Card, Form, Input, InputNumber, Popconfirm, Select, Tag, Typography } from 'antd'
import snakeCase from 'lodash/snakeCase'
import pick from 'lodash/pick'
import { UNITS } from './data'
import { filterOption } from 'utils/select'
import omit from 'lodash/omit'
import { useToggle } from '@uidotdev/usehooks'
import DictionaryLogModal from '../LogModal'
import { snakeToCamel } from 'utils/string'

import classes from './dictionary.module.scss'

const ELEMENT_APP_VALUE = {
  DISABLED: 0,
  DEFAULT: 1,
  USER: 2
}

const DictionaryEditor = ({ dictype }) => {
  const newItemNameInput = useRef(null)
  const [form] = Form.useForm()

  const { data: { dictUnits = {}, dict = [], applications = [] } = {}, isFetching, refetch } = useQuery({
    queryKey: ['dict', 'getUnits', dictype],
    queryFn: () => getUnits({
      dictype
    }),
    select: (res) => {
      return {
        dictUnits: groupBy(res.data.dictionary, 'type'),
        dict: res.data.dictionary,
        applications: res.data.applications?.map(appName => `dictionary_${ appName }`),
      }
    }
  })

  const { mutate: saveUnitDict, isPending: isSaving } = useMutation({
    mutationFn: (data) => data.id ?
      updateUnit(dictype, data.id, omit(data, 'id'))
      : saveUnit(dictype, data),
    onSuccess: (res) => {
      refetch()
      setActiveElement(res.data.id)
    }
  })

  const { mutate: deleteElement } = useMutation({
    mutationFn: (item) => deleteUnit(dictype, item.value, {
      action: 'Delete Element',
      description: `Delete element ${ item.label }`
    }),
    onSuccess: (res) => {
      setActiveElement(null)
      refetch()
    }
  })

  const [activeCategory, setActiveCategory] = useState(null)
  const [activeSubCategory, setActiveSubCategory] = useState(null)
  const [activeElement, setActiveElement] = useState(null)
  const [logModalOpened, toggleLogModal] = useToggle(false)

  const onChangeCategory = useCallback((value) => {
    setActiveElement(null)
    setActiveSubCategory(null)
    setActiveCategory(value)
  }, [])

  const onChangeSubCategory = useCallback((value) => {
    setActiveElement(null)
    setActiveSubCategory(value)
  }, [])

  const subCategories = useMemo(() => {
    const categoryItems = dictUnits[activeCategory]
    if (!categoryItems) return []
    return groupBy(categoryItems, 'subtype')
  }, [activeCategory, dictUnits])

  const activeElementData = useMemo(() => dict.find(unit => unit.id === activeElement) ?? null, [activeElement, dict])

  const activeElementDefaultApps = useMemo(() => {
    return activeElementData ? applications?.filter(appName => activeElementData[appName] === ELEMENT_APP_VALUE.DEFAULT) ?? [] : []
  }, [activeElementData, applications])

  useEffect(() => {
    if (activeElementData && applications?.length) {
      form.setFieldsValue({
        ...activeElementData,
        applications: Object.keys(pick(activeElementData, ...applications)).filter(app => activeElementData[app] === ELEMENT_APP_VALUE.USER)
      })
    }
  }, [activeElementData, applications, form])

  const onAddNewElement = useCallback(() => {
    const elementName = newItemNameInput.current?.input?.value

    const data = {
      type: activeCategory,
      subtype: activeSubCategory,
      full_name: elementName,
      name: snakeCase(elementName),
      action: 'Add Element',
      description: `Add new Element: ${ elementName }`,
      dictype,
    }

    saveUnitDict(data)
  }, [activeCategory, activeSubCategory, dictype, saveUnitDict])

  const onSave = useCallback((data) => {
    if (!activeElementData) return;
    const newData = applications.reduce((data, app) => {
      if (data[app] === ELEMENT_APP_VALUE.DEFAULT) return data
      data[app] = ELEMENT_APP_VALUE.DISABLED
      return data
    }, { ...activeElementData })
    data.applications?.forEach(app => {
      if (newData[app] === ELEMENT_APP_VALUE.DEFAULT) return;
      newData[app] = ELEMENT_APP_VALUE.USER
    })

    Object.assign(newData, omit(data, 'applications'))

    const diffs = Object.entries(newData).reduce((_diffs, [key, value]) => {
      if (activeElementData[key] !== value) {
        _diffs.push(({
          key,
          value,
          oldValue: activeElementData[key]
        }))
      }

      return _diffs
    }, [])

    const diffDescription =
      `Changed values:\n${ diffs.map(diff => {
        const segments = ['-', `${ snakeToCamel(diff.key) }:`]
        segments.push(diff.oldValue ?? 'null')
        segments.push('->')
        segments.push(diff.value)
        return segments.join(' ')
      }).join('\n') }
      `

    saveUnitDict({
      ...pick(activeElementData, 'id', 'type', 'subtype', 'full_name', 'name'),
      ...newData,
      action: `Update Element ${ activeElementData.full_name }`,
      description: diffDescription,
      dictype
    })
  }, [activeElementData, dictype, saveUnitDict, applications])

  return (
    <>
      <DictionaryLogModal dictype={dictype} open={logModalOpened} onCancel={() => toggleLogModal(false)} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
      >
        <div className="vstack mx-2 gap-2">
          <div className="hstack justify-content-end gap-2">
            <Button onClick={() => toggleLogModal(true)}>View logs</Button>
            <Button type="primary" loading={isSaving} htmlType="submit">Save</Button>
          </div>
          <div className="row">
            <div className="col-2">
              <CategoryList
                isLoading={isFetching}
                header="Category"
                items={Object.keys(dictUnits).map(item => ({
                  value: item,
                  label: item
                }))}
                selected={activeCategory}
                onChange={onChangeCategory}
              />
            </div>
            <div className="col-2">
              {activeCategory ? (
                <CategoryList
                  isLoading={isFetching}
                  header="Subcategory"
                  items={Object.keys(subCategories).map(item => ({
                    value: item,
                    label: snakeToCamel(item)
                  }))}
                  selected={activeSubCategory}
                  onChange={onChangeSubCategory}
                />
              ) : null}
            </div>
            <div className="col-2">
              {activeSubCategory ? (
                <CategoryList
                  isLoading={isFetching}
                  header="Element"
                  items={Object.values(subCategories[activeSubCategory]).map(item => ({
                    value: item.id,
                    label: item.full_name ?? '',
                    is_system: item.is_system
                  }))}
                  selected={activeElement}
                  onChange={setActiveElement}
                  onDelete={deleteElement}
                  footer={
                    <Popconfirm
                      icon={null}
                      title="New Element"
                      description={
                        <Form.Item label={<Typography>Element name</Typography>}>
                          <Input ref={newItemNameInput} autoFocus />
                        </Form.Item>
                      }
                      onConfirm={onAddNewElement}
                      destroyTooltipOnHide
                    >
                      <Button loading={isSaving} className="w-100">Add Element</Button>
                    </Popconfirm>
                  }
                />
              ) : null}
            </div>
            {activeElement ? (
              <>
                <div className="col-2">
                  <Card
                    loading={isFetching || isSaving}
                    title={<Typography.Title level={4} className={classes.text}>Unit and Range</Typography.Title>}
                    style={{
                      backgroundColor: 'var(--bs-body-bg)'
                    }}
                    bodyStyle={{
                      color: 'var(--bs-light)'
                    }}
                    headStyle={{
                      color: 'var(--bs-light)'
                    }}
                  >
                    <Form.Item name={'unit_field'} label="Field">
                      <AutoComplete options={UNITS.map(val => ({
                        value: val
                      }))} />
                    </Form.Item>
                    <Form.Item name={'min_field'} label="Min">
                      <InputNumber className="w-100" />
                    </Form.Item>
                    <Form.Item name={'max_field'} label="Max">
                      <InputNumber className="w-100" />
                    </Form.Item>
                    <Form.Item name={'unit_metric'} label="Metric">
                      <Input />
                    </Form.Item>
                    <Form.Item name={'min_metric'} label="Min">
                      <InputNumber className="w-100" />
                    </Form.Item>
                    <Form.Item name={'max_metric'} label="Max">
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Card>
                </div>
                <div className="col-4">
                  <Card
                    loading={isFetching || isSaving}
                    title={<Typography.Title level={4} className={classes.text}>Application</Typography.Title>}
                    style={{
                      backgroundColor: 'var(--bs-body-bg)'
                    }}
                    bodyStyle={{
                      color: 'var(--bs-light)'
                    }}
                    headStyle={{
                      color: 'var(--bs-light)'
                    }}
                  >
                    {activeElementData?.is_system && activeElementDefaultApps.length ? (
                      <div className="vstack gap-2 mb-2">
                        <Typography className="text-light fs-6">Default applications</Typography>
                        <div className="hstack gap-2 flex-wrap">
                          {activeElementDefaultApps.map(appName => (
                            <Tag key={appName} color="#aaa">{snakeToCamel(appName.replace('dictionary_', ''))}</Tag>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    <Form.Item name="applications" label="Add new applications">
                      <Select
                        mode="multiple"
                        showSearch
                        filterOption={filterOption}
                        options={applications.map(appName => ({
                          value: appName,
                          label: snakeToCamel(appName.replace('dictionary_', ''))
                        }))}
                      />
                    </Form.Item>
                  </Card>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </Form>
    </>
  )
}

export default DictionaryEditor