import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectActiveWells } from "features/asset/assetSelectorSlice";
import {
  selectSelectedWellIds,
  selectGridParams,
  selectUsedWellInterpoParams,
  // setActiveMapUsedParam,
  selectShowWellSettingsWin,
  setShowWellSettingsWin,
} from "features/well_planner/plannerSlice";
import { getMapObjectivesFeatures } from "./map";

import LMap from "./LMap";
import { WellSettings } from "./settings/WellSettings";

import CheckIcon from "@mui/icons-material/Check";
import SectionSetting from "components/common/SectionSetting";

const zoom = 15;

function PlannerMap(props) {
  const { loadingIconActions } = props;

  const activeWells = useSelector(selectActiveWells);
  const selectedWellIds = useSelector(selectSelectedWellIds);
  const gridParams = useSelector(selectGridParams);
  const usedWellInterpoParams = useSelector(selectUsedWellInterpoParams);
  const showWellSettingsWin = useSelector(selectShowWellSettingsWin);

  const dispatch = useDispatch();

  const [interpoParams, setInterpoParams] = useState([]);
  const [activeParamIndex, setActiveParamIndex] = useState(null);

  const [objectives, setObjectives] = useState(null);
  // const [zoom] = useState(15);
  const [center, setCenter] = useState([0.0, 46.036]);
  const [bounds, setBounds] = useState(null);

  const [pickMode, setPickMode] = useState(false);

  const selectedWells = useMemo(() => {
    let selectedWells = [];
    if (
      selectedWellIds != null &&
      selectedWellIds.length > 0 &&
      activeWells != null &&
      activeWells.length > 0
    ) {
      let wellIdsSet = new Set(selectedWellIds);
      selectedWells = activeWells.filter((well) => wellIdsSet.has(well.uwi));
    }
    return selectedWells;
  }, [activeWells, selectedWellIds]);

  const interpolateParam = useMemo(() => {
    if (
      interpoParams == null ||
      !interpoParams.length === 0 ||
      activeParamIndex == null
    )
      return null;
    return interpoParams[activeParamIndex];
  }, [activeParamIndex, interpoParams]);

  useEffect(() => {
    if (selectedWells == null || selectedWells.length === 0) {
      setObjectives(null);
      return;
    }

    const [mapObjs, mapCenter, mapBounds] =
      getMapObjectivesFeatures(selectedWells);

    setCenter(mapCenter);
    setBounds(mapBounds);
    setObjectives(mapObjs);
  }, [selectedWells]);

  useEffect(() => {
    if (usedWellInterpoParams == null || usedWellInterpoParams.length === 0) {
      return;
    }

    let params = usedWellInterpoParams.map((item) => {
      return item.param;
    });
    setInterpoParams(params);
    setActiveParamIndex(0);
    // dispatch(setActiveMapUsedParam(params[0]));
  }, [usedWellInterpoParams, dispatch]);

  function handleParamChanged(e) {
    let value = e.target.value;
    setActiveParamIndex(value);
    // dispatch(setActiveMapUsedParam(interpoParams[value]));
  }

  const usedInterpoParamsSelector = useMemo(() => {
    if (interpoParams == null || interpoParams.length === 0) {
      return null;
    }

    return interpoParams.map((param, index) => {
      return (
        <option key={index} value={index}>
          {param}
        </option>
      );
    });
  }, [interpoParams]);

  let className = "popup-window-no-overlay top-right";
  if (showWellSettingsWin === false) {
    className += " visually-hidden";
  }

  return (
    <>
      {
        <div className={className}>
          <div className="popup-wrapper">
            <WellSettings
              close={() => {
                dispatch(setShowWellSettingsWin(false));
              }}
              loadingIconActions={loadingIconActions}
            />
          </div>
        </div>
      }
      <div className="cards-container h-100">
        <div className="cards-header d-flex flex-row justify-content-between align-items-center">
          <div className="header-title">Well Placing</div>
          <div className="header-menu-container d-flex flex-row">
            <button
              className="btn-custom me-2"
              onClick={(e) => {
                dispatch(setShowWellSettingsWin(true));
              }}
              disabled={selectedWells.length < 5}
              style={{ color: selectedWells.length < 5 ? "grey" : "" }}
            >
              Grid & Base
            </button>
            <div>
              <select
                name="interpoParam"
                value={activeParamIndex ? activeParamIndex : 0}
                onChange={handleParamChanged}
              >
                {usedInterpoParamsSelector}
              </select>
            </div>
            <div className="dropdown">
              <div
                className=""
                data-bs-toggle="dropdown"
                type="button"
                id="planerMapSettingDropDown1"
              >
                <div className="cards-setting-btn ms-2">
                  <SectionSetting />
                </div>
              </div>
              <ul
                className="dropdown-menu"
                aria-labelledby="planerMapSettingDropDown1"
              >
                <li>
                  <div className="d-flex justify-content-between align-items-center">
                    <button
                      type="button"
                      className="dropdown-item d-flex flex-row justify-content-between align-items-center"
                      onClick={(e) => {
                        setPickMode(!pickMode);
                        e.preventDefault();
                      }}
                    >
                      <span>
                        {" "}
                        Pick Location{" "}
                        {pickMode && (
                          <span className="ms-2">
                            {" "}
                            <CheckIcon style={{ color: "black" }} />{" "}
                          </span>
                        )}
                      </span>
                    </button>
                  </div>
                </li>
                <li>
                  <button type="button" className="dropdown-item">
                    <span>Edit</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="cards-content">
          <div className="chart-container">
            <LMap
              objectives={objectives ? objectives : []}
              center={center}
              zoom={zoom}
              bounds={bounds}
              pickMode={pickMode}
              grid_params={gridParams}
              objectiveSelectedHandler={() => { }}
              selectRed={false}
              interpolateParam={interpolateParam}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlannerMap;
