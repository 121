import SectionSetting from "components/common/SectionSetting";
import WellSummaryTable from "./WellSummaryTable";

function WellSummary(props) {
  const { assetId, wellId } = props;

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Well Summary</span>
        <div className="header-menu-container d-flex flex-row">
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>

      <div className="cards-content">
        <div className="table-container overflow-auto hide-scrollbar">
          <WellSummaryTable assetId={assetId} wellId={wellId} />
        </div>
      </div>
    </div>
  );
}

export { WellSummary };
