import { useEffect, useState } from 'react';
import { useMap } from "react-leaflet";
import L from "leaflet";

const SelectArea = (props) => {
    const map = useMap();

    useEffect(() => {
        if (!map.selectArea) return;

        map.selectArea.enable();
        map.on("areaselected", (e) => {
            let selectedObjectives = props.Objectives.filter((objective) => {
                const objLatLng = L.latLng(objective.Latitude, objective.Longitude);
                return e.bounds.contains(objLatLng);
            });
            props.selectedObjectivesHandler(selectedObjectives);
        });

        const bounds = map.getBounds().pad(1);
        map.selectArea.setValidate((layerPoint) => {
            return bounds.contains(this._map.layerPointToLatLng(layerPoint));
        });
        map.selectArea.setValidate();
    }, [props, map]);

    return null;
}

export default SelectArea;
