import { Card, Dropdown, Typography } from 'antd'
import React, { useCallback } from 'react'
import { Folder, MoreVert } from '@mui/icons-material'
import { formatDate } from 'utils/dateUtil'
import { IconButton } from '@mui/material'

import classes from './ProjectListItem.module.scss'
import { useNavigate } from 'react-router-dom'
import { deleteCOProject } from 'service/completionOptimizer'

const ProjectListItem = ({
  project,
  onDeleted
}) => {
  const navigate = useNavigate()

  const onDelete = useCallback(async () => {
    await deleteCOProject(project.id)
    onDeleted()
  }, [onDeleted, project.id])

  const onEdit = useCallback(() => {
    navigate(`/completion_optimizer/project_settings?projectId=${ project.id }&backupId=${ project.backupId }`)
  }, [navigate, project.backupId, project.id])

  return (
    <div className="col-2">
      <Card
        className={classes.projectItemCard}
        bordered={false}
        extra={
          <Dropdown
            menu={{
              items: [{
                label: 'Edit',
                key: 'edit',
                onClick: onEdit
              }, {
                label: 'Delete',
                key: 'delete',
                onClick: onDelete
              }]
            }}
            trigger={['click']}
            placement="bottomRight"
          >
            <IconButton className={classes.moreBtn}><MoreVert /></IconButton>
          </Dropdown>
        }
        headStyle={{
          border: 'none',
          minHeight: 'unset',
          padding: 0
        }}
      >
        <div className="vstack gap-4">
          <div onClick={onEdit} role="button" className="vstack justify-content-center align-items-center">
            <Folder color="primary" className={classes.folderIcon} />
            <Typography.Text>{project.name}</Typography.Text>
          </div>
          <div className="vstack">
            <Typography.Text>Type: {project.type}</Typography.Text>
            <Typography.Text>Last Updated: {formatDate(project.updated_date, 'MM/dd/yyyy HH:mm:ss')}</Typography.Text>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ProjectListItem