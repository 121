import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUserData } from "features/user/userSlice";

function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  let dispatch = useDispatch();
  let navigate = useNavigate();
  function handleRegister() {
    const data = {
      username,
      password,
      phone,
      email,
      first_name,
      last_name,
    };
    console.log(data);
    axios.post("/api/accounts/register/", data).then((res) => {
      dispatch(setUserData(res.data));
      navigate("/asset-viewer/", { replace: true });
    });
  }
  return (
    <div className="authentication-bg">
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <a href="/" className="mb-5 d-block auth-logo">
                  <img
                    src="/static/images/logo-text2.png"
                    alt=""
                    height="22"
                    className="logo"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Sign Up Account</h5>
                    <p className="text-muted">
                      Get your free Minible account now.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleRegister();
                      }}
                      className="needs-validation"
                      id="signup_form"
                      method="POST"
                      action="/account/signup/"
                    >
                      <div id="div_id_email" className="form-group">
                        {" "}
                        <label htmlFor="email" className=" requiredField">
                          First Name<span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                            value={first_name}
                            type="text"
                            className="form-control mb-1 emailinput"
                            placeholder="Enter First Name"
                            id="email"
                            required
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                      <div id="div_id_email" className="form-group">
                        {" "}
                        <label htmlFor="email" className=" requiredField">
                          Last Name<span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                            value={last_name}
                            type="text"
                            className="form-control mb-1 emailinput"
                            placeholder="Enter Last Name"
                            id="email"
                            required
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                      <div id="div_id_email" className="form-group">
                        {" "}
                        <label htmlFor="email" className=" requiredField">
                          Email<span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            value={email}
                            type="email"
                            className="form-control mb-1 emailinput"
                            placeholder="Enter Email"
                            id="email"
                            required
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                      <div id="div_id_username" className="form-group">
                        {" "}
                        <label htmlFor="username1" className=" requiredField">
                          Username<span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                            value={username}
                            type="text"
                            className="form-control mb-1 textinput textInput"
                            placeholder="Enter Username"
                            id="username1"
                            required
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                      <div id="div_id_username" className="form-group">
                        {" "}
                        <label htmlFor="username1" className=" requiredField">
                          Phone<span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                            value={phone}
                            type="number"
                            className="form-control mb-1 textinput textInput"
                            placeholder="Enter Phone"
                            id="username1"
                            required
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                      <div id="div_id_password1" className="form-group">
                        {" "}
                        <label htmlFor="password1" className=" requiredField">
                          Password<span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            value={password}
                            type="password"
                            className="form-control mb-1 textinput textInput"
                            placeholder="Enter Password"
                            id="password1"
                            required
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                      <div id="div_id_password2" className="form-group">
                        {" "}
                        <label htmlFor="password2" className=" requiredField">
                          Confirm Password
                          <span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            onChange={(e) => {
                              setPasswordConfirmation(e.target.value);
                            }}
                            value={passwordConfirmation}
                            type="password"
                            className="form-control mb-1 textinput textInput"
                            placeholder="Enter Confirm Password"
                            id="password2"
                            required
                          />{" "}
                        </div>{" "}
                      </div>
                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <div className="signin-other-title">
                          <h5 className="mb-3 title">
                            Sign up using
                          </h5>
                        </div>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              href="/account/facebook/login/"
                              className="social-list-item bg-primary text-white border-primary"
                            >
                              <i className="mdi mdi-facebook"></i>
                            </a>
                          </li>

                          <li className="list-inline-item">
                            <a
                              href="/account/google/login/"
                              className="social-list-item bg-danger text-white border-danger"
                            >
                              <i className="mdi mdi-google"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="text-muted mb-0">
                          Already have an account ?{" "}
                          <Link to="/login/" className="fw-medium text-primary">
                            {" "}
                            Login
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="mt-5 text-center">
                <p>
                  © <script>document.write(new Date().getFullYear())</script>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
