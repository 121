export const RECORD_FIELDS_MAP = {
  date: { name: 'Date' },
  routine_direct_expense: { name: 'Routine Direct Expense', unit: '$' },
  direct_expense: { name: 'Direct Expense', unit: '$' },
  indirect_expense: { name: 'Indirect Expense', unit: '$' },
  fixed_expense: { name: 'Fixed Expense', unit: '$' },
  variable_expense: { name: 'Variable Expense', unit: '$' },
  net_daily_prd_boe: { name: 'Net Daily PRD', unit: 'boe' },
  ad_val_tax_revenue_rev: { name: 'Ad Val Tax %/REV', unit: '%' },
  ad_val_tax_boe: { name: 'Ad Val Tax %/mcfe', unit: '$/boe' },
  serverance_taxes_boe: { name: 'Serverance Taxes', unit: '$/boe' },
  trans_processing_boe: { name: 'Trans Processing', unit: '$/boe' },
  workover_boe: { name: 'Workover', unit: '$/boe' },
  copas_overhead_boe: { name: 'Copas Overhead', unit: '$/boe' },
  direct_opex_boe: { name: 'Direct Opex', unit: '$/boe' },
  operating_income_boe: { name: 'Operating Income', unit: '$/boe' }
}