import { Card } from 'antd'
import omit from 'lodash/omit'
import React from 'react'

const FormSection = (props) => (
  <Card
    bodyStyle={{
      color: 'var(--bs-light)',
      backgroundColor: 'var(--bs-body-bg)',
      ...props.bodyStyle
    }}
    headStyle={{
      color: 'var(--bs-light)',
      backgroundColor: 'var(--bs-body-bg)',
      ...props.headStyle
    }}
    {...omit(props, 'bodyStyle', 'headStyle')}
  />
)

export default FormSection