import { Suspense, lazy } from "react";
import { Button, Modal } from "antd";
import Loading from "components/common/Loading";

import { CloseOutlined } from "@ant-design/icons";

const WellSummary = lazy(() => import('../WellSummary'));

const PopupWellSummary = (props) => {
    const { activeWell, onClose, showLoadingIcon, hideLoadingIcon } = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "800px",
            height: "600px",
            // display: visible ? 'block' : 'none',
            display: 'block',
            zIndex: 1001
        }}>
            <Button
                type="primary"
                shape="circle"
                icon={<CloseOutlined />}
                style={{
                    position: "absolute",
                    top: 4,
                    right: 6,
                    zIndex: 1
                }}
                onClick={handleClose}
            />
            <Suspense fallback={<Loading />}>
                <WellSummary
                    well_data={activeWell}
                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                />
            </Suspense>
        </div>
    );
};

export default PopupWellSummary;