import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setShowAddColumnPopup,
} from "features/input/dataManagerSlice";
import { CategoryOptions } from "./data";

function AddColumn(props) {
  const { inputColumnObj } = props;
  const dispatch = useDispatch();
  const [columnName, setColumnName] = useState(null);
  const [unit, setUnit] = useState(null);
  const [category, setCategory] = useState(null);

  function changeColumn(e) {
    let value = e.target.value;
    setColumnName(value);
  }

  function changeUnit(e) {
    let value = e.target.value;
    setUnit(value);
  }

  function categoryChange(e) {
    let value = e.target.value;
    setCategory(value);
  }

  function addColumn(e) {
    props.saveColumn({
      columnName: columnName,
      unit: unit,
      category: category
    });
  }

  return (
    <div className="popup-wrapper">
      <div className="d-flex flex-column">
        <div className="input-grid-3-4">
          <div></div>
          <div><span>Column Name</span></div>
          <div><span>Unit</span></div>
          <div><span>Category</span></div>

          <div><span>Input:</span></div>
          <div><span>{inputColumnObj ? inputColumnObj.columnName : ""}</span></div>
          <div><span>{inputColumnObj ? inputColumnObj.unit : ""}</span></div>
          <div></div>

          <label><span>New:</span></label>
          <input
            name="column_name"
            value={columnName ? columnName : ""}
            onChange={changeColumn}
          ></input>
          <input
            name="unit"
            value={unit ? unit : ""}
            onChange={changeUnit}
          ></input>
          <select name="category" onChange={categoryChange}>
            {CategoryOptions.map((item, index)=>{
              return (<option key={item.value} value={item.value}>{item.name}</option>)
            })}
          </select>

        </div>
        <div className="d-flex justify-content-between align-items-center p-4">
          <button className="btn-custom px-4 py-2" onClick={addColumn}>
            Add
          </button>
          <button
            className="btn-custom px-4 py-2"
            onClick={(e) => {
              dispatch(setShowAddColumnPopup(false));
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddColumn;
