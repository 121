import { ConfigProvider, Form, Tabs } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import ValueTable from './ValueTable'
import enUS from "antd/locale/en_US";

import classes from './ReservoirSetting.module.scss'

const ReservoirSetting = () => {
  const reservoirType = Form.useWatch(['GENERAL', 'reservoir'])

  const tabs = useMemo(
    /**
     * 
     * @returns {import('antd').TabsProps['items']}
     */
    () => [
      {
        key: 'matrix',
        label: 'Matrix',
        children: (
          <Form.List name={["RESERVOIR", "matrix"]}>
            {(fields, operation) => (
              <ValueTable tab="matrix" fields={fields} operation={operation} />
            )}
          </Form.List>
        )
      },
      ...(reservoirType === 'Singleperm' ? [] : [{
        key: 'fracture',
        label: 'Fracture',
        children: (
          <Form.List name={["RESERVOIR", "fracture"]}>
            {(fields, operation) => (
              <ValueTable tab="fracture" fields={fields} operation={operation} />
            )}
          </Form.List>
        )
      }])
    ]
    , [reservoirType])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      <Tabs type="card" items={tabs} className={classNames(classes.tabs, classes.parentTabs)} destroyInactiveTabPane={false} />
    </ConfigProvider>
  )
}

export default ReservoirSetting