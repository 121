import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  parseDevFile,
  parseTopFile,
  parseLasFile,
  parseDatFile,
  parsePrdFile,
  parseInjFile,
  parseEventsFile,
  parseSurveyFile,
} from "utils/inputFileParser";

import {
  submitPrdForm,
  submitInjForm,
  submitWellForm,
  submitWellLogForm,
  submitWellTopForm,
  submitWellDevForm,
  submitAlarmForm,
  submitEventsForm,
  submitPlanningForm,
  submitSurveyForm,
} from "service/dataInput";

import {
  selectPrdDataStructure,
  selectInjDataStructure,
  selectDatDataStructure,
  selectLasDataStructure,
  selectTopDataStructure,
  selectDevDataStructure,
  selectAlarmDataStructure,
  selectEventsDataStructure,
  selectPlanningDataStructure,
  selectSurveyDataStructure,
  setLasDataStructure,
  setDatDataStructure,
  setPrdDataStructure,
  setInjDataStructure,
  setDevDataStructure,
  setTopDataStructure,
  setSurveyDataStructure,
  setAlarmDataStructure,
  setEventsDataStructure,
  setPlanningDataStructure,
} from "features/input/dataManagerSlice";

import ProductionInput from "./ProductionInput";
import WellDataInput from "./WellDataInput";
import WellLogsInput from "./WellLogsInput";
import IntroductionView from "./IntroductionView";
import { getLasWellInfo, getLasData } from "./data";
import TopsInput from "./TopsInput";
import DeviationInput from "./DeviationInput";
import AlarmDataInput from "./AlarmInput";
import PlanningDataInput from "./PlanningInput";
import EventsDataInput from "./EventsInput";
import WellSurveyInput from "./WellSurveyInput";
import InjectionInput from "./InjectionInput";

function PetroInputData(props) {
  const prdDataStructure = useSelector(selectPrdDataStructure);
  const injDataStructure = useSelector(selectInjDataStructure);
  const datDataStructure = useSelector(selectDatDataStructure);
  const lasDataStructure = useSelector(selectLasDataStructure);
  const topDataStructure = useSelector(selectTopDataStructure);
  const devDataStructure = useSelector(selectDevDataStructure);
  const alarmDataStructure = useSelector(selectAlarmDataStructure);
  const eventsDataStructure = useSelector(selectEventsDataStructure);
  const planningDataStructure = useSelector(selectPlanningDataStructure);
  const surveyDataStructure = useSelector(selectSurveyDataStructure);
  const dispatch = useDispatch();

  const [activeFileType, setActiveFileType] = useState("-1");
  const [processing, setProcessing] = useState(false);
  const [prdData, setPrdData] = useState(null);
  const [injData, setInjData] = useState(null);
  const [wellData, setWellData] = useState(null);
  const [lasData, setLasData] = useState(null);
  const [topData, setTopData] = useState(null);
  const [devData, setDevData] = useState(null);
  const [eventsData, setEventsData] = useState(null);
  const [surveyData, setSurveyData] = useState(null);

  const OPTIONS = [
    {
      name: "Log file",
      value: "las",
      handler: function (fileHandles) {
        parseLasFile(fileHandles[0], (result) => {
          onParseLasFinished(result);
        });
      },
      submit: () => {
        let skipUnknownColumnData = lasDataStructure.filter(
          (el) => el.log_type !== "N/A"
        );
        let logsData = getLasData(lasData);
        let skipUnknownColumnLasData = logsData.map((el) => {
          return el.filter(
            (item, index) =>
              lasDataStructure[index].log_type !== "N/A"
          );
        });
        submitWellLogForm(
          getLasWellInfo(lasData),
          skipUnknownColumnLasData,
          skipUnknownColumnData,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setLasData(null);
              dispatch(setLasDataStructure(null));
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setLasData(null);
              dispatch(setLasDataStructure(null));
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Well head",
      value: "dat",
      handler: function (fileHandles) {
        parseDatFile(fileHandles[0], (result) => {
          onParseDatFinished(result);
        });
      },
      // callback: onParseDatFinished,
      submit: () => {
        submitWellForm(
          wellData.data,
          datDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setWellData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setWellData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Top file",
      value: "top",
      handler: function (fileHandles) {
        parseTopFile(fileHandles[0], (result) => {
          onParseTopFinished(result);
        });
      },
      submit: () => {
        submitWellTopForm(
          topData.data,
          topDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setTopData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setTopData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Well deviation",
      value: "dev",
      handler: function (fileHandles) {
        parseDevFile(fileHandles, (result) => {
          onParseDevFinished(result);
        });
      },
      submit: function () {
        submitWellDevForm(
          devData,
          devDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setDevData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setDevData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Injection",
      value: "inj",
      handler: function (fileHandles) {
        parsePrdFile(fileHandles[0], (result) => {
          onParseInjFinished(result);
        });
      },
      callback: null,
      submit: function () {
        submitInjForm(
          injData.data,
          injDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setInjData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setInjData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Production",
      value: "prd",
      handler: function (fileHandles) {
        parsePrdFile(fileHandles[0], (result) => {
          onParsePrdFinished(result);
        });
      },
      submit: function () {
        submitPrdForm(
          prdData.data,
          prdDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setPrdData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setPrdData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Alarm",
      value: "alarm",
      handler: function (fileHandles) {
        parseEventsFile(fileHandles[0], (result) => {
          onParseEventsFinished(result);
        });
      },
      callback: null,
      submit: function () {
        submitAlarmForm(
          eventsData,
          alarmDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setEventsData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setEventsData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Operation",
      value: "schedule",
      handler: function (fileHandles) {
        parseEventsFile(fileHandles[0], (result) => {
          onParseEventsFinished(result);
        });
      },
      callback: null,
      submit: function () {
        submitPlanningForm(
          eventsData,
          planningDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setEventsData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setEventsData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Event",
      value: "event",
      handler: function (fileHandles) {
        parseEventsFile(fileHandles[0], (result) => {
          onParseEventsFinished(result);
        });
      },
      callback: null,
      submit: function () {
        submitEventsForm(
          eventsData,
          eventsDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setEventsData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setEventsData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
    {
      name: "Survey",
      value: "survey",
      handler: function (fileHandles) {
        parseSurveyFile(fileHandles[0], (result) => {
          onParseSurveyFinished(result);
        });
      },
      callback: null,
      submit: function () {
        submitSurveyForm(
          surveyData,
          surveyDataStructure,
          (response) => {
            onSubmitSuccess(response, (response) => {
              setSurveyData(null);
            });
          },
          (error) => {
            onSubmitFailed(error, (error) => {
              setSurveyData(null);
              console.error("error", error);
            });
          }
        );
      },
    },
  ];

  function onParseDatFinished(result) {
    setWellData(result);
    setProcessing(false);
  }

  function onParseLasFinished(result) {
    setLasData(result);
    setProcessing(false);
  }

  function onParseTopFinished(result) {
    setProcessing(false);
    setTopData(result);
  }

  function onParseDevFinished(result) {
    setDevData(result);
    setProcessing(false);
  }

  function onParsePrdFinished(result) {
    setPrdData(result);
    setProcessing(false);
  }

  function onParseInjFinished(result) {
    setInjData(result);
    setProcessing(false);
  }

  function onParseEventsFinished(result) {
    setEventsData(result);
    setProcessing(false);
  }

  function onParseSurveyFinished(result) {
    setSurveyData(result);
    setProcessing(false);
  }

  function processFile(fileHandles) {
    let activeOption = null;
    for (const option of OPTIONS) {
      if (option.value === activeFileType) {
        activeOption = option;
        break;
      }
    }
    if (activeOption && activeOption.handler) {
      setProcessing(true);
      activeOption.handler(fileHandles);
    }
  }

  function handleChangeFileType(e) {
    const fileChosen = e.target.value;
    if (fileChosen !== activeFileType) {
      document.getElementById("fileTarget").value = null;
      setActiveFileType(fileChosen);
    }
  }

  function handleChooseFile(e) {
    if (e.target.files.length > 0) {
      processFile(e.target.files);
    }
  }

  function handleUpload(e) {
    e.preventDefault();
    setProcessing(true);
    for (const option of OPTIONS) {
      if (activeFileType === option.value) {
        option.submit && option.submit();
      }
    }
  }

  function resetMemory(e) {
    setActiveFileType(null);
    setDevData(null);
    setTopData(null);
    setPrdData(null);
    setLasData(null);
    setWellData(null);
    setProcessing(false);
  }

  function onSubmitSuccess(response, handler) {
    setTimeout(() => {
      handler(response);
      setActiveFileType(null);
      setProcessing(false);
    }, 500);
  }

  function onSubmitFailed(error, handler) {
    setTimeout(() => {
      handler(error);
      setActiveFileType(null);
      setProcessing(false);
    }, 500);
  }

  function handleResetMemory(e) {
    setPrdData(null);
    setActiveFileType(null);
  }

  return (
    <>
      <div className="page-content">
        <div className="cards-container overflow-hidden h-100">
          <div className="cards-header d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-center">
              <select
                onChange={handleChangeFileType}
                value={activeFileType !== null ? activeFileType : "-1"}
              >
                <option key="empty" value="-1">
                  Select File Type
                </option>
                {OPTIONS.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.name} - *.{item.value}
                    </option>
                  );
                })}
              </select>
              <div className="d-flex flex-row align-items-center me-2">
                <input
                  className="file_chooser"
                  id="fileTarget"
                  type="file"
                  accept={activeFileType !== null ? `.${activeFileType}` : "*"}
                  onClick={(e) => {
                    if (activeFileType === null || activeFileType === "-1") {
                      e.preventDefault();
                    }
                  }}
                  multiple={activeFileType === "dev"}
                  onChange={(e) => {
                    handleChooseFile(e);
                  }}
                ></input>
                <label htmlFor="fileTarget" className="file_input_label">
                  Choose file
                </label>
              </div>
              <div className="d-flex flex-row align-items-center">
                <button className="btn-custom" onClick={handleUpload}>
                  Upload
                </button>
              </div>
              <div className="d-flex flex-row align-items-center">
                <button className="btn-custom" onClick={resetMemory}>
                  Reset
                </button>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center">
              {processing && (
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              )}
            </div>
            <div className="d-flex flex-row align-items-center">
              <button className="btn-custom me-2" onClick={handleResetMemory}>
                Reset Memory
              </button>
              <div className="d-flex">
                <span>
                  {activeFileType === null || activeFileType === "-1"
                    ? "LAS,TOP, DEV, INJ, ALARM, SCHEDULE, PRD, EVENT Files are accepted"
                    : `${activeFileType} file`}
                </span>
              </div>
            </div>
          </div>
          <div className="input-container h-100 overflow-auto">
            {activeFileType === "prd" && (
              <ProductionInput inputData={prdData} />
            )}
            {activeFileType === "inj" && (
              <InjectionInput inputData={injData} />
            )}
            {activeFileType === "dat" && <WellDataInput inputData={wellData} />}
            {activeFileType === "las" && <WellLogsInput inputData={lasData} />}
            {activeFileType === "top" && <TopsInput inputData={topData} />}
            {activeFileType === "dev" && <DeviationInput inputData={devData} />}
            {activeFileType === "alarm" && (
              <AlarmDataInput inputData={eventsData} />
            )}
            {activeFileType === "schedule" && (
              <PlanningDataInput inputData={eventsData} />
            )}
            {activeFileType === "event" && (
              <EventsDataInput inputData={eventsData} />
            )}
            {activeFileType === "survey" && (
              <WellSurveyInput inputData={surveyData} />
            )}
            {(activeFileType === null || activeFileType === "-1") && (
              <IntroductionView />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PetroInputData;
