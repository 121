import { useCallback } from "react";
import { ConfigProvider, Spin } from "antd";
import { useParams } from "react-router-dom";
import enUS from "antd/locale/en_US";
import {
	getProductions2,
	removeProductions,
	saveProductions,
} from "service/productionData";
import EditorTable from "../component/EditorTable";
import useLoading from "components/common/useLoading";

const productionColumns = [
	{
		title: "Well Name",
		dataIndex: "well_name",
		key: "well_name",
	},
	{
		title: "Date",
		dataIndex: "prd_date",
		key: "prd_date",
		sorter: (aRec, bRec) => (aRec.prd_date > bRec.prd_date ? 1 : -1),
	},
	{
		title: "Oil Rate",
		dataIndex: ["oil_prd_rate", "value"],
		// dataIndex: ["oil_prd_rate"],
		key: "oil_prd_rate",
	},
	{
		title: "Water Rate",
		dataIndex: ["water_prd_rate", "value"],
		// dataIndex: ["water_prd_rate"],
		key: "water_prd_rate",
	},
	{
		title: "Gas Rate",
		dataIndex: ["gas_prd_rate", "value"],
		// dataIndex: ["gas_prd_rate"],
		key: "gas_prd_rate",
	},
	{
		title: "Bottomhole Press",
		dataIndex: ["bottomhole_flow_pressure", "value"],
		// dataIndex: ["bottomhole_flow_pressure"],
		key: "bottomhole_flow_pressure",
	},
	{
		title: "Avg Htp",
		dataIndex: ["avg_htp", "value"],
		// dataIndex: ["avg_htp"],
		key: "avg_htp",
	},
	{
		title: "Tubing Pressure",
		dataIndex: ["wellhead_tubing_pressure", "value"],
		// dataIndex: ["wellhead_tubing_pressure"],
		key: "wellhead_tubing_pressure",
	},
	{
		title: "Casing Pressure",
		dataIndex: ["wellhead_casing_pressure", "value"],
		// dataIndex: ["wellhead_casing_pressure"],
		key: "wellhead_casing_pressure",
	},
	{
		title: "Dayson",
		dataIndex: ["dayson", "value"],
		// dataIndex: ["dayson"],
		key: "dayson",
	},
	{
		title: "Fluid Level",
		dataIndex: ["fluid_level", "value"],
		// dataIndex: ["fluid_level"],
		key: "fluid_level",
	},
];

function ProductionEditor3(props) {
	const { uwi } = useParams();
	const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(
		<Spin size="large" />,
		50
	);

	const fetcher = useCallback((uwi, succeed, fail) => {
		getProductions2(
			{ uwis: uwi },
			(...values) => {
				succeed(...values);
			},
			(...values) => {
				fail(...values);
			}
		);
	}, []);

  const buildQuery = useCallback(() => {
    return uwi;
  }, [uwi]);

	return (
		<div style={{ height: "91vh", overflowY: "auto" }}>
			<LoadingIcon />
			<ConfigProvider
				locale={enUS}
				componentSize="middle"
				theme={{
					token: {},
					components: {},
				}}
			>
				<EditorTable
					columns={productionColumns || []}
					title="Well Production"
					// uwi={uwi}
					// allowNullUwi={false}
					showNewRow={() => false}
					dataFetcher={[
						buildQuery,
						fetcher,
						(data) => {
							let result = [];
							const { productions, wells } = data;
							const recordsMap = new Map();
							const well_name = wells.find(
								(item) => item.uwi === uwi
							)?.well_name;
							const wellProductions = productions[uwi];
							for (let prd_index of Object.keys(
								wellProductions
							)) {
								const productions = wellProductions[prd_index];
								for (const dataItem of productions) {
									const [prd_date, record_id, prd_value] =
										dataItem;
									if (!recordsMap.has(prd_date)) {
										let record = {
											uwi: uwi,
											well_name: well_name,
											prd_date: prd_date,
										};
										recordsMap.set(prd_date, record);
									}
									let record = recordsMap.get(prd_date);
									record[prd_index] = {
										id: record_id,
										value: prd_value || 0,
									};
								}
							}
							result = Array.from(recordsMap.values());
							return result.map((item, index) => {
								return {
									...item,
									id: index,
								};
							});
						},
					]}
					dataSaver={[
						saveProductions,
						(rec, row) => {
							const record = { ...rec };
							const { prd_date } = record;
							delete record.uwi;
							delete record.prd_date;
							delete record.id;
							delete record.well_name;
							let result = {
								uwi,
								prd_date,
								data: {},
							};
							Object.keys(record)
								.filter((key) => key !== "index")
								.map((key) => {
									let keyValue = {
										value: parseInt(record[key]),
									};
									row[key] &&
										(keyValue["id"] = row[key]["id"]);
									result.data[key] = keyValue;
									rec[key] = keyValue;
									return null;
								});
							return [result];
						},
					]}
					dataRemover={[
						removeProductions,
						(record, originData) => {
							const { uwi, prd_date } = record;
							let result = {
								data: [
									{
										uwi,
										date: prd_date,
										data: Object.keys(record).filter(
											(item) =>
												![
													"uwi",
													"prd_date",
													"id",
													"well_name",
												].includes(item)
										),
									},
								],
							};
							return result;
						},
					]}
					loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
				/>
			</ConfigProvider>
		</div>
	);
}

export default ProductionEditor3;
