import { useState } from "react";

import { HistoricalChart } from "./HistoricalChart";
import { PrescriptiveChart } from "./PrescriptiveChart";

function AssetHistoricalPanel(props) {
  const { wellId, assetId, loadingIconActions } = props;
  const [historicalVisible, setHistoricalVisible] = useState(true);

  if (historicalVisible) {
    return (
      <HistoricalChart
        wellId={wellId}
        assetId={assetId}
        historicalVisible={historicalVisible}
        setHistoricalVisible={setHistoricalVisible}
        loadingIconActions={loadingIconActions}
      />
    );
  } else {
    return (
      <PrescriptiveChart
        wellId={wellId}
        assetId={assetId}
        historicalVisible={historicalVisible}
        setHistoricalVisible={setHistoricalVisible}
        loadingIconActions={loadingIconActions}
      />
    );
  }
}

export { AssetHistoricalPanel };
