import { EditableProTable } from '@ant-design/pro-components'
import { Button, Form, Typography } from 'antd'
import FormSection from 'components/form-section'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import classes from './EnumerationTable.module.scss'
import { isNumeric } from 'utils/string'
import { isNil } from 'lodash'

const units = {
  PRESSURE: {
    FIELD: '(psia)',
    METRIC: '(bar)'
  },
  RS: {
    FIELD: '(Mscf/STB)',
    METRIC: '(m3/m3)'
  }
}

const EnumerationTable = ({ fields }) => {
  const form = Form.useFormInstance()

  const simulationUnit = Form.useWatch(["GENERAL", "unit"])

  const [data, setData] = useState([])
  const [errors, setErrors] = useState([])

  const fetchData = () => {
    setData(fields.map((field, index) => ({
      ...form.getFieldValue(["INITIAL", field.name]),
      key: index
    })))
  }

  useEffect(() => {
    validateData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(
    /**
     * 
     * @returns {import('@ant-design/pro-components').ProColumnType[]}
     */
    () => [
      {
        key: 'REGION',
        dataIndex: 'REGION',
        title: 'Region',
        editable: false
      },
      {
        key: 'PRESSURE',
        dataIndex: 'PRESSURE',
        title: `Pressure ${ units.PRESSURE[simulationUnit] ?? '' }`,
        editable: true,
      },
      {
        key: 'SATGAS',
        dataIndex: 'SATGAS',
        title: 'SatGas',
        editable: true,
      },
      {
        key: 'SATWATER',
        dataIndex: 'SATWATER',
        title: 'SatWater',
        editable: true,
      },
      {
        key: 'RS',
        dataIndex: 'RS',
        title: `Rs ${ units.RS[simulationUnit] ?? '' }`,
        editable: true,
      },
      {
        title: "Operation",
        valueType: "option",
        key: "operation",
        width: 160,
        render: (text, record, index, action) => [
          <Button
            key="editable"
            size="small"
            onClick={() => {
              action?.startEditable?.(record.REGION);
            }}
          >
            Edit
          </Button>
        ],
      },
    ]
    , [simulationUnit])

  const validateData = useCallback((_data = []) => {
    setErrors([])

    const _errors = []

    const isRowEmpty = (row) => isNil(row.RS) && isNil(row.PRESSURE) && isNil(row.SATGAS) && isNil(row.SATWATER)

    if (_data.every(isRowEmpty)) return;

    _data.filter((row) => !isRowEmpty(row)).forEach((row, index) => {
      if (!isNumeric(row.RS)) {
        _errors.push(`Value of RS in row #${ index + 1 } must be a number`)
      } else {
        const rs = parseFloat(row.RS)
        if (rs < 0) {
          _errors.push(`Value of RS in row #${ index + 1 } must be >= 0`)
        }
      }
      if (!isNumeric(row.PRESSURE)) {
        _errors.push(`Value of Pressure in row #${ index + 1 } must be a number`)
      } else {
        const pressure = parseFloat(row.PRESSURE)
        if (pressure < 0) {
          _errors.push(`Value of Pressure in row #${ index + 1 } must be >= 0`)
        }
      }
      if (!isNumeric(row.SATGAS)) {
        _errors.push(`Value of SatGas in row #${ index + 1 } must be a number`)
      } else {
        const satgas = parseFloat(row.SATGAS)
        if (satgas < 0) {
          _errors.push(`Value of SatGas in row #${ index + 1 } must be >= 0`)
        }
      }
      if (!isNumeric(row.SATWATER)) {
        _errors.push(`Value of SatWater in row #${ index + 1 } must be a number`)
      } else {
        const satwater = parseFloat(row.SATWATER)
        if (satwater < 0) {
          _errors.push(`Value of SatWater in row #${ index + 1 } must be >= 0`)
        }
      }
    })

    setErrors(_errors)
  }, [])

  return (
    <FormSection title="Enumeration">
      <EditableProTable
        options={false}
        pagination={false}
        search={false}
        columns={columns}
        value={data}
        rowKey="REGION"
        className={classes.tableContainer}
        cardProps={{
          className: classes.card
        }}
        scroll={{
          y: 380
        }}
        tableClassName={classes.table}
        tableExtraRender={() => (
          <ul className="px-24 text-danger">
            {errors.map(errMsg => (
              <li key={errMsg}>
                <Typography.Text type="danger">{errMsg}</Typography.Text>
              </li>
            ))}
          </ul>
        )}
        editable={{
          type: 'multiple',
          actionRender: (row, config, defaultDom) => [
            defaultDom.save,
            defaultDom.cancel,
          ],
          onSave: (rowKey, data, row) => {
            const { index, ...updatedRow } = data
            form.setFieldValue(["INITIAL", index], updatedRow)
            fetchData()
          }
        }}
        recordCreatorProps={{
          style: {
            display: 'none'
          }
        }}
      />
    </FormSection>
  )
}

export default EnumerationTable