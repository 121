import React, { useState } from "react";

import { AssetSummary } from "../tables/AssetSummary";
import { AssetMap } from "../charts/AssetMap";
import { AssetHistoricalPanel } from "../charts/AssetHistoricalPanel";
import { WellSummary } from "../tables/WellSummary";
import EventLog from "../tables/EventLog";
import Planning from "../tables/AssetPlanning";
import WellArchitect from "pages/components/WellArchitect";
import useLoading from "components/common/useLoading";
import { Spin } from "antd";

function WellView(props) {
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(
    <Spin size="large" />,
    50
  );
  const [activeAssetId, setActiveAssetId] = useState(null);
  const [wellId, setwellId] = useState(null);

  function bubbleSelected(assetId, wellId) {
    setActiveAssetId(assetId);
    setwellId(wellId);
  }
  return (
    <div className="page-content hide-scrollbar">
      <LoadingIcon />
      <AssetSummary
        wellId={wellId}
        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
      />
      <div className="row row-wrapper mt-2 mb-2 g-0">
        <div className="col pe-1">
          <AssetMap
            showWellSelects={true}
            assetSelected={setActiveAssetId}
            bubbleSelected={bubbleSelected}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
        <div className="col ps-1">
          <AssetHistoricalPanel
            wellId={wellId}
            assetId={activeAssetId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
      </div>
      <div className="row row-wrapper mb-2 g-0">
        <div className="col pe-1">
          <WellSummary
            assetId={activeAssetId}
            wellId={wellId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
        <div className="col ps-1">
          <EventLog
            wellId={wellId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
      </div>
      <div className="d-flex row-wrapper g-0">
        <div className="w-50 pe-1">
          <WellArchitect
            wellId={wellId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
        <div className="w-50 ps-1">
          <Planning
            wellId={wellId}
            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
          />
        </div>
      </div>
    </div>
  );
}

export default WellView;
