import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectSelectedWellIds,
  setGridParams,
  selectGridParams,
  selectDataType,
  setWellGridProperties,
  selectWellLatLng,
} from "features/well_planner/plannerSlice";

import {
  selectActiveProject,
  setShowNewProjectPopup,
} from "features/project/projectsSlice";
import {
  selectActiveAssetId,
  selectActiveWells,
} from "features/asset/assetSelectorSlice";

import { saveGridParams } from "service/wellPlanner";
import { getMapObjectivesFeatures } from "../map";
import { geoDistance2 } from "utils/geo";

import { DataOptions } from "app/codes";
import AddProjectForm from "components/project/AddProjectForm";
import { message } from "antd";

function UserDefinedGridSettings(props) {
  const { onFinish } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const currentProject = useSelector(selectActiveProject);

  const activeWells = useSelector(selectActiveWells);
  const selectedWellIds = useSelector(selectSelectedWellIds);

  const gridParams = useSelector(selectGridParams);
  const dataType = useSelector(selectDataType);
  const activeAssetId = useSelector(selectActiveAssetId);
  const wellLatLng = useSelector(selectWellLatLng);

  const dispatch = useDispatch();

  const [params, setParams] = useState({});

  const selectedWells = useMemo(() => {
    let selectedWells = [];
    if (
      selectedWellIds != null &&
      selectedWellIds.length > 0 &&
      activeWells != null &&
      activeWells.length > 0
    ) {
      let wellIdsSet = new Set(selectedWellIds);
      selectedWells = activeWells.filter((well) => wellIdsSet.has(well.uwi));
    }
    return selectedWells;
  }, [activeWells, selectedWellIds]);

  useEffect(() => {
    if (wellLatLng == null) return;
    let wellLatLngObj = wellLatLng;
    if (typeof wellLatLng === "string") {
      wellLatLngObj = JSON.parse(wellLatLng);
    }
    setParams((params) => ({ ...params, ...wellLatLngObj }));
  }, [wellLatLng]);

  const initParams = useCallback(() => {
    if (selectedWells == null || selectedWells.length === 0) {
      return;
    }
    const [, , bounds] = getMapObjectivesFeatures(selectedWells);
    let x0 = bounds[0][0];
    let y0 = bounds[0][1];

    let distance_x = geoDistance2(
      bounds[0][0],
      bounds[0][1],
      bounds[0][0],
      bounds[1][1],
      "F"
    );
    let distance_y = geoDistance2(
      bounds[0][0],
      bounds[0][1],
      bounds[1][0],
      bounds[0][1],
      "F"
    );

    let newParams = {
      x0: x0,
      y0: y0,
      distance_x: distance_x,
      distance_y: distance_y,
    };
    return newParams;
  }, [selectedWells]);

  useEffect(() => {
    let initialParams;
    if (gridParams == null || gridParams.grid_type !== DataOptions[1].value) {
      if (selectedWells) {
        initialParams = initParams();
        setParams((params) => ({ ...params, ...initialParams }));
      }
      return;
    }

    initialParams = {
      x0: gridParams.x0,
      y0: gridParams.y0,
      distance_x: gridParams.distance_x,
      distance_y: gridParams.distance_y,
      dx: gridParams.dx,
      dy: gridParams.dy,
      rotation: gridParams.rotation,
    };

    setParams((params) => ({ ...params, ...initialParams }));
  }, [gridParams, selectedWells, initParams]);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    let newParams = { ...params };
    let val = 0;
    if (value == null || value === "") {
      val = 0;
    } else {
      val = value;
    }
    if (name === "dx" || name === "dy") {
      val = parseInt(val);
    } else {
      val = parseFloat(val);
    }
    newParams[name] = val;
    setParams(newParams);
  }

  const innerGridParams = useCallback(
    (projectId) => {
      const newParams = {
        ...gridParams,
        grid_type: "user",
        ...params,
      };

      if (!newParams.rotation) newParams.rotation = 0;

      let assetId = null;
      if (dataType === "asset") {
        assetId = activeAssetId;
      }
      showLoadingIcon && showLoadingIcon();
      saveGridParams(
        projectId,
        assetId,
        selectedWellIds,
        [newParams],
        (res) => {
          dispatch(setWellGridProperties(null));
          dispatch(setGridParams(newParams));
          onFinish();
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [
      activeAssetId,
      dataType,
      dispatch,
      gridParams,
      hideLoadingIcon,
      onFinish,
      params,
      selectedWellIds,
      showLoadingIcon,
    ]
  );

  function applyGridParams() {
    const { x0, y0, distance_x, distance_y, dx, dy } = params;
    if (!x0 || !y0 || !distance_x || !distance_y || !dx || !dy) {
      message.error("Please fill all fields!");
      return;
    }
    if (currentProject == null) {
      dispatch(setShowNewProjectPopup(true));
      return;
    }

    innerGridParams(currentProject.id);
  }

  return (
    <>
      <AddProjectForm
        input={{
          project_type: "well_planner",
          project_name: `PRJ_${new Date().getTime()}`,
          comment: "comment",
        }}
        callbackFunction={[innerGridParams, null]}
        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
      />
      <div className="input-grid-2-4">
        <div className="py-2">
          <label>
            X0
            <input
              className="ms-2"
              name="x0"
              value={params.x0 || ""}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="py-2">
          <label>
            Distance - X (Ft)
            <input
              className="ms-2"
              name="distance_x"
              value={params.distance_x || ""}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="py-2">
          <label>
            Dx
            <input
              className="ms-2"
              name="dx"
              value={params.dx || ""}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="py-2">
          <label>
            Rotation(0)
            <input
              className="ms-2"
              name="rotation"
              value={params.rotation || 0}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="py-2">
          <label>
            Y0
            <input
              className="ms-2"
              name="y0"
              value={params.y0 || ""}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="py-2">
          <label>
            Distance - Y (Ft)
            <input
              className="ms-2"
              name="distance_y"
              value={params.distance_y || ""}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="py-2">
          <label>
            Dy
            <input
              className="ms-2"
              name="dy"
              value={params.dy || ""}
              onChange={handleChange}
            />
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center py-2">
        <button
          className="btn-custom"
          onClick={(e) => {
            applyGridParams();
          }}
        >
          Apply
        </button>
      </div>
    </>
  );
}

export default UserDefinedGridSettings;
