import { DatePicker, Form, Input, Radio } from 'antd'
import FormSection from 'components/form-section'
import dayjs from 'dayjs'
import React from 'react'

const GeneralSetting = () => {
  return (
    <div className="vstack gap-4">
      <FormSection title="Project">
        <Form.Item name={["GENERAL", "title"]} label="Title">
          <Input />
        </Form.Item>
        <Form.Item name={["GENERAL", "notes"]} label="Notes">
          <Input.TextArea rows={4} />
        </Form.Item>
      </FormSection>
      <FormSection title="Model Setting">
        <Form.Item name={["GENERAL", "start"]} label="Simulation start date" getValueProps={(value) => ({
          value: value ? dayjs(value) : undefined
        })}>
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
        <Form.Item name={["GENERAL", "unit"]} label="Simulation Unit">
          <Radio.Group>
            <Radio value="FIELD">Field</Radio>
            <Radio value="METRIC">Metric</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={["GENERAL", "fluid"]} label="Fluid Type">
          <Radio.Group>
            <Radio value="GAS">Gas</Radio>
            <Radio value="BLACK_OIL">Black oil</Radio>
            <Radio value="CONDENSATE">Condensate</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={["GENERAL", "reservoir"]} label="Reservoir Type">
          <Radio.Group>
            <Radio value="Singleperm">Single perm</Radio>
            <Radio value="Dualperm">Dual perm</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={["GENERAL", "neuralnet"]} label="Neural Net Selection">
          <Radio.Group>
            <Radio value="Default">Default</Radio>
            <Radio value="CustomerRetrain">Customer retrain</Radio>
          </Radio.Group>
        </Form.Item>
      </FormSection>
    </div>
  )
}

export default GeneralSetting