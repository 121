import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const ArchitectureSources = [
  { value: "historical_data", name: "Historical" },
  { value: "hyp_predict", name: "Hyperbolic" },
  { value: "hyp_lower", name: "Hyperbolic Lower" },
  { value: "hyp_upper", name: "Hyperbolic Upper" },
  { value: "exp_predict", name: "Exponential" },
  { value: "exp_lower", name: "Exponential Lower" },
  { value: "exp_upper", name: "Exponential Upper" },
  { value: "ml_predict", name: "ML" },
  { value: "ml_lower", name: "ML Lower" },
  { value: "ml_upper", name: "ML Upper" },
];

const MODELS = { "hyp": "Auto-regression", "exp": "User-Arps", "ml": "ML" };
const LINE_TYPES = ["", "Lower", "Upper"];
const UPPER_INDEX = 1, LOWER_INDEX = 0;

const CustomPalette = [
  "blue",
  "hsl(39, 100%, 50%)",
  "hsl(59, 96%, 49%)",
  "hsl(59, 49%, 50%)",
  "hsl(120, 84%, 28%)",
  "hsl(120, 95%, 21%)",
  "hsl(120, 85%, 46%)",
  "hsl(320, 96%, 50%)",
  "hsl(320, 60%, 50%)",
  "hsl(320, 90%, 70%)",
];

const COLORS = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];

const TypeCurveUncertainty = (props) => {
  const { historical_data, hypPredict, expPredict, mlPredict, yUnit } = props;
  let key, data;
  if (hypPredict) { key = "hyp"; data = hypPredict }
  if (expPredict) { key = "exp"; data= expPredict }
  if (mlPredict) { key = "ml"; data = mlPredict }

  let models = [];  // for Ex: [{name: "Hyperbolic", value: 'hyp', index: 0}, {name: "Hyperbolic Upper", value: 'hyp_Upper', index: 2}]
  LINE_TYPES.map((t, index) => models.push({
    index: (index === 0) ? null : (index - 1),
    name: (MODELS[key] + ' ' + t).trim(),
    value: key + (t !== '' ? ('_' + t) : ""),
  }))

  const [options, setOptions] = useState({
    legend: {
      data: [' '],
      textStyle: {
        color: "#ccc",
      },
    },
    toolbox: {
      left: 'center',
      itemSize: 18,
      top: 15,
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: { type: 'png' }
      }
    },
    axisPointer: {
      show: true,
      snap: true,
      label: {
        precision: 3,
      }
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: ['1970-1-1']
    },
    yAxis: [{
      type: "value",
      show: true,
      splitLine: {
        show: false,
        lineStyle: {
          opacity: "0.2",
          color: ["#fff"],
        },
      },
      name: "",
      nameLocation: 'start',
      nameTextStyle: {
        color: "#fff",
        align: "left",
      },
    }],
    series: [{
      name: ' ',
      type: 'line',
      data: [0],
    }],
  });

  let legendArr = [], seriesValues = [], xAxisData = [], yAxisArr = [];
  function getChartOptions() {
    seriesValues = [];
    // get xAxis, set it
    const getXAxis = (data) => {
      return data.fitted.map(item => item[0]);
    };
    // get yAxis, unit\name\index\offset\color, push into
    const getYAxis = (model, index) => {
      return {
        type: "value",
        offset: (index ? (index - 1) : 0) * (20),
        position: index ? 'right' : "left",
        axisLine: {
          onZero: false,
          show: true,
          lineStyle: {
            color: COLORS[index],
          },
        },
        splitLine: {
          show: index ? false : true,
          lineStyle: {
            opacity: "0.2",
            color: ["#fff"],
          },
        },
        axisTick: {
          show: true,
          color: COLORS[index],
        },
        axisLabel: {
          show: true,
          interval: 'auto',
        },
        yAxisIndex: index,
        name: yUnit || "",
        nameLocation: "end",
        nameTextStyle: {
          align: 'left'
        },
      }
    };

    const getValues = (model, data, index) => {
      let seriesData = [];

      if (model.index === null) seriesData = data.fitted.map(item => item[1]);
      else {
        seriesData = data.fitted.map((item, index) => (model.index === UPPER_INDEX)
          ? (data.upper[index][1] - data.lower[index][1])
          : data.lower[index][1]);
      }
      return {
        name: model.name,
        type: 'line',
        stack: (model.index === null) ? "" : "uncertain",
        data: seriesData,
        lineStyle: {
          opacity: (model.index === null) ? 1 : 0,
        },
        areaStyle: {
          color: (model.index === UPPER_INDEX) ? COLORS[0] : 'none',
          opacity: 0.4,
        },
        stackStrategy: 'all',
        smooth: true,
        symbol: 'none',
      };
    };

    const prepareOptions = () => {
      let tempOption = { ...options };
      tempOption.legend.data = legendArr;
      tempOption.xAxis.data = xAxisData;
      tempOption.xAxis.axisLabel = {
        ...(tempOption.xAxis.axisLabel ? tempOption.xAxis.axisLabel : {}),
        formatter: function (value, index) {
          if (!isNaN(value)) return value;
          var date = new Date(value);
          if (date === "Invalid Date") return value;
          var texts = [
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2),
            date.getFullYear()
          ];
          return texts.join('/');
        }
      };
      tempOption.yAxis = yAxisArr;
      tempOption.series = seriesValues;
      return tempOption;
    }

    ((result) => {
      models.forEach((model, index) => {
        (index === 0) && legendArr.push(model.name);

        (xAxisData.length === 0) && (xAxisData = getXAxis(data));

        index === 0 && yAxisArr.push(getYAxis(model, index));

        seriesValues.push(getValues(model, data, index));
      });
      setOptions(prepareOptions(legendArr, xAxisData, yAxisArr, seriesValues));
    })();
  }

  useEffect(() => {
    data && getChartOptions();
  }, [historical_data, hypPredict, expPredict, mlPredict]);

  return (
    <>
      <ReactECharts
        option={options}
        notMerge={true}
        style={{ height: "100%" }}
      />
    </>
  );
};
export default TypeCurveUncertainty;
