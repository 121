import React, { useMemo } from 'react'
import { Button, Checkbox, Form, Tabs } from 'antd'
import { useDCAReport } from '../DCAReportProvider'

import classes from '../dca-reporting.module.scss'
import WellReport from './WellReport'
import { useMutation } from '@tanstack/react-query'
import { computeDCFS } from 'service/dcaAnalysis'
import { isDeepEmpty } from 'utils/isDeepEmpty'
import FormSection from 'components/form-section'

const ReportContent = ({ isActive }) => {
  const { sourceProject } = useDCAReport()

  const { wells = [] } = sourceProject || {}

  const form = Form.useFormInstance()

  const { mutate: computeData, data: {
    data
  } = {}, isPending } = useMutation({
    mutationFn: (formData) => {
      const payload = {
        project_id: formData.project_id,
        general: {
          price_projections: formData.general.price_projections,
          sensitivity_rates: formData.general.sensitivity_rates,
          discount_rate: formData.general.discount_rate,
          apply_diff: formData.general.apply_diff || false
        },
        wells: Object.entries(formData.wells)
          .filter(([, wellSetting]) => {
            return !isDeepEmpty(wellSetting)
          })
          .map(([uwi, wellSetting]) => {
            return {
              uwi: parseInt(uwi),
              expense_method: wellSetting.loe_expenses.avg_duration !== 'user' ? 'average' : 'user',
              expenses_range: ['user', 'all'].includes(wellSetting.loe_expenses.avg_duration) ? undefined : wellSetting.loe_expenses.avg_duration,
              loe_expenses: {
                fixed_expense: wellSetting.loe_expenses.fixed_expense,
                variable_expense: wellSetting.loe_expenses.variable_expense
              }
            }
          })
      }

      return computeDCFS(payload)
    }
  })

  const tabs = useMemo(
    /**
     * 
     * @returns {import('antd').TabsProps['items']}
     */
    () => wells
      .filter(well => {
        return data?.wells_dcf?.find(w => `${ w.uwi }` === `${ well.uwi }`)
      })
      .map(well => {
        const wellReport = data?.wells_dcf?.find(w => `${ w.uwi }` === `${ well.uwi }`)

        return {
          key: well.uwi,
          label: well.well_name,
          children: <WellReport uwi={well.uwi} report={wellReport} />
        }
      }),
    [data?.wells_dcf, wells]
  )

  if (!sourceProject) return null

  return (
    <div className="vstack gap-2">
      <FormSection title="KPIs">
        <div className="row">
          <div className="col-4">
            <Form.Item name={['general', 'cashflow']} valuePropName="checked">
              <Checkbox>Cashflow</Checkbox>
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item name={['general', 'discounted_cashflow']} valuePropName="checked">
              <Checkbox>Discounted cashflow</Checkbox>
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item name={['general', 'economic_eur']} valuePropName="checked">
              <Checkbox>Economic EUR</Checkbox>
            </Form.Item>
          </div>
        </div>
      </FormSection>
      <div className="hstack gap-2">
        <Button loading={isPending} onClick={() => computeData(form.getFieldsValue())}>Generate Data</Button>
      </div>
      {data ? (
        <FormSection title="Wells">
          <Tabs
            tabPosition="left"
            className={classes.tabs}
            items={tabs}
            style={{
              height: 640
            }}
          />
        </FormSection>
      ) : null}
    </div>
  )
}

export default ReportContent