import React, { useCallback, useEffect, useState } from "react";
import PpSelector from "./PpSelector";
import { useDispatch, useSelector } from "react-redux";
import { selectAssetList, selectActiveAssetId, selectSelectedWells, addSelectedLogName, removeSelectedLogName, selectSelectedLogNames } from "features/petrophysics/petrophysicsSlice";
import { getWellLogAttrs } from "service/petrophysics";

const PpLogSelector = (props) => {
    const { loadingIconActions } = props;
    const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
    const dispatch = useDispatch();
    const assetList = useSelector(selectAssetList);
    const assetId = useSelector(selectActiveAssetId);
    const wellList = useSelector(selectSelectedWells);
    const selectedLogNames = useSelector(selectSelectedLogNames);
    const [logNameList, setLogNameList] = useState(null);

    const handleLogNameSelected = useCallback((item, status) => {
        if (status) {
            dispatch(addSelectedLogName(item));
        } else {
            dispatch(removeSelectedLogName(item));
        }
    }, [dispatch]);

    useEffect(() => {
        if (!assetList || !assetId || !wellList) return;
        // get logs name, render to the list in component
        let wellsToLoadLogNames = [], activeAsset = assetList[assetId];
        if (wellList.length === 0) {
            setLogNameList(null);
            return;
        }
        wellList.map(wellId => {
            let well = activeAsset[wellId];
            if (!well || !well['logs'])
                wellsToLoadLogNames.push(wellId);
            return null;
        });
        // load log names
        (wellsToLoadLogNames.length > 0) && showLoadingIcon && showLoadingIcon();
        (wellsToLoadLogNames.length > 0) && getWellLogAttrs(
            wellsToLoadLogNames,
            (res) => {
                const cleanedRes = res.filter(item => item.log_type !== 'Depth');
                setLogNameList(cleanedRes.map(item => {
                    const { log_name_id, log_type, log_name, description } = item;
                    const logId = log_type + ":" + log_name;
                    handleLogNameSelected({
                        logNameId: log_name_id,
                        logType: log_type,
                        logName: log_name,
                        name: logId,
                    }, true);
                    return {
                        label: log_name,
                        logNameId: log_name_id,
                        name: logId,
                        description: description,
                        logType: log_type,
                        logName: log_name,
                    };
                }));
                hideLoadingIcon && hideLoadingIcon();
            },
            (e) => {
                console.error("load log names error.", e.message);
                hideLoadingIcon && hideLoadingIcon();
            }
        )
    }, [wellList, assetId, assetList, handleLogNameSelected, hideLoadingIcon, showLoadingIcon]);

    return (
        <div className="selector d-flex flex-column w-100 h-100">
            {
                logNameList && <PpSelector
                    dataList={wellList.length !== 0 ? logNameList : null}
                    title='Logs'
                    selected={selectedLogNames}
                    onOptionSelected={(item, status) => {
                        handleLogNameSelected(item, status);
                    }}
                />
            }
            {
                !logNameList && 'Please select wells above...'
            }
        </div>
    )
};
export default PpLogSelector;