import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { COLORS } from 'app/codes';
import { Empty } from "antd";

const DcaManagementHistoryChart = (props) => {
    const { xSeries, dataSeries, config } = props.data;
    const { multiYAxis, startColorIndex, xAxisLabel, leftYAxisLabel, title, allCurveUsePoint } = config || { multiYAxis: false, startColorIndex: 0 };
    const [options, setOptions] = useState({
        aria: {
            enabled: true,
            decal: {
                show: true,
            },
        },
        title: {
            text: title,
            left: 'center',
            top: "3%",
            textStyle: {
                color: 'rgba(255, 255, 255, 0.6)',
                fontSize: '1.5em'
            },
        },
        legend: {
            pageTextStyle: {
                width: 10,
                overflow: 'truncate',
            },
            textStyle: {
                color: "#ccc",
            },
            top: "7.5%",
            left: "13%",
            width: "70%",
            type: 'scroll',
            itemWidth: 20,
            pageIconSize: [15, 15],
            pageButtonItemGap: 0,
            pageButtonPosition: 'start',

        },
        toolbox: {
            left: 'right',
            itemSize: 18,
            top: 0,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: {},
                saveAsImage: { type: 'png' }
            }
        },
        axisPointer: {
            show: true,
            snap: true,
            label: {
                precision: 3,
            }
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "5%",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: [''],
            name: xAxisLabel,
            nameLocation: 'middle',
            nameGap: 25,
            nameTextStyle: {
                color: "#aaa",
            },
        },
        yAxis: [{
            type: "value",
            show: true,
            splitLine: {
                show: false,
                lineStyle: {
                    opacity: "0.2",
                    color: ["#fff"],
                },
            },
            name: leftYAxisLabel,
            nameLocation: 'center',
            nameGap: 35,
            nameTextStyle: {
                color: "#aaa",
            },
        }],
        series: [{
            name: ' ',
            type: 'line',
            data: [0],
        }],
    });

    useEffect(() => {
        xSeries && dataSeries && getChartOptions();
        //eslint-disable-next-line
    }, [xSeries, dataSeries]);

    let legendArr = [], seriesValues = [], xAxisData = [], yAxisArr = [];
    function getChartOptions() {
        seriesValues = [];
        // get yAxis, unit\name\index\offset\color, push into
        const getYAxis = (name, index, unit) => {
            return {
                type: "value",
                offset: (index ? (index - 1) : 0) * (20),
                position: index ? 'right' : "left",
                axisLine: {
                    onZero: false,
                    show: true,
                    lineStyle: {
                        color: multiYAxis ? COLORS[index] : "#888",
                    },
                },
                splitLine: {
                    show: index ? false : true,
                    lineStyle: {
                        opacity: "0.2",
                        color: ["#fff"],
                    },
                },
                axisTick: {
                    show: true,
                    color: COLORS[index],
                },
                axisLabel: {
                    show: true,
                    interval: 'auto',
                },
                name: (leftYAxisLabel || '') + '(' + unit + ')',
            }
        };

        // get values, clean(calc, get monthly)
        const getValues = (model, data, index) => {
            /* data:
                {
                    data: history.map(item => item.value),
                    lineType: 'scatter',
                }
            */
            return {
                name: model,
                type: 'line',
                data: data.data,
                symbol: 'circle',
                symbolSize: (index === 0 || allCurveUsePoint) ? 5 : 0,
                yAxisIndex: multiYAxis ? index : 0,
                lineStyle: {
                    show: false,
                    // opacity: (data.lineType === 'scatter' || allCurveUsePoint) ? 0 : 1,
                    opacity: 1,
                    smooth: true,
                },
                itemStyle: {
                    color: COLORS[index + startColorIndex],
                    borderWidth: 0,
                    decal: {
                        symbol: 'rect',
                    }
                },
            };
        };

        const prepareOptions = () => {
            let tempOption = {
                ...options,
                title: {
                    text: title,
                    left: 'center',
                    textStyle: {
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontSize: '1.5em'
                    },
                },
            };
            tempOption.xAxis.data = xAxisData;
            tempOption.xAxis.axisLabel = {
                ...(tempOption.xAxis.axisLabel ? tempOption.xAxis.axisLabel : {}),
                formatter: function (value, index) {
                    if (!isNaN(value)) return value;
                    var date = new Date(value);
                    if (date === "Invalid Date") return value;
                    var texts = [
                        ('0' + (date.getMonth() + 1)).slice(-2),
                        ('0' + date.getDate()).slice(-2),
                        date.getFullYear()
                    ];
                    return texts.join('/');
                }
            };
            tempOption.yAxis = yAxisArr;
            tempOption.series = seriesValues;

            return tempOption;
        }

        ((result) => {
            dataSeries && dataSeries.map((dataObject, index) => {
                /* dataObject: 
                    { "Historical": {
                        data: history.map(item => item.value),
                        lineType: 'scatter',
                        unit: 'bbl',
                    }}
                */
                let seriesName = Object.keys(dataObject)[0];
                legendArr.push(seriesName);

                (xAxisData.length === 0) && (xAxisData = xSeries);

                (index === 0 || multiYAxis) && yAxisArr.push(getYAxis(seriesName, index, dataObject[seriesName].unit));

                seriesValues.push(getValues(seriesName, dataObject[seriesName], index));

                return null;
            });
            setOptions(prepareOptions(legendArr, xAxisData, yAxisArr, seriesValues));
        })();
    }

    return (dataSeries && dataSeries?.length > 0) ? (<ReactECharts
        option={options}
        notMerge={true}
        style={{ height: "100%" }}
    />) : (<Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{ color: 'rgba(255,255,255, 0.6)', paddingTop: 80 }}
    >{"Loading..."}</Empty>)
};
export default DcaManagementHistoryChart;
