import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ReactECharts from "echarts-for-react";
import { getProductionsData } from "service/productionData";
import {
  selectAssetById,
  selectWellByAssetAndId,
} from "features/asset/assetSelectorSlice";

import * as dfd from "danfojs";

const defaultState = {
  title: {
    show: true,
    text: "",
    textStyle: {
      color: "#bbb",
      fontSize: 15,
    },
  },
  legend: {
    data: ["Oil"], //OK
    textStyle: {
      color: "#ccc",
    },
  },
  toolbox: {
    left: "center",
    itemSize: 18,
    top: 15,
    feature: {
      dataZoom: {
        yAxisIndex: "none",
      },
      restore: {},
      saveAsImage: { type: "png" },
    },
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["1970-1-1"],
  },
  yAxis: [
    {
      type: "value",
      show: true,
      splitLine: {
        show: true,
        lineStyle: {
          opacity: "0.2",
          color: ["#fff"],
        },
      },
      name: "",
      nameTextStyle: {
        color: "#aaa", // Todo color
        nameLocation: "middle",
      },
    },
  ],
  series: [
    {
      name: "Oil",
      type: "line",
      data: [0],
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

const updateLiquidRate = (source, result) => {
  let dataDF = null;
  for (let sourceName of source) {
    let series = result[sourceName];
    let sourceDF = new dfd.DataFrame(series, { columns: ["date", sourceName] });
    if (dataDF == null) {
      dataDF = sourceDF;
    } else {
      dataDF = dfd.merge({
        left: dataDF,
        right: sourceDF,
        on: ["date"],
        how: "outer",
      });
    }
  }
  dataDF.fillNa(0, { inplace: true });
  let liquidDF = dataDF
    .loc({ columns: source })
    .apply((row) => row.reduce((a, b) => a + b, 0), { axis: 1 });
  let dates = dataDF["date"].values;
  let values = liquidDF.values;
  let zip = (x, y) =>
    Array.from(Array(Math.max(x.length, y.length)), (_, i) => [x[i], y[i]]);
  let data = zip(dates, values);
  return data;
};

const updateCumulativeData = (source, result) => {
  let series = result[source[0]]; // only one
  let df = new dfd.DataFrame(series, { columns: ["date", source[0]] });
  // df["date"] = df["date"].astype("datetime64");
  let cumsum = df[source[0]].cumSum({ axis: 0 });
  df.addColumn("cumsum", cumsum, { inplace: true }); //{ column: "cumsum", value: cumsum }
  df.drop({ columns: source[0], inplace: true });
  // let data = dfd.toJSON(df);
  let data = df.values;
  return data;
};

const updateCumulativeLiquid = (source, result) => {
  if (source.length < 2 || result == null || result[source[0]] == null) {
    return;
  }

  let dataDF = null;
  for (let sourceName of source) {
    let series = result[sourceName];
    let sourceDF = new dfd.DataFrame(series, { columns: ["date", sourceName] });
    if (dataDF == null) {
      dataDF = sourceDF;
    } else {
      dataDF = dfd.merge({
        left: dataDF,
        right: sourceDF,
        on: ["date"],
        how: "outer",
      });
    }
  }
  dataDF.fillNa(0, { inplace: true });
  let liquidDF = dataDF
    .loc({ columns: source })
    .apply((row) => row.reduce((a, b) => a + b, 0), { axis: 1 });
  dataDF.addColumn("liquid", liquidDF, { inplace: true });
  dataDF.drop({ columns: source, inplace: true });
  let cumsum = dataDF["liquid"].cumSum({ axis: 0 });
  dataDF.addColumn("cumsum", cumsum, { inplace: true });
  dataDF.drop({ columns: "liquid", inplace: true });
  let data = dataDF.values;
  return data;
};

const updateOilGasRatio = (source, result) => {
  let oilSeries = result["oil_prd_rate"];
  let gasSeries = result["gas_prd_rate"];
  let oilDF = new dfd.DataFrame(oilSeries, {
    columns: ["date", "oil_prd_rate"],
  });
  let gasDF = new dfd.DataFrame(gasSeries, {
    columns: ["date", "gas_prd_rate"],
  });
  let df = dfd.merge({ left: oilDF, right: gasDF, on: ["date"], how: "outer" });
  df.fillNa(0, { inplace: true });
  let ratio = df["gas_prd_rate"].div(df["oil_prd_rate"]);
  df.addColumn("ratio", ratio, { inplace: true });
  df.drop({ columns: ["oil_prd_rate", "gas_prd_rate"], inplace: true });
  df.fillNa(0, { inplace: true });
  return df.values;
};

const updateWaterCut = (source, result) => {
  let oilSeries = result["oil_prd_rate"];
  let waterSeries = result["water_prd_rate"];
  // for (let dateStr of Object.keys(oilSeries)) {
  //   let sum = oilSeries[dateStr] + waterSeries[dateStr];
  //   data[dateStr] = sum ? waterSeries[dateStr] / sum : 0;
  // }
  let oilDF = new dfd.DataFrame(oilSeries, {
    columns: ["date", "oil_prd_rate"],
  });
  let waterDF = new dfd.DataFrame(waterSeries, {
    columns: ["date", "water_prd_rate"],
  });
  let df = dfd.merge({
    left: oilDF,
    right: waterDF,
    on: ["date"],
    how: "outer",
  });
  df.fillNa(0, { inplace: true });
  let liquidDF = df
    .loc({ columns: source })
    .apply((row) => row.reduce((a, b) => a + b, 0), { axis: 1 });
  df.addColumn("sum", liquidDF, { inplace: true });
  let ratio = df["water_prd_rate"].div(df["sum"]);
  df.addColumn("ratio", ratio, { inplace: true });
  df.print();
  df.drop({
    columns: ["oil_prd_rate", "water_prd_rate", "sum"],
    inplace: true,
  });
  df.fillNa(0, { inplace: true });
  return df.values;
};

const PRODUCT_DATA_SOURCE_HANDLER = {
  oil_prd_rate: { onlyValue: true, unit: "bbl/m" },
  gas_prd_rate: { onlyValue: true, unit: "Mscf/m" },
  water_prd_rate: { onlyValue: true, unit: "bbl/m" },
  power_consumption: { onlyValue: true, unit: "kWh" },
  liquid_rate: {
    source: ["oil_prd_rate", "water_prd_rate"],
    handler: updateLiquidRate,
    unit: "bbl/m",
  },
  cumulative_oil: {
    source: ["oil_prd_rate"],
    handler: updateCumulativeData,
    onlyValue: true,
    unit: "bbl",
  },
  cumulative_gas: {
    source: ["gas_prd_rate"],
    handler: updateCumulativeData,
    onlyValue: true,
    unit: "Mscf",
  },
  cumulative_water: {
    source: ["water_prd_rate"],
    handler: updateCumulativeData,
    onlyValue: true,
    unit: "bbl",
  },
  cumulative_power_consumption: {
    source: ["power_consumption"],
    handler: updateCumulativeData,
    onlyValue: true,
    unit: "kWh",
  },
  cumulative_liquid: {
    source: ["oil_prd_rate", "water_prd_rate"],
    handler: updateCumulativeLiquid,
    unit: "bbl",
  },
  oil_gas_ratio: {
    source: ["oil_prd_rate", "gas_prd_rate"],
    handler: updateOilGasRatio,
    unit: "",
  },
  water_cut: {
    source: ["oil_prd_rate", "water_prd_rate"],
    handler: updateWaterCut,
    unit: "",
  },
};

const COLORS = [
  "#5470c6",
  "#91cc75",
  "#fac858",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
];

let dataCache = {};

const HistoricalAAsset2 = (props) => {
  const { wellId, assetId, models, period } = props;

  const asset = useSelector((state) => selectAssetById(state, assetId));
  const well = useSelector((state) =>
    selectWellByAssetAndId(state, assetId, wellId)
  );
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  let titleText = "";
  if (well) {
    titleText = well.well_name;
  } else if (asset) {
    titleText = asset.asset_name;
  }

  const [options, setOptions] = useState(defaultState);

  let legendArr = [],
    seriesValues = [],
    xAxisData = [],
    yAxisArr = [];

  function getChartOptions() {
    const getAllProducts = (models) => {
      let products = [];
      models.forEach(({ value, name }) => {
        let modelProducts = PRODUCT_DATA_SOURCE_HANDLER[value].source || [
          value,
        ];
        modelProducts.forEach((p) => {
          if (!products.includes(p)) {
            products.push(p);
          }
        });
      });
      return products;
    };
    // get xAxis, set it
    const getXAxis = (data) => {
      let dates = data.map((item) => item[0]);
      if (period === 1) return dates;
      let months = [];
      dates.map((date) => {
        if (!months.includes(date.slice(0, 7))) months.push(date.slice(0, 7));
        return null;
      });
      return months;
    };
    // get yAxis, unit\name\index\offset\color, push into
    const getYAxis = (model, index) => {
      return {
        type: "value",
        offset: (index ? index - 1 : 0) * 35,
        position: index ? "right" : "left",
        axisLine: {
          onZero: false,
          show: true,
          lineStyle: {
            color: COLORS[index],
          },
        },
        splitLine: {
          show: index ? false : true,
          lineStyle: {
            opacity: "0.2",
            color: ["#fff"],
          },
        },
        axisTick: {
          show: true,
          color: COLORS[index],
        },
        axisLabel: {
          show: true,
          interval: "auto",
          formatter: (value, index) => {
            let str = "";
            value >= 1000 ? (str = value / 1000 + "K") : (str = value);
            value >= 1000 * 1000 && (str = value / 1000 / 1000 + "M");
            return str;
          },
        },
        name: PRODUCT_DATA_SOURCE_HANDLER[model.value].unit || " ",
        yAxisIndex: index,
        nameTextStyle: {
          nameLocation: "left",
        },
      };
    };

    // get values, clean(calc, get monthly)
    const dataHandled = (model, dataCache) => {
      let data = dataCache[model.value];
      let { handler, source } = PRODUCT_DATA_SOURCE_HANDLER[model.value];
      let result = data;

      handler && (result = handler(source, dataCache));
      return result;
    };

    // get values, clean(calc, get monthly)
    const getValues = (model, data, index) => {
      if (period === 1) {
        return {
          name: model.name,
          type: "line",
          lineStyle: {
            color: COLORS[index],
          },
          yAxisIndex: index || 0,
          data: data.map((item) => item[1]),
        };
      }
      let monthsSet = new Set();
      let months = [];
      data.forEach((item) => {
        let month = item[0].slice(0, 7);
        if (!monthsSet.has(month)) {
          monthsSet.add(month);
          months.push(month);
        }
      });
      let monthData = {};
      data.forEach((item) => {
        let month = item[0].slice(0, 7);
        monthData[month] === undefined && (monthData[month] = 0);
        monthData[month] += item[1];
      });

      return {
        name: model.name,
        type: "line",
        yAxisIndex: index || 0,
        data: months.map((month) => monthData[month]),
      };
    };

    const prepareOptions = () => {
      let tempOption = { ...options };
      tempOption.title.text = titleText;
      tempOption.legend.data = legendArr;
      tempOption.xAxis.data = xAxisData;
      tempOption.yAxis = yAxisArr;
      tempOption.series = seriesValues;
      return tempOption;
    };

    const getAllProductsData = (models) => {
      if (wellId == null && assetId == null) return;
      showLoadingIcon && showLoadingIcon();

      let allProducts = getAllProducts(models);

      let uwis = null;
      let asset_id = assetId;
      if (wellId != null) {
        uwis = [wellId];
        asset_id = null;
      }

      getProductionsData(
        uwis,
        asset_id,
        allProducts,
        null,
        null,
        true,
        (result) => {
          dataCache = result;
          models.forEach((model, index) => {
            let unsortedData = dataHandled(model, dataCache);
            hideLoadingIcon && hideLoadingIcon();
            if (unsortedData == null || unsortedData.length === 0) {
              legendArr = legendArr.filter((l) => l !== model.name);
              setOptions(defaultState);
              return;
            }
            let data = unsortedData.sort((a, b) => (a[0] > b[0] ? 1 : -1));
            // (Object.keys(unsortedData).sort((a, b) => a > b ? 1 : -1)).map(key => data[key] = unsortedData[key])
            !legendArr.includes(model.name) && legendArr.push(model.name);
            //
            xAxisData.length === 0 && (xAxisData = getXAxis(data));
            //
            yAxisArr.push(getYAxis(model, index));
            //
            seriesValues.push(getValues(model, data, index));
            //
            setOptions(
              prepareOptions(legendArr, xAxisData, yAxisArr, seriesValues)
            );
          });
        },
        (error) => {
          console.error("Error in fetching data.", error);
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    };
    getAllProductsData(models);
  }

  useEffect(() => {
    getChartOptions();
  }, [models, period, assetId, wellId]);

  return (
    <>
      <ReactECharts
        option={options}
        notMerge={true}
        onChartReady={(echarts) => { }}
        style={{ height: "100%" }}
      />
    </>
  );
};
export default HistoricalAAsset2;
