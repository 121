/*
    Layout and load/save log file.
*/import { DeleteOutlined, DownSquareTwoTone, WarningTwoTone } from "@ant-design/icons";
import { Button, Col, Layout, Row, Typography, Transfer, List, message, Modal } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import PpAssetWellSelector from "../selector/PpAssetWellSelector";
import CalcFormula from "./CalcFormula";
import Calculator from "./Calculator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectSelectedWellsForNewLog, selectAssetList, selectActiveAssetId, selectSelectedWells, setSelectedWells } from "features/petrophysics/petrophysicsSlice";
import { computeNewLogs, deleteNewLog, getNewLogs, getWellLogNames } from "service/petrophysics";
const { Title, Paragraph } = Typography;

const CalcLayout = (props) => {
    const { newLogName, logType, logUnit, logDesc } = props;
    const { loadingIconActions } = props;
    const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

    const assetList = useSelector(selectAssetList);
    const assetId = useSelector(selectActiveAssetId);
    const selectedWells = useSelector(selectSelectedWells);
    const selectedWellsForNewLog = useSelector(selectSelectedWellsForNewLog, shallowEqual);

    const [logNameList, setLogNameList] = useState([]);
    const [logFileList, setLogFileList] = useState([]);
    const [formula, setFormula] = useState([]); // for Ex: ['Sin', '(', '1.2', ')'];
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openFile, setOpenFile] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openNewLogSuccessMessage, setOpenNewLogSuccessMessage] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(0);
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };
    const onScroll = (direction, e) => {
    };
    const handleFormulaChange = (items) => {
        setFormula(items.target.value);
    }
    const handleCursorChange = (cursorPos) => {
        setCursorPosition(cursorPos);
    }
    useEffect(() => {
        openNewLogSuccessMessage && messageApi.open({
            type: 'success',
            content: 'Create new log successful',
            duration: 5,
            style: {
                marginTop: '100px',
            },
            onClose: () => setOpenNewLogSuccessMessage(false),
        });
    }, [openNewLogSuccessMessage, messageApi]);

    const handelClickOnCalculator = (item) => {
        const { print, autoComplete } = item;
        if (print === 'CLEAR') {
            setFormula([]);
            setCursorPosition(0);
        }
        else if (print === 'ENTER') {
            if (!newLogName || !logType || !logUnit || !selectedWells || !logNameList || !formula) {
                message.error("Please fill all required fields and the formula!");
                return;
            }
            showLoadingIcon && showLoadingIcon();
            computeNewLogs({
                "log_name": newLogName,
                "log_type": logType,
                "unit_system": logUnit,
                "log_description": logDesc,
                "uwis": selectedWellsForNewLog,
                "logs": logNameList.filter(key => formula.includes(key.prefix)).map(key => ({
                    "symbol": key.prefix,
                    "log_name_id": key.logNameId,
                    "log_type": key.log_type,
                    "log_name": key.log_name,
                })),
                "formula": formula.replace(' ', '').toLowerCase(),
            },
                (res) => {
                    dispatch(setSelectedWells([...selectedWells]));
                    setOpenNewLogSuccessMessage(true);
                    hideLoadingIcon && hideLoadingIcon();
                },
                (err) => {
                    console.warn("Save new log failed. ", err);
                    setOpenResult(true);
                    hideLoadingIcon && hideLoadingIcon();
                })
        }
        else {
            setFormula(formula.slice(0, cursorPosition) + print + (autoComplete ? autoComplete.join(' ') : "") + formula.slice(cursorPosition));
            setCursorPosition(cursorPosition + print.length + (autoComplete ? autoComplete[0].length : 0));
        }
    }

    useEffect(() => {
        if (!assetList || !assetId || !selectedWellsForNewLog) return;
        // get logs name, render to the list in component
        let wellsToLoadLogNames = [], activeAsset = assetList[assetId];
        if (selectedWellsForNewLog.length === 0) {
            setLogNameList([]);
            return;
        }
        selectedWellsForNewLog.map(wellId => {
            let well = activeAsset[wellId];
            return (!well || !well['logs']) && wellsToLoadLogNames.push(wellId);
        });
        // load log names
        // (wellsToLoadLogNames.length > 0) && showLoadingIcon && showLoadingIcon();
        (wellsToLoadLogNames.length > 0) && getWellLogNames(
            wellsToLoadLogNames,
            (res) => {
                setLogNameList(res.filter(item => item.log_type !== 'Depth').map((item, index) => {
                    const { log_name_id, log_type, log_name, description } = item;
                    // handleLogNameSelected(item, true);
                    return {
                        key: log_type + ":" + log_name,
                        logNameId: log_name_id,
                        logType: log_type,
                        logName: log_name,
                        title: log_name + "(" + log_type.slice(0, 5) + "...)",
                        prefix: "x" + index,
                        description: description,
                    };
                }));
                // hideLoadingIcon && hideLoadingIcon();
            },
            (e) => {
                console.warn("load log names error.", e.message);
                // hideLoadingIcon && hideLoadingIcon();
            }
        )
    }, [selectedWellsForNewLog, assetId, assetList]);

    const NewLogSelector = (props) => {
        const { logNameList, onClick } = props;
        let index = 0;

        return (
            <Transfer
                dataSource={logNameList}
                titles={['Source', 'Selected']}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                onScroll={onScroll}
                render={(item) => {
                    return (
                        <Fragment key={index}>
                            {(targetKeys.includes(item.key) ? item.prefix + "=" : "") + item.title}
                            <>
                                {targetKeys.includes(item.key) && <DownSquareTwoTone onClick={(e) => {
                                    onClick({
                                        label: item.key,
                                        pos: [],
                                        print: item.prefix,
                                        icon: '',
                                        formula: item.prefix,
                                    })
                                }} />}
                            </>
                        </Fragment>)
                }
                }
                style={{ color: 'white', textAlign: 'left', height: '100%' }}
                listStyle={{ height: '100%' }}
                operationStyle={{ color: 'white' }}
            />
        )
    }

    const handleOpenLogFile = () => {
        setOpenFile(true);
        showLoadingIcon && showLoadingIcon();
        getNewLogs(
            (res) => {
                setLogFileList(res.map(item => {
                    const { id, formula, params, create_date, log_name, log_type, log_description } = item;
                    let spreadFormula = formula;
                    params.map(item => {
                        const { symbol, log_type, log_name } = item;
                        return spreadFormula = spreadFormula.replace(symbol, `[${log_type}:${log_name}]`);
                    });
                    return {
                        id: id,
                        value: id,
                        title: spreadFormula,
                        logName: log_name,
                        logType: log_type,
                        date: create_date.slice(0, -7),
                        description: log_description,
                    }
                }));
                hideLoadingIcon && hideLoadingIcon();
            },
            (err) => {
                console.warn("Open log files failed!", err.message);
                hideLoadingIcon && hideLoadingIcon();
            }
        )
    }

    return (
        <Layout style={{ backgroundColor: 'rgb(35,38,63)', marginLeft: '5px', marginRight: '5px' }}>
            {
                <Modal
                    open={openResult}
                    title={<><WarningTwoTone twoToneColor="red" />There are some problems with your formula!</>}
                    onOk={() => setOpenResult(false)}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    closable={false}
                    centered={true}
                    icon={<WarningTwoTone />}
                >
                    Please recheck your input.
                </Modal>
            }
            {contextHolder}
            <Row justify='end'>
                <Button
                    size="small"
                    style={{ marginBottom: '0.5em' }}
                    onClick={handleOpenLogFile}
                >
                    New Log Management...
                </Button>
                <Modal
                    title="New Log List..."
                    open={openFile}
                    onOk={() => setOpenFile(false)}
                    onCancel={() => setOpenFile(false)}
                    style={{ marginTop: '50px', height: '300px' }}
                    cancelButtonProps={{ display: 'none' }}
                >
                    <List
                        itemLayout="horizontal"
                        dataSource={logFileList}
                        renderItem={(item, index) => (
                            <List.Item
                                actions={[<DeleteOutlined key={index}
                                    onClick={() => {
                                        showLoadingIcon && showLoadingIcon();
                                        deleteNewLog(
                                            { id: item.id },
                                            (res) => {
                                                dispatch(setSelectedWells([...selectedWells]));
                                                handleOpenLogFile();
                                                hideLoadingIcon && hideLoadingIcon();
                                            },
                                            (err) => {
                                                console.warn("Delete log file failed!", err.message);
                                                hideLoadingIcon && hideLoadingIcon();
                                            }
                                        )
                                    }}
                                />]}
                            >
                                <List.Item.Meta
                                    title={<Paragraph style={{ whiteSpace: 'pre-wrap' }}>{"Formula " + item.id + ": " + item.logName + "=" + item.title}</Paragraph>}
                                    description={<div><div>Description: {item.description}</div>Create Date: {item.date}</div>}
                                    id={item.id}
                                />
                            </List.Item>
                        )}
                    />
                </Modal>
            </Row>
            <Row align={"middle"} gutter={8} style={{ height: '31vh' }}>
                <Col span={8} style={{ height: '31vh' }}>
                    <Title level={5} style={{ color: 'white' }}>
                        Select Wells
                    </Title>
                    <PpAssetWellSelector
                        selectNewLog={true} heightPercent='90%'
                    // loadingIconActions={loadingIconActions}
                    />
                </Col>
                <Col span={16} style={{ height: '100%' }}>
                    <NewLogSelector
                        logNameList={logNameList}
                        onClick={handelClickOnCalculator}
                    />
                </Col>
            </Row>
            <Row>
                <CalcFormula
                    formula={formula}
                    onFormulaChange={handleFormulaChange}
                    onCursorChange={handleCursorChange}
                    newLogName={newLogName}
                />
            </Row>
            <Row><Calculator onClick={handelClickOnCalculator} /></Row>
            <Row justify={"space-around"} gutter={2}>
                <Button
                    type="primary"
                    onClick={() => handelClickOnCalculator({ print: 'ENTER' })}
                >
                    Calculate
                </Button>
                <Button
                    onClick={() => handelClickOnCalculator({ print: 'CLEAR' })}
                >Reset</Button>
            </Row>
        </Layout>
    )
};
export default CalcLayout;