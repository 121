import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  setAssets,
  setActiveAssetId,
  setAssetWells,
  selectAssets,
  // selectActiveAssetId,
  selectActiveAsset,
  selectActiveWells,
  setActiveAssetByName,
} from "features/asset/assetSelectorSlice";

import { getAssets } from "service/assets";
import { getWellSummary } from "service/wellSummary";

function AssetWellsSelector(props) {
  const {
    noAutoSelectFirstWell,
    selectedWellIds,
    onWellSelected,
    loadingIconActions,
  } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const assets = useSelector(selectAssets);
  const activeAsset = useSelector(selectActiveAsset);
  const assetWells = useSelector(selectActiveWells);

  const dispatch = useDispatch();

  // selected wells' ids
  const selectedWellIdsSet =
    selectedWellIds && selectedWellIds.length > 0
      ? new Set([...selectedWellIds])
      : new Set();

  let assetName = null;
  if (activeAsset) {
    assetName = activeAsset.asset_name;
  }

  const loadAssetWells = useCallback(
    (assetId) => {
      showLoadingIcon && showLoadingIcon();
      getWellSummary(
        assetId,
        (result) => {
          let wells = [];
          if (result && result.length > 0) {
            wells = result
              .slice()
              .sort((a, b) => (a.well_name > b.well_name ? 1 : -1));
          }
          dispatch(setAssetWells({ assetId: assetId, wells }));
          hideLoadingIcon && hideLoadingIcon();
        },
        (err) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [dispatch, hideLoadingIcon, showLoadingIcon]
  );

  useEffect(() => {
    showLoadingIcon && showLoadingIcon();
    getAssets(
      (result) => {
        if (result != null && result.length === 0) {
          return;
        }
        dispatch(setAssets(result));
        const assetId = result[0].id;
        dispatch(setActiveAssetId(assetId));
        loadAssetWells(assetId);
        hideLoadingIcon && hideLoadingIcon();
      },
      (err) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }, [dispatch, showLoadingIcon, hideLoadingIcon, loadAssetWells]);

  useEffect(() => {
    if (
      assetWells != null &&
      assetWells.length > 0 &&
      (!selectedWellIds || selectedWellIds.length === 0) &&
      !noAutoSelectFirstWell
    ) {
      const wellData = assetWells[0];
      wellData && onWellSelected(wellData);
    }
  }, [noAutoSelectFirstWell, selectedWellIds, assetWells, onWellSelected]);

  function handleAssetChanged(e) {
    const assetName = e.target.value;
    dispatch(setActiveAssetByName({ assetName }));

    const asset = assets.find((item) => item.asset_name === assetName);
    const assetId = asset.id;

    loadAssetWells(assetId);
  }

  const assetsSelect = () => {
    if (assets == null || assets.length === 0) {
      return null;
    }

    return assets.map((asset, index) => {
      return (
        <option
          key={"asset_" + index}
          className="d-flex justify-content-center align-items-content"
          value={asset.asset_name}
        >
          {asset.asset_name}
        </option>
      );
    });
  };

  const wellsSelect = () => {
    if (assetWells == null || assetWells.length === 0) {
      return null;
    }

    return assetWells.map((item, index) => {
      const selected =
        selectedWellIdsSet && selectedWellIdsSet.size > 0
          ? selectedWellIdsSet.has(item.uwi)
          : false;

      let className =
        "select-option d-flex justify-content-center align-items-start";

      if (selected) {
        className = className + " selected";
      }

      return (
        <div
          id={item.uwi}
          key={"well_" + index}
          className={className}
          onClick={(e) => {
            onWellSelected(item);
          }}
        >
          <span>{item.well_name}</span>
        </div>
      );
    });
  };

  return (
    <div className="selector d-flex flex-column w-100 h-100">
      <div className="selector-select d-flex flex-column align-items-stretch">
        <select
          onChange={(e) => {
            handleAssetChanged(e);
          }}
          name="asset_name"
          className="d-flex"
          value={assetName || ""}
        >
          {assetsSelect()}
        </select>
      </div>
      <div className="select-options-list hide-scrollbar">{wellsSelect()}</div>
    </div>
  );
}

export default AssetWellsSelector;
