import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setShowAddColumnPopup,
} from "features/input/dataManagerSlice";

function AddLogName(props) {
  const { inputColumnObj } = props;
  const dispatch = useDispatch();
  const [logType, setLogType] = useState(null);
  const [description, setDescription] = useState(null);

  function addColumn(e) {
    props.saveLogName({
      logName: inputColumnObj.logName,
      logType: logType,
      description: description,
    });
  }

  return (
    <div className="popup-wrapper">
      <div className="d-flex flex-column">
        <div className="input-grid-2-4">
          <div></div>
          <div>
            <span>Log Name</span>
          </div>
          <div>
            <span>Log Type</span>
          </div>
          <div>
            <span>Description</span>
          </div>

          <div>
            <span>Input:</span>
          </div>
          <div>
            <span>{inputColumnObj ? inputColumnObj.logName : ""}</span>
          </div>
          <select
            name="log_type"
            value={logType ? logType.log_type : ""}
            onChange={(e) => {
              let i = parseInt(e.target.value);
              setLogType(inputColumnObj.logTypes[i]);
            }}
          >
            {inputColumnObj &&
              inputColumnObj.logTypes.map((el, index) => {
                return <option key={index} value={index}>{el}</option>;
              })}
          </select>
          <input
            name="description"
            value={description ? description : ""}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></input>
        </div>
        <div className="d-flex justify-content-between align-items-center p-4">
          <button className="btn-custom px-4 py-2" onClick={addColumn}>
            Add
          </button>
          <button
            className="btn-custom px-4 py-2"
            onClick={(e) => {
              dispatch(setShowAddColumnPopup(false));
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddLogName;
