import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";

import {
  selectAvailableWellParams,
  selectAvailableParamsData,
  selectSelectedWellParams,
  selectActiveParam,
} from "features/well_planner/plannerSlice";

function ParamHistogramChart(props) {
  const activeParam = useSelector(selectActiveParam);
  const selectedWellParams = useSelector(selectSelectedWellParams);
  const availableWellParams = useSelector(selectAvailableWellParams);
  const availableParamsData = useSelector(selectAvailableParamsData);

  const [chartCategory, setChartCategory] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    return () => {
      setChartCategory([]);
      setChartData([]);
    };
  }, []);

  useEffect(() => {
    if (
      activeParam == null ||
      availableWellParams == null ||
      availableWellParams.length === 0 ||
      availableParamsData == null ||
      availableParamsData.length === 0
    ) {
      return;
    }

    let wellIds = Object.keys(availableWellParams);
    let dataMap = new Map();
    for (let wellId of wellIds) {
      let paramIndex = availableWellParams[wellId].findIndex(
        (param) => param === activeParam
      );
      let paramValue = availableParamsData[wellId][paramIndex];
      if (dataMap.has(paramValue) === false) {
        dataMap.set(paramValue, 1);
      } else {
        dataMap.set(paramValue, dataMap.get(paramValue) + 1);
      }
    }

    let category = [...dataMap.keys()];
    category.sort((x1, x2) => {
      return x1 - x2;
    });

    let total = 0;
    for (let key of category) {
      total += dataMap.get(key);
    }

    let data = category.map((key) => {
      return dataMap.get(key) / total;
    });

    setChartCategory(category);
    setChartData(data);
  }, [availableWellParams, availableParamsData, selectedWellParams, activeParam]);

  function getOptions() {
    let title = activeParam;
    if (activeParam != null) {
      title = activeParam.charAt(0).toUpperCase() + activeParam.slice(1);
    }

    let options = {
      title: {
        text: title,
        left: 'center',
        textStyle: {
          color: "#ccc",
        },
        // textAlign: 'center',
      },
      // legend: {
      //   data: [
      //     {
      //       name: activeParam,
      //     },
      //   ],
      //   textStyle: {
      //     color: "#ccc",
      //   },
      // },
      grid: {
        // left: "0",
        // right: "0",
        // bottom: "0",
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: chartCategory,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: chartData,
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
        },
      ],
    };

    return options;
  }
  return (
    <>
      <ReactECharts option={getOptions()} style={{ height: "100%", width: "100%" }} />
    </>
  );
}

export { ParamHistogramChart };
