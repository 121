import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShowNewProjectPopup,
  setShowNewProjectPopup,
  setActiveProjectId,
  addProjectItem,
} from "features/project/projectsSlice";

import { ProjectTypes, createProject } from "service/projects";

function AddProjectForm(props) {
  const { input, callbackFunction } = props;
  const { project_type, project_name, comment } = input || {};
  const [callbackFunc, callbackParams] = callbackFunction || [];
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const addProject = useSelector(selectShowNewProjectPopup);
  let dispatch = useDispatch();
  const [inputData, setInputData] = useState({
    project_type: project_type || ProjectTypes[0].value,
    project_name: project_name || "",
    comment: comment || "",
    redirect: "",
  });

  function handleSubmit() {
    if (
      inputData.project_name === "" ||
      inputData.comment === "" ||
      inputData.project_type === ""
    ) {
      return;
    }

    showLoadingIcon && showLoadingIcon();
    createProject(
      {
        project_type: inputData.project_type,
        name: inputData.project_name,
        comment: inputData.comment,
        is_share: false,
      },
      (res) => {
        dispatch(addProjectItem(res));
        dispatch(setActiveProjectId(res.id));
        callbackFunc && callbackFunc(res.id, callbackParams);
        hideLoadingIcon && hideLoadingIcon();
      },
      (err) => {
        hideLoadingIcon && hideLoadingIcon();
        console.error("Create project error! ", err);
      }
    );
  }

  const handleChange = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      {addProject && (
        <div className="add-project-wraper">
          <form
            className="form-container"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              dispatch(setShowNewProjectPopup(false));
            }}
          >
            <div className="form-title">
              <span>New Project</span>
              <span
                onClick={() => {
                  dispatch(setShowNewProjectPopup(false));
                }}
              >
                X
              </span>
            </div>

            <div className="input-group">
              <label>Enter Project Name</label>
              <input
                value={inputData.project_name}
                onChange={handleChange}
                name="project_name"
                type="text"
                placeholder="Type here ..."
                required
              />
              <small className="text-muted">*required</small>
            </div>

            <div className="input-group">
              <div className="special-label">
                <span>Select Project Type</span>
              </div>
              <select
                name="project_type"
                id="project_type"
                value={inputData.project_type}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                {ProjectTypes.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="input-group">
              <label>Comment</label>
              <textarea
                onChange={handleChange}
                placeholder="type the project description here...."
                name="comment"
                value={inputData.comment}
                required
              ></textarea>
              <small className="text-muted">*required</small>
            </div>
            <div className="input-group">
              <div className="submit-button-group">
                <button
                  type="submit"
                  onClick={(e) => {
                    if (!inputData.project_type) {
                      e.preventDefault();
                    }
                  }}
                >
                  Save
                </button>
                {/* <button
                  onClick={(e) => {
                    if (!inputData.project_type) {
                      e.preventDefault();
                    }
                  }}
                >
                  Save And Add Another
                </button> */}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setShowNewProjectPopup(false));
                  }}
                >
                  Exit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default AddProjectForm;
