import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PpLayout from "../components/PpLayout";
import { Layout, Space, Spin } from 'antd';
import PpAssetWellSelector from "../components/selector/PpAssetWellSelector";
import PpLogSelector from "../components/selector/PpLogSelector";
import PpWellsWithCrossSection from "../components/well_curve/PpWellsWithCrossSection";
import { useSelector } from "react-redux";
import { reset, selectBackgroundColorSetting } from "features/petrophysics/petrophysicsSlice";
import { rightStyle, siderStyle, assetContentStyle } from "../styles";
import useLoading from "components/common/useLoading";
const { Sider, Content } = Layout;

const LogViewer = (props) => {
    const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(<Spin size="large" />, 50);
    const backColor = useSelector(selectBackgroundColorSetting);

    const dispatch = useDispatch();
    useEffect(()=>{
        return ()=>{
            dispatch(reset());
        }
    }, [dispatch]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <LoadingIcon />
            <PpLayout>
                <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    size={[0, 48]}
                >
                    <Layout height={'100vh'}>
                        <Sider style={siderStyle} width={'9%'}>
                            <Content style={assetContentStyle} >
                                <PpAssetWellSelector loadingIconActions={[showLoadingIcon, hideLoadingIcon]} />
                            </Content>
                            <Content style={assetContentStyle}>
                                <PpLogSelector loadingIconActions={[showLoadingIcon, hideLoadingIcon]} />
                            </Content>
                        </Sider>
                        <Content style={{ ...rightStyle }}>
                            <PpWellsWithCrossSection
                                style={{ backgroundColor: backColor }}
                                gapWidth='25px'
                                columnWidth='150px'
                                height='880px'
                                syncDepth={false}
                                showTop={false}
                                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                            />
                        </Content>
                    </Layout>
                </Space>
            </PpLayout>
        </div>
    )
};
export default LogViewer;
