import { configureStore } from "@reduxjs/toolkit";

import projectsSlice from "features/project/projectsSlice";
import dcaProjectSlice from "features/decline_analysis/dcaProjectSlice";
import typeCurveProjectSlice from "features/decline_analysis/typeCurveProjectSlice";
import plannerSlice from "features/well_planner/plannerSlice";
import plannerDcaSlice from "features/well_planner/plannerDcaSlice";
import assetSelectorSlice from "features/asset/assetSelectorSlice";
import dataManagerSlice from "features/input/dataManagerSlice";
import routesSlice from "features/nav/routesSlice";

import userSlice from "features/user/userSlice";
import AssetViewerSlice from "features/input/AssetViewerSlice";
import dcaManagementSlice from "features/decline_analysis/dcaManagementSlice";
import petrophysicsSlice from "features/petrophysics/petrophysicsSlice";

export const store = configureStore({
  reducer: {
    projects: projectsSlice,
    dca_project: dcaProjectSlice,
    type_curve_project: typeCurveProjectSlice,
    well_planner: plannerSlice,
    well_planner_dca: plannerDcaSlice,
    asset_selector: assetSelectorSlice,
    data_manager: dataManagerSlice,
    asset_viewer: AssetViewerSlice,
    nav: routesSlice,
    user: userSlice,
    dcaManagement: dcaManagementSlice,
    petrophysics: petrophysicsSlice,
  },
});
