import L from "leaflet";
import { interpolateWellData } from "service/wellPlanner";
import { getLatLng } from "utils/geo";

export const DefaultObjectiveSize = 40;

/**
 * convert wells to map objective, wells bounds.
 * @param {*} wells
 * @returns
 */
export function getMapObjectivesFeatures(wells) {
  const latitudeArray = wells.map((well) => {
    return parseFloat(well.latitude);
  });
  const longitudeArray = wells.map((well) => {
    return parseFloat(well.longitude);
  });

  // latitudeArray.sort((x1, x2) => {
  //   return x1 - x2;
  // });
  // longitudeArray.sort((x1, x2) => {
  //   return x1 - x2;
  // });

  // let totalSize = latitudeArray.length;
  // let sumLat = latitudeArray.reduce(
  //   (prevValue, currValue) => prevValue + currValue,
  //   0
  // );
  // let sumLng = longitudeArray.reduce(
  //   (prevValue, currValue) => prevValue + currValue,
  //   0
  // );

  // let centerLat = sumLat / totalSize;
  // let centerLng = sumLng / totalSize;

  let latitudeMin = Math.min(...latitudeArray);
  let latitudeMax = Math.max(...latitudeArray);
  let longitudeMin = Math.min(...longitudeArray);
  let longitudeMax = Math.max(...longitudeArray);

  let centerLat = (latitudeMin + latitudeMax) / 2;
  let centerLng = (longitudeMin + longitudeMax) / 2;

  let leftBottom = [latitudeMin, longitudeMin];
  let rightTop = [latitudeMax, longitudeMax];

  let center = [centerLat, centerLng];
  let bounds = [leftBottom, rightTop];

  let objectives = wells.map((well) => {
    return {
      Name: well.well_name,
      Latitude: well.latitude,
      Longitude: well.longitude,
      Value: DefaultObjectiveSize,
      apiId: well.uwi,
    };
  });

  return [objectives, center, bounds];
}

/**
 * compute interpolated values of well properties on the map grids.
 *
 * @param {*} projectId
 * @param {*} wells
 * @param {*} interpoParams
 * @param {*} gridParams
 * @param {*} map
 * @param {*} onSuccess
 * @param {*} onError
 */
export function interpolateWellsWithGrid(
  projectId,
  wells,
  interpoParams,
  gridParams,
  map,
  onSuccess,
  onError
) {
  let leftBottom = gridParams.leftBottom;
  let rightTop = gridParams.rightTop;
  let rotate = gridParams.rotate;
  let xCount = gridParams.xCount;
  let yCount = gridParams.yCount;

  // convert from latlng to pixel, and rotate screen coordinate
  let leftBottomPoint = latlng2Pixel(leftBottom, map);
  let rightTopPoint = latlng2Pixel(rightTop, map);

  // translate
  let p2_x = rightTopPoint.x - leftBottomPoint.x;
  let p2_y = rightTopPoint.y - leftBottomPoint.y;

  // rotate
  let point = { x: p2_x, y: p2_y };
  if (rotate.radians !== 0) {
    point = rotateCoordinate(point, rotate);
  }

  let width = point.x;
  let height = point.y;

  let gridParamsS = {
    x0: leftBottomPoint.x,
    y0: leftBottomPoint.y,
    width: width,
    height: height,
    dx: xCount + 1,
    dy: yCount + 1,
    rotate: gridParams.rotation,
  };

  let wellPrams = [];
  for (let well of wells) {
    let point = latlng2Pixel([well.latitude, well.longitude], map);
    // translate
    point = { x: point.x - leftBottomPoint.x, y: point.y - leftBottomPoint.y };
    if (rotate.radians !== 0) {
      // rotate
      point = rotateCoordinate(point, rotate);
    }
    wellPrams.push({
      well_uwi: well.uwi,
      screen_x: point.x,
      screen_y: point.y,
    });
  }
  interpolateWellData(
    {
      project_id: projectId,
      well_params: wellPrams,
      grid_params: gridParamsS,
      interpo_params: interpoParams,
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

/**
 * generate grids' screen point of x and y coordinates
 *
 * @param {*} gridParams
 * @param {*} map
 * @returns
 */
export function generateScreenGrids(gridParams, map) {
  let leftBottom = gridParams.leftBottom;
  let rightTop = gridParams.rightTop;
  let rotate = gridParams.rotate;
  let xCount = gridParams.xCount;
  let yCount = gridParams.yCount;

  // convert from latlng to pixel, and rotate screen coordinate
  let leftBottomPoint = latlng2Pixel(leftBottom, map);
  let rightTopPoint = latlng2Pixel(rightTop, map);
  // translate
  let p2_x = rightTopPoint.x - leftBottomPoint.x;
  let p2_y = rightTopPoint.y - leftBottomPoint.y;

  // rotate
  let point = { x: p2_x, y: p2_y };
  if (rotate.radians !== 0) {
    point = rotateCoordinate(point, rotate);
  }

  let width = point.x;
  let height = point.y;

  let dx = width / xCount;
  let dy = height / yCount;

  let startX = -dx / 2;
  let startY = -dy / 2;

  let xCoordinates = [];
  let yCoordinates = [];
  let currentX = startX;
  for (let i = 0; i <= xCount; i++) {
    xCoordinates.push(currentX);
    currentX += dx;
  }

  let currentY = startY;
  for (let j = 0; j <= yCount; j++) {
    yCoordinates.push(currentY);
    currentY += dy;
  }

  return {
    xCoordinates: xCoordinates,
    yCoordinates: yCoordinates,
  };
}

/**
 * generate grids points' lat,lng coordinates.
 *
 * @param {*} gridParams
 * @param {*} map
 * @returns
 */
export function generateGrids(gridParams, map) {
  let leftBottom = gridParams.leftBottom;
  let rightTop = gridParams.rightTop;
  let rotate = gridParams.rotate;
  let xCount = gridParams.xCount;
  let yCount = gridParams.yCount;

  // convert from latlng to pixel, and rotate screen coordinate
  let leftBottomPoint = latlng2Pixel(leftBottom, map);
  let rightTopPoint = latlng2Pixel(rightTop, map);

  // translate
  let p2_x = rightTopPoint.x - leftBottomPoint.x;
  let p2_y = rightTopPoint.y - leftBottomPoint.y;
  // rotate
  let point = { x: p2_x, y: p2_y };
  if (rotate.radians !== 0) {
    point = rotateCoordinate(point, rotate);
  }

  let width = point.x;
  let height = point.y;

  let dx = width / xCount;
  let dy = height / yCount;
  let startX = -dx / 2;
  let startY = -dy / 2;
  let grids = []; // lat, lng array

  let currY = startY;
  for (let j = 0; j <= yCount; j++) {
    let row = [];
    let currX = startX;
    for (let i = 0; i <= xCount; i++) {
      let point = { x: currX, y: currY };
      if (rotate.radians !== 0) {
        point = rotateCoordinate(point, rotate);
      }
      let latlng = pixel2latlng(
        [point.x + leftBottomPoint.x, point.y + leftBottomPoint.y],
        map
      );
      row.push([latlng.lat, latlng.lng]);
      currX += dx;
    }
    grids.push(row);
    currY += dy;
  }
  return grids;
}

/**
 * draw grids and property bind to grid.
 * @param {*} grids
 * @param {*} xnum
 * @param {*} ynum
 * @param {*} gridData
 * @param {*} gridGroup
 * @param {*} markerGroup
 */
export function drawGrids(grids, gridProperties, gridGroup) {
  let ynum = grids.length;
  let xnum = grids[0].length;
  let maxValue = 0;
  let minValue = 0;
  for (let j = 0; j < ynum; j++) {
    let rowMax = Math.max(...gridProperties[j]);
    let rowMin = Math.min(...gridProperties[j]);
    maxValue = Math.max(maxValue, rowMax);
    minValue = Math.max(minValue, rowMin);
  }

  for (let j = 0; j < ynum - 1; j++) {
    for (let i = 0; i < xnum - 1; i++) {
      let property = gridProperties[j][i];

      var latlngs = [
        grids[j][i],
        grids[j][i + 1],
        grids[j + 1][i + 1],
        grids[j + 1][i],
      ];
      // if (!isValid(latlngs[0]) || !isValid(latlngs[1]) || !isValid(latlngs[2]) || !isValid(latlngs[3])) {
      // }

      // eslint-disable-next-line
      var polygon = L.polygon(latlngs, {
        color: generateColor(minValue, maxValue, property),
        fill: true,
      }).addTo(gridGroup);
    }
  }
}

/**
 * covert lat,lng to screen x,y
 * @param {*} latlng
 * @param {*} gridParams
 * @param {*} map
 * @returns {x, y}
 **/
export function toScreenCoordinate(latlng, gridParams, map) {
  let leftBottom = gridParams.leftBottom;
  let rotate = gridParams.rotate;

  // convert from latlng to pixel, and rotate screen coordinate
  let leftBottomPoint = latlng2Pixel(leftBottom, map);
  let screenPoint = latlng2Pixel(latlng, map);

  // translate
  let p2_x = screenPoint.x - leftBottomPoint.x;
  let p2_y = screenPoint.y - leftBottomPoint.y;

  let point = { x: p2_x, y: p2_y };
  if (rotate.radians !== 0) {
    // rotate
    point = rotateCoordinate(point, rotate);
  }
  return point;
}

/**
 * compute left bottom, right top lat,lng of the rectangle area
 * the grid x,y points numbers, grid points lat,lng.
 * @param {*} latlng
 * @param {*} gridParams
 * @param {*} map
 * @returns {x, y}
 **/
export function computeGridParams(gridParams) {
  let xCount = Math.floor(gridParams.distance_x / gridParams.dx + 1);
  let yCount = Math.floor(gridParams.distance_y / gridParams.dy + 1);

  let distance_x = gridParams.dx * xCount;
  let distance_y = gridParams.dy * yCount;

  let rotate = getRotate(gridParams.rotation);
  let leftBottom = [gridParams.x0, gridParams.y0];

  let diagonalDist = Math.sqrt(
    Math.pow(distance_x, 2) + Math.pow(distance_y, 2)
  );
  let alpha = 0;
  if (distance_y !== 0) {
    alpha = Math.atan(distance_x / distance_y);
  }
  let bearing = rotate.radians + alpha;
  let rightTop = getLatLng(
    gridParams.x0,
    gridParams.y0,
    diagonalDist,
    bearing,
    "F"
  );

  return {
    xCount: xCount,
    yCount: yCount,
    rotate: rotate,
    alpha: alpha,
    bearing: bearing,
    leftBottom: leftBottom,
    rightTop: rightTop,
  };
}

export function isValid(latlng) {
  return latlng && latlng[0] && latlng[1];
}

function generateColor(minValue, maxValue, value) {
  if (maxValue === 0) {
    return "rgb(0, 0, 255)";
  }
  //credit to https://stackoverflow.com/a/2262117/2737978 for the idea of how to implement
  let percent = value / maxValue;
  let blue = 255;
  let green = 80;
  let red = Math.floor(255 * percent);
  let color = "rgb(" + red + "," + green + "," + blue + ")";
  return color;
}

export function findBounds(points) {
  let xArr = points.map((point, index) => {
    return point.x;
  });
  let yArr = points.map((point, index) => {
    return point.y;
  });

  xArr.sort((x1, x2) => {
    return x1 - x2;
  });
  yArr.sort((x1, x2) => {
    return x1 - x2;
  });

  return {
    leftBottom: [xArr[0], yArr[0]],
    width: xArr[xArr.length - 1] - xArr[0],
    height: yArr[yArr.length - 1] - yArr[0],
  };
}

export function latlng2Pixel(latlng, map) {
  let point = map.latLngToLayerPoint(latlng);
  return point;
}

export function pixel2latlng(point, map) {
  return map.layerPointToLatLng(point);
}

export function getRotate(degree) {
  let radians = (degree / 180) * Math.PI;
  return {
    radians: radians,
    cosA: Math.cos(-radians), // used for screen coordinate rotation, with positive x axis, so is negative.
    sinA: Math.sin(-radians),
  };
}

function rotateCoordinate(point, rotate) {
  let p2_1_x = rotate.cosA * point.x - rotate.sinA * point.y;
  let p2_1_y = rotate.sinA * point.x + rotate.cosA * point.y;

  return { x: p2_1_x, y: p2_1_y };
}
