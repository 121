import { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	setDeclinePhase,
	selectApplyUserParams,
	setApplyUserParams,
	selectDcaCommonParams,
	selectDcaUserParams,
	selectDcaRecommParams,
	selectAllDcaCommonParams,
	selectAllDcaUserParams,
	selectAllDcaRecommParams,
	setDcaRecommParams,
	selectDeclinePhase,
	selectActiveWell,
	setDcaCommonParams,
	setDcaAllModelsParams,
	setDcaUserParams,
	selectDcaAllModelsParams,
} from "features/decline_analysis/dcaProjectSlice";

import { getRecommData, getUserTuneData } from "service/dcaAnalysis";
import { DECLINE_PHASE_OPTIONS, DeclineModelOptions } from "app/codes";
import { parseFloatValue } from "utils/dataUtil";
import DcaManagementHistoryChart from "components/charts/DcaManagementHistoryChart";
import SectionSetting from "components/common/SectionSetting";
import { useUserPreference } from "components/charts/UserPreference";

function DeclineValidationChart(props) {
	const { loadingIconActions } = props;
	const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
	const [UserPreference, showUserPreference] = useUserPreference();

	const activeWell = useSelector(selectActiveWell);
	const dcaCommonParams = useSelector(selectDcaCommonParams);
	const dcaUserParams = useSelector(selectDcaUserParams);
	const dcaRecommParams = useSelector(selectDcaRecommParams);
	const declinePhase = useSelector(selectDeclinePhase);
	const allModelsParameters = useSelector(selectDcaAllModelsParams);

	const dcaAllCommonParams = useSelector(selectAllDcaCommonParams);
	const dcaAllUserParams = useSelector(selectAllDcaUserParams);
	const dcaAllRecommParams = useSelector(selectAllDcaRecommParams);

	// signal flag from ParameterPanel when click Apply button.
	const applyUserParams = useSelector(selectApplyUserParams);

	const dispatch = useDispatch();

	// historical production data
	const [historicalData, setHistoricalData] = useState(null);

	// computed dca data with default dca parameters
	const [recommData, setRecommData] = useState(null);
	const [recommValidationData, setRecommValidationData] = useState(null);

	// computed dca data with user tunned dca parameters
	const [userDcaData, setUserDcaData] = useState(null);
	const [userDcaValidationData, setUserDcaValidationData] = useState(null);

	const [seriesData, setSeriesData] = useState({});

	const [maxMonths, setMaxMonths] = useState(2);

	function clearState() {
		// setHistoricalData(null); // it cause: SI-58 comment 问题一
		setRecommData(null);
		setRecommValidationData(null);
		setUserDcaData(null);
		setUserDcaValidationData(null);
	}

	const handleApplyChange = useCallback(() => {
		if (
			activeWell == null ||
			declinePhase == null ||
			dcaCommonParams.validationMonths == null ||
			dcaCommonParams.startDate == null
		) {
			console.warn("parameters are not ready.");
			return;
		}

		showLoadingIcon && showLoadingIcon();
		getRecommData(
			{
				wellId: activeWell.uwi,
				declinePhase: declinePhase,
				startDate: dcaCommonParams.startDate,
				validationMonths: dcaCommonParams.validationMonths,
			},
			(result) => {
				// setHistoricalData(result.history);
				// setMaxMonths(Math.max(result.history.length - 5, 0));
				let allModels = [...result.other_models];
				let { best_model, b, di, qi } = result.dca;
				allModels.push({
					model_name: best_model,
					model_params: {
						qi: qi,
						di: di,
						b: b,
					},
				});
				dispatch(setDcaAllModelsParams(allModels));

				const recommData = result.dca;
				// let startDate = null;
				// if (recommData.fitted && recommData.fitted.length > 0) {
				//   let date = dcaCommonParams.startDate || recommData.fitted[0][0];
				//   startDate = date.slice(0, 10);
				// }
				// if (startDate !== dcaCommonParams.startDate) {
				//   dispatch(
				//     setDcaCommonParams({
				//       ...dcaCommonParams,
				//       startDate: startDate,
				//     })
				//   );
				// }
				if (recommData.best_model !== dcaRecommParams.declineModel) {
					dispatch(
						setDcaRecommParams({
							...dcaRecommParams,
							declineModel: recommData.best_model,
							declineRate: recommData.qi,
							ipRatio: recommData.di,
							b: recommData.b,
						})
					);
				}

				// fix fitted data, to make it connect to predicted data in the chart.
				recommData.fitted.push(recommData.predicted[0]);

				setRecommData(recommData.fitted);
				setRecommValidationData(recommData.predicted);

				// onFinished({ startDate: startDate });
				hideLoadingIcon && hideLoadingIcon();
			},
			(error) => {
				console.error(error);
				hideLoadingIcon && hideLoadingIcon();
			}
		);

		if (dcaUserParams.declineModel == null) {
			return;
		}
		let declineRate = parseFloatValue(dcaUserParams.declineRate);
		if (declineRate === 0) {
			return;
		}

		let userParams = {
			wellId: activeWell.uwi,
			declinePhase: declinePhase,
			startDate: dcaCommonParams.startDate,
			validationMonths: dcaCommonParams.validationMonths,
			declineModel: dcaUserParams.declineModel,
			declineRate: dcaUserParams.declineRate,
			ipRatio: dcaUserParams.ipRatio,
			b: dcaUserParams.b,
		};
		showLoadingIcon && showLoadingIcon();
		getUserTuneData(
			userParams,
			(result) => {
				const userData = result.dca;
				// fix fitted data, to make it connect to predicted data in the chart.
				userData.fitted.push(userData.predicted[0]);
				setUserDcaData(userData.fitted);
				setUserDcaValidationData(userData.predicted);
				hideLoadingIcon && hideLoadingIcon();
			},
			(error) => {
				console.error(error);
				hideLoadingIcon && hideLoadingIcon();
			}
		);
	}, [
		dispatch,
		activeWell,
		dcaCommonParams,
		dcaUserParams,
		declinePhase,
		dcaRecommParams,
		hideLoadingIcon,
		showLoadingIcon,
	]);

	useEffect(() => {
		if (applyUserParams) {
			dispatch(setApplyUserParams(false));
			handleApplyChange();
		}
		// eslint-disable-next-line
	}, [applyUserParams, handleApplyChange]);

	const refreshData = useCallback(
		(wellUwi, declinePhase) => {
			showLoadingIcon && showLoadingIcon();
			getRecommData(
				{
					wellId: wellUwi,
					declinePhase: declinePhase,
					startDate: dcaCommonParams.startDate,
					validationMonths: dcaCommonParams.validationMonths,
				},
				(result) => {
					setHistoricalData(result.history);
					setMaxMonths(Math.max(result.history.length - 5, 0));
					let allModels = [...result.other_models];
					let { best_model, b, di, qi } = result.dca;
					allModels.push({
						model_name: best_model,
						model_params: {
							qi: qi,
							di: di,
							b: b,
						},
					});
					dispatch(setDcaAllModelsParams(allModels));

					const recommData = result.dca;
					let startDate = null;
					if (recommData.fitted && recommData.fitted.length > 0) {
						let date =
							dcaCommonParams.startDate ||
							recommData.fitted[0][0];
						startDate = date.slice(0, 10);
					}
					if (startDate !== dcaCommonParams.startDate) {
						dispatch(
							setDcaCommonParams({
								...dcaCommonParams,
								startDate: startDate,
							})
						);
					}
					if (
						recommData.best_model !== dcaRecommParams.declineModel
					) {
						dispatch(
							setDcaRecommParams({
								...dcaRecommParams,
								declineModel: recommData.best_model,
								declineRate: recommData.qi,
								ipRatio: recommData.di,
								b: recommData.b,
							})
						);
					}

					// fix fitted data, to make it connect to predicted data in the chart.
					recommData.fitted.push(recommData.predicted[0]);

					setRecommData(recommData.fitted);
					setRecommValidationData(recommData.predicted);

					// onFinished({ startDate: startDate });
					hideLoadingIcon && hideLoadingIcon();
				},
				(error) => {
					console.error(error);
					hideLoadingIcon && hideLoadingIcon();
				}
			);

			if (dcaUserParams.declineModel == null) {
				return;
			}
			let declineRate = parseFloatValue(dcaUserParams.declineRate);
			if (declineRate === 0) {
				return;
			}

			const userParams = {
				wellId: wellUwi,
				declinePhase: declinePhase,
				startDate: dcaCommonParams.startDate,
				validationMonths: dcaCommonParams.validationMonths,
				declineModel: dcaUserParams.declineModel,
				declineRate: dcaUserParams.declineRate,
				ipRatio: dcaUserParams.ipRatio,
				b: dcaUserParams.b,
			};

			if (
				userParams.declineModel === "HYP" &&
				(!userParams.b || parseFloat(userParams.b) === 0.0)
			) {
				console.error("Wrong parameters with HYP, b is zero!");
				return;
			}
			showLoadingIcon && showLoadingIcon();
			getUserTuneData(
				userParams,
				(result) => {
					const userData = result.dca;
					// fix fitted data, to make it connect to predicted data in the chart.
					userData.fitted.push(userData.predicted[0]);
					setUserDcaData(userData.fitted);
					setUserDcaValidationData(userData.predicted);
					hideLoadingIcon && hideLoadingIcon();
				},
				(error) => {
					console.error(error);
					hideLoadingIcon && hideLoadingIcon();
				}
			);
		},
		[
			dispatch,
			dcaCommonParams,
			dcaUserParams,
			dcaRecommParams,
			hideLoadingIcon,
			showLoadingIcon,
		]
	);

	useEffect(() => {
		clearState();
		if (activeWell == null) {
			return;
		}
		const wellUwi = activeWell.uwi;

		refreshData(wellUwi, declinePhase);
	}, [activeWell, declinePhase, refreshData]);

	useEffect(() => {
		if (!activeWell || !declinePhase || !historicalData || !recommData) {
			return;
		}

		let xSeries = historicalData.map((item) => item[0].slice(0, 10));
		let dataSeries = [];
		dataSeries.push({
			Historical: {
				data: historicalData.map((item) => item[1]),
				lineType: "scatter",
				unit: { oil: "bbl/m", gas: "Mscf/m", liquid: "bbl/m" }[
					declinePhase
				],
			},
		});
		dataSeries.push({
			"Auto-Regression": {
				data: [
					...Array(
						historicalData.length -
							recommData.length -
							recommValidationData.length +
							1
					).fill(null),
					...recommData.map((item) => item[1]),
				],
				lineType: "line",
			},
		});
		dataSeries.push({
			"Validation-Auto-regression": {
				data: [
					...Array(
						historicalData.length - recommValidationData.length
					).fill(null),
					...recommValidationData.map((item) => item[1]),
				],
				lineType: "line",
			},
		});
		if (userDcaData) {
			dataSeries.push({
				"user-Arp's": {
					data: [
						...Array(
							historicalData.length -
								userDcaData.length -
								userDcaValidationData.length +
								1
						).fill(null),
						...userDcaData.map((item) => item[1]),
					],
					lineType: "line",
				},
			});
			dataSeries.push({
				"Validation-user-Arp's": {
					data: [
						...Array(
							historicalData.length - userDcaValidationData.length
						).fill(null),
						...userDcaValidationData.map((item) => item[1]),
					],
					lineType: "line",
				},
			});
		}
		setSeriesData({
			xSeries,
			dataSeries,
			config: {
				multiYAxis: false,
				leftYAxisLabel: DECLINE_PHASE_OPTIONS.find(
					(item) => item.value === declinePhase
				).name,
				title: activeWell.well_name,
			},
		});
		// eslint-disable-next-line
	}, [
		declinePhase,
		historicalData,
		recommData,
		recommValidationData,
		userDcaData,
		userDcaValidationData,
	]);

	function handleSubmit() {
		if (!activeWell?.uwi) {
			console.error("ActiveWell is null!");
			return;
		}
		const projectParams = {
			well_uwi: activeWell.uwi,
			records: [],
		};

		for (let phase of ["oil", "gas", "liquid"]) {
			const dcaCommonParams = dcaAllCommonParams[phase];
			const dcaRecommParams = dcaAllRecommParams[phase];
			const dcaUserParams = dcaAllUserParams[phase];
			const record = {
				decline_phase: phase,
				start_date: dcaCommonParams.startDate,
				validation_length: dcaCommonParams.validationMonths,
				dca_model_type: dcaRecommParams.declineModel,
				user_decline_model: dcaUserParams?.declineModel,
				user_ip_ratio: dcaUserParams.ipRatio,
				user_initial_decline_rate: dcaUserParams.declineRate,
				user_coefficient_b: dcaUserParams.b,
				recomm_ip_ratio: dcaRecommParams.ipRatio,
				recomm_initial_decline_rate: dcaRecommParams.declineRate,
				recomm_coefficient_b: dcaRecommParams.b,
				forecast_length: 0,
				eur_ml: 0,
				eur_recomm_dca: 0,
				period_prd_ml: 0,
				period_prd_recomm_dca: 0,
				period_prd_user_dca: 0,
			};
			projectParams.records.push(record);
		}
		props.submitDcaProject(projectParams);
	}

	const onCloseUserPreferences = useCallback(
		(values) => {
			const { declineRate, ipRatio, b, date, declineModel, months } =
				values || {};
			dispatch(
				setDcaUserParams({
					declineRate,
					ipRatio,
					b,
					declineModel,
					// startDate: date,
					// validationMonths: months,
				})
			);

			let t = { ...dcaCommonParams };
			date && (t = { ...t, startDate: date });
			months && (t = { ...t, validationMonths: months });
			dispatch(setDcaCommonParams(t));
			dispatch(setApplyUserParams(true));
		},
		[dispatch, dcaCommonParams]
	);

	const getUserPreferencesRecommendData = useCallback(() => {
		const { startDate, validationMonths } = dcaCommonParams || {};
		const { declineModel, declineRate, ipRatio, b } = dcaRecommParams || {};
		return {
			declineModelValue: DeclineModelOptions.find(
				(item) => item.value === declineModel
			)?.name,
			ipRatioValue: ipRatio?.toFixed(3),
			declineRateValue: declineRate?.toFixed(3),
			bValue: b?.toFixed(3),
			dateValue: startDate,
			months: validationMonths,
		};
	}, [dcaCommonParams, dcaRecommParams]);

	const getUserPreferencesUserTunedData = useCallback(() => {
		const { declineRate, ipRatio, b, declineModel } = dcaUserParams || {};
		return {
			declineModel: declineModel,
			ipRatio: ipRatio,
			declineRate: declineRate,
			b: b,
			date: dcaCommonParams.startDate,
		};
	}, [dcaCommonParams, dcaUserParams]);

	const handleDeclinePhaseChange = useCallback(
		(e) => {
			const value = e.target.value;
			dispatch(setDeclinePhase(value));
			// clearState();
		},
		[dispatch]
	);

	const phasesSelect = () => {
		return DECLINE_PHASE_OPTIONS.map((item, index) => {
			const elemId = "val_phase_opt_" + index.toString();
			return (
				<span key={index}>
					<input
						className="btn-check"
						id={elemId}
						type="radio"
						name="validate_phase"
						value={item.value}
						onChange={handleDeclinePhaseChange}
						checked={declinePhase === item.value}
						// disabled={!seriesData}
					/>
					<label htmlFor={elemId} className="btn-label header">
						{item.name}
					</label>
				</span>
			);
		});
	};

	return (
		<>
			<UserPreference
				recommendData={getUserPreferencesRecommendData()}
				userTuneData={getUserPreferencesUserTunedData()}
				onClose={onCloseUserPreferences}
				hasDate={true}
				maxMonths={maxMonths}
				minMonths={1}
				defaultParameters={allModelsParameters}
			/>
			<div className="cards-container h-100">
				<div className="cards-header d-flex flex-row justify-content-between align-items-center">
					<span className="header-title">{"Model & Validation"}</span>
					<div className="header-menu-container d-flex flex-row">
						<div>
							<span>Decline Phase :</span>
							{phasesSelect()}
						</div>
						<div className="cards-setting-btn ms-2">
							<SectionSetting disable />
						</div>
					</div>
				</div>
				<div className="cards-content d-flex flex-column justify-content-between align-items-stretch">
					<div className="validation-chart-wrapper pb-2">
						{seriesData && (
							<DcaManagementHistoryChart data={seriesData} />
						)}
						{/* 
            seriesData{
              xSeries: [],
              dataSeries: {'Oil Rate': {
                data: paramData.map(item => item.value),
                lineType: 'line',
                unit: PARAM_NAMES[param.value].unit,
              }},
              config: {
                multiYAxis: false
              },
            }
          */}
					</div>
					<div className="chart-btn-wrapper d-flex flex-row justify-content-between align-items-center">
						<button
							className="btn-custom"
							onClick={(e) => {
								showUserPreference();
							}}
						>
							Edit Parameters
						</button>
						<button
							className="btn-custom"
							onClick={(e) => {
								handleSubmit();
							}}
						>
							Validated to Save
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default DeclineValidationChart;
