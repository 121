import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveProjectId } from "features/project/projectsSlice";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";

function ProjectItem(props) {
  const { detail_url, project } = props;

  let navigate = useNavigate();
  let dispatch = useDispatch();

  function handleDeleteProject(e) {
    props.onDelete(project.id);
  }

  return (
    <div className="project-item d-flex flex-column justify-content-between">
      <div className="d-flex flex-row justify-content-end align-items-center">
        <div className="dropdown">
          <div
            className=""
            data-bs-toggle="dropdown"
            type="button"
            id="projectOptionsBtn"
          >
            <MoreVertIcon className="more-icon" />
          </div>
          <ul className="dropdown-menu" aria-labelledby="projectOptionsBtn">
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={handleDeleteProject}
              >
                Delete
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Edit
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <FolderIcon
            className="folder-icon"
            style={{ fill: "#0072E1" }}
            onClick={() => {
              dispatch(setActiveProjectId(project.id));
              navigate(
                detail_url +
                `?project_id=${ project.id }&project_type=${ project.project_type }&project_name=${ project.name }`
              );
            }}
          />
        </div>
        <span className="project-title">{project.name}</span>
      </div>
      <div className="project-desc mt-2">
        <span className="desc-head">Type: </span>
        <span className="desc-value">{project.project_type}</span>
        <span className="desc-head">Last Updated: </span>
        <span className="desc-value">{project.last_modified}</span>
      </div>
    </div>
  );
}

export default ProjectItem;
