import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { selectDeclinePhase } from "features/well_planner/plannerDcaSlice";
import { DECLINE_PHASE_OPTIONS } from "app/codes";
import { useSelector } from "react-redux";

// const CustomPalette = [
//   "blue",
//   "hsl(39, 100%, 50%)",
//   "hsl(59, 96%, 49%)",
//   "hsl(59, 49%, 50%)",
//   "hsl(120, 84%, 28%)",
//   "hsl(120, 95%, 21%)",
//   "hsl(120, 85%, 46%)",
//   "hsl(320, 96%, 50%)",
//   "hsl(320, 60%, 50%)",
//   "hsl(320, 90%, 70%)",
// ];

const COLORS = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];

const WellPlannerPerformance = (props) => {
  const { architectureSources, customPalette, x_data, y_series } = props;
  const declinePhase = useSelector(selectDeclinePhase);

  let data = y_series;
  const [options, setOptions] = useState({
    legend: {
      data: [' '],
      textStyle: {
        color: "#ccc",
      },
    },
    toolbox: {
      left: 'center',
      itemSize: 18,
      top: 15,
      feature: {
        dataZoom: {
          yAxisIndex: 'none'
        },
        restore: {},
        saveAsImage: { type: 'png' }
      }
    },
    axisPointer: {
      show: true,
      snap: true,
      label: {
        precision: 3,
      }
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: ['1970-1-1']
    },
    yAxis: [{
      type: "value",
      show: true,
      splitLine: {
        show: false,
        lineStyle: {
          opacity: "0.2",
          color: ["#fff"],
        },
      },
      name: DECLINE_PHASE_OPTIONS.find(item => item.value === declinePhase)?.unit.slice(0, -3),
      nameLocation: 'end',
      nameTextStyle: {
        color: "rgba(255,255,255,0.6)",
        align: "left",
      },
    }],
    series: [{
      name: ' ',
      type: 'line',
      data: [0],
    }],
  });

  let legendArr = [], seriesValues = [], xAxisData = [], yAxisArr = [];
  function getChartOptions() {
    seriesValues = [];
    // get xAxis, set it
    // const getXAxis = (data) => {
    //   return data.fitted.map(item => item[0]);
    // };
    // get yAxis, unit\name\index\offset\color, push into
    const getYAxis = (model, index) => {
      return {
        type: "value",
        axisLine: {
          onZero: false,
          show: true,
          lineStyle: {
            color: COLORS[index],
          },
        },
        splitLine: {
          show: index ? false : true,
          lineStyle: {
            opacity: "0.2",
            color: ["#fff"],
          },
        },
        axisTick: {
          show: true,
          color: COLORS[index],
        },
        axisLabel: {
          show: true,
          interval: 'auto',
        },
        yAxisIndex: index,
        name: DECLINE_PHASE_OPTIONS.find(item => item.value === declinePhase)?.unit,
        nameLocation: "end",
        nameTextStyle: {
          align: 'left',
          color: 'rgba(255,255,255,0.6)',
        },
      }
    };

    const getValues = (model, data, index) => {
      let seriesData = [];
      let predicateDataPos = 0;

      // fill nulls, if fitted data were later, fox ex: {fitted: [{date: 10, value: 0},] ...}, {history: [{date: 0, value: 0},]}
      const { fitted, lower, upper } = data;
      let allData = { fitted: [], lower: [], upper: [] };

      x_data.forEach(date => {
        allData.fitted.push([
          date,
          fitted.find(item => item[0] === date)?.[1],
        ]);

        allData.lower.push([
          date,
          lower.find(item => item[0] === date)?.[1],
        ]);

        allData.upper.push([
          date,
          upper.find(item => item[0] === date)?.[1],
        ])
      });

      switch (index) {
        case 0:
          seriesData = allData.fitted.map(item => item[1]);
          break;
        case 1:
          seriesData = allData.fitted.map((item, index) => {
            let fitData = allData.fitted[index];
            let lowerData = allData.lower[predicateDataPos];
            let result = (fitData[0] === lowerData[0]) ? lowerData[1] : null;
            if (result !== null) predicateDataPos++;
            return result;
          });
          break;
        case 2:
          seriesData = allData.fitted.map((item, index) => {
            let fitData = allData.fitted[index];
            let upperData = allData.upper[predicateDataPos];
            let lowerData = allData.lower[predicateDataPos];
            let result = (fitData[0] === lowerData[0]) ? (upperData[1] - lowerData[1]) : null;
            if (result !== null) predicateDataPos++;
            return result;
          });
          break;
        default:
          break;
      }
      return {
        name: architectureSources[index].name,
        type: 'line',
        stack: (index === 0) ? "" : "performance",
        data: seriesData,
        lineStyle: {
          opacity: (index === 0) ? 1 : 0,
        },
        areaStyle: {
          color: (index === 2) ? COLORS[0] : 'none',
          opacity: 0.4,
        },
        stackStrategy: 'all',
        smooth: true,
        symbol: 'none',
      };
    };

    const prepareOptions = () => {
      let tempOption = { ...options };
      tempOption.legend.data = legendArr;
      tempOption.xAxis.data = xAxisData;
      tempOption.xAxis.axisLabel = {
        ...(tempOption.xAxis.axisLabel ? tempOption.xAxis.axisLabel : {}),
        formatter: function (value, index) {
          if (!isNaN(value)) return value;
          var date = new Date(value);
          if (date === "Invalid Date") return value;
          var texts = [
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2),
            date.getFullYear()
          ];
          return texts.join('/');
        }
      };
      tempOption.yAxis = yAxisArr;
      tempOption.series = seriesValues;
      return tempOption;
    }

    ((result) => {
      [{}, {}, {}].map((model, index) => {
        // (index === 0) && legendArr.push(architectureSources[0].name);

        (xAxisData.length === 0) && (xAxisData = x_data);

        index === 0 && yAxisArr.push(getYAxis(model, index));

        seriesValues.push(getValues(model, data, index));

        return null;
      });
      setOptions(prepareOptions(legendArr, xAxisData, yAxisArr, seriesValues));
    })();
  }

  useEffect(() => {
    y_series && getChartOptions();
  }, [architectureSources, customPalette, x_data, y_series]);

  return (
    <>
      <ReactECharts
        option={options}
        notMerge={true}
        style={{ height: "100%" }}
      />
    </>
  );
};
export default WellPlannerPerformance;
