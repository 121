import { get, post, remove } from "./base";

export function getProjectGroups(projectId, groupId, onSuccess, onError) {
  let params = {};
  if (projectId != null) {
    params.project_id = projectId;
  }
  if (groupId != null) {
    params.group_id = groupId;
  }

  get(
    "/api/well_planner/dca/group/",
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function saveProjectGroups(
  projectId,
  projectGroups,
  onSuccess,
  onError
) {
  post(
    "/api/well_planner/dca/group/",
    { project_id: projectId, project_groups: projectGroups },
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function deleteProjectGroups(projectId, groupIds, onSuccess, onError) {
  let params = {};
  if (projectId != null) {
    params.project_id = projectId;
  }
  if (groupIds != null) {
    params.group_ids = groupIds;
  }

  remove(
    "/api/well_planner/dca/group/",
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function getGroupWells(groupId, onSuccess, onError) {
  let params = {};
  if (groupId != null) {
    params.group_id = groupId;
  }
  get(
    "/api/well_planner/group_well/",
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function saveGroupWells(groupId, wellIds, onSuccess, onError) {
  post(
    "/api/well_planner/group_well/",
    { group_id: groupId, well_uwis: wellIds },
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function deleteGroupWells(groupId, wellIds, onSuccess, onError) {
  let params = {};
  params.group_id = groupId;
  if (wellIds != null) {
    params.well_uwis = wellIds;
  }

  remove(
    "/api/well_planner/group_well/",
    null,
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function getProjectClusters(projectId, clusterId, onSuccess, onError) {
  let params = {};
  if (projectId != null) {
    params.project_id = projectId;
  }
  if (clusterId != null) {
    params.cluster_id = clusterId;
  }

  get(
    "/api/well_planner/dca/cluster/",
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function saveProjectClusters(
  projectId,
  inputParams,
  projectClusters,
  onSuccess,
  onError
) {
  post(
    "/api/well_planner/dca/cluster/",
    {
      project_id: projectId,
      input_params: inputParams,
      project_clusters: projectClusters,
    },
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function deleteProjectClusters(
  projectId,
  clusterIds,
  onSuccess,
  onError
) {
  let params = {};
  if (projectId != null) {
    params.project_id = projectId;
  }
  if (clusterIds != null) {
    params.cluster_ids = clusterIds;
  }

  remove(
    "/api/well_planner/dca/cluster/",
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function getClusterWells(clusterId, onSuccess, onError) {
  let params = {};
  if (clusterId != null) {
    params.cluster_id = clusterId;
  }
  get(
    "/api/well_planner/cluster_well/",
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function saveClusterWells(clusterId, wellIds, onSuccess, onError) {
  post(
    "/api/well_planner/cluster_well/",
    { cluster_id: clusterId, well_uwis: wellIds },
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

export function deleteClusterWells(clusterId, wellIds, onSuccess, onError) {
  let params = {};
  params.cluster_id = clusterId;
  if (wellIds != null) {
    params.well_uwis = wellIds;
  }

  remove(
    "/api/well_planner/cluster_well/",
    null,
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function getMLInput(data, onSuccess, onError) {
  get(
    "/api/well_planner/ml_input/",
    { project_id: data.project_id },
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    }
  );
}

export function saveMLInput(data, onSuccess, onError) {
  post(
    "/api/well_planner/ml_input/",
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}
