import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { setUserData, selectUserData } from "features/user/userSlice";

function Login() {
  const user = useSelector(selectUserData);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  let dispatch = useDispatch();
  let navigate = useNavigate();

  function handleLogin() {
    axios
      .post("/api/accounts/login/", {
        username,
        password,
      })
      .then((res) => {
        dispatch(setUserData(res.data));
        navigate("/home/", { replace: false });
      });
  }

  useEffect(() => {
    // const user = localStorage.getItem("user");
    if (user !== undefined && user !== null && user !== "null") {
      navigate("/home/", { replace: false });
    }
  }, [navigate]);

  return (
    <div className="authentication-bg">
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <a href="/" className="mb-5 d-block auth-logo">
                  <img
                    src="/static/images/logo-dark.png"
                    alt=""
                    height="22"
                    className="logo logo-dark"
                  />
                  <img
                    src="/static/images/logo-light.png"
                    alt=""
                    height="22"
                    className="logo logo-light"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to Minible.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <form
                      id="signin_form"
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleLogin();
                      }}
                    >
                      <div id="div_id_login" className="form-group">
                        {" "}
                        <label htmlFor="username" className=" requiredField">
                          Username<span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                            type="text"
                            value={username}
                            name="login"
                            className="form-control mb-2 textinput textInput"
                            placeholder="Enter Username"
                            id="username"
                            required
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                      <div id="div_id_password" className="form-group">
                        {" "}
                        <label htmlFor="password" className=" requiredField">
                          Password<span className="asteriskField">*</span>{" "}
                        </label>{" "}
                        <div>
                          {" "}
                          <input
                            type="password"
                            name="password"
                            className="form-control mb-2 textinput textInput"
                            placeholder="Enter Password"
                            id="password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            value={password}
                            required
                          />{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="form-group">
                        {" "}
                        <div id="div_id_remember" className="form-check">
                          {" "}
                          <input
                            type="checkbox"
                            name="remember"
                            className="form-check-input checkboxinput"
                            id="id_remember"
                          />{" "}
                          <label
                            htmlFor="id_remember"
                            className="form-check-label text-muted"
                          >
                            Remember Me
                          </label>{" "}
                        </div>{" "}
                      </div>
                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <div className="signin-other-title">
                          <h5 className="mb-3 title text-muted">
                            Sign in with
                          </h5>
                        </div>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <a
                              href="/account/facebook/login/?next=%2F"
                              className="social-list-item bg-primary text-white border-primary"
                            >
                              <i className="mdi mdi-facebook"></i>
                            </a>
                          </li>

                          <li className="list-inline-item">
                            <a
                              href="/account/google/login/?next=%2F"
                              className="social-list-item bg-danger text-white border-danger"
                            >
                              <i className="mdi mdi-google"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/account-recovery/" className="text-muted">
                          <i className="mdi mdi-lock me-1"></i> Forgot your
                          password?
                        </Link>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0 text-muted">
                          Don&apos;t have an account ?{" "}
                          <Link
                            to="/register/"
                            className="fw-medium text-primary"
                          >
                            {" "}
                            Signup now{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="mt-5 text-center">
                <p>
                  © <script>document.write(new Date().getFullYear())</script>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
