import React from 'react'
import ProjectList from './components/ProjectList'
import { useQuery } from '@tanstack/react-query'
import { getCOProjects } from 'service/completionOptimizer'

const ProjectSummaryPage = () => {
  const { data: projects, refetch } = useQuery({
    queryKey: ['co_getProjectList'],
    queryFn: () => getCOProjects()
  })

  return (
    <div className="p-2" style={{
      overflowY: 'auto',
      height: '100vh',
      overflowX: 'hidden'
    }}>
      <ProjectList projects={projects?.map(p => ({
        id: p.pk,
        name: p.general?.title,
        type: p.general?.fluid,
        updated_date: p.updated_at,
        backupId: p.general?.id
      }))} refetch={refetch} />
    </div>
  )
}

export default ProjectSummaryPage