import { get, post, remove } from "./base";

export function saveGridParams(
  projectId,
  assetId,
  wellIds,
  gridParams,
  onSuccess,
  onError
) {
  post(
    "/api/well_planner/grid_params",
    {
      project_id: projectId,
      asset_id: assetId,
      uwis: wellIds,
      grid_params: gridParams,
    },
    null,
    {},
    (res) => {
      onSuccess(res);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

export function interpolateWellData(data, onSuccess, onError) {
  post(
    "/api/well_planner/interpolate",
    data,
    null,
    {},
    (res) => {
      onSuccess(res);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getHyperWells(projectId, onSuccess, onError) {
  get(
    "/api/well_planner/hyper_well/",
    { project_id: projectId },
    {},
    (res) => {
      onSuccess(res.data.wells);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function saveHyperWells(projectId, wells, onSuccess, onError) {
  post(
    "/api/well_planner/hyper_well/",
    { project_id: projectId, wells: wells },
    null,
    {},
    (res) => {
      onSuccess(res.data.wells);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

// use project_id to delete all wells of this project,
// use well_ids, only delete wells in well_ids.
export function removeHyperWells(projectId, wellIds, onSuccess, onError) {
  let params = {};
  if (projectId) {
    params.project_id = projectId;
  }
  // if (wellIds) {
  //   params.well_ids = wellIds;
  // }
  remove(
    "/api/well_planner/hyper_well/",
    { well_ids: wellIds },
    { project_id: projectId },
    {},
    (res) => {
      onSuccess(res.data.wells);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function findHyperWellNearest(
  projectId,
  wellIds,
  assetId,
  hyperWells,
  params,
  // wellGroupsData,
  onSuccess,
  onError
) {
  post(
    "/api/well_planner/find_nearest_neighbors",
    {
      project_id: projectId,
      well_uwis: wellIds,
      asset_id: assetId,
      hyper_wells: hyperWells,
      params: params,
      // well_groups: wellGroupsData
    },
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}
