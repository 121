import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectDevDataStructure,
  setDevDataStructure,
} from "features/input/dataManagerSlice";
import { getWellDevInputConfig } from "service/dataInput";
import NullFile from "./NullFile";

function DeviationInput(props) {
  const { inputData } = props;
  const devDataStructure = useSelector(selectDevDataStructure);
  const [wellNames, setWellNames] = useState(null);
  const [inputColumns, setInputColumns] = useState(null);
  const [devData, setDevData] = useState(null);
  const [columnDictionary, setColumnDictionary] = useState([]);

  let dispatch = useDispatch();

  useEffect(() => {
    if (inputData == null || inputData.length === 0) {
      return;
    }
    setWellNames(inputData.map((item) => { return item.well_name }));
    setInputColumns(inputData[0].headers);

    let wellDev = {};
    for (let item of inputData) {
      wellDev[item.well_name] = item.data;
    }
    setDevData(wellDev);
  }, [inputData]);

  useEffect(() => {
    if (wellNames == null || wellNames.length === 0) {
      return;
    }
    refreshData();
  }, [wellNames]);

  useEffect(() => {
    if (inputColumns == null || columnDictionary.length == 0) {
      return;
    }
    initDataStructure();
  }, [inputColumns, columnDictionary]);

  function refreshData() {
    getWellDevInputConfig(
      (result) => {
        const columns = result.map((x) => {
          return {
            header: x.name,
          };
        });
        setColumnDictionary([{ header: "N/A" }, ...columns]);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  function initDataStructure() {
    const initialSelection = inputColumns.map((x, index) => {
      let selectedCol = null;
      let header = x.trim().toLowerCase().replace(/\s/g, "_");
      let i = columnDictionary.findIndex(
        (e) =>
          header.startsWith(e.header) ||
          e.header.startsWith(header) ||
          header.includes(e.header) ||
          e.header.includes(header)
      );
      if (i !== -1) {
        selectedCol = columnDictionary[i].header;
      } else {
        selectedCol = columnDictionary[0].header;
      }
      return selectedCol;
    });

    dispatch(setDevDataStructure(initialSelection));
  }

  function handleChangeColumn(e, i) {
    const unpacked = devDataStructure.map((el, index) => {
      if (index === i) {
        return e.target.value;
      } else {
        return el;
      }
    });
    dispatch(setDevDataStructure(unpacked));
  }

  function generateTableBody() {
    if (devData == null || wellNames == null) {
      return null;
    }
    return wellNames.map((key, rIdx) => {
      let records = devData[key];
      return records.map((el, index) => {
        return (
          <div className="data-row" key={(index = 1) + "_" + 0}>
            <div className="data-cell">
              {key}
            </div>
            {el.map((val, subIndex) => {
              return (
                <div
                  className="data-cell"
                  key={(index = 1) + "_" + (subIndex + 1)}
                >
                  {val}
                </div>
              );
            })}
          </div>
        );
      });
    });
  }

  return (
    <div className="table-container d-flex flex-column justify-content-start align-items-start">
      {inputColumns == null ||
        devDataStructure == null ? (
        <NullFile />
      ) : (
        <>
          <div className="table-header">
            <div className="header-cell" key={0}>
              Well Name
            </div>
            {inputColumns.map((x, i) => {
              return (
                <div className="header-cell" key={i + 1}>
                  {x}
                </div>
              );
            })}
          </div>
          <div className="table-header">
            <div className="header-cell" key={0}></div>
            {inputColumns.map((x, i) => {
              return (
                <div className="header-cell" key={i}>
                  <select
                    id={`attribute_id${i}`}
                    onChange={(e) => {
                      handleChangeColumn(e, i);
                    }}
                    value={devDataStructure[i]}
                  >
                    {columnDictionary.map((y, j) => {
                      return (
                        <option key={i + "_" + j} value={y.header}>
                          {y.header}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
            })}
          </div>
          <div className="table-body">{generateTableBody()}</div>
        </>
      )}
    </div>
  );
}

export default DeviationInput;
