import { get, post } from "./base";

export function getPrdInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/productions/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function submitPrdForm(prdData, prdDataStructure, onSuccess, onError) {
  const dataArray = new FormData();
  dataArray.append(`inputData`, JSON.stringify({ data: prdData, dataStructure: prdDataStructure }));
  post(
    "/api/datamgr/productions/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getInjInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/injections/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function submitInjForm(injData, injDataStructure, onSuccess, onError) {
  const dataArray = new FormData();
  dataArray.append(`inputData`, JSON.stringify({ data: injData, dataStructure: injDataStructure }));
  post(
    "/api/datamgr/injections/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getWellInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/wells/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function submitWellForm(data, dataStructure, onSuccess, onError) {
  const dataArray = new FormData();
  dataArray.append(`inputData`, JSON.stringify({ data, dataStructure }));
  post(
    "/api/datamgr/wells/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function addWellLogColumn(data, onSuccess, onError) {
  post(
    "/api/logs/dict/",
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getWellLogInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/logs/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function submitWellLogForm(
  well_info,
  logs,
  dataStructure,
  onSuccess,
  onError
) {
  const dataArray = new FormData();
  dataArray.append(
    `inputData`,
    JSON.stringify({ well_info, logs, dataStructure })
  );
  post(
    "/api/datamgr/logs/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getWellTopInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/tops/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function submitWellTopForm(tops, dataStructure, onSuccess, onError) {
  const dataArray = new FormData();
  dataArray.append(`inputData`, JSON.stringify({ tops, dataStructure }));
  post(
    "/api/datamgr/tops/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function addWellTopColumn(data, onSuccess, onError) {
  post(
    "/api/tops/dict/",
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getWellDevInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/deviations/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function submitWellDevForm(
  deviations,
  dataStructure,
  onSuccess,
  onError
) {
  const dataArray = new FormData();
  dataArray.append(
    `inputData`,
    JSON.stringify({ deviations, dataStructure })
  );
  post(
    "/api/datamgr/deviations/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getAlarmInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/alarms/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function submitAlarmForm(alarms, dataStructure, onSuccess, onError) {
  const dataArray = new FormData();
  dataArray.append(`inputData`, JSON.stringify({ alarms, dataStructure }));
  post(
    "/api/datamgr/alarms/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getPlanningInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/plannings/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}

export function submitPlanningForm(plannings, dataStructure, onSuccess, onError) {
  const dataArray = new FormData();
  dataArray.append(`inputData`, JSON.stringify({ plannings, dataStructure }));
  post(
    "/api/datamgr/plannings/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getEventsInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/events/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}


export function submitEventsForm(events, dataStructure, onSuccess, onError) {
  const dataArray = new FormData();
  dataArray.append(`inputData`, JSON.stringify({ events, dataStructure }));
  post(
    "/api/datamgr/events/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}

export function getSurveyInputConfig(onSuccess, onError) {
  get(
    "/api/datamgr/surveys/input_config",
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    }
  );
}


export function submitSurveyForm(survey, dataStructure, onSuccess, onError) {
  const dataArray = new FormData();
  dataArray.append(`inputData`, JSON.stringify({ survey, dataStructure }));
  post(
    "/api/datamgr/surveys/input/",
    dataArray,
    null,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      onError(error);
    },
    true
  );
}