import { get, post, put, remove } from "./base";

/**
 *
 * @param {*} params :
 * {
 *  products - oil, gas, oil_diff, ng_diff, ngl
 *  start_date
 *  end_date, yyyy-mm-dd
 * }
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */

export function getWellSalePrices(uwi, params, onSuccess, onError) {
  return get(
    `/api/economics/well/${ uwi }/sale/prices/`,
    params,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    }
  );
}

/**
 * 
 * @param {*} data
 * [
 * {
        "date": "2019-08-01",
        "oil": 57.81,
        "gas": 2.22,
        "oil_diff": 8.88,
        "ng_diff": 0.34,
        "ngl": 8.2
 * },
 * ]
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function addWellSalePrice(uwi, data, onSuccess, onError) {
  return post(
    `/api/economics/well/${ uwi }/sale/prices/`,
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => {
      console.error(error);
      onError(error);
    },
    true
  );
}

/**
 * 
 * @param {*} data
 * [
 * {
 *      "id": 14,
        "date": "2019-08-01",
        "oil": 57.81,
        "gas": 2.22,
        "oil_diff": 8.88,
        "ng_diff": 0.34,
        "ngl": 8.2
 * },
 * ]
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function updateWellSalePrice(uwi, data, onSuccess, onError) {
  return put(
    `/api/economics/well/${ uwi }/sale/prices/`,
    data,
    null,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    true
  );
}

/**
 *
 */
export function deleteWellSalePrice(uwi, ids, onSuccess, onError) {
  return remove(
    `/api/economics/well/${ uwi }/sale/prices/`,
    { ids: ids },
    null,
    {},
    (res) => {
      onSuccess?.();
    },
    (error) => {
      console.error(error);
      onError?.(error);
    }
  );
}
