import { get, post, remove } from "./base";

import { getDateBefore, formatDate } from "utils/dateUtil";

export function getProductionsData(
  uwis,
  assetId,
  products,
  startDate,
  endDate,
  sumAllWells = false,
  onSuccess,
  onError
) {
  let params = buildReqParams(
    uwis,
    assetId,
    products,
    startDate,
    endDate,
    sumAllWells
  );

  get(
    "/api/productions",
    params,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getCumulativeData(
  uwis,
  assetId,
  products,
  startDate,
  endDate,
  onSuccess,
  onError
) {
  get(
    "/api/productions/total",
    buildReqParams(uwis, assetId, products, startDate, endDate, true),
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getAvgData(
  uwis,
  assetId,
  products,
  startDate,
  endDate,
  onSuccess,
  onError
) {
  get(
    "/api/productions/total",
    buildReqParams(uwis, assetId, products, startDate, endDate, true, true),
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getLastWeekCumulativeData(
  apiKey,
  assetId,
  products,
  weeks,
  onSuccess,
  onError
) {
  const startDate = getDateBefore(new Date(), weeks * 7);
  const endDate = new Date();

  getCumulativeData(
    apiKey,
    assetId,
    products,
    startDate,
    endDate,
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getLastWeekAvgData(
  apiKey,
  assetId,
  products,
  weeks,
  onSuccess,
  onError
) {
  const startDate = getDateBefore(new Date(), weeks * 7);
  const endDate = new Date();

  getAvgData(
    apiKey,
    assetId,
    products,
    startDate,
    endDate,
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getTotalProductionsData(
  uwis,
  assetId,
  products,
  startDate,
  endDate,
  onSuccess,
  onError
) {
  get(
    "/api/productions/total",
    buildReqParams(uwis, assetId, products, startDate, endDate),
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getProductions(queryData, onSuccess, onError) {
  get(
    "/api/productions/editor/",
    queryData,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getProductions2(queryData, onSuccess, onError) {
  get(
    "/api/productions/editor/",
    queryData,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function saveProductions(inputData, onSuccess, onError) {
  post(
    "/api/productions/editor/",
    inputData,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}

export function removeProductions(inputData, onSuccess, onError) {
  remove(
    "/api/productions/editor/",
    inputData,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

function buildReqParams(uwis, assetId, products, startDate, endDate, sum=false, average=false) {
  let params = {};

  if (uwis != null) {
    if (Array.isArray(uwis)) {
      uwis = uwis.join(",");
    }
    params.uwis = uwis;
  }
  if (assetId != null) {
    params.asset_id = assetId;
  }
  if (products != null) {
    if (Array.isArray(products)) {
      products = products.join(",");
    }
    params.products = products;
  }

  if (startDate != null) {
    params.start_date = formatDate(startDate);
  }
  if (endDate != null) {
    params.end_date = formatDate(endDate);
  }
  if (sum) {
    params.sum = sum
  }
  if (average) {
    params.average = average;
  }
  return params;
}

export function getProductionPrescriptive(queryData, onSuccess, onError) {
  get(
    "/api/productions/prescriptive/",
    queryData,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}