import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Empty } from "antd";

import SectionSetting from "components/common/SectionSetting";
import TypeCurveUncertainty from "components/charts/TypeCurveUncertainty";

import {
  setDeclinePhase,
  selectDeclinePhase,
  selectProjectGroupDataItems,
  selectGroupNormalizeBy,
} from "features/decline_analysis/typeCurveProjectSlice";
import { getPerformance } from "service/typeCurve";
import { DECLINE_PHASE_OPTIONS } from "app/codes";

function GroupUncertaintyChart(props) {
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const declinePhase = useSelector(selectDeclinePhase);
  // const product = declinePhase
  //   ? PHASE_MODEL_MAP[declinePhase]
  //   : PHASE_MODEL_MAP[DECLINE_PHASE_OPTIONS[0].value];

  const projectGroupDataItems = useSelector(selectProjectGroupDataItems);
  const normalizedByParam = useSelector(selectGroupNormalizeBy);

  const dispatch = useDispatch();
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);

  const CurveTypeOptions = [
    { value: "hyp", name: "Auto-regression" },
    // { value: "exp", name: "auto-Arp's(EXP)" },
    { value: "ml", name: "User-Arps" },
  ];

  const [curveTypeIndex, setCurveTypeIndex] = useState(0);
  const [historicalData, setHistoricalData] = useState(null);
  const [hypPredict, setHypPredict] = useState(null);
  const [expPredict, setExpPredict] = useState(null);
  const [mlPredict, setMlPredict] = useState(null);

  const refreshData = useCallback(
    (groupIndex, projectGroupDataItem, phase, normalizeBy) => {
      let wellIds = projectGroupDataItem.group_wells;
      if (wellIds == null || wellIds.length === 0) {
        return;
      }
      showLoadingIcon && showLoadingIcon();
      getPerformance(
        {
          uwis: wellIds,
          decline_phase: phase || "oil",
          normalize_by: normalizeBy,
        },
        (result) => {
          setHistoricalData(result.history);
          setHypPredict(result.dca);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [hideLoadingIcon, showLoadingIcon]
  );

  useEffect(() => {
    if (projectGroupDataItems == null || projectGroupDataItems.length === 0) {
      setHistoricalData(null);
      setHypPredict(null);
      setExpPredict(null);
      setMlPredict(null);
      if (selectedGroupIndex != null) {
        setSelectedGroupIndex(null);
      }
      return;
    }
    if (
      selectedGroupIndex == null ||
      selectedGroupIndex > projectGroupDataItems.length - 1
    ) {
      setSelectedGroupIndex(0);
    }
  }, [selectedGroupIndex, projectGroupDataItems]);

  useEffect(
    () => {
      if (
        selectedGroupIndex == null ||
        projectGroupDataItems == null ||
        projectGroupDataItems.length === 0 ||
        normalizedByParam == null ||
        declinePhase == null
      ) {
        return;
      }

      setHistoricalData(null);
      setHypPredict(null);
      setExpPredict(null);
      setMlPredict(null);

      let projectGroupDataItem = projectGroupDataItems[selectedGroupIndex];
      if (projectGroupDataItem == null) {
        return;
      }
      refreshData(
        selectedGroupIndex,
        projectGroupDataItem,
        declinePhase,
        normalizedByParam
      );
    },
    // eslint-disable-next-line
    [declinePhase, selectedGroupIndex, normalizedByParam]
  );

  function handleDeclinePhaseChanged(e) {
    let value = e.target.value;
    dispatch(setDeclinePhase(value));
  }

  function handleClusterSelectChanged(e) {
    let value = e.target.value;
    setSelectedGroupIndex(value);
  }

  const groupsSelector = useMemo(() => {
    if (projectGroupDataItems == null || projectGroupDataItems.length === 0) {
      return;
    }

    return projectGroupDataItems.map((projectItem, index) => {
      return (
        <option key={index} value={index}>
          {projectItem.project_group.group_name}
        </option>
      );
    });
  }, [projectGroupDataItems]);

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <div className="header-title">Uncertainty</div>
        <div className="header-menu-container d-flex flex-row">
          <select
            className="ms-2"
            value={selectedGroupIndex || ""}
            onChange={handleClusterSelectChanged}
          >
            {groupsSelector}
          </select>
          <select
            className="ms-2"
            value={declinePhase}
            onChange={handleDeclinePhaseChanged}
          >
            {DECLINE_PHASE_OPTIONS.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <select
            className="ms-2"
            value={curveTypeIndex}
            onChange={(e) => {
              let value = e.target.value;
              if (typeof value !== "number") {
                value = parseInt(value);
              }
              setCurveTypeIndex(value);
            }}
          >
            {CurveTypeOptions.map((item, index) => {
              return (
                <option key={index} value={index}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content">
        {(hypPredict || expPredict || mlPredict) && curveTypeIndex !== 1 && (
          <TypeCurveUncertainty
            historical_data={historicalData}
            hypPredict={curveTypeIndex === 0 ? hypPredict : null}
            expPredict={curveTypeIndex === 1 ? expPredict : null}
            mlPredict={curveTypeIndex === 2 ? mlPredict : null}
            yUnit={
              DECLINE_PHASE_OPTIONS.find((item) => item.value === declinePhase)
                ?.unit
            }
          />
        )}
        {curveTypeIndex === 1 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ color: "rgba(255,255,255, 0.6)" }}
          >
            {"No uncertainty interval calculated for user-defined Arp's model"}
          </Empty>
        )}
        {curveTypeIndex !== 1 && !(hypPredict || expPredict || mlPredict) && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ color: "rgba(255,255,255, 0.6)" }}
          >
            {
              "Please set clusters first! must select: Wellbore length to show data"
            }
          </Empty>
        )}
      </div>
    </div>
  );
}

export default GroupUncertaintyChart;
