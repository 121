import React, { useState, useEffect } from "react";

import { connect, useDispatch, useSelector } from "react-redux";

import { getCrossSectionMapFeatures } from "utils/map";
import LMap from "components/map/Map";
import { selectActiveAssetId, selectAssetList, selectSelectedWells, setSelectedWells } from "features/petrophysics/petrophysicsSlice";
import { getAssetWellList } from "service/petrophysics";

const PpCrossSectionMap = (props) => {
    const { loadingIconActions } = props;
    const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
    const dispatch = useDispatch();
    const activeAsset = useSelector(selectActiveAssetId);
    const selectedWellList = useSelector(selectSelectedWells);
    const assetList = useSelector(selectAssetList);
    const [features, setFeatures] = useState([]);
    const [zoom] = useState(15);
    const [center, setCenter] = useState([0.0, 46.036]);
    const [bounds, setBounds] = useState(null);
    const [wells, setWells] = useState(null);

    useEffect(() => {
        if (activeAsset == null) return;
        // load all wells
        showLoadingIcon && showLoadingIcon();
        getAssetWellList(activeAsset,
            (res) => {
                setWells(res);
                hideLoadingIcon && hideLoadingIcon();
            },
            (e) => {
                console.error('load asset and wells fail,', e);
                hideLoadingIcon && hideLoadingIcon();
            }
        )
    }, [hideLoadingIcon, activeAsset, showLoadingIcon]);

    useEffect(() => {
        if (assetList == null || assetList.length === 0 || activeAsset == null) return;
        if (wells) {
            const wellsWithLog = assetList[activeAsset];
            if (!wellsWithLog) {
                console.warn("No wells with log!");
                return;
            }
            updateMapData(wells.filter(({ uwi }) => wellsWithLog.find(w => w.uwi === uwi)));
        }
    }, [wells, assetList, activeAsset]);

    useEffect(() => {
        if (!selectedWellList) {
            return;
        }
        let newSet = new Set(selectedWellList);
        selectedWellList.map(uwi => newSet.add(uwi));
    }, [selectedWellList]);

    function updateMapData(wellsRecords) {
        const [tempFeatures, mapCenter, mapBounds] = getCrossSectionMapFeatures(wellsRecords);
        setFeatures(tempFeatures);
        setCenter(mapCenter);
        setBounds(mapBounds);
    }

    function handleObjectiveSelected(objective) {
        !selectedWellList.includes(objective.apiId) && dispatch(setSelectedWells([...selectedWellList, objective.apiId]));
    }

    return (
        <div className="cards-container h-100">
            <div className="cards-header d-flex flex-row justify-content-between align-items-center">
                <span className="header-title">Map</span>
            </div>
            <div className="cards-content">
                <div className="chart-container">
                    <LMap
                        objectives={features ? features : []}
                        center={center}
                        zoom={zoom}
                        bounds={bounds}
                        selectedObjectiveIds={
                            selectedWellList && (selectedWellList.length > 0)
                                ? new Set(selectedWellList)
                                : new Set()
                        }
                        objectiveSelectedHandler={handleObjectiveSelected}
                        selectRed={true}
                        showArrow={true}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(PpCrossSectionMap);
