import { Fragment, useEffect, useState } from "react";
import { DECLINE_PHASE_OPTIONS, DatePeriodOptions } from "app/codes";
import SectionSetting from "components/common/SectionSetting";
// import PredictChartByDca from "components/charts/PredictChartByDca";
import PredictChart from "components/charts/PredictChart";

function PrescriptiveChart(props) {
  const {
    wellId,
    assetId,
    historicalVisible,
    setHistoricalVisible,
    loadingIconActions,
  } = props;
  const modelOptions = DECLINE_PHASE_OPTIONS;

  const [activePeriod, setActivePeriod] = useState(1);

  const handlePeriodChanged = (days) => {
    setActivePeriod(days);
  };

  const [models, setModels] = useState([modelOptions[0]]);
  const [selectedIndexes, setSelectedIndexes] = useState([0]);

  useEffect(() => {
    if (selectedIndexes == null || selectedIndexes.length === 0) {
      return;
    }
    let selectedOptions = modelOptions.filter((option, index) =>
      selectedIndexes.includes(index)
    );
    setModels(
      selectedOptions.map((option) => {
        return { value: option.value, name: option.name };
      })
    );
  }, [selectedIndexes, modelOptions]);

  const handleModelChanged = (e) => {
    let indexes = [];
    for (let i = 0; i < e.target.options.length; i++) {
      if (e.target.options[i].selected) {
        let value = e.target.options[i].value;
        if (typeof value === "string") {
          value = parseInt(value);
        }
        indexes.push(value);
      }
    }
    setSelectedIndexes(indexes);
  };

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <div>
          <input
            type="radio"
            className="btn-check"
            name="chartTypeOptions"
            id="chartType1"
            defaultChecked={historicalVisible ? true : false}
            onClick={() => {
              setHistoricalVisible(true);
            }}
          />
          <label htmlFor="chartType1" className="btn-label header">
            Historical
          </label>
          <input
            onClick={() => {
              setHistoricalVisible(false);
            }}
            type="radio"
            className="btn-check"
            name="chartTypeOptions"
            id="chartType2"
            defaultChecked={!historicalVisible ? true : false}
          />
          <label htmlFor="chartType2" className="btn-label header">
            Prescriptive
          </label>
        </div>
        <div className="d-flex flex-row justify-content-end align-items-center">
          <div>
            <select value={selectedIndexes[0]} onChange={handleModelChanged}>
              {modelOptions.map((item, index) => {
                return (
                  <option key={index} value={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            {DatePeriodOptions.map((item, index) => {
              return (
                <Fragment key={index}>
                  <input
                    type="radio"
                    className="btn-check"
                    name="periodOpt"
                    id={"periodOpt" + index.toString()}
                    onChange={() => {
                      handlePeriodChanged(item.days);
                    }}
                    defaultChecked={activePeriod === item.days}
                  />
                  <label
                    htmlFor={"periodOpt" + index.toString()}
                    className="btn-label header"
                  >
                    {item.name}
                  </label>
                </Fragment>
              );
            })}
          </div>
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content">
        <div className="chart-container d-fex justify-content-center align-items-center">
          {
            // wellId ? <PredictChartByDca
            //   wellId={wellId}
            //   assetId={assetId}
            //   model={models[0]}
            //   period={activePeriod}
            //   loadingIconActions={loadingIconActions}
            // /> :
            <PredictChart
              wellId={wellId}
              assetId={assetId}
              model={models[0]}
              period={activePeriod}
              loadingIconActions={loadingIconActions}
            />
          }
          {/* <PredictChartByDca
            wellId={wellId}
            assetId={assetId}
            model={models[0]}
            period={activePeriod}
            loadingIconActions={loadingIconActions}
          /> */}
        </div>
      </div>
    </div>
  );
}

export { PrescriptiveChart };
