import React from 'react'
import ProjectListItem from '../ProjectListItem'

const ProjectList = ({
  projects = [],
  refetch
}) => {
  return (
    <div className="row flex-wrap gy-4">
      {projects.map(project => (
        <ProjectListItem key={project.id} project={project} onDeleted={refetch} />
      ))}
    </div>
  )
}

export default ProjectList