import Select from "react-select";

function DcaManagementModelSetting(props) {
    const { onChange, values, options } = props;

    return (
        <div className="popup-wrapper d-flex flex-column" style={{ width: '500px' }}>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <span>Model setting</span>
                <div
                    onClick={(e) => {
                        props.onClose();
                    }}
                >
                    <span>X</span>
                </div>
            </div>
            <div>Please select model parameters:</div>
            <Select
                defaultValue={values}
                onChange={(v) => { onChange(v.map(i => i.value)) }}   //{handleModelChanged}
                isMulti
                name="model"
                options={options}
                styles={{
                    width: '300px',
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'red' : 'grey',
                    }),
                    menuList: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? 'red' : 'black',
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? 'grey' : 'black',
                    }),
                }}
            />
        </div>
    );
}

export default DcaManagementModelSetting;
