import { Fragment, useEffect, useState } from "react";
import { Card, ConfigProvider, DatePicker, Row, Select, Spin } from "antd";
import { Form, Input, Button, List, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteWellTopAttr,
  getWellInfo,
  getWellTops,
  saveWellTops,
} from "service/petrophysics";
import enUS from "antd/locale/en_US";
import dayjs from "dayjs";
import { getAssets } from "service/assets";
import {
  deleteWell,
  saveWellSummary,
  updateWellSummary,
} from "service/wellSummary";
import {
  getWellDeviation,
  removeWellDeviation,
  saveWellDeviation,
} from "service/deviation";
// import { ExclamationCircleFilled } from "@ant-design/icons";
import EditorTable from "../component/EditorTable";
import useLoading from "components/common/useLoading";

const { Title } = Typography;
// const { confirm } = Modal;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const wellInputs = [
  {
    name: "name",
    label: "Name",
    fields: [
      {
        name: "well_name",
        label: "Well Name:",
        rule: [{ required: true, message: "Please input a well name!" }],
        type: "Input",
        disabled: false,
        value: "",
      },
      {
        name: "uwi",
        label: "UWI:",
        rule: [{ required: true, message: "Please input an uwi!" }],
        type: "Input",
        disabled: false,
        value: "",
      },
    ],
  },
  {
    name: "lease",
    label: "Lease",
    fields: [
      {
        name: "asset",
        label: "Asset:",
        rule: [{ required: false }],
        type: "Select",
        disabled: false,
      },
      {
        name: "lease_name",
        label: "Lease Name:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "county",
        label: "County:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "state",
        label: "State:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "basin",
        label: "Basin:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "reservoir_name",
        label: "Reservoir:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
    ],
  },
  {
    name: "gng",
    label: "G&G",
    fields: [
      {
        name: "well_log",
        label: "Well Log:",
        rule: [{ required: false }],
        type: "Select",
        disabled: true,
      },
      {
        name: "well_top",
        label: "Well Top:",
        rule: [{ required: false }],
        type: "Select",
        disabled: true,
      },
    ],
  },
  {
    name: "drilling",
    label: "Drilling",
    fields: [
      {
        name: "latitude",
        label: "Latitude:",
        rule: [{ required: true }],
        type: "Input",
        disabled: false,
      },
      {
        name: "longitude",
        label: "Longitude:",
        rule: [{ required: true }],
        type: "Input",
        disabled: false,
      },
      {
        name: "measured_depth",
        label: "Measured Depth:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "well_deviation_available",
        label: "Well Deviation:",
        rule: [{ required: false }],
        type: "Select",
        disabled: false,
      },
      {
        name: "well_survey_available",
        label: "Well Survey:",
        rule: [{ required: false }],
        type: "Select",
        disabled: false,
      },
    ],
  },
  {
    name: "production",
    label: "Production",
    fields: [
      {
        name: "well_type",
        label: "Well Type:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "drill_type",
        label: "Drill Type:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "producing_status",
        label: "Producing Status:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "production_type",
        label: "Production Type:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "artificial_lift_type",
        label: "Artificial Lift Type:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "artificial_lift_data_available",
        label: "Artificial Lift Data:",
        rule: [{ required: false }],
        type: "Select",
        disabled: true,
      },
      {
        name: "production_data_available",
        label: "Production Data:",
        rule: [{ required: false }],
        type: "Select",
        disabled: true,
      },
      {
        name: "injection_data_available",
        label: "Injection Data:",
        rule: [{ required: false }],
        type: "Select",
        disabled: true,
      },
      {
        name: "monitoring_available",
        label: "Monitoring:",
        rule: [{ required: false }],
        type: "Select",
        disabled: true,
      },
    ],
  },
  {
    name: "completion",
    label: "Completion",
    fields: [
      {
        name: "completion_available",
        label: "Completion:",
        rule: [{ required: false }],
        type: "Select",
        options: [
          { value: "1", label: "1" },
          { value: "2", label: "2" },
        ],
        disabled: true,
      },
      {
        name: "total_sand_usage",
        label: "Sand Usage:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "wellbore_length",
        label: "Wellbore Length:",
        rule: [{ required: false }],
        type: "Input",
        disabled: false,
      },
      {
        name: "spud_date",
        label: "Spud Date:",
        rule: [{ required: false }],
        type: "Date",
        disabled: false,
      },
      {
        name: "last_prod_date",
        label: "Last Production Date:",
        rule: [{ required: false }],
        type: "Date",
        disabled: false,
      },
    ],
  },
];

const deviationColumns = [
  {
    title: "Well Name",
    dataIndex: "uwi",
    key: "uwi",
    editable: false,
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
    key: "latitude",
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
    key: "longitude",
  },
  {
    title: "Azimuth",
    dataIndex: "azimuth",
    key: "azimuth",
  },
  {
    title: "Inclination",
    dataIndex: "inclination",
    key: "inclination",
  },
  {
    title: "Kelly Bushing",
    dataIndex: "kelly_bushing",
    key: "kelly_bushing",
  },
  {
    title: "Measured Depth",
    dataIndex: "measured_depth",
    key: "measured_depth",
  },
  {
    title: "Sub-sea Depth",
    dataIndex: "subsea_depth",
    key: "subsea_depth",
  },
  {
    title: "Tvd",
    dataIndex: "tvd",
    key: "tvd",
  },
];

const topColumns = [
  {
    title: "UWI",
    dataIndex: "uwi",
    key: "uwi",
    editable: false,
  },
  {
    title: "Name",
    dataIndex: "well_name",
    key: "well_name",
  },
];

const WellInfo = (props) => {
  const { wellInfo, assets } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const [form] = Form.useForm();
  const [showMore, setShowMore] = useState(false);
  const [initValue, setInitValue] = useState({});
  const [assetOptions, setAssetOptions] = useState([]);
  const [submitState, setSubmitState] = useState("modify");
  const navigate = useNavigate();

  useEffect(() => {
    setInitValue(wellInfo);
  }, [wellInfo]);

  useEffect(() => {
    if (assets == null || assets.length === 0) return;
    setAssetOptions(
      assets.map((asset) => ({ value: asset.id, label: asset.asset_name }))
    );
  }, [assets]);

  const handleInputChange = (e) => {
    const { name: nameList, value } = e[0];
    if (!nameList) return;
    const name = nameList[0];
    if (name !== "uwi") return;
    if (value !== wellInfo.uwi) {
      setSubmitState("new");
    } else {
      setSubmitState("modify");
    }
  };

  const onFinish = (values) => {
    const allValues = form.getFieldsValue();
    const { spud_date, last_prod_date } = allValues;
    allValues.spud_date = dayjs(spud_date).format("YYYY-MM-DD");
    allValues.last_prod_date = dayjs(last_prod_date).format("YYYY-MM-DD");
    showLoadingIcon && showLoadingIcon();
    if (submitState === "new") {
      saveWellSummary(
        allValues,
        (res) => {
          console.log("Save well summaries successful. ", res);
          hideLoadingIcon && hideLoadingIcon();
          navigate("/well_editor/" + allValues.uwi, { replace: "true" });
        },
        (err) => {
          console.warn("Save well summaries failed!", err.message);
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    } else if (submitState === "modify") {
      updateWellSummary(
        allValues,
        (res) => {
          console.log("Update well summaries successful. ", res);
          hideLoadingIcon && hideLoadingIcon();
          navigate("/well_editor/" + allValues.uwi, { replace: "true" });
        },
        (err) => {
          console.warn("Update well summaries failed!", err.message);
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    }
  };

  const onDelete = (values) => {
    let wellUwi = form.getFieldValue("uwi");
    if (!wellUwi) {
      console.warn("Null well name!");
      return;
    }
    showLoadingIcon();
    deleteWell(
      wellUwi,
      () => {
        navigate("/well_summary/", { replace: "true" });
        hideLoadingIcon();
      },
      () => hideLoadingIcon()
    );
    // confirm({
    //   title: 'Do you want to delete this well?',
    //   icon: <ExclamationCircleFilled />,
    //   content: '',
    //   onOk: () => {
    //     let wellUwi = form.getFieldValue("uwi");
    //     if (!wellUwi) {
    //       console.warn("Null well name!");
    //       return;
    //     }
    //     showLoadingIcon();
    //     deleteWells(
    //       [wellUwi],
    //       () => {
    //         navigate("/well_summary/", { replace: 'true' });
    //         hideLoadingIcon();
    //       },
    //       () => hideLoadingIcon()
    //     );
    //   },
    //   onCancel() {
    //   },
    // });
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const onReset = () => {
    setInitValue({});
    setSubmitState("new");
    form.resetFields();
    navigate("/well_editor/", { replace: "true" });
  };

  for (let item of wellInputs) {
    for (let block of item.fields) {
      if (block.name === "asset") {
        block.options = assetOptions;
      }
    }
  }

  return (
    <Card
      title={
        <Title level={5}>
          Well Information
          <Button
            style={{ marginLeft: "10px", border: 0 }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "less" : "more"}...
          </Button>
        </Title>
      }
      bordered={false}
      style={{ marginLeft: "0.5em", marginRight: "0.5em" }}
    >
      {wellInfo && initValue && (
        <Form
          {...layout}
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={initValue}
          onFieldsChange={handleInputChange}
        >
          {wellInputs
            .filter((item, index) => (showMore ? true : index === 0))
            .map((block, index) => {
              return (
                <Fragment key={index}>
                  <h3>{block.label}</h3>
                  <List
                    grid={{ column: 2 }}
                    dataSource={block.fields}
                    renderItem={(field) => (
                      <List.Item>
                        <Form.Item
                          name={field.name}
                          label={field.label}
                          rules={field.rule}
                        >
                          {field.type === "Input" ? (
                            <Input />
                          ) : field.type === "Select" ? (
                            <Select options={field.options} />
                          ) : field.type === "Date" ? (
                            <DatePicker disabled={field.disabled} />
                          ) : (
                            ""
                          )}
                        </Form.Item>
                      </List.Item>
                    )}
                  />
                </Fragment>
              );
            })}

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
            <Row justify={"space-around"}>
              <Button type="primary" htmlType="submit">
                {submitState === "new" ? "Submit" : "Modify"}
              </Button>
              <Button
                htmlType="reset"
                onClick={onReset}
                disabled={!wellInfo?.uwi}
              >
                New Well
              </Button>
              <Button
                htmlType="button"
                onClick={onDelete}
                style={{ color: "red" }}
              >
                Delete
              </Button>
            </Row>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
};

function WellEditor2() {
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(
    <Spin size="large" />,
    50
  );
  const { uwi } = useParams();
  const [assets, setAssets] = useState(null);
  const [wellInfo, setWellInfo] = useState(null);

  useEffect(() => {
    showLoadingIcon();
    getAssets(
      (result) => {
        hideLoadingIcon();
        setAssets(result);
      },
      (error) => {
        hideLoadingIcon();
      }
    );
  }, [showLoadingIcon, hideLoadingIcon]);

  useEffect(() => {
    if (!uwi) {
      setWellInfo({});
      return;
    }

    showLoadingIcon();
    getWellInfo(
      uwi,
      (res) => {
        hideLoadingIcon();
        if (!res) return;
        let { spud_date, last_prod_date } = res;
        spud_date = dayjs(spud_date, "YYYY-MM-DD");
        last_prod_date = dayjs(last_prod_date, "YYYY-MM-DD");
        setWellInfo({ ...res, spud_date, last_prod_date });
      },
      (err) => {
        console.warn("Load well info failed! uwi: ", uwi);
        hideLoadingIcon();
      }
    );
  }, [uwi, hideLoadingIcon, showLoadingIcon]);

  const topColumnHandler = (cols, data) => {
    let columnsResult = [...cols];
    const [topData] = data;

    const { tops } = topData;

    Object.keys(tops).map((topName) => {
      columnsResult.push({
        title: topName,
        dataIndex: ["tops", topName, "MD"],
        key: topName,
      });
      return null;
    });
    return columnsResult;
  };

  return (
    <div style={{ height: "91vh", overflowY: "auto" }}>
      <LoadingIcon />
      <ConfigProvider
        locale={enUS}
        componentSize="middle"
        theme={{
          token: {},
          components: {},
        }}
      >
        <WellInfo
          wellInfo={wellInfo}
          assets={assets}
          loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
        />

        <EditorTable
          columns={deviationColumns || []}
          title="Well Deviation"
          dataFetcher={[
            () => {
              return uwi;
            },
            getWellDeviation,
            (res) => res.deviations,
          ]}
          dataSaver={[
            saveWellDeviation, // update new record
            (rec, row) => {
              const record = { ...rec };
              delete record.index;
              return [
                {
                  azimuth: null,
                  inclination: null,
                  kelly_bushing: null,
                  latitude: 0,
                  longitude: 0,
                  measured_depth: null,
                  subsea_depth: null,
                  tvd: null,
                  uwi: uwi,
                  ...record,
                },
              ];
            },
            saveWellDeviation, // save new record
            (rec, row) => {
              const record = { ...rec };
              delete record.index;
              delete record.id;
              return [
                {
                  azimuth: null,
                  inclination: null,
                  kelly_bushing: null,
                  latitude: 0,
                  longitude: 0,
                  measured_depth: null,
                  subsea_depth: null,
                  tvd: null,
                  uwi: uwi,
                  ...record,
                },
              ];
            },
          ]}
          dataRemover={[
            removeWellDeviation,
            (record) => ({
              ids: [record.id],
            }),
          ]}
        />

        <EditorTable
          title="Well Top"
          // uwi={uwi}
          columns={topColumns || []}
          showNewRow={(data) => {
            // if no data, show new data button
            return !(data && data.length > 0);
          }}
          columnFetcher={topColumnHandler}
          dataFetcher={[
            () => {
              return uwi;
            },
            (uwi, succeed, fail) => getWellTops(uwi, null, succeed, fail),
            (res) => {
              let data = res[0];
              if (!data) return [];
              if (data.id === undefined || data.id === null)
                data["id"] = data.uwi.slice(4, -4);
              let tops = data.tops;
              Object.keys(tops).map(
                (key) => !tops[key]["MD"] && delete tops[key]
              );
              return [data];
            },
          ]}
          dataSaver={[
            saveWellTops,
            (record) => {
              let result = {},
                tops = {};
              let values = { ...record };
              delete values.index;
              delete values.uwi;
              delete values.well_name;
              delete values.id;
              delete values.tops;
              Object.keys(values).map(
                (key) => (tops[key] = { MD: parseInt(values[key]) })
              );

              result[record.uwi] = tops;
              return result;
            },
          ]}
          dataRemover={[
            deleteWellTopAttr,
            (record) => {
              let result = {},
                tops = {};
              Object.keys(record.tops).map((top) => (tops[top] = ["MD"]));
              result[record.uwi] = tops;

              return result;
            },
          ]}
        />
      </ConfigProvider>
    </div>
  );
}

export default WellEditor2;
