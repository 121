import { Outlet, Link, useNavigate } from "react-router-dom";
import { Suspense, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setLogout, selectUserData } from "features/user/userSlice";

import { SiderMenu } from "components/menu/SiderMenu";
import { Bread } from "components/menu/Bread";

import { selectActiveProject } from "features/project/projectsSlice";
import { ProjectTypes, parseProjectId } from "service/projects";

const UserDashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  let navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const projectId = parseProjectId(searchParams.get("project_id"));

  const activeProject = useSelector(selectActiveProject);
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  function handleVerticalMenu() {
    setCollapsed(!collapsed);
  }

  function getProjectLink(project) {
    for (const item of ProjectTypes) {
      if (item.value === project.project_type) {
        return item.url + `?project_id=${ project.id }&project_type=${ project.project_type }&project_name=${ project.name }`;
      }
    }
    return "project_summary/";
  }

  function getUserName() {
    if (userData == null) {
      return "";
    }

    return userData.first_name + " " + userData.last_name;
  }

  function generateProjectTitle() {
    if (activeProject == null) {
      return null;
    }

    let prjId = parseProjectId(activeProject.id);
    if (projectId != null && projectId !== prjId) {
      return null;
    }

    return (
      <div>
        Current Project:{" "}
        {/* <Link to={getProjectLink(activeProject)}>{activeProject.name}</Link> */}
        {/* <a href="#" onClick={()=>{
          let url = getProjectLink(activeProject);
          console.log("to=>", url);
          navigate(url);
        }}>{activeProject.name}</a> */}
        <a href={getProjectLink(activeProject)}><span style={{ marginLeft: '4px' }}>{activeProject.name}</span></a>
      </div>
    );
  }

  return (
    <div id="layout-wrapper" className={collapsed ? "vertical-collpsed" : ""}>
      <div id="page-topbar">
        <div className="navbar-header">
          <div>
            <Bread />
          </div>
          <div className="project-name">{generateProjectTitle()}</div>
          <div className="d-flex align-items-center">
            <img
              src="/static/images/home.png"
              alt="home"
              width={26}
              height={26}
              style={{ cursor: 'pointer', marginRight: '15px' }}
              onClick={() => window.location = "/"}
            />
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src="/static/images/users/avatar-4.jpg"
                  alt="Header Avatar"
                />
                <span className="d-none d-xl-inline-block ms-1 fw-medium">
                  {getUserName()}
                </span>
                <i className="uil-angle-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href="#">
                  <i className="uil uil-user-circle align-middle text-muted me-1"></i>{" "}
                  <span className="align-middle">View Profile</span>
                </a>
                <a className="dropdown-item" href="#">
                  <i className="uil uil-wallet align-middle me-1 text-muted"></i>{" "}
                  <span className="align-middle">My Wallet</span>
                </a>
                <a className="dropdown-item d-block" href="#">
                  <i className="uil uil-cog align-middle me-1 text-muted"></i>{" "}
                  <span className="align-middle">Settings</span>{" "}
                  <span className="badge bg-soft-success rounded-pill mt-1 ms-2">
                    03
                  </span>
                </a>
                <a className="dropdown-item" href="/utility/auth-lock-screen">
                  <i className="uil uil-lock-alt align-middle me-1 text-muted"></i>{" "}
                  <span className="align-middle">Lock screen</span>
                </a>

                <a className="dropdown-item" href="/accounts/password/change/">
                  <i className="align-middle text-muted me-1 mdi mdi-key"></i>{" "}
                  Change Password
                </a>

                <Link
                  className="dropdown-item text-danger"
                  onClick={() => {
                    dispatch(setLogout(null));
                  }}
                  to="/logout/"
                >
                  <i className="align-middle text-muted me-1 text-danger mdi mdi-logout-variant"></i>
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside className="vertical-menu">
        <div className="navbar-brand-box">
          <div className="logo">
            <img
              src="/static/images/logo-text2.png"
              alt="logo"
              onClick={() => window.location = "/"}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div className="menu-btn-wrapper">
          <div onClick={handleVerticalMenu} className="vertical-menu-btn">
            <i className="mennu-outline"></i>
          </div>
        </div>
        <div data-simplebar className="sidebar-menu-scroll">
          <div id="sidebar-menu">{<SiderMenu />}</div>
        </div>
      </aside>

      <main className="main-content">
        <Suspense fallback="Loading...">
          <Outlet />
        </Suspense>
        <footer className="footer"></footer>
      </main>
    </div>
  );
};

export default UserDashboard;
