import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectPrdDataStructure,
  setPrdDataStructure,
} from "features/input/dataManagerSlice";
import { getPrdInputConfig } from "service/dataInput";

import NullFile from "./NullFile";

function ProductionInput(props) {
  const { inputData } = props;
  const prdDataStructure = useSelector(selectPrdDataStructure);
  const [columnDictionary, setColumnDictionary] = useState([]);

  let dispatch = useDispatch();

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    getPrdInputConfig(
      (result) => {
        let tempColumn = result.map((item) => {
          return { id: item.id, header: item.prd_index };
        });
        setColumnDictionary([{ id: -1, header: "N/A" }, ...tempColumn]);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  useEffect(() => {
    if (inputData !== null && columnDictionary !== null) {
      initDataStructure();
    }
  }, [inputData, columnDictionary]);

  function initDataStructure() {
    const initialSelection = inputData["headers"].map((x, index) => {
      let selectedCol = null;
      if (x.includes("(")) {
        let start = x.indexOf("(");
        x = x.slice(0, start);
      }
      let header = x.trim().toLowerCase().replace(/\s/g, "_");
      let i = columnDictionary.findIndex(
        (e) =>
          header.startsWith(e.header) ||
          e.header.startsWith(header) ||
          header.includes(e.header) ||
          e.header.includes(header)
      );
      if (i !== -1) {
        selectedCol = columnDictionary[i].header;
      } else {
        selectedCol = columnDictionary[0].header;
      }
      return selectedCol;
    });
    dispatch(setPrdDataStructure(initialSelection));
  }

  function handleChangeColumn(e, i) {
    const unpacked = prdDataStructure.map((el, index) => {
      if (index === i) {
        return e.target.value;
      } else {
        return el;
      }
    });
    dispatch(setPrdDataStructure(unpacked));
  }

  return (
    <div className="table-container d-flex flex-column justify-content-start align-items-start">
      {inputData == null || prdDataStructure == null ? (
        <NullFile />
      ) : (
        <>
          <div className="table-header">
            {inputData["headers"].map((x, i) => {
              return (
                <div className="header-cell" key={i}>
                  <span>{x}</span>
                </div>
              );
            })}
          </div>
          <div className="table-header">
            {inputData["headers"].map((x, i) => {
              return (
                <div className="header-cell" key={i}>
                  <select
                    id={`attribute_id${i}`}
                    onChange={(e) => {
                      handleChangeColumn(e, i);
                    }}
                    value={prdDataStructure[i]}
                  >
                    {columnDictionary.map((y, j) => {
                      return (
                        <option key={i + "_" + j} value={y.header}>
                          {y.header}
                        </option>
                      );
                    })}
                  </select>
                </div>
              );
            })}
          </div>
          <div className="table-body">
            {inputData["data"].map((el, index) => {
              return (
                <div className="data-row" key={index}>
                  {el.map((val, subIndex) => {
                    return (
                      <div className="data-cell" key={index + "_" + subIndex}>
                        <span>{val}</span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default ProductionInput;
