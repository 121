export const WellSummaryHeaders = [
  { value: "uwi", name: "UWI" },
  { value: "well_name", name: "Well Name" },
  { value: "asset_name", name: "Asset" },
  { value: "well_type", name: "Well Type" },
  { value: "producing_status", name: "Prod Status" },
  { value: "lease_name", name: "Lease Name" },
  { value: "drill_type", name: "Drill Type" },
  { value: "county", name: "County" },
  { value: "state", name: "State" },
  { value: "basin", name: "Basin" },
  { value: "reservoir", name: "Reservoir" },
  { value: "latitude", name: "Latitude" },
  { value: "longitude", name: "Longitude" },
  { value: "measured_depth", name: "Depth" },
  { value: "production_type", name: "Prod Type" },
  { value: "artificial_lift_type", name: "Lift Type" },
  { value: "spud_date", name: "Spud Date" },
  { value: "well_deviation", name: "Dev", type: "bool" },
  { value: "well_survey", name: "Survey", type: "bool" },
  { value: "well_top", name: "Top", type: "bool" },
  { value: "well_log", name: "Log", type: "bool" },
  { value: "completion", name: "Completion", type: "bool" },
  { value: "artificial_lift_data", name: "Lift Data", type: "bool" },
  { value: "production_data", name: "Prod Data", type: "bool" },
  { value: "injection_data", name: "Inj Data", type: "bool" },
  { value: "monitoring", name: "Monitoring", type: "bool" },
  { value: "sand_usage", name: "Sand Usage" },
  { value: "wellbore_length", name: "Wellbore Length" },
  { value: "last_prod_date", name: "Last Prod Date" },
];

export const WellDeviationHeaders = [
  { value: "well_name", name: "Well Name" , editable: false},
  { value: "latitude", name: "Latitude", editable: true },
  { value: "longitude", name: "Longitude", editable: true },
  { value: "tvd", name: "TVD", editable: true },
  { value: "measured_depth", name: "Measured Depth", editable: true },

];
