import { Typography, InputNumber, Form } from 'antd'
import FormSection from 'components/form-section'
import React from 'react'

const units = {
  oil: {
    FIELD: 'lb /ft^3',
    METRIC: 'kg/m3'
  },
  water: {
    FIELD: 'lb /ft^3',
    METRIC: 'kg/m3'
  },
  gas: {
    FIELD: 'lb /ft^3',
    METRIC: 'kg/m3'
  }
}

const DensityTable = () => {
  const simulationUnit = Form.useWatch(["GENERAL", "unit"])

  return (
    <FormSection title="Density">
      <div className="vstack gap-2">
        <div className="hstack gap-2 align-items-center">
          <Typography.Text className="text-light" style={{ width: 200 }}>
            Oil Density
          </Typography.Text>
          <Form.Item name={['PVT', 'density', 'oil', 'value']} className="mb-0">
            <InputNumber className="w-100" step={0.01} />
          </Form.Item>
          <Typography.Text className="text-light">
            {units.oil[simulationUnit] ?? ''}
          </Typography.Text>
        </div>
        <div className="hstack gap-2 align-items-center">
          <Typography.Text className="text-light" style={{ width: 200 }}>
            Water Density
          </Typography.Text>
          <Form.Item name={['PVT', 'density', 'water', 'value']} className="mb-0">
            <InputNumber className="w-100" step={0.01} />
          </Form.Item>
          <Typography.Text className="text-light">
            {units.water[simulationUnit] ?? ''}
          </Typography.Text>
        </div>
        <div className="hstack gap-2 align-items-center">
          <Typography.Text className="text-light" style={{ width: 200 }}>
            Gas Density
          </Typography.Text>
          <Form.Item name={['PVT', 'density', 'gas', 'value']} className="mb-0">
            <InputNumber className="w-100" step={0.01} />
          </Form.Item>
          <Typography.Text className="text-light">
            {units.gas[simulationUnit] ?? ''}
          </Typography.Text>
        </div>
      </div>
    </FormSection>
  )
}

export default DensityTable