import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectAssets,
  selectActiveAsset,
  selectActiveWells,
  setActiveAssetByName,
} from "features/asset/assetSelectorSlice";

import { selectDcaWells } from "features/decline_analysis/dcaManagementSlice";

function DcaManagementWellsSelector(props) {
  const { selectedWellIds, onWellSelected } = props;

  const assets = useSelector(selectAssets);
  const activeAsset = useSelector(selectActiveAsset);
  const assetWells = useSelector(selectActiveWells);

  const dcaWells = useSelector(selectDcaWells);

  const dispatch = useDispatch();

  const [wellsData, setWellsData] = useState(null);

  let assetName = null;
  if (activeAsset) {
    assetName = activeAsset.asset_name;
  }

  useEffect(() => {
    if (
      assetWells == null ||
      assetWells.length === 0 ||
      dcaWells == null ||
      dcaWells.length === 0
    ) {
      return;
    }

    let wells = dcaWells
      .map((dcaWell) => {
        const well = assetWells.find((well) => well.uwi === dcaWell.well_uwi);
        if (well) {
          return well;
        }
        return null;
      })
      .filter((item) => item != null)
      .slice()
      .sort((a, b) => (a.well_name > b.well_name ? 1 : -1));

    setWellsData(wells);
  }, [assetWells, dcaWells]);

  function handleAssetChanged(e) {
    const assetName = e.target.value;
    dispatch(setActiveAssetByName({ assetName }));
  }

  const handleWellSelected = useCallback(
    (well) => {
      onWellSelected(well);
    },
    [onWellSelected]
  );

  const assetsSelect = () => {
    if (assets == null || assets.length === 0) {
      return null;
    }

    return assets.map((asset, index) => {
      return (
        <option
          key={index}
          className="d-flex justify-content-center align-items-content"
          value={asset.asset_name}
        >
          {asset.asset_name}
        </option>
      );
    });
  };

  const wellsSelect = () => {
    if (wellsData == null || wellsData.length === 0) {
      return null;
    }

    let selectedIdSet = null;
    if (selectedWellIds != null && selectedWellIds.length > 0) {
      selectedIdSet = new Set(selectedWellIds);
    }
    return wellsData.map((item, index) => {
      const selected =
        selectedIdSet != null && selectedIdSet.size > 0
          ? selectedIdSet.has(item.uwi)
          : false;
      let className =
        "select-option d-flex justify-content-center align-items-start";
      if (selected) {
        className = className + " selected";
      }
      return (
        <div
          id={item.uwi}
          key={item.uwi}
          className={className}
          onClick={() => {
            handleWellSelected(item);
          }}
        >
          <span>{item.well_name}</span>
        </div>
      );
    });
  };

  return (
    <div className="selector d-flex flex-column w-100 h-100">
      <div className="selector-select d-flex flex-column align-items-stretch">
        <select
          onChange={handleAssetChanged}
          name="asset_name"
          className="d-flex"
          value={assetName || ""}
        >
          {assetsSelect()}
        </select>
      </div>
      <div className="select-options-list">{wellsSelect()}</div>
    </div>
  );
}

export default DcaManagementWellsSelector;
