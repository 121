import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeWell: null,

  /*
  dcaWells structures
  [
    {
      "well_uwi": "42329421930000",
      "well_name": "BRC-231",
      "decline_phase": "oil",
      "start_date": "2019-10-01",
      "validation_length": 2,
      "forecast_length": 20,
      "ai_params": [
          {
              "id": 1,
              "param_name": "wellhead_casing_pressure",
              "value_suggest": 100,
              "value_user": 120
          },
          {
              "id": 2,
              "param_name": "wellhead_tubing_pressure",
              "value_suggest": 10,
              "value_user": 30
          }
        ]
    },
    ...
  ]
  */
  dcaWells: [],
  declinePhase: "oil",
  managementGlobal: {
    oil: {
      startDate: null,
      validationMonths: 0,
      params: [],
      paramsSuggested: [],
      forecastSetting: {
        forecastMonths: 20,
        paramsValues: [],
      },
    },
    gas: {
      startDate: null,
      validationMonths: 0,
      params: [],
      paramsSuggested: [],
      forecastSetting: {
        forecastMonths: 20,
        paramsValues: [],
      },
    },
    liquid: {
      startDate: null,
      validationMonths: 0,
      params: [],
      paramsSuggested: [],
      forecastSetting: {
        forecastMonths: 20,
        paramsValues: [],
      },
    },
  },
  managementModel: {
    type: "Plot",
    validateMonths: 0,
  },
  eurData: {
    oil: {
      eurML: 0,
      eurRecomm: 0,
      eurUser: 0,
      periodPrdML: 0,
      periodPrdRecomm: 0,
      periodPrdUser: 0,
    },
    gas: {
      eurML: 0,
      eurRecomm: 0,
      eurUser: 0,
      periodPrdML: 0,
      periodPrdRecomm: 0,
      periodPrdUser: 0,
    },
    liquid: {
      eurML: 0,
      eurRecomm: 0,
      eurUser: 0,
      periodPrdML: 0,
      periodPrdRecomm: 0,
      periodPrdUser: 0,
    },
  },
};

const dcaManagementSlice = createSlice({
  name: "dcaManagement",
  initialState,
  reducers: {
    reset: () => initialState,
    setActiveWell: (state, action) => {
      state.activeWell = action.payload;
    },
    setDcaWells: (state, action) => {
      state.dcaWells = action.payload;
    },
    addDcaWell: (state, action) => {
      const well_uwi = action.payload.well_uwi;
      // let wells = state.dcaWells.filter((well) => well.well_uwi !== well_uwi);
      // state.dcaWells = [...wells, action.payload];
      let wells = state.dcaWells.filter((well) => well.well_uwi !== well_uwi);
      let savedWell = state.dcaWells.find((well) => well.well_uwi === well_uwi);
      let updatedWell = { ...action.payload };
      let newRecords = [];

      // merge old and new dca well data
      // add old dca phase to new dca phases if it doesn't exist in new records.
      // otherwise, use new dca phase instead of old dca phase.
      savedWell &&
        savedWell.records &&
        savedWell.records.length > 0 &&
        savedWell.records.forEach((oldRecord) => {
          if (updatedWell.records && updatedWell.records.length > 0) {
            if (
              !updatedWell.records.find(
                (record) => record.decline_phase === oldRecord.decline_phase
              )
            ) {
              newRecords.push(oldRecord);
            }
          }
        });
      updatedWell.records = [...updatedWell.records, ...newRecords];
      state.dcaWells = [...wells, updatedWell];
    },
    setActiveDcaWell: (state, action) => {
      const dcaWellId = action.payload;
      let dcaWell = null;
      if (dcaWellId && state.dcaWells != null && state.dcaWells.length > 0) {
        dcaWell = state.dcaWells.find((item) => item.well_uwi === dcaWellId);
      }
      if (
        dcaWell == null ||
        dcaWell.records == null ||
        dcaWell.records.length === 0
      ) {
        state.managementGlobal = initialState.managementGlobal;
        state.eurData = initialState.eurData;
        return;
      }
      if (dcaWell && dcaWell.records && dcaWell.records.length > 0) {
        for (let record of dcaWell.records) {
          const phase = record.decline_phase;
          state.managementGlobal[phase] = {
            ...state.managementGlobal[phase],
            startDate: record.start_date,
            validationMonths: record.validation_length,
            params: record.ai_params.map((item) => item.param_name),
            paramsSuggested: record.ai_params.map((item) => item.value_suggest),
            forecastSetting: {
              forecastMonths: record.forecast_length,
              paramsValues: record.ai_params.map((item) => item.value_user),
            },
          };
        }
      }
    },
    setManagementGlobal: (state, action) => {
      state.managementGlobal = action.payload;
    },
    setManagementGlobalByPhase: (state, action) => {
      const { phase, settings } = action.payload;
      state.managementGlobal[phase] = {
        ...state.managementGlobal[phase],
        ...settings,
      };
    },
    setDeclinePhase: (state, action) => {
      state.declinePhase = action.payload;
    },
    setManagementGlobalAvailableParams: (state, action) => {
      const { availableParams, allParamsRange, availableParamsResult } =
        action.payload;
      state.managementGlobal.availableParams = availableParams;
      state.managementGlobal.allParamsRange = allParamsRange;
      state.managementGlobal.availableParamsResult = availableParamsResult;
    },
    setManagementGlobalParamsSuggested: (state, action) => {
      state.managementGlobal[state.declinePhase].paramsSuggested =
        action.payload;
    },
    setManagementModel: (state, action) => {
      state.managementModel = action.payload;
    },
    setEurData: (state, action) => {
      state.eurData = action.payload;
    },
  },
});

export const {
  reset,
  setActiveWell,
  setDcaWells,
  addDcaWell,
  setActiveDcaWell,
  setManagementGlobal,
  setManagementGlobalByPhase,
  setManagementModel,
  setManagementGlobalParamsSuggested,
  setManagementGlobalAvailableParams,
  setEurData,
  setDeclinePhase,
} = dcaManagementSlice.actions;

export const selectActiveWell = (state) => state.dcaManagement.activeWell;
export const selectDcaWells = (state) => state.dcaManagement.dcaWells;
export const selectDcaWell = (state, well_uwi) => {
  return state.dcaManagement.dcaWells.find(
    (well) => well.well_uwi === well_uwi
  );
};
export const selectManagementGlobal = (state) =>
  state.dcaManagement.managementGlobal;
export const selectManagementGlobalParamsSuggested = (state) =>
  state.dcaManagement.managementGlobal[state.dcaManagement.declinePhase]
    .paramsSuggested;
export const selectManagementModel = (state) =>
  state.dcaManagement.managementModel;
export const selectEurData = (state) => state.dcaManagement.eurData;

export const selectDeclinePhase = (state) => state.dcaManagement.declinePhase;

export const selectForecastSetting = (state) =>
  state.dcaManagement.managementGlobal[state.dcaManagement.declinePhase]
    .forecastSetting;

export default dcaManagementSlice.reducer;
