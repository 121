import { get, post } from "./base";

export function getRecommData(params, onSuccess, onError) {
  const data = {
    decline_phase: params.declinePhase,
    decline_model: params.declineModel,
    validation_months: params.validationMonths,
  };

  if (params.wellId) {
    data.uwi = params.wellId;
  }

  if (params.assetName) {
    data.asset_name = params.assetName;
  }
  if (params.startDate) {
    data.start_date = params.startDate;
  }

  post(
    "/api/dca/init/",
    data, null, {
    headers: {
      "Content-Type": "application/json",
    },
  },
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    false,
    true,
  );
}

export function getUserTuneData(params, onSuccess, onError) {
  const data = {
    decline_phase: params.declinePhase,
    decline_model: params.declineModel,
    start_date: params.startDate,
    validation_months: params.validationMonths,
    decline_rate: params.declineRate,
    ip_ratio: params.ipRatio,
    b: params.b,
  };

  if (params.wellId) {
    data.uwi = params.wellId;
  }

  if (params.assetName) {
    data.asset_name = params.assetName;
  }

  post(
    "/api/dca/user_tune/",
    data, null, {
    headers: {
      "Content-Type": "application/json",
    },
  },
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getDcaForecast(params, onSuccess, onError) {
  const data = {
    decline_phase: params.declinePhase,
    decline_model: params.declineModel,
    start_date: params.startDate,
    forecast_months: params.forecastMonths,
    decline_rate: params.declineRate,
    ip_ratio: params.ipRatio,
    b: params.b,
  };

  if (params.wellId) {
    data.uwi = params.wellId;
  }

  if (params.assetName) {
    data.asset_name = params.assetName;
  }

  post(
    "/api/dca/forecast/",
    data, null, {
    headers: {
      "Content-Type": "application/json",
    },
  },
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getDcaManagementHistory(params, onSuccess, onError) {
  const data = {
    "decline_phase": params.declinePhase,
    "start_date": params.startDate,
    "validation_months": params.validationMonths,
    "mode": "sum",
    "params": params.params,
  };

  if (params.wellId) {
    data.uwi = params.wellId;
  } else {
    console.error("wellId is null!");
    onError("wellId is null!");
    return;
  }

  post(
    "/api/dca/ml_physics/validation/",
    data, null, {
    headers: {
      "Content-Type": "application/json",
    },
  },
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function getDcaManagementForecast(params, onSuccess, onError) {
  const data = {
    "decline_phase": params.declinePhase,
    "forecast_months": params.forecastMonths,
    "mode": "sum",
    "params": params.params,
    "params_values": params.paramsValues,
  };

  if (params.startDate) {
    data.start_date = params.startDate;
  }
  if (params.wellId) {
    data.uwi = params.wellId;
  } else {
    return;
  }

  post(
    "/api/dca/ml_physics/forecast/",
    data, null, {
    headers: {
      "Content-Type": "application/json",
    },
  },
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) }
  );
}

export function dcaReportInit() {
  return get(
    `/api/report/dca/init`
  )
}

export function computeDCFS(data) {
  return post(
    `/api/report/dca/dcfs/`,
    data
  )
}

export function exportDCAReportPDF(data) {
  return post(
    `/api/report/dca/gen`,
    data,
    {},
    {
      responseType: 'blob'
    }
  )
}

export function getDCAReportTemplates() {
  return get(
    '/api/report/dca/templates',
  )
}