import { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  DatePeriodOptions,
  DECLINE_PHASE_OPTIONS,
  NormalizeOptions,
  DeclineModelOptions,
} from "app/codes";

import {
  setDeclinePhase,
  selectDeclinePhase,
  selectGroupNormalizeBy,
  setGroupNormalizeBy,
  selectProjectGroupDataItems,
  selectApplyUserParams,
  setApplyUserParams,
  // setActiveGroupId,
  setClusterNormalizeBy,
  setProjectGroupDataRecommParamsWithIndex,
  setProjectGroupDataUserParamsWithIndex,
} from "features/decline_analysis/typeCurveProjectSlice";
import { getDcaRecommends, getDcaUserTuneData } from "service/typeCurve";

import TypeCurveTypeCurve from "../../components/charts/TypeCurveTypeCurve";
import { Empty } from "antd";
import {
  selectDcaAllModelsParams,
  setDcaAllModelsParams,
} from "features/decline_analysis/dcaProjectSlice";
import SectionSetting from "components/common/SectionSetting";
import { useUserPreference } from "components/charts/UserPreference";
function UserTypeCurveChart(props) {
  const { submitTCProject } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const [UserPreference, showUserPreference] =
    useUserPreference();

  const declinePhase = useSelector(selectDeclinePhase);
  // const product = useSelector(selectProduct);
  // const product = declinePhase ? PHASE_MODEL_MAP[declinePhase] : PHASE_MODEL_MAP[DECLINE_PHASE_OPTIONS[0].value];

  const projectGroupItems = useSelector(selectProjectGroupDataItems);
  const normalizedByParam = useSelector(selectGroupNormalizeBy);
  const applyGroupParams = useSelector(selectApplyUserParams);
  const allModelsParameters = useSelector(selectDcaAllModelsParams);
  const dispatch = useDispatch();
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  // historical production data
  const [historicalData, setHistoricalData] = useState(null);
  // computed dca data with default dca parameters
  const [recommData, setRecommData] = useState(null);
  const [userDcaData, setUserDcaData] = useState(null);
  const [activePeriod, setActivePeriod] = useState(1);
  const normalizeBy = useSelector(selectGroupNormalizeBy);

  const handlePeriodChanged = (days) => {
    setActivePeriod(days);
  };

  const updateRecommData = useCallback(
    (result) => {
      let history = result.history;
      setHistoricalData(history);

      let recommends = result.dca;
      let bestModel = recommends.best_model;
      setRecommData(result.dca.fitted);

      dispatch(
        setProjectGroupDataRecommParamsWithIndex({
          index: selectedGroupIndex,
          recommParams: {
            normalized_by: normalizedByParam,
            decline_phase: declinePhase,
            dca_model_type: bestModel,
            recomm_ip_ratio: recommends.di,
            recomm_initial_decline_rate: recommends.qi,
            recomm_coefficient_b: recommends.b,
          },
        })
      );
    },
    [declinePhase, dispatch, normalizedByParam, selectedGroupIndex]
  );

  const getDcaUserTuneDataCallback = useCallback(
    (groupIndex, projectGroupItem) => {
      let projectGroup = projectGroupItem.project_group;
      const record = projectGroup?.records?.find(
        (item) => item.decline_phase === declinePhase
      );
      let declineModel = record.user_decline_model || record.dca_model_type;
      let wellIds = projectGroupItem.group_wells;
      if (!declineModel || !wellIds || wellIds.length === 0) {
        return;
      }

      let decline_rate = record.user_initial_decline_rate;
      let ip_ratio = record.user_ip_ratio;
      let b = record.user_coefficient_b;

      if (typeof decline_rate != "number") {
        decline_rate = parseFloat(decline_rate);
        ip_ratio = parseFloat(ip_ratio);
        b = parseFloat(b);
      }

      if ((!decline_rate && !ip_ratio) || (declineModel === "HYP" && !b)) {
        return;
      }
      showLoadingIcon && showLoadingIcon();
      getDcaUserTuneData(
        {
          uwis: wellIds,
          decline_phase: declinePhase || "oil",
          decline_model: declineModel,
          normalize_by: normalizedByParam,
          decline_rate: decline_rate,
          ip_ratio: ip_ratio,
          b: b,
        },
        (result) => {
          if (!result || !result.dca || !result.dca.fitted) return;
          setUserDcaData(result.dca.fitted);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [declinePhase, hideLoadingIcon, normalizedByParam, showLoadingIcon]
  );

  const getDcaRecommendDataCallback = useCallback(
    (groupIndex, projectGroupItem) => {
      let wellIds = projectGroupItem.group_wells;
      if (!wellIds || wellIds.length === 0) {
        setHistoricalData(null);
        return;
      }
      showLoadingIcon && showLoadingIcon();
      getDcaRecommends(
        {
          uwis: wellIds,
          decline_phase: declinePhase,
          normalize_by: normalizedByParam,
        },
        (result) => {
          updateRecommData(result);
          let allModels = [...result.other_models];
          let { best_model, b, di, qi } = result.dca;
          allModels.push({
            model_name: best_model,
            model_params: {
              qi: qi,
              di: di,
              b: b,
            },
          });
          dispatch(setDcaAllModelsParams(allModels));
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [
      dispatch,
      declinePhase,
      normalizedByParam,
      hideLoadingIcon,
      showLoadingIcon,
      updateRecommData,
    ]
  );

  useEffect(() => {
    if (projectGroupItems == null || projectGroupItems.length === 0) {
      setRecommData(null);
      setUserDcaData(null);
      setHistoricalData(null);
      setSelectedGroupIndex(null);
      return;
    }
    if (
      selectedGroupIndex == null ||
      projectGroupItems > projectGroupItems.length - 1
    ) {
      setSelectedGroupIndex(0);
    }
  }, [dispatch, selectedGroupIndex, projectGroupItems]);

  useEffect(
    () => {
      if (
        selectedGroupIndex == null ||
        !projectGroupItems ||
        projectGroupItems.length === 0
      ) {
        return;
      }

      setRecommData(null);
      setUserDcaData(null);
      setHistoricalData(null);
      getDcaRecommendDataCallback(
        selectedGroupIndex,
        projectGroupItems[selectedGroupIndex]
      );
      getDcaUserTuneDataCallback(
        selectedGroupIndex,
        projectGroupItems[selectedGroupIndex]
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [declinePhase, selectedGroupIndex, normalizeBy]
  );

  useEffect(() => {
    if (
      !applyGroupParams ||
      !projectGroupItems ||
      projectGroupItems.length === 0 ||
      selectedGroupIndex == null
    ) {
      return;
    }

    setUserDcaData(null);
    getDcaUserTuneDataCallback(
      selectedGroupIndex,
      projectGroupItems[selectedGroupIndex]
    );

    // close params panel window
    dispatch(setApplyUserParams(false));
  }, [
    dispatch,
    applyGroupParams,
    projectGroupItems,
    selectedGroupIndex,
    getDcaUserTuneDataCallback,
  ]);

  function handleGroupSelectChanged(e) {
    let value = e.target.value;
    setSelectedGroupIndex(value);
    // dispatch(setActiveGroupId(projectGroupItems[value].project_group.id));
  }

  function handleDeclinePhaseChanged(e) {
    let value = e.target.value;
    dispatch(setDeclinePhase(value));
  }

  function handleSubmitProject() {
    let projectItem = projectGroupItems[selectedGroupIndex];
    if (projectItem) {
      submitTCProject({ projectItem: projectItem });
    }
  }

  function generateGroupSelector() {
    if (projectGroupItems == null || projectGroupItems.length === 0) {
      return;
    }

    return projectGroupItems.map((item, index) => {
      return (
        <option key={index} value={index}>
          {item.project_group.group_name}
        </option>
      );
    });
  }

  function handleNormalizeParamChanged(e) {
    const value = e.target.value;
    if (value !== normalizeBy) {
      dispatch(setGroupNormalizeBy(value));
      dispatch(setClusterNormalizeBy(value));
    }
  }

  const getRecommParams = useMemo(() => {
    const projectGroup =
      projectGroupItems[selectedGroupIndex]?.project_group;
    const record = projectGroup?.records?.find(
      (item) => item.decline_phase === declinePhase
    );
    const {
      dca_model_type,
      recomm_ip_ratio,
      recomm_initial_decline_rate,
      recomm_coefficient_b,
    } = record || {};
    return {
      declineModelValue: DeclineModelOptions.find(
        (item) => item.value === dca_model_type
      )?.name,
      ipRatioValue: recomm_ip_ratio?.toFixed(3),
      declineRateValue: recomm_initial_decline_rate?.toFixed(3),
      bValue: recomm_coefficient_b?.toFixed(3),
    };
  }, [declinePhase, selectedGroupIndex, projectGroupItems]);

  const getUserParams = useMemo(() => {
    const projectGroup =
      projectGroupItems[selectedGroupIndex]?.project_group;
    const record = projectGroup?.records?.find(
      (item) => item.decline_phase === declinePhase
    );
    const {
      user_decline_model,
      user_ip_ratio,
      user_initial_decline_rate,
      user_coefficient_b,
    } = record || {};
    return {
      declineModel: user_decline_model,
      ipRatio: user_ip_ratio,
      declineRate: user_initial_decline_rate,
      b: user_coefficient_b,
    };
  }, [declinePhase, selectedGroupIndex, projectGroupItems]);

  return (
    <>
      <UserPreference
        recommendData={getRecommParams}
        userTuneData={getUserParams}
        defaultParameters={allModelsParameters}
        onClose={(values) => {
          dispatch(
            setProjectGroupDataUserParamsWithIndex({
              index: selectedGroupIndex,
              userParams: {
                decline_phase: declinePhase,
                normalized_by: normalizedByParam,
                user_decline_model: values.declineModel,
                user_ip_ratio: values.ipRatio,
                user_initial_decline_rate: values.declineRate,
                user_coefficient_b: values.b,
              },
            })
          );
          dispatch(setApplyUserParams(true));
        }}
      />
      <div className="cards-container h-100">
        <div className="cards-header d-flex flex-row justify-content-between align-items-center">
          <div className="header-title">Type Curve</div>
          <div className="header-menu-container d-flex flex-row">
            <div className="d-flex flex-row align-items-center">
              <div>Normalized By</div>
              <select
                className="ms-2"
                value={normalizeBy || "wellbore_length"}
                onChange={handleNormalizeParamChanged}
              >
                {NormalizeOptions.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <select
              className="ms-2"
              value={selectedGroupIndex || 0}
              onChange={handleGroupSelectChanged}
            >
              {generateGroupSelector()}
            </select>
            <select
              className="ms-2"
              value={declinePhase || "oil"}
              onChange={handleDeclinePhaseChanged}
            >
              {DECLINE_PHASE_OPTIONS.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <div>
              {DatePeriodOptions.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <input
                      type="radio"
                      className="btn-check"
                      name="periodOpt"
                      id={"periodOpt" + index.toString()}
                      onChange={() => {
                        handlePeriodChanged(item.days);
                      }}
                      checked={activePeriod === item.days}
                    />
                    <label
                      htmlFor={"periodOpt" + index.toString()}
                      className="btn-label header"
                    >
                      {item.name}
                    </label>
                  </Fragment>
                );
              })}
            </div>
            <div className="cards-setting-btn">
              <SectionSetting disable />
            </div>
          </div>
        </div>
        <div className="cards-content d-flex flex-column justify-content-between align-items-stretch">
          <div className="validation-chart-wrapper mb-2">
            {historicalData || recommData || userDcaData ? (
              <TypeCurveTypeCurve
                historical_data={historicalData}
                recomm_data={recommData}
                user_dca={userDcaData}
                yUnit={
                  DECLINE_PHASE_OPTIONS.find(
                    (item) => item.value === declinePhase
                  )?.unit
                }
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ color: "rgba(255,255,255, 0.6)" }}
              >
                {
                  "Please set clusters first! must select: Wellbore length to show data"
                }
              </Empty>
            )}
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <button
              className="btn-custom"
              onClick={(e) => {
                showUserPreference();
              }}
              disabled={!projectGroupItems?.length}
            >
              Edit Parameters
            </button>
            <button
              className="btn-custom"
              onClick={(e) => {
                handleSubmitProject();
              }}
              disabled={!projectGroupItems?.length}
            >
              Validated to Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserTypeCurveChart;
