import { EditableProTable } from '@ant-design/pro-components'
import { Button, Form, Popconfirm, Select, Typography } from 'antd'
import FormSection from 'components/form-section'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generateUuid } from 'utils/dataUtil'
import { isDecreasing, isIncreasing } from 'utils/number'

import classes from './Table.module.scss'
import { isNumeric } from 'utils/string'

const SWTable = ({ tab, fields = [], operation }) => {
  const form = Form.useFormInstance()

  const [data, setData] = useState([])
  const [errors, setErrors] = useState([])

  const fetchData = () => {
    setData(fields.map((field, index) => ({
      ...form.getFieldValue(["SCAL", tab, "SWO", field.name]),
      key: index
    })) ?? [])
  }

  useEffect(() => {
    validateData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  const columns = useMemo(
    /**
     * 
     * @returns {import('@ant-design/pro-components').ProColumnType[]}
     */
    () => [
      {
        key: 'key',
        dataIndex: 'key',
        title: "Row",
        editable: false,
        render: (dom, record, index) => index + 1
      },
      {
        key: 'SW',
        dataIndex: 'SW',
        title: 'Sw',
        editable: true,
      },
      {
        key: 'KRW',
        dataIndex: 'KRW',
        title: 'Krw',
        editable: (_, __, index) => index !== 0
      },
      {
        key: 'KRO',
        dataIndex: 'KRO',
        title: 'Kro',
        editable: true,
      },
      {
        key: 'PSIA',
        dataIndex: 'PSIA',
        title: 'Pc (psia)',
        editable: true,
      },
      {
        title: "Operation",
        valueType: "option",
        key: "operation",
        width: 160,
        render: (text, record, index, action) => [
          <Button
            key="editable"
            type="text"
            className="text-primary"
            onClick={() => {
              action?.startEditable?.(record.key);
            }}
          >
            Edit
          </Button>,
          <Popconfirm
            key="delete"
            title="Sure to delete?"
            onConfirm={(e) => {
              operation?.remove?.(index)
            }}
          >
            <Button type="text" danger>Delete</Button>
          </Popconfirm>
        ],
      },
    ]
    , [operation])

  const validateData = useCallback((_data = []) => {
    setErrors([])

    const _errors = []

    if (!_data.length) return;

    const swValues = []
    const krwValues = []
    const kroValues = []
    const pcValues = []

    _data.forEach((row, index) => {
      if (!isNumeric(row.SW)) {
        _errors.push(`Value of Sw in row #${ index + 1 } must be a number`)
      } else {
        const sw = parseFloat(row.SW)
        if (sw < 0 || sw > 1) {
          _errors.push(`Value of Sw in row #${ index + 1 } must be between 0 and 1`)
        } else swValues.push(sw)
      }
      if (!isNumeric(row.KRW)) {
        _errors.push(`Value of Krw in row #${ index + 1 } must be a number`)
      } else {
        const krw = parseFloat(row.KRW)
        if (krw < 0 || krw > 1) {
          _errors.push(`Value of Krw in row #${ index + 1 } must be between 0 and 1`)
        } else krwValues.push(krw)
      }
      if (!isNumeric(row.KRO)) {
        _errors.push(`Value of Kro in row #${ index + 1 } must be a number`)
        const kro = parseFloat(row.KRO)
        if (kro < 0 || kro > 1) {
          _errors.push(`Value of Kro in row #${ index + 1 } must be between 0 and 1`)
        } else kroValues.push(kro)
      }
      if (!isNumeric(row.PSIA)) {
        _errors.push(`Value of Pc (psia) in row #${ index + 1 } must be a number`)
      } else {
        const pc = parseFloat(row.PSIA)
        pcValues.push(pc)
      }
    })

    if (_errors.length) {
      setErrors(_errors)
      return
    }

    const lastKroValue = kroValues[kroValues.length - 1]
    if (kroValues.length > 1 && lastKroValue !== 0) {
      _errors.push(`The last Kro value must be 0`)
    }

    const isSwIncreasing = isIncreasing(swValues)
    if (!isSwIncreasing) {
      _errors.push(`The values of Sw must be increasing`)
    }

    const isKrwIncreasing = isIncreasing(krwValues)
    if (!isKrwIncreasing) {
      _errors.push(`The values of Krw must be increasing`)
    }

    const isKroDecreasing = isDecreasing(kroValues)
    if (!isKroDecreasing) {
      _errors.push(`The values of Kro must be decreasing`)
    }

    const isPcDecreasing = isDecreasing(pcValues)
    if (!isPcDecreasing) {
      _errors.push(`The values of Pc (psia) must be level or decreasing`)
    }

    setErrors(_errors)
  }, [])

  return (
    <FormSection
      title="SWO (Sw-Krw-Kro-Pc)"
      extra={
        <div className="d-flex justify-content-end align-items-center gap-2">
          <Typography.Text className="text-light">Reference</Typography.Text>
          <Select style={{ minWidth: 120 }} dropdownMatchSelectWidth={false} />
        </div>
      }
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0
      }}
    >
      <EditableProTable
        options={false}
        pagination={false}
        search={false}
        columns={columns}
        value={data}
        rowKey="key"
        tableExtraRender={() => (
          <ul className="px-24 text-danger">
            {errors.map(errMsg => (
              <li key={errMsg}>
                <Typography.Text type="danger">{errMsg}</Typography.Text>
              </li>
            ))}
          </ul>
        )}
        sticky={{
          offsetHeader: 32
        }}
        className={classes.tableContainer}
        cardProps={{
          className: classes.card
        }}
        tableClassName={classes.table}
        editable={{
          type: 'multiple',
          actionRender: (row, config, defaultDom) => [
            defaultDom.save,
            defaultDom.cancel,
          ],
          onSave: (rowKey, data, row) => {
            const { index, isNew, ...recordData } = data
            if (isNew) {
              operation?.add?.(recordData)
            } else {
              form.setFieldValue(["SCAL", tab, "SWO", index], recordData)
              fetchData()
            }
          }
        }}
        recordCreatorProps={{
          record: (index) => ({
            key: generateUuid(),
            isNew: true,
            KRW: index === 0 ? 0 : null
          })
        }}
      />
    </FormSection>
  )
}

export default SWTable