import { post } from "./base";

export function doCluster(data, onSuccess, onError) {
  post(
    "/api/type_curve/cluster/",
    data,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    (error) => { console.error(error); onError(error) },
    true
  );
}
