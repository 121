import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showNewProjectPopup: false,
  activeProjectId: null,
  projectsItems: null,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    resetProject: () => {
      return initialState;
    },
    setShowNewProjectPopup: (state, action) => {
      state.showNewProjectPopup = action.payload;
    },
    setActiveProjectId: (state, action) => {
      state.activeProjectId = action.payload;
    },
    setProjectItems: (state, action) => {
      state.projectsItems = action.payload;
    },
    addProjectItem: (state, action) => {
      const project = action.payload;
      if (state.projectsItems == null) {
        state.projectsItems = [project];
      } else {
        let projectsItems = state.projectsItems.filter(item => item.id !== project.id);
        state.projectsItems = [...projectsItems, project];
      }
    }
  },
});

export const {
  resetProject,
  setShowNewProjectPopup,
  setActiveProjectId,
  setProjectItems,
  addProjectItem,
} = projectsSlice.actions;

export const selectShowNewProjectPopup = (state) =>
  state.projects.showNewProjectPopup;

export const selectActiveProjectId = (state) => state.projects.activeProjectId;

export const selectActiveProject = (state) => {
  if (
    state.projects.activeProjectId == null ||
    state.projects.projectsItems == null
  ) {
    return null;
  }
  return state.projects.projectsItems.find(
    (project) => project.id === state.projects.activeProjectId
  );
};

export const selectProjectById = (state, projectId) => {
  if (projectId == null || state.projects.projectsItems == null) {
    return null;
  }
  return state.projects.projectsItems.find(
    (project) => project.id === projectId
  );
};

export const selectProjectsItems = (state) => state.projects.projectsItems;

export default projectsSlice.reducer;
