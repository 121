function IntroductionView() {
  const FileTypeItems = [
    { name: "Alarm", type: "*.alarm", description: "ascii table - operational alarm file" },
    { name: "Event", type: "*.event", description: "ascii table - historical records of well event" },
    { name: "Injection", type: "*.inj", description: "ascii table - Injection data, liquid, oil, gas, water, pressure …" },
    { name: "Operation", type: "*.schedule", description: "ascii table - scheduled operational activities" },
    { name: "Production", type: "*.prd", description: "ascii table - production data, liquid, oil, gas, water, pressure …" },
    { name: "Top", type: "*.top", description: "ascii table - formation tops" },
    { name: "Well Deviation", type: "*.dev", description: "ascii table - well deviation file" },
    { name: "Well head", type: "*.dat", description: "ascii table - well head and locator" },
  ];
  return (
    <div className="d-flex flex-column w-100">
      <div className="common-header d-flex align-items-center p-2">
        <div className="col-3 p-2">
          <span>Data</span>
        </div>
        <div className="col-3 p-2">
          <span>File Type</span>
        </div>
        <div className="col-6 p-2">
          <span>Explained</span>
        </div>
      </div>
      <div className="d-flex flex-column py-2">
        {FileTypeItems.map((row, rowId) => {
          return (
            <div key={rowId} className="common-row d-flex py-2 align-items-center">
              <div className="col-3 p-2">
                <span>{row.name}</span>
              </div>
              <div className="col-3 p-2">
                <span>{row.type}</span>
              </div>
              <div className="col-6 p-2">
                <span>{row.description}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default IntroductionView;
