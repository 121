export const UNITS = [
  '360 degree',
  'bbl',
  'bbl/month',
  'day',
  'F',
  'ft',
  'ft/s',
  'gallon',
  'gt',
  'HP',
  'inch',
  'KWh',
  'KWh/month',
  'lb',
  'md',
  'mscf',
  'mscf/month',
  'ppm',
  'psi',
  'psi/ft',
  'yyyy-mm-dd',
  'yyyy-mm-dd hh:mm:ss',
  'economic',
  '$',
  '$/bbl',
  '$/boe',
  '$/gal',
  '$/mcf',
  '$/mcfe',
  '%',
  '%/REV',
  'boe/d',
  'mcfe/d'
]