import { Table } from 'antd'
import React from 'react'

import classes from './performance-metrics-table.module.scss'
import { RECORD_FIELDS_MAP } from './consts'
import { formatDate } from 'utils/dateUtil'

/**
 * @type {import('antd/es/table').ColumnsType}
 */
const columns = [
  {
    title: RECORD_FIELDS_MAP.date.name,
    dataIndex: 'date',
    width: 100,
    fixed: true,
    render: (date) => formatDate(date, 'yyyy-MM')
  },
  {
    title: `${ RECORD_FIELDS_MAP.routine_direct_expense.name }${ RECORD_FIELDS_MAP.routine_direct_expense.unit ? ` (${ RECORD_FIELDS_MAP.routine_direct_expense.unit })` : '' }`,
    dataIndex: 'routine_direct_expense',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.direct_expense.name }${ RECORD_FIELDS_MAP.direct_expense.unit ? `(${ RECORD_FIELDS_MAP.direct_expense.unit })` : '' }`,
    dataIndex: 'direct_expense',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.indirect_expense.name }${ RECORD_FIELDS_MAP.indirect_expense.unit ? ` (${ RECORD_FIELDS_MAP.indirect_expense.unit })` : '' }`,
    dataIndex: 'indirect_expense',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.fixed_expense.name }${ RECORD_FIELDS_MAP.fixed_expense.unit ? ` (${ RECORD_FIELDS_MAP.fixed_expense.unit })` : '' }`,
    dataIndex: 'fixed_expense',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.variable_expense.name }${ RECORD_FIELDS_MAP.variable_expense.unit ? ` (${ RECORD_FIELDS_MAP.variable_expense.unit })` : '' }`,
    dataIndex: 'variable_expense',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.net_daily_prd_boe.name }${ RECORD_FIELDS_MAP.net_daily_prd_boe.unit ? ` (${ RECORD_FIELDS_MAP.net_daily_prd_boe.unit })` : '' }`,
    dataIndex: 'net_daily_prd_mcfe',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.ad_val_tax_revenue_rev.name }${ RECORD_FIELDS_MAP.ad_val_tax_revenue_rev.unit ? ` (${ RECORD_FIELDS_MAP.ad_val_tax_revenue_rev.unit })` : '' }`,
    dataIndex: 'ad_val_tax_revenue_rev',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.ad_val_tax_boe.name }${ RECORD_FIELDS_MAP.ad_val_tax_boe.unit ? ` (${ RECORD_FIELDS_MAP.ad_val_tax_boe.unit })` : '' }`,
    dataIndex: 'ad_val_tax_mcfe',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.serverance_taxes_boe.name }${ RECORD_FIELDS_MAP.serverance_taxes_boe.unit ? ` (${ RECORD_FIELDS_MAP.serverance_taxes_boe.unit })` : '' }`,
    dataIndex: 'serverance_taxes_mcfe',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.trans_processing_boe.name }${ RECORD_FIELDS_MAP.trans_processing_boe.unit ? ` (${ RECORD_FIELDS_MAP.trans_processing_boe.unit })` : '' }`,
    dataIndex: 'trans_processing_mcfe',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.workover_boe.name }${ RECORD_FIELDS_MAP.workover_boe.unit ? ` (${ RECORD_FIELDS_MAP.workover_boe.unit })` : '' }`,
    dataIndex: 'workover_mcfe',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.copas_overhead_boe.name }${ RECORD_FIELDS_MAP.copas_overhead_boe.unit ? ` (${ RECORD_FIELDS_MAP.copas_overhead_boe.unit })` : '' }`,
    dataIndex: 'copas_overhead_mcfe',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.direct_opex_boe.name }${ RECORD_FIELDS_MAP.direct_opex_boe.unit ? ` (${ RECORD_FIELDS_MAP.direct_opex_boe.unit })` : '' }`,
    dataIndex: 'direct_opex_mcfe',
    width: 100
  },
  {
    title: `${ RECORD_FIELDS_MAP.operating_income_boe.name }${ RECORD_FIELDS_MAP.operating_income_boe.unit ? ` (${ RECORD_FIELDS_MAP.operating_income_boe.unit })` : '' }`,
    dataIndex: 'operating_income_mcfe',
    width: 100
  }
]

const PerformanceMetricsTable = ({
  isLoading,
  records = []
}) => {
  return (
    <Table
      loading={isLoading}
      pagination={false}
      rowKey="id"
      columns={columns}
      dataSource={isLoading ? [] : records}
      className={classes.table}
      scroll={{
        x: 1400,
        y: 200
      }}
    />
  )
}

export default PerformanceMetricsTable