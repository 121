import { EditableProTable } from '@ant-design/pro-components'
import { Button, Col, Form, Popconfirm, Row, Select, Typography } from 'antd'
import FormSection from 'components/form-section'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { generateUuid } from 'utils/dataUtil'

import classes from './Table.module.scss'
import { isDecreasing, isIncreasing } from 'utils/number'
import { isNumeric } from 'utils/string'
import Chart from 'chart.js/auto'
import { groupBy, uniq } from 'lodash'

const units = {
  RS: {
    FIELD: '(Mscf/STB)',
    METRIC: '(m3/m3)'
  },
  PRESSURE: {
    FIELD: '(psia)',
    METRIC: '(bar)'
  },
  FVF: {
    FIELD: '(rbbl/STB)',
    METRIC: '(rm3/sm3)'
  },
  VISCOSITY: {
    FIELD: '(cP)',
    METRIC: '(cP)'
  }
}

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

/**
 * @type {import('chart.js').Plugin} htmlLegendPlugin
 */
const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    const groupedItems = groupBy(items, 'text')

    Object.entries(groupedItems).forEach(([legendText, items]) => {
      const item = items[0]
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      li.onclick = () => {
        items.forEach(i => {
          chart.setDatasetVisibility(i.datasetIndex, !chart.isDatasetVisible(i.datasetIndex));
        })
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.flexShrink = 0;
      boxSpan.style.height = '20px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '20px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};

const OilTable = ({ fields = [], operation }) => {
  /**
   * @type {React.MutableRefObject<Chart>} chart
   */
  const chart = useRef()
  const chartCanvas = useRef()
  const form = Form.useFormInstance()

  const simulationUnit = Form.useWatch(["GENERAL", "unit"])

  const [data, setData] = useState([])
  const [errors, setErrors] = useState([])

  const fetchData = () => {
    setData(fields.map((field, index) => ({
      ...form.getFieldValue(["PVT", "oil", field.name]),
      key: index
    })) ?? [])
  }

  useEffect(() => {
    validateData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  const columns = useMemo(
    /**
     * 
     * @returns {import('@ant-design/pro-components').ProColumnType[]}
     */
    () => [
      {
        key: 'key',
        dataIndex: 'key',
        title: '#',
        editable: false,
        render: (dom, record, index) => index + 1
      },
      {
        key: 'RS',
        dataIndex: 'RS',
        title: `Rs ${ units.RS[simulationUnit] ?? '' }`,
        editable: true
      },
      {
        key: 'PRESSURE',
        dataIndex: 'PRESSURE',
        title: `Pressure ${ units.PRESSURE[simulationUnit] ?? '' }`,
        editable: true
      },
      {
        key: 'FVF',
        dataIndex: 'FVF',
        title: `FVF ${ units.FVF[simulationUnit] ?? '' }`,
        editable: true,
      },
      {
        key: 'VISCOSITY',
        dataIndex: 'VISCOSITY',
        title: `Viscosity ${ units.VISCOSITY[simulationUnit] ?? '' }`,
        editable: true,
      },
      {
        title: "Operation",
        valueType: "option",
        key: "operation",
        width: 160,
        render: (text, record, index, action) => [
          <Button
            key="editable"
            type="text"
            className="text-primary"
            onClick={() => {
              action?.startEditable?.(record.key);
            }}
          >
            Edit
          </Button>,
          <Popconfirm
            key="delete"
            title="Sure to delete?"
            onConfirm={(e) => {
              operation?.remove?.(index)
            }}
          >
            <Button type="text" danger>Delete</Button>
          </Popconfirm>
        ],
      },
    ]
    , [operation, simulationUnit])

  const chartOptions = useMemo(
    /**
     * 
     * @returns {import('chart.js').ChartData}
     */
    () => {
      const labels = uniq(data.map(row => row[columns[2].dataIndex]))

      const sections = []
      const rsData = new Array(labels.length).fill(null)
      data.forEach(row => {
        const fvf = row[columns[3].dataIndex]
        const vsc = row[columns[4].dataIndex]
        const rs = row[columns[1].dataIndex]

        if (rs) {
          const ps = row[columns[2].dataIndex]
          const psIndex = labels.indexOf(ps)
          const fvfArr = new Array(psIndex + 1)
          const vscArr = new Array(psIndex + 1)

          rsData[psIndex] = rs

          sections.push({
            fvf: fvfArr.fill(null).map((item, index) => {
              if (index < psIndex) return item
              return fvf
            }),
            vsc: vscArr.fill(null).map((item, index) => {
              if (index < psIndex) return item
              return vsc
            }),
          })
          return
        }
        const lastSection = sections[sections.length - 1]
        if (!lastSection) return;

        lastSection.fvf.push(fvf)
        lastSection.vsc.push(vsc)
      })

      const datasets = sections.flatMap(section => [{
        data: section.fvf,
        fill: false,
        borderColor: 'green',
        yAxisID: columns[3].dataIndex,
        tension: 0.4,
        label: columns[3].title,
      },
      {
        data: section.vsc,
        fill: false,
        borderColor: 'blue',
        yAxisID: columns[4].dataIndex,
        tension: 0.4,
        label: columns[4].title,
      }])

      return {
        labels,
        datasets: [
          {
            data: rsData,
            fill: false,
            borderColor: 'red',
            yAxisID: columns[1].dataIndex,
            tension: 0.4,
            label: columns[1].title
          },
          ...datasets
        ]
      }
    },
    [columns, data])

  /**
   * 
   * @param {import('chart.js').ChartData} config 
   * @returns 
   */
  const updateChart = (config) => {
    if (!chartCanvas.current) return
    if (!chart.current) return

    chart.current.data.labels = config.labels
    chart.current.data.datasets = config.datasets

    chart.current.update()
  }

  useEffect(() => {
    if (chart.current) chart.current.destroy()
    chart.current = new Chart(chartCanvas.current, {
      type: 'line',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'legend-container',
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yAxisID;
            }
          }
        },
        scales: {
          [columns[1].dataIndex]: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: columns[1].title
            }
          },
          [columns[4].dataIndex]: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: columns[4].title
            }
          },
          [columns[3].dataIndex]: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
              text: columns[3].title
            }
          },
          x: {
            display: true,
            title: {
              display: true,
              text: columns[1].title
            }
          }
        }
      },
      plugins: [htmlLegendPlugin]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateChart(chartOptions)
  }, [chartOptions])

  const validateData = useCallback((_data = []) => {
    setErrors([])

    const _errors = []

    if (!_data.length) return;

    let sectionsCount = 0;

    const rsValues = []
    const pressureValues = []
    const fvfValues = []
    const viscosityValues = []

    _data.forEach((row, index) => {
      if (row.RS) {
        sectionsCount++
        if (!isNumeric(row.RS)) {
          _errors.push(`Value of RS in row #${ index + 1 } must be a number`)
        } else {
          const rs = parseFloat(row.RS)
          if (rs <= 0) {
            _errors.push(`Value of RS in row #${ index + 1 } must be > 0`)
          } else rsValues.push(rs)
        }
      }
      if (!isNumeric(row.PRESSURE)) {
        _errors.push(`Value of Pressure in row #${ index + 1 } must be a number`)
      } else {
        const pressure = parseFloat(row.PRESSURE)
        if (pressure <= 0) {
          _errors.push(`Value of Pressure in row #${ index + 1 } must be > 0`)
        } else pressureValues.push(pressure)
      }
      if (!isNumeric(row.FVF)) {
        _errors.push(`Value of FVF in row #${ index + 1 } must be a number`)
      } else {
        const fvf = parseFloat(row.FVF)
        if (fvf <= 0) {
          _errors.push(`Value of FVF in row #${ index + 1 } must be > 0`)
        } else fvfValues.push(fvf)
      }
      if (!isNumeric(row.VISCOSITY)) {
        _errors.push(`Value of Viscosity in row #${ index + 1 } must be a number`)
      } else {
        const viscosity = parseFloat(row.VISCOSITY)
        if (viscosity <= 0) {
          _errors.push(`Value of Viscosity in row #${ index + 1 } must be > 0`)
        } else viscosityValues.push(viscosity)
      }
    })

    if (sectionsCount < 2) {
      _errors.push('Oil table must have 2 or more sections')
    }

    if (_errors.length) {
      setErrors(_errors)
      return
    }

    const isRSIncreasing = isIncreasing(rsValues.filter(Boolean))
    if (!isRSIncreasing) {
      _errors.push(`The values of RS must be increasing`)
    }

    const isPressureIncreasing = isIncreasing(pressureValues)
    if (!isPressureIncreasing) {
      _errors.push(`The values of Pressure must be increasing`)
    }

    const isFVFDecreasing = isDecreasing(fvfValues)
    if (!isFVFDecreasing) {
      _errors.push(`The values of FVF must be decreasing`)
    }

    const isViscosityIncreasing = isIncreasing(viscosityValues)
    if (!isViscosityIncreasing) {
      _errors.push(`The values of Viscosity must be increasing`)
    }

    setErrors(_errors)
  }, [])

  return (
    <FormSection
      title="Oil"
      extra={
        <div className="d-flex justify-content-end align-items-center gap-2">
          <Typography.Text className="text-light">Reference</Typography.Text>
          <Select style={{ minWidth: 120 }} dropdownMatchSelectWidth={false} />
        </div>
      }
    >
      <Row>
        <Col span={12}>
          <EditableProTable
            options={false}
            pagination={false}
            search={false}
            columns={columns}
            value={data}
            rowKey="key"
            sticky={{
              offsetHeader: 32
            }}
            className={classes.tableContainer}
            cardProps={{
              className: classes.card
            }}
            tableClassName={classes.table}
            tableExtraRender={() => (
              <ul className="px-24 text-danger">
                {errors.map(errMsg => (
                  <li key={errMsg}>
                    <Typography.Text type="danger">{errMsg}</Typography.Text>
                  </li>
                ))}
              </ul>
            )}
            editable={{
              type: 'multiple',
              actionRender: (row, config, defaultDom) => [
                defaultDom.save,
                defaultDom.cancel,
              ],
              onSave: (rowKey, data, row) => {
                const { index, isNew, ...recordData } = data
                if (isNew) {
                  operation?.add?.(recordData)
                } else {
                  form.setFieldValue(["PVT", "oil", index], recordData)
                  fetchData()
                }
              }
            }}
            recordCreatorProps={{
              record: {
                key: generateUuid(),
                isNew: true
              }
            }}
          />
        </Col>
        <Col span={12} className="pb-2">
          <div className="flex flex-col gap-4">
            <div id='legend-container'></div>
            <canvas ref={chartCanvas} className='max-h-[500px]' />
          </div>
        </Col>
      </Row>
      {/* <div className="flex flex-col gap-4">
        <div id='legend-container'></div>
        <canvas ref={chartCanvas} className='max-h-[500px]' />
      </div> */}
    </FormSection>
  )
}

export default OilTable