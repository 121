import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectActiveProject,
  setShowNewProjectPopup,
} from "features/project/projectsSlice";
import { selectActiveWells } from "features/asset/assetSelectorSlice";
import {
  selectUsedWellInterpoParams,
  selectSelectedWellIds,
  selectCustomWells,
  setCustomWellScreenParams,
  setGridData,
  selectGridData,
  setUpdatedCustomWells,
  setWellGridProperties,
  selectWellGridProperties,
  selectGridType,
  selectReInterpolate,
  setReInterpolate,
  setWellLatLng,
} from "features/well_planner/plannerSlice";

import { MapContainer } from "react-leaflet/MapContainer";
import { Popup } from "react-leaflet/Popup";
import { Marker } from "react-leaflet/Marker";
import { Tooltip } from "react-leaflet/Tooltip";
import { TileLayer } from "react-leaflet/TileLayer";
import { useMapEvents } from "react-leaflet/hooks";
import { SVGOverlay } from "react-leaflet/SVGOverlay";
import { getLatLng } from "../../utils/geo";

import L from "leaflet";
import transparentMarker from "components/map/images/transparent.png";
import greenMarker from "components/map/images/green-marker.png";

import {
  DefaultObjectiveSize,
  generateGrids,
  drawGrids,
  generateScreenGrids,
  getRotate,
  interpolateWellsWithGrid,
  computeGridParams,
  toScreenCoordinate,
} from "./map";
import {
  update_well_data_after_interpolate,
  parseFloatValue,
} from "utils/dataUtil";

function EventHandlers(props) {
  let dispatch = useDispatch();
  const { pickMode } = props;

  // eslint-disable-next-line
  const map = useMapEvents({
    click: (e) => {
      if (pickMode) {
        dispatch(setWellLatLng({ x0: e.latlng.lat, y0: e.latlng.lng }));
      }
    },
    locationfound: (location) => { },
  });
  return null;
}

function LMap(props) {
  const {
    objectives,
    center,
    zoom,
    bounds,
    pickMode,
    grid_params,
    objectiveSelectedHandler,
    interpolateParam,
  } = props;

  const currentProject = useSelector(selectActiveProject);

  const activeWells = useSelector(selectActiveWells);
  const selectedWellIds = useSelector(selectSelectedWellIds);

  const customWells = useSelector(selectCustomWells);
  const gridData = useSelector(selectGridData);
  const gridType = useSelector(selectGridType);
  const wellGridProperties = useSelector(selectWellGridProperties);

  const usedWellInterpoParams = useSelector(selectUsedWellInterpoParams);
  const reInterpolate = useSelector(selectReInterpolate);

  const dispatch = useDispatch();

  const [map, setMap] = useState(null);
  const [gridGroup, setGridGroup] = useState(null);
  // const [markerGroup, setMarkerGroup] = useState(null);

  const selectedWells = useMemo(() => {
    let selectedWells = [];
    if (
      selectedWellIds != null &&
      selectedWellIds.length > 0 &&
      activeWells != null &&
      activeWells.length > 0
    ) {
      let wellIdsSet = new Set(selectedWellIds);
      selectedWells = activeWells.filter((well) => wellIdsSet.has(well.uwi));
    }
    return selectedWells;
  }, [activeWells, selectedWellIds]);

  function getMarker(objective, idsSet) {
    return idsSet && idsSet.has(objective.apiId)
      ? transparentMarker
      : greenMarker;
  }

  const gridParams = useMemo(() => {
    if (grid_params == null) {
      return null;
    }
    let params = computeGridParams(grid_params);
    return params;
  }, [grid_params]);

  const wellObjectives = useMemo(() => {
    if (objectives == null || objectives.length === 0) {
      return [];
    }
    // let wellIds = new Set();
    let allWellObjectives = [...objectives];
    if (customWells != null && customWells.length > 0) {
      let customObjectives = customWells.map((well) => {
        // wellIds.add(well.well_uwi);
        return {
          Name: well.well_name,
          Latitude: well.landing_x,
          Longitude: well.landing_y,
          rotation: well.rotation,
          distance: well.wellbore_length,
          Value: DefaultObjectiveSize,
          apiId: well.well_uwi,
        };
      });
      allWellObjectives = [...allWellObjectives, ...customObjectives];
    }
    return allWellObjectives;
  }, [objectives, customWells]);

  const customWellIdsSet = useMemo(() => {
    if (customWells == null || customWells.length === 0) return new Set();
    return new Set(customWells.map((well) => well.well_uwi));
  }, [customWells]);

  useEffect(() => {
    if (map) {
      if (bounds) {
        if (
          bounds.length === 2 &&
          bounds[0][0] &&
          bounds[0][1] &&
          bounds[1][0] &&
          bounds[0][1]
        )
          map.fitBounds(bounds);
      } else {
        map.setView(center, zoom);
      }
      setGridGroup((gridGroup) => gridGroup || L.layerGroup().addTo(map));

      // setMarkerGroup((markerGroup) => markerGroup || L.layerGroup().addTo(map));
    }
  }, [center, zoom, bounds, map]);

  useEffect(() => {
    if (gridParams == null || map == null) {
      return;
    }
    try {
      let grids = generateGrids(gridParams, map);
      dispatch(setGridData(grids));
    } catch (error) {
      console.error(error);
    }
  }, [gridParams, map, dispatch]);

  useEffect(() => {
    if (
      map == null ||
      customWells == null ||
      grid_params == null ||
      gridParams == null ||
      wellGridProperties == null ||
      gridType == null
    ) {
      return;
    }

    if (gridType !== grid_params.grid_type) {
      return;
    }

    // let rotation = getRotate(grid_params.rotation);
    let wellScreenParams = customWells.map((well) => {
      let x = parseFloatValue(well.landing_x);
      let y = parseFloatValue(well.landing_y);
      // let screenPoint = latlng2Pixel([x, y], rotation, map);
      let screenPoint = toScreenCoordinate([x, y], gridParams, map);
      return {
        well_uwi: well.well_uwi,
        screen_x: screenPoint.x,
        screen_y: screenPoint.y,
      };
    });
    dispatch(setCustomWellScreenParams(wellScreenParams));

    let screenCoordinates = generateScreenGrids(gridParams, map);
    const params = Object.keys(wellGridProperties);
    let updatedCustomWells = [];

    for (let well of wellScreenParams) {
      let updatedWell = { ...well };
      for (let param of params) {
        let z_grid = wellGridProperties[param];

        let value = update_well_data_after_interpolate(
          screenCoordinates.xCoordinates,
          screenCoordinates.yCoordinates,
          well.screen_x,
          well.screen_y,
          z_grid
        );
        updatedWell[param] = value;
      }

      updatedCustomWells.push(updatedWell);
    }
    dispatch(setUpdatedCustomWells(updatedCustomWells));
  }, [
    customWells,
    map,
    grid_params,
    gridParams,
    wellGridProperties,
    dispatch,
    gridType,
  ]);

  useEffect(() => {
    if (
      map == null ||
      grid_params == null ||
      selectedWells == null ||
      selectedWells.length === 0 ||
      usedWellInterpoParams == null ||
      reInterpolate === false
    ) {
      return;
    }

    // if (gridGroup == null || markerGroup == null) {
    if (gridGroup == null) {
      return;
    }

    gridGroup.clearLayers();

    if (currentProject == null) {
      dispatch(setShowNewProjectPopup(true));
      return;
    }

    if (reInterpolate) {
      dispatch(setReInterpolate(false));
    }

    interpolateWellsWithGrid(
      currentProject.id,
      selectedWells,
      usedWellInterpoParams,
      gridParams,
      map,
      (res) => {
        dispatch(setWellGridProperties(res));
      },
      (error) => {
        console.error(error);
      }
    );
  }, [
    reInterpolate,
    currentProject,
    dispatch,
    gridGroup,
    gridParams,
    grid_params,
    map,
    // markerGroup,
    selectedWells,
    usedWellInterpoParams,
  ]);

  useEffect(() => {
    if (
      gridData == null ||
      grid_params == null ||
      wellGridProperties == null ||
      gridGroup == null
      // ||markerGroup == null
    ) {
      return;
    }
    if (interpolateParam) {
      let wellProperties = wellGridProperties[interpolateParam];
      if (wellProperties == null || wellProperties.length === 0) {
        console.warn("no interplated well properties");
        return;
      }
      // drawGrids(gridData, wellProperties, gridGroup, markerGroup);
      drawGrids(gridData, wellProperties, gridGroup);
    }
  }, [
    gridData,
    interpolateParam,
    wellGridProperties,
    gridGroup,
    grid_params,
    // markerGroup,
  ]);

  return (
    <MapContainer
      className="map-container"
      center={center}
      zoom={zoom}
      ref={setMap}
    >
      <EventHandlers pickMode={pickMode} />
      <TileLayer
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {wellObjectives &&
        wellObjectives.length > 0 &&
        wellObjectives.map((objective, index) => (
          <Marker
            key={index}
            icon={
              new L.icon({
                iconUrl: getMarker(objective, customWellIdsSet),
                iconAnchor: null,
                popupAnchor: [0, -10],
                shadowUrl: null,
                shadowSize: null,
                shadowAnchor: null,
                iconSize: new L.Point(objective.Value, objective.Value),
              })
            }
            position={[objective.Latitude, objective.Longitude]}
            eventHandlers={{
              click: () => {
                // let ids = new Set();
                // ids.add(objective.apiId);
                // setSelectedObjectiveIds(ids);
                objectiveSelectedHandler(objective);
              },
            }}
          >
            <Popup>
              Postion: ({objective.Latitude}, {objective.Longitude})
            </Popup>
            <Tooltip
              sticky
              className="tooltip"
              direction="top"
              offset={[0, 0]}
              opacity={1}
              permanent
            >
              <p className="fw-bold">{objective.Name}</p>
            </Tooltip>
          </Marker>
        ))}
      {wellObjectives.length > 0 &&
        wellObjectives.map((well, index) => {
          let { Latitude, Longitude, distance, rotation } = well;
          if (
            Latitude === undefined ||
            Longitude === undefined ||
            distance === undefined ||
            rotation === undefined
          ) {
            return null;
          }
          Latitude = parseFloat(Latitude);
          Longitude = parseFloat(Longitude);
          distance = parseFloat(distance);
          rotation = 90 - parseFloat(rotation);
          const { radians } = getRotate(rotation);
          const [lat2, lng2] = getLatLng(
            Latitude,
            Longitude,
            distance,
            radians,
            "F"
          );

          return (
            <SVGOverlay key={index} bounds={bounds}>
              {(([[Latitude, Longitude], [lat2, lng2]]) => {
                let [[bx1, by1], [bx2, by2]] = bounds;
                let width = Math.abs(bx2 - bx1),
                  height = Math.abs(by2 - by1);
                let x1, y1, x2, y2;
                // codes under is very confusing because the percentage coordinates in the map are very different against geo-coordinates
                y1 = (Math.abs(Latitude - bx2) / width) * 100 + "%";
                x1 = (Math.abs(Longitude - by1) / height) * 100 + "%";
                y2 = (Math.abs(lat2 - bx2) / width) * 100 + "%";
                x2 = (Math.abs(lng2 - by1) / height) * 100 + "%";
                return (
                  <line
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    fill="red"
                    strokeWidth="3"
                    stroke="red"
                  />
                );
              })([
                [Latitude, Longitude],
                [lat2, lng2],
              ])}
            </SVGOverlay>
          );
        })}
    </MapContainer>
  );
}

export default LMap;
