import { get, post, remove } from "./base";

/**
 * 
 * @param {*} uwis uwi or uwi list
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */

export function getWellCompletionString(uwis, onSuccess, onError) {
  let param = null;
  if (Array.isArray(uwis)) {
    if (uwis.length > 0)
      param = { uwis: uwis.join(",") };
  } else {
    param = { uwis: uwis };
  }
  console.log("uwis", uwis);
  return get(
    "/api/completions/string/",
    param,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export function updateWellCompletionString(completions, onSuccess, onError) {
  if (!completions || !Array.isArray(completions) || !completions.length) {
    return;
  }

  return post(
    "/api/completions/string/",
    {completions: completions},
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) },
    true,
  );
}

export function deleteWellCompletionString(ids, onSuccess, onError) {
  if (!ids || !Array.isArray(ids) || !ids.length) {
    return;
  }

  return remove(
    "/api/completions/string/",
    { ids: ids },
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export function getWellCompletionFrac(uwis, onSuccess, onError) {
  let param = null;
  if (uwis) {
    if (Array.isArray(uwis)) {
      if (uwis.length > 0)
        param = { uwis: uwis.join(",") };
    } else {
      param = { uwis: uwis };
    }
  }

  return get(
    "/api/completions/frac/",
    param,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export function updateWellCompletionFrac(completions, onSuccess, onError) {
  if (!completions || !Array.isArray(completions) || !completions.length) {
    return;
  }

  return post(
    "/api/completions/frac/",
    {completions: completions},
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) },
    true,
  );
}

export function deleteWellCompletionFrac(ids, onSuccess, onError) {
  if (!ids || !Array.isArray(ids) || !ids.length) {
    return;
  }

  return remove(
    "/api/completions/frac/",
    { ids: ids },
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export function getWellCompletionFracChem(uwis, onSuccess, onError) {
  let param = null;
  if (uwis) {
    if (Array.isArray(uwis)) {
      if (uwis.length > 0)
        param = { uwis: uwis.join(",") };
    } else {
      param = { uwis: uwis };
    }
  }

  return get(
    "/api/completions/frac/chem/",
    param,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export function updateWellCompletionFracChem(completions, onSuccess, onError) {
  if (!completions || !Array.isArray(completions) || !completions.length) {
    return;
  }

  return post(
    "/api/completions/frac/chem/",
    completions,
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) },
    true,
  );
}

export function deleteWellCompletionFracChem(ids, onSuccess, onError) {
  if (!ids || !Array.isArray(ids) || !ids.length) {
    return;
  }

  return remove(
    "/api/completions/frac/chem/",
    { ids: ids },
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

// ===========Input Config and Upload==================

export function getWellCompletionStringInput(onSuccess, onError) {
  return get(
    "/api/datamgr/completions/string/input_config",
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export function getWellCompletionFracInputConfig(onSuccess, onError) {
  return get(
    "/api/datamgr/completions/frac/input_config",
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

export function getWellCompletionFracChemInputConfig(onSuccess, onError) {
  return get(
    "/api/datamgr/completions/frac/chem/input_config",
    null,
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) }
  );
}

/**
 * 
 * @param {*} formInputData {
    "dataStructure": [
        {
            "selectedColumn": {
                "name": "well_name"
            },
            "selectedUnit": {
                "id": 17,
                "name": "N/A"
            }
        },
        {
            "selectedColumn": {
                "name": "number_of_stage"
            },
            "selectedUnit": {
                "id": 17,
                "name": "N/A"
            }
        },
        ......
    ],
    "completionsData": [
        ["BRC-686", 50, 4.5, 5.5, "N85", 3000, 3500, 8500, "N95", 3.25, 3.5, 1000, "N95", 8.5, 10.25, 0, 2.125, 2.625, 8400, 0, "T35"],
["BRC-659",45,4.5,5.5,"N85",3050,3525,8550,"N95",3.25,3.5,1000,"N95",8.5,10.25,0,2.125,2.625,8450,0,"T34"],
["BRC-729",50,4.5,5.5,"N85",3100,3550,8600,"N95",3.25,3.5,1000,"N95",8.5,10.25,0,2.125,2.625,8500,0,"T35"]
    ]
}
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function uploadWellCompletionString(formInputData, onSuccess, onError) {
  return post(
    "/api/datamgr/completions/string/input",
    { inputData: JSON.stringify(formInputData) },
    {},
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) },
    true,
  );
}

/**
 * 
 * @param {*} formInputData {
    "dataStructure": [
        {
            "selectedColumn": {
                "name": "well_name"
            },
            "selectedUnit": {
                "id": 17,
                "name": "N/A"
            }
        },
        {
            "selectedColumn": {
                "name": "stage_number"
            },
            "selectedUnit": {
                "id": 17,
                "name": "N/A"
            }
        },
        {
            "selectedColumn": {
                "name": "job_date"
            },
            "selectedUnit": {
                "id": 9,
                "name": "mm/dd/yyyy"
            }
        },
        {
            "selectedColumn": {
                "name": "frac_fluid_mass"
            },
            "selectedUnit": {
                "id": 28,
                "name": "gallon"
            }
        },
        {
            "selectedColumn": {
                "name": "fluid_type"
            },
            "selectedUnit": {
                "id": 17,
                "name": "N/A"
            }
        },
        {
            "selectedColumn": {
                "name": "proppant_type"
            },
            "selectedUnit": {
                "id": 17,
                "name": "N/A"
            }
        },
        {
            "selectedColumn": {
                "name": "proppant_mesh"
            },
            "selectedUnit": {
                "id": 17,
                "name": "N/A"
            }
        },
        {
            "selectedColumn": {
                "name": "proppant_mass"
            },
            "selectedUnit": {
                "id": 29,
                "name": "lb"
            }
        },
        {
            "selectedColumn": {
                "name": "average_stp"
            },
            "selectedUnit": {
                "id": 5,
                "name": "psi"
            }
        },
        {
            "selectedColumn": {
                "name": "fracture_gradient"
            },
            "selectedUnit": {
                "id": 30,
                "name": "psi/ft"
            }
        },
        {
            "selectedColumn": {
                "name": "md_middle_perforation"
            },
            "selectedUnit": {
                "id": 21,
                "name": "ft"
            }
        },
        {
            "selectedColumn": {
                "name": "min_stp"
            },
            "selectedUnit": {
                "id": 5,
                "name": "psi"
            }
        },
        {
            "selectedColumn": {
                "name": "max_stp"
            },
            "selectedUnit": {
                "id": 5,
                "name": "psi"
            }
        }
    ],
    "completionsData": [
        ["BRC-686",1,"2/10/10 20:25",30000,"water","Sand","40/70",2500,7725,0.75,6800,7500,7950],
["BRC-686",2,"2/10/10 20:25",50000,"water","Sand","40/70",2500,7905,0.75,6800,7580,8230],
["BRC-686",3,"2/10/10 20:25",30000,"water","Sand","40/70",2500,8085,0.75,6800,7660,8510]
    ]
} 
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function uploadWellCompletionFrac(formInputData, onSuccess, onError) {
  return post(
    "/api/datamgr/completions/frac/input",
    { inputData: JSON.stringify(formInputData) },
    {},
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) },
    true,
  );
}

/**
 * 
 * @param {*} formInputData {
    "dataStructure": [
        {
            "well_name": "BRC-686",
            "stage_number": 1,
            "additive_id": 231,
            "columns": [
                {
                    "selectedColumn": {
                        "name": "additive_mass"
                    },
                    "selectedUnit": {
                        "id": 29,
                        "name": "lb"
                    }
                },
                {
                    "selectedColumn": {
                        "name": "concentration"
                    },
                    "selectedUnit": {
                        "id": 32,
                        "name": "ppm"
                    }
                }
            ]
        },
        ....
    ],
    "completionsData": [
        [
            "BRC-686",
            1,
            231,
            "surfactant",
            3000,
            "Friction reducer",
            200,
            "Friction reducer"
        ],
        ....
    ]
}
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function uploadWellCompletionChem(formInputData, onSuccess, onError) {
  return post(
    "/api/datamgr/completions/frac/chem/input",
    { inputData: JSON.stringify(formInputData) },
    {},
    {},
    (res) => {
      onSuccess && onSuccess(res.data);
    },
    (error) => { console.error(error); onError && onError(error) },
    true,
  );
}

