/*
    render single or multipl parameters curve(provided by PpWellCurve) of one well.
    support loose or tight mode for Log Viewer and Correlation.
*/
import { Empty } from "antd";
import React, { Fragment, useEffect, useRef, useState } from "react";
import * as echarts from 'echarts';
import PpWellMultiCurve from "./PpWellMultiCurve";

const PpWell = (props) => {
    const { wellData, gapWidth, columnWidth, tops, height, uwi, onTopCoordsChange, yAxisboundary, startEndValue, onZoomChange, syncDepth, onChartReady } = props;
    const [wellStartEndValue, setWellStartEndValue] = useState(startEndValue || [0, 100]);
    const [groupData, setGroupData] = useState(null);
    const zoomRef = useRef(null);

    useEffect(() => {
        if (!startEndValue) return;
        if (startEndValue[0] !== wellStartEndValue[0] || startEndValue[1] !== wellStartEndValue[1])
            setWellStartEndValue([...startEndValue]);
    }, [startEndValue, wellStartEndValue])

    const handleChange = (index, startEnd) => {
        if (!startEnd || startEnd.length < 2) return;
        if (startEnd[0] !== wellStartEndValue[0] || startEnd[1] !== wellStartEndValue[1]) {
            if (onZoomChange) {
                onZoomChange(uwi, startEnd);
            }
        }
    }

    /**
     * 
     * @param {*} wellData {logId: [[194, 0.506, 1, 3], ...], ...}  // [depth, value, unit_min, unit_max]
     * @returns grouped {
            "combined": {
                "Neutron:ni": [...],
                "Density:di":[...],
            },
            "ACOUSTIC": {
                "ACOUSTIC:aco": [...],
                "ACOUSTIC:aa": [...],
            },
    }
     */
    const groupedWellData = wellData => {
        if (!wellData) return null;
        const COMBINED_LOG_TYPES = ["Neutron", "Density", "Density_Correction", "Photoelectric_Factor"];

        const grouped = {};   // "combined" for combined: Neutron, Density, Density_Correction, Photoelectric_Factor
        Object.keys(wellData).forEach(logId => {
            const [logType, logName] = logId.split(":");

            let groupName = COMBINED_LOG_TYPES.includes(logType) ? "combined" : logType;
            if (!grouped[groupName]) grouped[groupName] = {};
            grouped[groupName][logId] = wellData[logId];
        });
        return grouped;
    }

    useEffect(() => {
        setGroupData(groupedWellData(wellData));
    }, [wellData])

    return (<>
        {
            groupData ? Object.keys(groupData).sort((a, b) => (a < b ? -1 : 1)).map((param, index) => {
                return (<Fragment key={index}>
                    <span >
                        <PpWellMultiCurve
                            uwi={uwi}
                            zoomRef={zoomRef}
                            key={index}
                            index={index}
                            param={param.split(":").length > 1 ? param.split(":")[1] : param}
                            titleTooltip={param.split(":").length > 1 ? param.split(":")[0] : param}
                            dataSeries={groupData[param]}
                            tops={tops}
                            height={height}
                            config={{
                                columnWidth: (parseInt(columnWidth) + (index === 0 ? 40 : 0)) + 'px',
                                showYAxis: index === 0,
                                startColorIndex: 0,
                            }}
                            yAxisboundary={yAxisboundary}
                            onTopCoordsChange={([uwi, res, index, startEnd]) => {
                                handleChange(index, startEnd);
                                echarts.connect(syncDepth ? 'shale' : uwi);
                                onTopCoordsChange([uwi, res, index]);
                            }}
                            showZoomer={index === (Object.keys(groupData).length - 1)}
                            startEndValue={wellStartEndValue}
                            syncDepth={syncDepth}
                            onChartReady={onChartReady}
                        />
                    </span>
                    <span style={{ width: gapWidth || '0px' }} ></span>
                </Fragment>)
            })
                : <Empty />
        }
    </>

    )
};
export default PpWell;