import { UploadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, InputNumber, Upload } from 'antd'
import FormSection from 'components/form-section'
import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'

const units = {
  wellboreLength: {
    FIELD: '(ft)',
    METRIC: '(m)'
  },
  wellboreDiameter: {
    FIELD: '(in)',
    METRIC: '(cm)'
  },
}

const WellSetting = () => {
  const isFirstRender = useRef(true)
  const form = Form.useFormInstance()

  const numberOfStages = Form.useWatch(['Completion', 'well', 'NUMBER_STAGES'])
  const simulationUnit = Form.useWatch(["GENERAL", "unit"])

  const updateStagesValues = (numberOfStages) => {
    if (!numberOfStages) return
    const currentStages = form.getFieldValue(["Completion", "hydraulic_fracture"])
    if (currentStages?.length && currentStages?.length >= numberOfStages) return
    const newStagesCount = (numberOfStages ?? 0) - (currentStages?.length ?? 0)
    const stageArr = Array(newStagesCount).fill({
      MODEL: 'GENERIC',
      STAGE_LEN: "",
      SAND_USAGE: "",
      NUMBER_CLUSTERS: "",
      PRIMARY: {
        FRACTURE_HALF_LEN: "",
        VERTICAL_PENETRATION: "",
        FRACTURE_WIDTH: ""
      },
      SECONDARY: {
        FRACTURE_HALF_LEN: "",
        VERTICAL_PENETRATION: "",
        FRACTURE_WIDTH: ""
      }
    })

    form.setFieldValue(["Completion", "hydraulic_fracture"], [...(currentStages ?? []), ...stageArr])
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return;
    }
    updateStagesValues(numberOfStages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfStages])

  return (
    <div className="vstack gap-4">
      <FormSection title="General">
        <Form.Item name={['Completion', 'well', 'WELL_NAME']} label="Well Name">
          <Input />
        </Form.Item>
      </FormSection>
      <FormSection title="Completion">
        <div className="row">
          <div className="col-6">
            <div className="vstack gap-4">
              <Form.Item name={['Completion', 'well', 'WELLBORE_LEN']} label={`Wellbore Length ${ units.wellboreLength[simulationUnit] }`}>
                <InputNumber className="w-100" />
              </Form.Item>
              <Form.Item name={['Completion', 'well', 'WELLBORE_DIAMETER']} label={`Wellbore diameter ${ units.wellboreDiameter[simulationUnit] }`}>
                <InputNumber className="w-100" />
              </Form.Item>
            </div>
          </div>
          <div className="col-6">
            <Form.Item name={['Completion', 'well', 'NUMBER_STAGES']} label="Number of stages">
              <InputNumber className="w-100" />
            </Form.Item>
          </div>
        </div>
      </FormSection>
      <FormSection title="Production">
        <Form.Item name={['Completion', 'well', 'DATE_INITIAL_PROD']} label="Date of initial production" getValueProps={(value) => ({
          value: value ? dayjs(value) : undefined
        })}>
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>
      </FormSection>
      {/* <FormSection title="Observation Data (optional)">
        <Upload accept="text/csv" multiple={false}>
          <Button icon={<UploadOutlined />}>Upload from local CSV file</Button>
        </Upload>
      </FormSection> */}
    </div>
  )
}

export default WellSetting