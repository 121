import { Modal, Select, Spin, Table, Typography } from 'antd'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Papaparse from 'papaparse'
import classNames from 'classnames'
import { USER_PREDICT_DATA_FIELDS } from './data'

import classes from './user-predict-data-import.module.scss'

/**
 * @type {React.FC<{
 *  data: Array<string[]>
 * }>}
 */
const DataTable = forwardRef(({ data }, ref) => {
  /**
   * @type {[Record<number, string>, React.Dispatch<Record<number, string>>]}
   */
  const [columnsMap, setColumnMap] = useState({
    0: USER_PREDICT_DATA_FIELDS[0].value,
    1: USER_PREDICT_DATA_FIELDS[1].value,
    2: USER_PREDICT_DATA_FIELDS[2].value,
    3: USER_PREDICT_DATA_FIELDS[3].value
  })

  useImperativeHandle(ref, () => ({
    getImportData: () => {
      return data.map(fields => {
        return {
          [columnsMap[0]]: fields[0] ?? null,
          [columnsMap[1]]: fields[1] ?? null,
          [columnsMap[2]]: fields[2] ?? null,
          [columnsMap[3]]: fields[3] ?? null
        }
      })
    }
  }))

  const onSelectColumnName = useCallback((index, colName) => {
    setColumnMap(prev => ({
      ...prev,
      [index]: colName
    }))
  }, [])

  const columns = useMemo(
    /**
     * 
     * @returns {import('antd/es/table').ColumnsType<string[]>}
     */
    () => {
      return [
        {
          title: (props) => (
            <Select
              className={classes.columnSelector}
              dropdownMatchSelectWidth={false}
              value={columnsMap[0]}
              onChange={(val) => onSelectColumnName(0, val)}
              options={USER_PREDICT_DATA_FIELDS}
            />
          ),
          dataIndex: 0
        },
        {
          title: (props) => (
            <Select
              className={classes.columnSelector}
              dropdownMatchSelectWidth={false}
              value={columnsMap[1]}
              onChange={(val) => onSelectColumnName(1, val)}
              options={USER_PREDICT_DATA_FIELDS} />
          ),
          dataIndex: 1
        },
        {
          title: (props) => (
            <Select
              className={classes.columnSelector}
              dropdownMatchSelectWidth={false}
              value={columnsMap[2]}
              onChange={(val) => onSelectColumnName(2, val)}
              options={USER_PREDICT_DATA_FIELDS} />
          ),
          dataIndex: 2
        },
        {
          title: (props) => (
            <Select
              className={classes.columnSelector}
              dropdownMatchSelectWidth={false}
              value={columnsMap[3]}
              onChange={(val) => onSelectColumnName(3, val)}
              options={USER_PREDICT_DATA_FIELDS} />
          ),
          dataIndex: 3
        }
      ]
    },
    [columnsMap, onSelectColumnName])

  return (
    <Table
      bordered
      columns={columns}
      rowKey="index"
      dataSource={data.map((record, index) => ({
        ...record,
        index
      }))}
      pagination={false}
      scroll={{
        y: 420
      }}
    />
  )
})

DataTable.displayName = 'UserPredictImportDataTable'

/**
 * 
 * @param {{
* file: File
* }} props
* @returns 
*/
const DataVisualizer = forwardRef(({ file }, ref) => {
  /**
   * @type {[Papaparse.ParseResult, React.Dispatch<Papaparse.ParseResult>]}
   */
  const [result, setResult] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const processFile = (_file) => {
    setResult(null)
    setLoading(true)
    Papaparse.parse(_file, {
      skipEmptyLines: true,
      complete(result) {
        setResult(result)
        setLoading(false)
      },
    })
  }

  useEffect(() => {
    processFile(file)
  }, [file])

  return (
    <div className={classNames('vstack', classes.dataVisualizer)}>
      {isLoading ?
        <Spin size="large" />
        : result ? (
          result.errors.length ? (
            <ul>
              {result.errors.map(err => (
                <li key={err.code} className="text-danger">
                  <Typography.Text className="text-danger">Row {err.row + 1}: {err.message}</Typography.Text>
                </li>
              ))}
            </ul>
          ) : (
            <DataTable ref={ref} data={result.data} />
          )
        ) : null}
    </div>
  )
})

DataVisualizer.displayName = 'UserPredictDataImportVisualizer'

/**
 * 
 * @param {import('antd').ModalProps & {
 * isImporting: boolean
 * file: File | null
 * onClose: () => void
 * onImport: (data: Array<Record<string, string>>) => void
 * }} props
 * @returns 
 */
const UserPredictDataImportModal = ({ isImporting, file, onClose, onImport, ...modalProps }) => {
  const dataVisualizer = useRef(null)

  const onSave = useCallback(() => {
    const data = dataVisualizer.current?.getImportData()
    onImport?.(data)
  }, [onImport])

  return (
    <Modal
      title="Import Data"
      okText="Save"
      width={640}
      {...modalProps}
      onCancel={onClose}
      onOk={onSave}
      okButtonProps={{
        loading: isImporting
      }}
      cancelButtonProps={{
        disabled: isImporting
      }}
    >
      {file && modalProps.open ? <DataVisualizer ref={dataVisualizer} file={file} /> : null}
    </Modal>
  )
}

export default UserPredictDataImportModal