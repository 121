import React, { useState, useCallback } from "react";

const useLoading = (icon, iconSize) => {
    const [requestCount, setRequestCount] = useState(0);

    const increaseRequestCount = useCallback(() => {
        setRequestCount((prev) => prev + 1);
    }, []);

    const decreaseRequestCount = useCallback(() => {
        setRequestCount((prev) => prev - 1);
    }, []);

    return [
        () => (
            <div>
                {requestCount > 0 && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: '370px',
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1001,
                        }}
                    >
                        <div style={{ marginTop: '25px', marginRight: '180px' }}>
                            <img src="/static/images/dig.gif" alt="loading icon" width={300} height={180} />
                        </div>
                    </div>
                )}
            </div>
        ),
        increaseRequestCount,
        decreaseRequestCount,
    ];
};

export default useLoading;