import React from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import SubMenu from "./SubMenu";
import { selectRoutes } from "features/nav/routesSlice";

function SiderMenu(props) {
  const routes = useSelector(selectRoutes);

  function generateMenus(data) {
    return data.map((item, index) => {
      if (item.routes && item.routes.length > 0) {
        return (
          <SubMenu key={"menu_" + index} route={item}>
            {generateMenus(item.routes)}
          </SubMenu>
        );
      }
      return (
        <li key={item.id}>
          <Link to={item.link}>
            {item.iconCls ? <i className={item.iconCls}></i> : <></>}
            <span>{item.title}</span>
          </Link>
        </li>
      );
    });
  }

  return (
    <>
      <ul className="metismenu list-unstyled" id="side-menu">
        {generateMenus(routes)}
      </ul>
    </>
  );
}

export { SiderMenu };
