import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectSelectedWellIds,
  setGridParams,
  selectDataType,
  setWellGridProperties,
} from "features/well_planner/plannerSlice";

import {
  selectActiveProject,
  setShowNewProjectPopup,
} from "features/project/projectsSlice";
import {
  selectActiveAssetId,
  selectActiveWells,
} from "features/asset/assetSelectorSlice";

import { saveGridParams } from "service/wellPlanner";

import { getMapObjectivesFeatures } from "../map";
import { geoDistance2 } from "utils/geo";
import AddProjectForm from "components/project/AddProjectForm";

function DefaultGridSettings(props) {
  const { onFinish } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const currentProject = useSelector(selectActiveProject);
  const activeWells = useSelector(selectActiveWells);
  const selectedWellIds = useSelector(selectSelectedWellIds);
  const dataType = useSelector(selectDataType);
  const activeAssetId = useSelector(selectActiveAssetId);

  const dispatch = useDispatch();

  const [leftBottom, setLeftBottom] = useState(null);
  const [distanceX, setDistanceX] = useState(null);
  const [distanceY, setDistanceY] = useState(null);
  const [dx] = useState(50);
  const [dy] = useState(50);
  const [rotate] = useState(0);

  const selectedWells = useMemo(() => {
    let selectedWells = [];
    if (
      selectedWellIds != null &&
      selectedWellIds.length > 0 &&
      activeWells != null &&
      activeWells.length > 0
    ) {
      let wellIdsSet = new Set(selectedWellIds);
      selectedWells = activeWells.filter((well) => wellIdsSet.has(well.uwi));
    }
    return selectedWells;
  }, [activeWells, selectedWellIds]);

  useEffect(() => {
    if (selectedWells == null || selectedWells.length === 0) {
      return;
    }
    const [, , bounds] = getMapObjectivesFeatures(selectedWells);
    setLeftBottom(bounds[0]);

    let distance_x = geoDistance2(
      bounds[0][0],
      bounds[0][1],
      bounds[0][0],
      bounds[1][1],
      "F"
    );
    setDistanceX(distance_x);
    let distance_y = geoDistance2(
      bounds[0][0],
      bounds[0][1],
      bounds[1][0],
      bounds[0][1],
      "F"
    );
    setDistanceY(distance_y);
    // setDistanceX(bounds[1][0] - bounds[0][0]);
    // setDistanceY(bounds[1][1] - bounds[0][1]);
  }, [selectedWells]);

  function setValue(value) {
    if (value == null) {
      return 0;
    }
    return value.toFixed(4);
  }

  const innerGridParams = (projectId) => {
    const gridParams = {
      grid_type: "default",
      x0: leftBottom[0],
      y0: leftBottom[1],
      distance_x: distanceX,
      distance_y: distanceY,
      dx: dx,
      dy: dy,
      rotation: rotate,
    };

    let assetId = null;
    if (dataType === "asset") {
      assetId = activeAssetId;
    }

    showLoadingIcon && showLoadingIcon();
    saveGridParams(
      projectId,
      assetId,
      selectedWellIds,
      [gridParams],
      (res) => {
        dispatch(setWellGridProperties(null));
        dispatch(setGridParams(gridParams));
        onFinish();
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  };

  function applyGridParams() {
    if (currentProject == null) {
      dispatch(setShowNewProjectPopup(true));
      return;
    }

    innerGridParams(currentProject.id);
  }

  return (
    <>
      <AddProjectForm
        input={{
          project_type: "well_planner",
          project_name: `PRJ_${new Date().getTime()}`,
          comment: "comment",
        }}
        callbackFunction={[innerGridParams, null]}
        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
      />
      <div className="input-grid-2-4 p-4">
        <div className="d-flex p-2">
          <label>X0</label>
          <div className="ms-2 data-cell px-2">
            {leftBottom ? leftBottom[0] : 0}
          </div>
        </div>
        <div className="d-flex p-2">
          <label>Distance - X (Ft)</label>
          <div className="ms-2 data-cell px-2">{setValue(distanceX)}</div>
        </div>
        <div className="d-flex p-2">
          <label>Dx</label>
          <div className="ms-2 data-cell px-2">{dx}</div>
        </div>
        <div className="d-flex p-2">
          <label>Rotation(0)</label>
          <div className="ms-2 data-cell px-2">{rotate}</div>
        </div>

        <div className="d-flex p-2">
          <label>Y0</label>
          <div className="ms-2 data-cell px-2">
            {leftBottom ? leftBottom[1] : 0}
          </div>
        </div>
        <div className="d-flex p-2">
          <label>Distance - Y (Ft)</label>
          <div className="ms-2 data-cell px-2">{setValue(distanceY)}</div>
        </div>
        <div className="d-flex p-2">
          <label>Dy</label>
          <div className="ms-2 data-cell px-2">{dy}</div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn-custom"
          onClick={(e) => {
            applyGridParams();
          }}
        >
          Apply
        </button>
      </div>
    </>
  );
}

export default DefaultGridSettings;
