import React, { Fragment, useEffect } from "react";
import PpSelector from "./PpSelector";
import { useDispatch, useSelector } from "react-redux";
import { selectAssetList, selectActiveAssetId, setCrossSectionSelectedWells, selectCrossSectionSelectedWells, selectSelectedWells, setSelectedWells } from "features/petrophysics/petrophysicsSlice";
import { Button } from "antd";

const PpSelectedSelector = (props) => {
    const dispatch = useDispatch();
    const assetList = useSelector(selectAssetList);
    const assetId = useSelector(selectActiveAssetId);
    const selectedCrossSectionWells = useSelector(selectCrossSectionSelectedWells);
    const selectedWellsAbove = useSelector(selectSelectedWells);

    useEffect(() => {
    }, [assetList, assetId]);

    useEffect(() => {
        let tempWells = [...selectedCrossSectionWells];
        selectedWellsAbove.map(item => {
            if (!selectedCrossSectionWells.includes(item))
                tempWells = [...tempWells, item];
            return null;
        })
        dispatch(setCrossSectionSelectedWells(tempWells.filter(item => selectedWellsAbove.includes(item))));
        // eslint-disable-next-line
    }, [selectedWellsAbove, dispatch]);

    const getWellNameFromAssetListById = (assetList, assetId, wellId) => {
        if (!assetId || !assetList || !wellId) return null;
        let asset = assetList[assetId];
        let wellList = asset.filter(item => item.uwi === wellId);
        if (wellList.length === 0) return null;
        let { well_name } = wellList[0];
        return well_name;
    }

    const removeAll = () => {
        dispatch(setCrossSectionSelectedWells([]));
        dispatch(setSelectedWells([]));
    }

    return (
        <Fragment>
            {
                <PpSelector
                    dataList={selectedCrossSectionWells.map(item => {
                        return { label: getWellNameFromAssetListById(assetList, assetId, item), name: item };
                    })}
                    title='Selected'
                    selected={selectedCrossSectionWells}
                    disabled={true}
                >
                    <Button type="primary" onClick={removeAll}>Clear Selected</Button>
                </PpSelector>
            }
        </Fragment>
    )
};
export default PpSelectedSelector;