import React, { useCallback, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

import {
  getProductionsData,
} from "service/productionData";

import {
  toDateSeries,
  computeAcummulativeData,
  computeAcummulativeCurve,
  computeNormalCurve,
} from "./DataFunctions";
import { getDateBefore } from "utils/dateUtil";
import { DataPeriodOptions } from "app/codes";
import { ViewersProductOptions } from "../../app/codes";

const CUMULATIVE_PRODUCTS = [
  "oil_prd_rate",
  "gas_prd_rate",
  "water_prd_rate",
  "power_consumption",
];

const IndicatorIcon = {
  up: "/static/icons/indicator-up.svg",
  down: "/static/icons/indicator-down.svg",
};

function commarize(num) {
  return num.toLocaleString();
}

function setDisplayValue(value, decimalNum) {
  if (value == null || value === 0) {
    return value;
  }

  return value.toFixed(decimalNum);
}

function SummaryCard(props) {
  const {
    icon,
    color,
    title,
    dataSeries,
    getDates,
    computeFunc,
    computeCurve,
    unit,
  } = props;

  const [value, setValue] = useState(0);
  const [percent, setPercent] = useState(0);
  const [period, setPeriod] = useState(0);
  const [yMinValue, setYMinValue] = useState(0);
  const [yMaxValue, setYMaxValue] = useState(0);
  const [lineSeries, setLineSeries] = useState([]);

  const bgColor = {
    background: color,
  };

  const computeCurveData= useCallback((series, startDate, endDate) => {
    let curve = computeCurve(series, startDate, endDate);

    if (curve && curve.length > 0) {
      let values = curve.map((item) => {
        return item.value;
      });
      setYMinValue(Math.min(...values));
      setYMaxValue(Math.max(...values));
    }

    let line = {
      type: "line",
      smooth: true,
      symbol: "none",
      emphasis: { focus: "series" },
      lineStyle: {
        color: "#fff",
        width: 2,
      },
      data: curve,
    };
    setLineSeries([line]);
  }, [computeCurve]);

  const computeValues = useCallback((days) => {
    // let dates = getDates(dataSeries);
    // let endDate = dates[dates.length - 1];
    let endDate = new Date();
    let startDate = getDateBefore(endDate, days);
    let value2 = computeFunc(dataSeries, startDate, endDate);

    let value1 = computeFunc(
      dataSeries,
      getDateBefore(startDate, days + 1),
      getDateBefore(startDate, 1)
    );

    setValue(value2);
    if (value1 !== 0) {
      setPercent((value2 - value1) / value1);
    }

    computeCurveData(dataSeries, startDate, endDate);
  }, [computeCurveData, computeFunc, dataSeries]);

  useEffect(() => {
    if (dataSeries === null || Object.keys(dataSeries).length === 0) {
      return;
    }
    let dates = getDates(dataSeries);
    if (period === 0) {
      let value1 = computeFunc(dataSeries, dates[0], dates[dates.length - 1]);
      setValue(value1);
      computeCurveData(dataSeries, dates[0], dates[dates.length - 1]);
    } else if (period === 1) {
      // last week
      computeValues(7);
    } else if (period === 2) {
      // last month
      computeValues(30);
    } else if (period === 3) {
      // last year
      computeValues(365);
    }
  }, [dataSeries, period, getDates, computeCurveData, computeFunc, computeValues]);
  
  function getChartOptions() {
    let options = {
      grid: {
        left: "0",
        right: "0",
        bottom: "0",
        top: "0",
      },
      xAxis: {
        type: "time",
        // min:xMinValue,
        // max:xMaxValue,
        // min: 'dataMin',
        // max: 'dataMax',
      },
      yAxis: {
        type: "value",
        min: yMinValue,
        max: yMaxValue,
        splitLine: {
          show: false,
        },
      },
      series: (dataSeries && Object.keys(dataSeries).length > 0) ? lineSeries : [],
    };
    return options;
  }

  function changePeriod(period) {
    setPeriod(period);
  }

  return (
    <div
      className="d-flex justify-content-between align-items-stretch summary-card-container"
      style={bgColor}
    >
      <div className="d-flex align-items-center">
        <img src={icon} className="summary-card-icon" alt="" />
        <div className="d-flex flex-column summary-card-info">
          <div className="summary-card-title">
            <span>{title + (unit && ' (' + unit + ')')}</span>
          </div>
          <div className="summary-card-value">
            <span>{(dataSeries && Object.keys(dataSeries).length > 0) ? commarize(value) : 0}</span>
          </div>
          <div className="d-flex flex-row align-items-center">
            <img
              src={percent < 0 ? IndicatorIcon.down : IndicatorIcon.up}
              className="indicator-icon"
              alt=""
            />
            <div className="summary-card-percent">
              <span>{setDisplayValue(percent, 2)}%</span>
            </div>
            <div className="summary-card-desc">
              <span>
                {period !== 0 ? `Since ${DataPeriodOptions[period].desc}` : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-stretch flex-grow-1 ps-1">
        <div
          className="d-flex me-2 py-2"
          style={{ height: "80px", width: "160px" }}
        >
          <ReactECharts
            style={{ height: "100%", width: "100%" }}
            notMerge={true}
            option={getChartOptions()}
          />
        </div>
      </div>
      {/* <img src={trend_icon} className="summary-card-trend me-2" alt="" /> */}
      <div className="dropdown">
        <div className="summary-setting-btn" data-bs-toggle="dropdown">
          <i className="summary-setting-icon" />
        </div>
        <ul className="dropdown-menu">
          <li>
            <div
              className="dropdown-item"
              onClick={(e) => {
                changePeriod(0);
              }}
            >
              {DataPeriodOptions[0].name}
            </div>
          </li>
          <li>
            <div
              className="dropdown-item"
              onClick={(e) => {
                changePeriod(1);
              }}
            >
              {DataPeriodOptions[1].name}
            </div>
          </li>
          <li>
            <div
              className="dropdown-item"
              onClick={(e) => {
                changePeriod(2);
              }}
            >
              {DataPeriodOptions[2].name}
            </div>
          </li>
          <li>
            <div
              className="dropdown-item"
              onClick={(e) => {
                changePeriod(3);
              }}
            >
              {DataPeriodOptions[3].name}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

function AssetSummary(props) {
  const { wellId, assetId } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const [historicalData, setHistoricalData] = useState(null);

  const updateCummulativeData = useCallback((data) => {
    let productionHistoricalData = {};
    for (const product of Object.keys(data)) {
      productionHistoricalData[product] = toDateSeries(data[product]);
    }
    setHistoricalData(productionHistoricalData);
  }, []);

  const refreshData = useCallback((uwi, assetId) => {
    let apiKeys = null;
    if (uwi != null) {
      apiKeys = [uwi];
    } else if (assetId == null) {
      return;
    }

    showLoadingIcon && showLoadingIcon();
    getProductionsData(
      apiKeys,
      assetId,
      CUMULATIVE_PRODUCTS,
      null,
      null,
      true,
      (tempData) => {
        updateCummulativeData(tempData);
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        updateCummulativeData({});
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }, [updateCummulativeData, hideLoadingIcon, showLoadingIcon]);

  useEffect(() => {
    if (wellId == null && assetId == null) {
      return;
    }
    refreshData(wellId, assetId);
  }, [wellId, assetId, refreshData]);

  const assetsData = [
    {
      title: "Cum Oil",
      icon: "/static/images/assetview/v-icon-01.svg",
      color: "rgba(109, 77, 237, 0.1)",
      dataSeries: historicalData,
      getDates: (dataSeries) => {
        return dataSeries["oil_prd_rate"].map((item) => {
          return item.date;
        });
      },
      computeFunc: (dataSeries, startDate, endDate) => {
        return computeAcummulativeData(
          dataSeries["oil_prd_rate"],
          startDate,
          endDate
        );
      },
      computeCurve: (dataSeries, startDate, endDate) => {
        return computeAcummulativeCurve(
          dataSeries["oil_prd_rate"],
          startDate,
          endDate
        );
      },
    },
    {
      title: "Cum Gas",
      icon: "/static/images/assetview/v-icon-02.svg",
      color: "rgba(255, 184, 0, 0.1)",
      dataSeries: historicalData,
      getDates: (dataSeries) => {
        return dataSeries["oil_prd_rate"].map((item) => {
          return item.date;
        });
      },
      computeFunc: (dataSeries, startDate, endDate) => {
        return computeAcummulativeData(
          dataSeries["gas_prd_rate"],
          startDate,
          endDate
        );
      },
      computeCurve: (dataSeries, startDate, endDate) => {
        return computeAcummulativeCurve(
          dataSeries["gas_prd_rate"],
          startDate,
          endDate
        );
      },
    },
    {
      title: "Cum Water",
      icon: "/static/images/assetview/v-icon-03.svg",
      color: "rgba(69, 130, 249, 0.1)",
      dataSeries: historicalData,
      getDates: (dataSeries) => {
        return dataSeries["oil_prd_rate"].map((item) => {
          return item.date;
        });
      },
      computeFunc: (dataSeries, startDate, endDate) => {
        return computeAcummulativeData(
          dataSeries["water_prd_rate"],
          startDate,
          endDate
        );
      },
      computeCurve: (dataSeries, startDate, endDate) => {
        return computeAcummulativeCurve(
          dataSeries["water_prd_rate"],
          startDate,
          endDate
        );
      },
    },
    {
      title: "Avg Gas/Oil Ratio",
      icon: "/static/images/assetview/v-icon-04.svg",
      color: "rgba(0, 188, 139, 0.1)",
      dataSeries: historicalData,
      getDates: (dataSeries) => {
        return dataSeries["oil_prd_rate"].map((item) => {
          return item.date;
        });
      },
      computeFunc: (dataSeries, startDate, endDate) => {
        let gasRate = computeAcummulativeData(
          dataSeries["gas_prd_rate"],
          startDate,
          endDate
        );
        let oilRate = computeAcummulativeData(
          dataSeries["oil_prd_rate"],
          startDate,
          endDate
        );
        let ratio = 0;
        if (oilRate !== 0) {
          ratio = gasRate / oilRate;
        }
        return ratio;
      },
      computeCurve: (dataSeries, startDate, endDate) => {
        let gasDataCurve = computeNormalCurve(
          dataSeries["gas_prd_rate"],
          startDate,
          endDate
        );
        let oilDataCurve = computeNormalCurve(
          dataSeries["oil_prd_rate"],
          startDate,
          endDate
        );

        let curveData = [];
        for (let i = 0; i < oilDataCurve.length; i++) {
          let value = 0;
          let oilRate = oilDataCurve[i].value;
          let gasRate = gasDataCurve[i].value;
          if (oilRate !== 0) {
            value = gasRate / oilRate;
          }
          curveData.push([
            oilDataCurve[i].date, value
          ]);
        }
        return curveData;
      },
    },
    {
      title: "Avg Water Cut",
      icon: "/static/images/assetview/v-icon-05.svg",
      color: "rgba(36, 210, 253, 0.1)",
      dataSeries: historicalData,
      getDates: (dataSeries) => {
        return dataSeries["oil_prd_rate"].map((item) => {
          return item.date;
        });
      },
      computeFunc: (dataSeries, startDate, endDate) => {
        let waterRate = computeAcummulativeData(
          dataSeries["water_prd_rate"],
          startDate,
          endDate
        );
        let oilRate = computeAcummulativeData(
          dataSeries["oil_prd_rate"],
          startDate,
          endDate
        );
        let ratio = 0;
        let baseRate = waterRate + oilRate;
        if (baseRate !== 0) {
          ratio = waterRate / baseRate;
        }
        return ratio;
      },

      computeCurve: (dataSeries, startDate, endDate) => {
        let waterDataCurve = computeNormalCurve(
          dataSeries["water_prd_rate"],
          startDate,
          endDate
        );
        let oilDataCurve = computeNormalCurve(
          dataSeries["oil_prd_rate"],
          startDate,
          endDate
        );

        let curveData = [];
        for (let i = 0; i < oilDataCurve.length; i++) {
          let value = 0;
          let oilRate = oilDataCurve[i].value;
          let waterRate = waterDataCurve[i].value;
          let base = oilRate + waterRate;
          if (base !== 0) {
            value = waterRate / base;
          }
          curveData.push([oilDataCurve[i].date, value]);
        }
        return curveData;
      },
    },
    {
      title: "Power Consumption",
      icon: "/static/images/assetview/v-icon-06.svg",
      color: "rgba(151, 5, 255, 0.1)",
      dataSeries: historicalData,
      getDates: (dataSeries) => {
        return dataSeries["oil_prd_rate"].map((item) => {
          return item.date;
        });
      },
      computeFunc: (dataSeries, startDate, endDate) => {
        let data = dataSeries["power_consumption"];
        if (data == null) {
          return 0;
        }
        return computeAcummulativeData(data, startDate, endDate);
      },
      computeCurve: (dataSeries, startDate, endDate) => {
        return computeAcummulativeCurve(
          dataSeries["power_consumption"],
          startDate,
          endDate
        );
      },
    },
  ];

  let generateCards = (data) => {
    return data.map((item, index) => {
      let unit = ViewersProductOptions.find((op) => op.name === item.title)?.unit || "";
      return <SummaryCard key={index} {...item} unit={unit} />;
    });
  };

  let cards = generateCards(assetsData);

  return (
    <div className="cards-container">
      <div className="summary-content overflow-visible">
        <div className="row g-0">
          <div className="col pe-1">{cards[0]}</div>
          <div className="col ps-1 pe-1">{cards[1]}</div>
          <div className="col ps-1">{cards[2]}</div>
        </div>
        <div className="row mt-2 g-0">
          <div className="col pe-1">{cards[3]}</div>
          <div className="col ps-1 pe-1">{cards[4]}</div>
          <div className="col ps-1">{cards[5]}</div>
        </div>
      </div>
    </div>
  );
}

export { AssetSummary };
