import { Form, InputNumber, Modal, Select, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo } from 'react'
import { filterOption } from 'utils/select'
import { snakeToCamel } from 'utils/string'

const AddAFEItemModal = ({ afeDict, currentCostItems = [], onSubmit, isSubmitting, ...modalProps }) => {
  const [form] = Form.useForm()

  const drillingCostPerUnit = Form.useWatch('drilling_cost_per_unit', form)
  const drillingUnits = Form.useWatch('drilling_days_or_units', form)
  const completionCostPerUnit = Form.useWatch('completion_cost_per_unit', form)
  const completionUnits = Form.useWatch('completion_days_or_units', form)

  const drillingCost = Form.useWatch('drilling_cost', form)
  const completionCost = Form.useWatch('completion_cost', form)

  useEffect(() => {
    form.resetFields()
  }, [form, modalProps.open])

  useEffect(() => {
    const drillingCost = (drillingCostPerUnit ?? 0) * (drillingUnits ?? 0)

    form.setFieldValue('drilling_cost', drillingCost)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drillingCostPerUnit, drillingUnits])

  useEffect(() => {
    const completionCost = (completionCostPerUnit ?? 0) * (completionUnits ?? 0)

    form.setFieldValue('completion_cost', completionCost)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completionCostPerUnit, completionUnits])

  const costItemOptions = useMemo(() => {
    return Object.values(afeDict ?? {}).flat().filter(item => !currentCostItems.includes(item.id)).map(item => ({
      value: item.id,
      label: snakeToCamel(item.name)
    }))
  }, [afeDict, currentCostItems])

  const onFinish = useCallback((item) => {
    item.total_cost = (item.drilling_cost ?? 0) + (item.completion_cost ?? 0)
    onSubmit(item)
  }, [onSubmit])

  return (
    <Modal
      title="New AFE Item"
      okText="Save"
      width={640}
      onOk={form.submit}
      cancelButtonProps={{
        disabled: isSubmitting
      }}
      okButtonProps={{
        loading: isSubmitting
      }}
      {...modalProps}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item name="cost_item_id" label="Cost Item" rules={[{
          required: true
        }]}>
          <Select options={costItemOptions} filterOption={filterOption} showSearch />
        </Form.Item>
        <div className="row">
          <div className="col-4">
            <Form.Item
              name="drilling_cost_per_unit"
              label="Drilling Cost Per Unit"
              rules={[{
                required: true
              }]}
            >
              <InputNumber className="w-100" step={0.01} />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item
              name="drilling_days_or_units"
              label="Drilling Days/Units"
              rules={[{
                required: true
              }]}
            >
              <InputNumber className="w-100" />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item
              name="drilling_cost"
              label="Drilling Cost"
            >
              <InputNumber className="w-100" step={0.01} readOnly />
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <Form.Item
              name="completion_cost_per_unit"
              label="Completion Cost Per Unit"
              rules={[{
                required: true
              }]}
            >
              <InputNumber className="w-100" step={0.01} />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item
              name="completion_days_or_units"
              label="Completion Days/Units"
              rules={[{
                required: true
              }]}
            >
              <InputNumber className="w-100" />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item
              name="completion_cost"
              label="Completion Cost"
            >
              <InputNumber className="w-100" step={0.01} readOnly />
            </Form.Item>
          </div>
        </div>
        <div>
          <Typography.Text>Total Cost: <strong>{(drillingCost ?? 0) + (completionCost ?? 0)}</strong></Typography.Text>
        </div>
      </Form>
    </Modal>
  )
}

export default AddAFEItemModal