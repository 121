import React, { useCallback, useRef, useState } from "react";
import { Input, Spin, Tree } from "antd";
import groupBy from 'lodash/groupBy'

import { getAssets } from "service/assets";
import { getWellSummary, searchWells } from "service/wellSummary";

import classes from './wells-selector.module.scss'
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";
import classNames from "classnames";

const updateTreeData = (list, key, children) =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

const WellsSelector = (props) => {
  const { onWellSelected } = props;
  const containerRef = useRef(null)
  const [treeData, setTreeData] = useState([])
  const [searchText, setSearchText] = useState('')

  const debouncedSearchText = useDebounce(searchText, 400)

  const { data: assets = [], isFetching: isLoadingAssets, refetch: refetchAssets } = useQuery({
    queryKey: ['getAssets'],
    queryFn: () => getAssets((result) => {
      setTreeData(result?.map(asset => ({
        key: asset.id,
        title: asset.asset_name,
        selectable: false,
        type: 'asset'
      })) ?? [])
    }),
    select: (res) => {
      return res.data ?? []
    }
  })

  const { data: queryTreeData, isFetching: isSearching } = useQuery({
    queryKey: ['searchWells', debouncedSearchText],
    queryFn: () => {
      return searchWells(debouncedSearchText)
        .then(res => {
          const groupedWells = groupBy(res.data ?? [], 'asset_id')
          const assetsMap = groupBy(assets, 'id')

          return Object.entries(groupedWells).reduce((state, [assetId, assetWells]) => {
            const asset = assetsMap[assetId]?.[0]
            if (!asset) return state

            const leases = groupBy(assetWells, 'lease_name')

            state.keys.push(asset.id)

            state.treeData.push({
              key: asset.id,
              title: asset.asset_name,
              selectable: false,
              type: 'asset',
              children: Object.keys(leases).sort().map((lease) => {
                const wells = leases[lease]
                const leaseKey = `${ assetId }.${ lease }`

                state.keys.push(leaseKey)

                return {
                  key: leaseKey,
                  title: lease,
                  selectable: false,
                  type: 'lease',
                  children: wells
                    .slice()
                    .sort((a, b) => (a.well_name > b.well_name ? 1 : -1))
                    .map(well => (({
                      key: `${ assetId }.${ lease }.${ well.uwi }`,
                      title: well.well_name,
                      isLeaf: true,
                      id: well.uwi,
                      asset_id: well.asset_id,
                      type: 'well'
                    })))
                }
              })
            })

            return state
          }, {
            treeData: [],
            keys: []
          })
        })
    },
    enabled: Boolean(debouncedSearchText && assets?.length)
  })

  const onLoadData = useCallback(async (node) => {
    const { key, type, children } = node

    if (type !== 'asset' || children?.length) return;

    return getWellSummary(
      key,
      (result) => {
        if (!result || result.length === 0) {
          return;
        }

        const leases = groupBy(result, 'lease_name')

        setTreeData(origin => {
          return updateTreeData(origin, key, Object.keys(leases).sort().map((lease) => {
            const wells = leases[lease]

            return {
              key: `${ key }.${ lease }`,
              title: lease,
              selectable: false,
              type: 'lease',
              children: wells
                .slice()
                .sort((a, b) => (a.well_name > b.well_name ? 1 : -1))
                .map(well => (({
                  key: `${ key }.${ lease }.${ well.uwi }`,
                  title: well.well_name,
                  isLeaf: true,
                  id: well.uwi,
                  asset_id: well.asset_id,
                  type: 'well'
                })))
            }
          }))
        })
      },
      (err) => { }
    );
  }, [])

  const onSelect = useCallback((keys, info) => {
    const { node: {
      id: wellId,
      asset_id
    } } = info
    onWellSelected(wellId, asset_id)
  }, [onWellSelected])

  const onSearch = useCallback((text) => {
    setSearchText(text)
    if (!text) {
      refetchAssets({
        cancelRefetch: true
      })
    }
  }, [refetchAssets])

  return (
    <div ref={containerRef} className="w-100 px-2 vstack gap-2">
      <div className="flex-1">
        <Input.Search className={classes.search} onSearch={onSearch} />
      </div>
      {isLoadingAssets || isSearching ? (
        <Spin />
      ) : (
        <Tree
          showLine
          treeData={searchText ? queryTreeData?.treeData : treeData}
          loadData={onLoadData}
          className={classNames(classes.wellsTree, 'flex-grow-1')}
          height={containerRef.current?.parentNode?.parentNode?.clientHeight}
          onSelect={onSelect}
          {...(searchText ? {
            defaultExpandedKeys: searchText ? queryTreeData?.keys : []
          } : {})}
        />
      )}
    </div>
  );
};

export default WellsSelector;
