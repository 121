import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PpSelector from "./PpSelector";
import {
    setAssetList,
    selectAssetList,
    setActiveAssetId,
    selectActiveAssetId,
    addSelectedWell,
    removeSelectedWell,
    selectSelectedWells,
    selectActiveCrossSection,
    selectCrossSectionList,
    addSelectedWellForNewLog,
    removeSelectedWellForNewLog,
    selectSelectedWellsForNewLog,
    setLoading,
    selectLoading
} from "features/petrophysics/petrophysicsSlice";
import { getAssets } from "service/assets";
import { getAssetSimpleListWithLog } from "service/petrophysics";

const PpAssetWellSelector = (props) => {
    const { onlyXSection, selectNewLog, heightPercent } = props;
    const { loadingIconActions } = props;
    const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

    const dispatch = useDispatch();
    const stableDispatch = useCallback(args => {
        return dispatch(args);
    }, [dispatch]);
    const assetList = useSelector(selectAssetList);
    const activeAssetId = useSelector(selectActiveAssetId);
    const selectedWells = useSelector(selectNewLog ? selectSelectedWellsForNewLog : selectSelectedWells);

    const selectedCrossSection = useSelector(selectActiveCrossSection);
    const crossSectionList = useSelector(selectCrossSectionList);
    const loading = useSelector(selectLoading);
    const [assets, setAssets] = useState(null);
    const getSelectedWellsListFromCrossSectionList = () => {
        if (!crossSectionList || !selectedCrossSection || selectedCrossSection.length === 0) return;

        let result = [];
        let selectedCS = crossSectionList.filter(item => selectedCrossSection.includes(item.label));
        selectedCS.map(item => {
            const { wells } = item;
            wells.map(well => {
                result.push({ well_name: well.well_name, uwi: well.uwi });
                return null;
            });
            return null;
        });

        return result;
    }

    useEffect(() => {
        getAssets(
            (result) => {
                setAssets(result);
                if (result && result.length > 0) {
                    stableDispatch(setActiveAssetId(result[0].id));
                }
            },
            (err) => { console.error(err); }
        );
    }, [stableDispatch]);

    useEffect(() => {

        if (activeAssetId != null && !assetList && !loading) {

            stableDispatch(setLoading(true));
            showLoadingIcon && showLoadingIcon();
            // load all assets and wells from backend.
            getAssetSimpleListWithLog(activeAssetId,
                (res) => {
                    stableDispatch(setAssetList({ assetId: activeAssetId, wells: res }));
                    // Below part select only 5 wells using for testing
                    // let r = {};
                    // Object.keys(res).map(key => {
                    //     r[key] = res[key].filter(well => testWells.includes(well.uwi));
                    //     return null;
                    // });
                    // stableDispatch(setAssetList(r));
                    stableDispatch(setLoading(false));
                    // if (!activeAssetId) {
                    //     stableDispatch(setActiveAssetId(Object.keys(res)[0]));
                    // }
                    hideLoadingIcon && hideLoadingIcon();
                },
                (e) => {
                    console.warn("load assets failed.")
                    stableDispatch(setLoading(false));
                    hideLoadingIcon && hideLoadingIcon();
                }
            );
        }
    }, [assetList, activeAssetId, stableDispatch, hideLoadingIcon, showLoadingIcon, loading]);

    const onAssetChanged = (e) => {
        if (!e) return;
        dispatch(setActiveAssetId(e.target.value));
    }

    const handleWellSelected = useCallback((item, status) => {
        const uwi = item.name;
        if (status) {
            stableDispatch(selectNewLog ? addSelectedWellForNewLog(uwi) : addSelectedWell(uwi));
        } else {
            stableDispatch(selectNewLog ? removeSelectedWellForNewLog(uwi) : removeSelectedWell(uwi));
        }
    }, [selectNewLog, stableDispatch]);

    // useEffect(() => {
    //     if (!selectedWells || selectedWells.length === 0) return;
    //     selectedWells.map(well => handleWellSelected(well, true));
    // }, [selectedWells, handleWellSelected])
    return (
        <div className="selector d-flex flex-column w-100" style={{ height: heightPercent ? parseInt(heightPercent) + '%' : '100%' }}>
            <div className="selector-select d-flex flex-column align-items-stretch">
                <select
                    onChange={onAssetChanged}
                    name="asset_name"
                    className="d-flex"
                    value={activeAssetId || ""}
                >
                    {
                        assets && assets.map((asset, index) => {
                            return (
                                <option
                                    key={index}
                                    className="d-flex justify-content-center align-items-content"
                                    value={asset.id}
                                    label={asset.asset_name}
                                >
                                    {asset.asset_name}
                                </option>
                            );
                        })
                    }
                </select>
            </div>
            <PpSelector
                dataList={assetList
                    && activeAssetId
                    && assetList[activeAssetId]
                    && assetList[activeAssetId]
                        .filter(item => {
                            if (!onlyXSection || selectedCrossSection === null || selectedCrossSection.length === 0) return true;
                            const list = getSelectedWellsListFromCrossSectionList();
                            if (!list || list.filter(li => item.uwi === li.uwi).length === 0) return false;
                            return true;
                        })
                        .map(item => ({
                            label: item.well_name,  // for show
                            name: item.uwi  // for id
                        })
                        )}
                onOptionSelected={handleWellSelected}
                needSort={true}
                selected={selectedWells}
            />
        </div>
    )
};
export default PpAssetWellSelector;