import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import AvailableParamsChart from "./chart/AvailableParamsChart";
import { ParamsCorrelationChart } from "./chart/ParamsCorrelationChart";
import { ParamHistogramChart } from "./chart/ParamHistogramChart";

import {
  selectSelectedWellIds,
  setSelectedWellIds,
  setAvailableParamsData,
  setAvailableWellParams,
  setSelectedWellParams,
  setInterpoParams,
  setAllParams,
  selectDataType,
  setDataType,
  setActiveParam,
  selectActiveParam,
} from "features/well_planner/plannerSlice";

import { selectActiveWells } from "features/asset/assetSelectorSlice";

import { getWellAvailableParams, prepareWellData } from "service/wellData";

const ChartTypes = ["Availability", "Histogram", "Correlation"];

function DataPanel(props) {
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const DataOptions = [
    { value: "asset", name: "Asset" },
    { value: "wells", name: "Selected Wells" },
  ];

  const selectedWellIds = useSelector(selectSelectedWellIds);
  const activeWells = useSelector(selectActiveWells);
  const dataType = useSelector(selectDataType);
  const activeParam = useSelector(selectActiveParam);

  const dispatch = useDispatch();

  const [chartType, setChartType] = useState(0);

  const [processing, setProcessing] = useState(false);
  const [allParamsData, setAllParamsData] = useState(null);

  const refreshData = useCallback((wellIds) => {
    dispatch(setAvailableParamsData(null));

    showLoadingIcon && showLoadingIcon();
    getWellAvailableParams(
      { uwis: wellIds, input_category: "well_planner" },
      (result) => {
        dispatch(setAllParams(result.all_params));
        dispatch(setAvailableWellParams(result.available_params));
        dispatch(setAvailableParamsData(result.available_params_data));
        dispatch(setInterpoParams(result.interpo_params));
        let tempAvailableParams = null;
        for (let wellId of Object.keys(result.available_params)) {
          let wellAvailableParams = result.available_params[wellId];
          if (tempAvailableParams == null) {
            tempAvailableParams = new Set(wellAvailableParams);
          } else {
            for (let param of tempAvailableParams) {
              if (!wellAvailableParams.includes(param)) {
                tempAvailableParams.delete(param);
              }
            }
          }
        }

        let allParams = result.all_params.map((param) => {
          return {
            name: param,
            selected: false,
            available: tempAvailableParams.has(param),
          };
        });
        setAllParamsData(allParams);
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => { hideLoadingIcon && hideLoadingIcon(); }
    );
  }, [dispatch, hideLoadingIcon, showLoadingIcon]);

  useEffect(() => {
    if (selectedWellIds == null || selectedWellIds.length === 0) {
      setAllParamsData(null);
      dispatch(setAvailableWellParams(null));
      dispatch(setAvailableParamsData(null));
      dispatch(setSelectedWellParams(null));
      dispatch(setInterpoParams(null));
      dispatch(setAllParams(null));
      return;
    }
    refreshData(selectedWellIds);
  }, [dispatch, selectedWellIds, refreshData]);

  function startPrepareWellData() {
    setProcessing(true);
    setTimeout(() => {
      setProcessing(false);
    }, 25000);
    showLoadingIcon && showLoadingIcon();
    prepareWellData(
      (res) => {
        setProcessing(false);
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        setProcessing(false);
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }

  function dataTypeChanged(e) {
    let value = e.target.value;
    dispatch(setDataType(value));
    if (value === DataOptions[0].value) {
      dispatch(
        setSelectedWellIds(
          activeWells.map((well) => {
            return well.uwi;
          })
        )
      );
    } else {
      dispatch(setSelectedWellIds(null));
    }
  }

  function handleParamSelectedChanged(index) {
    let tempParamsData = [...allParamsData];
    let item = { ...allParamsData[index] };
    item.selected = !item.selected;
    tempParamsData[index] = item;
    setAllParamsData(tempParamsData);

    let selected = tempParamsData.filter((item) => item.selected);
    let availableParams = selected.map((item) => {
      return item.name;
    });
    dispatch(setSelectedWellParams(availableParams));
  }

  function generateParamSelector() {
    if (!allParamsData) {
      return null;
    }
    return allParamsData.map((param, index) => {
      let className = "list-item";
      if (!param.available) {
        className += " disabled";
      }
      if (param.name === activeParam) {
        className += " selected";
      }
      return (
        <div
          key={index}
          className={`${className} p-2 d-flex flex-row justify-content-between align-items-center`}
          onClick={(e) => {
            if (param.available === false) {
              dispatch(setActiveParam(null));
            } else {
              dispatch(setActiveParam(param.name));
            }
          }}
        >
          <div>{param.name}</div>
          <input
            id={"i_" + index}
            type="checkbox"
            onChange={(e) => {
              handleParamSelectedChanged(index);
            }}
            checked={param.selected}
            disabled={!param.available}
          ></input>
        </div>
      );
    });
  }

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <div className="header-title">Data</div>
        <div className="">
          <select
            name="chartType"
            value={chartType}
            onChange={(e) => {
              let value = e.target.value;
              setChartType(parseInt(value));
            }}
          >
            {ChartTypes.map((item, index) => {
              return <option key={index} value={index}>{item}</option>;
            })}
          </select>
        </div>
        <div>
          {processing && (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
        <div className="header-menu-container d-flex flex-row">
          {DataOptions.map((option, index) => {
            return (
              <div key={"data_type_" + index} className="form-check me-2">
                <input
                  className="form-check-input"
                  type="radio"
                  id={"dataType" + index}
                  name="dataType"
                  value={option.value}
                  onChange={dataTypeChanged}
                  checked={dataType === option.value}
                ></input>
                <label className="form-check-label ms-2" htmlFor={"dataType" + index}>
                  {option.name}
                </label>
              </div>
            );
          })}

          <div className="dropdown">
            <div className="cards-setting-btn" data-bs-toggle="dropdown">
              <div className="cards-setting-icon" />
            </div>
            <ul className="dropdown-menu">
              <li>
                <div
                  className="dropdown-item"
                  onClick={(e) => {
                    startPrepareWellData();
                  }}
                >
                  Prepare Well Data
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="cards-content d-flex">
        <div className="params-tree-wrapper d-flex h-100">
          <div className="overflow-auto hide-scrollbar">
            {generateParamSelector()}
          </div>
        </div>
        <div
          className="ms-2 h-100" style={{ width: '100%' }}
        >
          {chartType === 0 && <AvailableParamsChart />}
          {chartType === 1 && <ParamHistogramChart />}
          {chartType === 2 && <ParamsCorrelationChart
            loadingIconActions={loadingIconActions}
          />}
        </div>
      </div>
    </div>
  );
}

export default DataPanel;
