import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { ProjectTypes, createProject } from "service/projects";
import {
  setActiveProjectId,
  addProjectItem,
} from "features/project/projectsSlice";

function NewProjectLayout(props) {
  const { input } = props;
  const dispatch = useDispatch();

  const [inputData, setInput] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    let initialInput = {};
    if (input == null) {
      initialInput = {
        project_type: ProjectTypes[0].value,
        project_name: "",
        comment: "",
        redirect: "",
      };
    } else {
      initialInput = { ...input };
    }
    if (initialInput.project_type == null || initialInput.project_type === "") {
      initialInput.project_type = ProjectTypes[0].value;
    }
    setInput(initialInput);
  }, [input]);

  function handSubmit() {
    if (
      inputData.project_name === "" ||
      inputData.comment === "" ||
      inputData.project_type === ""
    ) {
      return;
    }
    createProject(
      {
        project_type: inputData.project_type,
        name: inputData.project_name,
        comment: inputData.comment,
        is_share: false,
      },
      (res) => {
        dispatch(addProjectItem(res));
        dispatch(setActiveProjectId(res.id));
        let project_id = res.id;
        let projectType = ProjectTypes.find((item) => item.value === inputData.project_type);
        let redirectUrl = projectType.url + `?project_id=${project_id}&project_type=${inputData.project_type}&project_name=${inputData.project_name}`
        navigate(redirectUrl);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  const handleChange = (e) => {
    setInput({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="page-content">
      <div className="content">
        <div className="new-project-container">
          <div className="cards-header">Create A New Project</div>
          <div className="new-project-content">
            <form
              onSubmit={(e) => {
                handSubmit();
                e.preventDefault();
              }}
              className="form-container"
            >
              <div className="input-group">
                <label>Enter Project Name</label>
                <input
                  value={inputData != null ? inputData.project_name : ""}
                  onChange={handleChange}
                  name="project_name"
                  type="text"
                  placeholder="Type here ..."
                  required
                />
                <small className="text-muted">*required</small>
              </div>

              <div className="input-group">
                <label>Select Project Type</label>
                <select
                  name="project_type"
                  value={inputData != null ? inputData.project_type : ""}
                  onChange={handleChange}
                >
                  {ProjectTypes.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <small className="text-muted">*required</small>
              </div>

              <div className="input-group">
                <label>Comment</label>
                <textarea
                  onChange={handleChange}
                  placeholder="type here...."
                  name="comment"
                  value={inputData != null ? inputData.comment : ""}
                  required
                ></textarea>
                <small className="text-muted">*required</small>
              </div>
              <div className="input-group">
                <div className="submit-button-group">
                  <button>save</button>
                  <button
                    onClick={() => {
                      navigate(
                        inputData != null
                          ? inputData.redirect
                          : "/project_summary/"
                      );
                    }}
                  >
                    exit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewProjectLayout;
