import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PpLayout from "../components/PpLayout";
import { Layout, Space, Spin } from 'antd';
import PpAssetWellSelector from "../components/selector/PpAssetWellSelector";
import PpLogSelector from "../components/selector/PpLogSelector";
import PpWellsWithCrossSection from "../components/well_curve/PpWellsWithCrossSection";
import { reset, selectBackgroundColorSetting } from "features/petrophysics/petrophysicsSlice";
import { rightContentStyle, rightStyle, siderStyle, assetContentStyle } from "../styles";
import PpLogCalculator from "../components/PpLogCalculator";
import useLoading from "components/common/useLoading";
const { Sider, Content } = Layout;

const PropertyLog = (props) => {
    const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(<Spin size="large" />, 50);
    const backColor = useSelector(selectBackgroundColorSetting);
    const dispatch = useDispatch();
    useEffect(()=>{
        return ()=>{
            dispatch(reset());
        }
    }, [dispatch]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <LoadingIcon />
            <PpLayout>
                <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    size={[0, 48]}
                >
                    <Layout height={'100vh'}>
                        <Sider style={siderStyle}>
                            <Content style={assetContentStyle} >
                                <PpAssetWellSelector
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                            <Content style={assetContentStyle}>
                                <PpLogSelector
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                        </Sider>
                        <Content style={{
                            ...rightStyle,
                            flexDirection: 'row',
                            display: 'flex'
                        }}>
                            <Content style={{
                                ...rightContentStyle,
                                backgroundColor: backColor,
                                height: '100%',
                                width: '60%'
                            }}>
                                <PpWellsWithCrossSection
                                    gapWidth='0px'
                                    columnWidth='80px'
                                    height='880px'
                                    space={80}
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                            <Content style={{
                                ...rightContentStyle,
                                height: '100%',
                                width: '40%'
                            }}
                                span={10}
                            >
                                <PpLogCalculator
                                    logName='test name'
                                    logType='test type'
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                        </Content>
                    </Layout>
                </Space>
            </PpLayout>
        </div>
    )
};
export default PropertyLog;
