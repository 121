import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

import { getWellStatus, getWellCategory } from "service/wellSummary";
import SectionSetting from "components/common/SectionSetting";
function WellStatusChart(props) {
  const { assetId } = props;
  const [statusSeries, setStatusSeries] = useState(null);
  const [categorySeries, setCategorySeries] = useState(null);

  useEffect(() => {
    if (assetId == null) return;

    getWellStatus(
      assetId,
      (result) => {
        updateStatusSeries(result);
      },
      (error) => {}
    );
    getWellCategory(
      assetId,
      (result) => {
        updateCategorySeries(result);
      },
      (error) => {}
    );
  }, [assetId]);

  function updateStatusSeries(data) {
    let dataSeries = [];
    const keys = Object.keys(data);
    for (const category of keys) {
      dataSeries.push({ name: category, value: data[category] });
    }
    setStatusSeries(dataSeries);
  }

  function updateCategorySeries(data) {
    let dataSeries = [];
    const keys = Object.keys(data);
    for (const category of keys) {
      dataSeries.push({ name: category, value: data[category] });
    }
    setCategorySeries(dataSeries);
  }

  function getOptions(data, title, series_name) {
    return {
      title: {
        text: title,
        textStyle: {
          color: "#fff",
          fontSize: 12,
        },
      },
      legend: {
        top: "bottom",
        textStyle: {
          color: "#fff",
        },
      },
      series: [
        {
          name: series_name,
          type: "pie",
          radius: ["30%", "70%"],
          roseType: "area",
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: "inside",
            formatter: "{c}",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "40",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
  }

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Well Status</span>
        <div className="header-menu-container d-flex flex-row">
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content">
        <div className="h-100 row g-0">
          <ReactECharts
            option={getOptions(statusSeries, "Well Status", "Well Status")}
            className="col w-50"
          />
          <ReactECharts
            option={getOptions(
              categorySeries,
              "Well Category",
              "Well Category"
            )}
            className="col w-50"
          />
        </div>
      </div>
    </div>
  );
}

export { WellStatusChart };
