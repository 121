import { EditableProTable } from '@ant-design/pro-components'
import React, { useMemo, useRef } from 'react'
import enUS from "antd/locale/en_US";
import { Button, ConfigProvider, Popconfirm } from 'antd'

import editorTableClasses from '../editable-table.module.scss'
import { useMutation } from '@tanstack/react-query';
import { deleteWellSalePrice, updateWellSalePrice } from 'service/salePrices';

const WellSalePricesEditor = ({
  isLoading,
  records = [],
  wellId,
  onRefetch
}) => {
  const container = useRef(null)

  const { mutate: deleteSalePrice } = useMutation({
    mutationFn: (id) => deleteWellSalePrice(wellId, [id]),
    onSuccess: () => onRefetch()
  })

  const { mutate: updateSalePrice } = useMutation({
    mutationFn: (record) => updateWellSalePrice(wellId, [record]),
    onSuccess: () => onRefetch()
  })

  const columns = useMemo(
    /**
     * 
     * @returns {import('@ant-design/pro-components').ProColumns}
     */
    () => [
      {
        title: 'Date',
        dataIndex: 'date',
        editable: true,
      },
      {
        title: 'Oil Sale Price ($/bbl)',
        dataIndex: 'oil',
        editable: true,
      },
      {
        title: 'NG Sale Price ($/MSCF)',
        dataIndex: 'gas',
        editable: true
      },
      {
        title: 'NGL Sale Price ($/gln)',
        dataIndex: 'ngl',
        editable: true
      },
      {
        key: 'actions',
        valueType: "option",
        title: 'Actions',
        render: (_, record, index, action) => {
          return (
            <div className="d-flex gap-2">
              <Button onClick={() => action.startEditable?.(record.id)}>Edit</Button>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => deleteSalePrice(record.id)}
              >
                <Button>Delete</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    [deleteSalePrice])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      <div ref={container} className="vstack h-100">
        <EditableProTable
          loading={isLoading}
          alwaysShowAlert={false}
          tableAlertRender={false}
          tableAlertOptionRender={false}
          pagination={false}
          search={false}
          rowKey="id"
          sticky={true}
          columns={columns}
          value={records}
          className={editorTableClasses.editableTable}
          cardProps={{
            className: editorTableClasses.card
          }}
          tableClassName={editorTableClasses.table}
          scroll={{
            y: container.current?.clientHeight - 60
          }}
          recordCreatorProps={{
            style: {
              display: 'none'
            }
          }}
          editable={{
            onSave: (id, record) => {
              if (record.isNewRecord) return;
              updateSalePrice(record)
            }
          }}
        />
      </div>
    </ConfigProvider>
  )
}

export default WellSalePricesEditor