import { useQuery } from '@tanstack/react-query'
import { Checkbox, Form, InputNumber, Select } from 'antd'
import FormSection from 'components/form-section'
import React from 'react'
import { getKIcReferences } from 'service/completionOptimizer'

const units = {
  youngs_module: {
    FIELD: 'psi',
    METRIC: 'GPa'
  },
  poisson_ratio: {
    FIELD: 'n/a',
    METRIC: 'n/a'
  },
  fracture_toughness: {
    FIELD: 'psi.in^0.5',
    METRIC: 'MPa∙m^0.5'
  },
  formation_thinkness: {
    FIELD: 'ft',
    METRIC: 'm'
  },
  stage_length: {
    FIELD: '',
    METRIC: '(m)'
  },
  number_cluster: {
    FIELD: 'n/a',
    METRIC: 'n/a'
  },
  perf_each_cluster: {
    FIELD: 'n/a',
    METRIC: 'n/a'
  },
  perf_diameter: {
    FIELD: 'inch',
    METRIC: 'cm'
  },
  perf_angle: {
    FIELD: '360',
    METRIC: '360'
  },
  leakOff_coe: {
    FIELD: 'ft/sec^0.5',
    METRIC: 'm/sec^0.5'
  },
  pumping_rate: {
    FIELD: 'bbl/min',
    METRIC: 'm^3/min'
  },
  fluid_viscosity: {
    FIELD: 'Pa∙sec',
    METRIC: 'Pa∙sec'
  },
  pumping_time: {
    FIELD: 'min',
    METRIC: 'min'
  },
  sand_usage: {
    FIELD: 'lb',
    METRIC: 'kg'
  }
}

const CustomizedSettings = ({ stageIndex }) => {
  const simulationUnit = Form.useWatch(["GENERAL", "unit"])
  const kicReferenced = Form.useWatch(['Completion', 'hydraulic_fracture', stageIndex, 'CUSTOMIZED', 'geomechanics', 'k_ic_reference'])

  const { data: kicReferences } = useQuery({
    queryKey: ['getKIcReferences'],
    queryFn: () => getKIcReferences()
  })

  return (
    <div className="flex flex-col gap-4">
      <FormSection title="Geomechanics">
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'geomechanics', 'youngs_module']}
            label={`Young’s modulus (E) (${ units.youngs_module[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'geomechanics', 'poisson_ratio']}
            label={`Poisson ratio (ν) (${ units.poisson_ratio[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'geomechanics', 'fracture_toughness']}
            label={`Fracture toughness (KIc) (${ units.fracture_toughness[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'geomechanics', 'k_ic_reference']}
            valuePropName='checked'
            className="self-end"
          >
            <Checkbox className="w-full">KIc reference (dropdown select)</Checkbox>
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'geomechanics', 'formation_thinkness']}
            label={`Formation thickness (D) (${ units.formation_thinkness[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          {kicReferenced ? (
            <Form.Item
              name={[stageIndex, 'CUSTOMIZED', 'geomechanics', 'kic_name']}
              label={`Select KIc`}
            >
              <Select options={kicReferences?.map(item => ({
                value: item.name,
                label: item.name
              }))} />
            </Form.Item>
          ) : null}
        </div>
      </FormSection>
      <FormSection title="Completion">
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'custom_completion', 'stage_length']}
            label={`Stage length ${ units.stage_length[simulationUnit] }`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'custom_completion', 'number_cluster']}
            label={`Number of cluster (${ units.number_cluster[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'custom_completion', 'perf_each_cluster']}
            label={`Perf of each cluster (${ units.perf_each_cluster[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'custom_completion', 'perf_diameter']}
            label={`Perf diameter (${ units.perf_diameter[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'custom_completion', 'perf_angle']}
            label={`Perf angle (${ units.perf_angle[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
      </FormSection>
      <FormSection title="Treatment">
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'treatemnt', 'leakOff_coe']}
            label={`LeakOff coefficient (CL) (${ units.leakOff_coe[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'treatemnt', 'pumping_rate']}
            label={`Pumping rate (Q0) (${ units.pumping_rate[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'treatemnt', 'fluid_viscosity']}
            label={`Fluid viscosity (μ) (${ units.fluid_viscosity[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'treatemnt', 'pumping_time']}
            label={`Pumping time (t) (${ units.pumping_time[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name={[stageIndex, 'CUSTOMIZED', 'treatemnt', 'sand_usage']}
            label={`Sand usage (${ units.sand_usage[simulationUnit] })`}
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
      </FormSection>
    </div>
  )
}

export default CustomizedSettings