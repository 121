import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  routes: [
    {
      id: "1",
      level: 0,
      active: false,
      title: "Asset Viewer",
      link: "/asset-viewer",
      iconCls: "icon-01",
      routes: [],
    },
    {
      id: "2",
      level: 0,
      active: false,
      title: "Well Viewer",
      link: "/well-viewer",
      iconCls: "icon-02",
      routes: [],
    },
    {
      id: "3",
      level: 0,
      active: false,
      title: "Production Analyzer",
      link: "#",
      iconCls: "icon-03",
      routes: [
        {
          id: "31",
          level: 1,
          title: "Project Summary",
          link: "/project_summary/",
          // icon: "/images/project/Group4.png",
        },

        {
          id: "32",
          level: 1,
          active: false,
          title: "Decline Analysis",
          link: "#",
          routes: [
            {
              id: "321",
              level: 2,
              title: "New Project",
              link: "/decline_analysis/create_project/",
              // icon: "/images/project/Group2.png",
            },
            {
              id: "322",
              level: 2,
              title: "DCA Engine",
              link: "/decline_analysis/analyzer/",
              // icon: "/images/project/Group3.png",
            },
            {
              id: "324",
              level: 2,
              title: "Decline Management",
              link: "/decline_analysis/management/",
            },
            {
              id: "323",
              level: 2,
              title: "Reporting",
              link: "/decline_analysis/reporting/",
              // icon: "/images/project/Group6.png",
            },
          ],
        },
        {
          id: "33",
          level: 2,
          active: false,
          title: "Type Curve Engine",
          link: "#",
          // icon: "/images/project/Group3.png",
          routes: [
            {
              id: "331",
              level: 2,
              title: "New Project",
              link: "/type_curve/create_project/",
              // icon: "/images/project/Group2.png",
            },
            {
              id: "332",
              level: 2,
              title: "Type Curve Engine",
              link: "/type_curve/analyzer/",
              // icon: "/images/project/Group3.png",
            },

            {
              id: "333",
              level: 2,
              title: "Reporting",
              link: "/type_curve/reporting/",
              // icon: "/images/project/Group6.png",
            },
          ],
        },

        {
          id: "34",
          level: 1,
          active: false,
          title: "Well Planner",
          link: "#",
          routes: [
            {
              id: "341",
              level: 2,
              title: "New Project",
              link: "/well_planner/create_project/",
            },
            {
              id: "342",
              level: 2,
              title: "Well Planner",
              link: "/well_planner/",
            },
            {
              id: "343",
              level: 2,
              title: "Reporting",
              link: "/well_planner/reporting/",
            },
          ],
        },
      ],
    },
    {
      id: "4",
      level: 0,
      active: false,
      title: "Data Manager",
      link: "#",
      iconCls: "icon-04",
      routes: [
        {
          id: "41",
          level: 1,
          title: "Data Input",
          link: "/petrophysics_input/",
          routes: [],
        },
        {
          id: "42",
          level: 1,
          active: false,
          title: "Well",
          link: "#",
          routes: [
            {
              id: "420",
              level: 2,
              title: "Well Asset",
              link: "/well_asset/",
            },
            {
              id: "421",
              level: 2,
              title: "Well Summary",
              link: "/well_summary/",
            },
            {
              id: "422",
              level: 2,
              title: "Well Editor",
              link: "/well_editor/",
            },
            {
              id: "423",
              level: 2,
              title: "ThreeDViewer",
              link: "/well_threed/",
            },
          ],
        },
        {
          id: "43",
          level: 1,
          active: false,
          title: "Petrophysics",
          link: "#",
          routes: [
            {
              id: "431",
              level: 2,
              title: "Log Viewer",
              link: "/petrophysics_logviewer/",
            },

            {
              id: "432",
              level: 2,
              title: "Well Correlation",
              link: "/petrophysics_correlation/",
            },
            {
              id: "433",
              level: 2,
              title: "Cross Section",
              link: "/petrophysics_crosssection/",
            },
            {
              id: "434",
              level: 2,
              title: "Property Log",
              link: "/petrophysics_propertylog/",
            },
          ],
        },
        {
          id: "44",
          level: 1,
          title: "Completion",
          link: "#",
          routes: [],
        },

        {
          id: "45",
          level: 1,
          active: false,
          title: "Production",
          link: "#",
          routes: [
            {
              id: "451",
              level: 2,
              title: "Production Viewer",
              link: "/production_viewer/",
            },
            {
              id: "452",
              level: 2,
              title: "Data Editor",
              link: "/production_editor/",
            },
          ],
        },
        {
          id: "46",
          level: 1,
          active: false,
          title: "Operation",
          link: "#",
          routes: [
            {
              id: "461",
              level: 2,
              title: "Data Editor",
              link: "/operation_editor/",
            },
          ],
        },
        {
          id: "47",
          level: 1,
          active: false,
          title: "Economics",
          link: "#",
          routes: [
            {
              id: "462",
              level: 2,
              title: "Basic",
              link: "/economics/basic",
            },
            {
              id: "463",
              level: 2,
              title: "Editor",
              link: "/economics/editor",
            },
          ],
        },
      ],
    },
    {
      id: "5",
      level: 0,
      active: false,
      title: "Completion Optimizer",
      iconCls: "icon-05",
      link: "#",
      routes: [
        {
          id: "50",
          level: 1,
          title: "Project Summary",
          link: "/completion_optimizer/project_summary",
        },
        {
          id: "51",
          level: 1,
          title: "Project Settings",
          link: "/completion_optimizer/project_settings",
        },
        {
          id: "52",
          level: 1,
          title: "Run",
          link: "/completion_optimizer/run",
        },
        {
          id: "53",
          level: 1,
          title: "Results",
          link: "/completion_optimizer/results",
        },
      ],
    }
  ],

  activeRoutes: [],
};

const routesSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setRoutes: (state, action) => {
      state.routes = action.payload;
      localStorage.setItem("routes", JSON.stringify(action.payload));
    },
    setActiveRoutes: (state, action) => {
      state.activeRoutes = action.payload;
      localStorage.setItem("active_routes", JSON.stringify(action.payload));
    },
  },
});

export const { setRoutes, setActiveRoutes } = routesSlice.actions;
export const selectRoutes = (state) => {
  const localItem = JSON.parse(localStorage.getItem("routes"));
  if (!localItem) {
    return state.nav.routes;
  } else {
    return localItem;
  }
}
export const selectActiveRoutes = (state) => {
  const localItem = JSON.parse(localStorage.getItem("active_routes"));
  if (!localItem) {
    return state.nav.activeRoutes;
  } else {
    return localItem;
  }
};
export default routesSlice.reducer;
