import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import Select from 'react-select';

import { selectManagementGlobal, setManagementGlobalAvailableParams } from "features/decline_analysis/dcaManagementSlice";
import { getDcaManagementAvailableParams } from "service/wellData";
import DcaManagementHistoryChart from "components/charts/DcaManagementHistoryChart";
import { DcaManagementCorrelation } from "./DcaManagementCorrelation";
import { DcaManagementHistogram } from "./DcaManagementHistogram";
import { selectActiveWell } from "features/decline_analysis/dcaProjectSlice";
import PopupWellSummary from "./PopupWellSummary";
import { PARAM_NAMES } from "./data";

const ChartTypes = ["Plot", "Histogram", "Correlation"];

function DcaManagementDataPanel(props) {
  const { selectedWellIds } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const managementGlobal = useSelector(selectManagementGlobal);
  const activeWell = useSelector(selectActiveWell)
  const dispatch = useDispatch();

  const [chartType, setChartType] = useState(0);
  const [seriesData, setSeriesData] = useState({});
  const [availableParamsResult, setAvailableParamsResult] = useState({});
  const [multiSelected, setMultiSelected] = useState([{ value: "oil_prd_rate", label: "Oil Rate" }]);

  const reloadData = useCallback(wellId => {
    showLoadingIcon && showLoadingIcon();
    getDcaManagementAvailableParams({
      uwi: wellId,
    },
      (result) => {
        let allRange = {};

        // get all params range from "available data" api
        let availableResult = result;
        let allParams = availableResult.available_params;

        allParams.map(param => {
          let dataArr = availableResult[param];
          let max = dataArr[0][1], min = dataArr[0][1];
          dataArr.map(data => {
            data[1] > max && (max = data[1]);
            data[1] < min && (min = data[1]);
            return null;
          })
          allRange[param] = min + '-' + max;
          return null;
        });
        dispatch(setManagementGlobalAvailableParams({
          availableParams: result.available_params,
          allParamsRange: allRange,
          availableParamsResult: result
        }));
        setAvailableParamsResult(result);
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      })
  }, [dispatch, showLoadingIcon, hideLoadingIcon]);

  useEffect(() => {
    if (selectedWellIds == null || selectedWellIds.length === 0) {
      return;
    }
    // if (multiSelected.length === 0) setMultiSelected([{ value: "oil_prd_rate", label: "Oil Rate" }])
    reloadData(selectedWellIds[0]); // only one well 
  }, [selectedWellIds, reloadData]);

  useEffect(() => {
    let xSeries, dataSeries = [];

    if (!multiSelected || !availableParamsResult) {
      setSeriesData({ xSeries: [], dataSeries: [] });
      return;
    }

    multiSelected.map((param, index) => {
      let paramData = availableParamsResult[param.value];
      if (paramData) {
        if (!xSeries || xSeries.length < paramData.length) {
          xSeries = paramData.map(item => item[0]);
        }
        if (!xSeries) {
          console.error("there is no date!")
        }
        let d = {};
        d[param.label || 'Oil Rate'] = {
          data: paramData.map(item => item[1]),
          lineType: index === 0 ? 'scatter' : 'line',
          unit: PARAM_NAMES[param.value].unit,
        }
        dataSeries.push(d);
      }
      return null;
    });

    setSeriesData({
      xSeries,
      dataSeries,
      config: {
        multiYAxis: true,
        allCurveUsePoint: true,
      }
    });
  }, [availableParamsResult, multiSelected])

  const handleMultiSelected = (arr) => {
    setMultiSelected(arr);
  }

  const handleSingleSelected = (selected) => {
    setMultiSelected([selected]);
  }

  const getMatrixFromCorrelationData = (selectedParams, allParams, correlationData) => {
    if (!selectedParams || !allParams || !correlationData) {
      return;
    }
    let nameIndexArr = []
    for (let i = 0; i < selectedParams.length; i++) {
      let found = allParams.findIndex(p => p === selectedParams[i]);
      if (found !== undefined) nameIndexArr.push(found);
    }
    if (nameIndexArr && nameIndexArr.length > 0) {
      let rows = nameIndexArr.map(i => i === -1 ? [0, 0, 0, 0, 0, 0, 0, 0] : correlationData[i]);
      let result = [];
      rows.map(row => {
        let arr = [];
        nameIndexArr.map(i => i === -1 ? arr.push(0) : arr.push(row[i]));
        return result.push(arr);
      });
      return result;
    }
    return null;
  }

  const [visible, setVisible] = useState(false);

  return (
    <div className="cards-container h-100">
      {visible && (<PopupWellSummary
        activeWell={activeWell}
        onClose={() => setVisible(false)}
        showLoadingIcon={showLoadingIcon}
        hideLoadingIcon={hideLoadingIcon} />
      )}
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <div className="header-title">Available Data</div>
        <div className="d-flex flex-row">
          <div className="">
            <select
              name="chartType"
              value={chartType}
              onChange={(e) => {
                let value = e.target.value;
                setChartType(parseInt(value));
              }}
            >
              {ChartTypes.map((item, index) => {
                return <option key={index} value={index}>{item}</option>;
              })}
            </select>
          </div>
          {/* <div>
            {false && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </div> */}
          <div className="header-menu-container d-flex flex-row">
            <div className="dropdown">
              <div className="cards-setting-btn">
                <div className="cards-setting-icon" onClick={() => { activeWell && setVisible(true) }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {chartType !== 1 && <Select
        defaultValue={{ value: 'oil_prd_rate', label: 'Oil Rate', key: 0 }}
        placeholder="Please select parameters..."
        onChange={handleMultiSelected}
        isMulti
        name="multi"
        options={managementGlobal.availableParams && managementGlobal.availableParams.map((item, index) => {
          return { key: index, value: item, label: PARAM_NAMES[item].name }
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? 'red' : 'grey',
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? 'red' : 'black',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? 'grey' : 'black',
          }),
        }}
      />}
      {chartType === 1 && <Select
        onChange={handleSingleSelected}
        name="single"
        options={managementGlobal.availableParams && managementGlobal.availableParams.map((item, index) => {
          return { key: index, value: item, label: PARAM_NAMES[item].name }
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? 'red' : 'grey',
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? 'red' : 'black',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? 'grey' : 'black',
          }),
        }}
      />}
      <div className="cards-content d-flex">
        <div
          className=" ms-2 h-100" style={{ width: "100%" }}
        >
          {chartType === 0 && availableParamsResult && <DcaManagementHistoryChart
            data={seriesData} />}

          {chartType === 1 && managementGlobal.availableParamsResult && <DcaManagementHistogram
            // get x, the second array
            xSeries={managementGlobal.availableParamsResult["histogram"][multiSelected[0].value][1].slice(0, -1)}

            // get y, the first array
            dataSeries={managementGlobal.availableParamsResult["histogram"][multiSelected[0].value][0]}
          />}

          {chartType === 2 && availableParamsResult &&
            <DcaManagementCorrelation
              xSeries={multiSelected.length > 0 ? multiSelected.map(i => i.label) : [multiSelected.label]}
              dataSeries={multiSelected.length > 0 && availableParamsResult &&
                getMatrixFromCorrelationData(
                  multiSelected.map(({ value }) => value),
                  availableParamsResult.correlation_params,
                  availableParamsResult.correlation
                )}
            />}
        </div>
      </div>

    </div>
  );
}

export default DcaManagementDataPanel;
