export const snakeToCamel = (str) => {
  if (!str) return ''
  let fixedStr = str
    .trim()
    .toLowerCase()
    .replace(/[-_].{0,1}/g, (group) =>
      group.toUpperCase().replace("-", " ").replace("_", " ")
    )
  return fixedStr.charAt(0).toUpperCase() + fixedStr.slice(1);
};

export function isNumeric(str) {
  if (typeof str === 'number') return true
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}