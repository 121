import Dashboard from "./user-dashboard";
import { RequireAuth } from "components/auth";

function UserDashboard() {
  return (
    <RequireAuth>
      <Dashboard />
    </RequireAuth>
  );
}

export { UserDashboard };
