import { useState, useEffect } from "react";
import { Cascader, ConfigProvider, Spin } from 'antd';
import {
  getProductions,
} from "service/productionData";

import {
  getInjections,
} from "service/injectionData";

import { ProductionHeaders } from "./data";
import { useNavigate } from "react-router-dom";
import { convertToImperialDate } from "app/codes";
import { getAssets } from "service/assets";
import { getWellSummary } from "service/wellSummary";
import useLoading from "components/common/useLoading";
import * as dfd from "danfojs";

const QueryTypeOptions = [
  { value: "production", name: "Production" },
  { value: "injection", name: "Injection" },
];
function ProductionEditor() {
  const [queryType, setQueryType] = useState(QueryTypeOptions[0].value);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [assetWells, setAssetWells] = useState([]);
  const [selectedAssetWells, setSelectedAssetWells] = useState(null);
  const [defaultAssetWell] = useState(null);
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(<Spin size="large" />, 50);
  const navigate = useNavigate();

  useEffect(() => {
    getAssets(
      (result) => {
        let tempAssetWells = result.map((asset) => {
          return {
            value: asset.id,
            label: asset.asset_name,
            isLeaf: false,
            // children: [],
          };
        });
        setAssetWells(tempAssetWells);
      },
      (err) => { console.error(err); }
    );
  }, []);

  // Load Assets and Wells list
  const loadAssetWells = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.children && targetOption.children.length > 0) {
      return;
    }
    targetOption.loading = true;

    getWellSummary(
      targetOption.value,
      (result) => {
        targetOption.loading = false;
        let wells = result.map((well) => {
          return {
            value: well.uwi,
            label: well.well_name,
          };
        }).slice()
        .sort((a, b) => (a.label > b.label ? 1 : -1));
        targetOption.children = wells;
        setAssetWells([...assetWells]);
      },
      (err) => { console.error(err); }
    );
  }

  useEffect(() => {
    // refreshData();
    if (!selectedAssetWells) return;
    if (queryType === QueryTypeOptions[0].value) {
      showLoadingIcon();
      getProductions(
        // use selectedAssetWells
        { uwis: selectedAssetWells[1] },
        (result) => {
          updateProductionData(result);
          hideLoadingIcon();
        },
        (error) => {
          console.error(error);
          hideLoadingIcon();
        }
      );
    } else {
      showLoadingIcon();
      getInjections(
        { uwis: selectedAssetWells[1] },
        (result) => {
          updateInjectionData(result);
          hideLoadingIcon();
        },
        (error) => {
          console.error(error);
          setQueryType(QueryTypeOptions[0].value);
          hideLoadingIcon();
        }
      );
    }
  }, [queryType, selectedAssetWells, showLoadingIcon, hideLoadingIcon]);

  function updateProductionData(result) {
    let wellInfos = result.wells;
    let wellInfoMap = new Map();
    for (let well of wellInfos) {
      wellInfoMap.set(well.uwi, well);
    }

    let recordKey = ["well_name", "prd_date"];
    let productions = result.productions;

    let dataDf = null;
    for (let wellId of Object.keys(productions)) {
      let wellProductions = productions[wellId];
      let wellInfo = wellInfoMap.get(wellId);
      if (!wellInfo) continue;

      let wellDataDf = null;
      for (let prdIndex of Object.keys(wellProductions)) {
        recordKey.push(prdIndex);
        let productionsData = wellProductions[prdIndex];

        let prdDf = new dfd.DataFrame(productionsData, { columns: ["prd_date", `${prdIndex}_id`, prdIndex] });
        if (wellDataDf == null) {
          wellDataDf = prdDf;
          continue;
        }
        wellDataDf = dfd.merge({
          left: wellDataDf,
          right: prdDf,
          how: "outer",
          on: ["prd_date"],
        });
      }
      const uwiColumn = new Array(wellDataDf.shape[0]).fill(wellInfo.uwi);
      const wellNameColumn = new Array(wellDataDf.shape[0]).fill(wellInfo.well_name);
      wellDataDf.addColumn("uwi", uwiColumn, { inplace: true });
      wellDataDf.addColumn("well_name", wellNameColumn, { inplace: true });
      if (dataDf == null) {
        dataDf = wellDataDf;
      } else {
        dataDf = dfd.concat({ dfList: [dataDf, wellDataDf], axis: 0 });
      }
    }
    let dataRecords = dfd.toJSON(dataDf);

    let headers = ProductionHeaders.filter((header) =>
      recordKey.includes(header.value)
    );
    setTableHeaders(headers);
    setTableData(dataRecords);
  }

  function updateInjectionData(result) {
    let wellInfos = result.wells;
    let wellInfoMap = new Map();
    for (let well of wellInfos) {
      wellInfoMap.set(well.well_uwi, well);
    }

    let recordKey = ["well_name", "prd_date"];
    let dataRecords = [];
    let injections = result.injections;
    for (let wellId of Object.keys(injections)) {
      let wellInjection = injections[wellId];
      let wellInfo = wellInfoMap.get(wellId);
      for (let injDate of Object.keys(wellInjection)) {
        let injectionData = wellInjection[injDate];
        let record = {};
        for (let injIndex of Object.keys(injectionData)) {
          let dataItem = injectionData[injIndex];
          record.uwi = wellInfo.well_uwi;
          record.well_name = wellInfo.well_name;
          record.inj_date = convertToImperialDate(injDate);
          record[injIndex] = dataItem;
          if (recordKey.includes(injIndex) === false) {
            recordKey.push(injIndex);
          }
        }
        dataRecords.push(record);
      }
    }
    let headers = ProductionHeaders.filter((header) =>
      recordKey.includes(header.value)
    );
    setTableHeaders(headers);
    setTableData(dataRecords);
  }

  function handleEditRowData(record) {
    navigate('/production_editor/' + record?.uwi);
  }

  function generateTableHeaders(headers) {
    if (headers == null) {
      return null;
    }

    return headers.map((x, i) => {
      return (
        <div className="header-cell" key={i + 1}>
          <span>{x.name}</span>
        </div>
      );
    });
  }

  function generateTableBody() {
    if (tableData == null || tableData.length === 0) {
      return null;
    }

    return tableData.map((record, index) => {
      return (
        <div
          key={index}
          className="data-row"
          onClick={(e) => {
            handleEditRowData(record);
          }}
        >
          <div className="data-cell" key={index + "_" + 0}>
            <span>{index}</span>
          </div>
          {tableHeaders.map((header, i) => {
            let cell = record[header.value];
            let callValue = null;
            if (cell != null) {
              if (cell.value != null) {
                callValue = cell.value;
              } else {
                callValue = cell;
              }
            }
            return (
              <div className="data-cell" key={index + "_" + (i + 1)}>
                <span>{callValue != null ? callValue : ""}</span>
              </div>
            );
          })}
        </div>
      );
    });
  }

  const onChange = (value, selectedOptions) => {
    if (!value || value.length === 1) return;
    setSelectedAssetWells(value);
  };

  return (
    <>
      <div className="page-content d-flex flex-column">
        <LoadingIcon />
        <div className="cards-container">
          <div className="cards-header d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <label htmlFor="queryType">Change</label>
              <select
                name="queryType"
                value={queryType}
                onChange={(e) => {
                  let index = e.target.value;
                  setQueryType(QueryTypeOptions[index].value);
                }}
              >
                {QueryTypeOptions.map((item, index) => {
                  return <option value={index} key={index}>{item.name}</option>;
                })}
              </select>
              <label htmlFor="queryType">Asset/Well</label>
              <ConfigProvider
                theme={{
                  components: {
                    Cascader: {
                      controlItemWidth: 50,
                      controlWidth: 200,
                      controlHeight: 25,
                      dropdownHeight: 300,
                      borderRadiusSM: 2,
                    },
                  },
                  token: {
                    colorText: 'white',
                    colorTextQuaternary: 'white',
                    colorBgElevated: 'rgba(25, 25, 25, 0.73)',
                    colorBgContainer: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
              >
                <Cascader
                  options={assetWells}
                  value={selectedAssetWells}
                  onChange={onChange}
                  loadData={loadAssetWells}
                  defaultValue={defaultAssetWell}
                  bordered={false}
                  // showSearch={true}
                  // expandTrigger={'click'}
                  placeholder="Please select"
                  changeOnSelect
                />
              </ConfigProvider>
            </div>
            <div className="header-menu-container d-flex flex-row"></div>
          </div>
          <div className="input-container">
            <div
              className="table-container d-flex flex-column justify-content-start align-items-start"
              style={{ height: "90%" }}
            >
              <div className="table-header">
                <div className="header-cell" key={0}>
                  <span>#</span>
                </div>
                {generateTableHeaders(tableHeaders)}
              </div>
              <div className="table-body">{generateTableBody()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductionEditor;
