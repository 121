import React from 'react'
import PerformanceMetricsTable from './PerformanceMetricsTable'
import PerformanceMetricsChart from './PerformanceMetricsChart'
import { useQuery } from '@tanstack/react-query'
import { getWellPerformanceMetrics } from 'service/wellSummary'

import classes from './performace-metrics.module.scss'
import classNames from 'classnames'

const PerformanceMetrics = ({ wellId }) => {
  const { data: records = [], isFetching } = useQuery({
    queryKey: ['getWellPerformanceMetrics', wellId],
    queryFn: () => getWellPerformanceMetrics(wellId),
    select: (res) => res.data
  })

  return (
    <div className="vstack h-100 gap-2">
      <div className="h-50">
        <PerformanceMetricsTable isLoading={isFetching} records={records} />
      </div>
      <div className={classNames('h-50', classes.chartContainer)}>
        <PerformanceMetricsChart isLoading={isFetching} records={records} />
      </div>
    </div>
  )
}

export default PerformanceMetrics