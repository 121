import { Tabs } from 'antd'
import React from 'react'

import classes from './dictionary-editor.module.scss'
import DictionaryEditor from './Editor'
import classNames from 'classnames'

/**
 * @type {import('antd').TabsProps['items']}
 */
const tabs = [{
  key: 'technical',
  label: 'Technical',
  children: <DictionaryEditor dictype="technical" />
}, {
  key: 'economic',
  label: 'Economic',
  children: <DictionaryEditor dictype="economic" />
}]

const DictionaryPage = () => {

  return (
    <div className={classNames("stack p-2", classes.wrapper)}>
      <Tabs items={tabs} destroyInactiveTabPane type="card" className={classes.tabs} />
    </div>
  )
}

export default DictionaryPage