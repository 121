import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import PpLayout from "../components/PpLayout";
import { Layout, Space, Spin } from 'antd';
import PpAssetWellSelector from "../components/selector/PpAssetWellSelector";
import PpLogSelector from "../components/selector/PpLogSelector";
import PpFormationTable from "../components/PpFormationTable";
import PpTopSelector from "../components/selector/PpTopSelector";
import PpWellsWithCrossSection from "../components/well_curve/PpWellsWithCrossSection";
import { useSelector } from "react-redux";
import { reset, selectBackgroundColorSetting } from "features/petrophysics/petrophysicsSlice";
import { contentStyle, rightContentStyle, rightStyle, siderStyle } from "../styles";
import useLoading from "components/common/useLoading";
const { Sider, Content } = Layout;

const Correlation = (props) => {
    const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(<Spin size="large" />, 50);
    const backColor = useSelector(selectBackgroundColorSetting);
    const dispatch = useDispatch();
    useEffect(()=>{
        return ()=>{
            dispatch(reset());
        }
    }, [dispatch]);

    const tops = [1.2, 2.6];
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <LoadingIcon />
            <PpLayout>
                <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    size={[0, 48]}
                >
                    <Layout height={'100vh'}>
                        <Sider style={siderStyle}>
                            <Content style={{ ...contentStyle }} >
                                <PpAssetWellSelector
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                            <Content style={{ ...contentStyle }}>
                                <PpLogSelector
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                            <Content style={{ ...contentStyle }}>
                                <PpTopSelector
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                        </Sider>
                        <Content style={rightStyle}>
                            <Content style={{
                                ...rightContentStyle,
                                backgroundColor: backColor,
                                height: '70%'
                            }}>
                                <PpWellsWithCrossSection
                                    gapWidth='0px'
                                    columnWidth='100px'
                                    tops={tops}
                                    showTop={true}
                                    height={590}
                                    syncDepth={false}
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                            <Content style={{
                                ...rightContentStyle,
                                height: '30%'
                            }}>
                                <PpFormationTable
                                    editable={true}
                                    loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                />
                            </Content>
                        </Content>
                    </Layout>
                </Space>
            </PpLayout>
        </div>
    )
};
export default Correlation;
