export function parseDevFile(fileHandles, onFinished) {
  let allData = [];
  for (let fileHandle of fileHandles) {
    parseSingleDevFile(fileHandle, (result) => {
      allData.push(result);
      if (allData.length === fileHandles.length) {
        onFinished(allData);
      }
    });
  }
}

export function parseSingleDevFile(fileHandle, onFinished) {
  let fileName = fileHandle.name;
  const wellName = fileName.split(".")[0];
  const fr = new FileReader();
  fr.onload = function () {
    let text = fr.result;
    const lines = text.split("\n");
    let BigList = [];
    for (const line of lines) {
      const currentLine = line.trim();
      if (currentLine.length < 3) {
        console.error("error input data", currentLine);
        continue;
      }
      let items = currentLine.split(/\s+/);
      let record = items.map((item) => {
        if (item != null && typeof item === "string") {
          return item.trim();
        }
        return item;
      });

      BigList.push(record);
    }
    onFinished({
      version: "version x",
      well_name: wellName,
      headers: ["latitude", "longitude", "tvd", "measured_depth"],
      data: BigList,
    });
  };
  fr.readAsText(fileHandle);
}

export function parseTopFile(fileHandle, onFinished) {
  const fr = new FileReader();
  fr.onload = function () {
    let text = fr.result;
    const lines = text.split("\n");
    let BigList = [];
    let HeaderList = [];
    let s = 0;
    for (let line of lines) {
      const currentLine = line.trim();
      let items = currentLine.split("\t");
      let record = items.map((item) => {
        if (item != null && typeof item === "string") {
          return item.trim();
        }
        return item;
      });

      if (s === 0) {
        HeaderList = [...record];
        s += 1;
        continue;
      }
      if (record.length > 1) {
        BigList.push(record);
      }
    }
    onFinished({
      headers: HeaderList,
      data: BigList,
    });
  };
  fr.readAsText(fileHandle);
}

export function parseLasFile(fileHandle, onFinished) {
  const fr = new FileReader();
  fr.onload = function () {
    let text = fr.result;
    const lines = text.split("\n");
    let hit = 0;
    let BigList = [];
    let elementValues = [];
    let prevHeading = "";
    for (let line of lines) {
      const currentLine = line.trim();
      if (currentLine.length === 0 || currentLine === "\n") {
        continue;
      }

      if (currentLine[0] === "~") {
        hit = hit + 1;
        if (hit === 2) {
          BigList.push({
            title: prevHeading.slice(1),
            elementValues: elementValues,
          });
          elementValues = [];
        } else if (hit > 2) {
          BigList.push({
            title: prevHeading.slice(1),
            elementValues: elementValues,
          });
          elementValues = [];
        }
        prevHeading = currentLine;
      } else {
        if (currentLine[0] !== "#") {
          elementValues.push(currentLine);
        }
      }
    }
    BigList[BigList.length - 1].elementValues = elementValues.map((el) => {
      return el.split(/\s+/);
    });
    BigList[BigList.length - 1].title = prevHeading.slice(1);

    for (let i = 0; i < BigList[0].elementValues.length; i++) {
      let splitted = BigList[0].elementValues[i].split(":");
      let further_splitted = splitted[0].split(/\s+/);
      BigList[0].elementValues[i] = {
        title: further_splitted[0].trim(),
        data: further_splitted[1].trim(),
        description: splitted[1].trim(),
      };
    }

    for (let i = 0; i < BigList[1].elementValues.length; i++) {
      let splitted = BigList[1].elementValues[i].split(":");
      let description = splitted[1].trim();
      let part1 = splitted[0].trim();
      let index = part1.indexOf(".");
      let name = part1.substring(0, index).trim();
      let part2 = part1.substring(index + 1);
      let unit = "";
      let data = null;
      if (part2[0] === " ") {
        data = part2.trim();
      } else {
        let startIdx = part2.indexOf(" ");
        unit = part2.substring(0, startIdx).trim();
        data = part2.substring(startIdx + 1).trim();
      }

      BigList[1].elementValues[i] = {
        name: name,
        unit: unit,
        data: data,
        description: description,
      };
    }

    for (let i = 0; i < BigList[2].elementValues.length; i++) {
      let splitted = BigList[2].elementValues[i].split(":");
      let further_splitted = splitted[0].split(".");
      BigList[2].elementValues[i] = {
        name: further_splitted[0].trim(),
        unit: further_splitted[1].trim(),
        description: splitted[1].trim(),
      };
    }
    onFinished(BigList);
  };
  fr.readAsText(fileHandle);
}

export function parseDatFile(fileHandle, onFinished) {
  const fr = new FileReader();
  fr.onload = function () {
    let text = fr.result;
    const rows = text.split("\n");
    let headers = rows[0].split("\t");

    let records = [];
    for (let i = 1; i < rows.length; i++) {
      const currentLine = rows[i].trim();
      if (currentLine !== "") {
        let items = currentLine.split("\t");
        let record = items.map((item) => {
          if (item != null && typeof item === "string") {
            return item.trim();
          }
          return item;
        });
        records.push(record);
      }
    }
    onFinished({
      headers: headers,
      data: records,
    });
  };
  fr.readAsText(fileHandle);
}

export function parsePrdFile(fileHandle, onFinished) {
  const fr = new FileReader();
  fr.onload = function () {
    let data = fr.result;
    const rows = data.split("\r");
    let records = [];
    let headers = rows[0].split("\t");

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].trim();
      if (row !== "") {
        let items = row.split("\t");
        let record = items.map((item) => {
          if (item != null && typeof item === "string") {
            return item.trim();
          }
          return item;
        });
        records.push(record);
      }
    }

    onFinished({
      headers: headers,
      data: records,
    });
  };

  fr.readAsText(fileHandle);
}

export function parseInjFile(fileHandle, onFinished) {
  const fr = new FileReader();
  fr.onload = function () {
    let data = fr.result;
    const rows = data.split("\r");
    let records = [];
    let headers = rows[0].split("\t");

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].trim();
      if (row !== "") {
        let items = row.split("\t");
        let record = items.map((item) => {
          if (item != null && typeof item === "string") {
            return item.trim();
          }
          return item;
        });
        records.push(record);
      }
    }

    onFinished({
      headers: headers,
      data: records,
    });
  };

  fr.readAsText(fileHandle);
}

export function parseEventsFile(fileHandle, onFinished) {
  const fr = new FileReader();
  fr.onload = function () {
    let data = fr.result;
    const rows = data.split("\r");
    let records = [];
    let headers = rows[0].split("\t");

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].trim();
      if (row !== "") {
        let items = row.split("\t");
        let record = items.map((item) => {
          if (item != null && typeof item === "string") {
            return item.trim();
          }
          return item;
        });
        records.push(record);
      }
    }

    onFinished({
      headers: headers,
      data: records,
    });
  };

  fr.readAsText(fileHandle);
}

export function parseSurveyFile(fileHandle, onFinished) {
  let fileName = fileHandle.name;
  const wellName = fileName.split(".")[0];

  const fr = new FileReader();
  fr.onload = function () {
    let data = fr.result;
    const rows = data.split("\r");
    let records = [];
    let headers = rows[0].split("\t");
    let units = rows[1].split("\t");

    for (let i = 2; i < rows.length; i++) {
      const row = rows[i].trim();
      if (row !== "") {
        let items = row.split("\t");
        let record = items.map((item) => {
          if (item != null && typeof item === "string") {
            return item.trim();
          }
          return item;
        });
        records.push(record);
      }
    }

    onFinished({
      headers: headers,
      units: units,
      well_info: { well_name: wellName },
      data: records,
    });
  };

  fr.readAsText(fileHandle);
}
