import { ConfigProvider, Descriptions, Empty, Input, Layout, Radio, Select, Space, Typography } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { getWellInputConfig } from "service/petrophysics";
import CalcLayout from "./calculator/CalcLayout";

const PpLogCalculator = (props) => {
    const { loadingIconActions } = props;
    const { Title } = Typography;

    const [methods, setMethods] = useState("calculator");   //calulator, ai
    const [formula] = useState([]);
    const [newLogName, setNewLogName] = useState('');
    const [logTypeAndUnit, setLogTypeAndUnit] = useState(null);
    const [logType, setLogType] = useState(null);
    const [unit, setUnit] = useState(null);
    const [desc, setDesc] = useState("");

    useEffect(() => {
        // showLoadingIcon && showLoadingIcon();
        getWellInputConfig(
            (res) => {
                setLogTypeAndUnit(res.log_types);
                // hideLoadingIcon && hideLoadingIcon();
            },
            (err) => {
                console.warn("load input config error. ", err);
                // hideLoadingIcon && hideLoadingIcon();
            }
        )
    }, []);

    const getUnits = useCallback(logType => {
        if (!logType || !logTypeAndUnit) return [];
        let oneLog = logTypeAndUnit.find(item => item.log_type === logType);
        if (!oneLog) return [];


        let result = [{
            label: oneLog.unit_uk,
            value: "UK",
        }];

        if (oneLog.unit_metric) result.push({
            label: oneLog.unit_metric,
            value: "metric",
        });

        return result;
    }, [logTypeAndUnit]);

    useEffect(() => {
        let units = getUnits(logType);

        setUnit(units[0]);
    }, [logType, getUnits]);

    const onChange = (e) => {
        setMethods(e.target.value);
    }

    return (
        <Fragment>
            <ConfigProvider theme={{
                components: {
                    Title: {
                        colorPrimary: 'red',
                    },
                    Checkbox: {
                        colorText: 'white',
                    }
                },
                token: {
                    colorText: 'rgba(0,0,0,0.6)',
                    borderRadiusLG: 4,
                    controlHeightLG: 30,
                    lineHeightLG: 1,
                    colorTextDisabled: 'rgba(255, 255, 255, 0.4)'
                }
            }}>
                <Layout style={{ backgroundColor: 'rgb(35,38,63)', marginLeft: '5px', marginRight: '5px' }}>
                    <Space />
                    <Title level={4} style={{ color: 'white', marginTop: '1em' }}>Create a New log for selected wells</Title>
                    <Descriptions
                        labelStyle={{ color: 'rgba(255,255,255,0.6)' }}
                        contentStyle={{ color: 'white' }}
                        style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '5px' }}
                        column={3}
                        size='small'
                        layout="vertical"
                    >
                        <Descriptions.Item label="* Log Name">
                            <Input
                                style={{ marginRight: '5px', width: '150px' }}
                                onChange={(e) => setNewLogName(e.target.value)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="* Log type">
                            <Select
                                options={logTypeAndUnit ? logTypeAndUnit.map(item => ({ label: item.log_type, value: item.log_type })) : []}
                                onChange={(e) => setLogType(e)}
                                style={{ width: '150px', height: '2.4em' }}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="* Unit">
                            <Select
                                value={unit}
                                options={getUnits(logType)}
                                style={{ width: '100px', height: '2.4em' }}
                                onChange={(e) => { setUnit(e) }}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Description" span={3}>
                            <Input
                                style={{ marginRight: '5px', width: '490px' }}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Methods" span={1}>
                            <Radio.Group onChange={onChange} value={methods}>
                                <Radio value={"calculator"} style={{ color: 'white' }}>Calculator</Radio>
                                <Radio value={"ai"} style={{ color: 'white' }}>AI</Radio>
                            </Radio.Group>
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        methods === "calculator"
                            ? <CalcLayout
                                formula={formula}
                                newLogName={newLogName}
                                logType={logType}
                                logUnit={unit?.value || ''}
                                logDesc={desc}
                                loadingIconActions={loadingIconActions}
                            />
                            : <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description=""
                            >
                                <Title level={5} style={{ color: 'white' }}>This feature is currently under development</Title>
                            </Empty>
                    }

                </Layout>
            </ConfigProvider>
        </Fragment >
    )
};
export default PpLogCalculator;