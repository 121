import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectCustomWells,
  setCustomWells,
  selectWellLatLng,
} from "features/well_planner/plannerSlice";
import {
  selectActiveProject,
} from "features/project/projectsSlice";
import { saveHyperWells, removeHyperWells } from "service/wellPlanner";
import { generateUuid } from "utils/dataUtil";
import { message } from "antd";

const WellHeaders = [
  { value: "well_name", name: "Well Name" },
  { value: "rotation", name: "Rotate" },
  { value: "landing_x", name: "Landing X" },
  { value: "landing_y", name: "Landing Y" },
  { value: "sand_usage", name: "Sand Usage" },
  { value: "wellbore_length", name: "Wellbore Length" },
  { value: "number_of_stage", name: "Of Frac Stage" },
  { value: "perf_stage", name: "Perf Per Stage" },
];

function PlaceWellSetting(props) {
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const currentProject = useSelector(selectActiveProject);
  const wellLatLng = useSelector(selectWellLatLng);
  const customWells = useSelector(selectCustomWells);
  const dispatch = useDispatch();

  const initialWellValue = {
    well_name: "",
    rotation: "",
    landing_x: "",
    landing_y: "",
    sand_usage: "",
    wellbore_length: "",
    number_of_stage: "",
    perf_stage: ""
  }
  const [editWell, setEditWell] = useState(initialWellValue);

  useEffect(() => {
    if (wellLatLng == null) return;
    let wellLatLngObj = wellLatLng;
    if (typeof wellLatLng === "string") {
      wellLatLngObj = JSON.parse(wellLatLng);
    }
    setEditWell(editWell => ({ ...editWell, landing_x: wellLatLngObj.x0, landing_y: wellLatLngObj.y0 }))
  }, [wellLatLng]);

  function saveWells(e) {
    if (currentProject == null) {
      message.error("Please create a project at Step-1 !");
      return;
    }

    const { well_name, rotation, landing_x, landing_y, wellbore_length, number_of_stage, perf_stage } = editWell || {};
    if (editWell === null || !well_name || !landing_x || !landing_y || rotation === undefined || rotation === "" || !wellbore_length || !number_of_stage || !perf_stage) {
      message.error("Please select point from the map and fill all fields !");
      return;
    }

    let newWell = { ...editWell };
    if (newWell.well_uwi == null) {
      let well = customWells ? customWells.find(wellInfo => wellInfo.well_name === newWell.well_name) : null;
      if (!well) newWell.well_uwi = generateUuid().replaceAll("-", "");
      else {
        message.error('Well name exists! please use another name. if you want to modify a well, please clicked the well in "Created Wells" list.');
        return;
      }
    }

    newWell.project_id = currentProject.id;

    showLoadingIcon && showLoadingIcon();
    saveHyperWells(
      currentProject.id,
      [newWell],
      (res) => {
        if (res && res.length > 0) {
          let savedWell = res[0];
          updateNewCreatedWell(savedWell);
        } else {
          updateOldWell(editWell);
        }
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        console.error(error);
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }

  function removeWells(e) {
    if (editWell == null || editWell.id == null) {
      message.error("Please select a well to delete from below table first !");
      return;
    }
    showLoadingIcon && showLoadingIcon();
    removeHyperWells(
      currentProject.id,
      [editWell.id],
      (res) => {
        updateRemovedWell(editWell);
        setEditWell(initialWellValue);
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => { hideLoadingIcon && hideLoadingIcon(); }
    );
  }

  function updateNewCreatedWell(well) {
    let tempWells = [];
    if (customWells) {
      tempWells = [...customWells];
    }
    tempWells.push(well);
    dispatch(setCustomWells(tempWells));
  }

  function updateOldWell(well) {
    let oldWellIndex = -1;
    let tempWells = [];
    tempWells = [...customWells];
    for (let i = 0; i < customWells.length; i++) {
      if (customWells[i].id === well.id) {
        oldWellIndex = i;
        break;
      }
    }

    let oldWell = customWells[oldWellIndex];
    let newWell = { ...oldWell, ...well };
    tempWells[oldWellIndex] = newWell;

    dispatch(setCustomWells(tempWells));
  }

  function updateRemovedWell(removedWell) {
    let tempWells = customWells.filter((well) => well.id !== removedWell.id)
    dispatch(setCustomWells(tempWells));
  }

  function handleChange(e) {
    let well = {};
    if (editWell != null) {
      well = { ...editWell };
    }
    well[e.target.name] = e.target.value;
    setEditWell(well);
  }

  function generateWellsHeader() {
    return (
      <div className="table-header">
        {WellHeaders.map((item, i) => {
          return <div key={i} className="header-cell">{item.name}</div>;
        })}
      </div>
    );
  }

  function generateWellsList() {
    if (customWells == null || customWells.length === 0) {
      return;
    }

    return customWells.map((well, index) => {
      return (
        <div
          key={'cw' + index}
          className="data-row"
          onClick={(e) => {
            setEditWell({ ...customWells[index] });
          }}
        >
          {WellHeaders.map((item, i) => {
            return (
              <div
                key={'wh' + i}
                className="data-cell"
                onClick={(e) => {
                  setEditWell({ ...customWells[index] });
                }}
              >
                {well[item.value]}
              </div>
            );
          })}
        </div>
      );
    });
  }

  function setTextValue(well, propName) {
    let value = "";
    if (well != null) {
      value = well[propName] ?? "";
    }
    return value;
  }

  function setNumberValue(well, propName) {
    let value = 0;
    if (well != null) {
      value = well[propName] ?? "";
    }
    return value;
  }

  return wellLatLng && (
    <div className="d-flex flex-column params-panel">
      <div className="d-flex justify-content-between align-items-center">
        <div className="py-2 px-2 panel-header">Place And Complete Well</div>
      </div>
      <div className="d-flex flex-column panel-container mt-2 p-2">
        <div className="d-flex flex-column">
          <div className="data-row py-2">
            <label className="data-header" htmlFor="well_name">Well Name</label>
            <input
              className="data-cell"
              name="well_name"
              value={setTextValue(editWell, "well_name")}
              onChange={handleChange}
            ></input>
            <label htmlFor="rotation">Rotation(0)</label>
            <input
              name="rotation"
              value={setNumberValue(editWell, "rotation")}
              type="number"
              onChange={handleChange}
            ></input>
            <label htmlFor="sand_usage">Sand Amount</label>
            <input
              name="sand_usage"
              value={setNumberValue(editWell, "sand_usage")}
              type="number"
              onChange={handleChange}
            ></input>
          </div>

          <div className="data-row py-2">
            <label htmlFor="landing_x">Landing X</label>
            <input
              name="landing_x"
              value={setNumberValue(editWell, "landing_x")}
              type="number"
              onChange={handleChange}
            ></input>
            <label htmlFor="wellbore_length">Wellbore Length</label>
            <input
              name="wellbore_length"
              value={setNumberValue(editWell, "wellbore_length")}
              type="number"
              onChange={handleChange}
            ></input>
            <label htmlFor="perf_stage">Perf Per Stage</label>
            <input
              name="perf_stage"
              value={setNumberValue(editWell, "perf_stage")}
              type="number"
              onChange={handleChange}
            ></input>
          </div>
          <div className="data-row py-2">
            <label htmlFor="landing_y">Landing Y</label>
            <input
              name="landing_y"
              value={setNumberValue(editWell, "landing_y")}
              type="number"
              onChange={handleChange}
            ></input>
            <label htmlFor="number_of_stage">Of Frac Stage</label>
            <input
              name="number_of_stage"
              value={setNumberValue(editWell, "number_of_stage")}
              type="number"
              onChange={handleChange}
            ></input>
            <label htmlFor="op"></label>
            <input id="op" style={{ opacity: "0" }}></input>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center py-2">
          <button className="btn-custom me-4" onClick={saveWells}>
            Save
          </button>
          <button className="btn-custom ms-4" onClick={removeWells}>
            Delete
          </button>
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="py-4">Created Wells</div>
        <div className="table-container">
          <div className="input-container">
            {generateWellsHeader()}
            <div className="table-body h-100 overflow-auto hide-scrollbar">
              {generateWellsList()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlaceWellSetting;
