import { Form } from 'antd'
import React, { useEffect, useMemo, useRef } from 'react'
import Chart from 'chart.js/auto'
import { COLORS } from 'app/codes'
import { isNaN, toNumber } from 'lodash'

const SGOPlot = ({ name }) => {
  /**
   * @type {React.MutableRefObject<Chart>} chart
   */
  const chart = useRef()
  const chartCanvas = useRef()

  const data = Form.useWatch(name)

  const chartOptions = useMemo(
    /**
     * 
     * @returns {import('chart.js').ChartData}
     */
    () => {
      if (!data) return {}
      const labels = data.map(row => row.SG)

      const pcDatasetData = data.map(row => toNumber(row.PSIA))

      return {
        labels,
        datasets: [
          {
            label: 'Krg',
            data: data.map(row => row.KRG),
            fill: false,
            borderColor: COLORS[0],
            yAxisID: 'KRG',
            tension: 0.4
          },
          {
            label: 'Kro',
            data: data.map(row => row.KRO),
            fill: false,
            borderColor: COLORS[2],
            yAxisID: 'KRO',
            tension: 0.4
          },
          {
            label: 'Pc (psia)',
            data: pcDatasetData,
            fill: false,
            borderColor: COLORS[1],
            yAxisID: pcDatasetData.some(num => !isNaN(num) && num !== 0) ? 'PSIA' : undefined,
            tension: 0.4
          },
        ]
      }
    },
    [data])

  /**
   * 
   * @param {import('chart.js').ChartData} config 
   * @returns 
   */
  const updateChart = (config) => {
    if (!chartCanvas.current) return
    if (!chart.current) return

    if (config.labels) chart.current.data.labels = config.labels
    if (config.datasets) chart.current.data.datasets = config.datasets

    chart.current.update()
  }

  useEffect(() => {
    if (chart.current) chart.current.destroy()
    chart.current = new Chart(chartCanvas.current, {
      type: 'line',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'SGOF (Gas/Oil Saturation Functions)'
          }
        },
        scales: {
          KRG: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: 'Krg'
            }
          },
          KRO: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
              text: 'Kro'
            }
          },
          PSIA: {
            type: 'linear',
            display: 'auto',
            position: 'left',
            title: {
              display: true,
              text: 'Pc (psia)'
            }
          },
          x: {
            display: true,
            title: {
              display: true,
              text: 'Sg'
            }
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateChart(chartOptions)
  }, [chartOptions])

  return (
    <canvas ref={chartCanvas} />
  )
}

export default SGOPlot