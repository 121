import { useState, useEffect, useCallback } from "react";
import { Cascader } from "antd";
import { getAssets } from "service/assets";
import { getWellSummary } from "service/wellSummary";
import {
  getAlarms,
  saveAlarms,
  getWellEvents,
  saveWellEvents,
  getPlanning,
  savePlanning,
} from "service/events";

import { AlarmHeaders, EventHeaders, PlanningHeaders } from "./data";
import { useNavigate } from "react-router-dom";
const QueryTypeOptions = [
  { value: "alarm", name: "Alarm" },
  { value: "events", name: "Events" },
  { value: "planning", name: "Planning" },
];

function EventsEditor() {
  const [queryType, setQueryType] = useState(QueryTypeOptions[0].value);
  const [assetWells, setAssetWells] = useState([]);
  const [selectedAssetWells, setSelectedAssetWells] = useState(null);
  const [defaultAssetWell] = useState(null);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState(false);

  const [editHeaders, setEditHeaders] = useState([]);
  const [editRecords, setEditRecords] = useState([]);

  const navigate = useNavigate();

  const refreshData = useCallback(
    (uwi) => {
      if (queryType === QueryTypeOptions[0].value) {
        getAlarms(
          uwi,
          (result) => {
            // updateAlarmsData(result);
            setTableHeaders(AlarmHeaders);
            setTableData(result);
          },
          (error) => {
            console.error(error);
          }
        );
      } else if (queryType === QueryTypeOptions[1].value) {
        getWellEvents(
          uwi,
          (result) => {
            setTableHeaders(EventHeaders);
            setTableData(result);
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        getPlanning(
          uwi,
          (result) => {
            setTableHeaders(PlanningHeaders);
            setTableData(result);
          },
          (error) => {
            console.error(error);
          }
        );
      }
    },
    [queryType]
  );

  // Load Assets and Wells list
  useEffect(() => {
    getAssets(
      (result) => {
        let tempAssetWells = result.map((asset) => {
          return {
            value: asset.id,
            label: asset.asset_name,
            isLeaf: false,
            // children: [],
          };
        });
        setAssetWells(tempAssetWells);
      },
      (err) => {}
    );
  }, []);

  useEffect(() => {
    if (selectedAssetWells == null || selectedAssetWells.length === 0) {
      return;
    }
    if (selectedAssetWells.length === 2) {
      let wellId = selectedAssetWells[1];
      refreshData(wellId);
    }
  }, [selectedAssetWells, queryType, refreshData]);

  // Load Assets and Wells list
  const loadAssetWells = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.children && targetOption.children.length > 0) {
      return;
    }
    targetOption.loading = true;

    getWellSummary(
      targetOption.value,
      (result) => {
        targetOption.loading = false;
        let wells = result
          .map((well) => {
            return {
              value: well.uwi,
              label: well.well_name,
            };
          })
          .slice()
          .sort((a, b) => (a.label > b.label ? 1 : -1));
        targetOption.children = wells;
        setAssetWells([...assetWells]);
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const onChange = (value) => {
    if (!value) return;
    setSelectedAssetWells(value);
  };

  function handleEditRowData(record) {
    setEditHeaders([...tableHeaders]);
    setEditRecords([record]);
  }
  function handleCellValueChanged(rowIndex, key, value) {
    let tempRecords = [...editRecords];
    let tempRecord = { ...editRecords[rowIndex], [key]: value, changed: true };
    tempRecords[rowIndex] = tempRecord;
    setEditRecords(tempRecords);
  }

  function addRecord(e) {
    let record = {};
    let headers = {};
    if (queryType === QueryTypeOptions[0].value) {
      headers = AlarmHeaders;
    } else if (queryType === QueryTypeOptions[1].value) {
      headers = EventHeaders;
    } else {
      headers = PlanningHeaders;
    }

    for (let header of headers) {
      record[header.value] = "";
    }
    setEditHeaders([...headers]);
    setEditRecords([record]);
  }

  function saveEditResult(e) {
    let records = editRecords.filter((item) => item.changed === true);
    if (queryType === QueryTypeOptions[0].value) {
      saveAlarms(
        records,
        (result) => {
          if (result.success) {
            updateOldRecords(records);
          }
          if (result.data) {
            addNewCreatedRecords(result.data);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    } else if (queryType === QueryTypeOptions[1].value) {
      saveWellEvents(
        records,
        (result) => {
          if (result.success) {
            updateOldRecords(records);
          }
          if (result.data) {
            addNewCreatedRecords(result.data);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      savePlanning(
        records,
        (result) => {
          if (result.success) {
            updateOldRecords(records);
          }
          if (result.data) {
            addNewCreatedRecords(result.data);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  function updateOldRecords(records) {
    let updatedRecords = records.filter((record) => record.id != null);
    let tempTableData = [...tableData];
    for (let record of updatedRecords) {
      let oldIndex = tableData.findIndex((item) => item.id === record.id);
      tempTableData[oldIndex] = record;
    }
    setTableData(tempTableData);
  }

  function addNewCreatedRecords(records) {
    let tempTableData = [...tableData];
    for (let record of records) {
      tempTableData.push(record);
    }
    setTableData(tempTableData);
  }

  function changeKeyword(e) {
    let value = e.target.value;
    setKeyword(value);
  }

  function searchDatas(e) {
    let searchedRecords = tableData.filter((item) =>
      item.well_name.includes(keyword)
    );
    setEditHeaders([...tableHeaders]);
    setEditRecords(searchedRecords);
  }

  function generateTableHeaders(headers) {
    if (headers == null) {
      return null;
    }

    return headers.map((x, i) => {
      return (
        <div className="header-cell" key={i + 1}>
          <span>{x.name}</span>
        </div>
      );
    });
  }

  function generateTableBody() {
    if (tableData == null || tableData.length === 0) {
      return null;
    }

    return tableData.map((record, index) => {
      return (
        <div
          className="data-row"
          key={index}
          onClick={(e) => {
            navigate("/operation_editor/" + queryType + "/" + record.id);
          }}
        >
          <div className="data-cell" key={index + "_" + 0}>
            <span>{index}</span>
          </div>
          {tableHeaders.map((header, i) => {
            let cell = record[header.value];
            let callValue = null;
            if (cell != null) {
              if (cell.value != null) {
                callValue = cell.value;
              } else {
                callValue = cell;
              }
            }
            return (
              <div className="data-cell" key={index + "_" + (i + 1)}>
                <span>{callValue != null ? callValue : ""}</span>
              </div>
            );
          })}
        </div>
      );
    });
  }

  function generateEditBody() {
    if (
      editRecords == null ||
      editRecords.length === 0 ||
      editHeaders == null ||
      editHeaders.length === 0
    ) {
      return null;
    }

    return editRecords.map((record, index) => {
      return generateEditRow(record, index);
    });
  }

  function generateEditRow(record, rowIndex) {
    return (
      <div className="data-row">
        {editHeaders.map((header, i) => {
          let cell = record[header.value];
          let cellValue = "";
          if (cell != null) {
            if (cell.value != null) {
              cellValue = cell.value;
            } else {
              cellValue = cell;
            }
          }
          return (
            <input
              className="data-cell"
              key={"" + header.value + i}
              value={cellValue}
              name={header.value}
              onChange={(e) => {
                handleCellValueChanged(rowIndex, e.target.name, e.target.value);
              }}
            ></input>
          );
        })}
      </div>
    );
  }

  return (
    <>
      <div className="page-content d-flex flex-column">
        <div className="cards-container">
          <div className="cards-header d-flex flex-row justify-content-between align-items-center">
            <div className="header-menu-container d-flex flex-row">
              <div className="d-flex align-items-center">
                <label htmlFor="queryType">Change</label>
                <select
                  name="queryType"
                  value={queryType}
                  onChange={(e) => {
                    let value = e.target.value;
                    setQueryType(value);
                  }}
                >
                  {QueryTypeOptions.map((item, index) => {
                    return (
                      <option value={item.value} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ marginLeft: "8px" }}>
                {"Asset/Well:  "}
                <Cascader
                  options={assetWells}
                  value={selectedAssetWells}
                  onChange={onChange}
                  defaultValue={defaultAssetWell}
                  loadData={loadAssetWells}
                  bordered={true}
                  // showSearch={true}
                  // expandTrigger={'hover'}
                  placeholder="Please select"
                  changeOnSelect
                />
              </div>
            </div>
          </div>
          <div className="input-container">
            <div
              className="table-container d-flex flex-column justify-content-start align-items-start"
              style={{ height: "800px" }}
            >
              <div className="table-header">
                <div className="header-cell" key={0}>
                  <span>#</span>
                </div>
                {generateTableHeaders(tableHeaders)}
              </div>
              <div className="table-body">{generateTableBody()}</div>
            </div>
          </div>
        </div>

        {/* <div className="cards-container mt-2">
          <div className="d-flex justify-content-between align-items-center px-4 py-2">
            <div className="d-flex flex-row align-items-center">
              <div>
                <span>Editor</span>
              </div>
              <div className="ms-4">
                <div className="app-search d-none d-lg-block">
                  <div className="search-wraper d-flex flex-row">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={changeKeyword}
                    />
                    <div className="search-icon">
                      <i className="bi bi-search" onClick={searchDatas}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <button
                className="btn-custom btn-middle-size me-4"
                onClick={addRecord}
              >
                Add
              </button>
              <button
                className="btn-custom btn-middle-size"
                onClick={saveEditResult}
              >
                Save
              </button>
            </div>
          </div>
          <div
            className="table-container d-flex flex-column justify-content-start align-items-start px-2 py-2"
            style={{ height: "400px" }}
          >
            <div className="table-header">
              {generateTableHeaders(editHeaders)}
            </div>
            <div className="table-body">{generateEditBody()}</div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default EventsEditor;
