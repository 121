import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";

import { selectActiveWells } from "features/asset/assetSelectorSlice";
import {
  selectSelectedWellIds,
  selectAvailableWellParams,
  selectAllParams,
  selectAvailableParamsData,
} from "features/well_planner/plannerSlice";

function AvailableParamsChart(props) {
  const activeWells = useSelector(selectActiveWells);
  const selectedAllParams = useSelector(selectAllParams);
  const availableParams = useSelector(selectAvailableWellParams);
  const selectedWellIds = useSelector(selectSelectedWellIds);
  const selectedAvailableParamsData = useSelector(selectAvailableParamsData);
  const [options, setOptions] = useState({});

  const selectedWells = useMemo(() => {
    let selectedWells = [];
    if (
      selectedWellIds != null &&
      selectedWellIds.length > 0 &&
      activeWells != null &&
      activeWells.length > 0
    ) {
      let wellIdsSet = new Set(selectedWellIds);
      selectedWells = activeWells.filter((well) => wellIdsSet.has(well.uwi));
    }
    return selectedWells;
  }, [activeWells, selectedWellIds]);

  useEffect(() => {
    if (
      !(
        selectedWells &&
        selectedWellIds &&
        selectedAllParams &&
        availableParams
      )
    )
      return;
    let selectedWellNames = selectedWells.map((well) => well.well_name);
    let matrixData = [];

    for (let i = 0; i < selectedWellIds.length; i++) {
      for (let j = 0; j < selectedAllParams.length; j++) {
        availableParams[selectedWellIds[i]] &&
          matrixData.push([
            i,
            j,
            availableParams[selectedWellIds[i]].includes(selectedAllParams[j])
              ? 1
              : 0,
          ]);
      }
    }
    setOptions({
      tooltip: {
        show: true,
        trigger: "axis",
        formatter: "{b}",
      },
      gradientColor: ["rgb(255,255,255,0.5)", "rgb(0,255,0,0.5)"],
      grid: {
        height: "80%",
        top: "18%",
        left: "20%",
        right: "5%",
      },
      xAxis: {
        type: "category",
        data: selectedWellNames,
        position: "top",
        splitArea: {
          show: true,
        },
        axisLabel: {
          interval: "0",
          rotate: 90,
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 2,
            color: ["grey"],
          },
        },
      },
      yAxis: {
        type: "category",
        data: selectedAllParams,
        splitArea: {
          show: true,
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 2,
            color: ["grey"],
          },
        },
      },
      visualMap: {
        show: false,
        min: 0,
        max: 1,
        calculable: true,
        orient: "horizontal",
        left: "center",
        bottom: "0%",
      },
      series: [
        {
          name: "Data",
          type: "heatmap",
          // data: [[x, y, value]]
          data: matrixData,
          label: {
            show: false,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    });
  }, [
    availableParams,
    selectedWells,
    selectedAllParams,
    selectedAvailableParamsData,
    selectedWellIds,
  ]);

  return (
    <ReactECharts
      option={options}
      notMerge={true}
      style={{ height: "100%", width: "100%" }}
    />
  );
}

export default AvailableParamsChart;
