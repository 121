import { convertToImperialDate } from "app/codes";
import React, { useEffect, useState } from "react";
import { getWellEvents, getAssetWellEvents } from "service/events";

function EventLogTable(props) {
  const { wellId, assetId } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const [data, setData] = useState(null);

  useEffect(() => {
    if (wellId == null && assetId == null) {
      return;
    }

    showLoadingIcon && showLoadingIcon();
    if (wellId != null) {
      getWellEvents(
        wellId,
        (result) => {
          setData(result);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    } else if (assetId != null) {
      getAssetWellEvents(
        assetId,
        (result) => {
          setData(result);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    }
  }, [wellId, assetId, hideLoadingIcon, showLoadingIcon]);

  return (
    <>
      <table className="table table-borderless table-centered table-nowrap">
        <thead>
          <tr>
            <th style={{ width: "1.25rem" }}>
              <div className="d-flex flex-row align-items-center form-check align-middle">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                />
                <label className="form-check-label" htmlFor="customCheck1">
                  &nbsp;
                </label>
              </div>
            </th>
            <th>Well</th>
            <th>Event Type</th>
            <th>Start Date</th>
            <th>Duration(Days)</th>
            <th>Status</th>
            <th>Lead Person</th>
            <th>Contact</th>
          </tr>
        </thead>
        {data && (
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: "1.25rem" }}>
                    <div className="d-flex flex-row align-items-center form-check align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"customCheck" + item.id}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"customCheck" + item.id}
                      >
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td>{item.well_name}</td>
                  <td>{item.event}</td>
                  <td>{convertToImperialDate(item.start_date)}</td>
                  <td>{item.duration}</td>
                  <td>{item.status}</td>
                  <td>{item.lead}</td>
                  <td>{item.contact}</td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </>
  );
}

export default EventLogTable;
