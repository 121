import { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setShowEurParamsPanel,
  // selectEurData,
  // resetEurParams,
  selectActiveWell,
  selectDcaCommonParams,
  selectDcaUserParams,
  selectDcaRecommParams,
  selectDeclinePhase,
  setDeclinePhase,
  selectRecommEurDataAllPhase,
  selectUserEurDataAllPhase,
  setRecommEurDataByPhase,
  setUserEurDataByPhase,
  // setRecommEurDataAllPhase,
  // setUserEurDataAllPhase,
  selectAllDcaCommonParams,
  selectAllDcaRecommParams,
  selectAllDcaUserParams,
  setDcaCommonParamsByPhase,
  setDcaRecommParamsByPhase,
  setDcaUserParamsByPhase,
} from "features/decline_analysis/dcaProjectSlice";

import { getDcaForecast } from "service/dcaAnalysis";
import { getRecommData, getUserTuneData } from "service/dcaAnalysis";
import { parseFloatValue } from "utils/dataUtil";
import DcaManagementHistoryChart from "components/charts/DcaManagementHistoryChart";
import SectionSetting from "components/common/SectionSetting";
import { DECLINE_PHASE_OPTIONS } from "app/codes";

// function deepCopy(obj) {
//   return JSON.parse(JSON.stringify(obj));
// }

function ForecastChart(props) {
  const { loadingIconActions, noHeaderAndFooter } = props;
  // const { wellId, startDate } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const activeWell = useSelector(selectActiveWell);
  const dcaCommonParams = useSelector(selectDcaCommonParams);
  const dcaRecommParams = useSelector(selectDcaRecommParams);
  const dcaUserParams = useSelector(selectDcaUserParams);
  const declinePhase = useSelector(selectDeclinePhase);

  const eurRecommDataAllPhase = useSelector(selectRecommEurDataAllPhase);
  const eurUserDataAllPhase = useSelector(selectUserEurDataAllPhase);

  const dcaAllCommonParams = useSelector(selectAllDcaCommonParams);
  const dcaAllRecommParams = useSelector(selectAllDcaRecommParams);
  const dcaAllUserParams = useSelector(selectAllDcaUserParams);

  const dispatch = useDispatch();

  const [forecastMonths, setForecastMonths] = useState(
    dcaCommonParams.forecastMonths || 240
  );

  // historical production data
  // const [historicalData, setHistoricalData] = useState(null);

  // computed dca data with default dca parameters
  const [recommForecastData, setRecommForecastData] = useState(null);

  // computed dca data with user tunned dca parameters
  const [userDcaForecastData, setUserDcaForecastData] = useState(null);

  // computed data with ML model
  const [seriesData, setSeriesData] = useState({});

  const getEurData = (result, mode) => {
    const history = result?.history;
    const forecast = result?.dca?.predicted;
    if (!history || !forecast) {
      console.warn("Wrong result data for computing the Eur!");
      return;
    }

    const forecastSum = forecast.reduce((total, item) => (total += item[1]), 0);
    const historySum = history.reduce((total, item) => (total += item[1]), 0);
    return mode === "RECOMM"
      ? {
          eurRecomm: forecastSum + historySum,
          periodPrdRecomm: forecastSum,
        }
      : {
          eurUser: forecastSum + historySum,
          periodPrdUser: forecastSum,
        };
  };

  const forecast = useCallback(
    (well_uwi, declinePhase, forecastLength) => {
      if (
        !well_uwi ||
        !dcaCommonParams.startDate ||
        !dcaRecommParams.declineModel
      ) {
        return;
      }

      let recommParams = {
        wellId: well_uwi,
        declinePhase: declinePhase,
        declineModel: dcaRecommParams.declineModel,
        startDate: dcaCommonParams.startDate,
        forecastMonths: forecastLength,
        declineRate: dcaRecommParams.declineRate,
        ipRatio: dcaRecommParams.ipRatio,
        b: dcaRecommParams.b,
      };

      // temp check
      if (recommParams.declineModel === "HYP" && !recommParams.b) return;
      showLoadingIcon && showLoadingIcon();

      getDcaForecast(
        recommParams,
        (result) => {
          const forecastObj = result.dca;
          // just for test
          setRecommForecastData(forecastObj.predicted);

          // Add this result to Eur recomm, Eur: {...oldEur, allPhases:{'Oil': {...}, 'Gas': {...}}}
          // set value got(RECOMM)
          let recommEurData = getEurData(result, "RECOMM");
          dispatch(
            setRecommEurDataByPhase({
              phase: declinePhase,
              eurData: recommEurData,
            })
          );
          dispatch(
            setUserEurDataByPhase({
              phase: declinePhase,
              eurData: {
                eurUser: recommEurData.eurRecomm,
                periodPrdUser: recommEurData.periodPrdRecomm,
              },
            })
          );

          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          console.error("dca recomm error====>", error);
          hideLoadingIcon && hideLoadingIcon();
        }
      );

      let declineRate = parseFloatValue(dcaUserParams.declineRate);
      if (declineRate === 0) {
        return;
      }

      if (!dcaUserParams.declineModel) return;
      let userParams = {
        wellId: well_uwi,
        declinePhase: declinePhase,
        declineModel: dcaUserParams.declineModel, // || dcaRecommParams.declineModel,
        startDate: dcaCommonParams.startDate,
        forecastMonths: forecastLength,
        declineRate: dcaUserParams.declineRate,
        ipRatio: dcaUserParams.ipRatio,
        b: dcaUserParams.b,
      };

      if (
        userParams.declineModel === "HYP" &&
        (!userParams.b || parseFloat(userParams.b) === 0.0)
      ) {
        console.error("Wrong parameters with HYP, b is zero!");
        return;
      }

      getDcaForecast(
        userParams,
        (result) => {
          const forecastObj = result.dca;
          // just for test
          setUserDcaForecastData(forecastObj.predicted);

          // Add this result to Eur user arps
          // set value got (USER)
          let userEurData = getEurData(result, "USER");
          dispatch(
            setUserEurDataByPhase({
              phase: declinePhase,
              eurData: userEurData,
            })
          );
        },
        (error) => {
          console.error("dca user error====>", error);
        }
      );
    },
    // eslint-disable-next-line
    [
      dispatch,
      dcaCommonParams,
      dcaRecommParams,
      dcaUserParams,
      hideLoadingIcon,
      showLoadingIcon,
    ]
  );

  const getAllPhaseEurData = useCallback(
    (well_uwi, declinePhase, forecastLength) => {
      // Get other phases data and add to Eur recomm
      // GetDcaForecast(phase), then compute, then set value(RECOMM)
      DECLINE_PHASE_OPTIONS.filter((op) => op.value !== declinePhase).forEach(
        (item) => {
          const thisPhase = item.value;
          const thisDcaCommonParams = dcaAllCommonParams[thisPhase];
          const thisDcaRecommParams = dcaAllRecommParams[thisPhase];
          if (thisDcaRecommParams.declineModel == null) {
            getRecommData(
              {
                wellId: well_uwi,
                declinePhase: thisPhase,
                validationMonths: thisDcaCommonParams.validationMonths,
              },
              (result) => {
                const { b, di, qi, best_model } = result?.dca ?? {};

                const date = result?.dca?.fitted[0][0] ?? null;
                let startDate = date && date.slice(0, 10);
                dispatch(
                  setDcaCommonParamsByPhase({
                    phase: thisPhase,
                    dcaParams: {
                      ...thisDcaCommonParams,
                      startDate: startDate || thisDcaCommonParams.startDate,
                    },
                  })
                );
                dispatch(
                  setDcaRecommParamsByPhase({
                    phase: thisPhase,
                    dcaParams: {
                      declineModel: best_model,
                      declineRate: qi,
                      ipRatio: di,
                      b: b,
                    },
                  })
                );
                getDcaForecast(
                  {
                    wellId: well_uwi,
                    forecastMonths: forecastLength,
                    startDate: startDate,
                    declineModel: best_model,
                    declinePhase: thisPhase,
                    declineRate: qi,
                    ipRatio: di,
                    b: b,
                  },
                  (result) => {
                    let recommEurData = getEurData(result, "RECOMM");
                    dispatch(
                      setRecommEurDataByPhase({
                        phase: thisPhase,
                        eurData: recommEurData,
                      })
                    );
                    dispatch(
                      setUserEurDataByPhase({
                        phase: thisPhase,
                        eurData: {
                          eurUser: recommEurData.eurRecomm,
                          periodPrdUser: recommEurData.periodPrdRecomm,
                        },
                      })
                    );
                  },
                  (error) => {
                    console.error("dca recomm error====>", error);
                  }
                );
              },
              (error) => {
                console.warn("Init recommend data error!", error);
              }
            );
          } else {
            getDcaForecast(
              {
                declinePhase: thisPhase,
                wellId: well_uwi,
                declineModel: thisDcaRecommParams.declineModel,
                startDate: thisDcaCommonParams.startDate,
                forecastMonths: forecastLength,
                declineRate: thisDcaRecommParams.declineRate,
                ipRatio: thisDcaRecommParams.ipRatio,
                b: thisDcaRecommParams.b,
              },
              (result) => {
                let recommEurData = getEurData(result, "RECOMM");
                dispatch(
                  setRecommEurDataByPhase({
                    phase: thisPhase,
                    eurData: recommEurData,
                  })
                );
                const thisDcaUserParams = dcaAllUserParams[thisPhase];
                if (thisDcaUserParams.declineModel == null) {
                  dispatch(
                    setUserEurDataByPhase({
                      phase: thisPhase,
                      eurData: {
                        eurUser: recommEurData.eurRecomm,
                        periodPrdUser: recommEurData.periodPrdRecomm,
                      },
                    })
                  );
                }
              },
              (error) => {
                console.error("dca recomm error====>", error);
              }
            );
          }
        }
      );

      // Get other phases data and add to Eur user arps
      // GetDcaForecast(phase), then compute, then set value(USER)
      DECLINE_PHASE_OPTIONS.filter((op) => op.value !== declinePhase).forEach(
        (item) => {
          const thisPhase = item.value;
          const thisDcaCommonParams = dcaAllCommonParams[thisPhase];
          // const thisDcaRecommParams = dcaAllRecommParams[thisPhase];
          const thisDcaUserParams = dcaAllUserParams[thisPhase];

          if (thisDcaUserParams.declineModel == null) {
            return;
          }
          getDcaForecast(
            {
              wellId: well_uwi,
              declinePhase: thisPhase,
              declineModel: thisDcaUserParams.declineModel,
              startDate: thisDcaCommonParams.startDate,
              forecastMonths: forecastLength,
              declineRate: thisDcaUserParams.declineRate,
              ipRatio: thisDcaUserParams.ipRatio,
              b: thisDcaUserParams.b,
            },
            (result) => {
              let userEurData = getEurData(result, "USER");
              dispatch(
                setUserEurDataByPhase({
                  phase: thisPhase,
                  eurData: userEurData,
                })
              );
            },
            (error) => {
              console.error("dca recomm error====>", error);
            }
          );
        }
      );
    },
    [dispatch, dcaAllCommonParams, dcaAllRecommParams, dcaAllUserParams]
  );

  useEffect(() => {
    let wellUwi = activeWell?.uwi;
    if (!wellUwi || forecastMonths == null) {
      return;
    }
    getAllPhaseEurData(wellUwi, declinePhase, forecastMonths);
  }, [activeWell, forecastMonths, declinePhase, getAllPhaseEurData]);

  useEffect(() => {
    // setHistoricalData(null);
    setRecommForecastData(null);
    setUserDcaForecastData(null);

    // let wellUwi = activeWell?.uwi || wellId;
    let wellUwi = activeWell?.uwi;
    if (!wellUwi || forecastMonths == null) {
      return;
    }
    // if (!dcaCommonParams?.startDate && !startDate) {
    if (!dcaCommonParams.startDate) {
      return;
    }

    forecast(wellUwi, declinePhase, forecastMonths);
    // getAllPhaseEurData(wellUwi, declinePhase, forecastMonths);
  }, [
    activeWell,
    // wellId,
    forecastMonths,
    declinePhase,
    dcaCommonParams,
    // startDate,
    forecast,
    // getAllPhaseEurData,
  ]);

  useEffect(() => {
    if (!declinePhase || !recommForecastData) return;

    let xSeries = recommForecastData.map((item) => item[0].slice(0, 10));
    let dataSeries = [];
    dataSeries.push({
      "Auto-regression": {
        data: recommForecastData.map((item) => item[1]),
        lineType: "line",
        unit: { oil: "bbl/m", gas: "Mscf/m", liquid: "bbl/m" }[declinePhase],
      },
    });
    if (userDcaForecastData) {
      dataSeries.push({
        "user-Arp's": {
          data: userDcaForecastData.map((item) => item[1]),
          lineType: "line",
        },
      });
    }
    setSeriesData({
      xSeries,
      dataSeries,
      config: {
        multiYAxis: false,
        startColorIndex: 1,
        leftYAxisLabel: DECLINE_PHASE_OPTIONS.find(
          (item) => item.value === declinePhase
        ).name,
        title: noHeaderAndFooter ? "" : activeWell?.well_name + " Forecast",
      },
    });
  }, [
    recommForecastData,
    userDcaForecastData,
    activeWell,
    declinePhase,
    noHeaderAndFooter,
  ]);

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(setDeclinePhase(value));
    },
    [dispatch]
  );

  function handleMonthsChange(e) {
    const value = e.target.value;
    setForecastMonths(value);
  }

  function handleSaveForecastData() {
    const projectParams = {
      well_uwi: activeWell.uwi,
      records: [],
    };

    for (let phase of ["oil", "gas", "liquid"]) {
      let recommEurData = eurRecommDataAllPhase[phase];
      let userEurData = eurUserDataAllPhase[phase];
      const record = {
        decline_phase: phase,
        // trained_ml_model: null,
        forecast_length: forecastMonths,
        eur_ml: 0,
        eur_recomm_dca: recommEurData?.eurRecomm || 0,
        eur_user_dca: userEurData?.eurUser || 0,
        period_prd_ml: 0,
        period_prd_recomm_dca: recommEurData?.periodPrdRecomm || 0,
        period_prd_user_dca: userEurData?.periodPrdUser || 0,
      };
      projectParams.records.push(record);
    }

    props.submitDcaProject(projectParams);
  }

  const phasesSelect = () => {
    return DECLINE_PHASE_OPTIONS.map((item, index) => {
      const elemId = "fst_phase_opt_" + index.toString();
      return (
        <span key={index}>
          <input
            className="btn-check"
            id={elemId}
            type="radio"
            name="forecast_phase"
            value={item.value}
            onChange={handleChange}
            checked={declinePhase === item.value}
            // disabled={!seriesData}
          />
          <label htmlFor={elemId} className="btn-label header">
            {item.name}
          </label>
        </span>
      );
    });
  };

  return (
    <div className="cards-container h-100">
      {!noHeaderAndFooter && (
        <div className="cards-header d-flex flex-row justify-content-between align-items-center">
          <span className="header-title">Forecast</span>
          <div className="header-menu-container d-flex flex-row">
            <div>
              <span>Decline Phase :</span>
              {phasesSelect()}
            </div>
            <div className="cards-setting-btn ms-2">
              <SectionSetting disable />
            </div>
          </div>
        </div>
      )}
      <div className="cards-content d-flex flex-column justify-content-between align-items-stretch">
        <div className="forecast-upper-part">
          <DcaManagementHistoryChart data={seriesData} />
        </div>
        {!noHeaderAndFooter && (
          <div className="forecast-lower-part d-flex flex-column mt-2">
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div className="d-flex align-items-center">
                <label className="me-1">Forecast Period:</label>
                <input
                  value={forecastMonths}
                  name="months"
                  type="number"
                  onChange={handleMonthsChange}
                />
                <span className="ms-2">Months</span>
              </div>
              <button
                className="btn-custom"
                onClick={(e) => {
                  dispatch(setShowEurParamsPanel(true));
                }}
              >
                EUR
              </button>
              <button
                className="btn-custom"
                style={{ minWidth: "8em" }}
                onClick={handleSaveForecastData}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForecastChart;
