import React, { useEffect, useState } from "react";

import { getProductionPrescriptive } from "service/productionData";
import DcaManagementHistoryChart from "./DcaManagementHistoryChart";

function PredictChart(props) {
  const { model, wellId, assetId } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  // computed dca data with default dca parameters
  const [seriesData, setSeriesData] = useState({});

  useEffect(() => {
    if (model == null || (wellId == null && assetId == null)) {
      return;
    }

    refreshData();
  }, [model, wellId, assetId]);

  function refreshData() {
    let params = {
      decline_phase: model.value,
    };
    if (wellId != null) params.uwi = wellId;
    else if (assetId != null) params.asset_id = assetId;

    showLoadingIcon && showLoadingIcon();
    getProductionPrescriptive(
      params,
      (result) => {
        updateRecommDcaData(result);
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        console.error("dca forecast error====>", error);
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }

  function updateRecommDcaData(result) {
    if (result == null) {
      return;
    }

    const forecastObj = result;
    setSeriesData({
      xSeries: result.map(item => item[0]),
      dataSeries: [{
        "Prescriptive": {
          data: forecastObj.map(item => item[1]),
          unit: ({ "oil": "bbl", "gas": "mscf", "liquid": "bbl" })[model.value],
        }
      }],
      config: {
        multiYAxis: false,
        xAxisLabel: 'Date',
        leftYAxisLabel: model.name,
        title: '',
      }
    });
  }

  return (
    <div className="model-chart-wrapper h-100">
      <DcaManagementHistoryChart data={seriesData} />
    </div>
  );
}

export default PredictChart;
