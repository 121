import { Checkbox, Form, Radio, Select } from 'antd'
import FormSection from 'components/form-section'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import GenericSettings from './StageSetting/GenericSettings'
import FractureVisualizer from './FractureVisualizer'
import CustomizedSettings from './StageSetting/CustomizedSettings'
import Swal from 'sweetalert2'
import { useQuery } from '@tanstack/react-query'
import { getCustomizedModels } from 'service/completionOptimizer'

const HydraulicFractureSetting = () => {
  const form = Form.useFormInstance()
  const [currentStage, setCurrentStage] = useState(0)
  const [currentStageValues, setCurrentStageValues] = useState(() => {
    const value = form.getFieldValue(['Completion', 'hydraulic_fracture', currentStage])
    return value
  })
  const [isStageSynced, setIsStageSynced] = useState(currentStageValues?.__synced ?? false)

  const numberOfStages = Form.useWatch(['Completion', 'well', 'NUMBER_STAGES'])
  const currentStageModel = Form.useWatch(['Completion', 'hydraulic_fracture', currentStage, 'model'])

  const stageSelections = useMemo(() => {
    return Array(numberOfStages ?? 0).fill(null).map((_, index) => ({
      value: index,
      label: `Stage ${ index + 1 }`
    }))
  }, [numberOfStages])

  const { data: customizedModels } = useQuery({
    queryKey: ['getCustomizedModels'],
    queryFn: () => getCustomizedModels()
  })

  useEffect(() => {
    if (!currentStageValues?.__synced) return

    const stageValues = form.getFieldValue(['Completion', 'hydraulic_fracture'])
    const syncValues = stageValues?.find(stage => stage.__synced)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage], syncValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStageValues?.__synced])

  const onSync = useCallback((e) => {
    const { checked } = e.target
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, '__synced'], checked)
    setIsStageSynced(checked)
    if (!checked) return
    const stageValues = form.getFieldValue(['Completion', 'hydraulic_fracture'])
    const syncValues = stageValues?.find((stage, index) => index !== currentStage && stage.__synced)
    if (!syncValues) return
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'STAGE_LEN'], syncValues.STAGE_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'NUMBER_CLUSTERS'], syncValues.NUMBER_CLUSTERS)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SAND_USAGE'], syncValues.SAND_USAGE)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'FRACTURE_HALF_LEN'], syncValues.PRIMARY.FRACTURE_HALF_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'VERTICAL_PENETRATION'], syncValues.PRIMARY.VERTICAL_PENETRATION)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'FRACTURE_WIDTH'], syncValues.PRIMARY.FRACTURE_WIDTH)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'FRACTURE_HALF_LEN'], syncValues.SECONDARY.FRACTURE_HALF_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'VERTICAL_PENETRATION'], syncValues.SECONDARY.VERTICAL_PENETRATION)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'FRACTURE_WIDTH'], syncValues.SECONDARY.FRACTURE_WIDTH)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'model_name'], syncValues.CUSTOMIZED?.model_name)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'youngs_module'], syncValues.CUSTOMIZED?.geomechanics?.youngs_module)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'poisson_ratio'], syncValues.CUSTOMIZED?.geomechanics?.poisson_ratio)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'fracture_toughness'], syncValues.CUSTOMIZED?.geomechanics?.fracture_toughness)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'k_ic_reference'], syncValues.CUSTOMIZED?.geomechanics?.k_ic_reference)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'formation_thinkness'], syncValues.CUSTOMIZED?.geomechanics?.formation_thinkness)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'kic_name'], syncValues.CUSTOMIZED?.geomechanics?.kic_name)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'stage_length'], syncValues.CUSTOMIZED?.custom_completion?.stage_length)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'number_cluster'], syncValues.CUSTOMIZED?.custom_completion?.number_cluster)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'perf_each_cluster'], syncValues.CUSTOMIZED?.custom_completion?.perf_each_cluster)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'perf_diameter'], syncValues.CUSTOMIZED?.custom_completion?.perf_diameter)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'perf_angle'], syncValues.CUSTOMIZED?.custom_completion?.perf_angle)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'fluid_viscosity'], syncValues.CUSTOMIZED?.treatemnt?.fluid_viscosity)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'pumping_time'], syncValues.CUSTOMIZED?.treatemnt?.pumping_time)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'sand_usage'], syncValues.CUSTOMIZED?.treatemnt?.sand_usage)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'leakOff_coe'], syncValues.CUSTOMIZED?.treatemnt?.leakOff_coe)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'pumping_rate'], syncValues.CUSTOMIZED?.treatemnt?.pumping_rate)
  }, [currentStage, form])

  const onCopy = useCallback((fromIndex) => {
    const fromValues = form.getFieldValue(['Completion', 'hydraulic_fracture', fromIndex])
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'STAGE_LEN'], fromValues?.STAGE_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'NUMBER_CLUSTERS'], fromValues?.NUMBER_CLUSTERS)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SAND_USAGE'], fromValues?.SAND_USAGE)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'FRACTURE_HALF_LEN'], fromValues?.PRIMARY.FRACTURE_HALF_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'VERTICAL_PENETRATION'], fromValues?.PRIMARY.VERTICAL_PENETRATION)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'PRIMARY', 'FRACTURE_WIDTH'], fromValues?.PRIMARY.FRACTURE_WIDTH)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'FRACTURE_HALF_LEN'], fromValues?.SECONDARY.FRACTURE_HALF_LEN)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'VERTICAL_PENETRATION'], fromValues?.SECONDARY.VERTICAL_PENETRATION)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'SECONDARY', 'FRACTURE_WIDTH'], fromValues?.SECONDARY.FRACTURE_WIDTH)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'model_name'], fromValues.CUSTOMIZED?.model_name)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'youngs_module'], fromValues.CUSTOMIZED?.geomechanics?.youngs_module)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'poisson_ratio'], fromValues.CUSTOMIZED?.geomechanics?.poisson_ratio)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'fracture_toughness'], fromValues.CUSTOMIZED?.geomechanics?.fracture_toughness)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'k_ic_reference'], fromValues.CUSTOMIZED?.geomechanics?.k_ic_reference)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'formation_thinkness'], fromValues.CUSTOMIZED?.geomechanics?.formation_thinkness)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'geomechanics', 'kic_name'], fromValues.CUSTOMIZED?.geomechanics?.kic_name)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'stage_length'], fromValues.CUSTOMIZED?.custom_completion?.stage_length)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'number_cluster'], fromValues.CUSTOMIZED?.custom_completion?.number_cluster)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'perf_each_cluster'], fromValues.CUSTOMIZED?.custom_completion?.perf_each_cluster)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'perf_diameter'], fromValues.CUSTOMIZED?.custom_completion?.perf_diameter)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'custom_completion', 'perf_angle'], fromValues.CUSTOMIZED?.custom_completion?.perf_angle)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'fluid_viscosity'], fromValues.CUSTOMIZED?.treatemnt?.fluid_viscosity)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'pumping_time'], fromValues.CUSTOMIZED?.treatemnt?.pumping_time)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'sand_usage'], fromValues.CUSTOMIZED?.treatemnt?.sand_usage)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'leakOff_coe'], fromValues.CUSTOMIZED?.treatemnt?.leakOff_coe)
    form.setFieldValue(['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'treatemnt', 'pumping_rate'], fromValues.CUSTOMIZED?.treatemnt?.pumping_rate)
  }, [currentStage, form])

  return (
    <div className="row">
      <div className="col-6">
        <FormSection
          title="Hydraulic fracture Stage Setting"
          extra={(
            <Checkbox checked={isStageSynced} onChange={onSync}>Sync with every Stage</Checkbox>
          )}
        >
          <div className="vstack gap-4">
            <div className="row">
              {/* <Form.Item name={['Completion', 'hydraulic_fracture', currentStage, 'model']}> */}
              <Radio.Group value={currentStageModel || 'GENERIC'} onChange={async (e) => {
                const confirmResult = await Swal.fire({
                  title: 'Alert',
                  text: 'Switching model will discard current stage data',
                  confirmButtonText: 'OK',
                  cancelButtonText: 'Cancel',
                  showCancelButton: true,
                  confirmButtonColor: '#1677ff',
                  reverseButtons: true
                })
                if (confirmResult.isConfirmed) {
                  stageSelections.forEach(opt => {
                    form.setFieldValue(['Completion', 'hydraulic_fracture', opt.value, 'model'], e.target.value)
                  })
                }
              }}>
                <Radio value="GENERIC">Generic model</Radio>
                <Radio value="CUSTOMIZED">Customized model (dropdown menu)</Radio>
              </Radio.Group>
              {/* </Form.Item> */}
            </div>
            {currentStageModel === 'CUSTOMIZED' ? (
              <Form.Item label="Select customized model" name={['Completion', 'hydraulic_fracture', currentStage, 'CUSTOMIZED', 'model_name']}>
                <Select options={customizedModels?.map(m => ({
                  value: m.model_name,
                  label: m.model_name
                }))} />
              </Form.Item>
            ) : null}
            <div className="row">
              <div className="col-6">
                <Form.Item label="Select Stage">
                  <Select options={stageSelections} value={currentStage} onChange={(value) => {
                    const nextStageValue = form.getFieldValue(['Completion', 'hydraulic_fracture', value])
                    setCurrentStage(value)
                    setCurrentStageValues(nextStageValue)
                    setIsStageSynced(nextStageValue?.__synced ?? false)
                  }} />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item label="Copy from">
                  <Select options={stageSelections.filter(option => option.value !== currentStage)} onChange={onCopy} />
                </Form.Item>
              </div>
            </div>
            <Form.List name={['Completion', 'hydraulic_fracture']}>
              {(fields) => fields.map((field) => {
                if (field.name !== currentStage) return null
                if (currentStageModel === 'CUSTOMIZED') return <CustomizedSettings key={field.name} stageIndex={field.name} isActive />
                return <GenericSettings key={field.name} stageIndex={field.name} isActive />
              })}
            </Form.List>
          </div>
        </FormSection>
      </div>
      <div className="col-6 h-fit">
        <FractureVisualizer />
      </div>
    </div>
  )
}

export default HydraulicFractureSetting