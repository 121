import React from 'react'
import CommodityPrices from './CommodityPrices'
import { Tabs } from 'antd'

import classes from './economic-basics.module.scss'

/**
 * @type {import('antd').TabsProps['items']}
 */
const tabs = [
  {
    key: 'commodity_price',
    label: 'Commodity Price',
    children: <CommodityPrices />
  },
  {
    key: 'tax',
    label: 'Tax',
  }
]

const EconomicBasics = () => {
  return (
    <div className="p-2">
      <Tabs className={classes.tabs} type="card" items={tabs} />
    </div>
  )
}

export default EconomicBasics