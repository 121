import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectActiveRoutes, setActiveRoutes } from "features/nav/routesSlice";

function SubMenu(props) {
  const { route } = props;
  const activeRoutes = useSelector(selectActiveRoutes);
  const [active, setActive] = useState(activeRoutes.includes(route.id));
  const dispatch = useDispatch();

  function handleMenuClick(e) {
    if (route.routes && route.routes.length > 0) {
      let flag = !active;
      setActive(flag);

      let tempActiveRoutes = [...activeRoutes];
      if (flag) {
        tempActiveRoutes.push(route.id);
      } else {
        tempActiveRoutes = activeRoutes.filter((item) => item !== route.id);
      }
      dispatch(setActiveRoutes(tempActiveRoutes));
      e.preventDefault();
    }
  }

  let menuClass = "";
  let activeClass = "";
  if (route.level === 0 && route.routes && route.routes.length > 0) {
    menuClass = "has-arrow";
    if (active) {
      activeClass = "mm-active";
    }
  }

  return (
    <li key={route.id} className={activeClass}>
      <a onClick={handleMenuClick} href={route.link} className={menuClass}>
        {route.iconCls ? <i className={route.iconCls}></i> : <></>}
        <span>{route.title}</span>
      </a>
      {active && <ul className="sub-menu">{props.children}</ul>}
    </li>
  );
}

export default SubMenu;
