import { useCallback, useEffect, useRef, useState } from "react";
import { EditableProTable } from "@ant-design/pro-components";
import { useToggle } from '@uidotdev/usehooks'
import { Button, Tooltip } from "antd";
import enUS from "antd/locale/en_US";
import { ConfigProvider } from "antd";
import Title from "antd/es/typography/Title";
import { USER_PREDICT_DATA_FIELDS } from "./data";

import {
  addCommodityPrice,
  updateCommodityPrice,
  deleteCommodityPrice,
} from "service/commodity";

import classes from './user-predict-data-editor.module.scss'
import UserPredictDataImportModal from "./UserPredictDataImportModal";
import classNames from "classnames";
import { formatDate } from "utils/dateUtil";
import { isValid } from "date-fns";

const title = "User Prediction";

const UserPredictDataEditor = (props) => {
  const { userSeries, dataChanged, onRefetch } = props;

  const importFileInput = useRef(null)

  const [dataSource, setDataSource] = useState([]);
  const [editableKeys, setEditableRowKeys] = useState([]);
  /**
   * @type {[File, React.Dispatch<File>]}
   */
  const [csvFile, setCSVFile] = useState(null)
  const [isImportModalOpen, toggleImportModal] = useToggle(false)
  const [isImporting, setImporting] = useState(false)

  const series = USER_PREDICT_DATA_FIELDS.filter((field) =>
    field.value !== "date"
  );
  const columns = series.map((field) => {
    return {
      title: `${ field.label } (${ field.unit })`,
      dataIndex: field.value,
      key: field.value,
    };
  });

  const editorColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      editable: true,
      render: (date) => {
        return date && isValid(date) ? formatDate(date, 'yyyy-MM') : date
      }
    },
    ...columns,
    ...[
      {
        title: "Operation",
        valueType: "option",
        key: "operation",
        width: 160,
        render: (text, record, _, action) => [
          <Button
            key="editable"
            onClick={() => {
              action?.startEditable?.(record.id);
            }}
          >
            Edit
          </Button>,
          <Button
            key="delete"
            onClick={(a, b, c) => {
              deleteCommodityPrice(
                [record.id],
                (res) => { },
                (err) => { }
              );
              let newRecords = dataSource.filter(
                (item) => item.id !== record.id
              );
              setDataSource(newRecords);
              dataChanged && dataChanged(newRecords);
            }}
          >
            Delete
          </Button>,
        ],
      },
    ],
  ];

  useEffect(() => {
    if (userSeries && userSeries.length > 0) {
      setDataSource([...userSeries]);
    }
  }, [userSeries]);

  const onTriggerImport = useCallback(() => {
    if (!importFileInput.current) return;
    importFileInput.current.value = null
    importFileInput.current.click()
  }, [])

  const onCSVFileChange = useCallback((e) => {
    const { target: {
      files
    } } = e

    const file = files[0]
    setCSVFile(file ?? null)
    toggleImportModal(true)
  }, [toggleImportModal])

  const onCloseImportModal = useCallback(() => {
    setCSVFile(null)
    toggleImportModal(false)
  }, [toggleImportModal])

  const onImportData = useCallback(async (data) => {
    try {
      setImporting(true)
      const idsToDelete = dataSource.map(record => record.id)
      if (idsToDelete.length) await deleteCommodityPrice(idsToDelete, undefined, undefined, true)
      await addCommodityPrice(data)
      onRefetch?.()
      onCloseImportModal()
    } finally {
      setImporting(false)
    }
  }, [dataSource, onCloseImportModal, onRefetch])

  return (
    <ConfigProvider
      locale={enUS}
      componentSize="middle"
      theme={{
        token: {},
        components: {},
      }}
    >
      <UserPredictDataImportModal
        open={isImportModalOpen}
        file={csvFile}
        isImporting={isImporting}
        onImport={onImportData}
        onClose={onCloseImportModal}
      />
      <div className={classNames('mx-4 hstack justify-content-between', classes.tableHeader)}>
        <Title level={5} className="text-light">{title}</Title>
        <div className="d-flex">
          <Tooltip
            placement="top"
            title={`CSV file format should be: ${ USER_PREDICT_DATA_FIELDS.map(field => field.label).join(', ') }. The first row should be header`}
          >
            <Button onClick={onTriggerImport}>Import CSV</Button>
            <input ref={importFileInput} type="file" accept="text/csv" onChange={onCSVFileChange} hidden />
          </Tooltip>
        </div>
      </div>
      <EditableProTable
        columns={editorColumns || []}
        options={false}
        pagination={false}
        search={false}
        rowKey="id"
        sticky={{
          offsetHeader: 32
        }}
        recordCreatorProps={{
          record: () => {
            const newId = 88 * 1000000 + (Math.random() * 1000000).toFixed(0);
            return { id: newId, isNewRecord: true };
          },
        }}
        value={dataSource || []}
        onChange={(values) => {
          setDataSource(values);
        }}
        editable={{
          type: "single",
          editableKeys,
          onChange: setEditableRowKeys,
          onSave: async (rowKey, data, row) => {
            let oldId = data.id;
            if (data.isNewRecord) {
              let newRecord = { ...data };
              delete newRecord.isNewRecord;
              delete newRecord.index;
              delete newRecord.id;
              addCommodityPrice(
                newRecord,
                (res) => {
                  let newRecords = dataSource.filter(
                    (record) => record.id !== oldId
                  );
                  newRecords.push(res);
                  setDataSource(newRecords);
                  dataChanged && dataChanged(res, true);
                },
                (err) => { }
              );
            } else {
              let newRecord = { ...data };
              let oldIndex = newRecord.index;
              delete newRecord.index;
              updateCommodityPrice(
                newRecord,
                (res) => {
                  let newRecords = [...dataSource];
                  newRecords[oldIndex] = res;
                  setDataSource(newRecords);
                  dataChanged && dataChanged(res);
                },
                (err) => { }
              );
            }
          },
        }}
        className={classes.tableContainer}
        cardProps={{
          className: classes.card
        }}
        tableClassName={classes.table}
      />
    </ConfigProvider>
  );
};

export default UserPredictDataEditor;
