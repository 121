import { Form, Input, InputNumber } from 'antd'
import classNames from 'classnames'
import FormSection from 'components/form-section'
import React, { useEffect } from 'react'

const units = {
  stageLength: {
    FIELD: '(ft)',
    METRIC: '(m)'
  },
  sandUsage: {
    FIELD: '(lb)',
    METRIC: '(kg)'
  },
  fractureHalfLength: {
    FIELD: '(ft)',
    METRIC: '(m)'
  },
  verticalPenetration: {
    FIELD: '(ft)',
    METRIC: '(m)'
  },
  fractureWidth: {
    FIELD: '(in)',
    METRIC: '(cm)'
  },
}

const GenericSettings = ({ stageIndex, isActive }) => {
  const form = Form.useFormInstance()
  const currentStageValues = Form.useWatch(['Completion', 'hydraulic_fracture', stageIndex])
  const simulationUnit = Form.useWatch(["GENERAL", "unit"])

  useEffect(() => {
    if (!currentStageValues?.__synced) return
    const stageValues = form.getFieldValue(['Completion', 'hydraulic_fracture'])
    const syncedStages = stageValues?.filter((stage, index) => index !== stageIndex && stage.__synced) ?? []
    syncedStages.forEach((_, index) => {
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'STAGE_LEN'], currentStageValues.STAGE_LEN)
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'NUMBER_CLUSTERS'], currentStageValues.NUMBER_CLUSTERS)
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'SAND_USAGE'], currentStageValues.SAND_USAGE)
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'PRIMARY', 'FRACTURE_HALF_LEN'], currentStageValues.PRIMARY.FRACTURE_HALF_LEN)
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'PRIMARY', 'VERTICAL_PENETRATION'], currentStageValues.PRIMARY.VERTICAL_PENETRATION)
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'PRIMARY', 'FRACTURE_WIDTH'], currentStageValues.PRIMARY.FRACTURE_WIDTH)
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'SECONDARY', 'FRACTURE_HALF_LEN'], currentStageValues.SECONDARY.FRACTURE_HALF_LEN)
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'SECONDARY', 'VERTICAL_PENETRATION'], currentStageValues.SECONDARY.VERTICAL_PENETRATION)
      form.setFieldValue(['Completion', 'hydraulic_fracture', index, 'SECONDARY', 'FRACTURE_WIDTH'], currentStageValues.SECONDARY.FRACTURE_WIDTH)
    })
  }, [currentStageValues, form, stageIndex])

  return (
    <div key={stageIndex} className={classNames('vstack gap-4', {
      'd-none': !isActive
    })}>
      <FormSection
        title="Operation"
      >
        <div className="row">
          <div className="col-6">
            <div className="vstack gap-2">
              <Form.Item name={[stageIndex, 'STAGE_LEN']} label={`Stage Length ${ units.stageLength[simulationUnit] }`}>
                <InputNumber className="w-100" />
              </Form.Item>
              <Form.Item name={[stageIndex, 'NUMBER_CLUSTERS']} label="Number of clusters">
                <InputNumber className="w-100" />
              </Form.Item>
            </div>
          </div>
          <div className="col-6">
            <Form.Item name={[stageIndex, 'SAND_USAGE']} label={`Sand Usage ${ units.sandUsage[simulationUnit] }`}>
              <InputNumber className="w-100" />
            </Form.Item>
          </div >
        </div >
      </FormSection >
      <FormSection
        title="Fracture Assumption"
      >
        <div className="row">
          <div className="col-6">
            <FormSection title="Primary">
              <div className="vstack">
                <Form.Item name={[stageIndex, 'PRIMARY', 'FRACTURE_HALF_LEN']} label={`Fracture Half length ${ units.fractureHalfLength[simulationUnit] }`}>
                  <Input />
                </Form.Item>
                <Form.Item name={[stageIndex, 'PRIMARY', 'VERTICAL_PENETRATION']} label={`Vertical Penetration ${ units.verticalPenetration[simulationUnit] }`}>
                  <Input />
                </Form.Item>
                <Form.Item name={[stageIndex, 'PRIMARY', 'FRACTURE_WIDTH']} label={`Fracture width ${ units.fractureWidth[simulationUnit] }`}>
                  <Input />
                </Form.Item>
              </div>
            </FormSection>
          </div>
          <div className="col-6">
            <FormSection title="Secondary">
              <div className="vstack">
                <Form.Item name={[stageIndex, 'SECONDARY', 'FRACTURE_HALF_LEN']} label={`Fracture Half length ${ units.fractureHalfLength[simulationUnit] }`}>
                  <InputNumber className="w-100" />
                </Form.Item>
                <Form.Item name={[stageIndex, 'SECONDARY', 'VERTICAL_PENETRATION']} label={`Vertical Penetration ${ units.verticalPenetration[simulationUnit] }`}>
                  <InputNumber className="w-100" />
                </Form.Item>
                <Form.Item name={[stageIndex, 'SECONDARY', 'FRACTURE_WIDTH']} label={`Fracture width ${ units.fractureWidth[simulationUnit] }`}>
                  <InputNumber className="w-100" />
                </Form.Item>
              </div>
            </FormSection>
          </div>
        </div>
      </FormSection>
    </div >
  )
}

export default GenericSettings