import { ConfigProvider, Spin } from "antd";
import enUS from "antd/locale/en_US";
import { getAssets, saveAsset, updateAsset, deleteAsset } from "service/assets";
import EditorTable from "../component/EditorTable";
import useLoading from "components/common/useLoading";

const AssetColumns = [
  {
    title: "Asset Name",
    dataIndex: "asset_name",
    key: "asset_name",
    rule: [{ required: true }],
    editable: true,
  },
  {
    title: "Unit System",
    dataIndex: "unit_system",
    key: "unit_system",
    rule: [{ required: true }],
    editable: true,
    valueType: "select",
    valueEnum: {
      field: { text: "Field", status: "Field" },
      metric: { text: "Metric", status: "Metric" },
    },
  },
];

function WellAssetEditor(props) {
  const [LoadingIcon] = useLoading(<Spin size="large" />, 50);

  return (
    <div style={{ height: "91vh", overflowY: "auto" }}>
      <LoadingIcon />
      <ConfigProvider
        locale={enUS}
        componentSize="middle"
        theme={{
          token: {},
          components: {},
        }}
      >
        <EditorTable
          columns={AssetColumns || []}
          title="Well Asset"
          dataFetcher={[
            () => { return {};},
            (params, onSuccess, onError) => {
              getAssets(onSuccess, onError);
            },
          ]}
          dataSaver={[
            (rec, onSuccess, onError)=>{
              updateAsset(rec.id, rec, onSuccess, onError);
            }, // update
            (rec, row) => {
              const record = { ...rec };
              delete record.index;
              return record;
            },
            saveAsset,
            (rec, row) => {
              const record = { ...rec };
              delete record.index;
              delete record.id;
              return record;
            },
          ]}
          dataRemover={[deleteAsset, (record) => record.id]}
        />
      </ConfigProvider>
    </div>
  );
}

export default WellAssetEditor;
