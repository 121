export const PARAM_NAMES = {
  oil: { name: "WTI Oil", unit: "$/bbl" },
  ng: { name: "Henry Hub Gas", unit: "$/MSCF" },
  oil_predict: { name: "WTI Futures", unit: "$/bbl" },
  ng_predict: { name: "Henry Hub Futures", unit: "$/MSCF" },
  oil_user_predict: { name: "User Oil", unit: "$/bbl" },
  ng_user_predict: { name: "User Gas", unit: "$/MSCF" },
  ngl: { name: "NGL", unit: "$/gln" },
  ngl_user_predict: { name: "User NGL", unit: "$/gln" },
};

export const USER_PREDICT_DATA_FIELDS = [
  {
    value: 'date',
    label: 'Date',
  },
  {
    value: 'oil_user_predict',
    label: 'Oil',
    unit: "$/bbl"
  },
  {
    value: 'ng_user_predict',
    label: 'Gas',
    unit: "$/MSCF"
  },
  {
    value: 'ngl_user_predict',
    label: 'NGL',
    unit: "$/gln"
  }
]