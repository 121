import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { selectUserData } from "features/user/userSlice";

let AuthContext = React.createContext(null);

function AuthProvider(props) {
  let user = useSelector(selectUserData);
  let value = { user };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

function useAuth() {
  return React.useContext(AuthContext);
}

function isStaticFiles(location) {
  const path = location.pathname;
  //console.log("path", path);
  return path && path.startsWith('/static');
}

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user && !isStaticFiles(location)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login/" state={{ from: location }} replace />;
  }

  return children;
}

export { AuthProvider, useAuth, RequireAuth };
