import { get, post, remove } from "./base";

export const EVENT_STATUS_MAP = [
  { text: "Low", textColor: "#FF5454", bg: "rgba(255, 84, 84, 0.1)" },
  { text: "Medium", textColor: "#FFB800", bg: "gba(255, 184, 0, 0.1)" },
  { text: "High", textColor: "#FF5454", bg: "rgba(255, 84, 84, 0.1)" },
];

export function getAssetAlarms(assetsId, onSuccess, onError) {
  get(
    `/api/assets/${assetsId}/alarms`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function getAlarms(uwi, onSuccess, onError) {
  get(
    `/api/events/alarms/${uwi}`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function saveAlarms(alarms, onSuccess, onError) {
  post(
    "/api/events/alarms/",
    alarms,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError,
    true
  );
}

export function removeAlarms(
  eventIds,
  wellUwis,
  eventType,
  onSuccess,
  onError
) {
  let params = {};
  if (eventIds != null && eventIds.length > 0) {
    params.event_ids = eventIds;
  }
  if (wellUwis && wellUwis.length > 0) {
    params.well_uwis = wellUwis;
  }
  if (eventType != null) {
    params.event_type = eventType;
  }

  remove(
    "/api/events/alarms/",
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function getAssetWellEvents(assetsId, onSuccess, onError) {
  get(
    `/api/assets/${assetsId}/events`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function getWellEvents(uwi, onSuccess, onError) {
  get(
    `/api/events/wells/${uwi}`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function saveWellEvents(events, onSuccess, onError) {
  post(
    "/api/events/wells/",
    events,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError,
    true
  );
}

export function removeWellEvents(eventIds, wellUwis, onSuccess, onError) {
  let params = {};
  if (eventIds != null && eventIds.length > 0) {
    params.event_ids = eventIds;
  }
  if (wellUwis && wellUwis.length > 0) {
    params.well_uwis = wellUwis;
  }

  remove(
    "/api/events/wells/",
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function getAssetPlannings(assetsId, onSuccess, onError) {
  get(
    `/api/assets/${assetsId}/plannings`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function getPlanning(uwi, onSuccess, onError) {
  get(
    `/api/events/plannings/${uwi}`,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}

export function savePlanning(events, onSuccess, onError) {
  post(
    "/api/events/plannings/",
    events,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError,
    true
  );
}

export function removePlanning(
  eventIds,
  wellUwis,
  eventType,
  onSuccess,
  onError
) {
  let params = {};
  if (eventIds != null && eventIds.length > 0) {
    params.event_ids = eventIds;
  }
  if (wellUwis && wellUwis.length > 0) {
    params.well_uwis = wellUwis;
  }
  if (eventType != null) {
    params.event_type = eventType;
  }

  remove(
    "/api/events/plannings/",
    params,
    null,
    {},
    (res) => {
      onSuccess(res.data);
    },
    onError
  );
}
