import React, { useCallback, useMemo, useState } from "react";
import WellsSelector from "./WellsSelector";
import WellOwnershipTable from "./WellOwnershipTable";
import WellAfeTable from "./WellAfeTable";
import { Tabs } from "antd";

import classes from './economics-editor.module.scss'
import WellLoeTable from "./WellLoeTable";
import WellSalePrices from "./WellSalePrices";
import classNames from "classnames";

function WellEconomicsEditor(props) {
	const [wellId, setWellId] = useState(null);
	const [assetId, setAssetId] = useState(null)

	const onWellSelected = useCallback((wellId, assetId) => {
		setWellId(wellId);
		setAssetId(assetId)
	}, [])

	const tabs = useMemo(
		/**
		 * 
		 * @returns {import('antd').TabsProps['items']}
		 */
		() => {
			return [
				{
					key: 'ownership',
					label: 'Ownership',
					children: <WellOwnershipTable wellId={wellId} assetId={assetId} />
				},
				{
					key: 'afe',
					label: 'AFE',
					children: <WellAfeTable wellId={wellId} assetId={assetId} />
				},
				{
					key: 'loe',
					label: 'LOE',
					children: <WellLoeTable wellId={wellId} assetId={assetId} />
				},
				{
					key: 'sale_price',
					label: 'Sale Price',
					children: <WellSalePrices wellId={wellId} assetId={assetId} />
				}
			]
		},
		[assetId, wellId])

	return (
		<div className="row w-100 h-100 py-2" style={{
			overflowY: 'auto'
		}}>
			<div
				className="col-2 sticky-top"
			>
				<div>
					<WellsSelector onWellSelected={onWellSelected} />
				</div>
				{/* <div className="select-options-list">{actionOptions}</div> */}
			</div>
			<div className="col-10" style={{
				overflowY: 'hidden'
			}}>
				{wellId && assetId ? <Tabs type="card" items={tabs} className={classNames(classes.tabs, classes.parentTabs)} destroyInactiveTabPane /> : null}
			</div>
		</div>
	);
}

export default WellEconomicsEditor;
