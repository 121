import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import DcaManagementHistoryChart from "components/charts/DcaManagementHistoryChart";
import { selectActiveProject } from "features/project/projectsSlice";

import {
  selectActiveWell,
  setDeclinePhase,
  selectDeclinePhase,
  setManagementGlobalByPhase,
  selectManagementGlobal,
} from "features/decline_analysis/dcaManagementSlice";
import { getDcaManagementForecast } from "service/dcaAnalysis";
import DcaManagementForecastSetting from "./DcaManagementForecastSetting";
import DcaManagementEurPanel from "../DcaManagementEurPanel";
import SectionSetting from "components/common/SectionSetting";

import { PARAM_NAMES, DECLINE_PHASE_OPTIONS } from "./data";

function DcaManagementForecast(props) {
  const { submitDcaProject, loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const dispatch = useDispatch();
  const activeWell = useSelector(selectActiveWell);
  const activeProject = useSelector(selectActiveProject);
  const managementGlobal = useSelector(selectManagementGlobal);

  const declinePhase = useSelector(selectDeclinePhase);
  const currentGlobal = managementGlobal[declinePhase];
  const forecastSettings = currentGlobal.forecastSetting;

  const [seriesData, setSeriesData] = useState({});
  const [showEurPanel, setShowEurPanel] = useState(false);
  const [showForecastSetting, setShowForecastSetting] = useState(false);
  const [eurData, setEurData] = useState({
    eurAutoRegression: 0.0, // history + predict
    eurUserArps: 0.0, // history + user predict
    predictAutoRegression: 0.0, // only predict
    predictUserArps: 0.0, // only user predict
  });
  // const [forecastSetting, setForecastSetting] = useState({
  //   forecastMonths: 20,
  //   parameters: {},
  // });

  const reloadSeriesData = useCallback(
    (
      userAdjusted,
      well_name,
      uwi,
      declinePhase,
      startDate,
      forecastMonths,
      aiParams,
      aiParamValues
    ) => {
      // if (!userAdjusted && userAdjusted !== undefined) return;
      // get data according to date series, if some dates has no data, the result will filled with null
      const getDataByDate = (dataWithDate, dates) => {
        let result,
          dataPos = 0;
        result = dates.map((item, index) => {
          if (dataPos >= dataWithDate.length) return null;
          if (item === dataWithDate[dataPos][0].slice(0, 10)) {
            return dataWithDate[dataPos++][1];
          } else {
            return null;
          }
        });
        return result;
      };

      let params = {
        wellId: uwi,
        declinePhase: declinePhase,
        startDate: startDate,
        forecastMonths: forecastMonths,
        // mode: "sum",
        params: aiParams,
        paramsValues: aiParamValues,
      };

      showLoadingIcon && showLoadingIcon();
      getDcaManagementForecast(
        params,
        (result) => {
          let { success, ml } = result;
          if (success) {
            // set curve data
            let { predicted } = ml;
            let dateSeries = predicted.map((item) => item[0].slice(0, 10));
            // let newKey = userAdjusted
            //   ? "AI-physics Forecast"
            //   : "AI-physics Forecast";
            let newKey = "AI-physics Forecast";
            let tempDataSeries = {};
            tempDataSeries[newKey] = {
              data: getDataByDate(predicted, dateSeries),
              lineType: "line",
              unit: PARAM_NAMES[declinePhase].unit,
            };
            setSeriesData((seriesData) => ({
              ...seriesData,
              xSeries: dateSeries,
              config: {
                multiYAxis: false,
                leftYAxisLabel: DECLINE_PHASE_OPTIONS.find(
                  (item) => item.value === declinePhase
                ).name,
                title: well_name + " Forecast",
              },
              dataSeries: (() => {
                if (!seriesData.dataSeries) return [tempDataSeries];
                let newArr = [];
                newArr.push(tempDataSeries);
                return newArr;
              })(),
            }));

            DECLINE_PHASE_OPTIONS.map(({ value: phase, name: phaseName }) => {
              return getDcaManagementForecast(
                { ...params, declinePhase: phase },
                (result) => {
                  let { success, history, ml } = result;
                  if (success) {
                    let { predicted } = ml;
                    // calculate eur data
                    let predictSum = predicted
                      .map((item) => item[1])
                      .reduce((total, v) => (total += v), 0);
                    let historySum = history
                      .map((item) => item[1])
                      .reduce((total, v) => (total += v), 0);
                    let tempEurData = {};
                    if (userAdjusted) {
                      tempEurData[phase + "_predictUserArps"] = predictSum;
                      tempEurData[phase + "_eurUserArps"] =
                        predictSum + historySum;
                    } else {
                      tempEurData[phase + "_predictAutoRegression"] =
                        predictSum;
                      tempEurData[phase + "_eurAutoRegression"] =
                        predictSum + historySum;
                    }
                    setEurData((eurData) => ({ ...eurData, ...tempEurData }));
                  }
                },
                (err) => {
                  console.warn("Get forecast error!", err);
                }
              );
            });
          } else {
            console.error("load data error.");
          }
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          console.error("forecast data error====>", error);
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [hideLoadingIcon, showLoadingIcon]
  );

  useEffect(() => {
    if (!activeWell) {
      return;
    }

    const userAdjusted = forecastSettings.userAdjusted;
    if (!userAdjusted && userAdjusted !== undefined) return;

    const wellName = activeWell.well_name;
    const uwi = activeWell.uwi;
    const startDate = currentGlobal.startDate
      ? dayjs(currentGlobal.startDate).format("YYYY-MM-DD")
      : "";
    const forecastMonths = forecastSettings
      ? forecastSettings.forecastMonths
      : 0;

    let realParams = [],
      realParamValues = [];
    if (currentGlobal.params && forecastSettings) {
      for (
        let i = 0;
        i <
        Math.min(
          currentGlobal.params.length,
          forecastSettings.paramsValues.length
        );
        i++
      ) {
        let p = currentGlobal.params[i],
          v = forecastSettings.paramsValues[i];
        if (p && v !== null && v !== undefined) {
          realParams.push(p);
          realParamValues.push(v);
        } else break;
      }
    }

    reloadSeriesData(
      userAdjusted,
      wellName,
      uwi,
      declinePhase,
      startDate,
      forecastMonths,
      realParams,
      realParamValues
    );
  }, [
    forecastSettings,
    currentGlobal,
    declinePhase,
    managementGlobal,
    activeWell,
    reloadSeriesData,
  ]);

  function handleSaveManagementSettings() {
    if (!activeWell) {
      console.warn("selected no well! activeWell:", activeWell);
      return;
    }
    let activeWellId = activeWell.uwi || activeWell.well_uwi;
    if (!activeWellId) {
      console.warn("no well id! activeWell:", activeWell);
      return;
    }

    let aiPrams = [];
    for (let i = 0; i < currentGlobal.params.length; i++) {
      aiPrams.push({
        param_name: currentGlobal.params[i],
        value_suggest: currentGlobal.paramsSuggested[i],
        value_user: currentGlobal.forecastSetting.paramsValues[i],
      });
    }

    const projectParams = {
      well_uwi: activeWellId,
      project_id: activeProject?.id,
      records: [
        {
          decline_phase: declinePhase,
          start_date: currentGlobal.startDate,
          validation_length: currentGlobal.validationMonths,
          forecast_length: currentGlobal.forecastSetting.forecastMonths,
          ai_params: aiPrams,
        },
      ],
    };

    submitDcaProject(projectParams);
  }

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      dispatch(setDeclinePhase(value));
    },
    [dispatch]
  );

  const phasesSelect = useMemo(() => {
    return DECLINE_PHASE_OPTIONS.map((item, index) => {
      const id = "man_forecast" + index.toString();
      return (
        <span key={index}>
          <input
            className="btn-check"
            id={id}
            type="radio"
            name="man_forecast"
            value={item.value}
            onChange={handleChange}
            checked={declinePhase === item.value}
          />
          <label htmlFor={id} className="btn-label header">
            {item.name}
          </label>
        </span>
      );
    });
  }, [handleChange, declinePhase]);

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Forecast</span>
        <div className="header-menu-container d-flex flex-row">
          <div>
            <span>Decline Phase :</span>
            {phasesSelect}
          </div>
          <div className="cards-setting-btn ms-2">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content d-flex flex-column justify-content-between align-items-stretch">
        <div className="forecast-upper-part">
          <DcaManagementHistoryChart data={seriesData} />
        </div>
        <div className="forecast-lower-part d-flex flex-column mt-2">
          <div className="d-flex justify-content-between align-items-center mt-2">
            <button
              className="btn-custom"
              onClick={(e) => {
                setShowForecastSetting(true);
              }}
            >
              Parameter Setting
            </button>
            <button
              className="btn-custom"
              onClick={(e) => {
                setShowEurPanel(true);
              }}
            >
              EUR
            </button>
            <button
              className="btn-custom"
              style={{ minWidth: "8em" }}
              onClick={handleSaveManagementSettings}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {showForecastSetting && (
        <div className="popup-window">
          <DcaManagementForecastSetting
            value={{
              ...currentGlobal.forecastSetting,
              paramsNames: currentGlobal.params,
              paramsRange: currentGlobal.params.map((param) =>
                managementGlobal.allParamsRange
                  ? managementGlobal.allParamsRange[param]
                  : ""
              ),
              paramsSuggested: currentGlobal.paramsSuggested,
              paramsDefault: currentGlobal.params.map((param) => {
                let paramData = managementGlobal.availableParamsResult[param];
                if (paramData && paramData.length > 0 ) {
                  return paramData[paramData.length - 1][1];
                } else {
                  return 0;
                }
              }),
            }}
            onApply={(value) => {
              dispatch(
                setManagementGlobalByPhase({
                  phase: declinePhase,
                  settings: {
                    forecastSetting: { ...value, userAdjusted: true },
                  },
                })
              );
            }}
            onClose={() => {
              setShowForecastSetting(false);
              // setForecastSetting({ ...forecastSetting, userAdjusted: false });
            }}
          />
        </div>
      )}
      {showEurPanel && (
        <div className="popup-window">
          <DcaManagementEurPanel
            eurData={eurData}
            phase={declinePhase}
            dcaTable={true}
            onClose={() => setShowEurPanel(false)}
          />
        </div>
      )}
    </div>
  );
}

export default DcaManagementForecast;
