import React, { useEffect, useState, useCallback } from "react";
import ReactECharts from "echarts-for-react";

const MODELS = {
  "historical_data": { value: "historical_data", name: "Historical", style: "scatter" },
  "user_dca": { value: "user_dca", name: "user-Arp's" },
  "recommend_data": { value: "recommend_data", name: "Auto-regression" },
  "ml_data": { value: "ml_data", name: "ML" },
}

const COLORS = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];

const chartInitialOptions = {
  aria: {
    enabled: true,
    decal: {
      show: true,
    },
  },
  legend: {
    data: [' '],
    textStyle: {
      color: "#ccc",
    },
  },
  toolbox: {
    left: 'center',
    itemSize: 18,
    top: 15,
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
      restore: {},
      saveAsImage: { type: 'png' }
    }
  },
  axisPointer: {
    show: true,
    snap: true,
    label: {
      precision: 3,
    }
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ['1970-1-1']
  },
  yAxis: [{
    type: "value",
    show: true,
    splitLine: {
      show: false,
      lineStyle: {
        opacity: "0.2",
        color: ["#fff"],
      },
    },
    name: "",
    nameTextStyle: {
      color: "#aaa",
      nameLocation: "middle",
    },
  }],
  series: [{
    name: ' ',
    type: 'line',
    data: [0],
  }],
};

const TypeCurveTypeCurve = (props) => {
  const { historical_data, recomm_data, user_dca, yUnit } = props;

  const [options, setOptions] = useState(chartInitialOptions);

  const getChartOptions = useCallback((historicalData, recommData, userDca, yUnit) => {
    let key, data = {};
    if (historicalData) { key = "historical_data"; data[key] = historicalData }
    if (recommData) { key = "recommend_data"; data[key] = recommData }
    if (userDca) { key = "user_dca"; data[key] = userDca }

    let models = [];
    historicalData && models.push(MODELS["historical_data"]);
    recommData && models.push(MODELS["recommend_data"]);
    userDca && models.push(MODELS["user_dca"]);

    let legendArr = [], seriesValues = [], xAxisData = [], yAxisArr = [];
    // get xAxis, set it
    const getXAxis = (data) => {
      return data.map(item => item[0]);
    };
    // get yAxis, unit\name\index\offset\color, push into
    const getYAxis = (model, index) => {
      return {
        type: "value",
        offset: (index ? (index - 1) : 0) * (20),
        position: index ? 'right' : "left",
        axisLine: {
          onZero: false,
          show: true,
          // lineStyle: {
          //   color: COLORS[index],
          // },
        },
        splitLine: {
          show: index ? false : true,
          lineStyle: {
            opacity: "0.2",
            color: ["#fff"],
          },
        },
        axisTick: {
          show: true,
          color: COLORS[index],
        },
        axisLabel: {
          show: true,
          interval: 'auto',
        },
        name: yUnit || model.name,
        yAxisIndex: index,
        nameTextStyle: {
          nameLocation: "middle",
        },
      }
    };

    // get values, clean(calc, get monthly)
    const getValues = (model, data, index) => {
      let seriesData = [], dataArr = data[model.value];
      let xAxisArr = getXAxis(data["historical_data"]);
      let dataIndex = 0;
      xAxisArr.forEach(date => {
        let item = null;
        if (dataArr[dataIndex][0] === date) {
          item = dataArr[dataIndex][1];
          dataIndex++;
        }
        seriesData.push(item);
      });
      return {
        name: model.name,
        type: 'line',
        data: seriesData,
        symbol: 'circle',
        symbolSize: index === 0 ? 4 : 0,
        lineStyle: {
          show: false,
          opacity: index === 0 ? 0 : 1,
        },
        itemStyle: {
          color: COLORS[index],
          borderWidth: 0,
          decal: {
            symbol: 'rect',
          }
        },
      };
    };

    models && models.forEach((model, index) => {
      legendArr.push(model.name);
      (xAxisData.length === 0) && (xAxisData = getXAxis(historicalData));
      index === 0 && yAxisArr.push(getYAxis(model, index));
      seriesValues.push(getValues(model, data, index));
    });

    return {
      legendArr: legendArr,
      xAxisData: xAxisData,
      yAxisArr: yAxisArr,
      seriesValues: seriesValues,
    };

  }, []);

  useEffect(() => {
    if (historical_data) {
      let { legendArr, xAxisData, yAxisArr, seriesValues } = getChartOptions(historical_data, recomm_data, user_dca, yUnit);

      setOptions((opts) => {
        if (!opts) return;

        let tempOpts = { ...opts };
        tempOpts.legend.data = legendArr;
        tempOpts.xAxis.data = xAxisData;
        tempOpts.xAxis.axisLabel = {
          ...(tempOpts.xAxis.axisLabel ? tempOpts.xAxis.axisLabel : {}),
          formatter: function (value, index) {
            if (!isNaN(value)) return value;
            var date = new Date(value);
            if (date === "Invalid Date") return value;
            var texts = [
              ('0' + (date.getMonth() + 1)).slice(-2),
              ('0' + date.getDate()).slice(-2),
              date.getFullYear()
            ];
            return texts.join('/');
          }
        };
        tempOpts.yAxis = yAxisArr;
        tempOpts.series = seriesValues;
        return tempOpts;
      });
    }
  }, [historical_data, recomm_data, user_dca, yUnit, getChartOptions]);

  return (
    <>
      <ReactECharts
        option={options}
        notMerge={true}
        style={{ height: "100%" }}
      />
    </>
  );
};
export default TypeCurveTypeCurve;
