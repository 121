import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	Button,
	Modal,
	Form,
	Select,
	Input,
	Popconfirm,
	ConfigProvider,
} from "antd";
import enUS from "antd/locale/en_US";

import {
	getWellOwnershipDict,
	getWellOwnership,
	addWellOwnership,
	updateWellOwnership,
	deleteWellOwnership,
} from "service/wellOwnership";
import { EditableProTable } from "@ant-design/pro-components";
import { exportToSpreadsheet } from "utils/excelUtil";

import classes from '../editable-table.module.scss'
import { snakeToCamel } from "utils/string";

const WellOwnershipTable = (props) => {
	const { wellId } = props;
	const [propKeys, setPropKeys] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [ownershipRecords, setOwnershipRecords] = useState([]);
	const formRef = useRef();

	const [openAddOwnership, setOpenAddOwnership] = useState(false);

	const recordTypes = useMemo(() => ownershipRecords.reduce((state, record) => {
		let key = null
		for (let propKey of propKeys) {
			if (propKey in record) {
				key = propKey
				break
			}
		}
		state[record.id] = key
		return state
	}, {}), [ownershipRecords, propKeys])

	const fetchRecords = useCallback(async (wellId) => {
		if (isLoading) return;
		setLoading(true)
		setOwnershipRecords([])
		try {
			await getWellOwnership(
				wellId,
				null,
				(res) => {
					if (!res || res.length === 0) return;
					setOwnershipRecords(res);
				},
				(error) => { }
			);
		} finally {
			setLoading(false)
		}
	}, [isLoading])

	const handleDelete = useCallback((recordId) => {
		deleteWellOwnership(
			wellId,
			[recordId],
			() => {
				fetchRecords(wellId)
			},
			(err) => { }
		);
	}, [fetchRecords, wellId]);

	const columns = useMemo(
		/**
		 * @returns {import("@ant-design/pro-components").ProColumns[]}
		 */
		() => {
			return [
				{
					key: 'owner_type',
					title: 'Owner Type',
					render: (_, record) => {
						const key = recordTypes[record.id]
						if (!key) return null
						return snakeToCamel(key)
					},
					editable: true,
					valueType: 'select',
					formItemProps: (form, config) => {
						return {
							getValueProps: (val) => {
								const value = typeof val === 'object' ? recordTypes[config.entity.id] : val
								return {
									value
								}
							}
						}
					},
					fieldProps: (form, config) => {
						return {
							options: propKeys.map((propKey) => {
								return {
									value: propKey,
									label: snakeToCamel(propKey)
								}
							}),
						}
					},
				},
				{
					title: 'Owner',
					editable: true,
					dataIndex: 'owner',
				},
				{
					key: 'percentage',
					title: 'Percentage',
					render: (_, record) => {
						const key = recordTypes[record.id]
						if (!key) return null

						return record[key]
					},
					editable: true,
					formItemProps: (form, config) => ({
						getValueProps: (val) => {
							const { entity } = config
							const key = recordTypes[entity.id]
							if (!key) return {}
							const value = typeof val === 'object' ? val[key] : val
							return {
								value
							}
						}
					})
				},
				{
					title: 'Note',
					editable: true,
					dataIndex: 'notes'
				},
				{
					key: 'actions',
					valueType: "option",
					title: 'Actions',
					render: (_, record, index, action) => {
						return (
							<div className="d-flex gap-2">
								<Button onClick={() => action.startEditable?.(record.id)}>Edit</Button>
								<Popconfirm
									title="Sure to delete?"
									onConfirm={() => handleDelete(record.id)}
								>
									<Button>Delete</Button>
								</Popconfirm>
							</div>
						)
					}
				}
			]
		},
		[handleDelete, propKeys, recordTypes])

	useEffect(() => {
		getWellOwnershipDict(
			(res) => {
				if (!res || res.length === 0) return;
				setPropKeys(res);
			},
			(error) => { }
		);
	}, []);

	useEffect(() => {
		if (!wellId) return;

		fetchRecords(wellId)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wellId]);

	const onFinish = () => {
		const values = formRef.current?.getFieldsValue();
		if (
			values["owner"] == null ||
			values["type"] == null ||
			values["value"] == null
		) {
			Modal.error({
				title: "Error!",
				content: (
					<>
						<p>Please check input fields</p>
					</>
				),
			});
			return;
		}
		setOpenAddOwnership(false);

		let data = {
			owner: values.owner,
			notes: values.notes,
		};
		data[values.type] = values.value;

		addWellOwnership(
			wellId,
			data,
			(res) => {
				let newRecords = [...ownershipRecords, res];
				setOwnershipRecords(newRecords);
			},
			(err) => { }
		);
	};

	const exportTableToExcelFile = () => {
		if (!ownershipRecords || ownershipRecords.length === 0) {
			Modal.info({
				title: "Info!",
				content: (
					<>
						<p>No data to export.</p>
					</>
				),
			});
			return;
		}
		const data = [['Owner Type', 'Owner', 'Percentage', 'Note']]
		data.push(...ownershipRecords.map(record => {
			let ownerType = null
			for (let propKey of propKeys) {
				if (propKey in record) {
					ownerType = propKey
					break
				}
			}
			return [ownerType ? snakeToCamel(ownerType) : '', record.owner, record[ownerType] ?? '', record.notes]
		}))

		exportToSpreadsheet(data, "ownership-" + wellId);
	};

	return (
		<div className="w-100 h-100 vstack gap-2">
			<ConfigProvider
				locale={enUS}
				componentSize="middle"
				theme={{
					token: {},
					components: {},
				}}
			>
				<div className="w-100 d-flex justify-content-end">
					<Button
						onClick={() => {
							formRef.current?.resetFields();
							setOpenAddOwnership(true);
						}}
					>
						Add New Ownership
					</Button>
					<Button
						onClick={() => {
							exportTableToExcelFile();
						}}
					>
						Export to excel
					</Button>
				</div>
				<EditableProTable
					loading={isLoading}
					alwaysShowAlert={false}
					tableAlertRender={false}
					tableAlertOptionRender={false}
					pagination={false}
					search={false}
					rowKey="id"
					sticky={true}
					columns={columns}
					value={ownershipRecords}
					className={classes.editableTable}
					cardProps={{
						className: classes.card
					}}
					tableClassName={classes.table}
					editable={{
						onSave: (rowKey, data, row) => {
							if (data.isNewRecord) return;
							const record = {
								id: data.id,
								notes: data.notes,
								owner: data.owner,
							}
							let percentage = data.percentage
							if (typeof percentage === 'object') {
								percentage = percentage[recordTypes[data.id]]
							}
							if (typeof data.owner_type === 'string') {
								record[data.owner_type] = percentage
							} else {
								record[recordTypes[data.id]] = percentage
							}
							updateWellOwnership(
								wellId,
								record,
								(res) => {
									setOwnershipRecords(records => {
										const newRecords = [...records]
										newRecords[data.index] = res
										return newRecords
									})
								},
								(err) => { }
							);
						}
					}}
					recordCreatorProps={{
						style: {
							display: 'none'
						}
					}}
				/>
				<Modal
					title="Add new ownership"
					open={openAddOwnership}
					onOk={() => onFinish()}
					onCancel={() => setOpenAddOwnership(false)}
				>
					<Form
						name=""
						labelCol={{
							span: 6,
						}}
						wrapperCol={{
							span: 16,
						}}
						layout="horizontal"
						ref={formRef}
					>
						<Form.Item
							label="Owner"
							name="owner"
							rules={[
								{
									required: true,
									message: "Please input Owner!",
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label="Ownership"
							name="type"
							rules={[
								{
									required: true,
									message: "Please select ownership!",
								},
							]}
						>
							<Select>
								{propKeys &&
									propKeys.map((propKey, index) => {
										return (
											<Select.Option
												key={index}
												value={propKey}
											>
												{snakeToCamel(propKey)}
											</Select.Option>
										);
									})}
							</Select>
						</Form.Item>
						<Form.Item
							label="Percentage"
							name="value"
							rules={[
								{
									required: true,
									message: "Please input percentage!",
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item label="Notes" name="notes">
							<Input.TextArea />
						</Form.Item>
					</Form>
				</Modal>
			</ConfigProvider>
		</div>
	);
};

export default WellOwnershipTable;
