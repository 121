import SectionSetting from "components/common/SectionSetting";
import EventLogTable from "./EventLogTable";

const EventLog = (props) => {
  const { wellId, assetId, loadingIconActions } = props;

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Event Log</span>
        <div className="header-menu-container d-flex flex-row">
          <div className="dropdown me-2">
            <a
              className="dropdown-toggle"
              href="#"
              id="log_filter_button"
              data-bs-toggle="dropdown"
            >
              <span className="">
                All Members
                <i className="mdi mdi-chevron-down ms-1"></i>
              </span>
            </a>

            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton2"
            >
              <a className="dropdown-item" href="#">
                Locations
              </a>
              <a className="dropdown-item" href="#">
                Revenue
              </a>
              <a className="dropdown-item" href="#">
                Join Date
              </a>
            </div>
          </div>
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>

      <div className="cards-content">
        <div className="table-container overflow-auto hide-scrollbar">
          <EventLogTable
            wellId={wellId}
            assetId={assetId}
            loadingIconActions={loadingIconActions}
          />
        </div>
      </div>
    </div>
  );
};

export default EventLog;
