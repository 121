import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PpLayout from "../components/PpLayout";
import { Layout, Spin } from 'antd';
import PpAssetWellSelector from "../components/selector/PpAssetWellSelector";
import PpLogSelector from "../components/selector/PpLogSelector";
import PpFormationTable from "../components/PpFormationTable";
import PpSelectedSelector from "../components/selector/PpSelectedSelector";
import PpXSectionSelector from "../components/selector/PpXSectionSelector";
import PpCrossSectionMap from "../components/PpCrossSectionMap";
import PpWellsWithCrossSection from "../components/well_curve/PpWellsWithCrossSection";
import { useSelector } from "react-redux";
import { reset, selectBackgroundColorSetting } from "features/petrophysics/petrophysicsSlice";
import PpTopSelector from "../components/selector/PpTopSelector";
import { contentStyle, rightContentStyle, rightStyle, siderStyle } from "../styles";
import useLoading from "components/common/useLoading";
const { Sider, Content } = Layout;

const CrossSection = (props) => {
    const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(<Spin size="large" />, 50);
    const [mapView, setMapView] = useState(true);
    const backColor = useSelector(selectBackgroundColorSetting);
    const tops = [1.2, 2.6];

    const dispatch = useDispatch();
    useEffect(()=>{
        return ()=>{
            dispatch(reset());
        }
    }, [dispatch]);

    return (
        <PpLayout>
            <LoadingIcon />
            <Layout height={'100vh'}>
                <Sider style={siderStyle}>
                    <Content style={{ ...contentStyle }} >
                        <PpAssetWellSelector
                            onlyXSection={true}
                            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                        />
                    </Content>
                    <Content style={{ ...contentStyle }}>
                        {
                            mapView ? <PpSelectedSelector /> : <PpLogSelector loadingIconActions={[showLoadingIcon, hideLoadingIcon]} />
                        }
                    </Content>
                    <Content style={{ ...contentStyle }}>
                        <PpXSectionSelector
                            title='X-section'
                            save={mapView ? true : false}
                            viewSwitch={true}
                            needSort={true}
                            onViewSwitch={(value) => {
                                setMapView(!mapView);
                            }
                            }
                            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                        />
                        <div style={{ display: 'hidden' }}>
                            <PpTopSelector
                                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                            />
                        </div>
                    </Content>
                </Sider>
                <Content style={rightStyle}>
                    {
                        mapView && <PpCrossSectionMap
                            loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                        />
                    }
                    {
                        !mapView && (
                            <>
                                <Content style={{
                                    ...rightContentStyle,
                                    backgroundColor: backColor,
                                    height: '70%'
                                }}>
                                    <PpWellsWithCrossSection
                                        gapWidth='0px'
                                        columnWidth='120px'
                                        tops={tops}
                                        showTop={true}
                                        height={600}
                                        syncDepth={false}
                                        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                    />
                                </Content>
                                <Content style={{ ...rightContentStyle, height: '30%' }}>
                                    <PpFormationTable
                                        editable={false}
                                        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
                                    />
                                </Content>
                            </>
                        )
                    }
                </Content>
            </Layout>
        </PpLayout>
    )
};
export default CrossSection;
