import { useSelector, useDispatch } from "react-redux";
import AIClusteringPanel from "./AIClusteringPanel";
import UserGroupingPanel from "./UserGroupingPanel";

import {
  selectClusteringType,
  setClusteringType,
  setSelectedWellIds,
  // setSelectedWells,
} from "features/decline_analysis/typeCurveProjectSlice";
import { ClusteringTypes } from "app/codes";
import { prepareWellData } from "service/wellData";
import { useState } from "react";

function ClusteringPanel(props) {
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const clusteringType = useSelector(selectClusteringType);
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  function handleClusteringTypeChanged(e) {
    dispatch(
      setClusteringType(
        clusteringType === ClusteringTypes.AIClustering
          ? ClusteringTypes.UserGroupping
          : ClusteringTypes.AIClustering
      )
    );
    dispatch(setSelectedWellIds([]));
    // dispatch(setSelectedWells([]));
  }

  function startPrepareWellData() {
    setProcessing(true);
    showLoadingIcon && showLoadingIcon();
    setTimeout(() => {
      setProcessing(false);
    }, 25000);
    prepareWellData(
      (res) => {
        setProcessing(false);
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => {
        setProcessing(false);
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }

  return (
    <div className="cards-container d-flex flex-column h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <div className="cluster-switch form-check form-switch d-flex flex-row justify-content-between align-items-center">
          <div
            className="header-title me-2"
            style={
              clusteringType === ClusteringTypes.AIClustering
                ? { color: "#0d6efd" }
                : { color: "white" }
            }
          >
            Al Clustering
          </div>
          <input
            className="form-check-input"
            type="checkbox"
            id="cluster_type"
            checked={clusteringType === ClusteringTypes.UserGroupping}
            onChange={handleClusteringTypeChanged}
          ></input>
          <label
            className="form-check-label ms-2"
            style={
              clusteringType === ClusteringTypes.UserGroupping
                ? { color: "#0d6efd" }
                : { color: "white" }
            }
          >
            User Grouping
          </label>
        </div>
        <div>
          {processing && (
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
        <div className="header-menu-container d-flex flex-row">
          <div className="dropdown">
            <div className="cards-setting-btn" data-bs-toggle="dropdown">
              <div className="cards-setting-icon" />
            </div>
            <ul className="dropdown-menu">
              <li>
                <div
                  className="dropdown-item"
                  onClick={(e) => {
                    startPrepareWellData();
                  }}
                >
                  Prepare Well Data
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {clusteringType === ClusteringTypes.AIClustering && <AIClusteringPanel loadingIconActions={loadingIconActions} />}
      {clusteringType === ClusteringTypes.UserGroupping && (
        <UserGroupingPanel loadingIconActions={loadingIconActions} />
      )}
    </div>
  );
}

export default ClusteringPanel;
