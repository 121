import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectSelectedWellIds } from "features/well_planner/plannerSlice";
import {
  selectAssets,
  selectActiveAssetId,
  selectActiveWells,
  setActiveAssetByName,
  selectActiveAsset,
} from "features/asset/assetSelectorSlice";

function WellsSelector(props) {
  const { singleSelect } = props;

  const activeAssetId = useSelector(selectActiveAssetId);
  const activeAsset = useSelector(selectActiveAsset);
  const assets = useSelector(selectAssets);
  const activeWells = useSelector(selectActiveWells);
  const wellIds = useSelector(selectSelectedWellIds);

  const dispatch = useDispatch();

  const [wellsData, setWellsData] = useState(null);
  const [selectedWellIds, setSelectedWellIds] = useState(new Set());

  let assetName = null;
  if (activeAsset) {
    assetName = activeAsset.asset_name;
  }

  useEffect(() => {
    if (wellIds == null || wellIds.length === 0 || activeWells == null || activeWells.length === 0) {
      return;
    }

    const idsSet = new Set(wellIds);
    const wells = activeWells
      .filter((well) => idsSet.has(well.uwi))
      .filter((well) => well != null && well.asset_id === activeAssetId);
    setWellsData(wells);
  }, [activeAssetId, wellIds, activeWells]);

  function handleAssetChanged(e) {
    const assetName = e.target.value;
    dispatch(setActiveAssetByName({ assetName }));
  }

  function handleWellSelected(well) {
    const wellId = well.uwi;
    let tempSet = null;
    if (selectedWellIds == null) {
      tempSet = new Set([wellId]);
    } else if (!selectedWellIds.has(wellId)) {
      tempSet = singleSelect
        ? new Set([wellId])
        : new Set([...selectedWellIds, wellId]);
    } else {
      if (singleSelect) {
        tempSet = new Set();
      } else {
        tempSet = new Set([...selectedWellIds]);
        tempSet.delete(wellId);
      }
    }
    setSelectedWellIds(tempSet);
  }

  const assetsSelect = () => {
    if (assets == null || assets.length === 0) {
      return null;
    }

    return assets.map((asset, index) => {
      return (
        <option
          key={index}
          className="d-flex justify-content-center align-items-content"
          value={asset.asset_name}
        >
          {asset.asset_name}
        </option>
      );
    });
  };

  const wellsSelect = () => {
    if (wellsData == null || wellsData.length === 0) {
      return null;
    }
    return wellsData.map((item, index) => {
      const selected =
        selectedWellIds && selectedWellIds.size > 0
          ? selectedWellIds.has(item.uwi)
          : false;
      let className =
        "select-option d-flex justify-content-center align-items-start";
      if (selected) {
        className = className + " selected";
      }
      return (
        <div
          id={item.uwi}
          key={item.uwi}
          className={className}
          onClick={() => {
            handleWellSelected(item);
          }}
        >
          {item.well_name}
        </div>
      );
    });
  };

  return (
    <div className="selector d-flex flex-column w-100 h-100">
      <div className="selector-select d-flex flex-column align-items-stretch">
        <select
          onChange={handleAssetChanged}
          name="asset_name"
          className="d-flex"
          value={assetName || ""}
        >
          {assetsSelect()}
        </select>
      </div>
      <div className="select-options-list hide-scrollbar">{wellsSelect()}</div>
    </div>
  );
}

export default WellsSelector;
