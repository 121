import { Button, Card, Form, Steps, message } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'

import classes from './dca-reporting.module.scss'
import SourceProject from './components/SourceProject'
import GeneralSetting from './components/GeneralSetting'
import WellSetting from './components/WellSetting'
import classNames from 'classnames'
import DCAReportProvider, { useDCAReport } from './DCAReportProvider'
import { omit } from 'lodash'
import ReportContent from './components/ReportContent'
import { useMutation } from '@tanstack/react-query'
import { exportDCAReportPDF } from 'service/dcaAnalysis'
import * as fileSaver from 'file-saver'
import { isDeepEmpty } from 'utils/isDeepEmpty'

const DeclineAnalysisReportingContent = () => {
  const { sourceProject } = useDCAReport()
  const [currentStep, setCurrentStep] = useState(0)

  const { wells = [] } = sourceProject || {}

  const [form] = Form.useForm()

  const { mutate: exportPDF, isPending: isExporting } = useMutation({
    mutationFn: (data) => exportDCAReportPDF(data),
    onSuccess: (res) => {
      const title = form.getFieldValue('report_title')
      fileSaver.saveAs(res.data, `${ title }.pdf`)
    }
  })

  const steps = useMemo(() => [
    {
      key: 'source_project',
      title: 'Source Project',
      content: <SourceProject />
    },
    {
      key: 'general_setting',
      title: 'General Settings',
      disabled: !sourceProject,
      content: <GeneralSetting />
    },
    {
      key: 'well_setting',
      title: 'Well Settings',
      disabled: !sourceProject,
      content: <WellSetting />
    },
    {
      key: 'report',
      disabled: !sourceProject,
      title: 'Report',
      content: <ReportContent isActive={currentStep === 3} />
    },
  ], [currentStep, sourceProject])

  const onNext = useCallback(() => {
    if (!sourceProject) {
      message.error('Please select source project')
      return
    }
    setCurrentStep(num => num + 1)
  }, [sourceProject])

  const onBack = useCallback(() => {
    setCurrentStep(num => num - 1 || 0)
  }, [])

  const onSubmit = useCallback((data) => {
    const payload = {
      report_title: data.report_title,
      report_type: data.report_type,
      report_note: data.report_note,
      project_id: data.project_id,
      general: {
        price_projections: data.general.price_projections,
        apply_diff: data.general.apply_diff || false,
        apply_ngl: data.general.apply_ngl || false,
        ngl_ratio: data.general.ngl_ratio,
        discount_rate: data.general.discount_rate,
        sensitivity_rates: data.general.sensitivity_rates,
        kpis: Object.entries({
          cashflow: data.general.cashflow,
          dcf: data.general.discounted_cashflow,
          eur: data.general.economic_eur
        }).reduce((kpis, [key, checked]) => {
          if (checked) {
            kpis.push(key)
          }
          return kpis
        }, [])
      },
      wells: Object.entries(data.wells)
        .filter(([, wellSetting]) => {
          return !isDeepEmpty(wellSetting)
        })
        .map(([uwi, wellSetting]) => {
          const wellData = wells.find(w => w.uwi === uwi)

          const loeExpenses = wellData?.loe_expenses?.reduce((map, loe) => {
            map[loe.id] = loe
            return map
          }, {}) ?? {}

          return {
            uwi,
            expense_method: wellSetting.loe_expenses.avg_duration !== 'user' ? 'average' : 'user',
            expenses_range: ['user', 'all'].includes(wellSetting.loe_expenses.avg_duration) ? undefined : wellSetting.loe_expenses.avg_duration,
            loe_expense_items: wellSetting.loe_expenses.items?.map(id => ({
              ...loeExpenses[id]
            })),
            loe_expenses: {
              fixed_expense: wellSetting.loe_expenses.fixed_expense,
              variable_expense: wellSetting.loe_expenses.variable_expense
            },
            afe_ids: wellSetting.capax.afe_tables ?? [],
            ownerships: {
              revenue_interest: wellSetting.ownership.revenue_interest && parseFloat(wellSetting.ownership.revenue_interest),
              working_interest: wellSetting.ownership.working_interest && parseFloat(wellSetting.ownership.working_interest),
              royalty_share: wellSetting.ownership.lease_nri && parseFloat(wellSetting.ownership.lease_nri)
            }
          }
        })
    }

    exportPDF(payload)
  }, [exportPDF, wells])

  return (
    <Form
      form={form}
      className={classNames('vstack gap-4 px-4 py-2 overflow-y-scroll h-100', classes.form)}
      layout="vertical"
      labelAlign="left"
      onFinish={onSubmit}
    >
      <div className="w-100">
        <Steps
          type="navigation"
          current={currentStep}
          onChange={setCurrentStep}
          className={classes.steps}
          size="small"
          items={steps.map(step => omit(step, 'content'))}
          progressDot
        />
      </div>
      <Card
        className="mx-2"
        style={{
          backgroundColor: 'var(--bs-body-bg)'
        }}
        bodyStyle={{
          color: 'var(--bs-light)'
        }}
        headStyle={{
          color: 'var(--bs-light)'
        }}
      >
        {steps.map((step, stepIndex) => (
          <div
            key={step.key}
            className={classNames({
              'd-none': stepIndex !== currentStep
            })}
          >
            {step.content}
          </div>
        ))}
      </Card>
      <div className="hstack justify-content-center gap-2">
        {currentStep > 0 ? (
          <Button onClick={onBack}>
            Back
          </Button>
        ) : null}
        {currentStep === steps.length - 1 ? (
          <>
            <Button
              type="primary"
              htmlType="submit"
              loading={isExporting}
            >
              Export Report
            </Button>
          </>
        ) : null}
        {currentStep < steps.length - 1 ? (
          <Button onClick={onNext}>
            Next
          </Button>
        ) : null}
      </div>
    </Form>
  )
}

const DeclineAnalysisReporting = () => {

  return (
    <DCAReportProvider>
      <DeclineAnalysisReportingContent />
    </DCAReportProvider>
  )
}

export default DeclineAnalysisReporting