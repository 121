export const DefaultMaxObjectiveSize = 60;
export const DefaultMinObjectiveSize = 10;

export function getMapFeatures(productionsData, wellsData, productType) {
	let latitudeArray = [];
	let longitudeArray = [];
	let productionArray = [];

	for (const wellData of wellsData) {
		let latitude = wellData.latitude; //parseFloat(wellData.latitude);
		let longitude = wellData.longitude; //parseFloat(wellData.longitude);
		latitudeArray.push(latitude);
		longitudeArray.push(longitude);

		// if (wellData.uwi in productionsData === false) {
		//   continue;
		// }
		const productionData = productionsData[wellData.uwi];
		if (productionData !== undefined && productionData != null) {
			let prdValue = productionData[productType];
			if (
				prdValue !== undefined &&
				prdValue != null &&
				!isNaN(prdValue)
			) {
				productionArray.push(prdValue);
			}
		}
	}

	let latitudeMin = Math.min(...latitudeArray);
	let latitudeMax = Math.max(...latitudeArray);
	let longitudeMin = Math.min(...longitudeArray);
	let longitudeMax = Math.max(...longitudeArray);

	let centerLat = (latitudeMin + latitudeMax) / 2;
	let centerLng = (longitudeMin + longitudeMax) / 2;

	let leftTop = [latitudeMax, longitudeMin];
	let rightBottom = [latitudeMin, longitudeMax];

	let center = [centerLat, centerLng];
	let bounds = [leftTop, rightBottom];

	if (productionArray.length === 0) {
		return [[], center, bounds];
	}

	let productionMax = Math.max(...productionArray);
	let tempFeatures = wellsData
		.map((wellData, index) => {
			if (wellData.uwi in productionsData === false) {
				return null;
			}
			const productionData = productionsData[wellData.uwi];
			let prdValue = productionData[productType];
			if (prdValue == null || prdValue === undefined) {
				return null;
			}
			let bubbleSize = computeBubbleSize(
				prdValue,
				productionMax,
				DefaultMinObjectiveSize,
				DefaultMaxObjectiveSize
			);
			return {
				Name: wellData.well_name,
				Latitude: wellData.latitude,
				Longitude: wellData.longitude,
				Value: bubbleSize,
				apiId: wellData.uwi,
			};
		})
		.filter((item) => item != null);

	return [tempFeatures, center, bounds];
}

export function getCrossSectionMapFeatures(wellsData) {
	let latitudeArray = [];
	let longitudeArray = [];

	for (const wellData of wellsData) {
		let latitude = parseFloat(wellData.latitude);
		let longitude = parseFloat(wellData.longitude);
		latitudeArray.push(latitude);
		longitudeArray.push(longitude);
	}

	let latitudeMin = Math.min(...latitudeArray);
	let latitudeMax = Math.max(...latitudeArray);
	let longitudeMin = Math.min(...longitudeArray);
	let longitudeMax = Math.max(...longitudeArray);

	let centerLat = (latitudeMin + latitudeMax) / 2;
	let centerLng = (longitudeMin + longitudeMax) / 2;

	let leftTop = [latitudeMax, longitudeMin];
	let rightBottom = [latitudeMin, longitudeMax];

	let tempFeatures = [];
	for (const wellData of wellsData) {
		const feature = {
			Name: wellData.well_name,
			Latitude: wellData.latitude,
			Longitude: wellData.longitude,
			Value: 30,
			apiId: wellData.uwi,
		};
		tempFeatures.push(feature);
	}
	let center = [centerLat, centerLng];
	let bounds = [leftTop, rightBottom];
	return [tempFeatures, center, bounds];
}

function computeBubbleSize(
	value,
	maxValue,
	defaultMinBubbleSize,
	defaultMaxBubbleSize
) {
	if (maxValue === 0) {
		return 0;
	}

	let size = (value / maxValue) * defaultMaxBubbleSize;
	if (size < defaultMinBubbleSize) {
		size = defaultMinBubbleSize;
	}
	return size;
}

export function compareNumbers(a, b) {
	return a - b;
}
