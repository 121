/*
    numbers and operands.
*/
import { Button, Image } from "antd";
import { calcOps, calcOpsType, calcOpsUrl } from "app/codes";
import React, { useEffect } from "react";

const Calculator = (props) => {
    const { onClick } = props;

    useEffect(() => {

    }, []);

    return (
        <div style={{ marginLeft: '5px', marginTop: '2em', marginBottom: '2em' }}>
            {
                calcOps.map((row, index) =>
                    <div
                        key={index}
                        style={{ textAlign: 'center' }}>
                        {
                            row.map(item =>
                                <Button
                                    key={item.label}
                                    style={{ width: '3.9em', height: '2.48em' }}
                                    size="small"
                                    value={item.label}
                                    label={item.label}
                                    onClick={() => onClick(item)}
                                >
                                    <Image
                                        src={calcOpsUrl + item.icon + calcOpsType}
                                        preview={false}
                                    />
                                </Button>)
                        }
                    </div>
                )
            }
        </div>
    )
};

export default Calculator;