import { Form, Input, Modal } from 'antd'
import React, { useCallback, useEffect } from 'react'

const SaveAFEModal = ({ onSave, isSubmitting, ...modalProps }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields(['afe_name', 'notes'])
  }, [form, modalProps.open])

  const onSubmit = useCallback((data) => {
    onSave(data)
  }, [onSave])

  return (
    <Modal
      title="Save as..."
      okText="Confirm"
      width={640}
      onOk={form.submit}
      cancelButtonProps={{
        disabled: isSubmitting
      }}
      okButtonProps={{
        loading: isSubmitting
      }}
      {...modalProps}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Form.Item label="AFE Name" name="afe_name" rules={[{
          required: true
        }]}>
          <Input placeholder="AFE Name" />
        </Form.Item>
        <Form.Item label="Note" name="notes">
          <Input.TextArea placeholder="Note" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default SaveAFEModal