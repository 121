import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import LMap from "components/map/Map";

import { getAssets } from "service/assets";
import { getWellSummary } from "service/wellSummary";
import { getTotalProductionsData } from "service/productionData";
import { getMapFeatures } from "utils/map";
import { getDateBefore } from "utils/dateUtil";

import {
  setAssets,
  setActiveAssetId,
  setActiveAssetByName,
  selectAssets,
  setAssetWells,
  selectAssetWells,
  selectActiveAssetId,
  selectActiveAsset,
} from "features/asset/assetSelectorSlice";

import { DataPeriodOptions } from "app/codes";
import SectionSetting from "components/common/SectionSetting";

const ProductOptions = [
  { value: "oil_prd_rate", name: "Cumulative Oil" },
  { value: "gas_prd_rate", name: "Cumulative Gas" },
  { value: "water_prd_rate", name: "Cumulative Water" },
  { value: "avg_oil_gas_prd_rate", name: "Average Oil-gas Ratio" },
  { value: "avg_water_cut", name: "Average Water-cut" },
  { value: "power_consumption", name: "Power Consumption" },
];

const zoomFactor = 15;

function AssetMap(props) {
  const { showWellSelects, assetSelected, bubbleSelected } = props;
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

  const assets = useSelector(selectAssets);
  const activeAssetId = useSelector(selectActiveAssetId);
  const activeAsset = useSelector(selectActiveAsset);
  const assetWells = useSelector((state) => {
    if (activeAssetId) {
      return selectAssetWells(state, activeAssetId);
    }
    return null;
  });
  const dispatch = useDispatch();

  const [productionsData, setProductionsData] = useState(null);
  const [activeProduct, setActiveProduct] = useState(ProductOptions[0].value);
  const [selectedWellIds, setSelectedWellIds] = useState([]);

  // const [zoomFactor, setZoomFactor] = useState(15);
  const [features, setFeatures] = useState(null);
  const [center, setCenter] = useState([0.0, 46.036]);
  const [bounds, setBounds] = useState(null);
  const [period, setPeriod] = useState(0);

  let assetName = null;
  if (activeAsset) {
    assetName = activeAsset.asset_name;
  }

  const loadAssetWells = useCallback(
    (assetId) => {
      showLoadingIcon && showLoadingIcon();
      getWellSummary(
        assetId,
        (result) => {
          let wells = [];
          if (result && result.length > 0) {
            wells = result
              .slice()
              .sort((a, b) => (a.well_name > b.well_name ? 1 : -1));
          }
          dispatch(setAssetWells({ assetId: assetId, wells }));
          hideLoadingIcon && hideLoadingIcon();
        },
        (err) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [dispatch, hideLoadingIcon, showLoadingIcon]
  );

  const updateProductionsData = useCallback((result, product) => {
    let data = {};
    let wellIds = Object.keys(result);
    if (product === "avg_oil_gas_prd_rate") {
      for (let wellId of wellIds) {
        let productions = result[wellId];
        if (productions === undefined || productions == null) {
          continue;
        }
        let oilRate = productions["oil_prd_rate"];
        let gasRate = productions["gas_prd_rate"];
        if (
          oilRate !== undefined &&
          oilRate != null &&
          gasRate !== undefined &&
          gasRate != null
        ) {
          if (oilRate !== 0) {
            let gasOilRatio = gasRate / oilRate;
            data[wellId] = { avg_oil_gas_prd_rate: gasOilRatio };
          }
        }
      }
    } else if (product === "avg_water_cut") {
      for (let wellId of wellIds) {
        let productions = result[wellId];
        if (productions === undefined || productions == null) {
          continue;
        }
        let oilRate = productions["oil_prd_rate"];
        let waterRate = productions["water_prd_rate"];
        if (
          oilRate !== undefined &&
          oilRate != null &&
          waterRate !== undefined &&
          waterRate != null
        ) {
          let baseRate = oilRate + waterRate;
          let waterCut = 0;
          if (baseRate !== 0) {
            waterCut = waterRate / baseRate;
            data[wellId] = { avg_water_cut: waterCut };
          }
        }
      }
    } else {
      for (let wellId of wellIds) {
        let productions = result[wellId];
        if (productions === undefined || productions == null) {
          continue;
        }
        let value = productions[product];
        if (value != null && value !== undefined) {
          data[wellId] = {};
          data[wellId][product] = value;
        }
      }
    }
    setProductionsData(data);
  }, []);

  const queryProductData = useCallback(
    (assetId, product, period) => {
      if (assetId == null) {
        return;
      }

      let startDate = null;
      let endDate = null;

      if (period === 1) {
        // last week
        endDate = new Date();
        startDate = getDateBefore(endDate, 7);
      } else if (period === 2) {
        // last month
        endDate = new Date();
        startDate = getDateBefore(endDate, 30);
      } else if (period === 3) {
        // last year
        endDate = new Date();
        startDate = getDateBefore(endDate, 365);
      }

      showLoadingIcon && showLoadingIcon();
      let products = null;
      if (
        [
          "oil_prd_rate",
          "gas_prd_rate",
          "water_prd_rate",
          "power_consumption",
        ].includes(product)
      ) {
        products = [product];
      } else {
        products = ["oil_prd_rate", "gas_prd_rate", "water_prd_rate"];
      }

      getTotalProductionsData(
        null,
        assetId,
        products,
        startDate,
        endDate,
        (result) => {
          updateProductionsData(result, product);
          hideLoadingIcon && hideLoadingIcon();
        },
        (error) => {
          hideLoadingIcon && hideLoadingIcon();
        }
      );
    },
    [hideLoadingIcon, showLoadingIcon, updateProductionsData]
  );

  useEffect(() => {
    showLoadingIcon && showLoadingIcon();
    getAssets(
      (result) => {
        hideLoadingIcon && hideLoadingIcon();
        if (result == null || result.length === 0) {
          return;
        }
        dispatch(setAssets(result));

        const assetId = result[0].id;
        dispatch(setActiveAssetId(assetId));

        if (showWellSelects === false && assetSelected) {
          assetSelected(assetId);
        }

        loadAssetWells(assetId);

        if (assetId != null) {
          queryProductData(assetId, ProductOptions[0].value, 0);
        }
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }, [
    dispatch,
    hideLoadingIcon,
    showLoadingIcon,
    queryProductData,
    loadAssetWells,
    assetSelected,
    showWellSelects,
  ]);

  useEffect(() => {
    if (
      activeAssetId != null &&
      assetWells != null &&
      assetWells.length > 0 &&
      (!selectedWellIds || selectedWellIds.length === 0)
    ) {
      const wellData = assetWells[0];
      if (wellData && bubbleSelected) {
        bubbleSelected(activeAssetId, wellData.uwi);
      }
    }
  }, [activeAssetId, assetWells, selectedWellIds, bubbleSelected]);

  useEffect(() => {
    if (
      assetWells == null ||
      productionsData == null ||
      activeProduct == null
    ) {
      return;
    }

    const [tempFeatures, mapCenter, mapBounds] = getMapFeatures(
      productionsData,
      assetWells,
      activeProduct
    );
    // if (!tempFeatures || isNaN(tempFeatures[0]?.Value)) return;
    setFeatures(tempFeatures);
    setCenter(mapCenter);
    setBounds(mapBounds);
  }, [assetWells, productionsData, activeProduct]);

  const handleAssetChanged = useCallback(
    (e) => {
      e.preventDefault();
      const assetName = e.target.value;
      dispatch(setActiveAssetByName({ assetName }));

      const asset = assets.find((item) => item.asset_name === assetName);
      const assetId = asset.id;

      if (showWellSelects === false && assetSelected) {
        assetSelected(assetId);
      }

      loadAssetWells(assetId);

      if (activeAssetId != null && activeProduct != null && period != null) {
        queryProductData(activeAssetId, activeProduct, period);
      }
    },
    [
      activeAssetId,
      activeProduct,
      assetSelected,
      assets,
      dispatch,
      loadAssetWells,
      period,
      queryProductData,
      showWellSelects,
    ]
  );

  function handleProductChanged(e) {
    e.preventDefault();
    const product = e.target.value;
    setActiveProduct(product);

    if (activeAssetId != null && product != null && period != null) {
      queryProductData(activeAssetId, product, period);
    }
  }

  function changePeriod(value) {
    if (typeof value === "string") {
      value = parseInt(value);
    }
    setPeriod(value);

    if (activeAssetId != null && activeProduct != null && value != null) {
      queryProductData(activeAssetId, activeProduct, value);
    }
  }

  const onSelectedWellChange = useCallback(
    (wellId) => {
      setSelectedWellIds([wellId]);
      if (bubbleSelected) {
        bubbleSelected(activeAssetId, wellId);
      }
    },
    [activeAssetId, bubbleSelected]
  );

  const handleWellSelected = useCallback(
    (e) => {
      const wellId = e.target.value;
      onSelectedWellChange(wellId);
    },
    [onSelectedWellChange]
  );

  function handleObjectiveSelected(objective) {
    if (showWellSelects) {
      if (!selectedWellIds.includes(objective.apiId)) {
        onSelectedWellChange(objective.apiId);
      }
    }
  }

  const productsSelect = useMemo(() => {
    return ProductOptions.map((item, index) => {
      return (
        <option key={index} value={item.value}>
          {item.name}
        </option>
      );
    });
  }, []);

  const assetsSelect = useMemo(() => {
    if (!assets || assets.length === 0) {
      return <></>;
    }

    return assets.map((asset, index) => {
      return (
        <option key={index} value={asset.asset_name}>
          {asset.asset_name}
        </option>
      );
    });
  }, [assets]);

  const wellsSelect = useMemo(() => {
    if (!showWellSelects || !assetWells || assetWells.length === 0) {
      return null;
    }

    const selects = assetWells.map((item, index) => {
      return (
        <option key={index} value={item.uwi}>
          {item.well_name}
        </option>
      );
    });

    return (
      <>
        <select
          value={
            selectedWellIds && selectedWellIds.length > 0
              ? selectedWellIds[0]
              : ""
          }
          onChange={handleWellSelected}
        >
          {selects}
        </select>
      </>
    );
  }, [assetWells, selectedWellIds, showWellSelects, handleWellSelected]);

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Map</span>
        <div className="header-menu-container d-flex flex-row">
          <select
            className=""
            onChange={handleAssetChanged}
            value={assetName || ""}
          >
            {assetsSelect}
          </select>
          {wellsSelect}
          <select
            className=""
            onChange={handleProductChanged}
            value={activeProduct || ""}
          >
            {productsSelect}
          </select>
          <select
            className=""
            value={period || ""}
            onChange={(e) => {
              changePeriod(e.target.value);
            }}
          >
            {DataPeriodOptions.map((item, index) => {
              return (
                <option key={index} value={index}>
                  {" "}
                  {item.name}{" "}
                </option>
              );
            })}
          </select>
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content">
        <div className="chart-container">
          <LMap
            objectives={features ? features : []}
            center={center}
            zoom={zoomFactor}
            bounds={bounds}
            selectedObjectiveIds={new Set(selectedWellIds)}
            objectiveSelectedHandler={handleObjectiveSelected}
          />
        </div>
      </div>
    </div>
  );
}

export { AssetMap };
