import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import AssetWellsSelector from "../components/AssetWellsSelector";
import WellsMap from "../components/WellsMap";
import WellsSelector from "./WellsSelector";
import ClusteringPanel from "./ClusteringPanel";
import AiTypeCurveChart from "./AITypeCurveChart";
import UserTypeCurveChart from "./UserTypeCurveChart";
import ClusterUncertaintyChart from "./ClusterUncertaintyChart";
import GroupUncertaintyChart from "./GroupUncertaintyChart";

import { ClusteringTypes } from "app/codes";

import {
  reset,
  setSelectedWellIds,
  selectSelectedWellIds,
  setProjectClusterDataItems,
  setProjectGroupDataItems,
  setMlInputs,
  setClusterWells,
  setGroupWells,
  selectDeclinePhase,
  selectClusteringType,
  setClusteringType,
} from "features/decline_analysis/typeCurveProjectSlice";

import {
  setShowNewProjectPopup,
  addProjectItem,
  setActiveProjectId,
  selectActiveProject,
} from "features/project/projectsSlice";

import { getProjectDetail, parseProjectId } from "service/projects";
import { saveProjectGroups, saveProjectClusters } from "service/typeCurve";
import useLoading from "components/common/useLoading";
import { Spin } from "antd";
import { PHASE_MODEL_MAP, DECLINE_PHASE_OPTIONS } from "app/codes";

function TypeCurve(props) {
  let [searchParams] = useSearchParams();
  const projectId = parseProjectId(searchParams.get("project_id"));
  // const projectType = searchParams.get("project_type");
  // const projectName = searchParams.get("project_name");

  const currentProject = useSelector(selectActiveProject);

  const selectedWellIds = useSelector(selectSelectedWellIds);

  const declinePhase = useSelector(selectDeclinePhase);
  const product = declinePhase
    ? PHASE_MODEL_MAP[declinePhase]
    : PHASE_MODEL_MAP[DECLINE_PHASE_OPTIONS[0].value];

  const clusteringType = useSelector(selectClusteringType);
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(
    <Spin size="large" />,
    50
  );

  const dispatch = useDispatch();

  const [projectLoadStatus, setProjectLoadStatus] = useState(null); // loading, loaded

  const updateProjectData = useCallback(
    (projectData) => {
      const projectClusterData = projectData["project_clusters"];
      const projectGroupData = projectData["project_groups"];

      const mlInputs = projectData["ml_inputs"];

      if (projectClusterData.length > 0) {
        dispatch(setProjectClusterDataItems(projectClusterData));
        dispatch(setClusteringType(ClusteringTypes.AIClustering));
      }
      if (projectGroupData.length > 0) {
        dispatch(setProjectGroupDataItems(projectGroupData));
        if (projectClusterData.length === 0)
          dispatch(setClusteringType(ClusteringTypes.UserGroupping));
      }

      if (mlInputs) {
        dispatch(setMlInputs(mlInputs));
      }

      if (projectData.cluster_wells) {
        dispatch(setClusterWells(projectData.cluster_wells));
      }

      if (projectData.group_wells) {
        dispatch(setGroupWells(projectData.group_wells));
      }
    },
    [dispatch]
  );

  const refreshData = useCallback(
    (projId) => {
      showLoadingIcon();
      setProjectLoadStatus("loading");
      getProjectDetail(
        projId,
        (result) => {
          setProjectLoadStatus("loaded");
          if (currentProject == null) {
            dispatch(
              addProjectItem({
                id: result.project_id,
                name: result.project_name,
                project_type: result.project_type,
                custom_data: result.custom_data,
              })
            );
            dispatch(setActiveProjectId(result.project_id));
          }
          updateProjectData(result);
          hideLoadingIcon();
        },
        (error) => {
          console.error(error);
          setProjectLoadStatus(null);
          hideLoadingIcon();
        }
      );
    },
    [
      dispatch,
      currentProject,
      hideLoadingIcon,
      showLoadingIcon,
      updateProjectData,
    ]
  );

  useEffect(() => {
    return () => {
      dispatch(reset());
      dispatch(setActiveProjectId());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      projectLoadStatus === "loading" ||
      projectLoadStatus === "loaded" ||
      (projectId == null && currentProject == null)
    )
      return;
    refreshData(projectId || currentProject.id);
  }, [projectLoadStatus, projectId, currentProject, refreshData]);

  function handleSubmitTcUserProject(projectParams) {
    if (currentProject === null) {
      dispatch(setShowNewProjectPopup(true));
      return;
    }

    let userProjectItem = projectParams.projectItem;
    let tempItem = { ...userProjectItem, project_id: currentProject.id };
    showLoadingIcon();
    saveProjectGroups(
      currentProject.id,
      [tempItem],
      (result) => {
        hideLoadingIcon();
      },
      (error) => {
        hideLoadingIcon();
      }
    );
  }

  function handleSubmitTcClusterProject(projectParams) {
    if (currentProject === null) {
      dispatch(setShowNewProjectPopup(true));
      return;
    }
    let clusterProjectItem = projectParams.projectItem;
    let tempItem = { ...clusterProjectItem, project_id: currentProject.id };
    showLoadingIcon();
    saveProjectClusters(
      currentProject.id,
      null,
      [tempItem],
      "update",
      (result) => {
        hideLoadingIcon();
      },
      (error) => {
        hideLoadingIcon();
      }
    );
  }

  const handleWellSelected = useCallback(
    (well) => {
      let wellId = well.uwi;
      let tempIds = null;

      if (!selectedWellIds) {
        tempIds = [wellId];
      } else if (selectedWellIds.includes(wellId)) {
        tempIds = selectedWellIds.filter((item) => item !== wellId);
      } else {
        tempIds = [...selectedWellIds, wellId];
      }

      dispatch(setSelectedWellIds(tempIds));
    },
    [dispatch, selectedWellIds]
  );

  const handleWellsSelected = useCallback(
    (wells) => {
      if (wells == null || wells.length === 0) {
        return;
      }
      let wellIds = wells.map((well) => {
        return well.uwi;
      });

      let tempIds = null;
      if (!selectedWellIds) {
        tempIds = wellIds;
      } else {
        let tempIdsSet = new Set([...selectedWellIds, ...wellIds]);
        tempIds = [...tempIdsSet];
      }

      dispatch(setSelectedWellIds(tempIds));
    },
    [dispatch, selectedWellIds]
  );

  return (
    <>
      <LoadingIcon />
      <div className="page-content d-flex flex-column hide-scrollbar">
        <div className="row mb-2 row-wrapper g-0">
          <div className="h-100 ps-0 pe-1" style={{ width: "8.875rem" }}>
            <AssetWellsSelector
              // singleSelect={false}
              noAutoSelectFirstWell={true}
              selectedWellIds={selectedWellIds}
              onWellSelected={handleWellSelected}
              onWellsSelected={handleWellsSelected}
              loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
            />
          </div>
          <div className="col ps-1 pe-1">
            <WellsMap
              product={product}
              selectedWellIds={selectedWellIds}
              onWellSelected={handleWellSelected}
              onWellsSelected={handleWellsSelected}
              loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
            />
          </div>
          <div className="col ps-1">
            <ClusteringPanel
              loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
            />
          </div>
        </div>
        <div className="row mb-2 row-wrapper g-0">
          <div className="h-100 ps-0 pe-1" style={{ width: "8.875rem" }}>
            <WellsSelector
              loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
            />
          </div>
          <div className="col ps-1 pe-1">
            {clusteringType === ClusteringTypes.AIClustering && (
              <AiTypeCurveChart
                submitTCProject={handleSubmitTcClusterProject}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            )}
            {clusteringType === ClusteringTypes.UserGroupping && (
              <UserTypeCurveChart
                submitTCProject={handleSubmitTcUserProject}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            )}
          </div>
          <div className="col ps-1">
            {clusteringType === ClusteringTypes.AIClustering && (
              <ClusterUncertaintyChart
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            )}
            {clusteringType === ClusteringTypes.UserGroupping && (
              <GroupUncertaintyChart
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TypeCurve;
