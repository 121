export const ProductionHeaders = [
    { value: "well_name", name: "Well Name" },
    { value: "prd_date", name: "Date" },
    { value: "oil_prd_rate", name: "Oil Rate" },
    { value: "gas_prd_rate", name: "Gas Rate" },
    { value: "water_prd_rate", name: "Water Rate" },
    { value: "bottomhole_flow_pressure", name: "Bottomhole Press" },
    { value: "avg_htp", name: "Avg Htp" },
    { value: "wellhead_tubing_pressure", name: "Tubing Pressure" },
    { value: "wellhead_casing_pressure", name: "Casing Pressure" },
    { value: "dayson", name: "Dayson" },
    { value: "fluid_level", name: "Fluid Level" },
  ];

  export const InjectionHeaders = [
    { value: "well_name", name: "Well Name" },
    { value: "inj_date", name: "Date" },
    { value: "oil_prd_rate", name: "Oil Rate" },
    { value: "gas_prd_rate", name: "Gas Rate" },
    { value: "water_prd_rate", name: "Water Rate" },
    { value: "bottomhole_press", name: "Bottomhole Press" },
    { value: "avg_htp", name: "Avg Htp" },
    { value: "tubing_pressure", name: "Tubing Pressure" },
    { value: "casing_pressure", name: "Casing Pressure" },
    { value: "dayson", name: "Dayson" },
    { value: "fluid_level", name: "Fluid Level" },
  ];