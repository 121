import { Button, List, Popconfirm, Typography } from 'antd'
import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { snakeToCamel } from 'utils/string'

import classes from './dictionary.module.scss'

const CategoryList = ({ isLoading, header, items, selected, onChange, onDelete, ...props }) => {
  return (
    <List
      bordered
      className={classes.list}
      loading={isLoading}
      header={<Typography.Title level={4} className={classes.text}>{header}</Typography.Title>}
      dataSource={items}
      renderItem={(item) => (
        <List.Item
          key={item.value}
          className={classes.listItem}
          data-active={item.value === selected}
          role="button"
          onClick={() => onChange(item.value)}
        >
          <Typography.Text className={classes.text}>{snakeToCamel(item.label)}</Typography.Text>
          {!item.is_system && onDelete ? (
            <Popconfirm
              title="Delete element"
              description="Are you sure to delete this element?"
              onConfirm={() => onDelete(item)}
            >
              <Button danger type="text" shape="circle" className="d-flex align-items-center justify-content-center" icon={<DeleteOutlined />} />
            </Popconfirm>
          ) : null}
        </List.Item>
      )}
      {...props}
    />
  )
}

export default CategoryList