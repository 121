//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) products  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                  'F' is foot                                            :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2022            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//https://www.geodatasource.com/resources/tutorials/how-to-calculate-the-distance-between-2-locations-using-javascript/

export function geoDistance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radLat1 = (Math.PI * lat1) / 180;
    var radLat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radTheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radLat1) * Math.sin(radLat2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    if (unit === "F") {
      dist = dist * 5280;
    }
    return dist;
  }
}

export function geoDistance2(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    let R = 6371000; //meters
    var radLat1 = (Math.PI * lat1) / 180;
    var radLat2 = (Math.PI * lat2) / 180;
    var deltaLat = ((lat2 - lat1) * Math.PI) / 180;
    var deltaLng = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(radLat1) *
      Math.cos(radLat2) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let dist = R * c; //meters

    if (unit === "K") {
      dist = dist / 1000;
    } else if (unit === "S") {
      dist = (dist / 1000) * 0.621371192;
    } else if (unit === "F") {
      dist = (dist / 1000) * 0.621371192 * 5280;
    }
    return dist;
  }
}

//https://www.movable-type.co.uk/scripts/latlong.html

export function getLatLng(lat1, lng1, d, bearing, unit) {
  if (unit === "F") {
    d = d * 0.3048; // foot -> meter
  } else if (unit === "M") {
    d = d * 1609.3;
  }
  let R = 6371000; //earth radius (meter)

  var radLat1 = (Math.PI * lat1) / 180;
  var radLng1 = (Math.PI * lng1) / 180;

  const radLat2 = Math.asin(
    Math.sin(radLat1) * Math.cos(d / R) +
    Math.cos(radLat1) * Math.sin(d / R) * Math.cos(bearing)
  );
  const lat2 = (radLat2 * 180) / Math.PI;
  const radLng2 =
    radLng1 +
    Math.atan2(
      Math.sin(bearing) * Math.sin(d / R) * Math.cos(radLat1),
      Math.cos(d / R) - Math.sin(radLat1) * Math.sin(radLat2)
    );
  const lng2 = (radLng2 * 180) / Math.PI;
  return [lat2, lng2];
}
