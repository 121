import { get, post, put, remove } from "./base";

/**
 *
 * @param {*} params :
 * {
 *  products - oil, ng, ngl, oil_predict, ng_predict, oil_user_predict, ng_user_predict, ngl_user_predict
 *  start_date
 *  end_date, yyyy-mm-dd
 * }
 * @param {*} onSuccess
 * @param {*} onError
 * @returns
 */

export function getCommodityPrices(params, onSuccess, onError) {
  return get(
    "/api/commodity/prices/",
    params,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    }
  );
}

/**
 * 
 * @param {*} data
 * [
 * {
 *      "date": "2025-02-15",
        "oil": "22.93",
        "ng": null,
        "oil_predict": null,
        "ng_predict": null,
        "oil_user_predict": null,
        "ng_user_predict": null,
        "ngl": null,
        "ngl_user_predict": null 
 * },
 * ]
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function addCommodityPrice(data, onSuccess, onError) {
  return post(
    "/api/commodity/prices/",
    data,
    null,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    true
  );
}


/**
 * 
 * @param {*} data
 * [
 * {
 *      "id": 1,
 *      "date": "2025-02-15",
        "oil": "22.93",
        "ng": null,
        "oil_predict": null,
        "ng_predict": null,
        "oil_user_predict": null,
        "ng_user_predict": null,
        "ngl": null,
        "ngl_user_predict": null 
 * },
 * ]
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function updateCommodityPrice(data, onSuccess, onError) {
  return put(
    "/api/commodity/prices/",
    data,
    null,
    {},
    (res) => {
      onSuccess?.(res.data);
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    true
  );
}

export function deleteCommodityPrice(ids, onSuccess, onError, noConfirm) {
  return remove(
    "/api/commodity/prices/",
    { ids: ids },
    null,
    {},
    (res) => {
      onSuccess?.();
    },
    (error) => {
      console.error(error);
      onError?.(error);
    },
    noConfirm
  );
}