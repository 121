export const AlarmHeaders = [
    { value: "asset_name", name: "Asset Name" },
    { value: "well_name", name: "Well Name" },
    { value: "start_date", name: "Start Date" },
    { value: "event_type", name: "Event Type" },
    { value: "status", name: "Status" },
    { value: "severity", name: "Severity" },
    { value: "reporter", name: "Reporter" }
  ];

  export const EventHeaders = [
    { value: "asset_name", name: "Asset Name" },
    { value: "well_name", name: "Well Name" },
    { value: "start_date", name: "Start Date" },
    { value: "duration", name: "Duration" },
    { value: "description", name: "Description" },
    { value: "status", name: "Status" },
    { value: "owner", name: "Owner" },
    { value: "contact", name: "Contact" }
  ];

  export const PlanningHeaders = [
    { value: "event", name: "Event" },
    { value: "asset_name", name: "Asset Name" },
    { value: "well_name", name: "Well Name" },
    { value: "event_type", name: "Event Type" },
    { value: "start_date", name: "Start Date" },
    { value: "duration", name: "Duration" },
    { value: "status", name: "Status" },
    { value: "lead", name: "lead" },
    { value: "details", name: "details" }
  ];