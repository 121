import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assets: [], // assets
  assetWellsMap: {}, // asset id => wells map
  activeAssetId: null, // current selected asset
};

const assetSelectorSlice = createSlice({
  name: "asset_selector",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setAssets: (state, action) => {
      state.assets = action.payload;
    },
    setActiveAssetId: (state, action) => {
      state.activeAssetId = action.payload;
    },
    setActiveAssetByName: (state, action) => {
      const { assetName } = action.payload;
      const asset = state.assets.find(
        (asset) => asset.asset_name === assetName
      );
      state.activeAssetId = asset.id;
    },
    setAssetWells: (state, action) => {
      const { assetId, wells } = action.payload;
      state.assetWellsMap[assetId] = wells;
    },
  },
});

export const {
  reset,
  setAssets,
  setActiveAssetId,
  setActiveAssetByName,
  setAssetWells,
} = assetSelectorSlice.actions;

export const selectAssets = (state) => state.asset_selector.assets;

export const selectAssetById = (state, assetId) => {
  if (assetId == null || state.asset_selector.assets == null) return null;
  return state.asset_selector.assets.find((asset) => asset.id === assetId);
};

export const selectActiveAssetId = (state) =>
  state.asset_selector.activeAssetId;

export const selectActiveAsset = (state) => {
  if (state.asset_selector.activeAssetId == null) return null;
  return state.asset_selector.assets.find(
    (asset) => asset.id === state.asset_selector.activeAssetId
  );
};

export const selectAssetWells = (state, assetId) => {
  return state.asset_selector.assetWellsMap[assetId];
};

export const selectActiveWells = (state) => {
  let wells = [];
  const assetId = state.asset_selector.activeAssetId;
  if (assetId != null) {
    wells = state.asset_selector.assetWellsMap[assetId];
  }
  return wells;
};

export const selectWellByAssetAndId = (state, assetId, wellId) => {
  if (
    assetId == null ||
    wellId == null ||
    state.asset_selector.assetWellsMap == null
  )
    return null;
  let assetWells = state.asset_selector.assetWellsMap[assetId];
  if (assetWells) {
    const well = assetWells.find((well) => well.uwi === wellId);
    if (well) return well;
  }
  return null;
};

export const selectWellById = (state, wellId) => {
  if (wellId == null || state.asset_selector.assetWellsMap == null) return null;
  const assetIds = Object.keys(state.asset_selector.assetWellsMap);
  for (const assetId of assetIds) {
    let assetWells = state.asset_selector.assetWellsMap[assetId];
    if (assetWells) {
      const well = assetWells.find((well) => well.uwi === wellId);
      if (well) return well;
    }
  }
  return null;
};

export default assetSelectorSlice.reducer;
