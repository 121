import { Form, Tabs } from 'antd'
import React, { useEffect, useMemo } from 'react'
import IndividualSetting from './IndividualSetting'

import classes from '../dca-reporting.module.scss'
import { useDCAReport } from '../DCAReportProvider'

const WellSetting = () => {
  const { sourceProject } = useDCAReport()

  const { wells = [] } = sourceProject || {}

  const form = Form.useFormInstance()

  const tabs = useMemo(
    /**
     * 
     * @returns {import('antd').TabsProps['items']}
     */
    () => wells.map(well => ({
      key: well.uwi,
      label: well.well_name,
      children: <IndividualSetting well={well} />
    })),
    [wells]
  )

  useEffect(() => {
    tabs.forEach(tab => {
      form.setFieldValue(['wells', tab.key, 'sync'], true)
    })
  }, [form, tabs])

  if (!sourceProject) return null

  return (
    <Tabs
      tabPosition="left"
      className={classes.tabs}
      items={tabs}
      style={{
        height: 680
      }}
    />
  )
}

export default WellSetting