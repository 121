import { get, post, put, remove } from "./base";

export function getAfeDict(onSuccess, onError) {
	return get(
		`/api/economics/afe/dict/`,
		{},
		{},
		(res) => {
			onSuccess?.(res.data);
		},
		(error) => {
			console.error(error);
			onError?.(error);
		}
	);
}

export function getWellAfe(uwi, params, onSuccess, onError) {
	return get(
		`/api/economics/well/${ uwi }/afe/`,
		params,
		{},
		(res) => {
			onSuccess?.(res.data);
		},
		(error) => {
			console.error(error);
			onError?.(error);
		}
	);
}

/**
 * 
 * @param {*} uwi 
 * @param {*} data 
 * {
		"uwi": "42329397390000",
		"afe_name": "AFE 2",
		"creator_id": 4,
		"approval_status": "No",
		"notes": null,
		"cost_items": [
				{
						"cost_item_id": 71,
						"drilling_cost_per_unit": 24668.13,
						"drilling_days_or_units": 2,
						"drilling_cost": 49336.26,
						"completion_cost_per_unit": 28548.51,
						"completion_days_or_units": 2,
						"completion_cost": 57097.02,
						"total_cost": 2816953423.95
				},
				{
						"cost_item_id": 72,
						"drilling_cost_per_unit": 18543.0,
						"drilling_days_or_units": 2,
						"drilling_cost": 37086.0,
						"completion_cost_per_unit": 20026.44,
						"completion_days_or_units": 3,
						"completion_cost": 60079.32,
						"total_cost": 2228101661.52
				}
		]
}
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function addWellAfe(uwi, data, onSuccess, onError) {
	return post(
		`/api/economics/well/${ uwi }/afe/`,
		data,
		null,
		{},
		(res) => {
			onSuccess?.(res.data);
		},
		(error) => {
			console.error(error);
			onError?.(error);
		},
		true,
		true
	);
}

/**
 * 
 * @param {*} uwi 
 * @param {*} data 
 * {
		"id": 286,
		"uwi": "42329397390000",
		"afe_name": "AFE 2",
		"approval_status": "Yes",
		"approved_by_id": 3,
		"approval_date": "2019-08-31T00:00:00",
		"notes": null,
		"cost_items": [
				{
						"id": 40705,
						"drilling_cost_per_unit": "24668.13",
						"drilling_days_or_units": 2,
						"drilling_cost": "49336.26",
						"completion_cost_per_unit": "28548.51",
						"completion_days_or_units": 2,
						"completion_cost": "57097.02",
						"total_cost": "2816953423.95",
						"afe_id": 286,
						"cost_item_id": 71
				},
				{
						"id": 40706,
						"drilling_cost_per_unit": "18543.00",
						"drilling_days_or_units": 2,
						"drilling_cost": "37086.00",
						"completion_cost_per_unit": "20026.44",
						"completion_days_or_units": 3,
						"completion_cost": "60079.32",
						"total_cost": "2228101661.52",
						"afe_id": 286,
						"cost_item_id": 72
				}
		]
}
 * @param {*} onSuccess 
 * @param {*} onError 
 * @returns 
 */
export function updateWellAfe(uwi, data, onSuccess, onError) {
	return put(
		`/api/economics/well/${ uwi }/afe/`,
		data,
		null,
		{},
		(res) => {
			onSuccess?.(res.data);
		},
		(error) => {
			console.error(error);
			onError?.(error);
		},
		true
	);
}

export function deleteWellAfe(uwi, afe_id, cost_item_ids, onSuccess, onError) {
	const data = {};
	if (afe_id !== null) {
		data.afe_id = afe_id;
	}
	if (cost_item_ids !== null) {
		data.cost_item_ids = cost_item_ids;
	}
	return remove(
		`/api/economics/well/${ uwi }/afe/`,
		data,
		null,
		{},
		(res) => {
			onSuccess?.(res.data);
		},
		(error) => {
			console.error(error);
			onError?.(error);
		},
		true
	);
}
