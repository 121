import React, { useState, useEffect, useCallback } from "react";

import { useSelector } from "react-redux";
import {
  selectActiveWells,
  selectActiveAssetId,
} from "features/asset/assetSelectorSlice";
import LMap from "components/map/Map";
import { getTotalProductionsData } from "service/productionData";
import { getMapFeatures } from "utils/map";
import SectionSetting from "components/common/SectionSetting";

const zoom = 15;

const WellsMap = (props) => {
  const {
    product,
    selectedWellIds,
    onWellSelected,
    onWellsSelected,
    loadingIconActions,
  } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const activeAssetId = useSelector(selectActiveAssetId);
  const wells = useSelector(selectActiveWells);
  const [productionsData, setProductionsData] = useState(null);

  const [features, setFeatures] = useState([]);
  // const [zoom] = useState(15);
  const [center, setCenter] = useState([0.0, 46.036]);
  const [bounds, setBounds] = useState(null);

  const refreshData = useCallback(() => {
    let products = [product];
    let plus = false;
    if (product.includes("+")) {
      plus = true;
      products = product.split("+");
    }
    showLoadingIcon && showLoadingIcon();
    getTotalProductionsData(
      null,
      activeAssetId,
      products,
      null,
      null,
      (result) => {
        if (plus) {
          let data = {};
          for (let wellId of Object.keys(result)) {
            let prdData = result[wellId];
            data[wellId] = {};
            let prdValue = 0;
            for (let prdKey of Object.keys(prdData)) {
              prdValue += prdData[prdKey];
            }
            data[wellId][product] = prdValue;
          }
          setProductionsData(data);
          hideLoadingIcon && hideLoadingIcon();
        } else {
          setProductionsData(result);
          hideLoadingIcon && hideLoadingIcon();
        }
      },
      (error) => {
        hideLoadingIcon && hideLoadingIcon();
      }
    );
  }, [activeAssetId, product, hideLoadingIcon, showLoadingIcon]);

  useEffect(() => {
    if (!product || !activeAssetId) {
      return;
    }
    refreshData();
  }, [activeAssetId, product, refreshData]);

  useEffect(() => {
    if (productionsData && wells && product) {
      const [tempFeatures, mapCenter, mapBounds] = getMapFeatures(
        productionsData,
        wells,
        product
      );
      if (!tempFeatures || isNaN(tempFeatures[0]?.Value)) return;
      setFeatures(tempFeatures);
      setCenter(mapCenter);
      setBounds(mapBounds);
    }
  }, [
    productionsData,
    wells,
    product,
  ]);

  function handleObjectiveSelected(objective) {
    let well = wells.find((well) => well.uwi === objective.apiId);
    onWellSelected(well);
  }

  function handleAreaSelected(objectives) {
    if (objectives && objectives.length > 0) {
      let tempWells = objectives.map((objective) => {
        return wells.find((well) => well.uwi === objective.apiId);
      });
      onWellsSelected(tempWells);
    }
  }

  return (
    <div className="cards-container h-100">
      <div className="cards-header d-flex flex-row justify-content-between align-items-center">
        <span className="header-title">Map</span>
        <div className="header-menu-container d-flex flex-row">
          <div className="cards-setting-btn">
            <SectionSetting disable />
          </div>
        </div>
      </div>
      <div className="cards-content">
        <div className="chart-container">
          <LMap
            objectives={features ? features : []}
            center={center}
            zoom={zoom}
            bounds={bounds}
            selectedObjectiveIds={
              selectedWellIds && selectedWellIds.length > 0
                ? new Set(selectedWellIds)
                : new Set()
            }
            objectiveSelectedHandler={handleObjectiveSelected}
            areaSelectedHandler={handleAreaSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default WellsMap;
