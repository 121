import React, { Fragment, useEffect, useState, useMemo } from "react";
import PpSelector from "./PpSelector";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCrossSectionSelectedWells, setActiveCrossSection, setCrossSectionList,
    setCrossSectionSelectedWells, setSelectedWells, selectActiveCrossSection, selectCrossSectionList
} from "features/petrophysics/petrophysicsSlice";
import { Button, Col, Input, Row, Switch } from "antd";
import AddProjectForm from "components/project/AddProjectForm";
import { getCrossSections, saveCrossSection } from "service/petrophysics";

const Context = React.createContext({
    name: 'Default',
});

const PpXSectionSelector = (props) => {
    const { title, save, viewSwitch, onViewSwitch } = props;
    const { loadingIconActions } = props;
    const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
    const dispatch = useDispatch();
    const selectedWellsInSelectedSelector = useSelector(selectCrossSectionSelectedWells);
    const activeCrossSection = useSelector(selectActiveCrossSection);
    const crossSectionList = useSelector(selectCrossSectionList);

    const [showSaveDialog] = useState(false);
    const [inputting, setInputting] = useState(false);
    const [inputtingSaveAs, setInputtingSaveAs] = useState(false);
    const [crossSectionName, setCrossSectionName] = useState('');

    const handleSave = () => {
        if (!crossSectionName || !selectedWellsInSelectedSelector || selectedWellsInSelectedSelector.length === 0) {
            console.warn("no cross section name or no selected list to save.");
            return;
        }
        showLoadingIcon && showLoadingIcon();
        saveCrossSection({
            name: crossSectionName,
            uwis: selectedWellsInSelectedSelector,
        },
            (res) => {
                dispatch(setActiveCrossSection(crossSectionName));
                hideLoadingIcon && hideLoadingIcon();
            },
            (e) => {
                console.error("save cross section failed.", e);
                hideLoadingIcon && hideLoadingIcon();
            });
    };

    useEffect(() => {
        showLoadingIcon && showLoadingIcon();
        getCrossSections(
            (res) => {
                if (!res) return;
                let csList = [];
                res.map(cs => csList.push({ label: cs.name, name: cs.name, wells: cs.wells }));
                dispatch(setCrossSectionList(csList));
                hideLoadingIcon && hideLoadingIcon();
            },
            (e) => {
                console.error("load cross sections failed.", e);
                hideLoadingIcon && hideLoadingIcon();
            }
        )
    }, [activeCrossSection, dispatch, showLoadingIcon, hideLoadingIcon]);

    const contextValue = useMemo(
        () => ({
            // name: activeCrossSection ? activeCrossSection[0] : '',
            name: '',
        }),
        [],
    );

    const handleCrossSectionSelected = (selectionId, status) => {
        // deselect a cross section, remove selected and/or other selection
        if (!status) {
            dispatch(setCrossSectionSelectedWells([]));
            dispatch(setActiveCrossSection([]));
            dispatch(setSelectedWells([]));
            return;
        }

        // select a cross section, select wells, and select selected
        let cs = crossSectionList.filter(item => item.label === selectionId.label);

        if (!cs || cs.length === 0) return;
        let wells = cs[0].wells;

        if (!wells || wells.length === 0) return;
        let sel = wells.map(item => item.uwi);

        dispatch(setCrossSectionSelectedWells(sel));
        dispatch(setSelectedWells(sel));
        dispatch(setActiveCrossSection(selectionId));
    }

    return (
        <Context.Provider value={contextValue}>
            <Fragment>
                <PpSelector
                    dataList={crossSectionList}
                    selected={activeCrossSection}
                    title={title || 'Tops'}
                    needSort={true}
                    onOptionSelected={handleCrossSectionSelected}
                >
                    {save && !inputting && selectedWellsInSelectedSelector && (selectedWellsInSelectedSelector.length > 0) && <Button
                        onClick={() => {
                            if (crossSectionName) {
                                handleSave();
                                return;
                            }
                            setInputting(true);
                            setInputtingSaveAs(false)
                        }}
                        style={{ marginTop: '0.7em' }}
                    >Save</Button>}
                    {(inputting || inputtingSaveAs) && <Row>
                        <Col span={19}><Input
                            value={crossSectionName}
                            style={{ backgroundColor: 'white' }}
                            onChange={(e) => setCrossSectionName(e.target.value)}
                            placeholder="type name..."
                        /></Col>
                        <Col span={4}><Button
                            style={{ height: '2.5em' }}
                            onClick={() => {
                                // save project
                                handleSave();
                                setInputting(false);
                                setInputtingSaveAs(false);
                            }}
                        >OK</Button></Col></Row>}
                    {save && !inputtingSaveAs && activeCrossSection[0] && <Button
                        onClick={() => { setInputting(false); setInputtingSaveAs(true) }}
                    >Save As...
                    </Button>}
                    {viewSwitch && (<p
                        style={{ textAlign: 'center', marginTop: '0.5em' }}
                    >
                        <span style={{ fontSize: 10 }}>Map</span>
                        <span>
                            <Switch
                                onChange={onViewSwitch}
                                style={{ width: '30px' }}
                            />
                        </span>
                        <span style={{ fontSize: 10 }}>Log</span>
                    </p>)}
                </PpSelector>
                {
                    showSaveDialog && <AddProjectForm />
                }
            </Fragment>
        </Context.Provider>
    )
};
export default PpXSectionSelector;