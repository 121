import { Form, Input, InputNumber, Typography } from 'antd'
import FormSection from 'components/form-section'
import React from 'react'

const units = {
  pref: {
    FIELD: 'psia',
    METRIC: 'bar'
  },
  water_fvf_at_pref: {
    FIELD: 'psia',
    METRIC: 'rm3/sm3'
  },
  water_compressibility: {
    FIELD: '/psi',
    METRIC: '1/bar'
  },
  water_viscosity_at_pref: {
    FIELD: 'cP',
    METRIC: 'cP'
  },
  water_viscosity: {
    FIELD: '/psi',
    METRIC: '1/bar'
  }
}

const WaterTable = () => {
  const simulationUnit = Form.useWatch(["GENERAL", "unit"])

  return (
    <FormSection title="Water">
      <div className="vstack gap-2">
        <div className="hstack gap-2 align-items-center">
          <Typography.Text className="text-light" style={{ width: 200 }}>
            Reference Pressure (Pref)
          </Typography.Text>
          <Form.Item name={['PVT', 'water', 'pref', 'value']} className="mb-0">
            <InputNumber className="w-100" step={0.01} />
          </Form.Item>
          <Typography.Text className="text-light">
            {units.pref[simulationUnit] ?? ''}
          </Typography.Text>
        </div>
        <div className="hstack gap-2 align-items-center">
          <Typography.Text className="text-light" style={{ width: 200 }}>
            Water FVF at Pref
          </Typography.Text>
          <Form.Item name={['PVT', 'water', 'water_fvf_at_pref', 'value']} className="mb-0">
            <InputNumber className="w-100" step={0.01} />
          </Form.Item>
          <Typography.Text className="text-light">
            {units.water_fvf_at_pref[simulationUnit] ?? ''}
          </Typography.Text>
        </div>
        <div className="hstack gap-2 align-items-center">
          <Typography.Text className="text-light" style={{ width: 200 }}>
            Water Compressibility
          </Typography.Text>
          <Form.Item name={['PVT', 'water', 'water_compressibility', 'value']} className="mb-0">
            <InputNumber className="w-100" step={0.01} />
          </Form.Item>
          <Typography.Text className="text-light">
            {units.water_compressibility[simulationUnit] ?? ''}
          </Typography.Text>
        </div>
        <div className="hstack gap-2 align-items-center">
          <Typography.Text className="text-light" style={{ width: 200 }}>
            Water viscosibility at Pref
          </Typography.Text>
          <Form.Item name={['PVT', 'water', 'water_viscosity_at_pref', 'value']} className="mb-0">
            <InputNumber className="w-100" step={0.01} />
          </Form.Item>
          <Typography.Text className="text-light">
            {units.water_viscosity_at_pref[simulationUnit] ?? ''}
          </Typography.Text>
        </div>
        <div className="hstack gap-2 align-items-center">
          <Typography.Text className="text-light" style={{ width: 200 }}>
            Water viscosibility
          </Typography.Text>
          <Form.Item name={['PVT', 'water', 'water_viscosity', 'value']} className="mb-0">
            <InputNumber className="w-100" step={0.01} />
          </Form.Item>
          <Typography.Text className="text-light">
            {units.water_viscosity[simulationUnit] ?? ''}
          </Typography.Text>
        </div>
      </div>
    </FormSection>
  )
}

export default WaterTable