import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLogout, selectUserData } from "features/user/userSlice";
import { Col, Row } from "antd";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Image } from "antd";
import { setRoutes } from "features/nav/routesSlice";
import { RequireAuth } from "components/auth";

const proState = {
  routes: [
    {
      id: "1",
      level: 0,
      active: false,
      title: "Asset Viewer",
      link: "/asset-viewer",
      iconCls: "icon-01",
      routes: [],
    },
    {
      id: "2",
      level: 0,
      active: false,
      title: "Well Viewer",
      link: "/well-viewer",
      iconCls: "icon-02",
      routes: [],
    },
    {
      id: "3",
      level: 0,
      active: false,
      title: "Production Analyzer",
      link: "#",
      iconCls: "icon-03",
      routes: [
        {
          id: "32",
          level: 1,
          active: false,
          title: "Decline Analysis",
          link: "#",
          routes: [
            {
              id: "320",
              level: 1,
              title: "Project Summary",
              link: "/project_summary/dca",
            },
            {
              id: "321",
              level: 2,
              title: "New Project",
              link: "/decline_analysis/create_project/",
            },
            {
              id: "322",
              level: 2,
              title: "DCA Engine",
              link: "/decline_analysis/analyzer/",
            },
            {
              id: "323",
              level: 2,
              title: "Reporting",
              link: "/decline_analysis/reporting/",
            },
          ],
        },
        {
          id: "34",
          level: 1,
          active: false,
          title: "Decline Management",
          link: "#",
          routes: [
            {
              id: "340",
              level: 1,
              title: "Project Summary",
              link: "/project_summary/dcm",
            },
            {
              id: "341",
              level: 2,
              title: "New Project",
              link: "/decline_analysis/create_project/",
            },
            {
              id: "342",
              level: 2,
              title: "Decline Management Engine",
              link: "/decline_analysis/management/",
            },
          ],
        },
        {
          id: "33",
          level: 2,
          active: false,
          title: "Type Curve Engine",
          link: "#",
          routes: [
            {
              id: "330",
              level: 1,
              title: "Project Summary",
              link: "/project_summary/type_curve",
            },
            {
              id: "331",
              level: 2,
              title: "New Project",
              link: "/type_curve/create_project/",
            },
            {
              id: "332",
              level: 2,
              title: "Type Curve Engine",
              link: "/type_curve/analyzer/",
            },

            {
              id: "333",
              level: 2,
              title: "Reporting",
              link: "/type_curve/reporting/",
            },
          ],
        },
      ],
    },
  ],

  activeRoutes: [],
};

const wpState = {
  routes: [
    {
      id: "1",
      level: 0,
      active: false,
      title: "Asset Viewer",
      link: "/asset-viewer",
      iconCls: "icon-01",
      routes: [],
    },
    {
      id: "2",
      level: 0,
      active: false,
      title: "Well Viewer",
      link: "/well-viewer",
      iconCls: "icon-02",
      routes: [],
    },
    {
      id: "3",
      level: 0,
      active: false,
      title: "Well Planner",
      iconCls: "icon-03",
      link: "#",
      routes: [
        {
          id: "30",
          level: 1,
          title: "Project Summary",
          link: "/project_summary/well_planner",
          // icon: "/images/project/Group4.png",
        },
        {
          id: "31",
          level: 1,
          title: "New Project",
          link: "/well_planner/create_project/",
        },
        {
          id: "32",
          level: 1,
          title: "Well Planner",
          link: "/well_planner/",
        },
        {
          id: "33",
          level: 1,
          title: "Reporting",
          link: "/well_planner/reporting/",
        },
      ],
    },
  ],

  activeRoutes: [],
};

const dmState = {
  routes: [
    {
      id: "1",
      level: 0,
      active: false,
      title: "Asset Viewer",
      link: "/asset-viewer",
      iconCls: "icon-01",
      routes: [],
    },
    {
      id: "2",
      level: 0,
      active: false,
      title: "Well Viewer",
      link: "/well-viewer",
      iconCls: "icon-02",
      routes: [],
    },
    {
      id: "4",
      level: 0,
      active: false,
      title: "Data Manager",
      link: "#",
      iconCls: "icon-04",
      routes: [
        {
          id: "40",
          level: 1,
          title: "Settings",
          link: "#",
          routes: [
            {
              id: "402",
              level: 2,
              title: "Dictionary",
              link: "/dictionary_editor/",
            },
            {
              id: "403",
              level: 2,
              title: "Well Asset",
              link: "/well_asset/",
            },
          ],
        },
        {
          id: "41",
          level: 1,
          title: "Data Input",
          link: "/petrophysics_input/",
          routes: [],
        },
        {
          id: "42",
          level: 1,
          active: false,
          title: "Well",
          link: "#",
          routes: [
            {
              id: "421",
              level: 2,
              title: "Well Summary",
              link: "/well_summary/",
            },
            {
              id: "422",
              level: 2,
              title: "Well Editor",
              link: "/well_editor/",
            },
            {
              id: "423",
              level: 2,
              title: "ThreeDViewer",
              link: "/well_threed/",
            },
          ],
        },
        {
          id: "43",
          level: 1,
          active: false,
          title: "Petrophysics",
          link: "#",
          routes: [
            {
              id: "431",
              level: 2,
              title: "Log Viewer",
              link: "/petrophysics_logviewer/",
            },

            {
              id: "432",
              level: 2,
              title: "Well Correlation",
              link: "/petrophysics_correlation/",
            },
            {
              id: "433",
              level: 2,
              title: "Cross Section",
              link: "/petrophysics_crosssection/",
            },
            {
              id: "434",
              level: 2,
              title: "Property Log",
              link: "/petrophysics_propertylog/",
            },
          ],
        },
        {
          id: "44",
          level: 1,
          title: "Completion",
          link: "#",
          routes: [
            {
              id: "441",
              level: 2,
              title: "Data Editor",
              link: "/completion_editor/",
            },
          ],
        },
        {
          id: "45",
          level: 1,
          active: false,
          title: "Production",
          link: "#",
          routes: [
            {
              id: "451",
              level: 2,
              title: "Production Viewer",
              link: "/production_viewer/",
            },
            {
              id: "452",
              level: 2,
              title: "Data Editor",
              link: "/production_editor/",
            },
          ],
        },
        {
          id: "46",
          level: 1,
          active: false,
          title: "Operation",
          link: "#",
          routes: [
            {
              id: "461",
              level: 2,
              title: "Data Editor",
              link: "/operation_editor/",
            },
          ],
        },
        {
          id: "47",
          level: 1,
          active: false,
          title: "Economics",
          link: "#",
          routes: [
            {
              id: "462",
              level: 2,
              title: "Basic",
              link: "/economics/basic",
            },
            {
              id: "463",
              level: 2,
              title: "Editor",
              link: "/economics/editor",
            },
          ],
        },
      ],
    },
  ],

  activeRoutes: [],
};

const coState = {
  routes: [
    {
      id: "1",
      level: 0,
      active: false,
      title: "Completion Optimizer",
      iconCls: "icon-01",
      link: "#",
      routes: [
        {
          id: "50",
          level: 1,
          title: "Project Summary",
          link: "/completion_optimizer/project_summary",
        },
        {
          id: "51",
          level: 1,
          title: "Project Settings",
          link: "/completion_optimizer/project_settings",
        },
        {
          id: "52",
          level: 1,
          title: "Run",
          link: "/completion_optimizer/run",
        },
        {
          id: "53",
          level: 1,
          title: "Results",
          link: "/completion_optimizer/results",
        },
      ],
    },
  ],

  activeRoutes: [],
}

const { Title } = Typography;
const MODULES = [
  {
    ASSET_VIEWER: {
      img: "/static/images/home/asset.png",
      title: "Asset Viewer",
      url: "/asset-viewer",
      menu: proState,
    },
  },
  {
    WELL_VIEWER: {
      img: "/static/images/home/wv.png",
      title: "Well Viewer",
      url: "/well-viewer",
      menu: proState,
    },
  },
  {
    PRODUCTION_ANALYZER: {
      img: "/static/images/home/pa.png",
      title: "Production Analyzer",
      url: "/decline_analysis/analyzer/",
      menu: proState,
    },
  },
  {
    DATA_MANAGER: {
      img: "/static/images/home/dm.png",
      title: "Data Manager",
      url: "/petrophysics_input/",
      menu: dmState,
    },
  },
  {
    WELL_PLANNER: {
      img: "/static/images/home/wp.png",
      title: "Well Planner",
      url: "well_planner/",
      menu: wpState,
    },
  },
  {
    COMPLETION_OPTIMIZER: {
      img: "/static/images/home/co.png",
      title: "Completion Optimizer",
      url: "/completion_optimizer/",
      menu: coState,
    },
  },
];
const ModuleBlock = (props) => {
  const [size] = useState("large");
  const { img, title, url, menu } = props;
  let navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: "100%",
        height: "15vh",
        alignItems: "left",
        alignContent: "start",
        textAlign: "left",
        verticalAlign: "middle",
      }}
      onClick={() => {
        dispatch(setRoutes(menu.routes || proState.routes));
        navigate(url);
      }}
      size={size}
    >
      <Row style={{ alignItems: "center", cursor: "pointer" }}>
        <Image
          width={120}
          style={{ marginRight: "10px" }}
          src={img || "/images/project/Group2.png"}
          preview={false}
        />
        <Title type="secondary" style={{ color: "#ccc", marginLeft: "12px" }}>
          {title}
        </Title>
      </Row>
    </div>
  );
};
const Homepage = (props) => {
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  function getUserName() {
    if (userData == null) {
      return "";
    }

    return userData.first_name + " " + userData.last_name;
  }

  return (
    <RequireAuth>
      <div style={{ width: "100%", height: "70px" }}>
        <div className="navbar-header" style={{ backgroundColor: "#003" }}>
          <div style={{ fontSize: "24px", marginLeft: "20px" }}>
            <img
              style={{ height: "6em" }}
              src="/static/images/logo-text2.png"
              alt="logo"
            />
          </div>
          <div className="d-flex align-items-center">
            <div
              className="dropdown d-inline-block"
              style={{ marginRight: "30px" }}
            >
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src="/static/images/users/avatar-4.jpg"
                  alt="Header Avatar"
                />
                <span className="d-none d-xl-inline-block ms-1 fw-medium">
                  {getUserName()}
                </span>
                <i className="uil-angle-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <div className="dropdown-item">
                  <i className="uil uil-user-circle align-middle text-muted me-1"></i>{" "}
                  <span className="align-middle">View Profile</span>
                </div>
                <div className="dropdown-item">
                  <i className="uil uil-wallet align-middle me-1 text-muted"></i>{" "}
                  <span className="align-middle">My Wallet</span>
                </div>
                <div className="dropdown-item d-block">
                  <i className="uil uil-cog align-middle me-1 text-muted"></i>{" "}
                  <span className="align-middle">Settings</span>{" "}
                  <span className="badge bg-soft-success rounded-pill mt-1 ms-2">
                    03
                  </span>
                </div>
                <a className="dropdown-item" href="/utility/auth-lock-screen">
                  <i className="uil uil-lock-alt align-middle me-1 text-muted"></i>{" "}
                  <span className="align-middle">Lock screen</span>
                </a>

                <a className="dropdown-item" href="/accounts/password/change/">
                  <i className="align-middle text-muted me-1 mdi mdi-key"></i>{" "}
                  Change Password
                </a>

                <Link
                  className="dropdown-item text-danger"
                  onClick={() => {
                    dispatch(setLogout(null));
                  }}
                  to="/logout/"
                >
                  <i className="align-middle text-muted me-1 text-danger mdi mdi-logout-variant"></i>
                  Log Out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="layout-wrapper">
        <main
          style={{
            width: "80%",
            marginLeft: "10%",
            marginTop: "18vh",
            alignItems: "start",
          }}
        >
          <Row gutter={[32, 48]}>
            {MODULES.map((mod, index) => {
              let item = mod[Object.keys(mod)[0]];
              return (
                <Col key={index} className="gutter-row" span={12}>
                  <ModuleBlock
                    url={item.url}
                    img={item.img}
                    title={item.title}
                    menu={item.menu}
                  />
                </Col>
              );
            })}
          </Row>
          <footer className="footer"></footer>
        </main>
      </div>
    </RequireAuth>
  );
};

export default Homepage;
