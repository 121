import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectAvailableWellParams,
  selectInterpoParams,
  setUsedWellInterpoParams,
  selectUsedWellInterpoParams,
  setReInterpolate,
} from "features/well_planner/plannerSlice";

import { ModelOptions } from "app/codes";
import { message } from "antd";

function InterpolateParamsSettings(props) {
  const { nextStep } = props;

  const availableWellParams = useSelector(selectAvailableWellParams);
  const interpoParams = useSelector(selectInterpoParams);
  const usedWellInterpoParams = useSelector(selectUsedWellInterpoParams);
  const dispatch = useDispatch();

  const [geoParams, setGeoParams] = useState(null);

  useEffect(() => {
    if (
      availableWellParams == null ||
      interpoParams == null ||
      interpoParams.length === 0
    ) {
      return;
    }

    let wellParams = [];
    for (let wellId of Object.keys(availableWellParams)) {
      wellParams = [...wellParams, ...availableWellParams[wellId]];
    }

    let wellParamsSet = new Set(wellParams);
    let params = interpoParams.map((item, index) => {
      let usedInterpoParam = null;
      if (usedWellInterpoParams != null && usedWellInterpoParams.length > 0) {
        usedInterpoParam = usedWellInterpoParams.find(
          (usedParam) => usedParam.param === item
        );
      }
      return {
        name: item,
        selected: usedInterpoParam != null,
        available: wellParamsSet.has(item),
        model: usedInterpoParam != null ? usedInterpoParam.method : "nearest",
      };
    });
    setGeoParams(params);
  }, [availableWellParams, interpoParams, usedWellInterpoParams]);

  function handleParamSelectedChanged(index) {
    let tempParams = [...geoParams];
    let modifiedItem = { ...geoParams[index] };
    modifiedItem.selected = !modifiedItem.selected;
    tempParams[index] = modifiedItem;
    setGeoParams(tempParams);
  }

  function handleModelSelect(e, index) {
    let tempParams = [...geoParams];
    let modifiedItem = { ...geoParams[index] };
    modifiedItem.model = e.target.value;
    tempParams[index] = modifiedItem;
    setGeoParams(tempParams);
  }

  function handleApply(e) {
    let useParams = geoParams.filter((item) => item.selected);
    if (useParams?.length < 1) {
      message.error("Please select at least 1 parameter!");
      return;
    }

    dispatch(
      setUsedWellInterpoParams(
        useParams.map((item) => {
          return {
            param: item.name,
            method: item.model,
          };
        })
      )
    );
    dispatch(setReInterpolate(true));
    nextStep();
  }

  function generateGeoParamsSelector() {
    if (geoParams == null || geoParams.length === 0) {
      return;
    }

    return geoParams.map((param, index) => {
      return (
        <div
          key={"param_row_" + index}
          className="list-item d-flex justify-content-between align-items-center mb-2"
        >
          <div className="d-flex">
            <input
              id={"g_" + index}
              className="py-2"
              type="checkbox"
              onChange={(e) => {
                handleParamSelectedChanged(index);
              }}
              checked={param.selected}
              disabled={!param.available}
            ></input>
            <div className="ms-4">{param.name}</div>
          </div>
          <select
            id={"sg_" + index}
            value={param.model}
            onChange={(e) => {
              handleModelSelect(e, index);
            }}
          >
            {ModelOptions.map((item, i) => {
              return (
                <option key={"param_" + i} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      );
    });
  }
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column">
        <div className="py-4">Interpolation</div>
        <div className="panel-container d-flex flex-column overflow-auto hide-scrollbar p-4" style={{ height: "320px" }}>
          {generateGeoParamsSelector()}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center py-2">
        <button className="btn-custom me-2" onClick={handleApply}>
          Apply
        </button>
        <button
          className="btn-custom"
          onClick={(e) => {
            props.close();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export { InterpolateParamsSettings };
