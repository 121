import * as d3 from "d3";

export function drawCorrChart(svgRef, corrParams, corrData, dimensions) {
  let data = [];
  for (let i = 0; i < corrData.length; i++) {
    for (let j = 0; j < corrData[i].length; j++) {
      let value = corrData[i][j];
      data.push({
        x: corrParams[i],
        y: corrParams[j],
        value: +value,
      });
    }
  }

  let maxValue = 0;
  for (let row of corrData) {
    maxValue = Math.max(maxValue, Math.max(...row));
  }

  let num = corrParams.length;
  // let num = Math.sqrt(corrData.length);

  let margin = {
    top: dimensions.cellSize / 2,
    right: dimensions.cellSize / 2 + 80,
    bottom: dimensions.cellSize / 2,
    left: dimensions.cellSize / 2,
  };
  let width = dimensions.width - margin.left - margin.right;
  let height = dimensions.height - margin.top - margin.bottom;
  let maxRadius = width / (num * 2);
  let domain = corrParams; //d3.set(corrParams).values();

  let color = d3
    .scaleLinear()
    .domain([-1, 0, 1])
    .range(["#B22222", "#fff", "#008000"]);

  let x = d3.scalePoint().range([0, width]).domain(domain);
  let y = d3.scalePoint().range([0, height]).domain(domain);
  let xSpace = x.range()[1] - x.range()[0];
  let ySpace = y.range()[1] - y.range()[0];

  d3.select("g").remove();

  let svg = d3
    .select(svgRef)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  let cor = svg
    .selectAll(".cor")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "cor")
    .attr("transform", function (d) {
      return "translate(" + x(d.x) + "," + y(d.y) + ")";
    });

  cor
    .append("rect")
    .attr("width", xSpace / (num - 1))
    .attr("height", ySpace / (num - 1))
    .attr("x", -xSpace / (num - 1) / 2)
    .attr("y", -ySpace / (num - 1) / 2);

  cor
    .filter(function (d) {
      var ypos = domain.indexOf(d.y);
      var xpos = domain.indexOf(d.x);
      for (var i = ypos + 1; i < num; i++) {
        if (i === xpos) return false;
      }
      return true;
    })
    .append("text")
    .attr("y", 2.5)
    .text(function (d) {
      if (d.x === d.y) {
        return d.x;
      } else {
        return d.value.toFixed(2);
      }
    })
    .style("fill", function (d) {
      if (d.value === 1) {
        return "#fff";
      } else {
        return color(d.value);
      }
    })
    .style('font-size', Math.max((9 - num), 2) + 'px')
    ;

  cor
    .filter(function (d) {
      var ypos = domain.indexOf(d.y);
      var xpos = domain.indexOf(d.x);
      for (var i = ypos + 1; i < num; i++) {
        if (i === xpos) return true;
      }
      return false;
    })
    .append("circle")
    .attr("r", function (d) {
      let r = maxRadius * (Math.abs(d.value) + 0.1);
      return r;
    })
    .style("fill", function (d) {
      if (d.value === 1) {
        return "#fff";
      } else {
        return color(d.value);
      }
    });

  var aS = d3
    .scaleLinear()
    .range([-margin.top + 5, height + margin.bottom - 5])
    .domain([1, -1]);

  var yA = d3.axisRight().scale(aS).tickPadding(7);

  var aG = svg
    .append("g")
    .attr("class", "y axis")
    .call(yA)
    .attr("transform", "translate(" + (width + margin.right / 2) + " ,0)");

  var iR = d3.range(-1, 1.01, 0.01);
  var h = height / iR.length + 3;
  iR.forEach(function (d) {
    aG.append("rect")
      .style("fill", color(d))
      .style("stroke-width", 0)
      .style("stoke", "none")
      .attr("height", h)
      .attr("width", 10)
      .attr("x", 0)
      .attr("y", aS(d));
  });
}
