import React, { useMemo, useRef, useState } from 'react'
import { SALE_PRICE_PARAMS } from './consts'
import TimeSeriesChart from 'components/common/TimeSeriesChart'
import { Select } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { getCommodityPrices } from 'service/commodity'
import { isBefore } from 'date-fns'
import { groupBy, merge, orderBy, pick, uniq } from 'lodash'
import { formatDate } from 'utils/dateUtil'
import { isDeepEmpty } from 'utils/isDeepEmpty'

const WellSalePricesChart = ({
  records = []
}) => {
  const now = useRef(new Date())
  const [visibleCurves, setVisibleCurves] = useState(Object.keys(SALE_PRICE_PARAMS))

  const { data: commodityPrices = [] } = useQuery({
    queryKey: ['getCommodityPrices'],
    queryFn: () => getCommodityPrices(),
    select: (res) => res.data.filter(record => {
      const isBeforeToday = isBefore(new Date(record.date), now.current)
      return isBeforeToday
    }).map(record => ({
      id: record.id,
      date: record.date,
      oil_history: record.oil,
      gas_history: record.ng,
    }))
  })

  const data = useMemo(() => {
    const monthBasedData = [...records, ...commodityPrices].map(record => ({
      ...record,
      date: formatDate(record.date, 'yyyy-MM')
    }))
    const groupedByDate = groupBy(monthBasedData, 'date')
    return orderBy(Object.values(groupedByDate).reduce((state, group) => {
      const record = merge(...group)
      state.push(record)
      return state
    }, []), (record) => new Date(record.date), 'asc')
  }, [commodityPrices, records])

  const dataSeries = useMemo(() => {
    if (!data.length || !visibleCurves.length) return {}
    const dateSeries = []
    const series = data.reduce((state, record) => {
      const visibleKeys = Object.keys(SALE_PRICE_PARAMS).filter(key => {
        return visibleCurves.includes(key)
      })
      const visibleKeysData = pick(record, ...visibleKeys)
      if (isDeepEmpty(visibleKeysData ?? {})) return state
      const date = formatDate(record.date, 'yyyy-MM')
      if (date) dateSeries.push(date)
      visibleKeys.forEach((key) => {
        const param = SALE_PRICE_PARAMS[key]
        if (!state[param.name]) {
          const showAxisLine = ['oil', 'gas', 'ngl'].includes(key)
          state[param.name] = {
            data: [],
            lineType: "line",
            showAxisLine,
            unit: param.unit,
            lineName: param.axisName ? `${ param.axisName } (${ param.unit })` : param.name
          }
        }
        state[param.name].data.push(record[key] ? `${ record[key] }` : null)
      })
      return state
    }, {})

    const _dataSeries = {
      xSeries: orderBy(uniq(dateSeries), [(date) => new Date(date)], ['asc']),
      config: {
        multiYAxis: true,
        leftYAxisLabel: "Oil ($/bbl)",
        yAxisOffset: 40,
        yAxisNameGap: 25
      },
      dataSeries: series,
    };

    return _dataSeries
  }, [data, visibleCurves])

  const onSelectCurve = (curveType) => {
    setVisibleCurves(prev => [...prev, curveType])
  }

  const onDeselectCurve = (curveType) => {
    setVisibleCurves(prev => prev.filter(type => type !== curveType))
  }

  return (
    <div className="vstack gap-2 h-100">
      {/* <Typography.Title level={5} className="text-center text-light">Commodity Prices</Typography.Title> */}
      <Select
        mode="multiple"
        dropdownMatchSelectWidth={false}
        value={visibleCurves}
        options={Object.entries(SALE_PRICE_PARAMS).map(([key, param]) => ({
          value: key,
          label: param.name
        }))}
        placeholder="Select curves"
        style={{
          minWidth: 150
        }}
        onSelect={onSelectCurve}
        onDeselect={onDeselectCurve}
      />
      <div className="flex-grow-1">
        <TimeSeriesChart data={dataSeries} />
      </div>
    </div>
  )
}

export default WellSalePricesChart