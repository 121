import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import AssetWellsSelector from "../components/AssetWellsSelector";
import DcaManagementWellsSelector from "./DcaManagementWellsSelector";
import DcaManagementWellsMap from "./components/DcaManagementWellsMap";
import DcaManagementForecast from "./components/DcaManagementForecast";
import DcaManagementHistoryWatch from "./components/DcaManagementHistoryWatch";
import AddProjectForm from "components/project/AddProjectForm";
import DcaManagementDataPanel from "./components/DcaManagementDataPanel";

import {
  setShowNewProjectPopup,
  addProjectItem,
  setActiveProjectId,
  selectActiveProject,
} from "features/project/projectsSlice";

import {
  getProjectDetail,
  parseProjectId,
  saveDcaManagementProjectDetail,
} from "service/projects";
import {
  reset,
  setActiveWell,
  setDcaWells,
  addDcaWell,
  setActiveDcaWell,
} from "features/decline_analysis/dcaManagementSlice";

import useLoading from "components/common/useLoading";
import { Spin } from "antd";

function DcaManagement(props) {
  let [searchParams] = useSearchParams();
  const projectId = parseProjectId(searchParams.get("project_id"));
  const projectType = searchParams.get("project_type");
  const projectName = searchParams.get("project_name");

  const activeProject = useSelector(selectActiveProject);

  const [projectLoadStatus, setProjectLoadStatus] = useState(null); // loading, loaded
  const [selectedWellIds, setSelectedWellIds] = useState(null);

  const [projectParamsToSave, setProjectParamsToSave] = useState(null);
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(
    <Spin size="large" />,
    60
  );

  const dispatch = useDispatch();

  const refreshData = useCallback(
    (projectId) => {
      showLoadingIcon();
      setProjectLoadStatus("loading");
      getProjectDetail(
        projectId,
        (result) => {
          setProjectLoadStatus("loaded");
          if (activeProject == null) {
            dispatch(
              addProjectItem({
                id: result.project_id,
                name: result.project_name,
                project_type: result.project_type,
                custom_data: result.custom_data,
              })
            );
            dispatch(setActiveProjectId(result.project_id));
          }
          if (result.wells) {
            dispatch(setDcaWells(result.wells));
          }

          hideLoadingIcon();
        },
        (error) => {
          console.error(error);
          setProjectLoadStatus(null);
          hideLoadingIcon();
        }
      );
    },
    [activeProject, dispatch, hideLoadingIcon, showLoadingIcon]
  );

  useEffect(() => {
    return () => {
      dispatch(reset());
      dispatch(setActiveProjectId());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      projectLoadStatus === "loading" ||
      projectLoadStatus === "loaded" ||
      (projectId == null && activeProject == null)
    )
      return;
    refreshData(projectId || activeProject.id);
    // eslint-disable-next-line
  }, [projectLoadStatus, projectId, activeProject, dispatch]);

  const submitDcmProject = useCallback(
    (projId, projectParams) => {
      projectParams.project_id = projId;
      showLoadingIcon();
      saveDcaManagementProjectDetail(
        projectParams,
        (result) => {
          setProjectParamsToSave(null);
          if (result?.success !== true) {
            hideLoadingIcon();
            console.error("DCA save failed!");
            return;
          }

          dispatch(addDcaWell(result.wells));
          hideLoadingIcon();
        },
        (error) => {
          console.error("Save management setting failed!", error);
          hideLoadingIcon();
        }
      );
    },
    [dispatch, hideLoadingIcon, showLoadingIcon]
  );

  const handleSubmitDcmProject = useCallback(
    (projectParams) => {
      if (projectId === null && activeProject === null) {
        dispatch(setShowNewProjectPopup(true));
        setProjectParamsToSave(projectParams);
        return;
      }

      submitDcmProject(projectId || activeProject.id, projectParams);
    },
    [activeProject, projectId, dispatch, submitDcmProject]
  );

  const handleWellSelected = useCallback(
    (well) => {
      const wellId = well.uwi;
      // single select
      if (
        selectedWellIds == null ||
        selectedWellIds.includes(wellId) === false
      ) {
        setSelectedWellIds([wellId]);
        dispatch(setActiveWell(well));
        dispatch(setActiveDcaWell(well.uwi));
      }
    },
    [dispatch, selectedWellIds]
  );

  const handleDcaWellSelected = useCallback(
    (well) => {
      // single select
      if (
        selectedWellIds == null ||
        selectedWellIds.includes(well.uwi) === false
      ) {
        setSelectedWellIds([well.uwi]);
        dispatch(setActiveWell(well));
        dispatch(setActiveDcaWell(well.uwi));
      }
    },
    [dispatch, selectedWellIds]
  );

  return (
    <div style={{ height: "100vh" }}>
      <AddProjectForm
        input={{
          project_type: "dca_management",
          project_name: `PRJ_${new Date().getTime()}`,
          comment: "comment",
        }}
        callbackFunction={[submitDcmProject, projectParamsToSave]}
        loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
      />
      <LoadingIcon />

      <div className="page-content d-flex flex-column hide-scrollbar">
        <div className="d-flex row-wrapper mb-2 g-0">
          <div className="well-selector h-100 ps-0 pe-1">
            <AssetWellsSelector
              selectedWellIds={selectedWellIds}
              onWellSelected={handleWellSelected}
              loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              autoSelectFirst={!projectId}
            />
          </div>
          <div className="row h-100 flex-grow-1 g-0">
            <div className="col ps-1 pe-1">
              <DcaManagementWellsMap
                selectedWellIds={selectedWellIds}
                onWellSelected={handleWellSelected}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
            <div className="col ps-1">
              <DcaManagementDataPanel
                selectedWellIds={selectedWellIds}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
          </div>
        </div>
        <div className="d-flex row-wrapper mb-2 g-0">
          <div className="row h-100 flex-1 g-0">
            <div className="h-100 ps-0 pe-1" style={{ width: "142px" }}>
              <DcaManagementWellsSelector
                selectedWellIds={selectedWellIds}
                onWellSelected={handleDcaWellSelected}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
            <div className="col w-50 ps-1 pe-1">
              <DcaManagementHistoryWatch
                // well_data={activeWell}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
            <div className="col w-50 ps-1">
              <DcaManagementForecast
                submitDcaProject={handleSubmitDcmProject}
                loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DcaManagement;
