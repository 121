import { EditableProTable } from "@ant-design/pro-components";
import { Button } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";

const EditorTable = (props) => {
	/*
        title: String, title of this table
        columns: [], define the columns of table, including head and configuration of each column
        columnFetcher: func, can be null. Used to handle the case where the column definition is different from the predefined definition: columns. 
                if columns the processor for columns if needed, the parameter is the result data got by dataFetcher
        // uwi: String, could be null if allowNullUwi were true. Well UWI
        // allowNullUwi: boolean, if the uwi can be null
        dataFetcher: [func, func], can't be null. define the api get data, and post handler of the result data
        dataSaver: [func, func], can't be null. Define the api for saving data(a row), and the preprocessing method before saving data
        dataRemover: [func, func], can't be null. Define the api for deleting data(a row), and the preprocessing method before deleting data 
        showNewRow: func, the return value defines if show the new row button
        readonly: boolean, if the data in table is read only for the user. default is false, that means editable 
    */
	const {
		title,
		columns,
		dataFetcher,
		dataSaver,
		columnFetcher,
		dataRemover,
		showNewRow,
		readonly,
	} = props;
	/*
        fetcher: func, can't be null. the api of fetching data
        buildQuery: build query parameters for fetcher
        postDataHandler: func, can be null. the method will be called after data got. 
                especially if data got did not contains the 'id' column, the postDataHandler should generate the id data, or the id column will show only '-'
    */
	const [buildQuery, fetcher, postDataHandler] = dataFetcher;
	/*
        saver: func, can't be null. the api of saving data(a row)
        preDataHandler: func, can be null. the method will be called before saving data. it is using to build data formats and parameters needed by the saver API.
    */
	const [saver, preDataHandler, saverCreate, preCreateDataHandler] =
		dataSaver;
	/*
        remover: func, can't be null. the api of deleting data(a row)
        preDataHandler: func, can be null. the method will be called before deleting data. it is using to build data formats and parameters needed by the deleting API.
    */
	const [remover, preRemoveHandler] = dataRemover;

	const [editableKeys, setEditableRowKeys] = useState([], shallowEqual);
	const [dataSource, setDataSource] = useState([], shallowEqual);
	const [editableColumns, setEditableColumns] = useState(null, shallowEqual);
	const [loadingState] = useState(false);

	const { loadingIconActions } = props;
	const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];

	function refresh(
		fetcher,
		buildQuery,
		postDataHandler,
		showLoadingIcon,
		hideLoadingIcon
	) {
		const queryParams = buildQuery();
		if (queryParams == null || queryParams === undefined) return;

		showLoadingIcon && showLoadingIcon();

		fetcher(
			queryParams,
			(res) => {
				console.log("Load data ok.", res);
				setDataSource(postDataHandler ? postDataHandler(res) : res);
				hideLoadingIcon && hideLoadingIcon();
			},
			(err) => {
				console.warn("Load data failed! query: ", queryParams);
				setDataSource(null);
				hideLoadingIcon && hideLoadingIcon();
			}
		);
	}

	useEffect(() => {
		if (!columns) return;

		const hasData = dataSource && dataSource.length > 0;
		let cols = [...columns];
		hasData && columnFetcher && (cols = columnFetcher(cols, dataSource));
		setEditableColumns([
			{
				title: "ID",
				dataIndex: "id",
				key: "id",
				width: 40,
				editable: false,
			},
			...cols,
			...(readonly
				? []
				: [
						{
							title: "Operation",
							valueType: "option",
							key: "operation",
							width: 160,
							render: (text, record, _, action) => [
								<Button
									key="editable"
									onClick={() => {
										action?.startEditable?.(record.id);
									}}
								>
									Edit
								</Button>,
								<Button
									key="delete"
									onClick={(a, b, c) => {
										remover && showLoadingIcon && showLoadingIcon();
										remover && remover(
												preRemoveHandler
													? preRemoveHandler(record)
													: record,
												(res) => {
													hideLoadingIcon && hideLoadingIcon();
												},
												(err) => {
													console.warn(
														"Remove data failed!",
														err
													);
													hideLoadingIcon && hideLoadingIcon();
												}
											);
										hasData &&
											setDataSource(
												dataSource.filter(
													(item) =>
														item.id !== record.id
												)
											);
									}}
								>
									Delete
								</Button>,
							],
						},
				]
            ),
		]);
	}, [
		columns,
		dataSource,
		columnFetcher,
		preRemoveHandler,
		remover,
		readonly,
		showLoadingIcon,
		hideLoadingIcon,
	]);

	useEffect(
		() => {
			// if ((!uwi && !allowNullUwi) || !fetcher) {
			if (!fetcher || !buildQuery) {
				setDataSource(null);
				return;
			}
			refresh(
				fetcher,
				buildQuery,
				postDataHandler,
				showLoadingIcon,
				hideLoadingIcon
			);
		},
		// react-hooks/exhaustive-deps
		// eslint-disable-next-line
		[
			buildQuery,
			// fetcher, buildQuery, postDataHandler,
			// hideLoadingIcon, showLoadingIcon
		]
	);

	return (
		<EditableProTable
			headerTitle={<Title level={5}>{title}</Title>}
			columns={editableColumns || []}
			options={false}
			dataSource={dataSource || []}
			pagination={false}
			search={false}
			rowKey="id"
			recordKey="id"
			scroll={{
				x: 960,
			}}
			sticky={true}
			virtual={true}
			recordCreatorProps={{
				record: () => {
					const newId =
						88 * 1000000 + (Math.random() * 1000000).toFixed(0);
					return { id: newId, isNewRecord: true };
				},
				style: ((showNewRow && !showNewRow(dataSource)) ||
					editableKeys.length !== 0) && {
					display: "none",
				},
			}}
			loading={loadingState}
			value={dataSource || []}
			onChange={(values) => {
				setDataSource(values);
			}}
			editable={{
				type: "single",
				editableKeys,
				onSave: async (rowKey, data, row) => {
					let uniSaver, uniDataHandler;
					if (data.isNewRecord) {
						console.log("Save new row.");
						delete data.isNewRecord;
						uniSaver = saverCreate;
						uniDataHandler = preCreateDataHandler;
					} else {
						console.log("Update row.");
						uniSaver = saver;
						uniDataHandler = preDataHandler;
					}
					let data2Save = uniDataHandler
						? uniDataHandler(data, row, dataSource)
						: data;
					uniSaver && showLoadingIcon && showLoadingIcon();
					uniSaver &&
						uniSaver(
							data2Save,
							(res) => {
								console.log("Saved ok.");
								hideLoadingIcon && hideLoadingIcon();
								refresh(
									fetcher,
									buildQuery,
									postDataHandler,
									showLoadingIcon,
									hideLoadingIcon
								);
							},
							(err) => {
								console.warn("Save data failed!", err);
								hideLoadingIcon && hideLoadingIcon();
							}
						);
				},
				onChange: setEditableRowKeys,
			}}
			style={{
				backgroundColor: "#001529",
				margin: "5px",
				captionSide: "top",
			}}
		/>
	);
};

export default EditorTable;
