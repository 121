import React, { useState, useEffect, useCallback } from "react";
import EditorTable from "../component/EditorTable";
import { Cascader, ConfigProvider, Form, Select, Spin } from "antd";
import enUS from "antd/locale/en_US";
import {
  deleteWellCompletionFrac,
  deleteWellCompletionFracChem,
  deleteWellCompletionString,
  getWellCompletionFrac,
  getWellCompletionFracChem,
  getWellCompletionFracChemInputConfig,
  getWellCompletionString,
  updateWellCompletionFrac,
  updateWellCompletionFracChem,
  updateWellCompletionString,
} from "service/wellCompletion";
import { getAssets } from "service/assets";
import { getWellSummary } from "service/wellSummary";
import useLoading from "components/common/useLoading";

function CompletionEditor(props) {
  const [uwi, setUwi] = useState(null);
  const [wellName, setWellName] = useState(null);
  // const [assets, setAssets] = useState(null);
  const [assetWells, setAssetWells] = useState([]);
  const [selectedAssetWells, setSelectedAssetWells] = useState(null);
  const [defaultAssetWell] = useState(null);
  const [LoadingIcon, showLoadingIcon, hideLoadingIcon] = useLoading(
    <Spin size="large" />,
    50
  );

  const [hasNewCompletion, setHasNewCompletion] = useState(false);
  const [chemInputConfig, setChemInputConfig] = useState(null);

  const completionColumns = [
    {
      title: "Well Name",
      dataIndex: "well_name",
      key: "well_name",
      width: "85px",
      hideInTable: true,
    },
    {
      title: "UWI",
      dataIndex: "uwi",
      key: "uwi",
      width: "85px",
      hideInTable: true,
    },
    {
      title: "Number Of Stage",
      dataIndex: "number_of_stage",
      key: "number_of_stage",
      width: "85px",
    },
    {
      title: "Inter Casing Id",
      dataIndex: "inter_casing_id",
      key: "inter_casing_id",
      width: "85px",
    },
    {
      title: "Inter Casing Od",
      dataIndex: "inter_casing_od",
      key: "inter_casing_od",
      width: "85px",
    },
    {
      title: "Inter Casing Grade",
      dataIndex: "inter_casing_grade",
      key: "inter_casing_grade",
      width: "85px",
    },
    {
      title: "Inter Casing Start",
      dataIndex: "inter_casing_start",
      key: "inter_casing_start",
      width: "85px",
    },
    {
      title: "Inter Casing End",
      dataIndex: "inter_casing_end",
      key: "inter_casing_end",
      width: "85px",
    },
    {
      title: "Prd Casing ID",
      dataIndex: "prd_casing_id",
      key: "prd_casing_id",
      width: "85px",
    },
    {
      title: "Prd Casing OD",
      dataIndex: "prd_casing_od",
      key: "prd_casing_od",
      width: "85px",
    },
    {
      title: "Prd Casing Grade",
      dataIndex: "prd_casing_grade",
      key: "prd_casing_grade",
      width: "85px",
    },
    {
      title: "Prd Casing End",
      dataIndex: "prd_casing_end",
      key: "prd_casing_end",
      width: "85px",
    },
    {
      title: "Suf Casing Id",
      dataIndex: "suf_casing_id",
      key: "suf_casing_id",
      width: "85px",
    },
    {
      title: "Suf Casing Od",
      dataIndex: "suf_casing_od",
      key: "suf_casing_od",
      width: "85px",
    },
    {
      title: "Suf Casing Grade",
      dataIndex: "suf_casing_grade",
      key: "suf_casing_grade",
      width: "85px",
    },
    {
      title: "Suf Casing Start",
      dataIndex: "suf_casing_start",
      key: "suf_casing_start",
      width: "85px",
    },
    {
      title: "Suf Casing End",
      dataIndex: "suf_casing_end",
      key: "suf_casing_end",
      width: "85px",
    },
    {
      title: "Tubing Casing Id",
      dataIndex: "tubing_id",
      key: "tubing_id",
      width: "85px",
    },
    {
      title: "Tubing Casing Od",
      dataIndex: "tubing_od",
      key: "tubing_od",
      width: "85px",
    },
    {
      title: "Tubing Casing Grade",
      dataIndex: "tubing_grade",
      key: "tubing_grade",
      width: "100px",
    },
    {
      title: "Tubing Casing Start",
      dataIndex: "tubing_start",
      key: "tubing_start",
      width: "85px",
    },
    {
      title: "Tubing Casing End",
      dataIndex: "tubing_end",
      key: "tubing_end",
      width: "85px",
    },
  ];

  const fracturingColumns = [
    {
      title: "Well Name",
      dataIndex: "well_name",
      key: "well_name",
      hideInTable: true,
      hideInForm: false,
    },
    {
      title: "UWI",
      dataIndex: "uwi",
      key: "uwi",
      hideInTable: true,
      hideInForm: false,
    },
    {
      title: "Stage Number",
      dataIndex: "stage_number",
      key: "stage_number",
    },
    {
      title: "Job Date",
      dataIndex: "job_date",
      key: "job_date",
      // render: (text, record, index) => {
      //     let date = renderUnit(text, record, index, 'job_date');
      //     if (React.isValidElement(date)) return date;
      //     else return date.split("T")[0];
      // },
    },
    {
      title: "Frac Fluid Mass",
      dataIndex: "frac_fluid_mass",
      key: "frac_fluid_mass",
    },
    {
      title: "Fluid Type",
      dataIndex: "fluid_type",
      key: "fluid_type",
    },
    {
      title: "Proppant Type",
      dataIndex: "proppant_type",
      key: "proppant_type",
    },
    {
      title: "Proppant Mesh",
      dataIndex: "proppant_mesh",
      key: "proppant_mesh",
    },
    {
      title: "Proppant Mass",
      dataIndex: "proppant_mass",
      key: "proppant_mass",
    },
    {
      title: "Average Stp",
      dataIndex: "average_stp",
      key: "average_stp",
    },
    {
      title: "Fracture Gradient",
      dataIndex: "fracture_gradient",
      key: "fracture_gradient",
    },
    {
      title: "Md Middle Perforation",
      dataIndex: "md_middle_perforation",
      key: "md_middle_perforation",
    },
    {
      title: "Min Stp",
      dataIndex: "min_stp",
      key: "min_stp",
    },
    {
      title: "Max Stp",
      dataIndex: "max_stp",
      key: "max_stp",
    },
  ];

  const chemicalUsageColumns = [
    {
      title: "Well Name",
      dataIndex: "well_name",
      key: "well_name",
      hideInTable: true,
    },
    {
      title: "UWI",
      dataIndex: "uwi",
      key: "uwi",
      hideInTable: true,
    },
    {
      title: "Stage Number",
      dataIndex: "stage_number",
      key: "stage_number",
    },
    {
      title: "Additive Id",
      dataIndex: "additive_id",
      key: "additive_id",
    },
    {
      title: "Additive Name",
      dataIndex: "additive_name",
      key: "additive_name",
    },
    {
      title: "Additive Mass",
      dataIndex: "additive_mass",
      key: "additive_mass",
    },
    {
      title: "Unit",
      dataIndex: "additive_mass_unit_id",
      key: "additive_mass_unit_id",
      // defaultValue: 29,
      valueType: "select",
      valueEnum: () => {
        let result = {};
        chemInputConfig.map(
          (config) =>
            (result[config.id] = {
              text: config.unit,
              // selected: config.id === 29,
            })
        );

        return result;
      },
      render: (text, record, index) =>
        renderChemUnit(text, record, index, "additive_mass_unit_id"),
    },
    {
      title: "CAS Number",
      dataIndex: "cas_number",
      key: "cas_number",
    },
    {
      title: "Concentration",
      dataIndex: "concentration",
      key: "concentration",
    },
    {
      title: "Unit",
      dataIndex: "concentration_unit_id",
      key: "concentration_unit_id",
      defaultUnitId: 32,
      // defaultValue: 32,
      valueType: "select",
      valueEnum: () => {
        let result = {};
        chemInputConfig.map(
          (config) =>
            (result[config.id] = {
              text: config.unit,
              selected: config.id === 32,
            })
        );

        return result;
      },
      render: (text, record, index) =>
        renderChemUnit(text, record, index, "concentration_unit_id"),
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
  ];

  const renderChemUnit = (text, record, index, fieldName) => {
    const item = record[fieldName];

    if (!item || !chemInputConfig) return item;

    return chemInputConfig.find(({ id }) => id === item)?.unit;
  };

  const onChange = (value) => {
    if (!value || value.length < 1) return;
    setSelectedAssetWells(value);
    if (value.length < 2) return;
    setUwi(value[1]);
  };

  // Load Assets and Wells list
  useEffect(() => {
    getAssets(
      (result) => {
        // setAssets(result);

        let tempAssetWells = result.map((asset) => {
          return {
            value: asset.id,
            label: asset.asset_name,
            isLeaf: false,
            // children: [],
          };
        });
        setAssetWells(tempAssetWells);
      },
      (err) => {
        // hideLoadingIcon && hideLoadingIcon();
      }
    );
  }, []);

  // Load Assets and Wells list
  const loadAssetWells = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.children && targetOption.children.length > 0) {
      return;
    }
    targetOption.loading = true;

    getWellSummary(
      targetOption.value,
      (result) => {
        targetOption.loading = false;
        let wells = result.map((well) => {
          return {
            value: well.uwi,
            label: well.well_name,
          };
        });
        targetOption.children = wells;
        setAssetWells([...assetWells]);
      },
      (err) => {
        console.error(err);
      }
    );
  };

  // Load chem input config
  useEffect(() => {
    getWellCompletionFracChemInputConfig(
      (result) => {
        setChemInputConfig(result.units);
      },
      (err) => console.error("Well Summary: ", err)
    );
  }, []);

  const buildQuery = useCallback(()=>{return uwi;}, [uwi]);

  return (
    <div className="page-content d-flex flex-column">
      <LoadingIcon />
      <ConfigProvider locale={enUS} componentSize="middle">
        <div style={{ marginLeft: "8px" }}>
          {"Asset/Well:  "}
          <Cascader
            options={assetWells}
            value={selectedAssetWells}
            onChange={onChange}
            defaultValue={defaultAssetWell}
            loadData={loadAssetWells}
            bordered={true}
            // showSearch={true}
            // expandTrigger={'hover'}
            placeholder="Please select"
            changeOnSelect
          />
        </div>
        <EditorTable
          title="Well String"
          columns={completionColumns || []}
          // hasUnitRow={false}
          showNewRow={() => hasNewCompletion}
          // uwi={uwi}
          // allowNullUwi={false}
          dataFetcher={[
            buildQuery,
            (params, onSuccess, onError) => {
              getWellCompletionString(params, onSuccess, onError);
            },
            (dataSource) => {
              setHasNewCompletion(dataSource?.completions.length < 1);
              if (dataSource == null) return null;
              const { completions } = dataSource;
              return completions;
            },
          ]}
          dataSaver={[
            updateWellCompletionString,
            (data) => {
              delete data.index;
              delete data.well_name;
              console.log(data);
              return [data];
            },
            updateWellCompletionString,
            // buildUploadParameters,
            (data) => {
              delete data.index;
              delete data.well_name;
              delete data.id;
              data.uwi = uwi;
              console.log(data);
              return [data];
            }
          ]}
          dataRemover={[deleteWellCompletionString, (record) => [record.id]]}
          loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
        />
        <EditorTable
          title="Well Fracturing"
          columns={fracturingColumns || []}
          // uwi={uwi}
          // allowNullUwi={false}
          dataFetcher={[
            buildQuery,
            getWellCompletionFrac,
            (dataSource) => {
              if(dataSource == null) return null;
              let { completions } = dataSource; // chem has no completion_units
              return completions;
            }
              // attachUnitsToDataSource(dataSource, fracturingColumns),
          ]}
          dataSaver={[
            updateWellCompletionFrac,
            (data) => {
              // [{ well_name: wellName, ...data }]
              delete data.index;
              delete data.well_name;
              console.log(data);
              return [data];
            },
            // uploadWellCompletionFrac,
            // buildUploadParameters,
            updateWellCompletionFrac,
            (data) => {
              // [{ well_name: wellName, ...data }]
              delete data.index;
              delete data.well_name;
              delete data.id;
              data.uwi = uwi;
              console.log(data);
              return [data];
            },
          ]}
          dataRemover={[deleteWellCompletionFrac, (record) => [record.id]]}
          loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
        />
        <EditorTable
          title="Chemical Usage"
          columns={chemicalUsageColumns || []}
          //   uwi={uwi}
          //   allowNullUwi={false}
          dataFetcher={[
            buildQuery,
            getWellCompletionFracChem,
            (dataSource) => {
              if (!dataSource) return null;
              let { completions } = dataSource; // chem has no completion_units

              let currentAsset = assetWells.find(
                (asset) => asset.value === selectedAssetWells[0]
              );
              if (!currentAsset) {
                console.error("Can't find the asset: ", selectedAssetWells[0]);
                return;
              }
              if (!currentAsset.children || currentAsset.children.length < 1) {
                console.error("Asset has no wells: ", selectedAssetWells[0]);
                return;
              }
              let currentWell = currentAsset.children.find(
                (well) => well.value === selectedAssetWells[1]
              );
              if (!currentWell) {
                console.error("Can't find the well: ", selectedAssetWells[1]);
                return;
              }
              setWellName(currentWell.label);
              completions = completions
                .sort((prev, next) => prev.id - next.id)
                .map((item) => ({ ...item, well_name: currentWell.label }));

              return completions;
            },
          ]}
          dataSaver={[
            updateWellCompletionFracChem,
            (data) => [{ well_name: wellName, ...data }],
            updateWellCompletionFracChem,
            (data) => {
              delete data.id;
              delete data.index;
              return [{ uwi: uwi, ...data }];
            },
          ]}
          dataRemover={[deleteWellCompletionFracChem, (record) => [record.id]]}
          loadingIconActions={[showLoadingIcon, hideLoadingIcon]}
        />
      </ConfigProvider>
    </div>
  );
}

export default CompletionEditor;
