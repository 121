import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";

import {
  selectSelectedWellParams,
  selectAvailableParamsData,
  selectAvailableWellParams,
} from "features/well_planner/plannerSlice";
import { computeWellParamsCorrelation } from "service/wellData";

import { drawCorrChart } from "./chart";

function ParamsCorrelationChart(props) {
  const { loadingIconActions } = props;
  const [showLoadingIcon, hideLoadingIcon] = loadingIconActions || [];
  const selectedParams = useSelector(selectSelectedWellParams);
  const wellParams = useSelector(selectAvailableWellParams);
  const wellParamsData = useSelector(selectAvailableParamsData);

  const svgRef = React.useRef(null);

  // const dimensions = {
  //   width: 600,
  //   height: 300,
  //   margin: { top: 30, right: 30, bottom: 30, left: 60 }
  // };

  // const { width, height, margin } = dimensions;
  // const svgWidth = width + margin.left + margin.right;
  // const svgHeight = height + margin.top + margin.bottom;

  const [corrData, setCorrData] = useState(null);
  const [corrParams, setCorrParams] = useState(null);

  const [dimensions, setDimensions] = useState({
    cellSize: 48,
    width: 600,
    height: 600,
  });

  useEffect(() => {
    if (
      wellParams == null ||
      wellParamsData == null ||
      selectedParams == null ||
      selectedParams.length < 2
    ) {
      return;
    }
    let paramsMatrix = [];
    for (let param of selectedParams) {
      let wellIds = Object.keys(wellParams);
      let dataRow = [];
      for (let wellId of wellIds) {
        let params = wellParams[wellId];
        let paramsData = wellParamsData[wellId];
        let index = params.findIndex((p) => p === param);
        dataRow.push(paramsData[index]);
      }
      paramsMatrix.push({ [param]: dataRow });
    }

    showLoadingIcon && showLoadingIcon();
    computeWellParamsCorrelation(
      paramsMatrix,
      (result) => {
        setCorrData(result.corr);
        setCorrParams(result.params);

        setDimensions({
          cellSize: 48,
          width: 48 * result.params.length + 80,
          height: 48 * result.params.length,
        });
        hideLoadingIcon && hideLoadingIcon();
      },
      (error) => { hideLoadingIcon && hideLoadingIcon(); }
    );
  }, [wellParams, wellParamsData, selectedParams, hideLoadingIcon, showLoadingIcon]);

  useEffect(() => {

    if (
      corrData == null ||
      Array.isArray(corrData) === false ||
      corrData.length === 0 ||
      corrParams == null
    ) {
      return;
    }

    drawCorrChart(svgRef.current, corrParams, corrData, dimensions);
  }, [corrData, corrParams, dimensions]);

  return (
    <div
      id="corr_chart"
      className="w-100 h-100"
    // style={{ backgroundColor: "#fff" }}
    >
      <svg
        ref={svgRef}
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        className="w-100 h-100"
      />
      ;
    </div>
  );
}

export { ParamsCorrelationChart };
