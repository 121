import { useQuery } from '@tanstack/react-query'
import { Form, Input, Modal, Select, Typography } from 'antd'
import omit from 'lodash/omit'
import React, { useCallback, useEffect, useMemo } from 'react'
import { getWellAfe } from 'service/afe'
import { getWellSummary } from 'service/wellSummary'
import { formatDate } from 'utils/dateUtil'

const SOURCE_OPTIONS = {
  MANUAL: 'manual',
  COPY: 'copy'
}

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const CreateAFEModal = ({ assetId, isSubmitting, onSave, ...modalProps }) => {
  const [form] = Form.useForm()
  const source = Form.useWatch('source', form)
  const uwi = Form.useWatch('uwi', form)
  const ref_afe_id = Form.useWatch('ref_afe_id', form)

  const { data: wells, isFetching: isFetchingWells } = useQuery({
    queryKey: ['CreateAFEModal', 'getWellSummary', assetId],
    queryFn: () => getWellSummary(assetId),
    enabled: Boolean(assetId && source === SOURCE_OPTIONS.COPY),
    select: (res) => res.data
  })

  const { data: afeList, isFetching: isFetchingAFE } = useQuery({
    queryKey: ['CreateAFEModal', 'getWellAfe', uwi],
    queryFn: () => getWellAfe(uwi, null, () => {
      form.setFieldValue('ref_afe_id', null)
    }),
    select: res => res.data,
    enabled: Boolean(source === SOURCE_OPTIONS.COPY && uwi)
  })

  useEffect(() => {
    form.resetFields(['afe_name', 'notes', 'source', 'uwi', 'ref_afe_id'])
  }, [form, modalProps.open])

  const wellOptions = useMemo(() => wells?.map(well => ({
    value: well.uwi,
    label: well.well_name
  }) ?? []), [wells])

  const afeOptions = useMemo(() => afeList?.map(afe => ({
    value: afe.id,
    label: afe.afe_name
  }) ?? []), [afeList])

  const refAFEData = useMemo(() => ref_afe_id ? afeList?.find(afe => afe.id === ref_afe_id) : null, [afeList, ref_afe_id])

  const onSubmit = useCallback((formData) => {
    const data = omit(formData, 'source', 'uwi', 'ref_afe_id')
    onSave(data, formData.source === SOURCE_OPTIONS.MANUAL ? null : refAFEData)
  }, [onSave, refAFEData])

  return (
    <Modal
      title="New AFE"
      okText="Save"
      width={640}
      onOk={form.submit}
      cancelButtonProps={{
        disabled: isSubmitting
      }}
      okButtonProps={{
        loading: isSubmitting
      }}
      {...modalProps}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Form.Item label={<Typography className="text-dark">AFE Name</Typography>} name="afe_name" rules={[{
          required: true
        }]}>
          <Input placeholder="AFE Name" />
        </Form.Item>
        <Form.Item label={<Typography className="text-dark">Note</Typography>} name="notes">
          <Input.TextArea placeholder="Note" />
        </Form.Item>
        <Form.Item name="source" label={<Typography className="text-dark">Source</Typography>} rules={[{ required: true }]}>
          <Select
            options={[
              {
                value: SOURCE_OPTIONS.MANUAL,
                label: 'Manual'
              },
              {
                value: SOURCE_OPTIONS.COPY,
                label: 'Copy from...'
              }
            ]}
          />
        </Form.Item>
        {source === SOURCE_OPTIONS.COPY ? (
          <Form.Item name="uwi" label={<Typography className="text-dark">Reference Well</Typography>} rules={[{ required: true }]}>
            <Select showSearch filterOption={filterOption} loading={isFetchingWells} options={wellOptions} />
          </Form.Item>
        ) : null}
        {uwi ? (
          <Form.Item name="ref_afe_id" label={<Typography className="text-dark">AFE</Typography>} rules={[{ required: true }]}>
            <Select showSearch filterOption={filterOption} loading={isFetchingAFE} options={afeOptions} />
          </Form.Item>
        ) : null}
        {refAFEData ? (
          <div>
            <Typography.Text>Info:</Typography.Text>
            <ul>
              <li>Status: {refAFEData.approval_status === 'Yes' ? 'Approved' : 'Not approved'}</li>
              <li>Approved date: {refAFEData.approval_date ? formatDate(new Date(refAFEData.approval_date), 'MM/dd/yyyy HH:mm:ss') : ''}</li>
            </ul>
          </div>
        ) : null}
      </Form>
    </Modal>
  )
}

export default CreateAFEModal