import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  pos: {},
};

const AssetViewerSlice = createSlice({
  name: "asset_viewer",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setPos: (state, action) => {
      let linePos = action.payload; //{col1: {'1921': 123, '1299': 234}
      Object.keys(linePos).forEach(key => {
        state.pos[key] === undefined && (state.pos[key] = {});
        state.pos[key] = { ...linePos[key] };
      })
    },
  },
});

export const {
  reset,
  setPos,
} = AssetViewerSlice.actions;
export const selectPos = (state) => state.asset_viewer.pos;

export default AssetViewerSlice.reducer;
